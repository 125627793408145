var superproto = GUI.Element.prototype;

/**
* JavaScript Graphical User Interface
* GUI.ToolBar.Separator implementation
*
* @author Eugene Lyulka
* @version 2.0
* @namespace GUI.ToolBar
* @extends GUI.Element
*/
GUI.ToolBar.Separator = Class.create();
Object.extend(GUI.ToolBar.Separator.prototype, superproto);

/**
 * Initializes object
 * @param {Object} config Configuration object
 */
GUI.ToolBar.Separator.prototype.initialize = function (config) {
    // Call parent initialize meethod
    GUI.Element.prototype.initialize.apply(this, arguments);

    // Extend parent config
    Object.extend(this.config, {
        id          : GUI.getUniqId('toolbar-separator-'),
        className   : 'b-toolbar__delimiter',
        extraClass  : '', // blank
        hidden      : false
    });
    Object.extend(this.config, config);

    this.elementType = 'GUI.ToolBar.Separator';
};

/**
 * Renders DOM tree and sets this.dom to root of this tree
 * @return {HTMLElement} to DOMNode object
 */
GUI.ToolBar.Separator.prototype._render = function (to) {
    if (!to) {
        throw new Error('Can not render to:' + to);
    }
    var cfg = this.config;

    cfg.extraClass = cfg.extraClass || '';

    this.dom = document.createElement('I');
    this.dom.id = cfg.id;
    this.dom.className = cfg.className + ' ' + cfg.extraClass;

    to.appendChild(this.dom);

    if (cfg.hidden) {
        this.hide();
    }
};
