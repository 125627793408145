import {hotkeys, HotKeys, Keys, KEY, MODIFIER} from 'library/keyboard'

(function () {
    var superproto = GUI.Forms.TextArea.prototype;

    /**
     * JavaScript Graphical User
     * GUI.Forms.EditorTinymce implementation
     *
     * @author Inna
     * @version 2.0
     * @namespace GUI.Forms
     * @extends GUI.Forms.TextArea
     */
    GUI.Forms.EditorTinymce = Class.create();
    Object.extend(GUI.Forms.EditorTinymce.prototype, superproto);

    /**
     * Css class of the wysywyg, default is 'b-wysiwyg'
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.cls = 'b-wysiwyg';

    /**
     * Width, default is 400
     * @type Number
     */
    GUI.Forms.EditorTinymce.prototype.width = 500;

    /**
     * Default is is empty
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.value = '';

    /**
     * Default is 'full', may be is 'simple'
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.type = 'full';

    /**
     * Css class, default is null
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.css = null;

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.template = '<a class="noaction"></a><div id="{this.editorId}" class="b-wysiwyg__content"></div>';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Forms.EditorTinymce.prototype.useImageManager = true;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.EditorTinymce.prototype.useLinkToContent = false;

    /**
     * Url of handler image manager, default is ''
     * @type String
     */
    GUI.Forms.EditorTinymce.prototype.imageHandlerUrl = '';

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.resizeToolbar = true;

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.trimEmptyBlocks = true;

    /**
     * On render editor, set cursor for this tinymce editor
     * @type Boolean
     */
    GUI.Forms.EditorTinymce.prototype.autoFocus = false;

    /**
     * Editor placeholder, by default is empty
     * @type {String}
     */
    GUI.Forms.EditorTinymce.prototype.placeholder = '';

    /**
     * Mark is editor ready
     * @type {Boolean}
     */
    GUI.Forms.EditorTinymce.prototype.isReady = false;


    /**
     * Add domain to url
     * @type {Boolean}
     */
    GUI.Forms.EditorTinymce.prototype.baseHref = true;

    /**
     * Process dnd and paste images
     * @type {Boolean}
     */
    GUI.Forms.EditorTinymce.prototype.insertImage = true;

    /**
     * Add domain to url
     * @type {Boolean}
     */
    GUI.Forms.EditorTinymce.prototype.hotkeys = null;

    /**
     * Initializes object, buttons, toolbars, add events 'hide, 'show', 'enable',
     * 'disable', 'change', 'createToolbar', 'ready'
     * @param {Object} config Configuration object
     */
    GUI.Forms.EditorTinymce.prototype.initComponent = function (config) {
        var imMgr;

        if (this.useImageManager && !this.getPlugin(GUI.Forms.Plugins.EditorImageManager)) {
            imMgr = new GUI.Forms.Plugins.EditorImageManager();
            this.addPlugin(imMgr);
        }

        // Call parent initialize meethod
        superproto.initComponent.call(this);

        this.addEvents({
            hide: true,
            show: true,
            enable: true,
            disable: true,
            change: true,
            ready: true,
            keyup: true,
            afterRenderEditor: true
        });

        this.buttons = {};
    };

    /**
     * Override parent method, for remove tabindex
     * @param dom
     */
    GUI.Forms.EditorTinymce.prototype.initField = function (dom) {
        dom.id = this.getId();

        dom.addClass(this.fieldClass);
        dom.addClass(this.className);

        if (this.addClass !== null) {
            dom.addClass(this.addClass);
        }

        if (this.readOnly) {
            dom.readOnly = true;
        }

        if (this.title) {
            dom.setAttribute('title', this.title);
        }

        // Save default value to be used for reset
        this.defaultValue = this.value;
    };

    /**
     * Handler assign, sets image handler, plugin ImageManager
     * @param {HTMLElement} elem
     */
    GUI.Forms.EditorTinymce.prototype.onAssign = function (elem) {
        superproto.onAssign.call(this, elem);

        var attr, plugin, imMgr,
            css = elem.getAttribute('css');

        if (css) {
            this.setCss(css);
        }

        attr = elem.getAttribute('imageHandlerUrl');
        if (attr) {
            this.imageHandlerUrl = attr;
        }

        attr = elem.getAttribute('useimagemanager');
        if (attr) {
            attr = (attr === 'false') ? false : true;
            plugin = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
            if (plugin && !attr) { // remove plugin
                this.removePlugin(plugin);
            } else if (!plugin && attr) { // add plugin
                imMgr = new GUI.Forms.Plugins.EditorImageManager({});
                this.addPlugin(imMgr);
            }
        }

        attr = elem.getAttribute('iframebodyclass');
        if (attr) {
            this.iframeBodyClass = attr;
        }

        attr = elem.getAttribute('cssforiframe');
        if (attr) {
            this.cssForIframe = attr.split(',');
        }

        attr = elem.getAttribute('tabindex');
        if (attr) {
            this.tabIndex = attr;
            elem.setAttribute('tabindex', '-1');
        }

        attr = elem.getAttribute('resizeToolbar');
        if (attr) {
            this.resizeToolbar = (attr === 'false') ? false : true;
        }

        attr = elem.getAttribute('autoresize');
        if (attr) {
            this.autoresize = (attr === 'false') ? false : true;
        }

        attr = elem.getAttribute('hideToolbars');
        if (attr) {
            this.hideToolbars = (attr === 'false') ? false : true;
        }

        attr = elem.getAttribute('trimemptyblocks');
        if (attr) {
            this.trimEmptyBlocks = (attr === 'false') ? false : true;
        }

        attr = elem.getAttribute('pointerevets');
        if (attr) {
            this.pointerEvents = elem.getAttribute('pointerevets');
        }

        attr = elem.getAttribute('type');
        if (attr) {
            this.type = attr;
        }
    };

    /**
     * Creates html, sets css class, init template
     * @returns {HTMLElement} div
     */
    GUI.Forms.EditorTinymce.prototype.onRender = function () {
        var div,
            res = superproto.onRender.call(this);

        if (window.Env.isTestingEnv()) {
            window['editor-' + this.id] = this;
        }

        GUI.Dom.addClass(res, 'b-wysiwyg__iframe_source');

        this.toolbarId = this.id + '-toolbar';
        this.tableToolbarId = this.id + '-table-toolbar';

        this.editorId = this.id + '-editor';
        this.cssForIframe = this.cssForIframe || [window.themes_path + '/admin.css'];

        div = document.createElement('div');
        div.id = this.id + '-wrap';
        div.className = this.cls;

        if (!this.tpl) {
            this.tpl = new GUI.STemplate(this.template, this);
        }
        div.innerHTML = this.tpl.fetch(); // Check leaks in IE!
        div.appendChild(res);

        this.sourceBody = res;
        if (this.tabindex) {
            this.sourceBody.setAttribute('tabIndex', '-1');
        }
        //GUI.hide(this.sourceBody);

        this.sourceBody.addClass('g-hide-offsets');

        return div;
    };

    GUI.Forms.EditorTinymce.prototype.addImageButton = function(editor)
    {
        editor.addButton('image2', {
            icon: 'image',
            title: i18n('Insert Image'),
            onclick: function () {
                var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                pl.editor = this.editor_tinymce;
                pl.onClick();
            }.bindLegacy(this)
        });

        editor.addButton('image_simple', {
            icon: 'image',
            title: i18n('Insert Image'),
            onclick: function () {
                var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                pl.editor = this.editor_tinymce;
                pl.onClick();
                pl.setOnlyWeb();
            }.bindLegacy(this)
        });
    };

    GUI.Forms.EditorTinymce.prototype.setTopicSuggestions = function (ComponentTopicSuggestions) {
        this.ComponentTopicSuggestions = ComponentTopicSuggestions;
    };

    GUI.Forms.EditorTinymce.prototype.addTopicLinkInsert = function(editor)
    {
        // if (window.require && isAppEnabled && (isAppEnabled('community') || isAppEnabled('kb'))) {
        if (this.ComponentTopicSuggestions) {
            var action = function () {
                var topicSuggestions = new this.ComponentTopicSuggestions();
                topicSuggestions.configure({
                    preselectText: editor.selection.getContent().replace(/&nbsp;/gmi, ' ')
                });
                topicSuggestions.show();
                topicSuggestions.on('insertLink', function (content) {
                    if(content.hasOwnProperty('text')) {
                        var text = editor.dom.encode(content.text).replace(/ /gmi, '&nbsp;');
                        editor.insertContent(editor.dom.createHTML('a', content, text));
                    } else {
                        this.editor_tinymce.execCommand('mceInsertLink', false, content);
                    }
                }.bindLegacy(this))
            }.bindLegacy(this);

            editor.addButton('topic_link', {
                type: 'splitbutton',
                icon: 'link',
                title: tinymce.i18n.translate('Insert/edit link'),
                onclick: function () {
                    editor.execCommand('mceLink');
                },
                menu: [
                    {
                        text: i18n('Link'),
                        icon: 'link',
                        onclick: function () {
                            editor.execCommand('mceLink');
                        }
                    },
                    {
                        text: i18n('Link to topic'),
                        icon: 'link',
                        onclick: action
                    }
                ]
            });

            editor.addMenuItem('topic_link', {
                icon: 'link',
                text: i18n('Link to topic'),
                onclick: action,
                context: 'insert',
                prependToContext: true
            });
        } else {
            editor.addButton('topic_link', {
                icon: 'link',
                title: tinymce.i18n.translate('Insert/edit link'),
                onclick: function () {
                    editor.execCommand('mceLink');
                }
            });
        }
    };

    /**
     * Extends dom, create toolbar, init iframe, add event 'click' on status bar
     * @param {HTMLElement} dom
     * @param retry
     */
    GUI.Forms.EditorTinymce.prototype.onAfterRender = function (dom, retry=0) {
        this.textarea = dom.getElementsByTagName('textarea')[0];
        superproto.onAfterRender.call(this, this.textarea);

        var plugins, url,
            toolbarList = '',
            block_formats = '';

        setTimeout(function () {
            if (retry >= 30) {
                window.TrackJS && window.TrackJS.track(new Error('Editor not created after 30 retry'));
                return;
            }

            if (!this.dom) {
                return;
            }

            let dom = jQuery(this.dom);
            let count = dom.find(`iframe#${this.editorId}_ifr`).length;

            if (count === 0) {
                let listOfIdsEditors = _.map(tinyMCE.editors, (editor) => editor.id);
                window.TrackJS && window.TrackJS.track(
                    new Error(`Editor not created, alredy exists editors: ${listOfIdsEditors.join(',')} and now try create ${this.editorId}`)
                );

                this.id = GUI.getUniqId(this.id);
                this.editorId = `${this.id}-editor`;
                dom.attr('id', `${this.id}-wrap`);
                dom.find('textarea').attr('id', this.id);
                dom.find('.b-wysiwyg__content').attr('id', `${this.id}-editor`);

                this.onAfterRender(this.dom, retry + 1);
            }
        }.bindLegacy(this), 2000);

		// Widget: Add ticket
        if (this.type === 'front_ticket_widget') {
            plugins = ['anchor autolink lists charmap codesample colorpicker contextmenu fullscreen image link media paste tabfocus tabindex table table_default_props textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

//            toolbarList = 'format1 bold italic underline format2 | align | bullist numlist | topic_link image2 media codesample table';
            toolbarList = 'format1 bold italic underline format2 | bullist numlist | topic_link image2 media codesample | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Front: Add ticket, ticket reply
        } else if (this.type === 'front_ticket') {
            plugins = ['anchor autolink lists charmap codesample colorpicker contextmenu fullscreen image link media paste tabfocus tabindex table table_default_props textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

//            toolbarList = 'format1 bold italic underline | forecolor backcolor format2 | align | bullist numlist | topic_link image2 media codesample table';
            toolbarList = 'format1 bold italic underline format2 | bullist numlist | topic_link image2 media codesample | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Widget: Add item
        } else if (this.type === 'front_item_widget') {
            plugins = ['anchor autolink lists charmap codesample colorpicker contextmenu fullscreen image link media paste tabfocus tabindex table textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

//            toolbarList = 'format1 bold italic underline format2 | align | bullist numlist | topic_link image2 media codesample table';
            toolbarList = 'format1 bold italic underline format2 | bullist numlist | topic_link image2 media codesample | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Front: Add item, item reply
        } else if (this.type === 'front_item') {
            plugins = ['anchor autolink lists charmap codesample colorpicker contextmenu fullscreen image link media paste tabfocus tabindex table textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

//            toolbarList = 'format1 bold italic underline | forecolor backcolor format2 | align | bullist numlist | topic_link image2 media codesample table';
            toolbarList = 'format1 bold italic underline format2 | bullist numlist | topic_link image2 media codesample | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Admin: Add ticket, add ticket reply (image manager, no source)
        } else if (this.type === 'admin_ticket_add') {
            plugins = ['anchor autolink charmap codesample colorpicker contextmenu fullscreen image link lists media paste tabfocus tabindex table table_default_props textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Heading 1', icon: 'h1', value: 'h1'},
                {text: 'Heading 2', icon: 'h2', value: 'h2'},
                {text: 'Heading 3', icon: 'h3', value: 'h3'},
                {text: 'Heading 4', icon: 'h4', value: 'h4'},
                {text: 'Heading 5', icon: 'h5', value: 'h5'},
                {text: 'Heading 6', icon: 'h6', value: 'h6'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

            toolbarList = 'format1 bold italic underline | forecolor backcolor format2 | align | bullist numlist | topic_link image2 media codesample table | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Admin: Edit ticket reply, signature, macro, rules, bulk ticket edit (no image manager, source)
        } else if (this.type === 'admin_ticket_edit') {
            plugins = ['anchor autolink charmap codesample colorpicker contextmenu fullscreen link lists media paste tabfocus tabindex table table_default_props textcolor'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Heading 1', icon: 'h1', value: 'h1'},
                {text: 'Heading 2', icon: 'h2', value: 'h2'},
                {text: 'Heading 3', icon: 'h3', value: 'h3'},
                {text: 'Heading 4', icon: 'h4', value: 'h4'},
                {text: 'Heading 5', icon: 'h5', value: 'h5'},
                {text: 'Heading 6', icon: 'h6', value: 'h6'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

            toolbarList = 'format1 bold italic underline | forecolor backcolor format2 | align | bullist numlist | topic_link image_simple media codesample table | undo redo code2';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Admin: Item reply add/edit
        } else if (this.type === 'admin_item') {
            plugins = ['anchor autolink charmap codesample colorpicker contextmenu fullscreen image link lists media paste tabfocus tabindex table'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

            toolbarList = 'format1 bold italic underline codeformat format2 | align | bullist numlist | topic_link image2 media codesample table | undo redo';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
		// Admin: Item add/edit
        } else if (this.type === 'full') {
            plugins = ['anchor autolink charmap codesample colorpicker contextmenu fullscreen image link lists media paste searchreplace spellchecker tabfocus tabindex table textcolor toc'];

            block_formats = [{text: 'Paragraph', icon: 'paragraph', value: 'p'},
                {text: 'Heading 1', icon: 'h1', value: 'h1'},
                {text: 'Heading 2', icon: 'h2', value: 'h2'},
                {text: 'Heading 3', icon: 'h3', value: 'h3'},
                {text: 'Heading 4', icon: 'h4', value: 'h4'},
                {text: 'Heading 5', icon: 'h5', value: 'h5'},
                {text: 'Heading 6', icon: 'h6', value: 'h6'},
                {text: 'Pre', icon: 'code', value: 'pre'},
                {text: 'Quote', icon: 'quote', value: 'blockquote'}];

            toolbarList = 'format1 bold italic underline | forecolor backcolor format2 |' +
            ' align | bullist numlist | ' +
            ' indent outdent | topic_link image2 ' +
            ' media codesample table format3 | undo redo | code2 searchreplace';

            if (!GUI.isMobileSafari || !GUI.isMobile) {
                toolbarList += ' |';
            }

            if (!GUI.isMobileSafari) {
                toolbarList += ' fullscreen';
            }

            if (!GUI.isMobile) {
                toolbarList += ' help2';
            }
        }

        if (this.hideToolbars === true) {
            toolbarList = false;
        }

        if (this.autoresize) {
            this.resizeToolbar = false;
            this.width = 'auto';
            plugins[0] += ' customautoresize';
        }

        url = window.assets_url || 'https://' + window.location.host + '/';
        url += 'js/library/tinymce';
        //url += '_4.0.7_dev/tinymce/js/tinymce';

        tinymce.baseURL = url;
        var self = this;
        var config = {
            selector: "div#" + this.editorId,
            name: 'name3',
            width: this.width,

            menubar: false,
            statusbar: this.resizeToolbar,
            browser_spellcheck: true,
            gecko_spellcheck: true,
            skin: false,
            content_css: this.cssForIframe,
            directionality: GUI.LTR ? 'ltr' : 'auto',

            link_context_toolbar: true,
            table_toolbar: "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",

            toc_header: 'hidden',
            toc_depth: 4,

            tabindex: this.tabIndex,

            table_default_props_table: {style: 'border-collapse: collapse;'},
            table_default_props_thead: {style: 'background-color: #eee;'},
            table_default_props_tbody: {},
            table_default_props_tfoot: {style: 'background-color: #fbfbfb;'},
            table_default_props_tr: {},
            table_default_props_td: {style: 'border: 1px solid #DDD; padding: 8px; vertical-align: top;'},

            convert_urls: this.baseHref ? true : false,
            relative_urls : this.baseHref ? false : true,
            remove_script_host : this.baseHref ? false : true,
            link_title: false,

            plugins: plugins,

            external_plugins: {
                "codemirror": window.app_url_no_locale + 'js/library/tinymce/plugins/codemirror/plugin.js'
            },
            // paste_data_images: true,
            // paste_enable_default_filters: false,
            paste_word_valid_elements: '-strong/b,-em/i,-u,-span,-p,-ol,-ul,-li,-h1,-h2,-h3,-h4,-h5,-h6,' +
                '-p/div,-a[href|name],sub,sup,strike,br,del,table[width],tr,' +
                'td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody,img[src|width|height|name]',

            toolbar: toolbarList,

            schema: 'html5',
            contextmenu: 'link topic_link inserttable | cell row column deletetable',

            auto_focus: this.autoFocus ? this.editorId : false,

            formats: {
                bold: {inline: 'b'},
                italic: {inline: 'i'}
            },

            toolbar_items_size: 'small',
            end_container_on_empty_block: true,
            language: languageTinyMce,

            codemirror: {
                indentOnInit: true,
                path: window.app_url_no_locale + 'js/library/tinymce/plugins/codemirror/CodeMirror',
                config: {
                    lineNumbers: true
                }
            },

            codesample_content_css: false,
            codesample_languages: [
                {text: 'Bash', value: 'bash'},
                {text: 'Basic', value: 'basic'},
                {text: 'C', value: 'c'},
                {text: 'C#', value: 'csharp'},
                {text: 'C++', value: 'cpp'},
                {text: 'CoffeeScript', value: 'coffeescript'},
                {text: 'CSS', value: 'css'},
                {text: 'Diff', value: 'diff'},
                {text: 'Docker', value: 'docker'},
                {text: 'Git', value: 'git'},
                {text: 'Go', value: 'go'},
                {text: 'HTML/XML', value: 'markup'},
                {text: 'ini', value: 'ini'},
                {text: 'Java', value: 'java'},
                {text: 'JavaScript', value: 'javascript'},
                {text: 'JSON', value: 'json'},
                {text: 'Less', value: 'less'},
                {text: 'Lua', value: 'lua'},
                {text: 'Markdown', value: 'markdown'},
                {text: 'Makefile', value: 'makefile'},
                {text: 'MATLAB', value: 'matlab'},
                {text: 'Nginx', value: 'nginx'},
                {text: 'Objective-C', value: 'objectivec'},
                {text: 'Pascal', value: 'pascal'},
                {text: 'Perl', value: 'perl'},
                {text: 'PowerShell', value: 'powershell'},
                {text: 'PHP', value: 'php'},
                {text: 'Python', value: 'python'},
                {text: 'Sass (scss)', value: 'scss'},
                {text: 'Scala', value: 'scala'},
                {text: 'Smarty', value: 'smarty'},
                {text: 'SQL', value: 'sql'},
                {text: 'Swift', value: 'swift'},
                {text: 'R', value: 'r'},
                {text: 'Ruby', value: 'ruby'},
                {text: 'TypeScript', value: 'typescript'},
                {text: 'YAML', value: 'yaml'}
            ],

            setup: function (editor) {
                editor.addButton('format1', {
                    type: 'listbox',
                    text: '',
                    icon: 'paragraph',
                    title: 'Format',
                    onselect: function (e) {
                        self.setIconFormatMenu();

                        try {
                            editor.execCommand('mceToggleFormat', false, this.value());
                        } catch(e) {}
                    },
                    values: block_formats,
                    onPostRender: function () {
                        self.addButton('format1', this);
                        this.value('p');
                        self.setIconFormatMenu();
                    }
                });

                editor.addButton('format2', {
                    type: 'listbox',
                    text: '',
                    icon: 'textstyle',
                    title: 'Format',
                    onselect: function (e) {
                        self.setIconFormat2Menu();
                        editor.execCommand('mceToggleFormat', false, this.value());
                    },
                    values: [
                        {text: 'Strikethrough', icon: 'strikethrough', value: 'strikethrough'},
                        {text: 'Superscript', icon: 'superscript', value: 'superscript'},
                        {text: 'Subscript', icon: 'subscript', value: 'subscript'},
                        {text: 'Code', icon: 'code', value: 'code'},
                        {text: 'Clear formatting', icon: 'removeformat', value: 'removeformat'},
                    ],
                    onPostRender: function () {
                        self.addButton('format2', this);
                        self.setIconFormat2Menu();
                    }
                });
                editor.addButton('format3', {
                    type: 'listbox',
                    text: '',
                    icon: 'plus',
                    onselect: function (e) {
                        self.setIconFormat2Menu('format3');

                        var value = this.value();

                        if (value === 'anchor') {
                            editor.buttons.anchor.onclick();

                        } else if (value === 'hr') {
                            editor.execCommand('mceInsertContent', false, '<hr />');

                        } else if (value === 'charmap') {
                            editor.buttons.charmap.onclick();

                        } else if (value === 'toc') {
                            editor.execCommand('mceInsertToc');
                        }
                    },
                    values: [
                        {text: 'Anchor', icon: 'anchor', value: 'anchor'},
                        {text: 'Table of Contents', icon: 'table-of-contents', value: 'toc'},
                        {text: 'Horizontal line', icon: 'hr', value: 'hr'},
                        {text: 'Special character', icon: 'charmap', value: 'charmap'}
                    ],
                    onPostRender: function () {
                        self.addButton('format3', this);
                        self.setIconFormat2Menu('format3');
                    }
                });

                editor.addButton('align', {
                    type: 'listbox',
                    text: '',
                    icon: 'alignleft',
                    title: 'Align',
                    onselect: function (e) {
                        self.setIconAlign2Menu();
                        editor.execCommand('mceToggleFormat', false, this.value());
                    },
                    values: [
                        {text: 'Align left', icon: 'alignleft', value: 'alignleft'},
                        {text: 'Align center', icon: 'aligncenter', value: 'aligncenter'},
                        {text: 'Align right', icon: 'alignright', value: 'alignright'},
                        {text: 'Justify', icon: 'justifyfull', value: 'alignjustify'},
                    ],
                    onPostRender: function () {
                        self.addButton('align', this);
                        self.setIconAlign2Menu();
                    }
                });

                this.addImageButton(editor);
                this.addTopicLinkInsert(editor);

                editor.addButton('code2', {
                    icon: 'code',
                    title: 'Source',
                    onclick: function (e) {
                        var i, l, containerEd, winds, fr, win, elWind,
                            doc, pDiv, exists_elems, btn_undo,
                            btn = e.target.parentNode,
                            editor = this.editor_tinymce;

                        if (this.clickOnCode) {
                            return;
                        }
                        this.clickOnCode = true;

                        if (this.sourceMode) {
                            this.sourceMode = false;
                            //this.toogleToolbarButtons('enable');

                        } else {
                            this.sourceMode = true;
                            //  this.toogleToolbarButtons('disable');
                        }
                        // for chrome
                        if (btn.nodeName.toLowerCase() !== 'div') {
                            btn = btn.parentNode;
                        }

                        containerEd = editor.contentAreaContainer;

                        if (!GUI.Dom.hasClass(btn, 'mce-active')) {
                            GUI.Dom.addClass(btn, 'mce-active');

                            editor.buttons.code.onclick();

                            winds = editor.windowManager.windows;
                            l = winds.length;

                            for (i = 0; i < l; i++) {
                                // old code window
                                if (winds[i].settings && winds[i].settings.body && winds[i].settings.body.name === 'code') {
                                    GUI.hide(winds[i].getEl());
                                    GUI.hide('mce-modal-block');
                                    this.clickOnCode = false;
                                }

                                if (winds[i].settings && winds[i].settings.url && winds[i].settings.url.search('source') !== -1) {
                                    GUI.hide(winds[i].getEl());
                                    GUI.hide('mce-modal-block');
                                    this.clickOnCode = false;
                                }

                                // code mirror window
                                if (winds[i].settings && winds[i].settings.url && winds[i].settings.url.search('codemirror') !== -1) {

                                    this.codeWindow = winds[i];
                                    win = this.codeWindow;
                                    elWind = win.getEl();

                                    GUI.hide(elWind);
                                    GUI.hide('mce-modal-block');

                                    GUI.Dom.addClass(containerEd, 'g-hide-offsets');

                                    if (containerEd.nextSibling && this.resizeToolbar) {
                                        GUI.hide(containerEd.nextSibling);
                                    }

                                    pDiv = document.createElement('DIV');
                                    pDiv.className = 'mce-source';

                                    fr = GUI.Dom.findDescedent(elWind, 'iframe');
                                    if (fr) {
                                        fr.setAttribute("frameborder", "0");
                                        fr.setAttribute("allowtransparency", "true");
                                        pDiv.appendChild(fr);

                                        GUI.Dom.setStyle(fr, {
                                            height: GUI.getFullHeight(editor.contentAreaContainer) - 1 + 'px',
                                            width: GUI.getFullWidth(editor.contentAreaContainer) + 'px'
                                        });

                                        if (this.autoresize) {
                                            fr.onload = function () {
                                                editor.fire('change_codemirror');
                                            };
                                        }
                                    }

                                    this.sourceIframe = fr;
                                    this.sourceContainer = pDiv;

                                    exists_elems = GUI.Dom.findDescedents(containerEd.parentNode, 'div.mce-source');
                                    if (exists_elems) {
                                        exists_elems.each(function (item) {
                                            item.parentNode.removeChild(item);
                                        });
                                    }

                                    containerEd.parentNode.appendChild(pDiv);

                                    window.focus();
                                }
                            }
                            this.toogleToolbarButtons('disable');
                        } else {
                            this.hideCodeEditor(btn);
                        }
                        this.clickOnCode = false;
                    }.bindLegacy(this)
                });

                editor.addButton('help2', {
                    icon: 'help',
                    title:  i18n('Help'),
                    onclick: function () {
                        this.showHelp();
                    }.bindLegacy(this)
                });

                editor.on('keyup', this.updateToolbar.bindLegacy(this, editor, 'keyup'));
                editor.on('click', this.updateToolbar.bindLegacy(this, editor, 'click'));

                editor.on('keyup', function () {
                    this.fireEvent('keyup');
                }.bindLegacy(this));

                editor.on('focus', function () {
                    this.inFocus = true;

                    var v = this.getValue(),
                        p = this.placeholder;

                    if (p) {
                        if (!v || (v && this.matchPlaceholderInValue(v, p))) {
                            this.editor_tinymce.setContent('');
                        }
                    }
                    this.current_value = v;

                    GUI.Utils.globalEvents.fireEvent(GUI.Utils.globalEvents.names.hidePopupElements);
                }.bindLegacy(this));

                editor.on('blur', function () {
                    this.inFocus = false;
                    if (this.placeholder && !this.inFocus) {
                        this.setPlaceholder(this.placeholder);
                    }

                    if (this.current_value !== this.getValue()) {
                        this.current_value = this.getValue();

                        // fire change event on blur
                        this.fireEvent('change', this, this.getValue());
                    }
                }.bindLegacy(this));

                editor.on('change', function (e) {
                    this.updateToolbar.call(this, editor, 'click'); // CHD-2956
                    this.fireEvent('keyup', e);
                    this.fireEvent('change', e);
                }.bindLegacy(this));

                editor.on('change_codemirror', function () {
                    if (this.autoresize) {
                        (function () {
                            var fr = this.editor_tinymce.editorContainer.querySelector('.mce-source iframe');

                            if (null === fr || this.fullscreenState) {
                                return;
                            }

                            if (fr.contentDocument.body) {
                                fr.contentDocument.body.style.overflow = 'hidden';
                            }

                            if (fr.contentDocument.body.offsetHeight) {
                                GUI.Dom.setStyle(fr, {
                                    height: fr.contentDocument.body.offsetHeight + 'px'
                                });
                            }
                        }).defer(0, this);
                    }
                }.bindLegacy(this));

                editor.on('click', function () {
                    GUI.Utils.globalEvents.fireEvent(GUI.Utils.globalEvents.names.hidePopupElements);
                });

                editor.on('mouseenter', function () {
                    GUI.Utils.globalEvents.fireEvent(GUI.Utils.globalEvents.names.tinymceMouseEnter);
                });

                editor.on('mouseleave', function () {
                    GUI.Utils.globalEvents.fireEvent(GUI.Utils.globalEvents.names.tinymceMouseLeave);
                });

                editor.on('init', function () {
                    if (this.initialize === null) {
                        editor.destroy();
                        return;
                    }

                    this.isReady = true;
                    editor.dom.doc.documentElement.className = 'b-wysiwyg__iframe_preview';

                    if (this.iframeBodyClass) {
                        GUI.addClass(editor.dom.doc.body, this.iframeBodyClass);
                    }

                    if (this.autoresize) {
                        GUI.addClass(editor.dom.doc.body, 'autoresize');
                    }

                    if (this.value) {
                        editor.setContent(this.value);

                    } else if (this.placeholder) {
                        //editor.setContent(this.placeholder);
                        this.setPlaceholder(this.placeholder);
                    }

                    this.updateToolbar();

                    if (this.hideToolbars === true) {
                        this.dom.addClass('b-wysiwyg_super-simple');
                    }

                    this.setHeight(this.height);

                    if (this.pointerEvents) {
                        jQuery(this.dom).find('iframe').css({
                            pointerEvents: this.pointerEvents
                        });
                    }

                    if (document.body.dispatchEvent) {
                        jQuery(this.dom).find('iframe')[0].contentWindow.addEventListener('dragenter', function (e) {
                            document.body.dispatchEvent(new DragEvent(e.type, e));
                        });

                        jQuery(this.dom).find('iframe')[0].contentWindow.addEventListener('dragleave', function (e) {
                            document.body.dispatchEvent(new DragEvent(e.type, e));
                        });

                        jQuery(this.dom).find('iframe')[0].contentWindow.addEventListener('dragover', function (e) {
                            document.body.dispatchEvent(new DragEvent(e.type, e));
                        });

                    }


                    this.fireEvent('afterRenderEditor', this, editor);
                }.bindLegacy(this));

                editor.on('SetContent', function () {
                    this.sourceBody.value = this.getValue();
                }.bindLegacy(this));

                editor.on('FullscreenStateChanged', this.setHeightForFullScreen.bindLegacy(this));

                editor.on('paste', function (e) {
                    if(e.clipboardData === undefined) {
                        return;
                    }

                    if (!this.insertImage) {
                        return;
                    }

                    if (
                        e.clipboardData.files.length &&
                        (event.clipboardData || window.clipboardData).types.indexOf('text/rtf') === -1
                    ) {
                        var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                        pl.editor = editor;
                        pl.customLoadImage(e.clipboardData.files, function (fileUrl) {
                            this.fireEvent('keyup', {});
                            this.fireEvent('change', {});
                            setTimeout(function () {
                                editor.fire('keyup', {});
                            }, 100);
                        }.bindLegacy(this));

                        e.preventDefault();
                        return false;
                    } else {
                        var rtfRecords = self.getRtfRecords();
                        var rtfRecordCaret = -1;

                        setTimeout(function () {
                            jQuery(this.editor_tinymce.getBody()).find('img').each(function (i, img) {
                                img = jQuery(img);

                                if (img.attr('src').indexOf('file://') === 0) {
                                    rtfRecordCaret += 1;
                                    img.attr('src', rtfRecords[rtfRecordCaret]);
                                }

                                if (img.attr('src').indexOf('data:') === 0) {
                                    if (!img.attr('name')) {
                                        img.attr('name', 'Image_' + _.uniqueId());
                                    }

                                    var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                                    pl.editor = editor;
                                    pl.uploadBase64Image(
                                        img.attr('src'),
                                        img.attr('name')
                                    ).then(function (data) {
                                        var i;

                                        for (i = 0; i < data.list.length; i++) {
                                            if ((img.attr('name') + '.' + data.extension) === data.list[i].name) {
                                                img.attr('src', data.list[i].path);
                                                img.attr('data-mce-src', data.list[i].path);
                                                break;
                                            }
                                        }
                                    }).catch(function (error) {

                                    });
                                } else if (img.attr('src').indexOf('blob:') === 0) {
                                    var xhr = new XMLHttpRequest;
                                    xhr.responseType = 'blob';
                                    xhr.onload = function() {
                                       var reader = new FileReader;

                                       reader.onload = function() {
                                            var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                                            pl.editor = editor;
                                            pl.uploadBase64Image(
                                                reader.result,
                                                img.attr('name')
                                            ).then(function (data) {
                                                var i;

                                                for (i = 0; i < data.list.length; i++) {
                                                    if ((img.attr('name') + '.' + data.extension) === data.list[i].name) {
                                                        img.attr('src', data.list[i].path);
                                                        img.attr('data-mce-src', data.list[i].path);
                                                        break;
                                                    }
                                                }
                                            }).catch(function (error) {

                                            });

                                       }.bindLegacy(this);

                                       reader.readAsDataURL(xhr.response);
                                    }.bindLegacy(this);

                                    xhr.open('GET', img.attr('src'));
                                    xhr.send();
                                }
                            }.bindLegacy(this));
                        }.bindLegacy(this), 50);
                    }

                    setTimeout(function () {
                        jQuery(editor.dom.doc.body).find('*[data-sheets-value]').removeAttr('data-sheets-value');
                    }, 100);

                }.bindLegacy(this));

                editor.on('drop', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!this.insertImage) {
                        errorInfo(i18n('Image upload is not allowed here'));
                        return;
                    }

                    jQuery(document.body)
                        .removeClass('dz-drag-hover dz-drag-files')
                        .find(".dz-drag-hover,.dz-drag-start")
                            .removeClass('dz-drag-hover dz-drag-files');

                    if (e.dataTransfer && e.dataTransfer.files.length) {
                        var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
                        pl.editor = editor;
                        pl.customLoadImage(e.dataTransfer.files, function (fileUrl) {
                            this.fireEvent('keyup', {});
                            this.fireEvent('change', {});
                            editor.fire('keyup');
                        }.bindLegacy(this));
                    }
                }.bindLegacy(this));

                this.editor_tinymce = editor;

                this.hotkeys = new HotKeys(editor);
                this.hotkeys.on(new Keys(
                    [KEY.esc],
                    null,
                    () => this.removeFocus()
                ));
            }.bindLegacy(this)
        };

        tinymce.init(config);
    };

    GUI.Forms.EditorTinymce.prototype.getRtfRecords = function () {
        var rtfData = (event.clipboardData || window.clipboardData).getData('text/rtf');
        if ( !rtfData ) {
            return [];
        }

        var _convertHexToBase64 = function( hexString ) {
            return btoa( hexString.match( /\w{2}/g ).map( function (char) {
                return String.fromCharCode( parseInt( char, 16 ) );
            }.bindLegacy(this) ).join( '' ) );
        };

        var regexPictureHeader = /{\\pict[\s\S]+?\\bliptag-?\d+(\\blipupi-?\d+)?({\\\*\\blipuid\s?[\da-fA-F]+)?[\s}]*?/;
        var regexPicture = new RegExp( '(?:(' + regexPictureHeader.source + '))([\\da-fA-F\\s]+)\\}', 'g' );
        var images = rtfData.match( regexPicture );
        var result = [];

        if ( images ) {
            for ( var i in images ) {
                if (!images.hasOwnProperty(i)) {
                    continue;
                }
                var image = images[i];
                let imageType = false;

                if ( image.indexOf( '\\pngblip' ) !== -1 ) {
                    imageType = 'image/png';
                } else if ( image.indexOf( '\\jpegblip' ) !== -1) {
                    imageType = 'image/jpeg';
                }

                if ( imageType ) {
                    result.push(
                        'data:' + imageType + ';base64,' +
                        _convertHexToBase64(image.replace( regexPictureHeader, '' ).replace( /[^\da-fA-F]/g, '' ))
                    );
                }
            }
        }

        return result;
    };

    GUI.Forms.EditorTinymce.prototype.insertImagesFromFiles = function (files) {
        var pl = this.getPlugin(GUI.Forms.Plugins.EditorImageManager);
        pl.editor = this.editor_tinymce;
        pl.customLoadImage(files, function (fileUrl) {
            this.fireEvent('keyup', {});
            this.fireEvent('change', {});
            this.editor_tinymce.fire('keyup');
        }.bindLegacy(this));
    };

    GUI.Forms.EditorTinymce.prototype.hideCodeEditor = function (btn) {
        var doc;

        if (!this.clickOnCode) {
            return;
        }

        var editor = this.editor_tinymce,
            containerEd = editor.contentAreaContainer;

        if (btn === undefined) {
            btn = document.querySelector('i.mce-i-code').parentNode.parentNode;
        }

        editor.focus(true);
        GUI.Dom.removeClass(btn, 'mce-active');
        GUI.Dom.removeClass(containerEd, 'g-hide-offsets');

        if (containerEd.nextSibling && this.resizeToolbar) {
            GUI.show(containerEd.nextSibling);
        }

        if (this.sourceContainer) {
            GUI.Dom.addClass(this.sourceContainer, 'g-hide-offsets');
        }

        doc = document.querySelectorAll('div.mce-source>iframe')[0];
        if (doc && doc.contentWindow) {
            doc.contentWindow.submit();
        }

        if (this.codeWindow && !this.fullscreenState) {
            this.codeWindow.close();
            this.codeWindow = false;
        }

        if (this.sourceContainer && this.sourceContainer.parentNode) {
            this.sourceContainer.parentNode.removeChild(this.sourceContainer);
        }

        this.toogleToolbarButtons('enable');

        var btn_undo = GUI.Dom.findDescedent(editor.contentAreaContainer.previousSibling, 'i.mce-i-undo');
        if (btn_undo) {
            btn_undo = btn_undo.parentNode.parentNode;
            GUI.Dom[this.undoClass + 'Class'](btn_undo, 'mce-disabled');
        }
        if (editor.codemirrorHistorySize && editor.codemirrorHistorySize.undo > 2) {
            editor.undoManager.add();
            GUI.Dom.removeClass(btn_undo, 'mce-disabled');
        }

        this.fireEvent('change');
    };

    /**
     *
     * @param {type} fast
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.onDestroy = function (fast) {
        if (this.isReady) {
            this.editor_tinymce.execCommand('mceRemoveControl', true);
        }

        if (this.isReady) {
            this.editor_tinymce.remove();
            this.editor_tinymce.destroy();
        }

        if (this.hotkeys) {
            this.hotkeys.destroy();
            this.hotkeys = null;
        }

        superproto.onDestroy.call(this, fast);
    };

    /**
     *
     * @param {String} action
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.toogleToolbarButtons = function (action) {
        var i, item, items,
            actionForClass = (action === 'disable') ? 'add' : 'remove',
            cont = this.editor_tinymce.contentAreaContainer,
            toolbar = cont.previousSibling,
            toolbarHolder = GUI.Dom.findDescedent(toolbar, 'div.mce-container-body.mce-flow-layout'),
            toolbars = toolbarHolder.childNodes;

        if (action === 'disable') {
            toolbar.on('click', this.onClickOnDisabledToolbar, this);
        } else {
            toolbar.un('click', this.onClickOnDisabledToolbar, this);
        }

        if (toolbars && toolbars.length) {
            for (i = 0; i < toolbars.length; i++) {
                item = toolbars[i];

                items = GUI.Dom.findDescedents(item, 'div.mce-btn');

                items.each(function (it) {
                    var attr = it.getAttribute('aria-label');

                    if (actionForClass === 'add') {
                        if (attr === 'Undo') {
                            this.undoClass = (GUI.Dom.hasClass(it, 'mce-disabled')) ? 'add' : 'remove';
                        }

                        if (attr === 'Redo') {
                            this.redoClass = (GUI.Dom.hasClass(it, 'mce-disabled')) ? 'add' : 'remove';
                        }
                    }

                    if (attr !== 'Source' && attr !== 'Fullscreen') {
                        GUI.Dom[actionForClass + 'Class'](it, 'mce-disabled');
                    }

                    if (actionForClass === 'remove') {
                        if (attr === 'Undo') {
                            GUI.Dom[this.undoClass + 'Class'](it, 'mce-disabled');
                        }

                        if (attr === 'Redo') {
                            GUI.Dom[this.redoClass + 'Class'](it, 'mce-disabled');
                        }
                    }
                }.bindLegacy(this));
            }
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.onClickOnDisabledToolbar = function (e) {
        var t, p;

        e = GUI.Event.extend(e);
        t = e.getTarget();
        p = t.parentNode;

        if (p.nodeName.toLowerCase() !== 'div') {
            p = p.parentNode;
        }

        if (p.getAttribute('aria-label') !== 'Source' && p.getAttribute('aria-label') !== 'Fullscreen') {
            e.stop();
        }
    };

    /**
     *
     * @param {type} obj
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setHeightForFullScreen = function (obj) {
        if (!this.editor_tinymce.contentAreaContainer) {
            return;
        }

        var heightSourceFr,
            editor = this.editor_tinymce,
            hDocument = GUI.getViewportHeight(),
            hToolbar = editor.contentAreaContainer.previousSibling ?
                GUI.getFullHeight(editor.contentAreaContainer.previousSibling) :
                0;

        if (obj.state) {
            this.fullscreenState = true;
            heightSourceFr = hDocument - hToolbar - 1 + 'px';
            editor.contentAreaContainer.firstChild.style.height = heightSourceFr;

        } else {
            this.fullscreenState = false;
            heightSourceFr = GUI.getFullHeight(editor.contentAreaContainer) + 'px';
        }

        if (this.sourceContainer) {
            GUI.Dom.setStyle(this.sourceContainer.firstChild, {
                height: heightSourceFr
            });
        }
    };

    /**
     *
     * @param {type} name
     * @param {type} obj
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.addButton = function (name, obj) {
        this.buttons[name] = obj;
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setIconFormatMenu = function () {
        var btn = this.buttons.format1,
            value = btn.value(),
            icon = '',
            el = GUI.$(btn._id), sp, ic;

        if (!el) { // For quick create and bug with CHD-2956
            return;
        }

            sp = el.childNodes[0].querySelector('.mce-txt'),
            ic = GUI.Dom.findDescedent(el, 'i');

        if (value === 'p') {
            icon = 'paragraph';
        } else if (value === 'pre') {
            icon = 'code';
        } else if (value === 'blockquote') {
            icon = 'quote';
        } else {
            icon = value;
        }

        if (sp) {
            GUI.remove(sp);
        }

        GUI.Dom.insertBefore(el.childNodes[0].childNodes[2], '<span class="mce-txt"></span>');
        ic.className = 'mce-ico mce-i-' + icon;
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setIconFormat2Menu = function (btn_) {
        var el, sp,
            btn = btn_ || 'format2';

        btn = this.buttons[btn];
        el = GUI.$(btn._id);
        sp = el.childNodes[0].querySelector('.mce-txt');

        GUI.remove(sp);
        GUI.Dom.insertBefore(el.childNodes[0].childNodes[2], '<span class="mce-txt"></span>');
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setIconAlign2Menu = function (btn_) {
        var btn = this.buttons.align,
            value = btn.value(),
            icon = '',
            el = GUI.$(btn._id), sp, ic;

        if (!el) { // For quick create and bug with CHD-2956
            return;
        }

        sp = el.childNodes[0].querySelector('.mce-txt');
        ic = GUI.Dom.findDescedent(el, 'i');

        if (value === 'alignjustify') {
            icon = 'justifyfull'
        } else{
            icon = value;
        }


        if (sp) {
            GUI.remove(sp);
        }

        GUI.Dom.insertBefore(el.childNodes[0].childNodes[2], '<span class="mce-txt"></span>');
        ic.className = 'mce-ico mce-i-' + icon;
    };


    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.sourceToCode = function () {
        var doc = document.querySelectorAll('div.mce-source>iframe')[0];
        doc.contentWindow.submit();
    };

    /**
     *
     * @param {type} picker
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.updateToolbar = function (editor_, eventName, e) {
        var selection, dom, selectedElm, el, tagName, editor;

        editor = editor_ || this.editor_tinymce;

        selection = editor.selection;
        dom = editor.dom;

        selectedElm = selection.getNode();
        if (this.buttons && this.buttons.align) {
            var alignCss = jQuery(selectedElm).closest('[data-mce-style]').attr('data-mce-style');

            if (!alignCss) {
                this.buttons.align.value('alignleft');
            } else if (alignCss.indexOf('right;') !== -1) {
                this.buttons.align.value('alignright');
            } else if (alignCss.indexOf('center;') !== -1) {
                this.buttons.align.value('aligncenter');
            } else if (alignCss.indexOf('justify;') !== -1) {
                this.buttons.align.value('alignjustify');
            } else {
                this.buttons.align.value('alignleft');
            }

            this.setIconAlign2Menu();
        }

        // format style combo
        if (this.buttons && this.buttons.format1) {
            tagName = 'p';
            el = dom.getParent(selectedElm, 'p,h1,h2,h3,h4,h5,h6,pre,blockquote');

            if (el) {
                tagName = el.nodeName.toLowerCase();

                // for blockquote
                el = dom.getParent(el, 'blockquote');
                if (el) {
                    tagName = el.nodeName.toLowerCase();
                }
            }
            this.buttons.format1.value(tagName);
            this.setIconFormatMenu();
        }

        el = dom.getParent(selectedElm, 'img');

        if (e && e.target && e.target.nodeName === 'IMG') {
            el = e.target;
            this.hideImageLayer(false);
        }

        if (el && !GUI.Dom.hasClass(el, 'mce-object-iframe')) {

            if (el) {
                this.showImageLayer(el);
            } else {
                this.hideImageLayer(false);
            }

        } else {
            this.hideImageLayer(false);
        }

        this.sourceBody.value = this.getValue();

        if (eventName === 'keyup') {
            this.fireEvent('keyup', e);
        }

    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setPlaceholder = function (placeholder) {
        if (!this.isReady) {
            this.placeholder = placeholder;
            return;
        }

        var v = this.getValue();

        this.placeholder = placeholder || this.placeholder;

        if (v === '' && !this.inFocus) {
            //this.setValue('<span style="color: #888;">' + this.placeholder + '</span>');
            this.editor_tinymce.setContent('<span style="color: #888;">' + this.placeholder + '</span>');
        }
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.createLink = function (obj) {
        this.editor_tinymce.buttons.link.onclick();
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.unlink = function () {
        var editor, selection, dom, selectedElm, anchorElm, href;

        editor = this.editor_tinymce;
        selection = this.editor_tinymce.selection;
        dom = editor.dom;

        selectedElm = selection.getNode();
        anchorElm = dom.getParent(selectedElm, 'a[href]');
        href = anchorElm ? dom.getAttrib(anchorElm, 'href') : '';

        if (href !== '') {
            editor.execCommand('unlink');
            return;
        }
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype._updatePosition = function (node, region, toolbar) {
        var x, y, h, w, pos, editor, posEditor, pointerX, pointerY, vw, vh,
            scrollDoc, docBody, cls;

        editor = this.editor_tinymce;
        pos = GUI.getPosition(node);

        posEditor = GUI.getPosition(editor.contentAreaContainer);

        pointerX = pos[0];
        pointerY = pos[1];

        vw = GUI.getFullWidth(editor.contentAreaContainer);
        vh = GUI.getFullHeight(editor.contentAreaContainer);

        scrollDoc = GUI.getScroll(GUI.getBody());
        docBody = editor.getDoc().body;

        w = region.dom.offsetWidth;
        h = region.dom.offsetHeight;

        pointerX -= scrollDoc.left;
        pointerY -= scrollDoc.top;

        if (!scrollDoc.left && docBody.scrollLeft) {
            pointerX -= docBody.scrollLeft;
        }

        if (!scrollDoc.top && docBody.scrollTop) {
            pointerY -= docBody.scrollTop;
        }

        //
        if (pointerY + h + 25 > vh) {
            y = pointerY - h;
            cls = 'b-arrow_bottom';
        } else {
            y = pointerY + 25;
            cls = 'b-arrow_top';
        }
        y += posEditor[1];

        if (pointerX + w > vw) {
            x = pointerX - w + 45;
            cls += '_right';
        } else {
            x = pointerX;
            cls += '_left';
        }
        x += posEditor[0];

        region.dom.className = 'b-layer b-arrow ' + cls;
        region.setPosition(x, y);
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.showImageLayer = function (node) {
        var region, toolbar, widthInp;

        if (this.regionImageLayer) {
            this.regionImageLayer.destroy();
            this.regionImageLayer = false;
        }

        region = new GUI.Popup.Region({
            className: 'b-layer b-arrow b-arrow_top_left'
        });
        region.show();

        toolbar = new GUI.ToolBar({
            //type    : 'hint',
            elements: [{
                name: 'text-width',
                obj: new GUI.Element({
                    html: '<input class="b-text-field b-wysiwyg__link-inp" ' +
                    'type="text" id="' + this.id + '-width-img" name="" ' +
                    'hint="' + i18n('Resize image to a specific width (keep aspect ratio)') + '" ' +
                    'style="width: 42px;" value="' + node.width + '" /> px &nbsp;',
                    hint: 'Resize image to a specific width (keep aspect ratio)'
                })
            }, {
                name: 'groups1',
                obj: new GUI.ToolBar.ButtonGroups({
                    singleToggle: true,
                    items: [
                        new GUI.ToolBar.ToggleButton({
                            iconClass: 'x-editor-button x-editor-button-image-small',
                            hint: 'Resize image to small and link to the original',
                            toggled: (node.width === 100),
                            onClick: function () {
                                node.width = 100;
                                node.removeAttribute('height');
                                widthInp.value = 100;
                            }.bindLegacy(this)
                        }),
                        new GUI.ToolBar.ToggleButton({
                            iconClass: 'x-editor-button x-editor-button-image-medium',
                            hint: 'Resize image to medium and link to the original',
                            toggled: (node.width === 300),
                            onClick: function () {
                                node.width = 300;
                                node.removeAttribute('height');
                                widthInp.value = 300;
                            }.bindLegacy(this)
                        }),
                        new GUI.ToolBar.ToggleButton({
                            iconClass: 'x-editor-button x-editor-button-image-large',
                            hint: 'Resize image to large and link to the original',
                            toggled: (node.width === 500),
                            onClick: function () {
                                node.width = 500;
                                node.removeAttribute('height');
                                widthInp.value = 500;
                            }.bindLegacy(this)
                        }),
                        new GUI.ToolBar.ToggleButton({
                            caption: 'Original',
                            hint: 'Reset image size to original',
                            toggled: (node.width === node.getAttribute('originalWidth')),
                            onClick: function () {
                                node.removeAttribute('width');
                                node.removeAttribute('height');
                                widthInp.value = node.width;
                            }.bindLegacy(this)
                        })
                    ]
                })
            }, {
                name: 'border',
                obj: new GUI.ToolBar.ToggleButton({
                    caption: 'Border',
                    hint: 'Display a border around image',
                    toggled: (node.border === 1) ? true : false,
                    onClick: function () {
                        if (this.borderBtn.toggled) {
                            node.border = 1;
                        } else {
                            node.border = 0;
                        }
                    }.bindLegacy(this)
                })
            }, {
                name: 'link',
                obj: new GUI.ToolBar.Button({
                    caption: 'Link',
                    hint: 'Link this image to other content',
                    autoDisable: false,
                    hidden: (node.parentNode.nodeName.toLowerCase() === 'a') ? true : false,
                    onClick: function () {
                        this.createLink({linkName: node.getAttribute('nameImg'), imgNode: node});
                        this.hideImageLayer();
                    }.bindLegacy(this)
                })
            }, {
                name: 'editlink',
                obj: new GUI.ToolBar.Button({
                    caption: 'Edit Link',
                    hint: 'Edit Link',
                    autoDisable: false,
                    hidden: (node.parentNode.nodeName.toLowerCase() === 'a') ? false : true,
                    onClick: function () {
                        this.createLink({linkName: node.getAttribute('nameImg'), imgNode: node});
                        this.hideImageLayer();
                    }.bindLegacy(this)
                })
            }, {
                name: 'unlink',
                obj: new GUI.ToolBar.Button({
                    caption: 'Unlink',
                    hint: 'Delete link',
                    autoDisable: false,
                    hidden: (node.parentNode.nodeName.toLowerCase() === 'a') ? false : true,
                    onClick: function () {
                        this.unlink();
                        this.hideImageLayer();
                    }.bindLegacy(this)
                })
            }]
        });
        toolbar.render(region.dom);
        widthInp = GUI.$(this.id + '-width-img');

        // buttons
        this.borderBtn = toolbar.getElement('border');

        this._updatePosition(node, region, toolbar);
        this.regionImageLayer = region;

        widthInp.on('change', this.onChangeWidthImg.bindLegacy(this, node, widthInp));
        document.on('mousedown', this.hideImageLayer, this);
        document.on('scroll', this.hideImageLayer.bindLegacy(this, false));
        GUI.Event.on(this.editor_tinymce.getDoc(), 'scroll', this.hideImageLayer.bindLegacy(this, false));
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.hideImageLayer = function (e) {
        if (e) {
            e = GUI.Event.extend(e);
        }
        if (GUI.$(this.id + '-width-img')) {
            GUI.$(this.id + '-width-img').un('change', this.onChangeWidthImg, this);
        }
        if (this.regionImageLayer && (!e || (e && e.target.within && !e.target.within(this.regionImageLayer.getDom())))) {
            this.regionImageLayer.hide();
            try {
                document.un('mousedown', this.regionImageLayer, this);
                document.un('scroll', this.hideImageLayer.bindLegacy(this, false));
                GUI.Event.un(this.editor_tinymce.getDoc(), 'scroll', this.hideImageLayer.bindLegacy(this, false));
            } catch (e_) {
            }
        }
    };

    /**
     *
     */
    GUI.Forms.EditorTinymce.prototype.onChangeWidthImg = function (img, inp, e) {
        img.width = inp.value;
    };

    GUI.Forms.EditorTinymce.prototype.getTinymceTranslation = function (text) {
        return tinymce.i18n.translate(text);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.showHelp = function () {
        var dlg = new GUI.Popup.Dialog({
            title: i18n('Hotkeys that work with Ctrl or &#8984;'),
            modal: true,
            dimensions: {width: 800},
            content: '<div class="default-data-layout">' +
            '<div class="default-data-layout__row">' +
            //'<h2>' + i18n('Hotkeys that work with Ctrl or &#8984;') + '</h2>' +
            '<div class="default-data-layout__column">' +
            '<table class="b-forms__table b-forms__table_min">' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Bold") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">B</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Italic") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">I</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Underline") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">U</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Insert Link") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">L</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Undo") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">Z</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Redo") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">Y</span></button>' +
            '</td>' +
            '</tr>' +

            '</table>' +
            '</div>' +

            '<div class="default-data-layout__column">' +
            '<table class="b-forms__table b-forms__table_min">' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Copy") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">C</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Cut") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">X</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Paste") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">V</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Select All") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">A</span></button>' +
            '</td>' +
            '</tr>' +

            '<tr class="b-forms__row">' +
            '<td class="b-forms__cell label">' +
            '<label for="space_name" class="b-forms__label">' +
                this.getTinymceTranslation("Paragraph") +
            '</label>' +
            '</td>' +
            '<td class="b-forms__cell">' +
            '<button class="b-button b-button_passive"><span class="b-button__label">0</span></button>' +
            '</td>' +
            '</tr>' +

            '</table>' +
            '</div>' +

            '</div>' +

            '</div>',
            alwaysOnTop: true
        });
        dlg.create();
        dlg.show();
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.showSpellChecker = function () {
        var dlg = new GUI.Popup.Dialog({
            title: 'Spell Check',
            modal: true,
            dimensions: {width: 400},
            content: '<div class="default-data-layout">Spell checking function is available in all popular modern browsers. ' +
            'Built-in spell checkers of Firefox, Chrome, Safari, Opera, and ' +
            'Internet Explorer 10 work great here. Please refer to the ' +
            '<a href="http://www.web-site-scripts.com/knowledge-base/article/AA-00454/68/How-to-spellcheck-articles.html" ' +
            'target="_blank">spell checking guide</a>' +
            ' if you would like to know how to enable spell checking in your browser or how to use it.</div>',
            alwaysOnTop: true
        });
        dlg.create();
        dlg.show();
    };

    /**
     * Find replace click
     */
    GUI.Forms.EditorTinymce.prototype.onFindReplaceClick = function () {
        this.editor_tinymce.execCommand('SearchReplace');
    };

    /**
     *
     * @param {type} value
     * @param {type} silent
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setValue = function (value, silent) {
        if (this.isReady) {
            superproto.setValue.call(this, value, silent);

            if (value) {
                this.editor_tinymce.setContent(value);
            } else {
                this.editor_tinymce.setContent('');
                this.setPlaceholder();
            }

            // unbelievable hack  CHD-1798 (last comment)
            this.editor_tinymce.undoManager.clear();
            this.editor_tinymce.undoManager.add();
        } else {
            this.value = value;
        }
    };

    /**
     *
     * @param value
     * @param silent
     */
    GUI.Forms.EditorTinymce.prototype.insertContent = function (value, silent) {
        if (this.getValue() == '') {
            this.setValue(value, silent);
        } else {
            this.editor_tinymce.insertContent(value);
        }

        this.fireEvent('keyup', {});
        this.fireEvent('change', {});
    };

    /**
     *
     * @param {type} value
     * @param {type} silent
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.getValue = function () {
        var imgs, im, i,
            value = this.value || '';

        if (this.editor_tinymce && this.editor_tinymce.dom && this.editor_tinymce.dom.doc) {
            imgs = GUI.Dom.findDescedents(this.editor_tinymce.dom.doc.body, 'img');
            for (i = 0; i < imgs.length; i++) {
                im = imgs[i];
                if (im.alt && im.alt === 'clearscheme') {
                    im.setAttribute('src', im.src.replace(/http[s]?:/, ''));
                    im.setAttribute('data-mce-src', im.src.replace(/http[s]?:/, ''));
                    im.setAttribute('alt', '');
                }
            }
        }

        if (this.isReady && this.editor_tinymce && this.editor_tinymce.getContent) {
            value = this.editor_tinymce.getContent().trim();
        }

        if (this.trimEmptyBlocks) {
            value = value
                .replace(/(<p([^>]{0,})?>(&nbsp;)?<\/p>\s{0,}){2,}/gmi, '')
                .replace(/<p([^>]{0,})?>(&nbsp;)?<\/p>$/gmi, '');
        }

        if (this.matchPlaceholderInValue(value, this.placeholder)) {
            return '';

        }

        if (!GUI.html_entity_decode(value.strip_tags('<img><video><iframe><embed>')).trim()) {
            return '';
        }

        return value;
    };

    /**
     *
     * @param {type} value
     * @param {type} silent
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.setHeight = function (value_) {
        var editor, content, hToolbar,
            value = parseInt(value_, 10);

        superproto.setHeight.call(this, value);

        editor = this.editor_tinymce;
        content = editor.contentAreaContainer;

        if (!editor || !content) {
            return;
        }

        hToolbar = content.previousSibling ? GUI.getFullHeight(content.previousSibling) : 0;

        value = value - hToolbar - 1 - 2; // 1 for line in th end // 2 for top and bottom border

        content.firstChild.style.height = value + 'px';

        if (this.fullscreenState) {
            this.setHeightForFullScreen({state: true});

        } else {
            if (this.sourceIframe && this.sourceMode) {
                this.sourceIframe.style.height = value + 'px';
            }
        }
    };

    /**
     *
     * @returns {Boolean}
     */
    GUI.Forms.EditorTinymce.prototype.validate = function () {
        var l,
            value = this.getValue(),
            minlength = this.config.validation ? this.config.validation.minlength : 0;

        value = value.replace(/&nbsp;/g, '');

        value = value.strip_tags('<img>');

        value = value.trim();

        l = value.length;

        if (this.placeholder && this.matchPlaceholderInValue(value, this.placeholder)) {
            l = 0;
        }

        return (l >= minlength);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.EditorTinymce.prototype.matchPlaceholderInValue = function (v, p) {
        if (!v || !p) {
            return false;
        }
        v = GUI.html_entity_decode(v.strip_tags()).trim();

        p = GUI.html_entity_decode(p.strip_tags()).trim();

        if (v !== p) {
            return false;
        } else {
            return true;
        }
    };

    GUI.Forms.EditorTinymce.prototype.focus = function () {
        if (this.isReady) {
            this.editor_tinymce.focus()

        } else {
            var fnOnRendered = function () {
                this.editor_tinymce.focus();
                this.un('afterRenderEditor', fnOnRendered, this);
            }.bindLegacy(this);

            this.on('afterRenderEditor', fnOnRendered, this);
        }
    };

    GUI.Forms.EditorTinymce.prototype.removeFocus = function () {
        this.editor_tinymce.iframeElement.blur();
    };

    GUI.Forms.EditorTinymce.prototype.waitForInit = function () {
        return new Promise((resolve, reject) => {
            let intervalNumber = 0;

            let interval = setInterval(() => {
                if (this.isReady) {
                    clearInterval(interval);
                    resolve();
                } else if (intervalNumber >= 50) {
                    clearInterval(interval);
                    reject();
                } else {
                    intervalNumber += 1;
                }

            }, 100);
        });
    }
}());