/**
 * @class GUI.Utils.Collection
 * @extends GUI.Utils.Observable
 * A Collection class that maintains both numeric indexes and keys and exposes events.
 */
GUI.Utils.Collection = Class.create();

var superproto = GUI.Utils.Observable.prototype;

Object.extend(GUI.Utils.Collection.prototype, superproto);

/**
 * Constructor
 * @param {Boolean} allowFunctions True if the addAll function should add function references to the
 * collection (defaults to false)
 * @param {Function} keyFn A function that can accept an item of the type(s) stored in this MixedCollection
 * and return the key value for that item.  This is used when available to look up the key on items that
 * were passed without an explicit key parameter to a MixedCollection method.  Passing this parameter is
 * equivalent to providing an implementation for the {@link #getKey} method.
 */
GUI.Utils.Collection.prototype.initialize = function (allowFunctions, keyFn) {
    this.allowFunctions = false;
    this.items = [];
    this.map = {};
    this.keys = [];
    this.length = 0;
    this.addEvents({
        /**
         * @event clear
         * Fires when the collection is cleared.
         */
        "clear" : true,
        /**
         * @event add
         * Fires when an item is added to the collection.
         * @param {Number} index The index at which the item was added.
         * @param {Object} o The item added.
         * @param {String} key The key associated with the added item.
         */
        "add" : true,
        /**
         * @event replace
         * Fires when an item is replaced in the collection.
         * @param {String} key he key associated with the new added.
         * @param {Object} old The item being replaced.
         * @param {Object} new The new item.
         */
        "replace" : true,
        /**
         * @event remove
         * Fires when an item is removed from the collection.
         * @param {Object} o The item being removed.
         * @param {String} key (optional) The key associated with the removed item.
         */
        "remove" : true,
        "sort" : true
    });
    this.allowFunctions = allowFunctions === true;
    if (keyFn) {
        this.getKey = keyFn;
    }
    GUI.Utils.Observable.prototype.initialize.call(this);
};

/**
* Adds an item to the collection. Fires the {@link #add} event when complete.
* @param {String} key The key to associate with the item
* @param {Object} o The item to add.
* @returns {Object} The item added.
*/
GUI.Utils.Collection.prototype.add = function (key, o) {
    if (arguments.length === 1) {
        o = arguments[0];
        key = this.getKey(o);
    }
    if (typeof key === "undefined" || key === null) {
        this.length++;
        this.items.push(o);
        this.keys.push(null);
    } else {
        var old = this.map[key];
        if (old) {
            return this.replace(key, o);
        }
        this.length++;
        this.items.push(o);
        this.map[key] = o;
        this.keys.push(key);
    }
    this.fireEvent("add", this.length - 1, o, key);
    return o;
};

/**
* MixedCollection has a generic way to fetch keys if you implement getKey.
<pre><code>
// normal way
var mc = new GUI.Utils.Collection();
mc.add(someEl.dom.id, someEl);
mc.add(otherEl.dom.id, otherEl);
//and so on

// using getKey
var mc = new GUI.Utils.Collection();
mc.getKey = function (el) {
return el.dom.id;
};
mc.add(someEl);
mc.add(otherEl);

// or via the constructor
var mc = new GUI.Utils.Collection(false, function (el) {
return el.dom.id;
});
mc.add(someEl);
mc.add(otherEl);
</code></pre>
* @param o {Object} The item for which to find the key.
* @returns {Object} The key for the passed item.
*/
GUI.Utils.Collection.prototype.getKey = function (o) {
    return o.id;
};

/**
* Replaces an item in the collection. Fires the {@link #replace} event when complete.
* @param {String} key The key associated with the item to replace, or the item to replace.
* @param o {Object} o (optional) If the first parameter passed was a key, the item to associate with that key.
* @returns {Object}  The new item.
*/
GUI.Utils.Collection.prototype.replace = function (key, o) {
    if (arguments.length === 1) {
        o = arguments[0];
        key = this.getKey(o);
    }
    var index,
        old = this.item(key);

    if (typeof key === "undefined" || key === null || typeof old === "undefined") {
        return this.add(key, o);
    }
    index = this.indexOfKey(key);
    this.items[index] = o;
    this.map[key] = o;
    this.fireEvent("replace", key, old, o);
    return o;
};

/**
* Adds all elements of an Array or an Object to the collection.
* @param {Object/Array} objs An Object containing properties which will be added to the collection, or
* an Array of values, each of which are added to the collection.
*/
GUI.Utils.Collection.prototype.addAll = function (objs) {
    var i, len, key, args;
    if (arguments.length > 1 || objs instanceof Array) {
        args = arguments.length > 1 ? arguments : objs;
        for (i = 0, len = args.length; i < len; i++) {
            this.add(args[i]);
        }
    } else {
        for (key in objs) {
            if (this.allowFunctions || typeof objs[key] !== "function") {
                this.add(key, objs[key]);
            }
        }
    }
};

/**
* Executes the specified function once for every item in the collection, passing each
* item as the first and only parameter. Returning false from the function will stop the iteration.
* @param {Function} fn The function to execute for each item.
* @param {Object} scope (optional) The scope in which to execute the function.
*/
GUI.Utils.Collection.prototype.each = function (fn, scope) {
    var i, len,
        items = [].concat(this.items); // each safe for removal
    for (i = 0, len = items.length; i < len; i++) {
        if (fn.call(scope || items[i], items[i], i, len) === false) {
            break;
        }
    }
};

/**
* Executes the specified function once for every key in the collection, passing each
* key, and its associated item as the first two parameters.
* @param {Function} fn The function to execute for each item.
* @param {Object} scope (optional) The scope in which to execute the function.
*/
GUI.Utils.Collection.prototype.eachKey = function (fn, scope) {
    var i, len;
    for (i = 0, len = this.keys.length; i < len; i++) {
        fn.call(scope || window, this.keys[i], this.items[i], i, len);
    }
};

/**
 * Returns the first item in the collection which elicits a true return value from the
 * passed selection function.
 * @param {Function} fn The selection function to execute for each item.
 * @param {Object} scope (optional) The scope in which to execute the function.
 * @returns {Object} The first item in the collection which returned true from the selection function.
 */
GUI.Utils.Collection.prototype.find  = function (fn, scope) {
    var i, len;
    for (i = 0, len = this.items.length; i < len; i++) {
        if (fn.call(scope || window, this.items[i], this.keys[i])) {
            return this.items[i];
        }
    }
    return null;
};

/**
* Inserts an item at the specified index in the collection. Fires the {@link #add} event when complete.
* @param {Number} index The index to insert the item at.
* @param {String} key The key to associate with the new item, or the item itself.
* @param {Object} o (optional) If the second parameter was a key, the new item.
* @returns {Object} The item inserted.
*/
GUI.Utils.Collection.prototype.insert  = function (index, key, o) {
    if (arguments.length === 2) {
        o = arguments[1];
        key = this.getKey(o);
    }
    if (index >= this.length) {
        return this.add(key, o);
    }
    this.length++;
    this.items.splice(index, 0, o);
    if (typeof key !== "undefined" && key !== null) {
        this.map[key] = o;
    }
    this.keys.splice(index, 0, key);
    this.fireEvent("add", index, o, key);
    return o;
};

/**
* Removed an item from the collection.
* @param {Object} o The item to remove.
* @returns {Object} The item removed or false if no item was removed.
*/
GUI.Utils.Collection.prototype.remove  = function (o) {
    return this.removeAt(this.indexOf(o));
};

/**
* Remove an item from a specified index in the collection. Fires the {@link #remove} event when complete.
* @param {Number} index The index within the collection of the item to remove.
* @returns {Object} The item removed or false if no item was removed.
*/
GUI.Utils.Collection.prototype.removeAt = function (index) {
    var o, key;
    if (index < this.length && index >= 0) {
        this.length--;
        o = this.items[index];
        this.items.splice(index, 1);
        key = this.keys[index];
        if (typeof key !== "undefined") {
            delete this.map[key];
        }
        this.keys.splice(index, 1);
        this.fireEvent("remove", o, key);
        return o;
    }
    return false;
};

/**
* Removed an item associated with the passed key fom the collection.
* @param {String} key The key of the item to remove.
* @returns {Object} The item removed or false if no item was removed.
*/
GUI.Utils.Collection.prototype.removeKey  = function (key) {
    return this.removeAt(this.indexOfKey(key));
};

/**
* Returns the number of items in the collection.
* @returns {Number} the number of items in the collection.
*/
GUI.Utils.Collection.prototype.getCount  = function () {
    return this.length;
};

/**
* Returns index within the collection of the passed Object.
* @param {Object} o The item to find the index of.
* @returns {Number} index of the item.
*/
GUI.Utils.Collection.prototype.indexOf  = function (o) {
    return this.items.indexOf(o);
};

/**
* Returns index within the collection of the passed key.
* @param {String} key The key to find the index of.
* @returns {Number} index of the key.
*/
GUI.Utils.Collection.prototype.indexOfKey  = function (key) {
    return this.keys.indexOf(key);
};

/**
* Returns the item associated with the passed key OR index. Key has priority over index.
* @param {String/Number} key The key or index of the item.
* @returns {Object} The item associated with the passed key.
*/
GUI.Utils.Collection.prototype.item  = function (key) {
    var item = typeof this.map[key] !== "undefined" ? this.map[key] : this.items[key];
    return typeof item !== 'function' || this.allowFunctions ? item : null; // for prototype!
};

/**
* Returns the item at the specified index.
* @param {Number} index The index of the item.
* @returns {Object} The item at the specified index.
*/
GUI.Utils.Collection.prototype.itemAt  = function (index) {
    return this.items[index];
};

/**
* Returns the key at the specified index.
* @param {Number} index The index of the item.
* @returns {Object} The key of the item at the specified index.
 */
GUI.Utils.Collection.prototype.keyAt = function (index) {
    return this.keys[index];
};

/**
* Returns the item associated with the passed key.
* @param {String/Number} key The key of the item.
* @returns {Object} The item associated with the passed key.
*/
GUI.Utils.Collection.prototype.key = function (key) {
    return this.map[key];
};

/**
* Returns true if the collection contains the passed Object as an item.
* @param {Object} o  The Object to look for in the collection.
* @returns {Boolean} True if the collection contains the Object as an item.
*/
GUI.Utils.Collection.prototype.contains  = function (o) {
    return this.indexOf(o) !== -1;
};

/**
* Returns true if the collection contains the passed Object as a key.
* @param {String} key The key to look for in the collection.
* @returns {Boolean} True if the collection contains the Object as a key.
*/
GUI.Utils.Collection.prototype.containsKey  = function (key) {
    return typeof this.map[key] !== "undefined";
};

/**
* Removes all items from the collection.
* Fires the {@link #clear} event when complete.
*/
GUI.Utils.Collection.prototype.clear  = function () {
    this.length = 0;
    this.items = [];
    this.keys = [];
    this.map = {};
    this.fireEvent("clear");
};

/**
* Returns the first item in the collection.
* @returns {Object} the first item in the collection..
*/
GUI.Utils.Collection.prototype.first  = function () {
    return this.items[0];
};

/**
* Returns the last item in the collection.
* @returns {Object} the last item in the collection..
*/
GUI.Utils.Collection.prototype.last = function () {
    return this.items[this.length - 1];
};

/**
 * Sorts this collection with the passed comparison function
 * @param {String} property
 * @param {String} dir (optional) "ASC" or "DESC"
 * @param {Function} fn (optional) comparison function
 */
GUI.Utils.Collection.prototype._sort = function (property, dir, fn) {
    var k, items, i, len, v,
        c = [],
        dsc = String(dir).toUpperCase() === "DESC" ? -1 : 1;
    fn = fn || function (a, b) {
        return a - b;
    };
    k = this.keys;
    items = this.items;

    for (i = 0, len = items.length; i < len; i++) {
        c[c.length] = {key: k[i], value: items[i], index: i};
    }
    c.sort(function (a, b) {
        v = fn(a[property], b[property]) * dsc;
        if (v === 0) {
            v = (a.index < b.index ? -1 : 1);
        }
        return v;
    });
    for (i = 0, len = c.length; i < len; i++) {
        items[i] = c[i].value;
        k[i] = c[i].key;
    }
    this.fireEvent("sort", this);
};

/**
 * Sorts this collection with the passed comparison function
 * @param {String} dir (optional) "ASC" or "DESC"
 * @param {Function} fn (optional) comparison function
 */
GUI.Utils.Collection.prototype.sort = function (dir, fn) {
    this._sort("value", dir, fn);
};

/**
 * Sorts this collection by keys
 * @param {String} dir (optional) "ASC" or "DESC"
 * @param {Function} fn (optional) a comparison function (defaults to case insensitive string)
 */
GUI.Utils.Collection.prototype.keySort = function (dir, fn) {
    this._sort("key", dir, fn || function (a, b) {
        return String(a).toUpperCase() - String(b).toUpperCase();
    });
};

/**
 * Returns a range of items in this collection
 * @param {Number} start (optional) defaults to 0
 * @param {Number} end (optional) default to the last item
 * @returns {Array} An array of items
 */
GUI.Utils.Collection.prototype.getRange  = function (start, end) {
    var i,
        r = [],
        items = this.items;

    if (items.length < 1) {
        return [];
    }
    start = start || 0;
    end = Math.min(typeof end === "undefined" ? this.length - 1 : end, this.length - 1);

    if (start <= end) {
        for (i = start; i <= end; i++) {
            r[r.length] = items[i];
        }
    } else {
        for (i = start; i >= end; i--) {
            r[r.length] = items[i];
        }
    }
    return r;
};

/**
 * Filter the <i>objects</i> in this collection by a specific property.
 * Returns a new collection that has been filtered.
 * @param {String} property A property on your objects
 * @param {String/RegExp} value Either string that the property values
 * should start with or a RegExp to test against the property
 * @param {Boolean} anyMatch (optional) True to match any part of the string, not just the beginning
 * @param {Boolean} caseSensitive (optional) True for case sensitive comparison
 * @returns {MixedCollection} The new filtered collection
 */
GUI.Utils.Collection.prototype.filter  = function (property, value, anyMatch, caseSensitive) {
    if (!GUI.isSet(value)) {
        return this.clone();
    }
    value = this.createValueMatcher(value, anyMatch, caseSensitive);
    return this.filterBy(function (o) {
        return o && value.test(o[property]);
    });
};

/**
 * Filter by a function. * Returns a new collection that has been filtered.
 * The passed function will be called with each
 * object in the collection. If the function returns true, the value is included
 * otherwise it is filtered.
 * @param {Function} fn The function to be called, it will receive the args o (the object), k (the key)
 * @param {Object} scope (optional) The scope of the function (defaults to this)
 * @returns {MixedCollection} The new filtered collection
 */
GUI.Utils.Collection.prototype.filterBy  = function (fn, scope) {
    var r, k, i, len, it;
    r = new GUI.Utils.Collection();
    r.getKey = this.getKey;
    k = this.keys;
    it = this.items;
    for (i = 0, len = it.length; i < len; i++) {
        if (fn.call(scope || this, it[i], k[i])) {
            r.add(k[i], it[i]);
        }
    }
    return r;
};

/**
 * Finds the index of the first matching object in this collection by a specific property/value.
 * Returns a new collection that has been filtered.
 * @param {String} property A property on your objects
 * @param {String/RegExp} value Either string that the property values
 * should start with or a RegExp to test against the property.
 * @param {Number} start (optional) The index to start searching at
 * @param {Boolean} anyMatch (optional) True to match any part of the string, not just the beginning
 *
 * @param {Boolean} caseSensitive (optional) True for case sensitive comparison
 * @returns {Number} The matched index or -1
 */
GUI.Utils.Collection.prototype.findIndex  = function (property, value, start, anyMatch, caseSensitive) {
    if (!GUI.isSet(value)) {
        return -1;
    }
    value = this.createValueMatcher(value, anyMatch, caseSensitive);
    return this.findBy(function (o) {
        return o && value.test(o[property]);
    }, null, start);
};

/**
 * Find the index of the first matching object in this collection by a function.
 * If the function returns <i>true<i> it is considered a match.
 * @param {Function} fn The function to be called, it will receive the args o (the object), k (the key)
 * @param {Object} scope (optional) The scope of the function (defaults to this)
 * @param {Number} start (optional) The index to start searching at
 * @returns {Number} The matched index or -1
 */
GUI.Utils.Collection.prototype.findIndexBy  = function (fn, scope, start) {
    var i, len,
        k = this.keys,
        it = this.items;
    for (i = (start || 0), len = it.length; i < len; i++) {
        if (fn.call(scope || this, it[i], k[i])) {
            return i;
        }
    }
    if (typeof start === 'number' && start > 0) {
        for (i = 0; i < start; i++) {
            if (fn.call(scope || this, it[i], k[i])) {
                return i;
            }
        }
    }
    return -1;
};

/**
 * Returns regexp
 * @param {String} value
 * @param {Boolean} anyMatch True to match any part of the string, not just the beginning
 * @param {Boolean} caseSensitive True for case sensitive comparison
 */
GUI.Utils.Collection.prototype.createValueMatcher = function (value, anyMatch, caseSensitive) {
    if (!value.exec) { // not a regex
        value = String(value);
        value = new RegExp((anyMatch === true ? '' : '^') + GUI.escapeRe(value), caseSensitive ? '' : 'i');
    }
    return value;
};

/**
 * Change collection object to array
 * @return {Array}
 */
GUI.Utils.Collection.prototype.toArray = function () {
    return [].concat(this.items);
};

/**
 * Creates a duplicate of this collection
 * @return {MixedCollection}
 */
GUI.Utils.Collection.prototype.clone = function () {
    var i, len,
        r = new GUI.Utils.Collection(),
        k = this.keys,
        it = this.items;
    for (i = 0, len = it.length; i < len; i++) {
        r.add(k[i], it[i]);
    }
    r.getKey = this.getKey;
    return r;
};

/**
* Returns the item associated with the passed key or index.
* @method
* @param {String/Number} key The key or index of the item.
* @return {Object} The item associated with the passed key.
*/
GUI.Utils.Collection.prototype.get = GUI.Utils.Collection.prototype.item;