(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Layout implementation
     *
     * @author Inna Lavitskaya
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Layout = Class.create();
    Object.extend(GUI.Layout.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Layout.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);

        this.config =  {
            name    : null,
            columns : []
        };

        var cfg = this.config;
        Object.extend(cfg, config);

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }

        this.dom    = cfg.holder;
        this.name   = cfg.name;
        this.columns = cfg.columns;

        this.addEvents({
            afterRender : true
        });
    };

    /**
     * Renders dom layout
     */
    GUI.Layout.prototype.render = function () {
        var i, k, divCell, divContent, column,
            cols = this.columns;

        for (i = 0; i < cols.length; i++) {
            column = cols[i];

            divCell = document.createElement('DIV');
            divCell.className = 'l-layout__cell';
            this.dom.appendChild(divCell);

            if (column.cellClassName) {
                divCell.className += ' ' + column.cellClassName;
            }

            // content
            divContent = document.createElement('DIV');
            divContent.className = 'l-layout__content';
            divCell.appendChild(divContent);

            this['contentDom' + (i + 1)] = divContent;

            if (GUI.isString(column.content)) {
                divContent.innerHTML = column.content;

            } else if (GUI.isArray(column.content)) {
                for (k = 0; k < column.content.length; k++) {
                    column.content[k].render(divContent);
                }

            } else if (GUI.isObject(column.content)) {
                column.content.render(divContent);
            }

            if (cols[i + 1]) {
                this.renderDelimeter(this.dom);
            }
        }
        GUI.Dom.extend(this.dom);
    };

    /**
     * Renders delimeter
     */
    GUI.Layout.prototype.renderDelimeter = function (dom) {
        var i = document.createElement('I');
        i.className = 'b-delimiter';
        dom.appendChild(i);
    };

}());
