(function () {
    var superproto = GUI.Popup.Region.prototype;

    /**
     * JavaScript Graphical User Interface
     * Grid's editor implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Grid
     * @extends GUI.Popup.Region
     */
    GUI.Grid.Editor = Class.create();
    Object.extend(GUI.Grid.Editor.prototype, superproto);

    /**
     * Url of image
     * @type String
     */
    GUI.Grid.Editor.prototype.imgPath = window.JSGUI_IMAGES_PATH + 'inlineedit/';

    /**
     * Template of the editor
     * @type String
     */
    GUI.Grid.Editor.prototype.template =
        '<div class="inlineedit"><div class="inlineedit-top"><div class="inlineedit-l">' +
        '<div class="inlineedit-r"><div class="inlineedit-c"></div></div></div></div>' +
        '<div class="inlineedit-mdl"><div class="inlineedit-l"><div class="inlineedit-r">' +
        '<div class="inlineedit-c"><div class="inlineedit-content"><table cellspacing="0"><tr>' +
        '<td class="input"><div id="{this.editorId}"></div><div id="tmpDivEntityDecode" style="display: none;"></div></td>' +
        '<td class="ico"><div>' +
        '<img id="{this.applyId}" src="{this.imgPath}apply.gif" alt="' +
        i18n('Apply') + '" title="' + i18n('Apply') + '" width="13" height="13" />' +
        '<img id="{this.cancelId}" src="{this.imgPath}delete.gif" alt="' +
        i18n('Delete') + '" title="' + i18n('Delete') + '" width="13" height="13" />' +
        '</div></td></tr></table></div></div></div></div></div>' +
        '<div class="inlineedit-btm"><div class="inlineedit-l"><div class="inlineedit-r">' +
        '<div class="inlineedit-c"></div></div></div></div></div>';

    /**
     * Constructor. Creates template, popup region, sets content
     * and creates the editor of this type which is specified in config
     * @param {Object} config Configuration object
     */
    GUI.Grid.Editor.prototype.initialize = function (config) {
        var cfg, defCfg, tpl, modes;

        defCfg = {
            grid    : null,
            type    : 'text',
            className: 'x-region x-grid-editor',
            options : {},
            format  : 'd/m/Y'
        };

        Object.extend(defCfg, config);
        // Call parent method
        superproto.initialize.call(this, defCfg);
        Object.extend(this.config, config);
        cfg = this.config;

        this.editorId = GUI.getUniqId('x-grid-editor-');
        this.applyId = this.editorId + '-apply';
        this.cancelId = this.editorId + '-cancel';

        tpl = new GUI.STemplate(this.template, this);

        this.mask    = new GUI.Popup.Region();
        this.setContent(tpl.fetch());
        modes = {
            text    : GUI.Forms.TextField,
            spin    : GUI.Forms.SpinEdit,
            date    : GUI.Forms.DatePicker,
            combo   : GUI.Forms.Combo
        };

        if (modes[cfg.type]) {
            this.editor = new modes[cfg.type]({
                width   : '100%',
                css     : {},
                options : cfg.options,
                format  : cfg.format
            });
        } else {
            throw new Error("Unknow grid editor mode: ", cfg.type);
        }

        this.applyClickListener   = this.onApplyClick.bindAsEventListener(this);
        this.cancelClickListener  = this.onCancelClick.bindAsEventListener(this);
        this.keyUpListener        = this.onKeyUp.bindAsEventListener(this);
    };

    /**
     * Show editor. Shows mask, renders editor, sets value, aligns to node,
     * attach events, focus editor
     * @param {Object} cfg
     */
    GUI.Grid.Editor.prototype.show = function (cfg) {
        this.row = cfg.row;
        this.colIndex = cfg.colIndex;

        this.mask.takeDocument();
        this.mask.show();
        this.mask.dom.style.background = 'white';
        GUI.setOpacity(this.mask.dom, 0.4);

        var dims = GUI.getDimensions(cfg.node);
        if (cfg.editor === 'date' && dims.width < 130) {
            dims.width = 130;
        } else if (dims.width < 90) {
            dims.width = 90;
        }

        dims.height = 30;

        this.setDimensions(dims);
        // render region
        superproto.show.call(this);
        // render editor
        this.editor.render(this.editorId);
        // <-- html entities decode; LinuZz@27072009
        try {
            GUI.$('tmpDivEntityDecode').innerHTML = '<textarea id="tmpTxtEntityDecode">' + cfg.value + '</textarea>';
            this.value = GUI.$('tmpTxtEntityDecode').value.replace(/<[^>]*>/g, '');
            this.editor.setValue(this.value);
            GUI.$('tmpDivEntityDecode').innerHTML = '';
        } catch (e) {
            this.value = cfg.value.replace(/<[^>]*>/g, '');
            this.editor.setValue(cfg.value);
        }
        // -->

        this.alignTo(cfg.node, 'l-l', GUI.isIE6 ? [-7, 0] : [-6, 1]);
        this.attachEventListeners();
        this.editor.focus();
    };

    /**
     * Remove listeners, removes the editor, hides the mask
     */
    GUI.Grid.Editor.prototype.hide = function () {
        this.removeEventListeners();
        this.editor.unrender();
        superproto.hide.call(this);
        this.mask.hide();
    };

    /**
     * Nulls row and colIndex
     */
    GUI.Grid.Editor.prototype.cleanUp = function () {
        this.row = null;
        this.colIndex = null;
    };

    /**
     * Add events listeners
     */
    GUI.Grid.Editor.prototype.attachEventListeners = function () {
        GUI.Event.on(this.mask.dom, 'click', this.applyClickListener);
        GUI.Event.on(GUI.$(this.applyId), 'click', this.applyClickListener);
        GUI.Event.on(GUI.$(this.cancelId), 'click', this.cancelClickListener);
        GUI.Event.on(this.editor.dom, 'keyup', this.onKeyUp, this);
    };

    /**
     * Remove events listeners
     */
    GUI.Grid.Editor.prototype.removeEventListeners = function () {
        GUI.Event.un(this.mask.dom, 'click', this.applyClickListener);
        GUI.Event.un($(this.applyId), 'click', this.applyClickListener);
        GUI.Event.un($(this.cancelId), 'click', this.cancelClickListener);
        GUI.Event.un(this.editor.dom, 'keyup', this.onKeyUp, this);
    };

    /**
     * Handler click on cancel
     * @param {Event} e
     */
    GUI.Grid.Editor.prototype.onCancelClick = function (e) {
        this.hide();
        var grid = this.config.grid;
        grid.fireEvent('cancel', grid, this.row, this.colIndex, e);
        this.cleanUp();
    };
    /**
     * Handler click on apply.
     * @param {Event} e Event
     */
    GUI.Grid.Editor.prototype.onApplyClick = function (e) {
        var value, grid;

        value = this.editor.getValue();
        if (value === this.value) {
            return this.onCancelClick(e);
        }

        grid = this.config.grid;
        if (grid.fireEvent('beforeApply', grid, this.row, this.colIndex, value, e) !== true) {
            return;
        }
        this.hide();
        grid.fireEvent('apply', grid, this.row, this.colIndex, value, e);
        this.cleanUp();
        return true;
    };

    /**
     * Handlers key up
     * @param {Event} e
     */
    GUI.Grid.Editor.prototype.onKeyUp = function (e) {
        e = GUI.Event.extend(e);
        switch (e.getKey()) {
        case e.KEY_ESC:
            this.onCancelClick(e);
            break;

        case e.KEY_RETURN:
            this.onApplyClick(e);
            break;

        default:
            break;
        }
    };

}());
