/**
 * Extends destination object with properties of source
 * @param {Object} destination
 * @param {Object} source
 * @return {Object} Destination object, extended with the source properties
 */
Object.extend = function (destination, source) {
    var property;
    if (destination && source && typeof source === 'object') {
        for (property in source) {
            destination[property] = source[property];
        }
    }
    return destination;
};

//Object.deepExtend = function(destination, source) {
//    var property;
//    for (property in source) {
//        if (typeof source[property] === "object") {
//            destination[property] = destination[property] || {};
//            arguments.callee(destination[property], source[property]);
//        } else {
//            destination[property] = source[property];
//        }
//    }
//    return destination;
//};

// OOP
window.Class = {
    create: function () {
        var i, subclass,
            parent = null,
            properties = Object.toArray(arguments);

        if (GUI.isFunction(properties[0])) {
            parent = properties.shift();
        }

        function klass() {
            this.initialize.apply(this, arguments);
        }

        Object.extend(klass, Class.Methods);

        if (parent) {
            klass.superclass = parent.prototype;
            subclass = function () { };
            subclass.prototype = parent.prototype;
            klass.prototype = new subclass;

        } else {
            klass.superclass = null;
        }

        for (i = 0; i < properties.length; i++) {
            klass.addMethods(properties[i]);
        }

        if (!klass.prototype.initialize) {
            klass.prototype.initialize = GUI.emptyFunction;
        }
        klass.prototype.constructor = klass;

        return klass;
    },

    Methods: {
        addMethods: function (source) {
            var i, length, property, value,
                properties = Object.keys(source);

            for (i = 0, length = properties.length; i < length; i++) {
                property = properties[i];
                value = source[property];
                this.prototype[property] = value;
            }
            return this;
        }
    }
};

/**
 * Extend Object with functions that do not need access to private functions
 */
Object.extend(Object, {
    /**
     * Extends only undefined properties of the destination object
     * @param {Object} destination
     * @param {Object} source
     * @returns {Object} destination Extended object
     */
    extendIf: function (destination, source) {
        var property;
        if (destination && source && typeof source === 'object') {
            for (property in source) {
                if (typeof destination[property] === 'undefined') {
                    destination[property] = source[property];
                }
            }
        }
        return destination;
    },

    /**
     * Clones 1-st level properties of the object
     * @param {Object} object
     * @returns {Object} Cloned object
     */
    clone: function (object) {
        return Object.extend({}, object);
    },

    /**
     * Converts object to a json string
     * @param {Object} object
     * @returns {String} Json representation of the object
     */
    toJson: function (object) {

    },

    /**
     * Converts array-like object to real array
     * @param {Object} object
     * @returns {Array}
     */
    toArray: function (object) {
        var length = object.length || 0,
            results = new Array(length);

        while (length--) {
            results[length] = object[length];
        }
        return results;
    },

    /**
     * Returns array of names of object's properties
     * @param {Object} object
     * @returns {Array}
     */
    keys: function (object) {
        var property, keys = [];
        for (property in object) {
            keys.push(property);
        }
        return keys;
    },

    /**
     * Re turns array of names of object's properties
     * @param {Object} object
     * @returns {Array}
     */
    values: function (object) {
        var property, values = [];
        for (property in object) {
            values.push(object[property]);
        }
        return values;
    }
});

/**
 * Closure to do not publish private function in the global scope
 */
(function () {
    function _toQueryString(uri, object, prepend) {
        var i, len, key, value;
        switch (typeof object) {
        case 'number':
        case 'string':
        case 'boolean':
            // Number, string or boolean
            if (prepend === '') {
                throw new Error("At first object must be passed!");
            }
            uri.push(prepend + '=' + encodeURIComponent(object));
            break;

        case 'object':
            if (object !== null && object.constructor === Array) {
                // Array
                if (prepend === '') {
                    throw new Error("At first object must be passed!");
                }
                for (i = 0, len = object.length; i < len; i++) {
                    _toQueryString(uri, object[i], prepend + '[' + i + ']');
                }

            } else {
                // Object
                for (key in object) {
                    value = object[key];
                    // Prototype hack
                    if (typeof value === 'function') {
                        continue;
                    }

                    _toQueryString(uri, value,
                        prepend === '' ? key : (prepend + '[' + key + ']')
                        );
                }
            }
            break;
        }
    }

    // Extend with functions that require access to private functions
    Object.extend(Object, {
        /**
         * Converts object to a query string as deeply as possible
         * @param {Object} object
         * @returns {String}
         */
        toQueryString: function (object) {
            var uri = [];
            _toQueryString(uri, object, '');
            return uri.join('&');
        },

        /**
         * Converts quesry string to an array
         */
        fromQueryString: function (str) {
            var i, pair,
                res = {},
                pairs = str.split('&'),
                len = pairs.length;

            for (i = 0; i < len; i++) {
                pair = pairs[i].split('=');
                res[decodeURIComponent(pair[0])] = pair[1];
            }
            return res;
        }
    });

}());

// Extend Function.prototype
Object.extend(Function.prototype, {
    /**
     * Binds method to object, return method delegate
     * @param {Object} bindTo
     */
    bindLegacy: function (object) {
        var ap, args, concat,
            notPartial = arguments.length < 2, // 0 or 1 arguments passed
            __method = this;

        if (notPartial) { // fast variant for not partial
            if (typeof object !== 'undefined') {
                return function () { // mostly used variant
                    return arguments.length !== 0
                        ?  __method.apply(object, arguments)
                        : __method.call(object);
                };
            } else {
                return this;
            }
        } else { // partial
            ap = Array.prototype;
            args = ap.slice.call(arguments, 1);
            concat = ap.concat;

            return function () {
                return __method.apply(object,
                    (arguments.length === 0)
                        ? args
                        : concat.apply(args, arguments)
                    );
            };
        }
    },

    // not needed, stub for back support
    bindAsEventListener: function () {
        return this.bind.apply(this, arguments);
    },

    /**
     * Defers function execution. First argument is number of seconds to delay
     * function call; other arguments will be passed to deferred function
     * @param {Number} delay Delay time in seconds
     */
    defer: function () {
        var __method = this,
            args = Object.toArray(arguments),
            timeout = args.shift(),
            object = args.shift();

        return window.setTimeout(function () {
            return __method.apply(object, args);
        }, timeout);
    },

    /**
     * Returns methodized function. First argument is value of "this".
     */
    methodize: function () {
        if (this._methodized) {
            return this._methodized;
        }
        var __method = this,
            concat = Array.prototype.concat;

        this._methodized = function () {
            return __method.apply(null, concat.apply([this], arguments));
        };
        return this._methodized;
    }
});


// Extend Date.prototype
Object.extend(Date.prototype, {
    /**
     * Converts date to JSON representation
     * @return {String}
     */
    toJson: function () {
        return '"' + this.getUTCFullYear() + '-' +
            (this.getUTCMonth() + 1).toPaddedString(2) + '-' +
            this.getUTCDate().toPaddedString(2) + 'T' +
            this.getUTCHours().toPaddedString(2) + ':' +
            this.getUTCMinutes().toPaddedString(2) + ':' +
            this.getUTCSeconds().toPaddedString(2) + 'Z"';
    },

    formatDate: function (format) {
        var i, len, ch, re, o = '';
        for (i = 0, len = format.length; i < len; i++) {
            ch = format.charAt(i);
            re = '';
            switch (ch) {
            case 'd':
                // day
                re = this.getDate();
                re = (re < 10) ? '0' + re.toString() : re.toString();
                break;

            case 'm':
                // month
                re = this.getMonth() + 1;
                re = (re < 10) ? '0' + re.toString() : re.toString();
                break;

            case 'Y':
                // year
                re = this.getFullYear();
                break;

            default:
                re = ch;
                break;
            }
            o += re;
        }

        return o;
    },

    /**
     * format %d.%m.%y %H:%M:%s
     */
    toLocaleFormat : function (format) {
        var k,
            f = {
                y : this.getFullYear(),
                m : this.getMonth() + 1,
                d : this.getDate(),
                H : this.getHours(),
                M : this.getMinutes(),
                S : this.getSeconds()
            };

        for (k in f) {
            format = format.replace('%' + k, f[k] < 10 ? "0" + f[k] : f[k]);
        }

        return format;
    }
});

// Extend String.prototype
Object.extend(String.prototype, {
    /**
     * Returns new string as a specified number of repeated string
     * @param {Object} number
     *
     */
    times: function (number) {
        return (number < 1) ? '' : (new Array(number + 1)).join(this);
    },

    camelize: function () {
        var camelized, i,
            parts = this.split('-'),
            len = parts.length;

        if (len === 1) {
            return parts[0];
        }

        camelized = this.charAt(0) === '-'
            ? parts[0].charAt(0).toUpperCase() + parts[0].substring(1)
            : parts[0];

        for (i = 1; i < len; i++) {
            camelized += parts[i].charAt(0).toUpperCase() + parts[i].substring(1);
        }
        return camelized;
    },

    capitalize: function () {
        return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
    },

    capitalizeFirstWord: function () {
        return this.charAt(0).toUpperCase() + this.substring(1);
    },

    /**
     * Returns true of string contains substring, false if not
     * @param {String} subString
     * @return {Boolean}
     */
    include: function (subString) {
        return this.indexOf(subString) > -1;
    },

    /**
     * Returns true if the string is empty
     * @return {Boolean}
     */
    isEmpty: function () {
        return this === '';
    },

    startsWith: function (pattern) {
        return this.indexOf(pattern) === 0;
    },

    endsWith: function (pattern) {
        var d = this.length - pattern.length;
        return d >= 0 && this.lastIndexOf(pattern) === d;
    },

    evalJSON: function (sanitize) {
        if (window.JSON && JSON.parse) {
            try {
                return JSON.parse(this);
            } catch (e) {
                return null;
            }
        }

        try {
            return eval('(' + this + ')');
        } catch (e) {
            return null;
        }
    },

    htmlentities: function () {
        var res,
            div = document.createElement('div'),
            text = document.createTextNode(this);

        div.appendChild(text);
        res = div.innerHTML;
        div = null;
        return res;
    },

    trim: function (charlist) {    // Strip whitespace (or other characters) from the beginning and end of a string
        charlist = !charlist ? /^\s+|\s+$/ : charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/, '\$1');
        var re = new RegExp('^[' + charlist + ']+|[' + charlist + ']+$', 'g');
        return this.replace(re, '');
    },

    stripTags: function () {
        return this.replace(/<\/?[^>]+>/gi, '');
    },

    strip_tags: function (allowed) {
        allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

        return this.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    }
});

if (!String.prototype.repeat) {
  String.prototype.repeat = function(count) {
    'use strict';
    if (this == null) {
      throw new TypeError('can\'t convert ' + this + ' to object');
    }
    var str = '' + this;
    count = +count;
    if (count != count) {
      count = 0;
    }
    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }
    if (count == Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if (str.length == 0 || count == 0) {
      return '';
    }

    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }
    var rpt = '';
    for (;;) {
      if ((count & 1) == 1) {
        rpt += str;
      }
      count >>>= 1;
      if (count == 0) {
        break;
      }
      str += str;
    }
    return rpt;
  }
}

(function () {
    // Private variables
    var _trimRe = /^\s+|\s+$/g,
        _formatRe = /\{(\d+)\}/g;

    Object.extend(String.prototype, {
        /**
         * Removes spaces at the beginning and at the end of the string
         * @return {String} Trimmed string
         */
        /*trim: function () {
            return this.replace(_trimRe, "");
        },*/

        /**
         * Returns formatted string
         */
        format : function () {
            var args = arguments;
            return this.replace(_formatRe, function (m, i) {
                return args[i];
            });
        },

        /**
         * 20% faster then above
         * @param {Array} args
         */
        formatArr : function (args) {
            return this.replace(/\{(\d+)\}/g, function (m, i) {
                return args[i];
            });
        },

        /**
         * Escapes the passed string for use in a regular expression
         * @param {String} str
         * @return {String}
         */
        escapeRe : function () {
            return this.replace(/([.*+?^${}()|[\]\/\\])/g, "\\$1");
        }
    });
}());


// Extend Number.prototype
Object.extend(Number.prototype, {
    /**
     * Converts number to the fixed-length string, padded with zeros
     * @param {Object} length
     * @param {Object} radix
     */
    toPaddedString: function (length, radix) {
        var string = Math.abs(this).toString(radix || 10);
        string = '0'.times(length - string.length) + string;
        return (this < 0) ? ('-' + string) : string;
    },

    /**
     * Checks if the number is within a desired range. If the number is already
     * within the range it is returned, otherwise the min or max value is
     * returned depending on which side of the range is exceeded.
     * @param {Number} min The minimum number in the range
     * @param {Number} max The maximum number in the range
     * @return {Number} The constrained value if outside the range, otherwise the current value
     */
    constrain : function (min, max) {
        if (min === undefined) {
            min = null;
        }

        if (typeof max === 'number') {
            return Math.min(Math.max(this, min), max);
        } else {
            return Math.max(this, min);
        }
    }
});


// Extend Array.prototype
Object.extend(Array.prototype, {
    /**
     * Fills array with the specified value
     * @param {Mixed} value
     */
    fill: function (value) {
        var len = this.length;
        // Suggestion: unroll loop
        while (len--) {
            this[len] = value;
        }
        return this;
    },

    /**
    * Returns index of the element in the array if exists or -1 if not
    * @param {Mixed} object The object get index of
    * @return {Number}
    */
    indexOf: function (object) {
        var len = this.length;
        // Suggestion: unroll loop
        while (len--) {
            if (this[len] === object) {
                return len;
            }
        }
        return -1;
    },

    /**
    * Removes the specified object from the array or does nothing if no such object found
    * @param {Object} object The object to remove
    * @return {Boolean} True - object removed, false - no such object in the array
    */
    remove : function (o) {
        var index = this.indexOf(o);
        if (index !== -1) {
            this.splice(index, 1);
            return true;
        }
        return false;
    },

    /**
     * Returns copy of the array with the same elements
     * @return {Array} Cloned array
     */
    clone: function () {
        return [].concat(this);
    },

    /**
     * Returns true if array contains object
     * @param {Object} o
     * @return {Boolean}
     */
    include: function (o) {
        var len = this.length;
        while (len--) {
            if (this[len] === o) {
                return true;
            }
        }
        return false;
    },

    each: function (fn) {
        var len = this.length;
        while (len--) {
            if (fn(this[len]) === false) {
                break;
            }
        }
        return this;
    },

    compact: function () {
        var i, len = this.length, out = [], item, outlen = 0;
        for (i = 0; i < len; i++) {
            item = this[i];
            if (!out.include(item)) {
                out[outlen++] = item;
            }
        }
        return out;
    },

    last: function () {
        return this[this.length - 1];
    },

    clean: function () {
        var i, len = this.length, out = [], item;

        for (i = 0; i < len; i++) {
            item = this[i];
            if (item !== undefined) {
                out.push(item);
            }
        }
        return out;
    }
});

// IE7
(function () {
    var JSON = JSON || {};

    // implement JSON.stringify serialization
    JSON.stringify = JSON.stringify || function (obj) {
        var n, v, arr,
            json = [],
            t = typeof (obj);

        if (t !== "object" || obj === null) {
            // simple data type
            if (t === "string") {
                obj = '"' + obj + '"';
            }
            return String(obj);

        } else {
            // recurse array or object

            arr = (obj && obj.constructor === Array);

            for (n in obj) {
                v = obj[n];
                t = typeof (v);

                if (t === "string") {
                    v = '"' + v + '"';

                } else if (t === "object" && v !== null) {
                    v = JSON.stringify(v);
                }

                json.push((arr ? "" : '"' + n + '":') + String(v));
            }
            return (arr ? "[" : "{") + String(json) + (arr ? "]" : "}");
        }
    };
}());
