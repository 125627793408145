(function () {
    var superproto = GUI.BoxComponent.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Field implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.BoxComponent
    */
    GUI.Forms.Field = Class.create();
    Object.extend(GUI.Forms.Field.prototype, superproto);

    /**
     * Field's name
     * @type Strings
     */
    GUI.Forms.Field.prototype.name = null;

    /**
     * Field's value
     * @type String
     */
    GUI.Forms.Field.prototype.value = undefined;

    /**
     * If the field is "input", then this property specifies its type
     * @type String
     */
    GUI.Forms.Field.prototype.type = null;

    /**
     * Text that is displayed if the user entered nothing
     * @type String
     */
    GUI.Forms.Field.prototype.defaultText = '';

    /**
     * Browser title
     * @type String
     */
    GUI.Forms.Field.prototype.title = '';

    /**
     * True if field is read-only
     * @type Boolean
     */
    GUI.Forms.Field.prototype.readOnly = false;

    /**
     * TabIndex
     * @type Number
     */
    GUI.Forms.Field.prototype.tabIndex = -1;

    /**
     * Field's css class name
     * @type String
     */
    GUI.Forms.Field.prototype.className = '';

    /**
     * Css class that applied to all fields
     * @type String
     */
    GUI.Forms.Field.prototype.fieldClass = '';

    /**
     * Css class that applied when the field is invalid
     * @type String
     */
    GUI.Forms.Field.prototype.invalidClass = '';

    /**
     * Default is css class, 'jsgui-field-defaulttext'
     * @type String
     */
    GUI.Forms.Field.prototype.defaultTextClass = 'b-text-field_default_value';

    /**
     * If true field is focused, default is false
     * @type Boolean
     */
    GUI.Forms.Field.prototype.focused = false;

    /**
     * True, if it is field of form, default is true
     * @type Boolean
     */
    GUI.Forms.Field.prototype.isFormField = true;

    /**
     * True, if it can be empty, default is false
     * @type Boolean
     */
    GUI.Forms.Field.prototype.allowEmpty = false;

    /**
     * Validate on blur, default is true
     * @type Boolean
     */
    GUI.Forms.Field.prototype.validateOnBlur = false;

    /**
     * True, if field is editable, default is true
     * @type Boolean
     */
    GUI.Forms.Field.prototype.editable = true;

    /**
     * Dom element to render error element to, default is 'body'
     * @type String
     */
    GUI.Forms.Field.prototype.errorNodeHolder = 'body';

    /**
     * If true disable validation popups, default is false
     * @type Boolean
     */
    GUI.Forms.Field.prototype.disableValidationPopups = false;

    /**
     * Delay before start validation, default is 200
     * @type Number
     */
    GUI.Forms.Field.prototype.validationDelay = 200;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.Field.prototype.livevalidation = false;

    /**
     * True to automatically hide validation popups, default is true
     * @type Boolean
     */
    GUI.Forms.Field.prototype.autohide = true;

    /**
     * Delay before hide, default is 300
     * @type Number
     */
    GUI.Forms.Field.prototype.hidedelay = 3000;

    /**
     * True to animate validaiton popups, default is false
     * @type Boolean
     */
    GUI.Forms.Field.prototype.animate = false;

    /**
     * Style of float, default is 'none'
     * @type String
     */
    GUI.Forms.Field.prototype.cssFloat = 'none';

    /**
     * Style of float, default is 'none'
     * @type String
     */
    GUI.Forms.Field.prototype.validationAlignTo = 'dom';

    /**
     * Template, used to display validation error
     * @type String
     */
    GUI.Forms.Field.prototype.errorTemplate =
        '<div class="b-informer b-informer_error b-informer_big" style="border: 1px solid #FCEBBD;">' +
        '<i class="b-informer__icon"></i>' +
        '<div class="b-informer__data">' +
        '<p class="b-informer__txt">{0}</p>' +
        '</div>' +
        '</div>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.Field.prototype.initialize = function (config) {
        // back support
        if (config) {
            if (GUI.isSet(config.editable) && !GUI.isSet(config.readOnly)) {
                config.readOnly = config.editable;
                delete config.editable;
            }
        }

        superproto.initialize.call(this, config);
    };

    /**
     * Assigns component to the existing html markup
     * @param {HTMLElement} to Element for assign
     */
    GUI.Forms.Field.prototype.assign = function (to) {
        // Update config
        to = GUI.$(to);
        if (!to) {
            throw new Error("Element to assign to is null.");
        }
        if (to._jsguiComponent || this.onBeforeAssign(to) === false) {
            // Can not be assigned to the passed field
            return false;
        }

        // Read confguration from the element
        this.onAssign(to);
        this.fireEvent('assign', this, to);
        this.render(to.parentNode, to);
        GUI.destroyNode(to);
        to = null;
        return true;
    };

    /**
     * Validates field
     * @return {Boolean} true if the field is valid, false if invalid
     */
    GUI.Forms.Field.prototype.validate = function () {
        if (this.disabled) {
            return true;
        }
        var msg, name, validator,
            valid = true;

        // Proposal: Check allowEmpty ? If true and empty then return true, else validate
        if (this.allowEmpty && this.getValue() === '') {
            return true;
        }
        for (name in this.validators) {
            validator = this.validators[name];
            if (!validator.validate()) {
                valid = false;
                msg = validator.getErrorMessage();
                break;
            }
        }

        if (valid) {
            this.setValid();
        } else {
            this.setInvalid(msg);
        }

        return valid;
    };

    /**
     * Returns field element
     * @returns {HTMLElement} field element
     */
    GUI.Forms.Field.prototype.getStylingEl = function () {
        return this.fieldEl;
    };

    /**
     * Sets field as invalid with message. Usually called by validator
     * Displays validation error popup if it is not disabled by config
     * @param {Object} msg Text of the message
     */
    GUI.Forms.Field.prototype.setInvalid = function (msg) {
        this.getStylingEl().addClass(this.invalidClass);
        var html, region, errNode, alignTo;

        if (!this.disableValidationPopups) {
            // Show error message in the popup region
            html = this.errorTemplate.format(msg);

            if (!this.errorHolder) {
                // Need to create region
                switch (this.errorNodeHolder) {
                case 'body':
                    errNode = document.body;
                    break;

                case 'parent':
                    errNode = this.dom.parentNode;
                    break;

                default:
                    errNode = GUI.$(this.errorNodeHolder);
                    break;
                }

                this.errorHolder = new GUI.Popup.Region({
                    parentNode: errNode,
                    className : ''
                });
            }
            region = this.errorHolder;
            region.setContent(html);

            if (!region.getVisibility()) {
                switch (this.validationAlignTo) {
                    case 'parent':
                        alignTo = this.dom.parentNode;
                        break;

                    case 'dom':
                        alignTo = this.dom;
                        break;

                    default:
                        alignTo = GUI.$(this.validationAlignTo);
                        break;
                }

                if (GUI.isSmallMobile) {
                    region.alignTo(alignTo, 'tl-bl', [2, 0]);
                } else {
                    region.alignTo(alignTo, 'tl-tr', [2, -7]);
                }

                region.show();

                if (GUI.isSmallMobile) {
                    GUI.scrollIntoView(document.body, region.dom);
                }

                GUI.Event.on(region.dom, 'click', this.setValid, this);
                if (this.animate) {
                    this.showEf.config.element = region.getElement();
                    this.showEf.execute();
                }
            }
            if (this.autohide) {
                this.hideTask.delay(this.hidedelay);
            }
        }
        this.fireEvent('invalid', this, msg);
    };

    /**
     * Sets field valid.
     * @param {Boolean} onlyHide True to only hide popup error
     */
    GUI.Forms.Field.prototype.setValid = function (onlyHide) {
        this.getStylingEl().removeClass(this.invalidClass);

        if (!this.errorHolder) {
            return;
        }
        var region = this.errorHolder;
        if (region.getVisibility()) {
            GUI.Event.un(region.dom, 'click', this.setValid, this);
            if (this.animate) {
                if (this.showEf.context) {
                    this.showEf.stop();
                }
                this.hideEf.config.element = region.getElement();
                this.hideEf.execute();
            } else {
                this._setValid();
            }
        }

        this.fireEvent('valid', this);
    };

    /**
     * Hide error holder element
     */
    GUI.Forms.Field.prototype._setValid = function () {
        this.errorHolder.hide();
    };

    /**
     * Returns name of the field
     * @returns {String} name
     */
    GUI.Forms.Field.prototype.getName = function () {
        return this.name;
    };

    /**
     * Returns value of the field
     * @returns {String|Number} value
     */
    GUI.Forms.Field.prototype.getValue = function () {
        if (!this.fieldEl) {
            return this.value;
        }
        var v = this.fieldEl.value;

        if (v === this.defaultText) {
            return '';
        } else {
            return v;
        }
    };

    /**
     * Returns value of the raw
     * @returns {String|Number} value
     */
    GUI.Forms.Field.prototype.getRawValue = function () {
        return (this.fieldEl) ? this.fieldEl.value : this.value;
    };

    /**
     * Sets a data value into the field and validates it.
     * To set the value directly without validation use setRawValue.
     * @param {Object} v Value
     * @param {Boolean} silent If fase fire event 'change'
     */
    GUI.Forms.Field.prototype.setValue  = function (v, silent) {
        var oldValue = this.getValue();

        this.value = v;
        if (this.fieldEl) {
            this.fieldEl.value = (v === null || v === undefined ? '' : v);
        }
        if (v !== oldValue && !silent) {
            if (this.fieldEl) {
                this.validate();
            }

            this.fireEvent('change', this, v, oldValue);
        }
    };

    /**
     * Clear value of field, applies default text, blur
     */
    GUI.Forms.Field.prototype.clear = function () {
        if (this.getValue() !== '') {
            this.setRawValue('');
        }
        this.applyDefaultText();
        this.blur();
    };

    /**
     * Sets raw value
     * @param {String|Number} v Value
     */
    GUI.Forms.Field.prototype.setRawValue  = function (v) {
        this.value = v;
        if (this.fieldEl) {
            this.fieldEl.value = (v === null || v === undefined ? '' : v);
        }
    };

    /**
     * Sets value to default text, sets valid
     */
    GUI.Forms.Field.prototype.reset = function () {
        this.setValue(this.defaultValue);
        this.setValid();
    };

    /**
     * Init component. Add events 'focus', 'blur', 'change', 'valid', 'invalid', 'assign'
     */
    GUI.Forms.Field.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            'focus'     : true,
            'blur'      : true,
            'change'    : true,
            'valid'     : true,
            'invalid'   : true,
            'assign'    : true
        });

        this.validators = {};

        if (this.autohide) {
            this.hideTask = new GUI.Utils.DelayedTask(this.setValid, this);
        }
        if (this.animate) {
            this.showEf = new GUI.Fx.Animator({
                time  : 300,
                sleep : 50,
                modificators : [{
                    type : 'alpha',
                    from : 0,
                    to   : 1.0
                }]
            });
            this.hideEf = new GUI.Fx.Animator({
                time  : 300,
                sleep : 50,
                modificators : [{
                    type : 'alpha',
                    from : 1.0,
                    to   : 0
                }]
            });
            this.hideEf.on('stop', this._setValid, this);
        }
    };

    /**
     * Test if the component can be assigned to the passed element
     * @param {HTMLElement} to
     * @return {Boolean}
     */
    GUI.Forms.Field.prototype.onBeforeAssign = function (to) {
        // Can be assigned only to the text input
        return to.nodeName === 'INPUT' && to.type === this.type;
    };

    /**
     * Update configuration according to attributes of DOM Node element
     * @param {HTMLElement} elem Source DOM Node for getting configuration data
     */
    GUI.Forms.Field.prototype.onAssign = function (elem) {
        // Get config values from html attributes of element
        var value, autohide, hidedelay, livevalidation, disableValidationPopups,
            validation, elem_height, defText, cssFloat, attr, placeholder, title,
            tabIndex, validationAlignTo, autocomplete;

        if (GUI.isSet(elem.id)) {
            GUI.ComponentMgr.unregister(this);
            this.id = elem.id;
            GUI.ComponentMgr.register(this);
        } else {
            elem.id = this.id;
        }

        this.addClass = elem.className || null;

        this.name = elem.getAttribute('name');
        this.disabled = elem.disabled;

        autohide = elem.getAttribute('autohide'); // IE???

        if (autohide === 'true') {
            this.autohide = true;
        } else if (autohide === 'false') {
            this.autohide = false;
        }

        hidedelay = elem.getAttribute('hidedelay');
        if (hidedelay !== null) {
            this.hideDelay = hidedelay;
        }

        livevalidation = elem.getAttribute('livevalidation');
        if (GUI.isSet(livevalidation)) {
            this.livevalidation = (livevalidation === 'true');
        }

        disableValidationPopups = elem.getAttribute('disableValidationPopups');
        if (GUI.isSet(disableValidationPopups)) {
            this.disableValidationPopups = (disableValidationPopups === 'true');
        }

        // Parse validation string
        validation = elem.getAttribute('validation');
        if (GUI.isSet(validation)) {
            this.validation = this.parseAttributeParams(validation);
        }

        value = elem.value;
        if (value.length > 0 && value !== this.defaultText) {
            this.value = value;
        }

        title = elem.title;
        if (title.length > 0) {
            this.title = title;
        }

        placeholder = elem.placeholder || elem.getAttribute('placeholder');
        if (GUI.isSet(placeholder)) {
            this.placeholder = placeholder;
        }

        // Style width
        value = elem.style.width;
        if (GUI.isSet(value)) {
            if (value.endsWith('%')) {
                this.width = value;
            } else if (value.endsWith('px')) {
                this.width = parseInt(value, 10);
            } else if (value !== '') {
                this.width = value; // for 'auto' to work
            }
        }

        // Style height
        elem_height = elem.style.height;
        if (GUI.isSet(elem_height)) {
            if (elem_height.endsWith('%')) {
                this.height = elem_height;
            } else if (elem_height.endsWith('px')) {
                this.height = parseInt(elem_height, 10);
            }
        }

        // defaultText
        defText = elem.getAttribute('deftext');
        if (GUI.isSet(defText)) {
            this.defaultText = defText;
        }

        this.readOnly = !!elem.readOnly;

        cssFloat = elem.style[GUI.isIE ? 'styleFloat' : 'cssFloat'];
        if (cssFloat) {
            this.cssFloat = cssFloat;
        }

        attr = elem.getAttribute('validateonblur');
        if (attr !== null) {
            this.validateOnBlur = (attr === 'false') ? false : true;
        }

        tabIndex = elem.getAttribute('tabindex');
        if (tabIndex !== null) {
            this.tabIndex = parseInt(elem.getAttribute('tabindex'), 10);
        }

        autocomplete = elem.getAttribute('autocomplete');
        if (autocomplete !== null) {
            this.autocomplete = elem.getAttribute('autocomplete');
        }

        validationAlignTo =  elem.getAttribute('validationalignto');
        if (validationAlignTo !== null) {
            this.validationAlignTo = elem.getAttribute('validationalignto');
        }
    };

    /**
     * Parses string, thar contains configuration data in format:
     * "param1:value1, param2: value2"
     * Returns array with keys as params
     * @param {Object} str
     * @returns {Array} params
     */
    GUI.Forms.Field.prototype.parseAttributeParams = function (str) {
        // str = str.replace(/\s+/g, '');

        var item, param,
            arr = str.split(','),
            params = {},
            i = 0,
            len = 0;

        for (i = 0, len = arr.length; i < len; i++) {
            item = arr[i];
            param = item.split(':');
            var name = param.shift().trim();
            var value = param.join(':').trim();

            if (GUI.isSet(name)) {
                params[name.toLowerCase()] = (GUI.isSet(value)) ? value : true;
            }
        }
        return params;
    };

    /**
     * Remove validator from list of attached validators
     * @param {String} name Name of validator to remove
     */
    GUI.Forms.Field.prototype.removeValidator = function (name) {
        if (!this.validators[name]) {
            throw new Error('Trying to remove validator, that was not attached');
        }
        delete this.validators[name];
    };

    /**
     * Get validators' object by its name
     * @param {String} name Name of the validator
     * @return {Object} Validators' object
     */
    GUI.Forms.Field.prototype.getValidator = function (name) {
        name = name.capitalize();
        if (GUI.isSet(this.validators[name])) {
            return this.validators[name];
        }
        return false;
    };

    /**
     * Renders field
     * @returns {Obect} field
     */
    GUI.Forms.Field.prototype.onRender = function () {
        var fieldEl = this.getFieldElement();

        GUI.Dom.extend(fieldEl);
        fieldEl._jsguiComponent = true;
        this.initField(fieldEl);

        // Init validators
        this.initValidators();

        return fieldEl;
    };

    /**
     * Defaults to <input  />
     * @returns {HTMLElement} dom
     */
    GUI.Forms.Field.prototype.getFieldElement = function () {
        var dom = GUI.createInput(this.name);
        dom.setAttribute('type', this.type);
        return dom;
    };

    /**
     * Init field
     * @param {HTMLElement} dom Dom of the field
     */
    GUI.Forms.Field.prototype.initField = function (dom) {
        dom.id = this.getId();

        dom.addClass(this.fieldClass);
        dom.addClass(this.className);

        if (this.addClass !== null) {
            dom.addClass(this.addClass);
        }

        if (this.readOnly) {
            dom.readOnly = true;
        }
        if (this.tabIndex > -1) {
            dom.setAttribute('tabIndex', this.tabIndex);
        }
        if (this.autocomplete) {
            dom.setAttribute('autocomplete', this.autocomplete);
        }
        if (this.title) {
            dom.setAttribute('title', this.title);
        }

        // Save default value to be used for reset
        this.defaultValue = this.value;
    };

    /**
     * Init validators
     */
    GUI.Forms.Field.prototype.initValidators = function () {
        var name;
        if (this.livevalidation) {
            this.validationTask = new GUI.Utils.DelayedTask(this.validate, this);
        }

        if (this.validation) {
            for (name in this.validation) {
                this.addValidator(name, this.validation[name]);
            }
        }
    };

    /**
     * Adds validator to this field
     * @param {String} name Name of validator
     * @param {Object} params Parameter, passed to constructor of validator
     */
    GUI.Forms.Field.prototype.addValidator = function (name, params) {
        name = name.capitalize();
        if (!GUI.Forms.Validation[name]) {
            throw new Error('Trying to add unknown validator: ' + name);
        }

        var validator = new GUI.Forms.Validation[name]({
            field   : this,
            params  : params
        });

        if (!this.validators[name]) {
            this.validators[name] = validator;
        } else {
            throw new Error('Trying to add another validator with the same name');
        }
    };

    /**
     * Adjusting size
     * @param {Number} w Width
     * @param {Number} h Height
     * @returns {Object} size
     */
    GUI.Forms.Field.prototype.adjustSize = function (w, h) {
        var s = superproto.adjustSize.call(this, w, h);
        s.width = this.adjustWidth(this.dom.nodeName, s.width);
        return s;
    };

    /**
     * Adjusting width for strict mode
     * @param {Element} tag Tag
     * @param {Number} w Width
     */
    GUI.Forms.Field.prototype.adjustWidth  = function (tag, w) {
        if (typeof w === 'number') {
            if (GUI.isStrict) {
                w -= 6;
            }
        }
        return w;
    };

    /**
     * Handler after render
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.Field.prototype.onAfterRender = function (dom) {
        // call superclass
        superproto.onAfterRender.call(this, dom);

        this.fieldEl = dom;

        if (!GUI.LTR) {
            this.fieldEl.setAttribute('dir', 'auto');
        }

        if (this.value !== undefined) {
            // Value is specified, set it
            this.setValue(this.value, true); // do not fire change event
        } else if (!this.doNotReadValueFromDom) {
            if (dom.value.length > 0 && dom.value !== this.defaultText) {
                // Else get it from element
                this.setValue(dom.value, true); // do not fire change event
            }
        }
        // reference to original value for reset
        this.originalValue = this.getValue();

        if (this.cssFloat !== 'none') {
            dom.style[GUI.isIE ? 'styleFloat' : 'cssFloat'] = this.cssFloat;
        }
    };

    /**
     * Attach events 'focus', 'blur', 'mouseneter', 'mouseleave', 'keyup'
     */
    GUI.Forms.Field.prototype.attachEventListeners = function () {
        this.fieldEl.on('focus', this.onFocus, this);
        this.fieldEl.on('blur',  this.onBlur,  this);

        this.fieldEl.on('keyup', this.onKeyUp, this);
    };

    /**
     * Remove all listeners
     */
    GUI.Forms.Field.prototype.removeEventListeners = function () {
        this.fieldEl.un();
    };

    /**
     * Cancel validation task, destroy error holder, deletes validators
     * @param {Boolean} fast
     */
    GUI.Forms.Field.prototype.onDestroy = function (fast) {
        var name;
        if (this.validationTask) {
            this.validationTask.cancel();
        }

        if (this.hideTask) {
            this.hideTask.cancel();
        }

        if (this.errorHolder) {
            this.errorHolder.destroy();
            this.errorHolder = null;
        }

        for (name in this.validators) {
            delete this.validators[name];
        }

        superproto.onDestroy.call(this, fast);
    };

    /**
     * Sets raw value to default text, add css class defaultTextClass
     */
    GUI.Forms.Field.prototype.applyDefaultText = function () {
        if (this.fieldEl && this.defaultText && (this.getRawValue().length === 0
            || this.getRawValue() === this.defaultText)) {

            this.setRawValue(this.defaultText);
            this.fieldEl.addClass(this.defaultTextClass);
        }
    };

    /**
     * Disable field
     */
    GUI.Forms.Field.prototype.onDisable = function () {
        superproto.onDisable.call(this);
        this.fieldEl.disabled = true;
    };

    /**
     * Enable field
     */
    GUI.Forms.Field.prototype.onEnable = function () {
        superproto.onEnable.call(this);
        this.fieldEl.disabled = false;
    };

    /**
     * Add css class focusClass, select field, focused, fire event 'focus'
     */
    GUI.Forms.Field.prototype.onFocus = function () {
        if (!this.focused) {
            // TODO: check in opera
            //this.fieldEl.addClass(this.focusClass);
            if (this.selectOnFocus) {
                this.fieldEl.select();
            }
            this.focused = true;

            // Remember start value to emulate "change" event
            this.startValue = this.getValue();
            this.fireEvent('focus', this);
        }
    };

    /**
     * empty function
     */
    GUI.Forms.Field.prototype.onBeforeBlur = function () {};

    /**
     * Remove css class focusClass, validate, fire event 'blur'
     */
    GUI.Forms.Field.prototype.onBlur = function () {
        this.onBeforeBlur();
        // TODO: check in opera
        //this.fieldEl.removeClass(this.focusClass);
        this.focused = false;

        if (this.validateOnBlur) {
            this.validate();
        }

        // Change event emulation because of browser inconsistence
        var v = this.getValue();
        if (v !== this.startValue) {
            this.fireEvent('change', this, v, this.startValue);
        }
        this.fireEvent('blur', this);
    };

    /**
     * Start validation task with config delay
     * @param {Event} e Event
     */
    GUI.Forms.Field.prototype.onKeyUp = function (e) {
        if (!GUI.isNavKeyPress(e) && this.livevalidation) {
            this.validationTask.delay(this.validationDelay);
        }

        this.fireEvent('keyup', e);
    };

    /**
     * Returns DOM node of field
     * @returns {HTMLElement} dom
     */
    GUI.Forms.Field.prototype.getElement = function () {
        return this.dom;
    };

    /**
     * Returns id of the foused element
     * @returns {Number|String} id
     */
    GUI.Forms.Field.prototype.getFocusElId = function () {
        return this.id;
    };
}());