/**
 * JavaScript Graphical User Interface
 * i18n implementation
 *
 * @author Inna
 * @version 2.0
 * @namespace GUI
 */

GUIi18n = {

    "SU"    : [null, "SU"],
    "MO"    : [null, "MO"],
    "TU"    : [null, "TU"],
    "WE"    : [null, "WE"],
    "TH"    : [null, "TH"],
    "FR"    : [null, "FR"],
    "SA"    : [null, "SA"],

    "January"   : [null, "January"],
    "February"  : [null, "February"],
    "March"     : [null, "March"],
    "April"     : [null, "April"],
    "May"       : [null, "May"],
    "June"      : [null, "June"],
    "July"      : [null, "July"],
    "August"    : [null, "August"],
    "September" : [null, "September"],
    "October"   : [null, "October"],
    "November"  : [null, "November"],
    "December"  : [null, "December"],

    "Jan"       : [null, "Jan"],
    "Feb"       : [null, "Feb"],
    "Mar"       : [null, "Mar"],
    "Apr"       : [null, "Apr"],
    "Ma"       : [null, "May"],
    "Jun"       : [null, "Jun"],
    "Jul"       : [null, "Jul"],
    "Aug"       : [null, "Aug"],
    "Sep"       : [null, "Sep"],
    "Oct"       : [null, "Oct"],
    "Nov"       : [null, "Nov"],
    "Dec"       : [null, "Dec"],


    "OK"            : [null, 'OK'],
    "Cancel"        : [null, "Cancel"],
    "Close"         : [null, "Close"],


    "Add"           : [null, "Add"],
    "All"           : [null, "All"],
    "Apply"         : [null, "Apply"],
    "Attention!"    : [null, "Attention!"],
    "Action"        : [null, "Action"],

    "Create"        : [null, "Create"],
    "Collapse"      : [null, "Collapse"],

    "Delete"        : [null, "Delete"],

    "Edit"          : [null, "Edit"],
    "Expand"        : [null, "Expand"],

    "Insert"        : [null, "Insert"],

    "Loading..."    : [null, "Loading..."],

    "Next month"    : [null, "Next month"],
    "Next"          : [null, "Next"],

    "Print"         : [null, "Print"],
    "Page"          : [null, "Page"],
    "Prev month"    : [null, "Prev month"],
    "Prev"          : [null, "Prev"],

    "Selected:"     : [null, "Selected:"],
    "Save&Close"    : [null, "Save&Close"],
    "Select All"    : [null, "Select All"],
    "Searching..."  : [null, "Searching..."],

    "Today"         : [null, "Today"],


    "<b>Close</b><br>or Esc Key"        : [null, "<b>Close</b><br>or Esc Key"],
    "Double click to edit"              : [null, "Double click to edit"],
    "Items per Page"                   : [null, "Items per Page"],
    "Multiple Selection"                : [null, "Multiple Selection"],
    "No records found"                  : [null, "No records found"],
    "Search failure"                    : [null, "Search failure"],
    "There is no data to display"      : [null, "There is no data to display"],


    ""  : [null, ""],
    ""  : [null, ""],
    ""  : [null, ""],
    ""  : [null, ""],
    ""  : [null, ""],
    ""  : [null, ""]
};
if (!window.GUI) {
	GUI = {};
}

GUI.i18n = function() {
    // for translation purposes (parser)
    var _ = function (val) {
        return val;
    };

    return {
        GUI_PAGING_TPL: _("Displaying: %1$s-%2$s of %3$s"), // format for translation purposes
        GUI_PAGING_SIMPLE_TPL: _("%1$s of %2$s"),
        //GUI_WIZARD_TPL: _('Step {step} of {total}'),
        GUI_WIZARD_TPL: 'Step {step} of {total}',

        // upload files
        GUI_FILES_FAILED_UPLOAD: _('Files Failed to Upload'),
        GUI_FILE_EXTENSION_ERROR: _('File of this type is not allowed for upload. Here is the list of allowed file types:') + '<br /> {0}',
        GUI_NOT_SUPPORTED_DND: _("Your browser doesn't support drag'n'drop file uploads. Please upgrade."),

        // forms/validation/minlength.js
        GUI_VALIDATION_MINLENGTH: _('Minimal field length is %s'),

        // forms/validation/maxlength.js
        GUI_VALIDATION_MAXLENGTH: _('Maximal field length is %s'),

        // forms/validation/custom.js
        GUI_VALIDATION_CUSTOM: _('Pattern does not match'),

        // forms/validation/email.js
        GUI_VALIDATION_EMAIL: _('Invalid email'),

        // forms/validation/emailmultiple.js
        GUI_VALIDATION_EMAIL_MULTIPLE: _('At least one email is invalid'),

        // forms/validation/login.js
        GUI_VALIDATION_LOGIN: 'Invalid login',

        // forms/validation/siteurl.js
        GUI_VALIDATION_SITEURL: _('Invalid site URL'),

        // forms/validation/selected.js
        GUI_VALIDATION_SELECTED: _('Item is not selected'),

        // forms/validation/phone.js
        GUI_VALIDATION_PHONE: _('Invalid phone number'),

        // forms/validation/notempty.js
        GUI_VALIDATION_NOTEMPTY: _('Value must be not empty'),

        GUI_VALIDATION_NUMBER: _('Invalid number'),

        GUI_VALIDATION_DATE: _('Invalid date'),

        // layouts/informer.js
        //GUI_CLICK_TO_HIDE: _('Click to hide'),
        GUI_CLICK_TO_HIDE: 'Click to hide',

        // popup/menu.js
        //GUI_POPUP_MENU_NO_ITEMS: _('No Menu Items'),
        GUI_POPUP_MENU_NO_ITEMS: 'No Menu Items',

        // messages
        GUI_DELETE_MESSAGE: _('Are you sure you want to delete this?'),
        GUI_DELETE_MESSAGE_TITLE: _('Delete')
    }
} ();