(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Application Layout implementation
     *
     * @author Inna Lavitskaya
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.AppLayout = Class.create();
    Object.extend(GUI.AppLayout.prototype, superproto);

    /**
     *
     */
    GUI.AppLayout.prototype.template =
        '<div id="{this.mainMenuId}" class="l-layout__head"></div>' +
        '<div id="{this.config.id}" class="l-layout"></div>';

    /**
     * Constructor
     * @param {Object} config Configuration obect
     */
    GUI.AppLayout.prototype.initialize = function (config) {
        var cfg;

        // Call parent method
        superproto.initialize.call(this);

        this.config = {
            id          : GUI.getUniqId('layouts-data-'),
            holder      : config.holder || document.body,
            name        : null,
            lazyRender  : false
        };
        Object.extend(this.config, config);

        cfg = this.config;

        this.layouts = {};

        this.mainMenuId = GUI.getUniqId('mainmenu-');

        this.addEvents({
            resize: true,
            layoutChange    : true,
            beforeLayoutChange : true,
            afterRender     : true,
            sameLayout      : true
        });

        if (cfg.lazyRender) {
            this.render();
        }

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
    };

    /**
     * Renders dom
     */
    GUI.AppLayout.prototype.render = function () {
        var cfg, tpl;

        if (this.dom) {
            this.removeEventListeners();
            GUI.remove(this.dom);
            this.dom = null;
        }

        cfg = this.config;
        tpl = new GUI.STemplate(this.template, this);
        tpl.cfg = cfg;

        GUI.Dom.append(GUI.$(cfg.holder), tpl.fetch());

        this.dom = GUI.$(cfg.id);

        // render menu
        if (cfg.menu) {
            cfg.menu.render(this.mainMenuId);
        }
        this.mainMenu = cfg.menu;

        // render layouts
        this.layouts = cfg.layouts;

        this.renderLayouts();

        this.mask = new GUI.Popup.Mask({
            element : this.dom,
            text    : i18n('Loading...')
        });

        this.fireEvent('afterRender', this);

        this.attachEventListeners();
        this.onWindowResize(GUI.getViewportWidth(), GUI.getViewportHeight());
    };

    /**
     * Renders layouts
     */
    GUI.AppLayout.prototype.renderLayouts = function () {
        var i, 
            layouts = this.layouts;

        for (i = 0; i < layouts.length; i++) {
            layouts[i] = this.renderOneLayout(layouts[i]);
        }

        GUI.Dom.extend(this.dom);
    };

    GUI.AppLayout.prototype.renderOneLayout = function (layout) {
        var divL, id, l,
            dom = this.dom;

        id = GUI.getUniqId('layout-' + (layout.name + '-' || ''));

        divL = document.createElement('DIV');
        divL.className = 'l-layout__item ' + layout.name;
        divL.id = id;
        dom.appendChild(divL);
        GUI.hide(divL);

        if (layout.visible) {
            this.currentLayout = layout.name;
        }

        l = new GUI.Layout({
            holder  : divL,
            id      : id,
            name    : layout.name,
            columns : layout.columns,
            cellClassName: layout.extraClass
        });

        l.render();

        return l;
    };

    /**
     * Attach events listeners
     */
    GUI.AppLayout.prototype.attachEventListeners = function () {
        GUI.Event.onWindowResize(this.onWindowResize, this);
    };

    /**
     * Removes events listeners
     */
    GUI.AppLayout.prototype.removeEventListeners = function () {
        GUI.Event.unWindowResize(this.onWindowResize, this);
    };

    /**
     * Handler resizing of window
     * @param {Number} w Width
     * @param {Number} h Height
     */
    GUI.AppLayout.prototype.onWindowResize = function (windowWidth, windowHeight) {
        var dom = this.dom,
            menuHeight = GUI.$(this.mainMenuId).offsetHeight;

        GUI.setFullHeight(dom, windowHeight/* - menuHeight*/);
    };

    /**
     * If item has connected layout, then switch to it
     * @param {Object} menu
     * @param {Object} item
     */
    GUI.AppLayout.prototype.handleMenuClick = function (menu, item, e) {
        var name = item.connectedLayout;

        if (name) {
            this.switchToLayout(name);

        } else if (item && item.onClick) {
            item.onClick.call(this, menu, item, e);
        }
    };

    /**
     * Switch to layout
     * @param {String} name Name of the layout
     */
    GUI.AppLayout.prototype.switchToLayout = function (name) {
        if (this.currentLayout === name) {
            this.fireEvent('sameLayout', this, name);
            return;
        }

        if (!this.config.silent) {
            if (!this.fireEvent('beforeLayoutChange', this, this.currentLayout, name)) {
                return;
            }
        }

        // set selected item in main menu
        if (this.mainMenu && this.mainMenu._indexByName[name]) {
            this.mainMenu.setSelectedItem(this.mainMenu._indexByName[name]);
        }

        if (this.currentLayout) {
            this.hideLayout(this.currentLayout);
        }

        if (this.showLayout(name)) {
            var oldLayout = this.currentLayout;
            this.currentLayout = name;

            if (!this.config.silent) {
                this.fireEvent('layoutChange', this, oldLayout, name);
            }

        } else {
            this.currentLayout = null;
        }
    };

    /**
     * Showes layout dom
     */
    GUI.AppLayout.prototype.showLayout = function (name) {
        var l = this.getLayout(name);

        if (l) {
            GUI.show(l.dom);
            return true;

        } else {
            console.log('Layout with name ' + name + ' is not exists');
            return false;
        }
    };

    /**
     * Hides layout dom
     */
    GUI.AppLayout.prototype.hideLayout = function (name) {
        var l = this.getLayout(name);

        if (l) {
            GUI.hide(l.dom);

        } else {
            console.log('Layout with name ' + name + ' is not exists');
        }
    };

    /**
     * Returns layout by the name
     */
    GUI.AppLayout.prototype.getLayout = function (name) {
        return this.findItem('name', name);
    };

    /**
     * Finds item by the value
     */
    GUI.AppLayout.prototype.findItem = function (field, value) {
        var i;

        for (i = 0; i < this.layouts.length; i++) {
            if ((this.layouts[i][field] + '') === (value + '')) {
                return this.layouts[i];
            }
        }
    };

}());
