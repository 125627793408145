(function () {
    var superproto = GUI.Application.prototype;

    GUI.Domain = Class.create();
    Object.extend(GUI.Domain.prototype, superproto);

    /**
     * Type of the module, default is 'Domain'
     * @type String
     */
    GUI.Domain.prototype.type = 'Domain';

    /**
     * Initializes controller
     * @param {Object} data
     */
    GUI.Domain.prototype.initialize = function (data) {
        this._needChangeDataFireEvent = false;
        this._changeDataTypes = [];
        this._lock = false;

        this.addEvents([
            'destroy',
            'changeData'
        ]);
        this.data = Object.extend({}, data); // Defence from weak refer
        this.initGetter(data);
        this.initSetter(data);

        this.autoSetData(data);
    };

    /**
     * Update getters and setters from real data in domain
     * @returns {undefined}
     */
    GUI.Domain.prototype.updateMethods = function () {
        this.initGetter(this.data);
        this.initSetter(this.data);
    };

    /**
     * Fire event, if domain is call to destroy
     * @returns {undefined}
     */
    GUI.Domain.prototype.destroy = function () {
        this.fireEvent('destroy');
    };

    /**
     *
     * @param {Object} data
     * @returns {undefined}
     */
    GUI.Domain.prototype.initGetter = function (data) {
        var key, getter;

        getter = function (key) {
            return function () {
                return this.data[key];
            };
        };

        for (key in data) {
            if (!(this['get' + key.capitalizeFirstWord()] instanceof Function)) {
                this['get' + key.capitalizeFirstWord()] = getter(key);
            }
        }
    };

    /**
     *
     * @param {Object} data
     * @returns {undefined}
     */
    GUI.Domain.prototype.initSetter = function (data) {
        var key, setter;

        setter = function (key) {
            return function (value) {
                this.data[key] = value;
            };
        };

        for (key in data) {
            if (!(this['set' + key.capitalizeFirstWord()] instanceof Function)) {
                this['set' + key.capitalizeFirstWord()] = setter(key);
            }
        }
    };

    /**
     * Auto set data via setters function
     *
     * @param {Object} data
     * @returns {undefined}
     */
    GUI.Domain.prototype.autoSetData = function (data) {
        var key;
        for (key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }
            if (data[key] !== this.data[key]) {
                this.addToFire(key);
            }
            this['set' + key.capitalizeFirstWord()](data[key]);
        }
    };

    /**
     * Add domain property to create
     *
     * @param {String} property
     */
    GUI.Domain.prototype.addToFire = function (property) {
        this._needChangeDataFireEvent = true;
        this._changeDataTypes.push(property);
    };

    /**
     * Method for create signals about change domain data
     */
    GUI.Domain.prototype.completeEdit = function () {
        var i, eventName;

        if (!this._needChangeDataFireEvent || this._changeDataTypes.length === 0) {
            this._needChangeDataFireEvent = false;
            this._changeDataTypes = [];
            return;
        }

        this.fireEvent('changeData', this);
        for (i = 0; i < this._changeDataTypes.length; i++) {
            eventName = 'changeData' + this._changeDataTypes[i].capitalizeFirstWord();
            this.fireEvent(eventName, this, eventName);
        }

        this._needChangeDataFireEvent = false;
        this._changeDataTypes = [];
    };

    /**
     * Locking domain of delete
     */
    GUI.Domain.prototype.lock = function () {
        this._lock = true;
    };

    /**
     * Unlock domain for delete
     */
    GUI.Domain.prototype.release = function () {
        this._lock = false;
    };

    /**
     * Return, if domain is locked
     * @returns {boolean}
     */
    GUI.Domain.prototype.isLock = function () {
        return this._lock;
    };

    /**
     * Clone object
     * @return {GUI.Domain}
     */
    GUI.Domain.prototype.clone = function () {
        return new this.constructor(this.data);
    };

}());



