(function () {
    var superproto = GUI.Layouts.Layout.prototype;

    /**
    * JavaScript Graphical User Interface
    * Carousel Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    * @extends GUI.Layouts.Layout
    */
    GUI.Layouts.Carousel = Class.create();
    Object.extend(GUI.Layouts.Carousel.prototype, superproto);

    /**
     *
     */
    GUI.Layouts.Carousel.prototype.monitorResize = true;

    /**
     * True to render each contained item at the time it becomes active, false to render all contained items
     * as soon as the layout is rendered (defaults to false).  If there is a significant amount of content or
     * a lot of heavy controls being rendered into panels that are not displayed by default, setting this to
     * true might improve performance.
     * @type Boolean
     */
    GUI.Layouts.Carousel.prototype.deferredRender = false;

    /**
     * Default to false
     * @type Boolean
     */
    GUI.Layouts.Carousel.prototype.renderHidden = false;

    /**
     * Default to false
     * @type Boolean
     */
    GUI.Layouts.Carousel.prototype.wrapped = false;

    /**
     * Css class, default to 'jsgui-layout-carousel-item'
     * @type String
     */
    GUI.Layouts.Carousel.prototype.extraCls = 'jsgui-layout-carousel-item';

    /**
     * Default to true
     * @type Boolean
     */
    GUI.Layouts.Carousel.prototype.animate = true;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Layouts.Carousel.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);

        if (this.animate) {
            this.fx = new Animator({
                duration: 500
            });
        }
    };

    /**
     * Sets the active (visible) item in the layout.
     * @param {String|Number} item The string component id or numeric index of the item to activate
     */
    GUI.Layouts.Carousel.prototype.setActiveItem  = function (item) {
        var itemIndex, offset,
            ct = this.container;

        item = ct.getComponent(item);
        if (this.activeItem !== item) {
            if (this.wrapEl) {
                itemIndex = ct.items.indexOf(item);

                if (this.animate) {
                    this.fx.seekTo(itemIndex / (ct.items.length - 1));
                } else {
                    offset = itemIndex * this.wrapEl.parentNode.offsetWidth;
                    this.wrapEl.style.left = (-offset) + 'px';
                }
            }
            this.activeItem = item;
        }
    };

    /**
     * Renders all
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Carousel.prototype.renderAll  = function (ct, to) {
        if (!this.wrapped) {
            var div = document.createElement('div');
            div.className = 'jsgui-layout-carousel-wrap';
            to.appendChild(div);
            to = div;
            this.wrapEl = div;
            this.wrapped = true;
        } else {
            to = this.wrapEl;
        }

        if (this.deferredRender) {
            this.renderItem(this.activeItem, undefined, to);
        } else {
            superproto.renderAll.call(this, ct, to);
        }
    };

    /**
     * Prepares animation objects, sets position and size
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Carousel.prototype.onLayout = function (ct, to) {
        superproto.onLayout.call(this, ct, to);
        if (!this.container.collapsed) {
            var item, itemIndex, offset,
                size = GUI.getDimensions(to),
                items = ct.items.items,
                i = items.length,
                wrapWidth = this.wrapEl.parentNode.offsetWidth;

            if (!this.fx.isRunning()) {
                this.fx.clearSubjects();
                this.fx.addSubject(new window.NumericalStyleSubject(this.wrapEl, 'left', 0, (1 - i) * wrapWidth));
            }

            itemIndex = this.container.items.indexOf(this.activeItem);
            offset = itemIndex * wrapWidth;
            this.wrapEl.style.left = (-offset) + 'px';

            while (i--) {
                item = items[i];
                this.setItemPosition(item, size, i);
                this.setItemSize(item, size);
            }
        }
    };

    /**
     * Sets item position
     * @param {Object} item
     * @param {Number} size
     * @param {Number} index
     */
    GUI.Layouts.Carousel.prototype.setItemPosition = function (item, size, index) {
        if (item) {
            var offset = index * size.width;
            item.setPosition(offset, 0);
        }
    };

    /**
     * Sets item size
     * @param {Object} item
     * @param {Number} size
     */
    GUI.Layouts.Carousel.prototype.setItemSize = function (item, size) {
        if (item && size.height > 0) {
            item.setSize(size);
        }
    };

}());