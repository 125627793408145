(function () {
    var superproto = new GUI.Utils.Observable();

    window.Application = Class.create();

    prototype = {
        settings      : {},
        authorization : {},

        initialize : function () {
            this.addEvents({
                login            : true, // if user just passed authorization form
                authenticated    : true, // if user is already authenticated
                notauthenticated : true  // if user is not authenticated yet
            });

            this.documentEvents = {};

            this.checkAvatarsLoad();
        },

        addEventForDocument : function (event, handler) {
            if (!this.documentEvents[event]) {
                this.addEvent(event);
            }
            this.documentEvents[event].push(handler);
        },

        addEvent : function (event) {
            document.body.on(event, this.runHandlers.bindLegacy(this, event));
            this.documentEvents[event] = [];
        },

        runHandlers : function (event, e) {
            var i, handlers = this.documentEvents[event];

            for (i = 0; i < handlers.length; i++) {
                handlers[i](e);
            }
        },

        initSpaceComboBlock : function () {
            var spaceCombo;

            if (GUI.$('spaceCombo')) {
                spaceCombo = new GUI.Forms.Combo();
                spaceCombo.assign('spaceCombo');

                spaceCombo.on('change', function () {
                    window.location.href = spaceCombo.getValue();
                });
            }

            if (GUI.$('spaceFilter')) {
                spaceCombo = new GUI.Forms.Combo();
                spaceCombo.assign('spaceFilter');

                spaceCombo.on('change', function () {
                    window.location.href = spaceCombo.getValue();
                });
            }
        },

        initParticipationFilter : function () {
            if (GUI.$('participationFilter')) {
                var pFilter = new GUI.Forms.Combo();
                pFilter.assign('participationFilter');

                pFilter.on('change', function () {
                    window.location.href = pFilter.getValue();
                });
            }
        },

        initEmailNotificationListener : function () {
            if (GUI.$('emails-notifications-form')) {
                GUI.$('emails-notifications-form').on('submit', function () {
                    GUI.$('add-email-to-user').setAttribute('disabled', 'disabled');
                    GUI.$('save-profile-button').setAttribute('disabled', 'disabled');
                });
            }
        },

        initPasswordChangeSubmitListener : function () {
            if (GUI.$('password-form')) {
                GUI.$('password-form').on('submit', function () {
                    GUI.$('password-submit').setAttribute('disabled', 'disabled');
                });
            }
        },

        setInputElementsClassOnFocus : function (e) {
            e = GUI.Event.extend(e);
            var el = e.target, inp;

            if (this.focusedParentElInp) {
                GUI.Dom.removeClass(this.focusedParentElInp, 'text-field_advanced_focus');
            }

            if (el.nodeName.toLowerCase() === 'input') {
                this.focusedParentElInp = GUI.findParentByTag(el, 'i', null, 'text-field_advanced');
                if (this.focusedParentElInp) {
                    GUI.Dom.addClass(this.focusedParentElInp, 'text-field_advanced_focus');
                }
            }

            if (GUI.hasClass(el, 'fa_closeCircle') || GUI.hasClass(el, 'text-field__icon_clean')) {
                inp = GUI.Dom.findDescedent(el.parentNode, 'input');
                if (inp) {
                    inp.value = '';
                    inp.focus();
                }
                this.focusedParentElInp = GUI.findParentByTag(el, 'i', null, 'text-field_advanced');
                GUI.Dom.addClass(this.focusedParentElInp, 'text-field_advanced_focus');
            }
        },

        /**
         *
         */
        successInfo : function (text) {
            text = (typeof text === 'string') ? text : i18n('Done!');
            var informer = new GUI.Popup.PopupInformer({
                hideDelay   : window.hideInformerSuccessAfter,
                type        : 'success',
                text        : text
            });
            informer.show();
        },

        /**
         *
         */
        helpInfo : function (text) {
            var informer = new GUI.Popup.PopupInformer({
                hideDelay   : window.hideInformerInfoAfter,
                type        : 'info',
                text        : text || ''
            });
            informer.show();
        },

        /**
        *
        */
        errorInfo : function (text, hidePrevInformer) {
            var informer;

            hidePrevInformer = hidePrevInformer || false;

            if (hidePrevInformer) {
                this.hideErorInformer();
            }

            informer = new GUI.Popup.PopupInformer({
                hideDelay   : window.hideInformerErrorAfter,
                type        : 'error',
                text        : text || ''
            });
            informer.show();
        },

        hideErorInformer: function () {
            var informers,
                holderInformers = GUI.$('informer-holder');

            if (holderInformers) {
                informers = GUI.Dom.findDescedents(holderInformers, 'div.b-informer_error');
                if (informers && informers[0]) {
                    holderInformers.removeChild(informers[0]);
                }
            }
        },

        checkAvatarsLoad: function () {
            GUI.onDOMReady(function () {
                var images = document.querySelectorAll('img');

                if (!images.length) {
                    return;
                }
                for (var i = 0; i < images.length; i++) {
                    var img = images[i];

                    if (img.complete === false && (
                            img.src.indexOf('//secure.gravatar.com') !== -1
                            || (
                                img.src.indexOf('//s3.amazonaws.com/') !== -1 &&
                                img.src.indexOf('/avatars/') !==-1
                            )
                        )
                    ) {
                        img.onerror = function () {
                            onAvatarError(img);
                        };
                    }
                }
            });
        },

        initReCaptcha: function () {
            if (window.grecaptcha || (window.APPLICATION_ENV !== 'staging' && window.APPLICATION_ENV !== 'production')) {
                return;

            }
            var script = document.createElement('script');
            script.src = "https://www.google.com/recaptcha/api.js?onload=onReCaptchaLoad&render=explicit";

            document.head.appendChild(script);
        }
    };

    Object.extend(Application.prototype, superproto);
    Object.extend(Application.prototype, prototype);

    Application = new Application();
}());

window.onAvatarError = function(img) {
    var origSrc = img.src;

    img.onerror = null;
    img.src = window.defaultAvatar;
    img.className = img.className + ' avatar_default';

    setTimeout(function () {
        img.src = origSrc + '?&1';
        img.className = img.className.replace('avatar_default', '');

        img.onerror = function () {
            img.onerror = null;
            img.src = window.defaultAvatar;
            img.className = img.className + ' avatar_default';
        };
    }, 5000);
}