(function() {
   // var superproto = GUI.Bar.prototype;

    /**
     * JavaScript Graphical User Interface
     * GUI.ToolBar.Button implementation
     *
     * @author Inna
     * @version 2.0
     * @namespace GUI.ToolBar
     */
    GUI.ToolBar.ButtonGroups = Class.create();

   // Object.extend(GUI.ToolBar.ButtonGroups.prototype, superproto);

    /**
     * Css class of the toolbar, default is 'b-buttons-list'
     * @type String
     */
    GUI.ToolBar.ButtonGroups.prototype.className = 'b-buttons-list__group';

    GUI.ToolBar.ButtonGroups.prototype.extraClass = '';

    /**
     *
     */
    GUI.ToolBar.ButtonGroups.prototype.initialize = function (config) {
     //   superproto.initialize.apply(this, arguments);

        this.config = {};
        Object.extend(this.config, config);
    };

    /**
     *
     */
    GUI.ToolBar.ButtonGroups.prototype.render = function (holder) {
        var cfg = this.config,
            sp = document.createElement('SPAN');
        sp.className = this.className;
        cfg.items.reverse();
        cfg.items.each(function(item) {
            item.render(sp);
        });

        if (cfg.extraClass) {
            sp.addClass(cfg.extraClass);
        }

        cfg.items.each(function(item) {
            if (!item.canToggle) return;
            if (item.toggled) {
                this.toggled = item;
            }
            item.on('toggle', this.toggle, this);
        }.bindLegacy(this));

        holder.appendChild(sp);
        this.dom = sp;
    };

    /**
     *
     */
    GUI.ToolBar.ButtonGroups.prototype.unrender = function () {
        this.config.items.each(function (item) {
            item.unrender();

            if (!item.canToggle) return;
            item.un('toggle', this.toggle, this);
        });

        this.toggled = [];
    };

    GUI.ToolBar.ButtonGroups.prototype.toggle = function (obj, toggled) {
        if (toggled && this.toggled && this.config.singleToggle) {
            this.toggled.toggle(false);
        }
        if (toggled) {
            this.toggled = obj;
        }
    };

    GUI.ToolBar.ButtonGroups.prototype.toggleEl = function (name, toogled) {
        this.config.items.each(function(item) {
            if (item.name == name && item.canToggle) {
                item.toggle(toogled);
            }
        }.bindLegacy(this));
    };

    /**
     * Disables elements
     */
    GUI.ToolBar.ButtonGroups.prototype.disable = function () {
        this.config.items.each(function(item) {
            item.disable();
        });
    };

    /**
     * Enables elements
     */
    GUI.ToolBar.ButtonGroups.prototype.enable = function () {
        this.config.items.each(function(item) {
            item.enable();
        });
    };

    /**
     * Get Button Group wrapper
     */
    GUI.ToolBar.ButtonGroups.prototype.getDom = function () {
        return this.dom;
    };

}());