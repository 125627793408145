(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Number implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Number = Class.create();
    Object.extend(GUI.Forms.Validation.Number.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_NUMBER'
     * @type String
     */
    GUI.Forms.Validation.Number.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_NUMBER);

    /**
     * Type of the element, 'GUI.Forms.Validation.Number'
     * @type String
     */
    GUI.Forms.Validation.Number.prototype.elementType = 'GUI.Forms.Validation.Number';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Number.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        this.pattern =  /^\d*[\d\s.,]*$/;
    };

}());