(function () {
    var superproto = GUI.Layouts.Layout.prototype;

    /**
    * JavaScript Graphical User Interface
    * Fit Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    * @extends GUI.Layouts.Layout
    */
    GUI.Layouts.Fit = Class.create();
    Object.extend(GUI.Layouts.Fit.prototype, superproto);

    /**
     * Type of the element, default to 'true'
     * @type String
     */
    GUI.Layouts.Fit.prototype.elementType = 'Layouts.Fit';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Layouts.Fit.prototype.monitorResize = true;

    /**
     * Sets item size
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Fit.prototype.onLayout = function (ct, to) {
        superproto.onLayout.call(this, ct, to);
        if (!this.container.collapsed) {
            var size = GUI.getDimensions(to);
            this.setItemSize(this.activeItem || ct.items.itemAt(0), size);
        }
    };

    /**
     * Sets size
     * @param {Object} item
     * @param {Number} size
     */
    GUI.Layouts.Fit.prototype.setItemSize = function (item, size) {
        if (item && size.height > 0) {
            item.setSize(size);
        }
    };

}());