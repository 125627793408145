(function () { // variables isolation

    var RegistrationFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        _dialog   : null,
        _showed   : false,
        _formMask : null,
        _signupForm : {
            holder : null,
            form   : null
        },

        _setupFormElementListeners : function () {
            var key, element;

            GUI.$('registration-login-link').on(
                'click',
                function() {
                    this.close();
                    Application.AuthPanel.login();
                }.bindLegacy(this)
            );

            this._signupForm.holder = GUI.$('auth-dialog_signup-form');
            this._signupForm.form   = GUI.$('signup-form');

            for (key = 0; key < this._signupForm.form.elements.length; key++) {
                element = this._signupForm.form.elements[key];

                if (element.type && (element.type === 'text' || element.type === 'password')) {
                    this._setupInputListeners(element);
                }
            }

            this._signupForm.form.onsubmit = function (event) {
                try {
                    GUI.Dom.findDescedents(this._signupForm.holder, 'div.forms__notif_negative').each(GUI.remove);
                    GUI.Dom.findDescedents(this._signupForm.holder, 'div.forms__notif_positive').each(GUI.hide);
                    GUI.Dom.findDescedents(this._signupForm.holder, 'div.forms__notif').each(GUI.hide);
                } catch (e) {
                    console.log(e);
                }

                event = GUI.Event.extend(event);
                event.preventDefault();
                event.stopPropagation();

                if (window.APPLICATION_ENV === 'staging' || window.APPLICATION_ENV === 'production') {
                    grecaptcha.execute(GUI.$("signUpReCaptcha").getAttribute('data-id'));
                } else {
                    this.submit();
                }
                return false;
            }.bindLegacy(this);
        },

        _setupInputListeners : function (element) {
            var holder, hint;
            // add inline hints
            if (GUI.Dom.hasClass(element, 'text-field_default_value')) {
                hint = element.value; // closure - each input will have own hint value

                element.on('focus', function () {
                    if (element.value === hint) {
                        GUI.Dom.removeClass(element, 'text-field_default_value');
                        element.value = '';
                    }
                }, this);

                element.on('blur', function () {
                    if (element.value === '') {
                        GUI.Dom.addClass(element, 'text-field_default_value');
                        element.value = hint;
                    }
                }, this);
            }

            // hide all side hints
            holder = GUI.findParentByTag(element, 'div', null, 'dialog__item');

            // show side hint on input's focus
            element.on('focus', function () {
                var hint = GUI.Dom.findDescedent(holder, 'div.forms__notif');
                if (hint) {
                    GUI.Dom.findDescedents(holder, 'div.forms__notif').each(GUI.hide);
                    GUI.Dom.findDescedents(holder, 'div.forms__notif_description').each(GUI.show);
                    GUI.show(hint);
                }
            }, this);
        },

        initialize : function () {
            this.addEvents({
                show : true,
                hide : true
            });

            GUI.onDOMReady(function () {
                this._dialog = new Application.CommonDialogClass('auth');

                if (GUI.$('signup-form') && GUI.$('auth-dialog_signup-form')) {
                    this._setupFormElementListeners();
                }

                this._formMask = new GUI.Popup.Mask({className : 'mask', animate : false, stylePosition: 'absolute'});
                this._formMask.setElement(this._dialog.getContentHolder());
            }.bindLegacy(this));
        },

        show : function () {
            this.close();

            // CHD-298
            var i,
                dialogs = GUI.Dom.findDescedents(document, 'div.login_dialog');

            for (i = 0; i < dialogs.length; i++) {
                GUI.hide(dialogs[i]);
            }

            if (!this._showed) {

                this._dialog.close();

                this._dialog.onShow = function () {
                    GUI.show(this._signupForm.holder);

                    Application.initReCaptcha();

                    this._showed = true;

                    this._dialog.setCaption(i18n('Sign Up'));

                    this.fireEvent('show');

                    var holder = this._signupForm.form;

                    GUI.Dom.findDescedents(holder, 'div.forms__notif_negative').each(GUI.remove);
                    GUI.Dom.findDescedents(holder, 'div.forms__notif_positive').each(GUI.hide);
                    GUI.Dom.findDescedents(holder, 'div.forms__notif').each(GUI.hide);
                }.bindLegacy(this);

                this._dialog.onClose = function () {
                    GUI.hide(this._signupForm.holder);
                    this._showed = false;
                    this.fireEvent('hide');
                }.bindLegacy(this);

                this._dialog.show();
                GUI.$('registration-name').focus();

                if (window._gaq) {
                    window._gaq.push(['_trackPageview', '/register-open']);
                }
            }
        },

        close : function () {
            if (this._showed) {
                this._dialog.close();
            }
        },

        applyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }

            if (!node) {
                return;
            }
            node.on('click', this.show, this);
        },

        unapplyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }
            node.un('click', this.show, this);
        },

        submit: function () {
            var ajax, params = {};

            this._formMask.show();

            for (key = 0; key < this._signupForm.form.elements.length; key++) {
                params[this._signupForm.form.elements[key].name] = this._signupForm.form.elements[key].value;

                if (GUI.Dom.hasClass(this._signupForm.form.elements[key], 'text-field_default_value')) {
                    params[this._signupForm.form.elements[key].name] = '';
                }
            }

            ajax = new GUI.Ajax.Request(this._signupForm.form.action, {
                method : 'post',
                data : params,
                onSuccess : function (response) {
                    var elementName, errorType, errorMsg, element, holder, hint;

                    this._formMask.hide();

                    if (window.APPLICATION_ENV === 'staging' || window.APPLICATION_ENV === 'production') {
                        grecaptcha.reset(GUI.$("signUpReCaptcha").getAttribute('data-id'));
                    }

                    response = response.responseText.evalJSON();

                    if (response.done) {
                        // CHD-358
                        this.close();
                        Application.AuthPanel.login();
                        Application.helpInfo(response.message);

                    } else {

                        if (response.error) {
                            Application.errorInfo(response.message);

                        } else {
                            for (elementName in response.validation) {

                                for (errorType in response.validation[elementName]) {
                                    element = elementName;

                                    hint = '<div class="hint hint_error hint_corner hint_corner_left forms__notif forms__notif_negative"><p class="hint__txt description">';
                                    for (errorMsg in response.validation[elementName][errorType]) {
                                        hint += response.validation[elementName][errorType][errorMsg];

                                        if ('email' === elementName) {
                                            hint += '. <a id="register-forgot-password" href="#">' + i18n('Forgot your password?') + '</a>';
                                        }

                                        hint += '<br />';
                                    }
                                    hint += '</p></div>';

                                    holder = GUI.$('registration-' + element + '-msg-holder');
                                    if (holder) {
                                        GUI.Dom.findDescedents(holder, 'div.forms__notif_negative').each(GUI.remove);
                                        GUI.Dom.findDescedents(holder, 'div.forms__notif_positive').each(GUI.hide);
                                        GUI.Dom.findDescedents(holder, 'div.forms__notif').each(GUI.hide);
                                        GUI.Dom.append(holder, hint);
                                    }

                                    if (GUI.$('register-forgot-password')) {
                                        GUI.$('register-forgot-password').on(
                                            'click',
                                            function () {
                                                this.close();
                                                Application.AuthPanel.showForgotPassword();
                                            }.bindLegacy(this)
                                        );
                                    }
                                }
                            }
                        }
                    }
                }.bindLegacy(this)
            });
        }
    };

    Object.extend(RegistrationFormClass.prototype, superproto);
    Object.extend(RegistrationFormClass.prototype, prototype);

    Application.RegistrationForm = new RegistrationFormClass();

}());