(function () {
    var superproto = GUI.Forms.Validation.Email.prototype;
    /**
     * JavaScript Graphical User Interface
     * Forms.Validation.Email implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms.Validation
     * @extends GUI.Forms.Validation.Custom
     */
    GUI.Forms.Validation.Emailmultiple = Class.create();
    Object.extend(GUI.Forms.Validation.Emailmultiple.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_EMAIL'
     * @type String
     */
    GUI.Forms.Validation.Emailmultiple.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_EMAIL_MULTIPLE);

    /**
     * Element type, 'GUI.Forms.Validation.Emailmultiple'
     * @type String
     */
    GUI.Forms.Validation.Emailmultiple.prototype.elementType = 'GUI.Forms.Validation.Emailmultiple';


    GUI.Forms.Validation.Emailmultiple.prototype.validateFunc = function (value) {
        if (!value) {
            return true;
        }

        var emails = value.split(',');
        for (var i=0; i < emails.length; i++) {
            if (!this.pattern.test(emails[i].trim())) {
                return false;
            }
        }

        return true;
    };

}());

