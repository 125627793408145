(function () {
    var superproto = GUI.Popup.Region.prototype;

    /**
    * JavaScript Graphical User Interface
    * GUI.Popup.Layer implementation
    *
    * @author Viacheslav.Lucenko
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Popup.Region
    */
    GUI.Popup.Layer = Class.create();
    Object.extend(GUI.Popup.Layer.prototype, superproto);

    GUI.Popup.Layer.DUMMY_TARGET = 'dummy';

    GUI.Popup.Layer.prototype.initialize = function (config) {
        var defCfg, cfg;

        defCfg = {
            id          : GUI.getUniqId('layer-'),

            // GUI.Popup.Region instance for show popup information
            layer       : null,

            // Base target for show popup element
            target      : null,

            delayShow   : 200,
            delayHide   : 300,

            // Permanently show layer and don't hide after leave element
            useClickToShow: false,

            // Change base position target to current argument. Can change via function
            positionTarget: false,

            // Disable layer events. You can disable mouse enter and show layer manually
            disableProcessEvent: {
                onMouseMove: false,
                onMouseEnter: false,
                onMouseLeave: false
            },

            // Disable auto set array position
            arrowDefaultPosition: false
        };

        if (config.target === GUI.Popup.Layer.DUMMY_TARGET) {
            config.target = document.createElement('div');
        }

        Object.extend(defCfg, config);

        // Call parent initialize meethod
        superproto.initialize.call(this, defCfg);
        Object.extend(this.config, defCfg);

        cfg = this.config;

        this.elementType = 'GUI.Popup.Layer';

        this.layer = cfg.layer;

        this.timeoutHideLayer = null;
        this.timeoutShowLayer = null;

        this.isLayerShowed = false;
        this.permanentShow = false;


        this.addEventsListeners();

        this.addEvents([
            'onMouseEnter',
            'onMouseLeave',
            'onMouseMove',
            'onShowLayer',
            'onHideLayer'
        ])
    };

    GUI.Popup.Layer.prototype.addEventsListeners = function () {
        if (this.config.useClickToShow) {
            this.config.target.on('click', this.toggleLayer, this);
        }

        this.config.target.on('mousemove', this.onMouseMove, this);
        this.config.target.on('mouseenter', this.onMouseEnter, this);
        this.config.target.on('mouseleave', this.onMouseLeave, this);
        GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.tinymceMouseEnter, this.onMouseLeave, this);
    };

    GUI.Popup.Layer.prototype.onMouseEnter = function (e) {
        if (this.config.disableProcessEvent.onMouseEnter) {
            return;
        }

        this.startTimerForShowLayer();

        this.fireEvent('onMouseEnter', e);
    };

    GUI.Popup.Layer.prototype.onMouseLeave = function (e) {
        if (this.config.disableProcessEvent.onMouseLeave) {
            return;
        }

        this.startTimerForHideLayer();

        this.fireEvent('onMouseLeave', e);
    };

    GUI.Popup.Layer.prototype.onMouseMove = function (e) {
        if (this.config.disableProcessEvent.onMouseMove) {
            return;
        }

        if (this.hasListener('onMouseMove')) {
            this.fireEvent('onMouseMove', e);
        }
    };

    GUI.Popup.Layer.prototype.startTimerForShowLayer = function () {
        if (this.permanentShow) {
            return;
        }

        if (this.timeoutHideLayer) {
            this.stopTimerForHideLayer();
            return;
        }

        this.stopTimerForShowLayer();
        this.timeoutShowLayer = setTimeout(this.showLayer.bindLegacy(this), this.config.delayShow);
    };

    GUI.Popup.Layer.prototype.startTimerForHideLayer = function () {
        if (this.permanentShow) {
            return;
        }

        if (this.timeoutShowLayer) {
            this.stopTimerForShowLayer();
            return;
        }

        this.stopTimerForHideLayer();
        this.timeoutHideLayer = setTimeout(this.hideLayer.bindLegacy(this), this.config.delayHide);
    };

    GUI.Popup.Layer.prototype.stopTimerForShowLayer = function () {
        clearTimeout(this.timeoutShowLayer);
        this.timeoutShowLayer = null;
    };

    GUI.Popup.Layer.prototype.stopTimerForHideLayer = function () {
        clearTimeout(this.timeoutHideLayer);
        this.timeoutHideLayer = null;
    };

    GUI.Popup.Layer.prototype.showLayer = function () {
        this.stopTimerForShowLayer();

        if (this.config.layer.dom) {
            return;
        }

        this.config.layer.show();
        this.setPosition();

        this.config.layer.dom.on('mouseenter', this.onMouseEnter, this);
        this.config.layer.dom.on('mouseleave', this.onMouseLeave, this);

        //if (!this.permanentShow) {
        //    function clickHideOnBodyClick() {
        //        this.hideLayer();
        //        document.body.un('click', clickHideOnBodyClick, this);
        //    }
        //
        //    document.body.on('click', clickHideOnBodyClick, this);
        //}

        this.isLayerShowed = true;
        this.fireEvent('onShowLayer');
    };

    /**
     * Permanent show layer and hide it after click on body
     */
    GUI.Popup.Layer.prototype.permanentShowLayer = function () {
        this.permanentShow = true;

        if (!this.config.layer.dom) {
            this.showLayer();
        } else {
            this.setPosition();
            this.fireEvent('onShowLayer');
        }
    };

    /**
     * Immediately hide layer
     */
    GUI.Popup.Layer.prototype.hideLayer = function (e) {
        if (this.fireEvent('beforeHideLayer', e) === false) {
            return;
        }

        this.stopTimerForHideLayer();

        document.body.un('mouseup', this._hideContentOnBodyClick, this);
        GUI.Utils.globalEvents.un(GUI.Utils.globalEvents.names.hidePopupElements, this.hideLayer, this);

        if (this.config.layer.dom) {
            this.config.layer.dom.un('mouseenter', this.onMouseEnter, this);
            this.config.layer.dom.un('mouseleave', this.onMouseLeave, this);
            this.config.layer.hide();
        }

        this.permanentShow = false;
        this.isLayerShowed = false;
        this.fireEvent('onHideLayer');
    };

    /**
     *
     * @param {Event} e
     * @return {boolean} If true - layer as showed, false - layer hidden
     */
    GUI.Popup.Layer.prototype.toggleLayer = function (e) {
        if (e) {
            e = GUI.Event.extend(e);
            e.stopPropagation();
            e.preventDefault();
        }

        if (this.permanentShow) {
            this.hideLayer();
        } else {
            document.body.on('mouseup', this._hideContentOnBodyClick, this);
            GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hideLayer, this);
            this.permanentShowLayer();
        }

        return false;
    };

    /**
     *
     * @param {GUI.Popup.Region.dom} layer  Showed layer
     * @param {HTMLElement} node            Position layer at this elememn
     * @param {Array} offset_               Offsets
     */
    GUI.Popup.Layer.prototype.setPosition = function (layer, node, offset_) {
        var region, regionDim, pos, offset, cls,
            cfg = this.config;

        pos = cfg.position || 'tr-br?';
        offset = offset_ || cfg.offset || [0, 6];

        if (!this.config.layer.dom) {
            return;
        }

        layer = layer || this.config.layer.dom;
        node = node || this.config.positionTarget || this.config.target;

        region = this.config.layer;
        region.dom.setStyle({left: 0, top: 0});
        region.alignTo(node, pos, offset);

        if (cfg.arrowDefaultPosition === false) {
            if (layer.className.search('b-arrow') === -1) {
                layer.addClass('b-arrow');
            }

            if (layer.className.search('b-arrow_') !== -1) {
                layer.className = layer.className.replace(/( b-arrow_[^\s]*)/gm, '');
            }

            if (region.shiftY === 'under-bottom') {
                cls = 'b-arrow_bottom';

            } else {
                cls = 'b-arrow_top';
            }

            if (region.shiftX === 'right') {
                cls += '_right';
                region.alignTo(node, 'tr-br?', offset);

            } else if (pos.search('r') !== -1) {
                cls += '_right';
            } else {
                cls += '_left';
            }

            layer.addClass(cls);
        }
    };

    /**
     * Update layer position near current target. Not call update already showed layer
     * @param {HTMLElement} target
     */
    GUI.Popup.Layer.prototype.setLayerPositionTo = function(target) {
        this.config.positionTarget = target;
    };

    GUI.Popup.Layer.prototype._hideContentOnBodyClick = function (e) {
        if (
            !GUI.Dom.isDescendant(this.config.layer.dom, e.target) &&
            !GUI.Dom.isDescendant(this.config.target, e.target)
        ) {
            this.hideLayer(e);
        }
    };

    GUI.Popup.Layer.prototype.isShowed = function () {
        return this.isLayerShowed || this.permanentShow;
    };

    /**
     * Destroy and clean
     */
    GUI.Popup.Layer.prototype.destroy = function () {
        this.stopTimerForHideLayer();
        this.stopTimerForShowLayer();

        this.hideLayer();

        if (this.config.useClickToShow) {
            this.config.target.un('click', this.toggleLayer, this);
        }

        this.config.target.un('mouseenter', this.onMouseEnter, this);
        this.config.target.un('mouseleave', this.onMouseLeave, this);
        this.config.target.un('mousemove', this.onMouseMove, this);

        GUI.Utils.globalEvents.un(GUI.Utils.globalEvents.names.tinymceMouseEnter, this.hideLayer, this);

        this.config.layer.hide();

        superproto.destroy.call(this);
    };
}());
