(function () {
    var superproto = '';

    /**
     * JavaScript Graphical User Interface
     * ActiveElement implementation. This component extends GUI.BoxComponent
     * by making it responsive to user actions like mouseover, click, etc. A
     * ctive element is a base class for simple clickable components (tabs, buttons).
     *
     * @class ActiveElement
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Component
     */
    GUI.ActiveElement = Class.create();

    /**
     * Inheritance from GUI.BoxComponent
     */
    superproto = GUI.BoxComponent.prototype;
    Object.extend(GUI.ActiveElement.prototype, superproto);

    /**
     * Icon url
     * @type String
     */
    GUI.ActiveElement.prototype.icon = '';

    /**
     * Icon class
     * @type String
     */
    GUI.ActiveElement.prototype.iconClass = '';

    /**
     * Text, caption
     * @type String
     */
    GUI.ActiveElement.prototype.text = ''; // !!! because need of caption() method support

    /**
     * Right-icon url
     * @type String
     */
    GUI.ActiveElement.prototype.iconRight = '';

    /**
     * Right-icon class
     */
    GUI.ActiveElement.prototype.iconRightClass = '';

    /**
     * Used to quickly specify text, icons and hint using one option xtype.
     * Text, icon, iconClass, iconRight and hint are taken from GUI.ButtonTypes.
     * @see GUI.ButtonTypes
     * @type String
     */
    GUI.ActiveElement.prototype.xtype = '';

    /**
     * Hint to display when the mouse is holded over the field. Default is ''.
     * @type String
     */
    GUI.ActiveElement.prototype.hint = '';

    /**
     * Browser title.
     * @type String
     */
    GUI.ActiveElement.prototype.title = '';

    /**
     * TODO!
     * Sets display mode. Can be:
     *  - 'icon': Display only icon
     *  - 'text': Display only text
     *  - 'icontext': Display both icon and text
     *  - 'auto': Automatically detects mode.
     *  (If only icon set, uses 'icon' mode, only text - 'text',
     *  both set - 'icontext');
     */

    /**
     * Mode, can be 'icon', 'text', 'icontext', 'auto'; default is 'auto'
     * @type String
     */
    GUI.ActiveElement.prototype.mode = 'auto';

    /**
     * Css class name of the dom html ement. Default is 'b-button'
     * @type String
     */
    GUI.ActiveElement.prototype.className = 'b-button';

    /**
     * Extra css class name that is appended to the dom html ement. Default is ''
     * @type String
     */
    GUI.ActiveElement.prototype.extraClass = '';

    /**
     * Css class for simple button, default is 'b-button_simple'
     * @type String
     */
    GUI.ActiveElement.prototype.backgroundClass = 'b-button_simple';

    /**
     * Pressed class, default is 'b-button_toggled'
     * @type String
     */
    GUI.ActiveElement.prototype.pressedClass = 'selected';

    /**
     * Icon cell class, default is 'b-button__icon'
     * @type String
     */
    GUI.ActiveElement.prototype.iconCellClass = 'b-button__icon';

    /**
     * Text cell class, default is 'b-button__label'
     * @type String
     */
    GUI.ActiveElement.prototype.textCellClass = 'b-button__label';

    /**
     * Right cell class, default is 'b-button__icon'
     * @type String
     */
    GUI.ActiveElement.prototype.rCellClass = 'b-button__icon';

    /**
     * Custom class
     * @type String
     */
    GUI.ActiveElement.prototype.customClass = '';

    /**
     * DOM Event to use as click. Default is 'click'
     * @type String
     */
    GUI.ActiveElement.prototype.clickEvent = 'click';

    /**
     * True to make element togglable, i.e. it can persist in the toggled state
     * after mouse up. True to enable such behavior, false to disable. Default is false
     * @type Boolean
     */
    GUI.ActiveElement.prototype.canToggle = false;

    /**
     * True if the lement is toggled by default, false if not. Default is false
     * @type Boolean
     */
    GUI.ActiveElement.prototype.toggled = false;

    /**
     *
     */
    GUI.ActiveElement.prototype.primary = false;

    GUI.ActiveElement.prototype.primaryClass = 'b-button_color_primary';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.ActiveElement.prototype.background = true;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.ActiveElement.prototype.delimiter = false;

    /**
     * Define tab index number on page
     * @type {Number}
     */
    GUI.ActiveElement.prototype.tabIndex = 0;

    /**
     * @type {null|GUI.Utils.KeyboardEvents}
     */
    GUI.ActiveElement.prototype.keyboardEvents = null;

    /**
     * Call parent initialize.
     * Sets vars text and img
     */
    GUI.ActiveElement.prototype.initialize = function (config) {
        // Back support
        if (config) {
            if (!GUI.isSet(config.text) && GUI.isSet(config.caption)) {
                config.text = config.caption;
                delete config.caption;
            }

            if (!GUI.isSet(config.icon) && GUI.isSet(config.img)) {
                config.icon = config.img;
                delete config.img;
            }
        }
        superproto.initialize.call(this, config);
    };

    /**
     * Initialize objects.
     * Call parent initComponent.
     */
    GUI.ActiveElement.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            'click': true
        });

        // Back Support of GUI.ToolBar.Button
        if (this.xtype && this.xtype.length > 0) {
            var btn;
            if (GUI.ButtonTypes && (btn = GUI.ButtonTypes[this.xtype])) {
                this.icon       = btn.img || '';
                this.iconClass  = btn.iconClass || '';
                this.text       = btn.caption || '';
                this.iconRight  = btn.iconRight || '';
                this.hint       = btn.hint || '';
                this.title      = btn.title || '';
            }
        }
    };

    /**
     * Init button element. Sets caption element, hint, add events 'mouseenter',
     * 'mouseleave', 'mousedown', 'clickEvent', 'click'. Unselectable button
     * @param {Object} btn Button
     */
    GUI.ActiveElement.prototype.initButtonEl = function (btn) {
        this.dom = btn;

        this.keyboardEvents = new GUI.Utils.KeyboardEvents(btn);

        if (!GUI.isEmpty(this.text)) {
            this.captionEl = GUI.Dom.extend(this.dom.getElementsByTagName('span')[0]);
        }

        if (this.hint) {
            this.setHint(this.hint);
        }

        if (this.title) {
            this.setTitle(this.title);
        }

        if (this.canToggle && this.toggled) {
            this.dom.addClass(this.pressedClass);
            this.dom.removeClass(this.backgroundClass);
        }

        btn.on('mouseleave', this.onMouseLeave, this);

        btn.on(this.clickEvent, this.onClick, this);
        this.keyboardEvents.on(GUI.Utils.keys.enter, this.onClick, this);

        btn.on('click', this.preventDefaultEvent, this);
        btn.onkeydown = function(e) { // Disable keypress enter and space for generate click event by browser
            return !(GUI.Utils.keysToCode.enter == e.which || GUI.Utils.keysToCode.space == e.which);
        };

        GUI.unselectable(btn);
    };

    /**
     *
     */
    GUI.ActiveElement.prototype.disableClickByEnter = function () {
        this.keyboardEvents.un(GUI.Utils.keys.enter, this.onClick, this);
    };

    /**
     *
     * @returns {null|GUI.Utils.KeyboardEvents}
     */
    GUI.ActiveElement.prototype.getKeyboardEventManager = function () {
        if (!this.keyboardEvents) {
            throw new Error('keyboard is inactive');
        }

        return this.keyboardEvents;
    };

    /**
     * state is true add class 'pressedClass', else remove 'pressedClass'.
     * Fire event 'toogle'
     * @param {Boolean} state State toogle
     */
    GUI.ActiveElement.prototype.toggle = function (state) {
        if (!this.canToggle) {
            return;
        }

        if (!GUI.isSet(state)) {
            state = !this.toggled;
        }
        this.toggled = state;

        if (state) {
            this.dom.addClass(this.pressedClass);
            if (!this.background) {
                this.dom.removeClass(this.backgroundClass);
            }
        } else {
            this.dom.removeClass(this.pressedClass);
            if (!this.background) {
                this.dom.addClass(this.backgroundClass);
            }
        }

        this.fireEvent('toggle', this, state);
    };

    /**
     * Set caption
     * @param {String} value Text of caption
     * @returns {String} caption
     */
    GUI.ActiveElement.prototype.caption = function (value) {
        if (GUI.isSet(value)) {
            this.setText(value);
            return value;
        } else {
            return this.text;
        }
    };

    /**
     * Sets icon
     * @param {String} value Url of the icon
     * @reurns {String} url of the icon
     */
    GUI.ActiveElement.prototype.img = function (value) {
        if (GUI.isSet(value)) {
            this.setIcon(value);
            return value;
        } else {
            return this.icon;
        }
    };

    /**
     * Checks toogle
     * @returns {Boolean} toogled
     */
    GUI.ActiveElement.prototype.isToggled = function () {
        return this.toggled;
    };

    /** getters */

    /**
     * Returns template
     * @param {Boolean} hasIcon Icon
     * @param {Boolean} hasText Text
     * @param {Boolean} hasRightIcon Right icon
     * @returns {HTMLString} Html template
     */
    GUI.ActiveElement.prototype._getTemplate = function (hasIcon, hasText, hasRightIcon, hasDelimiter, primary, tabIndex) {
        return '<button id="{this.id}" class="{this.className} {backgroundClass} {this.extraClass}' +

            (primary ?
                    '{this.primaryClass}"'
                : '"') +
            (tabIndex ?
                    ' tabindex="{this.tabIndex}"'
                : ''
                ) +

            ' type="button">' +

            (hasIcon ?
                    '<i class="{this.iconCellClass} {this.iconClass}" {icon}></i>'
                : '') +

            (hasText ?
                    ' <span class="b-button__label">{this.text}</span> '
                : '') +

            (hasDelimiter ?
                    ' <i class="b-button__icon dropdown_delimiter">&nbsp;</i> '
                : '') +

            (hasRightIcon ?
                    ' <i class="{this.rCellClass} {this.iconRightClass}" {iconRight}>&nbsp;</i>'
                : '') +
            '</button>';
    };

    /**
     * Returns cached template
     * @param {Boolean} hasIcon Icon
     * @param {Boolean} hasText Text
     * @param {Boolean} hasRightIcon Right icon
     * @returns {HTMLString} Html cached template
     */
    GUI.ActiveElement.prototype._getCachedTemplate = function (hasIcon, hasText, hasRightIcon, hasDelimiter, primary) {
        // Generate template id
        var tplId = (this.iconRight ? 4 : 0) +
            (this.text ? 2 : 0) +
            ((this.icon || this.iconClass) ? 1 : 0),
            c;

        if (!(c = this.getSelf()._tplCache[tplId])) {
            // Need to create cache
            return (this.getSelf()._tplCache[tplId] = this._getTemplate(hasIcon, hasText, hasRightIcon, hasDelimiter, primary));

        } else {
            // Returne cached template
            return c;
        }
    };

    /**
     * Returns self, ActiveElement
     * @returns {Object} ActiveElement
     */
    GUI.ActiveElement.prototype.getSelf = function () {
        return GUI.ActiveElement;
    };

    /**
     * Returns name
     * @returns {String} name
     */
    GUI.ActiveElement.prototype.getName = function () {
        return this.name;
    };

    /**
     * Sets icon
     * @param {String} value Url of icon
     */
    GUI.ActiveElement.prototype.setIcon = function (value) {
        this.icon = value;
        if (this.dom) {
            this.dom.getElementsByTagName('i')[0].style.backgroundImage = 'url(' + value + ')';
        }
    };

    /**
     * Sets icon class
     * @param {String} value Css class name
     */
    GUI.ActiveElement.prototype.setIconClass = function (value) {
        this.iconClass = value;
        if (this.dom) {
            this.dom.getElementsByTagName('i')[0].className = this.iconCellClass + ' ' + value;
        }
    };

    /**
     * Sets text of caption
     * @param {String} value Caption text
     */
    GUI.ActiveElement.prototype.setText = function (value) {
        this.text = value;
        if (this.captionEl) {
            this.captionEl.innerHTML = value;
        }
    };

    /**
     * Sets right icon
     * @param {String} value Url of the icon
     */
    GUI.ActiveElement.prototype.setIconRight = function (value) {
        var tag;
        this.iconRight = value;
        if (this.dom) {
            tag = this.dom.getElementsByTagName('i');
            tag = (this.delimiter ? tag[2] : tag[1]) || tag[0];
            tag.style.backgroundImage = 'url(' + value + ')';
        }
    };

    /**
     * Sets icon class
     * @param {String} value Css class name
     */
    GUI.ActiveElement.prototype.setIconRightClass = function (value) {
        var tag;
        this.iconClass = value;
        if (this.dom) {
            tag = this.dom.getElementsByTagName('i');
            tag = (this.delimiter ? tag[2] : tag[1]) || tag[0];
            tag.className = this.rCellClass + ' ' + value;
        }
    };

    /**
     * Sets icon class
     * @param {String} value Css class name
     */
    GUI.ActiveElement.prototype.setExtraClass = function (value) {
        this.dom.removeClass(this.extraClass);
        this.dom.addClass(value);

        this.extraClass = value;
    };

    /**
     * Sets hint
     * @param {String} value Text of the hint
     */
    GUI.ActiveElement.prototype.setHint = function (value) {
        this.hint = value;
        if (this.dom) {
            this.dom.setAttribute('hint', value);
        }
    };

    /**
     * Sets hint
     * @param {String} value Text of the hint
     */
    GUI.ActiveElement.prototype.setTitle = function (value) {
        this.title = value;
        if (this.dom) {
            this.dom.setAttribute('title', value);
        }
    };

    /**
     * Sets hint
     * @param {String} value Text of the hint
     */
    GUI.ActiveElement.prototype.getTitle = function () {
        if (this.dom) {
            return this.dom.getAttribute('title');
        }
    };

    GUI.ActiveElement.prototype.setPrimary = function (primary) {
        primary = !!primary;

        if (this.primary === primary) {
            return;
        }

        this.primary = primary;

        if (primary) {
            this.dom.addClass(this.primaryClass);
        } else {
            this.dom.removeClass(this.primaryClass);
        }
    };

    /**
     * Sets template
     * @returns {Object} tpl
     */
    GUI.ActiveElement.prototype.onRender = function () {
        // Cahched version is buggy
        var tpl = this._getTemplate(
            this.icon || this.iconClass,
            this.text,
            this.iconRight || this.iconRightClass,
            this.delimiter,
            this.primary,
            this.tabIndex
        );

        tpl = new GUI.STemplate(tpl, this);

        tpl.icon = (this.icon && this.icon.length) ? 'style="background-image: url(\'' + this.icon + '\');"' : '';

        tpl.iconRight = (this.iconRight && this.iconRight.length) ? 'style="background-image: url(\'' + this.iconRight + '\');"' : '';

        tpl.backgroundClass = !this.background ? this.backgroundClass : '';

        tpl = tpl.fetch();

        return tpl;
    };

    /**
     * Call parent onAfterRender, init button
     * @param {Object} btn Button object
     */
    GUI.ActiveElement.prototype.onAfterRender = function (btn) {
        superproto.onAfterRender.call(this, btn);
        this.initButtonEl(btn);
    };

    /**
     * Sets to null captionEl. Call parent onDestroy.
     * @param {Boolean} fast
     */
    GUI.ActiveElement.prototype.onDestroy = function (fast) {
        this.captionEl = null;
        superproto.onDestroy.call(this, fast);
    };

    /** events */

    /**
     * Extend event
     * @param {Event} e
     */
    GUI.ActiveElement.prototype.preventDefaultEvent = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
    };

    /**
     * Remove all event listeners
     */
    GUI.ActiveElement.prototype.removeEventListeners = function () {
        this.dom.un();
    };

    /**
     * Disable element
     */
    GUI.ActiveElement.prototype.onDisable = function () {
        if (this.dom) {
            this.dom.setAttribute("disabled", "disabled");
        }
    };

    /**
     * Enable element
     */
    GUI.ActiveElement.prototype.onEnable = function () {
        if (this.dom) {
            this.dom.removeAttribute("disabled", "");
        }
    };

    /**
     *  Extend event, remove class 'pressed', remove event 'mouseup' on document
     *  @param {Event} e
     */
    GUI.ActiveElement.prototype.onMouseUp = function (e) {
        if (!this.disabled) {
            if (!this.toggled) { // Required, because clickEvent can be 'mousedown'
                this.pressed = false;
                this.dom.removeClass(this.pressedClass);
            }
            document.un('mouseup', this.onMouseUp, this); // need flag...
        }
    };

    /**
     * If not disabled toogle element, fire event 'click'
     * @param {Event} e
     */
    GUI.ActiveElement.prototype.onClick = function (e) {
        e = GUI.Event.extend(e);
        if (!this.disabled) {
            if (this.canToggle) {
                this.toggle();
            }
            this.fireEvent('click', this, e);
        }
    };

    /**
     * Removes blur from the dom
     * @param {Event} e
     */
    GUI.ActiveElement.prototype.onMouseLeave = function (e) {
        this.dom.blur();
    };

    // Static array, contains cached templates
    GUI.ActiveElement.prototype._tplCache = new Array(8);

}());