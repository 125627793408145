(function () {
    var superproto = GUI.Layouts.Layout.prototype;

    /**
    * JavaScript Graphical User Interface
    * Base BoxComponent implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    * @extends GUI.Layouts.Layout
    */
    GUI.Layouts.Table = Class.create();
    Object.extend(GUI.Layouts.Table.prototype, superproto);

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Layouts.Table.prototype.monitorResize = false;

    /**
     * Sets container
     * @param {Object} ct Container
     */
    GUI.Layouts.Table.prototype.setContainer = function (ct) {
        superproto.setContainer.call(this, ct);

        this.currentRow = 0;
        this.currentColumn = 0;
        this.cells = [];
    };

    /**
     * Add css class to target, append table and run render all
     * @param {Object} ct Container
     * @param {HTMLElement} taget Element to render object to
     */
    GUI.Layouts.Table.prototype.onLayout = function (ct, target) {
        var cs = ct.items.items, len = cs.length, c, i;

        if (!this.table) {
            target.addClass('x-table-layout-ct');

            this.table = GUI.Dom.append('<table class="x-tablelayout" cellSpacing="0"><tbody></tbody></table>', target);

            this.renderAll(ct, target);
        }
    };

    /**
     * Returns row by index
     * @param {Number} index
     */
    GUI.Layouts.Table.prototype.getRow = function (index) {
        var row = this.table.tBodies[0].childNodes[index];
        if (!row) {
            row = document.createElement('tr');
            this.table.tBodies[0].appendChild(row);
        }
        return row;
    };

    /**
     * Returns next cell
     * @param {Object} c Container
     * @returns {HTMLElement} td
     */
    GUI.Layouts.Table.prototype.getNextCell = function (c) {
        var rowIndex, colIndex, td, cls,
            cell = this.getNextNonSpan(this.currentColumn, this.currentRow),
            curCol = this.currentColumn = cell[0],
            curRow = this.currentRow = cell[1];

        for (rowIndex = curRow; rowIndex < curRow + (c.rowspan || 1); rowIndex++) {
            if (!this.cells[rowIndex]) {
                this.cells[rowIndex] = [];
            }
            for (colIndex = curCol; colIndex < curCol + (c.colspan || 1); colIndex++) {
                this.cells[rowIndex][colIndex] = true;
            }
        }
        td = document.createElement('td');
        if (c.cellId) {
            td.id = c.cellId;
        }
        cls = 'x-table-layout-cell';
        if (c.cellCls) {
            cls += ' ' + c.cellCls;
        }
        td.className = cls;
        if (c.colspan) {
            td.colSpan = c.colspan;
        }
        if (c.rowspan) {
            td.rowSpan = c.rowspan;
        }
        this.getRow(curRow).appendChild(td);
        return td;
    };

    /**
     * Returns next non span element
     * @param {Number} colIndex
     * @param {Number} rowIndex
     * @returns {Array}
     */
    GUI.Layouts.Table.prototype.getNextNonSpan = function (colIndex, rowIndex) {
        var cols = this.columns;
        while ((cols && colIndex >= cols) || (this.cells[rowIndex] && this.cells[rowIndex][colIndex])) {
            if (cols && colIndex >= cols) {
                rowIndex++;
                colIndex = 0;
            } else {
                colIndex++;
            }
        }
        return [colIndex, rowIndex];
    };

    /**
     * Renders item
     * @param {Object} c Container
     * @param {String} position
     * @param {HTMLElement} target
     */
    GUI.Layouts.Table.prototype.renderItem = function (c, position, target) {
        if (c && !c.rendered) {
            c.render(this.getNextCell(c));
        }
    };

    /**
     * empty function, returns true
     * @returns {Boolean} true
     */
    GUI.Layouts.Table.prototype.isValidParent = function (c, target) {
        return true;
    };

}());