(function () {
    //var superproto = GUI.Utils.Container;

    /**
    * JavaScript Graphical User Interface
    * Base Container implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Utils.Container
    */
    //GUI.ApplicationContainer = Class.create();
    //Object.extend(GUI.ApplicationContainer.prototype, superproto.prototype);

    var	ApplicationContainer = Class.create(GUI.Utils.Container, {

        layout : 'Application',

        /**
         * Initialize object
         * add css class 'jsgui-container-application'
         * add a handler for resizing the window
         */
        initComponent : function () {

            // Call parent initialize method
            ApplicationContainer.superclass.initComponent.call(this);

            /**
             * Defaults dom.scroll - no
             * @type HTML Element
             */
            this.dom = document.body;
                //this.dom.scroll = 'no';
            /**
             * Defaults true
             * @type Boolean
             */
            this.autoWidth = true;
            /**
             * Defaults height is 100%
             * @type String|Number
             */
            this.height = '100%';

            GUI.Event.onWindowResize(this.fireResize, this);
            /**
             * @type HTML Element
             */
            this.holder = this.dom;
        },

        /**
         * empty function
         */
        onRender : GUI.emptyFunction,

        /**
         * resizing the window
         * @param {String|Number} w width
         * @param {Srting|Number} h height
         */
        fireResize : function (w, h) {
            this.fireEvent('resize', this, w, h, w, h);
        }
    });

    GUI.ApplicationContainer = ApplicationContainer;
}());