(function () {
    var superproto = GUI.Application.prototype;

    GUI.Factory = Class.create();
    Object.extend(GUI.Factory.prototype, superproto);

    /**
     * Type of the module, default is 'Factory'
     * @type String
     */
    GUI.Factory.prototype.type = 'Factory';

    /**
     * Domain pool size, redefine in subclass to resize max pool size
     * @type {number}
     */
    GUI.Factory.prototype.maxPoolSize = 40;

    /**
     * Initializes controller
     * @param {Object} config
     */
    GUI.Factory.prototype.initialize = function (config) {
        Object.extend(this, config);
        superproto.create.call(this);
        this.pool = {};
    };

    /**
     * Add domain to factory pool
     *
     * @param {GUI.Domain} domain
     * @param {String} poolGroup
     * @returns {undefined}
     */
    GUI.Factory.prototype.pushToPool = function (domain, poolGroup) {
        var domainId = domain.getId();
        if (
            this.getFromPool(domainId) !== undefined &&
                this.getFromPool(domainId) !== domain
        ) {
            throw new Error('Domain with id ' + domainId + ' already in pool');
        }

        this.getPoolGroup(poolGroup)[domainId] = domain;
    };

    /**
     * Return domain from pool, or return undefined if domain not exist
     * in pool
     * @param {type} id
     * @param {String} poolGroup
     * @returns {GUI.Domain}
     */
    GUI.Factory.prototype.getFromPool = function (id, poolGroup) {
        this.clearPool();
        return this.getPoolGroup(poolGroup)[id];
    };

    /**
     * Remove old domains from pool
     * @param {String} poolGroup
     */
    GUI.Factory.prototype.clearPool = function (poolGroup) {
        var i = 0,
            keys = Object.keys(this.pool);

        for (i = keys.length - 1; i > this.maxPoolSize; i--) {
            domain = this.pool[keys[i]];
            if (domain.isLock()) {
                continue;
            }

            this.removeFromPool(keys[i], poolGroup);
        }
    };

    /**
     * Remove domain from pool
     * @param id
     * @param poolGroup
     */
    GUI.Factory.prototype.removeFromPool = function (id, poolGroup) {
        delete this.getPoolGroup(poolGroup)[id];
    };

    /**
     * Purge pool
     */
    GUI.Factory.prototype.purgePool = function () {
        this.pool = {};
    };

    /**
     * Return object with pooling group
     * @param group
     */
    GUI.Factory.prototype.getPoolGroup = function (group) {
        if (group) {
            var groupKey = '__group-' + group;
        } else {
            return this.pool;
        }

        if (this.pool[groupKey] === undefined) {
            this.pool[groupKey] = {};
        }

        return this.pool[groupKey];
    };

}());



