(function () {
    if (!GUI.Layouts) {
        GUI.Layouts = {};
    }

    /**
    * JavaScript Graphical User Interface
    * Base Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    */
    GUI.Layouts.Layout = Class.create();

    /**
     * Type of the element, default is 'Layouts.Layout'
     * @type String
     */
    GUI.Layouts.Layout.prototype.elementType = 'Layouts.Layout';

    /**
     * Constructor
     * @param {Object} config Configuretion object
     */
    GUI.Layouts.Layout.prototype.initialize = function (config) {
        Object.extend(this, config);
    };

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Layouts.Layout.prototype.monitorResize = false;

    /**
     * Default is null
     * @type Object
     */
    GUI.Layouts.Layout.prototype.activeItem = null;

    /**
     * Renders layout, add event 'afterlayout'
     */
    GUI.Layouts.Layout.prototype.layout = function () {
        var target = this.container.getLayoutTarget();
        GUI.Dom.extend(target);
        this.onLayout(this.container, target);
        this.container.fireEvent('afterlayout', this.container, this);
    };

    /**
     * Renders all
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Layout.prototype.onLayout = function (ct, to) {
        this.renderAll(ct, to);
    };

    /**
     * Returns true if parent is valid
     * @param {Object} c Container
     * @param {HTMLElement} target
     */
    GUI.Layouts.Layout.prototype.isValidParent = function (c, target) {
        var el = c.getPositionEl ? c.getPositionEl() : c.getDom();
        return el.parentNode === target.dom;
    };

    /**
     * Renders all items
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to rnder object to
     */
    GUI.Layouts.Layout.prototype.renderAll  = function (ct, to) {
        var i, len, c,
            items = ct.items.items;

        for (i = 0, len = items.length; i < len; i++) {
            c = items[i];
            if (c && (!c.rendered || !this.isValidParent(c, to))) {
                this.renderItem(c, i, to);
            }
        }
    };

    /**
     * Renders item
     * @param {Object} c Container
     * @param {String} position
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Layout.prototype.renderItem  = function (c, position, to) {
        if (c && !c.rendered) {
            // Not rendered, render
            c.render(to, position);
            if (this.extraCls) {
                var t = c.getPositionEl ? c.getPositionEl() : c;
                t.addClass(this.extraCls);
            }
            if (this.renderHidden && c !== this.activeItem) {
                c.hide();
            }
        }
    };

    /**
     * If resizeTask run it
     */
    GUI.Layouts.Layout.prototype.onResize = function () {
        if (this.container.collapsed) {
            return;
        }
        var b = this.container.bufferResize;
        if (b) {
            if (!this.resizeTask) {
                this.resizeTask = new GUI.Utils.DelayedTask(this.layout, this);
                this.resizeBuffer = typeof b === 'number' ? b : 100;
            }
            this.resizeTask.delay(this.resizeBuffer);
        } else {
            this.layout();
        }
    };

    /**
     * Sets container
     * @param {Object} ct Container
     */
    GUI.Layouts.Layout.prototype.setContainer  = function (ct) {
        if (this.monitorResize && ct !== this.container) {
            if (this.container) {
                this.container.un('resize', this.onResize, this);
            }
            if (ct) {
                ct.on('resize', this.onResize, this);
            }
        }
        this.container = ct;
    };

    /**
     * Parses margins
     * @returns {Object} margins
     */
    GUI.Layouts.Layout.prototype.parseMargins = function (v) {
        var ms = v.split(' '),
            len = ms.length;

        if (len === 1) {
            ms[1] = ms[0];
            ms[2] = ms[0];
            ms[3] = ms[0];
        } else if (len === 2) {
            ms[2] = ms[0];
            ms[3] = ms[1];
        } else if (len === 3) {
            ms[3]  = ms[1];
        }
        return {
            top     : parseInt(ms[0], 10) || 0,
            right   : parseInt(ms[1], 10) || 0,
            bottom  : parseInt(ms[2], 10) || 0,
            left    : parseInt(ms[3], 10) || 0
        };
    };

    /**
     * empty function
     */
    GUI.Layouts.Layout.prototype.destroy = GUI.emptyFunction;

}());