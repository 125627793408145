(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Siteurl implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Siteurl = Class.create();
    Object.extend(GUI.Forms.Validation.Siteurl.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_SITEURL'
     * @type String
     */
    GUI.Forms.Validation.Siteurl.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_SITEURL);

    /**
     * Type of the element, 'GUI.Forms.Validation.Siteurl'
     * @type String
     */
    GUI.Forms.Validation.Siteurl.prototype.elementType = 'GUI.Forms.Validation.Siteurl';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Siteurl.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);
        this.pattern = /(((https?)|(ftp)):\/\/[\-\w]+(\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/?)/i;
    };

}());
