(function() { // variables isolation

    var ProfileFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        //_dialog   : null,
        //_showed   : false,
        //_formMask : null,
        _profileForm: {
            holder    : null,
            form      : null,
            avatarForm: null
        },

        _setupFormElementListeners : function() {
            this._profileForm.holder = GUI.$('profile_profile-form');
            this._profileForm.form   = GUI.$('profile-form');
            this._profileForm.avatarForm   = new GUI.Forms.Form();
            this._profileForm.avatarForm.assign('avatar-form');


            if (undefined == this._profileForm.holder || undefined == this._profileForm.form) {
                return;
            }

            if (GUI.isIE) {
                jQuery(".avatar_option__block label").on('click', function (event) {
                    jQuery('#' + jQuery(event.target).closest('label').attr('for')).trigger('click');
                });
            }

            this._setupAvatarListener(jQuery('#avatar')[0]);

            jQuery("[name=avatarType]").on('change', function () {
                this._profileForm.avatarForm.submit();
            }.bindLegacy(this));
        },

        _setupAvatarListener : function (element) {
            GUI.Event.on(element, 'change', function (e) {
                Application.LoadAvatarForm.onLoadAvatar(e, element);
                Application.LoadAvatarForm.on('load', this._onImageLoad.bindLegacy(this));
            }.bindLegacy(this));
        },

        _onImageLoad : function (imageUrl) {
            jQuery("[data-holder=avatarTypeFile] img").attr('src',  imageUrl + "?" + Math.random().toString());
            if (jQuery('#avatarTypeFile').is(':checked')) {
                jQuery('#avatarTypeFile').trigger('change');
            } else {
                jQuery('#avatarTypeFile').trigger('click');
            }
        },

        initialize : function() {
            GUI.onDOMReady((function() {
                this._setupFormElementListeners();

                jQuery("#wipe-profile-data").on("click", this.onWipeClick.bindLegacy(this));

            }).bindLegacy(this));
        },

        onWipeClick: function (event) {
            var url = jQuery(event.target).closest("button").data('href');

            if (jQuery('#wipe-profile-data-dialog').length) {
                Application.ConfirmForm.setCallerElement(event.target, null, 'wipe-profile-data', 'wipe-profile-data-dialog-form', 'wipe-profile-data-form');
                Application.ConfirmForm.setActionUrl(url);
            } else {
                Application.ConfirmForm.setCallerElement(event.target, null, 'wipe-profile-data-staff', 'wipe-profile-data-dialog-form', 'wipe-profile-data-form');
            }

            Application.ConfirmForm.show(true);
        }
    };

    Object.extend(ProfileFormClass.prototype, superproto);
    Object.extend(ProfileFormClass.prototype, prototype);

    Application.ProfileForm = new ProfileFormClass();

}());