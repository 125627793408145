function moveGoogleAnalytics() {
    var location =
        window.location.pathname +
        window.location.search +
        window.location.hash;

    window.ga('system.send', 'pageview', location);
}

function setGoogleAnalytics() {

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
    window.ga('create', window.googleTrackCode, 'auto', {name: 'system'});
    moveGoogleAnalytics();

//    window.ga('system.require', 'ecommerce');

    if (window.GUI) {
        GUI.History.addEventListener('historyChange', moveGoogleAnalytics);
    }
}

window.gaActions = {
    ecommerce: function (transactionId, item, totalPrice) {
        if (window.Env.isTestingEnv()) {
            return;
        }

        // For docs
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce

        window.ga('billing.ecommerce:addTransaction', {
            id: transactionId,       // Transaction ID. Required.
            affiliation: 'HelpRace', // Affiliation or store name.
            revenue: totalPrice      // Grand Total.
        });

        window.ga('billing.ecommerce:addItem', {
            id: transactionId,                                   // Transaction ID. Required.
            name: item.name,                                     // Product name. Required.
            category: item.category,                             // Category or variation.
            price: item.price,                                   // Unit price.
            quantity: item.quantity                              // Quantity.
        });

        window.ga('billing.ecommerce:send');
    },

    sendSiteEvent: function (category, action, label, value) {
        if (window.Env.isTestingEnv()) {
            return;
        }

        if (!window.isOrderPresent) {
            // own ga with own track code
            if (!window.gaPayment) {
                (function (i, s, o, g, r, a, m) {
                    i['GoogleAnalyticsObject'] = r;
                    i[r] = i[r] || function () {
                        (i[r].q = i[r].q || []).push(arguments)
                    }, i[r].l = 1 * new Date();
                    a = s.createElement(o),
                        m = s.getElementsByTagName(o)[0];
                    a.async = 1;
                    a.src = g;
                    m.parentNode.insertBefore(a, m)
                })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'gaPayment');
                window.gaPayment('create', window.googleTrackCodeSiteEvent, 'auto', {name: 'billing'});
            }

            // For docs
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
            window.gaPayment('billing.send', 'event', category, action, label, value);
        }
    },

    overviewSearch: function (text, tiles, related, lang, account) {
        if (window.Env.isTestingEnv()) {
            return;
        }

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
        window.ga('create', window.googleTrackCode, 'auto');

        window.ga(
            'send',
            'pageview',
            '/admin/settings/overview?q=' + text.toLowerCase() +
                '&lang=' + lang +
                '&tiles=' + (tiles ? 'Found' : 'Not Found') +
                '&related=' + (related ? 'Found' : 'Not Found') +
                '&account=' + account
        );
    }
};



if (!window.Env.isTestingEnv()) {
    setGoogleAnalytics();
}
