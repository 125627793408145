(function () {

    var superproto = GUI.Utils.Collection.prototype;
    GUI.Collection = Class.create();
    Object.extend(GUI.Collection.prototype, superproto);

    GUI.Collection.prototype.initialize = function (data) {
        superproto.initialize.call(this);

        if (data) {
            data.forEach(function (item, index) {
                this.add(index, item);
            }.bindLegacy(this));
        }

        this.addEvents([
            'changeData'
        ]);

        if (this._eventsList && this._eventsList.length) {
            this.addListener('add', this._onAddDomainCollection, this);
            this.addListener('remove', this._onRemoveDomainCollection, this);
        }
    };

    /**
     * Destroy collection, remove all items from collection, unsubscribe from domains
     */
    GUI.Collection.prototype.destroy = function () {
        this.each(function (item) {
            this.removeKey(item.getId());
        }.bindLegacy(this));
    };

    /**
     * Event handler, after domain added to current collection
     * @param {Number} key
     * @param {Domain} domain
     * @private
     */
    GUI.Collection.prototype._onAddDomainCollection = function (key, domain) {
        var i;

        domain.addListener('changeData', this._onChangeDataEvent, this);
        for (i = 0; i < this._eventsList.length; i++) {
            domain.addListener(this._eventsList[i], this._onChangeDataEvent, this);
        }
    };

    /**
     * Event handler, after domain remove from current collection
     * @param {Number} key
     * @param {Domain} domain
     * @private
     */
    GUI.Collection.prototype._onRemoveDomainCollection = function (key, domain) {
        var i;
        domain.removeListener('changeData', this._onChangeDataEvent, this);
        for (i = 0; i < this._eventsList.length; i++) {
            domain.removeListener(this._eventsList[i], this._onChangeDataEvent, this);
        }
    };

    /**
     *
     * @param domain
     * @param eventName
     * @private
     */
    GUI.Collection.prototype._onChangeDataEvent = function (domain, eventName) {
        this.fireEvent(eventName || 'changeData', domain);
    };
}());