(function () {
    var superproto = GUI.Forms.Combo.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.ComboTree implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.Combo
    */
    GUI.Forms.ComboTree = Class.create();
    Object.extend(GUI.Forms.ComboTree.prototype, superproto);

    /**
     * Type of the element, 'hidden'
     * @type String
     */
    GUI.Forms.ComboTree.prototype.type = 'hidden';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.ComboTree.prototype.hiddenInput = false;

    /**
     * Css class name, default is ''
     * @type String
     */
    GUI.Forms.ComboTree.prototype.className = '';

    /**
     * Height of the list, default is 10 lines
     * @type Number
     */
    GUI.Forms.ComboTree.prototype.listHeight = 10;

    /**
     * Height of the item, default is 18
     * @type Number
     */
    GUI.Forms.ComboTree.prototype.itemHeight = 18;

    /**
     * Width of list, default is 200
     * @type Number
     */
    GUI.Forms.ComboTree.prototype.width = 200;

    /**
     * Text of the root node, default is 'Root'
     * @type String
     */
    GUI.Forms.ComboTree.prototype.rootText = 'Root';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.ComboTree.prototype.lazyInitList = false;

    /**
     * Multiple selection, default is false
     * @type Boolean
     */
    GUI.Forms.ComboTree.prototype.multiple = false;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Forms.ComboTree.prototype.autoLoad = true;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.ComboTree.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init components: tree, list
     */
    GUI.Forms.ComboTree.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        if (!this.tree || !this.tree.render) {
            this.initTree();
        }

        if (!this.lazyInitList) {
            this.initList();
        }
    };

    /**
     * Init tree
     */
    GUI.Forms.ComboTree.prototype.initTree = function () {
        var rootConfig, treeConfig;

        rootConfig = {
            id      : 'null',
            text    : this.rootText,
            async   : false,
            expanded: this.autoLoad
        };

        if (this.tree && this.tree.dataUrl) {
            rootConfig.async = true;
        }

        if (this.options) {
            rootConfig.child = this.options;
        }

        treeConfig = {
            checkBox        : true,
            freeCheckbox    : true,
            alwaysExpanded  : false,
            rootVisible     : this.multiple ? true : false,
            root            : new GUI.Tree.Node(rootConfig),
            height          : 'auto' // needed for listHeight to work
        };

        if (!this.multiple) {
            treeConfig.checkBox = false;
        }

        if (this.tree) {
            Object.extend(treeConfig, this.tree);
            delete this.tree;
        }

        this.tree = new GUI.Tree(treeConfig);
        this.tree.on('select', this.onNodeSelect, this);

        this.tree.on('nodecollapse', this.checkListHeight, this);
        this.tree.on('nodeexpand', this.checkListHeight, this);
    };

    /**
     * Handler after render, call parent
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.ComboTree.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, dom);
    };

    /**
     * Handles node selection by user
     * @param {Object} tree Object of tree
     * @param {Object} node Object of node
     */
    GUI.Forms.ComboTree.prototype.onNodeSelect = function (tree, node) {
        if (!this.multiple) {
            this.selectedNode = node;
            this.setValue(node.id);
            this.hideList();
        } else {
            tree.clearChecked(true); // ignore disabled
            node.setChecked(true);
        }
    };

    /**
     * Init list
     */
    GUI.Forms.ComboTree.prototype.initList = function () {
        var a = {};
        if (!this.list) {
            this.list = new GUI.Popup.Region({
                className   : this.listClass,
                parentNode  : this.listHolder ? this.listHolder : document.body,
                dimensions  : {
                    left : -10000,
                    top  : -10000
                },
                border      : '1'
            });

            // do not use hide() !!! it will destroy tree
            this.list.show();

            // Add focus link
            a = document.createElement('a');
            a.className = 'g-focus';
            a.href = '#';
            a.tabIndex = -1;
            this.list.dom.appendChild(a);
            a.innerHTML = '&#160;';
            this.listFocusEl = GUI.Dom.extend(a);
        }

        if (GUI.isNumber(this.listWidth)) {
            this.list.setDimensions({ width: this.listWidth});
        }

        if (!this.tree.rendered) {
            this.tree.render(this.list.dom);
        }
        if (this.listOpened) {
            // Realign if list is opened
            this.list.alignTo(this.fieldEl, 'tl-bl?');
        }
    };

    /**
     * Init list, align, focus, fire event 'listShow',
     * add event 'mousedown' on document
     */
    GUI.Forms.ComboTree.prototype.showList = function () {
        var offsetWidth;

        this.initList();
        if (!this.listOpened) {
            if (this.listWidth === 'auto') {
                offsetWidth = this.dom.offsetWidth;
                this.tree.setWidth(offsetWidth - 2);
                this.list.setDimensions({width: offsetWidth});
            }

            if (GUI.isSet(this.listHeight)) {
                this.checkListHeight();
            }
            document.on('mousedown', this.onDocumentMouseDown, this);
            GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);

            this.listOpened = true;
        }
        this.list.alignTo(this.dom, 'tl-bl?');

        this.focusList();
        this.deferFocusList();

        this.fireEvent('listShow', this);
    };

    /**
     * Remove event 'mousedown' from document,
     * fire event 'listHide', updates value.
     */
    GUI.Forms.ComboTree.prototype.hideList = function () {
        if (this.listOpened) {
            document.un('mousedown', this.onDocumentMouseDown, this);
            GUI.Utils.globalEvents.un(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);

            this.list.setDimensions({
                top: -10000,
                left: -10000
            });
            this.listOpened = false;
            this.fireEvent('listHide', this);
            this.updateValue();
        }
    };

    /**
     * Updates value
     */
    GUI.Forms.ComboTree.prototype.updateValue = function () {
        var arr, count, allCount, node,
            rawValue = '';

        if (this.multiple) {
            arr = this.tree.getCheckedNodes();
            count = arr.length;
            allCount = this.tree.getNodesCount();

            if (!this.tree.isRootVisible()) {
                allCount--;
            }

            switch (count) {
            case 0:
                rawValue = '';
                break;

            case 1:
                rawValue = arr[0].config.text;
                break;

            case allCount:
                rawValue = pgettext('General use', 'All');
                break;

            default:
                rawValue = i18n('Multiple Selection');
                break;
            }

        } else {
            node = this.tree.getSelectedNode();
            rawValue = node ? node.config.text : '';
        }
        this.setRawValue(rawValue);
        if (this.dom && rawValue.length > 0) {
            this.dom.removeClass(this.defaultTextClass);
        }
        this.applyDefaultText();
    };

    /**
     * Attach events listeners
     */
    GUI.Forms.ComboTree.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
        if (this.triggerOnFieldClick) {
            this.fieldEl.on(this.triggerEvent, this.onTriggerClick,     this);
        }
    };

    /**
     * Show list
     */
    GUI.Forms.ComboTree.prototype.onTriggerClick = function () {
        this.showList();
    };

    /**
     * Returns true if node name is SELECT
     * @param {HTMLElement} dom Dom
     * @returns {Boolean}
     */
    GUI.Forms.ComboTree.prototype.onBeforeAssign = function (dom) {
        return dom.nodeName === 'SELECT';
    };

    /**
     * Add options
     * @param {Object} elem
     */
    GUI.Forms.ComboTree.prototype.onAssign = function (elem) {
        superproto.onAssign.call(this, elem);

        var options = elem.options,
            i = 0,
            len = 0,
            option;

        this.clear();
        for (i = 0, len = options.length; i < len; i++) {
            option = options[i];
            this.add({
                id      : option.value,
                text    : option.text,
                checked : option.selected
            });
        }
    };

    /**
     * Returns dom
     * @returns {HTMLElement} dom
     */
    GUI.Forms.ComboTree.prototype.getStylingEl = function () {
        return this.dom;
    };

    /**
     * Destroy tree
     * @param {Boolean} fast
     */
    GUI.Forms.ComboTree.prototype.onDestroy = function (fast) {
        if (this.tree) {
            this.tree.destroy(fast);
            this.tree = null;
        }
        superproto.onDestroy.call(this, fast);
    };

    /**
     * Returns selected items
     * @returns {Array} selected items
     */
    GUI.Forms.ComboTree.prototype.getSelections = function () {
        if (this.multiple) {
            return this.tree.getCheckedNodes();
        } else {
            var node =  this.tree.getSelectedNode();
            return (node) ? [ node ] : [];
        }
    };

    /**
     * Returns object of tree
     * @returns {Object} tree
     */
    GUI.Forms.ComboTree.prototype.getTree = function () {
        return this.tree;
    };

    /**
     * Returns ids of checked items
     * @returns {Array} ids
     */
    GUI.Forms.ComboTree.prototype.getValue = function () {
        var ids = [],
            nodes,
            i = 0,
            len = 0;

        if (this.tree) {
            nodes = this.tree.getCheckedNodes();
            for (i = 0, len = nodes.length; i < len; i++) {
                ids[i] = nodes[i].id;
            }
        }
        return ids;
    };

    /**
     * Sets value
     * @param {Array} ids Ids of items for checked
     */
    GUI.Forms.ComboTree.prototype.setValue = function (ids) {
        // Need to check nodes with ids passed in the array and uncheck all other nodes
        var node,
            i = 0,
            len = 0;

        this.tree.root.setChecked(false, true, true); // ignoring disabled nodes

        if (GUI.isArray(ids)) {
            for (i = 0, len = ids.length; i < len; i++) {
                node = this.tree.getNodeById(ids[i]);
                if (node) {
                    if (this.multiple) {
                        node.setChecked(true);
                    } else {
                        this.tree.selectNode(node, true);
                        break;
                    }
                }
            }
        } else {
            node = this.tree.getNodeById(ids);
            if (node) {
                if (this.multiple) {
                    node.setChecked(true);
                } else {
                    this.tree.selectNode(node, true);
                }
            }
        }
        this.updateValue();
    };

    /**
     * Removes children of tree
     */
    GUI.Forms.ComboTree.prototype.clear = function () {
        this.tree.root.removeChildren();
    };

    /**
     * Adds option to the tree
     * @param {Object} option Option to add
     */
    GUI.Forms.ComboTree.prototype.add = function (option) {
        return this.tree.root.appendChild(new GUI.Tree.Node(option));
    };

    /**
     * If not tree, init it, load children of the tree
     * @param {Array} options Array of the options
     */
    GUI.Forms.ComboTree.prototype.setOptions = function (options) {
        if (!this.tree || !this.tree.render) {
            this.initTree();
        }
        this.tree.root.loadChildren(options);
    };

    /**
     * Reload tree
     */
    GUI.Forms.ComboTree.prototype.reloadData = function () {
        this.tree.root.reload();
    };

    /**
     * Limit list height if it reached its max allowed value
     */
    GUI.Forms.ComboTree.prototype.checkListHeight = function () {
        var tree = this.tree,
            height = tree.getScrollHeight(),
            maxHeight = this.itemHeight * this.listHeight;

        if (this._listHeightLimited) {
            // Check if we can remove limitation
            if (height < maxHeight) {
                tree.setHeight('auto');
                this._listHeightLimited = false;
            }
        } else {
            // Check if we must apply limitation
            if (height > maxHeight) {
                tree.setHeight(maxHeight);
                this._listHeightLimited = true;
            }
        }
    };

}());
