/**
 *
 */
(function () {
    SystemsAlerts = new window.Class.create();

    prototype = {
        initialize: function (alerts) {

            this.types = {
                'red'   : 'informer_error',
                'yellow': 'informer_alert',
                'green' : 'informer_success',
                'blue'  : 'informer_info'
            };

            this.alerts = alerts || [];
            this.currentInformer = 0;

            this.tpl = GUI.$('system_alert');

            if (this.tpl && this.alerts.length) {
                this.renderInformer();
            }
        },

        renderInformer: function () {
            var tpl = this.tpl,
                layoutEl = GUI.Dom.findDescedent(document.body, 'div.layout__data');

            layoutEl.parentNode.insertBefore(tpl, layoutEl);
            GUI.show(tpl);

            this.infEl = tpl;
            this.titleEl = GUI.$('system_alert_title'),
            this.bodyEl = GUI.$('system_alert_message');

            this.numAlertsEl = GUI.$('numAlerts');
            this.nextAlertEl = GUI.$('nextAlert');
            this.prevAlertEl = GUI.$('prevAlert');

            this.infEl.on('click', this.onClickInformer.bindLegacy(this));

            this.showInformer();
        },

        getTypeInformer: function (type) {
            return this.types[type];
        },

        setNumAlerts: function () {
            this.numAlertsEl.innerHTML = (this.currentInformer + 1) + ' of ' + this.alerts.length;

            if (this.alerts.length > 0 && this.currentInformer > 0) {
                this.prevAlertEl.parentNode.removeClass('disabled');
            } else {
                this.prevAlertEl.parentNode.addClass('disabled');
            }

            if (this.alerts.length > 0 && this.currentInformer < (this.alerts.length - 1)) {
                this.nextAlertEl.parentNode.removeClass('disabled');
            } else {
                this.nextAlertEl.parentNode.addClass('disabled');
            }
        },

        prevAlert: function () {
            var cur = this.currentInformer;

            if (cur > 0) {
                cur -= 1;
            }

            this.currentInformer = cur;
            this.showInformer();
        },

        nextAlert: function () {
            var cur = this.currentInformer;

            if (cur < this.alerts.length - 1) {
                cur += 1;

                this.currentInformer = cur;
                this.showInformer();
            }
        },

        showInformer: function () {
            var data = this.alerts[this.currentInformer];

            this.infEl.className = 'informer';
            this.infEl.addClass(this.getTypeInformer(data.type));

            this.titleEl.innerHTML = data.title;
            this.bodyEl.innerHTML = data.message;

            this.setNumAlerts();
        },

        onClickInformer: function (e) {
            if (e) {
                e = GUI.Event.extend(e);
            }
            var target = e.getTarget();

            if (target.id === 'nextAlert') {
                this.nextAlert();
            } else if (target.id === 'prevAlert') {
                this.prevAlert();
            } else if (target.id === 'hideInformer' || target.id === 'hideAlert') {
                this.hideInformer();
            }
        },

        hideInformer: function () {
            var alertId = this.alerts[this.currentInformer].id;
            this.alerts.splice(this.currentInformer, 1);

            if (this.alerts.length === 0) {
                GUI.hide(this.infEl);
            } else {
                if (this.currentInformer === this.alerts.length) {
                    this.currentInformer -= 1;
                }
                this.showInformer();
            }

            new Ajax.Request(route('hideAlert', {alertId: alertId}), {
                onSuccess   : function () {}.bindLegacy(this)
            });
        },

        discardChanges: function(callback) {
            this.confirmMessage(null, {
                msg             : i18n('Do you want to discard your changes?'),
                okButton        : i18n('Keep Working'),
                cancelButton    : i18n('Discard Changes'),
                title           : i18n('Unsaved Changes'),
                primaryButton   : 'okButton',
                cancelCallback  : callback
            });
        },

        confirmMessage: function (callback, config) {
            if (!config) {
                config = {};
            }

            var msgBox,
                title = config.title || i18n('Delete'),
                msg = config.msg || i18n('Are you sure you want to delete this?'),
                okButton = config.okButton || i18n('Delete'),
                cancelButton = config.cancelButton || i18n('Cancel'),
                cancelCallback = config.cancelCallback;

            msg = '<div class="default-data-layout">' + msg + '</div>';

            msgBox = this._errMessageBox = new GUI.Popup.Dialog({
                alwaysOnTop: true,
                title: title,
                content: msg,
                modal: true,
                autoDestroy: true,
                dimensions: {
                    width: 400
                },
                toolbuttons: [],
                footerButtons: [{
                    name: 'cancel',
                    obj: new GUI.ToolBar.Button({
                        text: cancelButton,
                        onClick: function () {
                            if (cancelCallback) {
                                cancelCallback();
                            }

                            msgBox.close();
                        }
                    })
                }, {
                    name: 'ok',
                    obj: new GUI.ToolBar.Button({
                        text: okButton,
                        primary: true,
                        onClick: function () {
                            if (callback) {
                                callback();
                            }
                            msgBox.close();
                        }
                    })
                }]
            });
            msgBox.create();
            msgBox.show();
        }
    };

    Object.extend(window.SystemsAlerts.prototype, prototype);


    window.Alerts = new window.SystemsAlerts();
}());