/**
 *
 */
(function () {
    window.ProfileLayer = window.Class.create();

    prototype = {
        initialize: function () {
            this.data = {};

            this.region = new GUI.Popup.Region({
                className   : 'user-layer layer b-arrow',
                content     : ''
            });

            this.layer = new GUI.Popup.Layer({
                offset: [-5, 5],
                position: 'tl-bl?',
                target: document.body,
                delayShow: 300,
                layer: this.region,
                disableProcessEvent: {
                    onMouseEnter: true
                }
            });

            this.layer.on('onMouseMove', this.onMouseMove, this);
            this.layer.on('onShowLayer', GUI.TimeAgo.refreshPageTime, GUI.TimeAgo);
        },

        onMouseMove : function (e) {
            var target = e.target,
                el = target.getAttribute('showProfileLayer');

            if (!el) {
                el = target.parentNode.getAttribute('showProfileLayer');
                target = target.parentNode;
            }

            if (this.region.dom && GUI.Dom.isDescendant(this.region.dom, target)) {
                this.layer.stopTimerForHideLayer();
                return;
            }

            if (el && target) {
                if (this.userId !== el) {
                    if (this.userId && el && this.layer.isShowed()) {
                        this.layer.setPosition();
                    } else {
                        this.layer.startTimerForShowLayer();
                        this.layer.stopTimerForHideLayer();
                    }
                }
        
                this.userId = el;
                this.layer.setLayerPositionTo(target);
                this.setContent();
            } else if (this.userId) {
                this.layer.setLayerPositionTo(false);
                this.layer.startTimerForHideLayer();
                this.userId = null;
            }
        },

        /**
         * Send request
         * @param HTMLElement el
         * @param Event e
         */
        loadProfile: function (el, e) {
            // stop if the request has been sent
            if (this.request && !this.request.complete) {
                //this.request.abort();
                return;
            }
            // if not, send request
            this.request = new GUI.Ajax.Request(
                route('getUserLayer', {user: this.userId, hideButtons: true}), {
                    method : 'GET',
                    onSuccess : this.onSuccessLoadProfile.bindLegacy(this)
                }
            );
        },

        /**
         *
         */
        onSuccessLoadProfile: function (xhr, response) {
            this.data[response.responseJson.id] = response.responseJson;

            if (this.userId) {
                this.setContent(this.data[this.userId]);
            }
        },

        /**
         * Sets content of the region
         */
        setContent: function (data) {
            var userTpl, staffPhrase;

            if (!data) {
                data = this.data[this.userId];
            }

            if (!data || this.userId && this.userId != data.id) {
                this.region.setContent('<span class="loader">' + i18n('Loading...') + '</span>');
                this.layer.setPosition();

                this.loadProfile();
                return;
            }

            this.region.setContent(data.content);
            this.layer.setPosition();
            GUI.TimeAgo.refreshPageTime();
        }
    };

    Object.extend(window.ProfileLayer.prototype, prototype);

}());

