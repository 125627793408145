/**
 *
 */
(function () { // variables isolation

    window.ItemViewClass = window.Class.create();

    prototype = {

        _retriveTarget : function (e) {
            e = GUI.Event.extend(e);
            e.stop();

            var target = e.getTarget();

            if (target.nodeName !== 'A') {
                target = target.parentNode;
            }

            return target;
        },

        initialize : function () {
            var url = window.location.href,
                targetId, bestComment;

            this.currentClickEvent = false;

            this.followBtn = GUI.$('view_item_follow');

            if (!this.followBtn) {
                return;
            }
            // this.shareViaEmailBtn = GUI.$('view_item_share_via_email');

            this.replyBtnTop = GUI.$('view_item_reply_top');
            this.replyBtnBottom = GUI.$('view_item_reply_bottom');

            this.addReplyBtn = GUI.$('add_reply');
            this.closeReplyFormBtn = GUI.$('hide_reply_form');

            this.commentsSection = GUI.$('comments_section');

            this.bestCommentSection = GUI.$('best_comment_container');

            this.manageInAdminBtn = GUI.$('manage_in_admin');

            this.editInAdminBtn = GUI.$('edit_in_admin');

            this.sharesBtn = GUI.$('share_action');

            this.sharesRegion = new GUI.Popup.Region({
                className: 'layer selected',
                id: 'shares-layer',
                content: GUI.$('share_action_hint').innerHTML
            });

            this.layers = new GUI.Popup.Layer({
                target      : this.sharesBtn,
                layer       : this.sharesRegion,
                positionTarget: this.sharesBtn,
                useClickToShow: true,

                offset      : [1, 6],
                position    : 'tl-bl?',

                disableProcessEvent: {
                    onMouseEnter: true,
                    onMouseLeave: true
                }
            });

            this.layers.on('onShowLayer', this.addEventsListenersSharesRegion.bindLegacy(this));

            this.UUID = null;

            this.addEventsListeners();

            if (-1 !== url.indexOf('#')) {
                targetId = url.substring(url.indexOf('#') + 1);

                // do not run for actions that do not require additional reload
                if (GUI.$(targetId) && targetId !== 'view_item_follow') {
                    GUI.Event.fire(GUI.$(targetId), 'click');
                }
            }

            bestComment = GUI.$('best_comment_section');

            if (GUI.$('item_container')) {
                GUI.addToPreviewImageListener(GUI.$('item_container'));
                GUI.resizeBlockToParent(GUI.$('item_container'));
            }

            if (GUI.$('comments_section')) {
                GUI.addToPreviewImageListener(GUI.$('comments_section'));
                GUI.resizeBlockToParent(GUI.$('comments_section'));
            }

            if (bestComment) {
                GUI.addToPreviewImageListener(bestComment);
                GUI.resizeBlockToParent(bestComment);
            }
        },

        addEventsListeners: function () {
            this.followBtn.on('click', this.followAction.bindLegacy(this));

            // this.shareViaEmailBtn.on('click', this.shareViaEmailAction.bindLegacy(this));

            if (this.replyBtnTop) {
                this.replyBtnTop.on('click', this.replyAction.bindLegacy(this, 'top'));
            }

            if (this.replyBtnBottom) {
                this.replyBtnBottom.on('click', this.replyAction.bindLegacy(this, 'bottom'));
            }

            if (this.closeReplyFormBtn) {
                this.closeReplyFormBtn.on('click', this.hideAllForms.bindLegacy(this));
            }

            if (this.addReplyBtn) {
                this.addReplyBtn.on('click', this.postComment.bindLegacy(this, GUI.$('add_reply_form_form')));
            }

            if (this.commentsSection) {
                this.commentsSection.on('click', this.commentsActions.bindLegacy(this));
            }

            if (this.bestCommentSection) {
                this.bestCommentSection.on('click', this.commentsActions.bindLegacy(this));
            }

            if (this.manageInAdminBtn) {
                this.manageInAdminBtn.on('click', this.goToAdmin.bindLegacy(this, false));
            }

            if (this.editInAdminBtn) {
                this.editInAdminBtn.on('click', this.goToAdmin.bindLegacy(this, true));
            }

            jQuery("#delete-item").on('click', this.onItemDelete.bindLegacy(this));
        },

        addEventsListenersSharesRegion: function () {
            var url = encodeURIComponent(window.location.href);
            var title = encodeURIComponent(document.title);

            var facebookBtn = document.querySelector('#shares-layer #facebook-button');
            var twitterBtn = document.querySelector('#shares-layer #twitter-button');
            var linkedInBtn = document.querySelector('#shares-layer #linkedin-button');
            var googleBtn = document.querySelector('#shares-layer #google-button');
            var shareViaEmailBtn = document.querySelector('#shares-layer #share-via-email-button');

            if(facebookBtn) {
                facebookBtn.href = "https://www.facebook.com/sharer/sharer.php?u=" + url;
                facebookBtn.on('click', this.shareThisSocial.bindLegacy(this));
            }

            if(twitterBtn) {
                twitterBtn.href = "https://twitter.com/intent/tweet?url=" + url + "&text=" + title;
                twitterBtn.on('click', this.shareThisSocial.bindLegacy(this));
            }

            if(linkedInBtn) {
                linkedInBtn.href = "http://www.linkedin.com/shareArticle?mini=true&summary=" + title + "&title=" + title + "&url=" + url;
                linkedInBtn.on('click', this.shareThisSocial.bindLegacy(this));
            }

            if(googleBtn) {
                googleBtn.href = "https://plus.google.com/share?url=" + url;
                googleBtn.on('click', this.shareThisSocial.bindLegacy(this));
            }

            shareViaEmailBtn.on('click', this.shareViaEmailAction.bindLegacy(this));
        },

        reloadPageWithUrl: function (url) {
            window.location.href = url;
        },

        confirmSpam: function (target, el, message) {
            var url = target.href;

            Application.ConfirmForm.setCallerElement(el, message, 'spam', 'spam-dialog-form', 'spam-form');
            Application.ConfirmForm.setActionUrl(url);

            Application.ConfirmForm.show(true);
        },

        confirmDelete: function (target, el, message) {
            var url = target.href;

            Application.ConfirmForm.setCallerElement(el, message, 'delete', 'delete-dialog-form', 'delete-form');
            Application.ConfirmForm.setActionUrl(url);

            Application.ConfirmForm.show(true);
        },

        confirmMarkBest: function (target, el, message) {
            window.location.href = target.href;
            //var url = target.href;
            //
            //Application.MarkBestForm.setCallerElement(el, message);
            //Application.MarkBestForm.setActionUrl(url);
            //
            //Application.MarkBestForm.show(true);
        },

        followAction: function (e) {
            var target = this._retriveTarget(e);

            if (target.nodeName === 'A') {
                Application.AuthPanel.authenticate(function (href) {
                    this.reloadPageWithUrl(href);
                }.bindLegacy(this, target.getAttribute('data-href')));
            }
        },

        shareViaEmailAction: function (e) {
            var target = this._retriveTarget(e);

            this.layers.hideLayer();

            Application.AuthPanel.authenticate(function (Application, href) {
                Application.ShareViaEmailForm.setActionUrl(href);
                Application.ShareViaEmailForm.show();
            }.bindLegacy(this, Application, target.href));
        },

        shareThisSocial:function (e) {
            e.preventDefault();
            var href = e.target.href;

            var w = window,
                d = document,
                e = d.documentElement,
                g = d.getElementsByTagName('body')[0],
                x = w.innerWidth || e.clientWidth || g.clientWidth,
                y = w.innerHeight|| e.clientHeight|| g.clientHeight;

            var top = y > 400 ? (y - 400)/2 : 400;
            var left = x > 500 ? (x - 500)/2 : 500;

            window.open(href, "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, top=" + top + ", left=" + left + ", width=500, height=400");
        },

        goToAdmin: function (edit, e) {
            var url, target = this._retriveTarget(e);

            url = AdminRouter(
                'community',
                {
                    space: target.getAttribute('space'),
                    channel: target.getAttribute('channel'),
                    action: edit ? 'edit' : 'view',
                    itemKey: target.getAttribute('key')
                }
            );
            window.open(url, "target=_blank");
        },

        /**
         * COMMENTS SECTION
         */
        commentsActions: function (e) {
            e = GUI.Event.extend(e);

            var targetId, id, el, message,
                target = e.getTarget();

            if (target.nodeName !== 'A') {
                target = target.parentNode;
            }
            targetId = target.id;

            if (targetId.search('show_menu') === 0) {
                this.showMenu(target);
            }


            // show add comment form
            if (targetId.search('show_add_comment_form') === 0) {
                e.stop();

                if (Application.authorization && Application.authorization.id) {
                    this.showCommentForm(target);
                } else {
                    Application.AuthPanel.login(function () {
                        window.location.href = '#' + target.id;
                        window.location.reload();
                    }.bindLegacy(this, target));
                }
            }

            // post comment/reply
            if (targetId === 'add_comment_to_reply'
                    || targetId === 'edit_comment'
                    || targetId === 'edit_reply') {
                e.stop();
                this.postComment(GUI.findParentByTag(target, 'form'));
            }

            // hide all forms
            if (targetId.search('hide_reply_forms') === 0) {
                e.stop();
                this.hideAllForms();
            }
        },

        showMenu: function (target) {
            var items = [],
                elements = target.parentNode.querySelectorAll('span.menu-items a');

            for (var i = 0; i < elements.length; i++) {
                var item = elements[i];

                var targetId = item.id;

                if (targetId.search('mark_best') === 0) {
                    items.push({
                        text: item.querySelector('span').innerHTML,
                        onClick: function (item) {
                            Application.AuthPanel.authenticate(function (target) {
                                var id      = target.id.replace('mark_best_', ''),
                                    el      = GUI.$('item_comment_block_' + id),
                                    message = target.getAttribute('message');

                                if (checkFeatureRestriction('featureCommunityMarkBestRepliesEnabled')) {
                                    window.showConfirmFeatureNotAvailableDialog();
                                } else {
                                    this.confirmMarkBest(target, el, message);
                                }
                            }.bindLegacy(this, item));
                        }.bindLegacy(this, item)
                    });
                } else if (targetId.search('delete_comment') === 0) {
                    items.push({
                        text: item.querySelector('span').innerHTML,
                        onClick: function (item) {
                            Application.AuthPanel.authenticate(function (target) {
                                var id      = target.id.replace('delete_comment_', ''),
                                    el      = GUI.$('item_comment_block_' + id),
                                    message = target.getAttribute('message');

                                this.confirmDelete(target, el, message);
                            }.bindLegacy(this, item));
                        }.bindLegacy(this, item)
                    });
                } else if (targetId.search('spam_comment') === 0) {
                    items.push({
                        text: item.querySelector('span').innerHTML,
                        onClick: function (item) {
                            Application.AuthPanel.authenticate(function (target) {
                                var id      = target.id.replace('spam_comment_', ''),
                                    el      = GUI.$('item_comment_block_' + id),
                                    message = target.getAttribute('message');

                                this.confirmSpam(target, el, message);
                            }.bindLegacy(this, item));
                        }.bindLegacy(this, item)
                    });
                } else if (targetId.search('show_edit_reply_form') === 0) {
                    items.push({
                        text: item.querySelector('span').innerHTML,
                        onClick: function (item) {
                            Application.AuthPanel.authenticate(function (target) {
                                this.showEditReplyForm(target);
                            }.bindLegacy(this, item));
                        }.bindLegacy(this, item)
                    });

                } else if (targetId.search('show_edit_comment_form') === 0) {
                    items.push({
                        text: item.querySelector('span').innerHTML,
                        onClick: function (item) {
                            Application.AuthPanel.authenticate(function (target) {
                                this.showEditCommentForm(target);
                            }.bindLegacy(this, item));
                        }.bindLegacy(this, item)
                    });
                }
            }

            var menu = new GUI.Popup.Menu({
                name        : 'actions',
                hideOnDocumentClick: true,
                items: items,
                onClick: function (menu, item) {
                    item.onClick();
                }
            });

            menu.alignTo(target);
            menu.render();
            menu.show();
        },

        showReplyForm: function (position) {
            this.hideAllForms();

            var buttonEl,
                formEl = GUI.$('add_reply_form'),
                containerEl = GUI.$('best_comment_section') || GUI.$('comments_section');

            GUI.show(formEl);

            if (position === 'top') {
                buttonEl = GUI.$('view_item_reply_top');
                if (containerEl) {
                    containerEl.parentNode.insertBefore(formEl, containerEl);
                }

            } else if (position === 'bottom') {
                buttonEl = GUI.$('view_item_reply_bottom_div');
                if (containerEl) {
                    containerEl.parentNode.insertBefore(formEl, buttonEl);
                }
            }

            this.spaceKey = formEl.getAttribute('spaceKey');
            this.channelURI = formEl.getAttribute('channelType');
            this.itemId = formEl.getAttribute('itemId');

            this.UUID = GUI.getUUID();

            if (Application.replyForm) {
                Application.replyForm.destroy();
            }

            Application.replyForm = new GUI.Forms.EditorTinymce({
                id              : 'add-comment-editor',
                holder          : 'reply_textarea',
                type            : 'front_item',
                width           : '100%',
                value           : '',
                cssForIframe    : [window.cssForIframe],
                validation      : {minlength: 1},
                autoFocus       : true,
                autoresize      : true,
                imageHandlerUrl : route('fileLoad', {item: this.itemId, type: 'item_reply_image', UUID: this.UUID})
            });

            Application.replyForm.on('afterRenderEditor', function () {
                this.editorRenderedContent = Application.replyForm.getValue();
            }.bindLegacy(this));
            Application.replyForm.render();

            GUI.hide(buttonEl);

            GUI.scrollIntoBehindTheViewportElement(formEl);
        },

        showCommentForm: function (node, id) {
            this.hideAllForms();

            if (!id) {
                id = node.id.replace('show_add_comment_form_', '');
            }

            var formEl = GUI.$('add_comment_form'),
                containerEl = GUI.$('comments__content_' + id);
            containerEl.appendChild(formEl);

            // for best reply
            id = id.replace('_best', '');

            // set parent id
            GUI.$('add_comment_form_parent_id').value = id;

            this.UUID = GUI.getUUID();

            if (Application.addCommentForm) {
                Application.addCommentForm.destroy();
            }

            Application.addCommentForm = new GUI.Forms.EditorTinymce({
                id                  : 'add-comment-editor',
                holder              : 'add_comment_textarea',
                type                : 'front_item',
                hideToolbars        : true,
                validation          : {minlength: 1},
                cssForIframe        : [window.cssForIframe],
                width               : '100%',
                value               : '',
                autoFocus           : true,
                autoresize          : true,
                imageHandlerUrl : route('fileLoad', {item: this.itemId, type: 'item_reply_image', UUID: this.UUID})
            });
            Application.addCommentForm.render();

            GUI.show(formEl);
            GUI.scrollIntoBehindTheViewportElement(formEl);
        },

        showEditReplyForm: function (node) {
            this.hideAllForms();

            var formEl, commentEl, commentAvatarEl, formDivEl,
                id = node.id.replace('show_edit_reply_form_', '');

            this._editReplyFormId = id;

            formEl    = GUI.$('edit_reply_form');
            commentEl = GUI.$('item_comment_block_' + id);
            commentEl.parentNode.insertBefore(formEl, commentEl);

            GUI.$('edit_reply_form_form').action = node.href;

            // prepare avatar
            commentAvatarEl = GUI.$('avatar_comment_' + id);
            commentAvatarEl = commentAvatarEl.cloneNode(true);
            commentAvatarEl.id = 'edit_reply_avatar';

            formDivEl = GUI.$('edit_reply_form_div');
            formDivEl.insertBefore(commentAvatarEl, formDivEl.childNodes[0]);

            this.spaceKey   = formEl.getAttribute('spaceKey');
            this.channelURI = formEl.getAttribute('channelType');
            this.itemId     = formEl.getAttribute('itemId');

            if (Application.editReplyForm) {
                Application.editReplyForm.destroy();
            }

            Application.editReplyForm = new GUI.Forms.EditorTinymce({
                id              : 'add-comment-editor',
                holder          : 'edit_reply_textarea',
                type            : 'front_item',
                width           : '100%',
                value           : GUI.$('comment_content_' + id).innerHTML,
                autoresize      : true,
                cssForIframe    : [window.cssForIframe],
                validation      : {minlength: 1},
                imageHandlerUrl : route('fileLoad', {item: this.itemId, type: 'item_reply_image', comment: id})
            });
            Application.editReplyForm.on('afterRenderEditor', function () {
                this.editorRenderedContent = Application.editReplyForm.getValue();
            }.bindLegacy(this));
            Application.editReplyForm.render();

            GUI.hide(commentEl);
            GUI.show(formEl);
            GUI.scrollIntoBehindTheViewportElement(formEl);
        },

        showEditCommentForm : function (node) {
            var id, formEl, commentEl, commentAvatarEl, formDivEl;
            this.hideAllForms();

            id = node.id.replace('show_edit_comment_form_', '');

            this._editCommentFormId = id;

            formEl = GUI.$('edit_comment_form');
            commentEl = GUI.$('item_comment_block_' + id);
            commentEl.parentNode.insertBefore(formEl, commentEl);

            GUI.$('edit_comment_form_form').action = node.href;
            //GUI.$('edit_comment_textarea').value = GUI.$('comment_content_' + id).innerHTML;

            // prepare avatar
            commentAvatarEl = GUI.$('avatar_comment_' + id);
            commentAvatarEl = commentAvatarEl.cloneNode(true);
            commentAvatarEl.id = 'edit_comment_avatar';

            formDivEl = GUI.$('edit_comment_form_div');
            formDivEl.insertBefore(commentAvatarEl, formDivEl.childNodes[0]);

            if (Application.editCommentForm) {
                Application.editCommentForm.destroy();
            }

            Application.editCommentForm = new GUI.Forms.EditorTinymce({
                id              : 'add-comment-editor',
                holder          : 'edit_comment_textarea',
                type            : 'front_item',
                hideToolbars    : true,
                width           : '100%',
                autoresize      : true,
                cssForIframe    : [window.cssForIframe],
                validation      : {minlength: 1},
                value           : GUI.$('comment_content_' + id).innerHTML,
                imageHandlerUrl : route('fileLoad', {item: this.itemId, type: 'item_reply_image', comment: id})
            });
            Application.editCommentForm.on('afterRenderEditor', function () {
                this.editorRenderedContent = Application.editCommentForm.getValue();
            }.bindLegacy(this));
            Application.editCommentForm.render();

            GUI.hide(commentEl);
            GUI.show(formEl);
            GUI.scrollIntoBehindTheViewportElement(formEl);
        },

        hideAllForms : function () {
            this.hideReplyForm();
            this.hideCommentForm();
            this.hideEditReplyFrom();
            this.hideEditCommentForm();
        },

        hideReplyForm : function () {
            var formEl, buttonElTop, buttonElBottom;

            if (!Application.replyForm) {
                return;
            }

            var hideForm = function () {
                if (Application.replyForm) {
                    Application.replyForm.destroy();

                    if (Application.replyForm.holder) {
                        Application.replyForm.holder.innerHTML = '';
                    }
                }

                delete Application.replyForm;
                Application.replyForm = false;

                // form
                formEl = GUI.$('add_reply_form');
                GUI.hide(formEl);

                // buttons
                buttonElTop = GUI.$('view_item_reply_top');
                if (buttonElTop) {
                    GUI.show(buttonElTop);
                }

                buttonElBottom = GUI.$('view_item_reply_bottom_div');
                // button at the bottom may not exist when no comments available
                if (buttonElBottom) {
                    GUI.show(buttonElBottom);
                }
            }.bindLegacy(this);

            if (
                Application.replyForm.getValue()
            ) {
                Alerts.discardChanges(hideForm);
            } else {
                hideForm();
            }
        },

        hideCommentForm : function () {
            if(!Application.addCommentForm) {
                return;
            }

            var hideForm = function () {
                // form
                if (Application.addCommentForm) {
                    Application.addCommentForm.destroy();

                    if (Application.addCommentForm.holder) {
                        Application.addCommentForm.holder.innerHTML = '';
                    }
                }

                delete Application.addCommentForm;
                Application.addCommentForm = false;

                // form
                var formEl = GUI.$('add_comment_form');
                GUI.hide(formEl);
            }.bindLegacy(this);

            if (
                Application.addCommentForm.getValue()
            ) {
                Alerts.discardChanges(hideForm);
            } else {
                hideForm();
            }
        },

        hideEditReplyFrom : function () {
            var avatar, formEl, commentEl;

            if (!this._editReplyFormId) {
                return;
            }

            var hideForm = function () {
                if (Application.editReplyForm) {
                    Application.editReplyForm.destroy();

                    if (Application.editReplyForm.holder) {
                        Application.editReplyForm.holder.innerHTML = '';
                    }
                }

                delete Application.editReplyForm;
                Application.editReplyForm = false;

                avatar = GUI.$('edit_reply_avatar');
                GUI.remove(avatar);

                formEl = GUI.$('edit_reply_form');
                GUI.hide(formEl);

                commentEl = GUI.$('item_comment_block_' + this._editReplyFormId);
                GUI.show(commentEl);

                this._editReplyFormId = false;
            }.bindLegacy(this);

            if (
                Application.editReplyForm.getValue() !== this.editorRenderedContent
            ) {
                Alerts.discardChanges(hideForm);
            } else {
                hideForm();
            }
        },

        hideEditCommentForm : function () {
            var avatar, formEl, commentEl;

            if (!this._editCommentFormId) {
                return;
            }

            var hideForm = function () {
                if (Application.editCommentForm) {
                    Application.editCommentForm.destroy();

                    if (Application.editCommentForm.holder) {
                        Application.editCommentForm.holder.innerHTML = '';
                    }
                }

                delete Application.editCommentForm;
                Application.editCommentForm = false;

                avatar = GUI.$('edit_comment_avatar');
                GUI.remove(avatar);

                formEl = GUI.$('edit_comment_form');
                GUI.hide(formEl);

                commentEl = GUI.$('item_comment_block_' + this._editCommentFormId);
                GUI.show(commentEl);

                this._editCommentFormId = false;
            }.bindLegacy(this);


            if (
                Application.editCommentForm.getValue() !== this.editorRenderedContent
            ) {
                Alerts.discardChanges(hideForm);
            } else {
                hideForm();
            }
        },

        replyAction: function (type, e) {
            e = GUI.Event.extend(e);
            if (Application.authorization && Application.authorization.id) {
                this.showReplyForm(type);
            } else {
                Application.AuthPanel.login(function () {
                    window.location.href = '#' + this._retriveTarget(e).id;
                    window.location.reload();
                }.bindLegacy(this, type));
            }
        },

        postComment : function (form) {
            if (this.addReplyBtn.getAttribute('disabled')) {
                return;
            }

            var editor = Application.replyForm || Application.editReplyForm || Application.addCommentForm || Application.editCommentForm;

            if (!editor.validate()) {
                return;
            }

            form.comment.value = editor.getValue();

            if (Application.replyForm) {
                var UUIDinput   = document.createElement('input');
                UUIDinput.type  = 'hidden';
                UUIDinput.name  = 'UUID';
                UUIDinput.value = this.UUID;
                form.appendChild(UUIDinput);
            }

            Application.AuthPanel.authenticate(function () {
                    this.addReplyBtn.setAttribute('disabled', 'disabled');
                    form.submit();
            }.bindLegacy(this, form));
        },

        onClickAction : function (event) {
            var url = window.location.href,
                target = event.target;

            if (target.nodeName !== 'A') {
                target = target.parentNode;
            }

            // actions that do not require additional reload
            if (target.id === 'view_item_follow') {
                GUI.Event.fire(event.target, 'click', event);
                return;
            }

            if (-1 !== url.indexOf('#')) {
                url = url.substring(0, url.indexOf('#'));
            }

            window.location.href = url + '#' + target.id; // set anchor
            window.location.reload();
        },

        onItemDelete: function (event) {
            var url = jQuery(event.target).closest("button").data('href');

            Application.ConfirmForm.setCallerElement(event.target, null, 'delete', 'delete-dialog-form', 'delete-form');
            Application.ConfirmForm.setActionUrl(url);

            Application.ConfirmForm.show(true);
        }
    };

    Object.extend(window.ItemViewClass.prototype, prototype);
}());

GUI.onDOMReady(function () {
    setTimeout(function () {
        Application.Item = new window.ItemViewClass();
    }, 0);
});