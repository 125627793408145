(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Tree Node implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Tree
     * @extends GUI.Utils.Draggable
     */
    GUI.Tree.Node = Class.create();
    Object.extend(GUI.Tree.Node.prototype, superproto);

    /**
     * Parent node
     * @type HTMLElement
     */
    GUI.Tree.Node.prototype.parentNode = null;

    /**
     * First child
     * @type HTMLElement
     */
    GUI.Tree.Node.prototype.firstChild = null;

    /**
     * Last child
     * @type HTMLElement
     */
    GUI.Tree.Node.prototype.lastChild = null;

    /**
     * Previuos sibling
     * @type HTMLElement
     */
    GUI.Tree.Node.prototype.previousSibling = null;

    /**
     * Next sibling
     * @type HTMLElement
     */
    GUI.Tree.Node.prototype.nextSibling = null;

    /**
     * Constructor
     * TODO: Use native prototype inheritance instead of configs
     * @param {Object} config Configuration object
     */
    GUI.Tree.Node.prototype.initialize = function (config) {
        var cfg, defCfg, i, ch, len;

        defCfg = {
            text        : '',
            disabled    : false,
            editable    : true,
            expanded    : false,
            checked     : false,
            checkBox    : false,
            async       : false, // Set to true to enable ajax childs loading
            isDir       : true, // Set to False if node can't contain other nodes
            readOnly    : false, // true to disallow editing
            child       : [], // Array of config for child nodes
            unselectable: false,
            displayed   : true,
            images      : {
                dropNo          : 'b-icon fa fa-ban fa_14 fa_white',
                dropBetween     : 'b-icon fa fa-arrows-v fa_14 fa_white',
                dropIn          : 'b-icon fa fa-plus fa_14 fa_font_19 fa_white',
                error           : 'b-icon fa fa-exclamation-circle fa_14 fa_white'
            },
            // Drag'n'Drop configuration
            dd  : {
                linkedElId      : null,
                dragElId        : null,
                handleElId      : null,
                targetElId      : null,
                isTarget        : true,
                moveOnly        : false,
                groups          : {
                    'tree' : true
                },
                clipElId        : null,
                enableDrag      : true,
                dropReciever    : false,
                dragType        : ''
            },
            animate : window.GUI_FX_ANIMATION === true
        };
        Object.extend(defCfg, config);

        // Call parent method
        superproto.initialize.call(this, defCfg);

        cfg = this.config;
        Object.extend(cfg, defCfg);

        if (typeof cfg.id === 'undefined') {
            cfg.id = GUI.getUniqId('autogenerated-');
        }
        // Properties related to node's tree functionality
        this.childNodes = [];

        // Component(UI) properties
        this.dom            = null;
        this.id             = cfg.id;
        cfg.id              = GUI.getUniqId('node-') + '-id-' + this.id;
        this.leaf           = !cfg.isDir;
        this.elId           = cfg.id + '-el';
        this.checkBoxId     = cfg.id + '-checkbox';
        this.textId         = cfg.id + '-text';
        this.containerId    = cfg.id + '-container';
        //this.wrapId         = cfg.id + '-container-wrap';
        // Drag proxy
        this.dpDropImgId    = cfg.id + '-dragproxy-img';

        this.disabled       = cfg.disabled;
        this.editable       = cfg.editable;
        this.rendered       = this.childrenRendered = false;
        this.expanded       = cfg.expanded === true;
        this.checked        = cfg.checked === true;

        // Create childs, if passed in config
        if (GUI.isArray(cfg.child)) {
            len = cfg.child.length;
            for (i = 0; i < len; i++) {
                if (!cfg.child[i]) continue;
                ch = new GUI.Tree.Node(cfg.child[i]);
                this.appendChild(ch);
            }
        } else {
            cfg.async = true;
        }

        this.loaded = !cfg.async || this.leaf;
        // Animator creating is delayed until it is really needed to boost performance
    };

    /**
     * Initializes animation object
     */
    GUI.Tree.Node.prototype.initAnimator = function () {
        if (!this.fx) {
            this.fx = new Animator({
                duration    : 500,
                scope       : this,
                onComplete  : this.onAnimationComplete
            });
        }
    };

    /**
     * If state of the animation object is true call expand method or collapse method.
     * @param {Object} fx Animation object
     * @returns {Object} node
     */
    GUI.Tree.Node.prototype.onAnimationComplete = function (fx) {
        if (fx.state) {
            this._expand();
        } else {
            this._collapse();
        }
    };

    /**
     * Append child to the dom
     * Methods related to node's tree functionality
     * @param {Object} node Node for append
     * @returns {Object} node
     */
    GUI.Tree.Node.prototype.appendChild = function (node) {
        var ownerTree, index, oldParent, oldIndex, nodeOwnerTree, ps;

        if (this.lastChild === node) {
            return; // Nothing to do
        }

        ownerTree = this.getOwnerTree();
        if (ownerTree) {
            if (false === ownerTree.fireEvent('beforeappend', ownerTree, this, node)) {
                return false;
            }
        }

        index = this.childNodes.length;
        oldParent = node.parentNode;
        // it's a move, make sure we move it cleanly
        oldIndex = 0;
        if (oldParent) {
            oldIndex = node.getIndex();

            nodeOwnerTree = node.getOwnerTree();
            if (nodeOwnerTree) {
                // Tree, node, oldParent, oldIndex, newParent, newIndex
                if (false === nodeOwnerTree.fireEvent('beforemove', nodeOwnerTree, node, oldParent, oldIndex, this, index)) {
                    return false;
                }
            }
            oldParent.removeChild(node); // what happens if remove is blocked ?
        }
        index = this.childNodes.length;
        if (index === 0) {
            this.firstChild = node;
        }

        this.childNodes[index] = node;
        node.parentNode = this;

        ps = this.childNodes[index - 1];
        if (ps) {
            node.previousSibling = ps;
            ps.nextSibling = node;
        } else {
            node.previousSibling = null;
        }
        node.nextSibling = null;
        this.lastChild = node;
        node.setOwnerTree(ownerTree);

        if (this.childrenRendered) {
            if (!node.rendered) {
                node.render();
            }
        }

        if (ownerTree) {
            ownerTree.fireEvent('append', ownerTree, this, node, index);
            if (oldParent) {
                // Tree, Node, oldParent, oldIndex, newParent, newIndex
                ownerTree.fireEvent('move', ownerTree, node, oldParent, oldIndex, this, index);
            }
        }
        return node;
    };

    /**
     * Removes child
     * @param {Object} node Node for remove
     * @returns {Object} node
     */
    GUI.Tree.Node.prototype.removeChild = function (node) {
        var nodeOwnerTree, ownerTree,
            index = this.childNodes.indexOf(node);

        if (index === -1) {
            return false; // This is not our node, return
        }

        nodeOwnerTree = node.getOwnerTree();
        if (nodeOwnerTree) {
            if (false === nodeOwnerTree.fireEvent('beforeremove', nodeOwnerTree, this, node)) {
                return false;
            }
        }
        this.childIndent = null; // clear indentation cache before removing node

        // remove it from childNodes collection
        this.childNodes.splice(index, 1);

        // update siblings
        if (node.previousSibling) {
            node.previousSibling.nextSibling = node.nextSibling;
        }
        if (node.nextSibling) {
            node.nextSibling.previousSibling = node.previousSibling;
        }

        // update child refs
        if (this.firstChild === node) {
            this.firstChild = node.nextSibling;
        }
        if (this.lastChild === node) {
            this.lastChild = node.previousSibling;
        }

        node.setOwnerTree(null);

        // clear any references from the node
        node.parentNode = null;
        node.previousSibling = null;
        node.nextSibling = null;

        if (node.rendered) {
            node.unrender();
        }

        this.setExpandIcon();

        ownerTree = this.getOwnerTree();
        if (ownerTree) {
            ownerTree.fireEvent('remove', ownerTree, this, node);
        }
        return node;
    };

    /**
     * Removes child from current parent node
     * @returns {Object} node
     */
    GUI.Tree.Node.prototype.remove = function () {
        if (this.parentNode) {
            return this.parentNode.removeChild(this);
        }
        return false;
    };

    /**
     * Insert node before refNode, passing in the arguments
     * @param {Object} node Node for insert
     * @param {Object} refNode Before this node will be append the new node
     */
    GUI.Tree.Node.prototype.insertBefore = function (node, refNode) {
        var index, ownerTree, oldParent, refIndex, oldIndex, nodeOwnerTree, ps;
        if (!refNode) { // like standard Dom, refNode can be null for append
            return this.appendChild(node);
        }

        if (node === refNode) {
            return false; // nothing to do
        }

        ownerTree = this.getOwnerTree();
        if (ownerTree) {
            if (false === ownerTree.fireEvent('beforeinsert', ownerTree, this, node, refNode)) {
                return false;
            }
        }

        index = this.childNodes.indexOf(refNode);
        oldParent = node.parentNode;
        refIndex = index;

        // when moving internally, indexes will change after remove
        if (oldParent === this && this.childNodes.indexOf(node) < index) {
            refIndex--;
        }

        // it's a move, make sure we move it cleanly
        oldIndex = 0;
        if (oldParent) {
            oldIndex = node.getIndex();

            nodeOwnerTree = node.getOwnerTree();
            if (nodeOwnerTree) {
                // Tree, node, oldParent, oldIndex, newParent, newIndex
                if (false === nodeOwnerTree.fireEvent('beforemove', nodeOwnerTree, node, oldParent, oldIndex, this, index)) {
                    return false;
                }
            }

            oldParent.removeChild(node); // what happens if remove is blocked?
        }
        if (refIndex === 0) {
            this.firstChild = node;
        }
        this.childNodes.splice(refIndex, 0, node);
        node.parentNode = this;
        ps = this.childNodes[refIndex - 1];
        if (ps) {
            node.previousSibling = ps;
            ps.nextSibling = node;
        } else {
            node.previousSibling = null;
        }
        node.nextSibling = refNode;
        refNode.previousSibling = node;
        node.setOwnerTree(this.getOwnerTree());

        if (this.childrenRendered) {
            if (!node.rendered) {
                node.render();
            }
        }

        if (ownerTree) {
            ownerTree.fireEvent('insert', ownerTree, this, node, index, refNode);
            if (oldParent) {
                // Tree, Node, oldParent, oldIndex, newParent, newIndex
                ownerTree.fireEvent('move', ownerTree, node, oldParent, oldIndex, this, refIndex);
            }
        }
        return node;
    };

    /**
     * Returns owner tree
     * @returns {Object} tree
     */
    GUI.Tree.Node.prototype.getOwnerTree = function () {
        return this.ownerTree;
    };

    /**
     * Sets owner tree
     * @param {Object} tree
     */
    GUI.Tree.Node.prototype.setOwnerTree = function (tree) {
        var cs, i, len;
        // if it's move, we need to update everyone
        if (tree !== this.ownerTree) {
            if (this.ownerTree) {
                if (this.config.checkBox) {
                    this.setChecked(false);
                }
                this.ownerTree.unregisterNode(this);
                // maybe clear childindent here ???
            }
            this.ownerTree = tree;
            // Also get checkbox settings from tree...
            if (tree) {
                this.config.checkBox = tree.config.checkBox;
            }
            cs = this.childNodes;
            for (i = 0, len = cs.length; i < len; i++) {
                cs[i].setOwnerTree(tree);
            }
            if (tree) {
                tree.registerNode(this);
            }
        }
    };

    /**
     * Returns depth of the tree
     * @returns {Number} depth
     */
    GUI.Tree.Node.prototype.getDepth = function () {
        var depth = 0,
            p = this;
        while (p.parentNode) {
            ++depth;
            p = p.parentNode;
        }
        return depth;
    };

    /**
     * Returns index of the current node
     * @returns {Number} index
     */
    GUI.Tree.Node.prototype.getIndex = function () {
        if (this.parentNode) {
            return this.parentNode.childNodes.indexOf(this);
        }
        return 0;
    };

    /**
     * Returns true if node is leaf
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.isLeaf = function () {
        return this.leaf === true && this.childNodes.length === 0;
    };

    /**
     * Returns true if node is first
     * @param {Boolean}
     */
    GUI.Tree.Node.prototype.isFirst = function () {
        return !this.parentNode ? true : this.parentNode.firstChild === this;
    };

    /**
     * Returns true if node is last
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.isLast = function () {
        return (!this.parentNode ? true : this.parentNode.lastChild === this);
    };

    /**
     * Returns true if node is root
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.isRootChild = function () {
        return this.parentNode && this.parentNode.isRoot;
    };

    /**
     * Returns true if node has children
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.hasChildNodes = function () {
        return ((!this.isLeaf()) && (this.childNodes.length > 0)) || !this.loaded;
    };

    /**
     * Returns number of the descendants
     * @returns {Number} num
     */
    GUI.Tree.Node.prototype.getNumberOfDescedents = function () {
        var count = 0;
        this.cascade(function () {count++;});
        return count;
    };

    /**
     * Returns true if node has any content
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.contains = function (node) {
        return node.isAncestor(this);
    };

    /**
     * Returns true if node is ancestor
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.isAncestor = function (node) {
        var p = this.parentNode;
        while (p) {
            if (p === node) {
                return true;
            }
            p = p.parentNode;
        }
        return false;
    };


    /**
     * Bubbles up the tree from this node, calling the specified function with each node. The scope (<i>this</i>) of
     * function call will be the scope provided or the current node. The arguments to the function
     * will be the args provided or the current node. If the function returns false at any point,
     * the bubble is stopped.
     * @param {Function} fn The function to call
     * @param {Object} scope (optional) The scope of the function (defaults to current node)
     * @param {Array} args (optional) The args to call the function with (default to passing the current node)
     */
    GUI.Tree.Node.prototype.bubble = function (fn, scope, args) {
        var p = this;
        while (p) {
            if (fn.apply(scope || p, args || [p]) === false) {
                break;
            }
            p = p.parentNode;
        }
    };

    /**
     * Cascades down the tree from this node, calling the specified function with each node. The scope (<i>this</i>) of
     * function call will be the scope provided or the current node. The arguments to the function
     * will be the args provided or the current node. If the function returns false at any point,
     * the cascade is stopped on that branch.
     * @param {Function} fn The function to call
     * @param {Object} scope (optional) The scope of the function (defaults to current node)
     * @param {Array} args (optional) The args to call the function with (default to passing the current node)
     */
    GUI.Tree.Node.prototype.cascade = function (fn, scope, args) {
        if (fn.apply(scope || this, args || [this]) !== false) {
            var i, len,
                cs = this.childNodes;
            for (i = 0, len = cs.length; i < len; i++) {
                cs[i].cascade(fn, scope, args);
            }
        }
    };

    /**
     * Returns path of the current node
     * @returns {Array} path
     */
    GUI.Tree.Node.prototype.getPath = function () {
        var p = [];
        this.bubble(function (node) {
            p.push({
                id  : node.id,
                text: node.config.text
            });
        });
        return p;
    };

    /**
     * Returns path of the current node.
     * @param {Boolean} includeRoot If true path include root node
     * @returns {String} path
     */
    GUI.Tree.Node.prototype.getPathString = function (nameField, joinStr, includeRoot) {
        var p = [];
        includeRoot = includeRoot || false;
        nameField = nameField || 'text';
        joinStr = joinStr || '&nbsp;/&nbsp;';
        this.bubble(function (node) {
            if (node.isRoot && !includeRoot) {
                return;
            }
            p.unshift(node.config[nameField]);
        });

        return p.join(joinStr);
    };

    /**
     * Returns node by the text
     * @param {String} text Text of the node
     * @returns {Object} node
     */
    GUI.Tree.Node.prototype.getChildByText = function (text) {
        var i, node,
            children = this.childNodes,
            len = children.length;

        for (i = 0; i < len; i++) {
            node = children[i];
            if (node.getText() === text) {
                return node;
            }
        }
        return null;
    };

    /**
     * Generates node html and returns html string
     * @returns {String} html of the node
     */
    GUI.Tree.Node.prototype._getHtml = function () {
        var html = [],
            nodeId = 'jsgui-tree-node-' + this.id,
            tree = this.getOwnerTree(),
            //disabled = '',
            checked = '',
            disabled_checkbox = '',
            divClasses = ['b-tree__item'],
            iconExpander = ['b-tree__icon', 'expander'],
            iconFolder = ['b-tree__icon'],
            depth = this.getDepth();

        if (this.config.iconFolder) {
            iconFolder.push(this.config.iconFolder);
        } else {
            iconFolder.push('fa fa-folder');
        }

        // root node
        if (this.isRoot) {
            if (tree.config.resizeTextHeaders) {
                if (tree.config.sizeTextRootLevel) {
                    divClasses.push('header_' + tree.config.sizeTextRootLevel);
                }
            }

            if (this.expanded) {
                iconExpander.push('expander_opened');
                iconFolder.push('fa fa-folder-open');
            }
        }

        if (tree.config.resizeTextHeaders) {
            if (depth === 1) {
                if (tree.config.sizeTextFirstLevel) {
                    divClasses.push('header_' + tree.config.sizeTextFirstLevel);
                }
            } else if (depth === 2) {
                if (tree.config.sizeTextSecondLevel) {
                    divClasses.push('header_' + tree.config.sizeTextSecondLevel);
                }
            }
        }

        // selected
        if (this.selected) {
            divClasses.push('selected');
        }

        // disabled
        if (this.disabled) {
            divClasses.push('disabled');
        }

        // expanded
        if (this.config.child.length) {
            if (this.expanded) {
                iconExpander.push('expander_opened');
                iconFolder.push('fa fa-folder-open');
                this.expand();
            }
        } else if (!this.isRoot) {
            iconExpander.push('expander_empty');
        }

        divClasses = divClasses.join(' ');
        iconExpander = iconExpander.join(' ');
        iconFolder = iconFolder.join(' ');

        html.push('<li id="' + nodeId + '" class="' + (this.config.liClass || 'b-tree__node') + '" ');

        // displayed
        if (!this.config.displayed) {
            html.push(' style="display: none;" ');
        }

        if (this.config.title) {
            html.push(' title="' + this.config.title + '" ');
        }
        html.push(' >');

        html.push('<div id="' + this.elId + '" class="' + divClasses + '" ');

        if (this.isRoot && !tree.isRootVisible()) {
            html.push(' style="display: none;" ');
        }
        html.push(' >');

        // expand collapse actions
        if (this.isRoot) {
            if (tree.config.expandButton && tree.config.collapseButton) {
                //html.push('<span class="b-tree__expand"><a href="#" id="jsgui-tree-expand-all" >(' + i18n('Expand') + '</a>' +
                html.push('<span class="b-tree__expand"><a href="#" id="jsgui-tree-expand-all" >(' + 'Expand' + '</a>' +
                    ' / ' +
                    //'<a href="#" id="jsgui-tree-collapse-all">' + i18n('Collapse') + ')</a></span>');
                    '<a href="#" id="jsgui-tree-collapse-all">' + 'Collapse' + ')</a></span>');

            } else if (tree.config.expandButton && !tree.config.collapseButton) {
                //html.push('<span class="b-tree__expand"><a href="#" id="jsgui-tree-expand-all" >(' + i18n('Expand') + ')</a></span>');
                html.push('<span class="b-tree__expand"><a href="#" id="jsgui-tree-expand-all" >(' + 'Expand' + ')</a></span>');

            } else if (!tree.config.expandButton && tree.config.collapseButton) {
                //html.push('<span><a href="#" id="jsgui-tree-collapse-all" >(' + i18n('Collapse') + ')</a></span>');
                html.push('<span><a href="#" id="jsgui-tree-collapse-all" >(' + 'Collapse' + ')</a></span>');
            }
        }

        //expander
        if (tree.config.expandIcons) {
            html.push('<i class="' + iconExpander + ' exp"');

            if (this.config.expanderTitle) {
                html.push(' title="' + this.config.expanderTitle + '" ');
            }
            html.push('>&nbsp;</i>');
        }


        // icon
        if (tree.config.noIcon !== true && this.config.noIcon !== true) {
            html.push('<i class="' + iconFolder + ' fld"');
            
            if (this.config.iconTitle) {
                html.push(' title="' + this.config.iconTitle + '" ');
            }
            html.push('>&nbsp;</i>');
        }

        // actions icon
        if (tree.config.actions && this.config.actions && this.config.actions.length) {
            html.push('<i class="b-tree__icon b-tree__icon_right fa far fa-ellipsis-v act"');

            if (this.config.actionsTitle) {
                html.push(' title="' + this.config.actionsTitle + '" ');
            }
            html.push('>&nbsp;</i>');
        }

        // checkbox
        if (this.config.checkBox) {
            if (this.disabled && this.ownerTree.config.allowDisabledCheckbox) {
                disabled_checkbox = 'disabled="disabled"';
            }
            if (this.checked) {
                checked = 'checked="checked"';
            }
            html.push('<i class="b-tree__checkbox__cell">' +
                '<input class="b-tree__checkbox" id="' + this.checkBoxId + '" type="checkbox" ' + disabled_checkbox + checked + ' />' +
                '</i>');
        }

        if (this.config.needBadge) {
            html.push('<span class="b-badge">' + this.config.badge + '</span>');
        }

        // text
        html.push('<span id="' + this.textId + '" class="b-tree__label"');

        if (this.config.textTitle) {
            html.push(' title="' + this.config.textTitle + '" ');
        }
        html.push('>' + (this.config.text || '') + '</span>');

        html.push('</div>');

        // Render child nodes if exist
        if (this.config.child) {
            html.push('<ul id="' + this.containerId + '" class="b-tree');

            if (this.isRoot && this.getOwnerTree().config.rootVisible === false) {
                html.push(' b-tree_root_hidden');
            }

            html.push('">');

            if (this.loading) {
                html.push('<span>' + this.loadingText + '</span>'); // TODO: change to i18n.xxx
            }
            html.push('</ul>');
        }

        return html.join('');
    };

    /**
     *
     */
    GUI.Tree.Node.prototype.setExpandIcon = function (expand) {
        if (!this.dom || !this.dom.firstChild) return;

        var el = this.dom.firstChild.findDescedent('i.exp');

        if (!el) {
            return;
        }

        if (this.expanded && this.childNodes.length) {
            GUI.Dom.removeClass(el, 'expander_empty');
            GUI.Dom.addClass(el, 'expander_opened');
            GUI.Dom.addClass(el.nextSibling, 'fa fa-folder-open');
        } else if (!this.expanded && this.childNodes.length) {
            this.expand();
        } else {
            this.collapse();
            GUI.Dom.addClass(el, 'expander_empty');
            GUI.Dom.removeClass(el, 'expander_opened');
            GUI.Dom.removeClass(el.nextSibling, 'fa fa-folder-open');
        }

    };

    /**
     * Renders dom
     */
    GUI.Tree.Node.prototype.render = function () { // Slow...
        if (this.rendered) {
            return;
        }

        var to,
            tree = this.getOwnerTree(),
            alwaysExpanded = tree.config.alwaysExpanded,
            needLoad = false;

        if (alwaysExpanded ||
                (!tree.isRootVisible() && (this.isRoot || (this.isRootChild() && this.expanded )))) {
            // Hard expand of the node
            this.expanded = true;
        }

        if (this.expanded) {
            if (!this.loaded) {
                // If node has to be rendered expanded then make it collapsed
                // and pend loading
                needLoad = true;
            }
        }

        to = (this.parentNode)
            ? this.parentNode.getContainer()
            : this.ownerTree.getContainer();

        to = GUI.$(to);

        if (this.nextSibling && this.nextSibling.rendered) {
            this.dom = GUI.Dom.extend(GUI.Dom.insertBefore(this.nextSibling.getDom(), this._getHtml()));
        } else {
            this.dom = GUI.Dom.extend(GUI.Dom.append(to, this._getHtml()));
        }

        this.nodeEl = this.dom.firstChild;
        this.containerEl = this.dom.lastChild;

        this.rendered = true;

        if (tree && tree.isEditable() && !this.disabled && this.editable && tree.config.showEditableHint) {
            //this.setHint(tree.config.hintText || i18n('Double click to edit'));
            this.setHint(tree.config.hintText || 'Double click to edit');
        } else if (this.disabled && tree.config.disabledNodeHint) {
            this.setHint(tree.config.disabledNodeHint);
        }

        // Initialize Drag'n'Drop
        this.ddConfig.linkedElId    = this.elId;
        this.ddConfig.targetElId    = this.elId;
        this.ddConfig.clipElId      = this.ownerTree.config.id;
        this.ddConfig.groups        = this.ownerTree.ddConfig.groups;
        this.initDD(); // also slowdowns :-(
        this.setHandleElId(this.elId);

        if (needLoad) {
            this.load({
                onLoad : function () {
                    if (this.expanded) {
                        this.expand();
                    }
                }.bindLegacy(this)
            });
        } else {
            if (this.expanded) {
                this.renderChildren();
            }
        }
    };

    /**
     * Sets hint of the node
     * @param {String} hint Text of the hint
     */
    GUI.Tree.Node.prototype.setHint = function (hint) {
        this.config.hint = hint;
        if (this.dom) {
            GUI.Popup.Hint.add(GUI.$(this.textId), '', hint);
        }
    };

    /**
     * Renders children
     */
    GUI.Tree.Node.prototype.renderChildren = function () {
        if (!this.childrenRendered) {
            var i,
                nodes  = this.childNodes,
                len    = nodes.length;

            for (i = 0; i < len; i++) {
                nodes[i].render();
            }
            this.childrenRendered = true;
        }
    };

    /**
     * Destroys objects, dom
     * @param {Boolean} quick
     */
    GUI.Tree.Node.prototype.destroy = function (quick) {
        var i, len;
        if (this._request && !this._request.complete) {
            this._request.abort();
            this._request = null;
        }

        if (this.fx) {
            this.fx.destroy();
            this.fx = null;
        }

        for (i = 0, len = this.childNodes.length; i < len; i++) {
            this.childNodes[i].destroy(quick);
        }
        this.childNodes = null;

        if (this.dom) {
            // Destroy Drag'n'Drop
            this.destroyDD();

            if (!quick) {
                GUI.destroyNode(this.dom);
            }

            if (this.wrapeEl) {
                GUI.destroyNode(this.wrapEl);
                this.wrapEl = null;
            }

        }
        this.dom = this.nodeEl = this.containerEl = null;
    };

    /**
     * Removes choldren, dom, objects
     * @param {Boolean} quick
     */
    GUI.Tree.Node.prototype.unrender = function (quick) {
        var i, len;
        if (this.dom) {
            // Destroy Drag'n'Drop
            this.destroyDD();

            for (i = 0, len = this.childNodes.length; i < len; i++) {
                this.childNodes[i].unrender(quick);
            }
            this.childrenRendered = false;
            if (!quick) {
                GUI.remove(this.dom);
            }
            this.dom = null;
            this.rendered = false;
        }
    };

    /**
     * If node is expanded  collapse it
     */
    GUI.Tree.Node.prototype.toggle = function () {
        if (this.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    };

    /**
     * Sets style of the container
     */
    GUI.Tree.Node.prototype.wrapContainer = function () {
        var cont, dims;
        if (this.wrapped) {
            return false;
        }

        if (!this.wrapEl) {
            this.wrapEl = document.createElement('div');
            s = this.wrapEl.style;
            s.position    = 'relative';
            s.overflow    = 'hidden';
        }

        cont = GUI.$(this.containerId);

        if (!this.expanded) {
            dims = GUI.getDimensions(cont, true);
            s.height  = dims.height + 'px';
            s.width   = dims.width + 'px';
        }
        cont.parentNode.insertBefore(this.wrapEl, cont);
        this.wrapEl.appendChild(cont);
        cont.style.position  = 'absolute';
        cont.style.bottom    = '0px';
        this.wrapped = true;
        return true;
    };

    /**
     * Removes style from the container
     */
    GUI.Tree.Node.prototype.unwrapContainer = function () {
        if (!this.wrapped) {
            return;
        }
        var cont;
        if (this.wrapEl.parentNode) {
            cont = this.wrapEl.firstChild;
            cont.style.position    = '';
            cont.style.bottom    = '';

            this.wrapEl.parentNode.insertBefore(cont, this.wrapEl);
            GUI.remove(this.wrapEl);
        } else {
            console.log('bug', this);
        }
        cont = null;
        this.wrapped = false;
    };

    /**
     * Prepares animation objects
     */
    GUI.Tree.Node.prototype.prepareFx = function () {
        if (this.fx.isRunning()) {
            return;
        }
        this.wrapContainer();
        this.fx.clearSubjects();
        var h = GUI.getDimensions(this.containerEl, true).height,
            startHeight = (GUI.isIE) ? 1 : 0;
        // need to pre set height

        this.fx.state = this.expanded ? 0 : 1;

        if (this.fx.state) {
            this.wrapEl.style.height = h + 'px';
        } else {
            this.wrapEl.style.height = startHeight + 'px';
        }
        this.fx.addSubject(new window.NumericalStyleSubject(this.wrapEl, 'height', startHeight, h));
    };

    /**
     * Expands node
     * @param {Boolean} deep If true expand children
     * @param {Boolean} fast If true expand without animation
     */
    GUI.Tree.Node.prototype.expand = function (deep, fast) {
        if (!this.loaded) {
            // If node childs are not loaded, delay expanding until childs are loaded
            setTimeout(function () {
                this.load({
                    onLoad : function () {
                        this.expand(deep, fast);
                    }.bindLegacy(this)
                });
            }.bindLegacy(this), 20);
            return;
        }

        if (!this.expanded) {
            this.expanded = true;
            if (this.rendered) {
                if (!this.childrenRendered) {
                    this.renderChildren();
                }
                if (this.config.child && this.childNodes.length) {
                    this.nodeEl.findDescedent('i.exp').removeClass('expander_empty');
                    this.nodeEl.findDescedent('i.exp').addClass('expander_opened');
                    this.nodeEl.findDescedent('i.fld').addClass('fa fa-folder-open');
                }

                if (this.config.animate) {
                    if (!this.fx) {
                        this.initAnimator();
                    }
                    if (!fast && !deep) {
                        GUI.show(this.containerId);
                        this.prepareFx();
                        this.fx.seekTo(1.0);
                    } else {
                        this.fx.jumpTo(1);
                        if (this.DDM.dragObj) {
                            this.DDM.refreshCache(this.ddConfig.groups);
                        }
                    }
                } else {
                    GUI.show(this.containerId);

                    if (this.DDM.dragObj) {
                        this.DDM.refreshCache(this.ddConfig.groups);
                    }
                    this.fireEvent('expand', this);
                    this.relayEvent('expand');
                }
            }
        }
        if (deep) {
            this.expandChildNodes(true, true);
        }
    };

    /**
     * Fire event 'expand', refresh cach of the Drag'n'Drop object
     */
    GUI.Tree.Node.prototype._expand = function () {
        // Cleanup animation
        this.unwrapContainer();
        if (this.DDM.dragObj) {
            this.DDM.refreshCache(this.ddConfig.groups);// was commented
        }
        this.fireEvent('expand', this);
        this.relayEvent('expand');
    };

    /**
     * Relays event to the owner tree
     * @param {String} eventName Name of the event
     */
    GUI.Tree.Node.prototype.relayEvent = function (eventName) {
        var ownerTree = this.ownerTree;
        if (ownerTree) {
            ownerTree.fireEvent('node' + eventName, ownerTree, this);
        }
    };

    /**
     * Expands child nodes
     * @param {Boolean} deep If true expand children
     * @param {Boolean} fast If true expand without animation
     */
    GUI.Tree.Node.prototype.expandChildNodes = function (deep, fast) {
        var i, len,
            cs = this.childNodes;
        for (i = 0, len = cs.length; i < len; i++) {
            cs[i].expand(deep, fast);
        }
    };

    /**
     * Collapses the node
     * @param {Boolean} deep If true collapse children
     * @param {Boolean} fast If true collapse without animation
     */
    GUI.Tree.Node.prototype.collapse = function (deep, fast) {
        if (this.expanded) { //  && !this.isRoot
            this.expanded = false;
            if (this.rendered) {
                if (this.config.animate) {
                    if (!this.fx) {
                        this.initAnimator();
                    }

                    if (!fast && !deep) {
                        this.prepareFx();
                        this.fx.seekTo(0);
                    } else {
                        GUI.Dom.removeClass(this.nodeEl.findDescedent('i.exp'), 'expander_opened');
                        GUI.Dom.removeClass(this.nodeEl.findDescedent('i.fld'), 'fa fa-folder-open');
                        this.fx.jumpTo(0);
                    }
                } else {
                    GUI.Dom.removeClass(this.nodeEl.findDescedent('i.exp'), 'expander_opened');
                    GUI.Dom.removeClass(this.nodeEl.findDescedent('i.fld'), 'fa fa-folder-open');

                    GUI.hide(this.containerId);
                    this.fireEvent('expand', this);
                    this.relayEvent('expand');
                }
            }
        }
        if (deep) {
            this.collapseChildNodes(deep, true);
        }
    };

    /**
     * Removes css classes 'jsgui-tree-node-expanded', fire event 'expand'
     */
    GUI.Tree.Node.prototype._collapse = function () {
        this.unwrapContainer();
        GUI.hide(this.containerId);
        this.fireEvent('expand', this);
        this.relayEvent('expand');
    };

    /**
     * Collapse child nodes
     * @param {Boolean} deep If true collapse children
     * @param {Boolean} fast If true collapse without animations
     */
    GUI.Tree.Node.prototype.collapseChildNodes = function (deep, fast) {
        var i, len,
            cs = this.childNodes;
        for (i = 0, len = cs.length; i < len; i++) {
            cs[i].collapse(deep, fast);
        }
    };

    /**
     * Removes children
     */
    GUI.Tree.Node.prototype.removeChildren = function () {
        this.beginUpdate(); // disable immediate rendering
        if (this.hasChildNodes()) {
            // Remove current childs
            while (this.firstChild) {
                this.removeChild(this.firstChild);
            }
        }
        this.endUpdate(); // render changes at once
    };

    /**
     * Updates node with some datas
     */
    GUI.Tree.Node.prototype.update = function (data) {
        this.loadChildren(data);
        this.setExpandIcon();
        this.onLoadComplete();
        if (this.ownerTree) {
            this.ownerTree.fireEvent('update', this, data, this.ownerTree);
        }
    };

    /**
     * Removes children, appends new children from data
     * @param {Array} data Array of the nodes
     */
    GUI.Tree.Node.prototype.loadChildren = function (data) {
        var i, len, ch;
        this.removeChildren();

        // Append new
        this.beginUpdate(); // disable immediate rendering
        for (i = 0, len = data.length; i < len; i++) {
            ch = new GUI.Tree.Node(data[i]);
            this.appendChild(ch);
        }

        // Render them
        this.endUpdate(); // render at once
        this.loaded = true;
    };

    /**
     * Loads node's childs via ajax request
     * @param {Object} loadCfg Configuration object
     */
    GUI.Tree.Node.prototype.load = function (loadCfg) {
        if (this.loading) {
            return; // TODO: maybe better to abort request and send a new one ?
        }

        var onLoad, treeData,
            pid = this.id,
            data = {
                parentID: pid
            },
            treeConfig = this.getOwnerTree().config;

        if (GUI.isString(treeConfig.postBody)) {
            treeData = Object.fromQueryString(treeConfig.postBody);
            Object.extendIf(data, treeData);
        }

        if (this.ownerTree) {
            if (false === this.ownerTree.fireEvent('beforenodeload', this.ownerTree, this, data)) {
                return false;
            }
        }

        onLoad = function () {};
        if (loadCfg) {
            if (loadCfg.onLoad) {
                onLoad = loadCfg.onLoad;
            }
        }

        if (this.loaded || this.isLeaf()) {
            return;
        }

        // Show loader
        if (this.rendered) {
           this.setLoadIcon(this.nodeEl.findDescedent('i.fld'), 'add');
        }

        // Set loading status
        this.loading = true;

        if (this._request && !this._request.complete) {
            this._request.abort();
        }
        this._request = new GUI.Ajax.Request(treeConfig.dataUrl, {
            method      : treeConfig.method,
            data        : data,
            scope       : this,
            onSuccess   : this.onLoadSuccess,
            onFailure   : this.onLoadFailure,
            onComplete  : this.onLoadComplete,
            onLoadNodeCallback : onLoad // can be accessed from succ/fail handler
        });
        return true;
    };

    GUI.Tree.Node.prototype.setLoadIcon = function (node, action) {
        node[action + 'Class']('loading');
    };

    /**
     * Try load children with json from response, fires event 'nodeLoad'.
     * If error fires event 'nodeLoadFailed'
     * @param {Object} transport
     * @param {Object} request
     */
    GUI.Tree.Node.prototype.onLoadSuccess = function (transport, request) {
        if (!this.childNodes) {
            return; // Node was destroyed while we were loading, nothing to do
        }

        var response, data;

        this.loading = false;
        try {
            response = request.responseJson;
            if (!response || !response.done) {
                // TODO: find a way to tell user that loading of node
                // content failed
                return;
            }
            data = response.tree;
            this.loadChildren(data);

            request.onLoadNodeCallback();
            if (this.ownerTree) {
                this.ownerTree.fireEvent('nodeLoad', this, response, this.ownerTree);
            }

        } catch (e) {
            if (this.ownerTree) {
                this.ownerTree.fireEvent('nodeLoadFailed', this, response, this.ownerTree);
            }
            alert(e.message);
            throw e;
        }
    };

    /**
     * Fires event 'nodeLoadFailed'
     */
    GUI.Tree.Node.prototype.onLoadFailure = function () {
        if (!this.childNodes) {
            return; // Node was destroyed while we were loading, nothing to do
        }

        this.loading = false;

        if (this.ownerTree) {
            this.ownerTree.fireEvent('nodeLoadFailed', this, this.ownerTree);
        }
    };

    /**
     * Removes css class 'jsgui-tree-node-loading'
     */
    GUI.Tree.Node.prototype.onLoadComplete = function () {
        if (this.dom) {
            this.setLoadIcon(this.nodeEl.findDescedent('i.fld'), 'remove');
        }
    };

    /**
     * Sets the state of the childrenRendered to false
     */
    GUI.Tree.Node.prototype.beginUpdate = function () {
        this.childrenRendered = false;
    };

    /**
     * If node is rendered and expanded, render children
     */
    GUI.Tree.Node.prototype.endUpdate = function () {
        if (this.rendered && this.expanded) {
            this.renderChildren();
        }
    };

    /**
     * If node is not leaf calls load method
     */
    GUI.Tree.Node.prototype.reload = function () {
        if (!this.isLeaf()) {
            this.loaded = false;
            this.load();
        }
    };

    /**
     * Returns the dom
     * @returns {HTMLElement} dom
     */
    GUI.Tree.Node.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Returns container
     * @returns {HTMLElement} container
     */
    GUI.Tree.Node.prototype.getContainer = function () {
        return GUI.$(this.containerId);
    };

    /**
     * Adds to the node css class 'jsgui-tree-node-item-selected'.
     * If node without children or it is not leaf
     * adds css class 'jsgui-tree-node-item-opened'
     */
    GUI.Tree.Node.prototype.onSelect = function () {
        if (!this.disabled) {
            GUI.Dom.addClass(this.nodeEl, 'selected');
        }
    };

    /**
     * Removes from the node css class 'jsgui-tree-node-item-selected'.
     * If the node has not children or it is not leaf
     * removes css class 'jsgui-tree-node-item-opened'
     */
    GUI.Tree.Node.prototype.onUnselect = function () {
        GUI.Dom.removeClass(this.nodeEl, 'selected');
       /* if (!this.leaf && !this.hasChildNodes()) {
         //   GUI.Dom.removeClass(this.nodeEl, 'opened');
        }*/
    };

    /**
     * If node has children called toogled method
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onExpandIconClick = function (e) {
        if (this.hasChildNodes()) {
            this.toggle();
        }
    };

    /**
     * empty function
     */
    GUI.Tree.Node.prototype.onIconClick = function (e) {
        //
    };

    /**
     * Sets checked
     */
    GUI.Tree.Node.prototype.toggleChecked = function () {
        this.setChecked(!this.checked);
    };

    /**
     * Sets checkbox state
     * @param {Boolean} val Checkbox state to set, by default is true
     * @param {Boolean} deep Set state for all descendant nodes checkboxes, by default is false
     * @param {Boolean} ignoreDisabled Ignore disabled nodes (but traverse them if deep is true)
     */
    GUI.Tree.Node.prototype.setChecked = function (val, deep, ignoreDisabled) {
        if (this.config.checkBox) {
            if (!GUI.isSet(val)) {
                val = true;
            }

            val = !!val;

            if (!this.disabled || !ignoreDisabled) {
                this.checked = val;

                if (this.rendered) {
                    GUI.$(this.checkBoxId).checked = val;
                }

                if (this.ownerTree) {
                    this.ownerTree.onCheckNode(this, val);
                }
            }

            if (deep) {
                // If deep is true, then check all descedents
                this.cascade(function () {
                    this.setChecked(val, false, ignoreDisabled);
                });
            }
        }
    };

    /**
     * Returns text of the node
     * @returns {String} text
     */
    GUI.Tree.Node.prototype.getText = function () {
        return this.config.text;
    };

    /**
     * Sets text of the node
     * @param {String} text New text for the node
     */
    GUI.Tree.Node.prototype.setText = function (text) {
        this.config.text = text;
        if (this.rendered) {
            GUI.$(this.textId).innerHTML = text;
        }
    };

    /**
     * If node is not disabled fires event 'nodeclick'
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onClick = function (e) {
        // Has to select item
        if (!this.disabled) {
            this.ownerTree.fireEvent('nodeclick', this.ownerTree, this, e);
        }
    };

    /**
     * If node is editable and not disabled shows editor,
     * fires event 'nodedblclick'
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onDblClick = function (e) {
        // Show editor
        var tree = this.getOwnerTree();
        if (this.editable && tree && tree.isEditable() && !this.disabled) {
            tree.showEditor(this);
        }
        if (!this.disabled) {
            this.ownerTree.fireEvent('nodedblclick', this.ownerTree, this, e);
        }
    };

    /**
     * Shows hint
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onMouseOver = function (e) {
        var textNode, textWidth, textHeight, treeDom, p, treeWidth, treeHeight,
            treeRegion, textRegion,
            nodeEl = this.nodeEl;

        if (e.isMouseEnter(nodeEl)) {
            // check if text is visible
            textNode = GUI.$(this.textId);
            if (!textNode) return;
            textWidth = textNode.offsetWidth; // margin
            textHeight = textNode.offsetHeight;

            if (this.config.unselectable) {
                GUI.addClass(nodeEl, 'unselectable');
            }

            if (this.config.not_movable) {
                GUI.addClass(nodeEl, 'not_movable');
            }

            treeDom = this.ownerTree.dom;
            p = GUI.getRelativeOffset(textNode, treeDom); // with scroll

            treeWidth = treeDom.clientWidth; // offsetWidth,
            treeHeight = treeDom.clientHeight; // offsetHeight;

            treeRegion = new GUI.Utils.Region(0, treeWidth, treeHeight, 0);
            textRegion = new GUI.Utils.Region(p[1], p[0] + textWidth, p[1] + textHeight, p[0]);

            if (!treeRegion.contains(textRegion)) {
                this.ownerTree.showHint(this);
            }
        }
    };

    /**
     * Hides hint,
     * fires event 'nodeMouseOut'
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onMouseOut = function (e) {
        var nodeEl, tree, hintList,
            ownerTree = this.ownerTree;

        if (!ownerTree) {
            return; // hovered node is deleted, nothing to do
        }
        if (false === ownerTree.fireEvent('nodeBeforeMouseOut', ownerTree, this, e)) {
            return false;
        }
        nodeEl = this.nodeEl;
        if (e.isMouseLeave(nodeEl)) {
            tree     = this.getOwnerTree();
            hintList = this.getOwnerTree().hintList;
            if (hintList && hintList.dom && !e.isMouseLeave(hintList.dom)) {
                // Going on hint
                return false; // do not fire mouseout event!
            } else {
                tree.hideHint();
            }
        }

        ownerTree.fireEvent('nodeMouseOut', ownerTree, this, e);
        return false;
    };

    // Drag...
    /**
     * Returns true if Drag'n'drop enabled and the node is not root and not disabled
     * @returns {Boolean}
     */
    GUI.Tree.Node.prototype.onBeforeDragStart = function () {
        if (this.ownerTree.fireEvent('beforeDragStart', this))
            return (this.ownerTree.config.enableDd && !this.isRoot && !this.disabled);
        return false;
    };

    /**
     * Creates drag proxy element
     * @param {Number} x Left position
     * @param {Number} y Top position
     */
    GUI.Tree.Node.prototype.onDragStart = function (x, y) {
        this.dropAccepted = false;

        if (!this.dragProxy) {
            this.dragProxy = new GUI.Popup.Region({
                className   : 'b-dialog b-dialog_phantom',
                dimensions  : {width    : 'auto'},
                content     :
                    GUI.Popup.Hint.renderHint({
                        text: '<i class="b-icon ' + this.config.images.dropNo + '" id="' + this.dpDropImgId + '">&nbsp;</i> ' + this.config.text
                    })
            });
        }
        this.dragProxy.setDimensions({
            left : x + 16,
            top  : y + 16
        });
        this.dragProxy.show();
    };

    /**
     * Paint drag proxy near mouse cursor
     * @param {Event} e Event
     * @param {Boolean} dropAccepted
     */
    GUI.Tree.Node.prototype.onDrag = function (e, dropAccepted) {
        var img, images,
            xy = e.getPageXY(),
            x = xy[0],
            y = xy[1],
            style = this.dragProxy.dom.style;

        style.left = (x + 16).toString() + 'px';
        style.top = (y + 16).toString() + 'px';

        if (!dropAccepted) {
            this.dragCursor = false;
        }

        if (this.dropAccepted !== this.dragCursor) {
            images = this.config.images;
            switch (this.dragCursor) {
            case 'before':
            case 'after':
                img = images.dropBetween;
                break;
            case 'in':
                img = images.dropIn;
                break;
            default:
                img = images.dropNo;
                break;
            }

            GUI.$(this.dpDropImgId).setClass(img); // canDropImg;
            this.dropAccepted = this.dragCursor;
        }
    };

    /**
     * Hides proxy element
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onDragEnd = function (e) {
        this.dragProxy.hide();
    };

    /**
     * Sets value of the moveDest and the dragCursor to null
     * @param {Event} e Event
     * @param {Object} dragObj Object fo the drag'n'drop
     */
    GUI.Tree.Node.prototype.onDragEnter = function (e, dragObj) {
        this.moveDest = null;
        dragObj.dragCursor = null;
    };

    /**
     * Check where is mouse pointer:
     * - if it's in top 33% of item height, render line before item
     * - if it's in bottom 33% of item height, render line after item
     * - else we append item into this node.
     * @param {Event} e Event
     * @param {Object} dragObj Object of the drag'n'drop
     * @param {Object} pt
     */
    GUI.Tree.Node.prototype.onDragOver = function (e, dragObj, pt) {
        this.DDM.refreshCache(this.ddConfig.groups);
        this.setHint('');
        // Check where is mouse pointer:
        // - if it's in top 33% of item height, render line before item
        // - if it's in bottom 33% of item height, render line after item
        // - else we append item into this node
        var p, h, y, deltaY, oneThird, dims, bypass,
            acceptDrop = false,
            lr = this.getOwnerTree().getLineRegion();

        if (this.isLeaf() && !dragObj.isLeaf()) {
            // Ignore moving directory to leaf
            lr.hide();
            dragObj.dragCursor = null;
            return false;
        }
        // for customize hint images, such a images.dropNo
        if (false === this.getOwnerTree().onTreeDragOver(e, dragObj, pt, this.moveDest, this)) {
            return false;
        }

        if (this.isRoot || (!this.isLeaf() && dragObj.isLeaf())) {
            if (this.moveDest !== 'in') {
                // No positioning, hide line, add hover
                this.moveDest = 'in';
                lr.hide();
                // GUI.Dom.addClass(this.nodeEl, 'jsgui-tree-node-item-hover');

                if (!this.isLeaf() && !this.expanded && !this.autoExpandTimer) {
                    // Delay auto expand if collapsed
                    this.autoExpandTimer = setTimeout(function () {
                        this.expand();
                        this.autoExpandTimer = null;
                    }.bindLegacy(this), 500);
                }
            }
            dragObj.dragCursor = 'in';
            return true;
        }

        p = this.DDM.locationCache[this.config.id];
        h = p.getHeight();
        y = e.getPageXY()[1];
        deltaY    = y - p.top;
        oneThird  = Math.round(0.33 * h);
        // Positioning can be applyed
        if (this.isAncestor(dragObj)) {
            lr.hide();
            dragObj.dragCursor = null;
            return false;
        }

        if (!this.isLeaf() && (deltaY > oneThird) && (deltaY < 2 * oneThird)) {
            if (this.moveDest !== 'in') {
                // Hide line, add hover
                this.moveDest = 'in';
                lr.hide();
                // GUI.Dom.addClass(this.nodeEl, 'jsgui-tree-node-item-hover');
                if (!this.expanded && !this.autoExpandTimer) {
                    // Delay auto expand if collapsed
                    this.autoExpandTimer = setTimeout(function () {
                        this.expand();
                        this.autoExpandTimer = null;
                    }.bindLegacy(this), 500);
                }
            }
            dragObj.dragCursor = 'in';
        } else {
            dims = {
                top: p.top
            };

            bypass = false;
            if (deltaY <= oneThird) {
                bypass = (this.moveDest === 'before');
                this.moveDest = 'before';
                dragObj.dragCursor = 'before';
            } else {
                bypass = (this.moveDest === 'after');
                this.moveDest = 'after';
                dragObj.dragCursor = 'after';
                dims.top += h;
            }
            if (!bypass) {
                // Show line
                lr.setDimensions(dims);
                lr.show();
              //  GUI.Dom.removeClass(this.nodeEl, 'jsgui-tree-node-item-hover');
            }
        }
        if (false === this.getOwnerTree().onNodeDragOver(e, dragObj, pt, this)) {
            acceptDrop = false;
        } else {
            acceptDrop = true;
        }
        return acceptDrop;
    };

    /**
     * Removes styles from the node element
     * @param {Event} e Event
     * @param {Object} dragObj Object of the drag'n'drop
     */
    GUI.Tree.Node.prototype.onDragLeave = function (e, dragObj) {
        if (this.autoExpandTimer) {
            clearTimeout(this.autoExpandTimer);
            this.autoExpandTimer = null;
        }

        var style = this.nodeEl.style;
        style.backgroundColor = '';
        style.borderTop = '';
        style.borderBottom = '';
        dragObj.dragCursor = null;
    };

    /**
     * Moves node
     * @param {Event} e Event
     * @param {Object} dragObj Object of the drag'n'drop
     */
    GUI.Tree.Node.prototype.onDragDrop = function (e, dragObj) {
        if (this.ownerTree && this.ownerTree.isEditable() && !this.disabled && this.editable && this.ownerTree.showEditableHint) {
            this.ownerTree.root.cascade(function (node) {
                //node.setHint(i18n('Double click to edit'));
                node.setHint('Double click to edit');
            });
        }
        if (dragObj instanceof GUI.Tree.Node) {
            if (
                this.id !== dragObj.id &&       // can't move to itself
                    !this.isAncestor(dragObj)   // can't move to subtree
            ) {

                var cs, refNode, i, len,
                    lr = this.getOwnerTree().lineRegion;
                lr.hide();

                if (this.isLeaf() && !dragObj.isLeaf()) {
                    // Ignore moving directory to leaf
                } else if (this.isRoot || (!this.isLeaf() && dragObj.isLeaf())) {
                    // Always append file to directory and anything to root
                    this.appendChild(dragObj);
                    this.expand();
                } else {
                    // Else we can position node
                    switch (this.moveDest) {
                    case 'before':
                        // Move node before us
                        this.parentNode.insertBefore(dragObj, this);
                        break;

                    case 'after':
                        // Move node after us
                        this.parentNode.insertBefore(dragObj, this.nextSibling);
                        break;

                    case 'in':
                        if (this.isLeaf()) {
                            // Moving file into file results into moving after file
                            this.parentNode.insertBefore(dragObj, this.nextSibling);
                        } else {
                            // Moving dir into dir results into appending dir to last dir on dir
                            cs = this.childNodes;
                            refNode = null;
                            for (i = 0, len = cs.length; i < len; i++) {
                                if (cs[i].isLeaf()) {
                                    refNode = cs[i];
                                    break;
                                }
                            }
                            this.insertBefore(dragObj, refNode);
                            this.expand();
                        }
                        break;
                    }
                }
                return true;
            }
        }
        return false;
    };

    /**
     * Hides th eline region
     * @param {Event} e Event
     */
    GUI.Tree.Node.prototype.onInvalidDrop = function (e) {
        var lr = this.getOwnerTree().lineRegion;
        if (lr) {
            lr.hide();
        }
    };

    GUI.Tree.Node.prototype.hide = function () {
        this.config.displayed = false;
        this.dom.style.display = 'none';
    };

    GUI.Tree.Node.prototype.show = function () {
        this.config.displayed = true;
        this.dom.style.display = '';
    };

}());
