GUI.onDOMReady(function() {
    var pagings = GUI.Dom.findDescedents(document.body, '.js-page-input');
    for (var i = 0; i < pagings.length; i ++ ) {
        pagings[i].on('keydown', function(ev) {
            if (ev.keyCode == 13) {
                var input = ev.target;
                window.location.replace(
                    input.getAttribute('route').replace(/_page_/, input.value)
                );
            }
        });
    }
});