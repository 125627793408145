var superproto = GUI.Bar.prototype;

/**
* JavaScript Graphical User Interface
* TabBar implementation
*
* @author Eugene Lyulka
* @version 2.0
* @namespace GUI
* @extends GUI.Bar
*/
GUI.TabBar = Class.create();
Object.extend(GUI.TabBar.prototype, superproto);

/**
 * Possible tabbar positions, can be 'top', 'right', 'bottom', 'left'
 * @type Array
 */
GUI.TabBar.prototype.positions = ['top', 'right', 'bottom', 'left'];

/**
 * Possible tabbar alignments
 * @type Array
 */
GUI.TabBar.prototype.aligns = {
    horizontal : ['left', 'right', 'center', 'none'],
    vertical   : ['top', 'middle', 'buttom']
};

/**
 * Initializes object
 * @param {Object} config Configuration object
 */
GUI.TabBar.prototype.initialize = function (config) {
    // Call parent initialize meethod
    GUI.Bar.prototype.initialize.apply(this, arguments);

    var elems, len, i, row, rows, j, elem,
        cfg = this.config;

    // Extend parent config
    Object.extend(cfg, {
        id          : GUI.getUniqId('tabbar-'),
        className   : 'b-tabbar',
        position    : 'top',
        multiline   : false,
        background  : false,

        pills       : false,
        primary     : false,
        inline      : false,

        minimal     : false
    });

    Object.extend(cfg, config);
    if (!GUI.isSet(cfg.align)) {
        cfg.align = ((cfg.position === 'top') || (cfg.position === 'bottom')) ? 'left' : 'top';
    }
    this.elementType = 'GUI.TabBar';
    this.activeTab = undefined;
    this.rendered = false;
    this.lastContentHidden = true;//false;

    // Add events
    this.addEvents({
        tabSwitch    : true
    });

    // Add event listeners from config
    if (typeof cfg.onTabSwitch === 'function') {
        this.on('tabSwitch', cfg.onTabSwitch, this);
    }

    // Add elements from config if passed
    if (cfg.multiline) {
        for (i = 0, rows = cfg.elements.length; i < rows; i++) {
            row = cfg.elements[i];
            for (j = 0, len = row.length; j < len; j++) {
                elem = row[j];
                this.add(elem.name, elem.obj, i);
            }
        }
    } else {
        if ((typeof (elems = cfg.elements) === 'object') && ((len = cfg.elements.length) > 0)) {
            for (i = 0; i < len; i++) {
                elem = elems[i];
                this.add(elem.name, elem.obj);
            }
        }
    }
};

/**
 * Adds element to tabbar
 * @param {String} name
 * @param {Object} elem
 * @param {Number} rowIndex
 */
GUI.TabBar.prototype.add = function (name, elem, rowIndex) {
    // Call parent method
    GUI.Bar.prototype.add.apply(this, arguments);

    if (elem instanceof GUI.TabBar.Tab) {
        elem.setBar(this);

        if (elem.isActive()) {
            if (GUI.isSet(this.activeTab)) {
                this.activeTab.deactivate();
            }
            elem.activate();
            this.activeTab = elem;
        }
    }

    // Render new element if bar is already rendered
    if (!this.rendered) {
        return;
    }
    elem.render(this.dom.firstChild);
};

/**
 * Removes element from tabbar
 * @param {String | Number} key String identifier or index number of element to remove
 */
GUI.TabBar.prototype.remove = function (key) {
    var div,
        elem = this.getElement(key);

    if (elem instanceof GUI.TabBar.Tab) {
        elem.deactivate();
    }
    // If already rendered then remove in dom
    if (this.rendered) {
        if (elem === -1) {
            throw new Error('trying to remove non-existent tab');
        }
        elem = elem.getDom();
        if (['top', 'bottom'].include(this.config.position)) {
            // horizontal bar, remove td
            div = elem.parentNode;
            div.removeChild(elem);
        } else {
            // vertical bar, remove tr/td
            div = elem.parentNode.parentNode;
            div.removeChild(elem);
        }
    }

    // Call parent method
    return GUI.Bar.prototype.remove.apply(this, arguments);
};

/**
 * Switch tabs. Deactivates active tab, and activates tab, passed as argument
 * @param {Object | String} id
 */
GUI.TabBar.prototype.switchTo = function (id, silent) {
    var newTab, oldTab;

    if (GUI.isString(id)) {
        newTab = this.elements.get(id);
    } else if (id instanceof GUI.TabBar.Tab) {
        newTab = id;
    } else {
        throw new Error(this.elementType + ' error: switchTo() unknown argument');
    }

    if (!GUI.isSet(newTab)) {
        // Non-existent new tab, return
        return;
    }
    oldTab = this.activeTab;
    if (newTab === oldTab) {
        // Trying to switch to itself, return
        return;
    }

    if (GUI.isSet(oldTab)) {
        oldTab.deactivate(newTab.hiddenContent);
    }
    newTab.activate();
    this.activeTab = newTab;

    if (!silent) {
        this.fireEvent('tabswitch', this, this.activeTab);
    }
};

/**
 * Remove tab from tabbar. If closing activeTab, then switch to next tab
 * @param {Object} tab Tab
 */
GUI.TabBar.prototype.closeTab = function (tab) {
    if (tab === this.activeTab) {
        this.nextTab();
    }
    this.remove(this.elements.indexOf(tab));
};

/**
 * Switches active tab to next tab
 * @returns {Object} Object of next tab
 */
GUI.TabBar.prototype.nextTab = function () {
    var c, item,
        count = this.elements.getCount(),
        index = this.elements.indexOf(this.activeTab),
        i = index + 1;

    for (c = 1; c < count; c++, i++) {
        if (i === count) {
            i = 0;
        }
        item = this.elements.itemAt(i);
        if ((item instanceof GUI.TabBar.Tab) && (item.isEnabled())) {
            this.switchTo(item);
            return item;
        }
    }
    return false;
};

/**
 * Sets bar position.
 * @param {String} pos Tabbar position: one of values from GUI.TabBar.positions
 */
GUI.TabBar.prototype.setPosition = function (pos) {
    if (!this.positions.include(pos)) {
        throw new Error('setPosition(): unknow position type specified: ' + pos);
    }
    this.config.position = pos;
    if (this.rendered) {
        // set tbody class name accroding to position
        this.dom.firstChild.className = pos;
    }
};

/**
 * Sets bar alignment.
 * @param {String} align  Tabbar alignment: one of values from GUI.TabBar.aligns
 */
GUI.TabBar.prototype.setAlign = function (align) {
    var cfg = this.config;
    if ((cfg.position === 'top') || (cfg.position === 'bottom')) {
        if (!this.aligns.horizontal.include(align)) {
            throw new Error('setAlign(): incorrect align for horizontal position specified: ' + align);
        }
        // setting horizontal alignment
        if (this.dom && (align !== 'none')) {
            // setting table align attribute
            // this.dom.align = align;
            this.dom.addClass(align);
        }
    } else if ((cfg.position === 'left') || (cfg.position === 'right')) {
        if (!this.aligns.vertical.include(align)) {
            throw new Error('setAlign(): incorrect align for vertical position specified: ' + align);
        }
    }
    cfg.align = align;
};

/**
 * Renders DOM tree and sets this.dom to root of this tree
 * @param {HTMLElement} containerNode
 * @return {Object} rendered DOMNode object
 */
GUI.TabBar.prototype._render = function (containerNode) {
    var div, divData,
        cfg = this.config;

    div = document.createElement('DIV');
    div.className = 'b-tabbar b-buttons-list';

    this.dom = div;

    if (cfg.renderAfterBeginHolder) {
        containerNode.insertBefore(div, containerNode.firstChild);
    } else {
        containerNode.appendChild(div);
    }
    //containerNode.appendChild(div);

    GUI.Dom.extend(this.dom);

    if (cfg.extraClass) {
        div.addClass(cfg.extraClass);
    }

    if (cfg.minimal) {
        div.addClass('b-tabbar_minimal');
    }

    if (cfg.pills) {
        div.addClass('b-tabbar_pills');
        if (cfg.primary) {
            div.addClass('b-tabbar_pills_primary');
        }
        if (cfg.inline) {
            div.addClass('b-tabbar_pills_inline');
        }
    }

    if (['top', 'bottom'].include(this.config.position)) {
        // horizontal bar
        this.elements.each(function (elem) {
            if (cfg.background) {
                elem.extraClass = 'b-button_tab_blue';
            }
            if (cfg.minimal) {
                elem.extraClass = 'b-button_tab_minimal';
            }
            elem.render(div);
        });

    } else {
        // vertical bar
        div.className = 'b-tabbar b-tabbar_vertical b-buttons-list_top';
        if (cfg.extraClass) {
            div.addClass(cfg.extraClass);
        }

        divData = document.createElement('DIV');
        divData.className = 'b-buttons-list__data';
        div.appendChild(divData);

        this.elements.each(function (elem) {
            elem.extraClass = 'b-button_tab_minimal_vertical';
            elem.render(divData);
        });
    }
    this.setAlign(this.config.align);
    return this.dom;
};

/**
 * Destroys objects
 * @param {Boolean} quick
 */
GUI.TabBar.prototype.destroy = function (quick) {
    GUI.Bar.prototype.destroy.call(this, quick);
    if (this.dom) {
        if (!quick) {
            GUI.destroyNode(this.dom);
        }
        this.dom = null;
    }
};

