(function () {
    var superprotot = GUI.Utils.Observable.prototype;

    GUI.Utils.KeyboardEvents = Class.create();
    Object.extend(GUI.Utils.KeyboardEvents.prototype, superprotot);


    GUI.Utils.KeyboardEvents.prototype.searchKeys = {}; // Constants for search event name
    GUI.Utils.KeyboardEvents.prototype.eventsList = {};
    GUI.Utils.KeyboardEvents.prototype.pauseEventsFire = false;

    /**
     *
     * @param target
     * @param cfg
     */
    GUI.Utils.KeyboardEvents.prototype.initialize = function (target, cfg) {
        this.config = Object.extend({
            byEvent: 'keydown',
            anyKey : false
        }, cfg);
        this.config.target = target;
        this.attachEvents();
    };

    /**
     * Stop fire events
     */
    GUI.Utils.KeyboardEvents.prototype.pause = function () {
        this.pauseEventsFire = true;
    };

    /**
     * Resume fire events
     */
    GUI.Utils.KeyboardEvents.prototype.resume = function () {
        this.pauseEventsFire = false;
    };

    /**
     *
     */
    GUI.Utils.KeyboardEvents.prototype.attachEvents = function () {
        this.addEvents(this.eventsList);
        this.config.target.on(this.config.byEvent, this.onKey, this);
    };
    /**
     *
     */
    GUI.Utils.KeyboardEvents.prototype.destroy = function () {
        this.keyboardBrowserEvents(true);
        this.config.target.un(this.config.byEvent, this.onKey, this);
        this.purgeListeners();
    };

    /**
     * @param e
     */
    GUI.Utils.KeyboardEvents.prototype.onKey = function (e) {
        if (this.pauseEventsFire) {
            return;
        }

        var keyName = this.searchKeys[e.which];
        if (this.config.anyKey) {
            this.fireEvent(GUI.Utils.keys.anyKey, keyName, e, e.ctrlKey, e.altKey)
        } else if (keyName) {
            this.fireEvent(keyName, e, e.ctrlKey, e.altKey);
        }
    };

    /**
     * Enable or disable all browser keyboard events
     * @param enable
     */
    GUI.Utils.KeyboardEvents.prototype.keyboardBrowserEvents = function (enable) {
        if (enable) {
            this.config.target['on' + this.config.byEvent] = null;
        } else {
            this.config.target['on' + this.config.byEvent] = function () {
                return false;
            };
        }
    };


    // Prepare actions
    var keyName, keyValue,
        eventsList = {};

    for (keyName in GUI.Utils.keysToCode) {
        if (GUI.Utils.keysToCode.hasOwnProperty(keyName)) {
            keyValue = GUI.Utils.keysToCode[keyName];
            GUI.Utils.KeyboardEvents.prototype.searchKeys[keyValue] = keyName;

            eventsList[keyName] = true;
        }
    }
    GUI.Utils.KeyboardEvents.prototype.eventsList = eventsList;
})();
