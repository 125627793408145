/**
 *
 */
GUI.onDOMReady(function () {
    if (GUI.$('new-ticket-reply')) {
        NewTicketReplyController.init();
    }
});

/**
 *
 */
NewTicketReplyController = new GUI.Controller({
    name    : 'NewTicketReply',
    models  : '',
    views   : '',

    form    : null
});


NewTicketReplyController.init = function () {
    this.form = new GUI.Forms.Form();
    this.form.assign('new-ticket-reply');

    this._form = GUI.$('new-ticket-reply');

    this.textarea = this.form.getField('content');
    this.textarea.focus();

    this.addEventListeners();
};

NewTicketReplyController.addEventListeners = function () {
    GUI.$('submit-ticket-reply').on('click', this.beforeSubmit.bindLegacy(this));

    if (GUI.$('attachments')) {
        onWithFeatureRestrictions(
            'featureAttachmentsEnabled',
            GUI.$('attachments'),
            'click',
            this.onClickAttachment.bindLegacy(this)
        );
    }
};

/**
 *
 * @returns {undefined}
 */
NewTicketReplyController.beforeSubmit = function (e) {
    e = GUI.Event.extend(e);
    e.stop();

    if (!this.form.getField('content').getValue()
        && (!this.attachments || !this.attachments.attachments.length)
    ) {
        return false;
    }

    jQuery('#submit-ticket-reply').attr("disabled", "disabled");

    this._form.submit();
};

NewTicketReplyController.onClickAttachment = function () {
    if (!this.attachments) {
        this.attachments = new Attachments();
        this.attachments.init({
            attachments       : [],
            attachmentsHolder : GUI.$('listNamesAttachments'),
            callback          : this.setAttachmentsCount.bindLegacy(this)
        });
    }

    this.attachments.render();
};

NewTicketReplyController.setAttachmentsCount = function (count) {
    var text = '';

    if (count > 0) {
        text = sprintf(i18n('%s Attachment', '%s Attachments', count), count);
    } else {
        text = i18n('Attachments');
    }

    GUI.$('attachments').innerHTML = text;
};
