(function () {

    var superproto = GUI.Utils.Observable.prototype;
    GUI.TreeNode = Class.create();
    Object.extend(GUI.TreeNode.prototype, superproto);

    GUI.TreeNode.prototype.dir = undefined;
    GUI.TreeNode.prototype.childs = undefined;
    GUI.TreeNode.prototype.parent = undefined;

    GUI.TreeNode.prototype.initialize = function (data, parent) {
        superproto.initialize.call(this);
        this.parent = parent;
        if (data.isDir) {
            this.dir = true;
            this._processChildItems(data.child);
            delete data.child;
            this.data = this.onDirParse(data);
        } else {
            this.dir = false;
            this.data = this.onLeafParse(data);
        }
    };

    GUI.TreeNode.prototype.onDirParse = function (data) {
        return data;
    };

    GUI.TreeNode.prototype.onLeafParse = function (data) {
        return data;
    };

    GUI.TreeNode.prototype.onSearch = function (child, key, value) {
        return child.getData()[key] === value;
    };

    GUI.TreeNode.prototype.get = function (index) {
        if (this.isLeaf()) {
            throw new Error("Can`t get leaf node. Only dir nodes");
        }

        return this.getChilds()[index];
    };


    GUI.TreeNode.prototype.getChilds = function () {
        return this.childs;
    };

    GUI.TreeNode.prototype.getNestedChilds = function (onlyLeaf, nestedLevel) {
        // By default false
        onlyLeaf = onlyLeaf ? true : false;
        // By default unlimited nested
        nestedLevel = nestedLevel === undefined ? 9999 : nestedLevel;

        var items = [];

        if (!nestedLevel) {
            return [];
        }

        this.each(function (child) {
            if (child.isDir()) {
                if (!onlyLeaf) {
                    items.push(child);
                }

                items = items.concat(child.getNestedChilds(onlyLeaf, nestedLevel - 1));

            } else {
                items.push(child);
            }
        });

        return items;
    };

    GUI.TreeNode.prototype.each = function (fn) {
        if (this.isLeaf()) {
            throw new Error("Can`t each leaf node. Only tree nodes");
        }

        var i;

        for (i = 0; i < this.childs.length; i++) {
            fn(this.childs[i]);
        }
    };

    GUI.TreeNode.prototype.search = function (key, value) {
        var result = [];

        this.each(function (child) {
            if (this.onSearch(child, key, value)) {
                result.push(child);
            }
        }.bindLegacy(this));

        return result;
    };

    GUI.TreeNode.prototype.getData = function () {
        return this.data;
    };

    GUI.TreeNode.prototype.getParent = function () {
        return this.parent;
    };

    GUI.TreeNode.prototype.isDir = function () {
        return this.dir;
    };

    GUI.TreeNode.prototype.isLeaf = function () {
        return !this.dir;
    };

    GUI.TreeNode.prototype.isRoot = function () {
        return !!this.parent;
    };

    GUI.TreeNode.prototype._processChildItems = function (items) {
        var i, item;
        this.childs = [];

        for (i = 0; i < items.length; i++) {
            item = items[i];

            this.childs.push(
                new this.constructor(item, this)
            );
        }
    };
}());