(function () { // variables isolation

    var ShareViaEmailFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        _showed        : false,
//        _dialog        : false,
        _disableReload : false,
        _popupWindow   : false,
        _popupHandler  : false,
        _formMask      : false,
        _shareViaEmailForm     : {
            recipient      : null,
            subject        : null,
            message        : null,
            submit         : null,
            holder         : null,
            form           : null
        },
        _actionUrl      : false,

        _setupFormElementListeners : function () {
            this._shareViaEmailForm.recipient    = GUI.$('share-via-email-dialog_recipient');
            this._shareViaEmailForm.subject      = GUI.$('share-via-email-dialog_subject');
            this._shareViaEmailForm.message      = GUI.$('share-via-email-dialog_message');
            this._shareViaEmailForm.holder       = GUI.$('share-via-email-dialog-form');
            this._shareViaEmailForm.form         = GUI.$('share-via-email-form');

            this._shareViaEmailForm.form.on('submit', this._onShareViaEmailSubmit, this);
        },

        _onShareViaEmailSubmit : function (event) {
            event.preventDefault();

            this._formMask.show();

            new GUI.Ajax.Request(
                this._actionUrl,
                {
                    method : 'POST',
                    data   : {
                        recipient : this._shareViaEmailForm.recipient.value,
                        subject   : this._shareViaEmailForm.subject.value,
                        message   : this._shareViaEmailForm.message.value

                    },
                    onSuccess : this._onResponse.bindLegacy(this)
                }
            );
        },

        _onResponse : function (answer) {
            this._formMask.hide();

            answer = answer.responseText.evalJSON();

            if (answer.done) {
                Application.fireEvent('shareViaEmail');

                this.close();
            } else {
                Application.errorInfo(answer.error);
            }
        },

        initialize : function () {
            this.addEvents({
                show : true,
                hide : true,
                shareViaEmail  : true
            });

            GUI.onDOMReady(function () {
                this._dialog = new Application.CommonDialogClass('share-via-email');

                if (!GUI.$('share-via-email-form')) {
                    return;
                }

                this._setupFormElementListeners();

                this._formMask = new GUI.Popup.Mask({className : 'mask', animate : false, stylePosition: 'absolute'});
                this._formMask.setElement(this._dialog.getContentHolder());
            }.bindLegacy(this));
        },

        show : function (disableReload) {
            if (!this._showed) {
                this._disableReload = disableReload === true; // as the first parameter may be an event
                                                              // check var for equivalense to true
                this._dialog.close();

                // set user
                GUI.$('share-via-email-user-data').innerHTML = '"'
                    + Application.authorization.name
                    + '" '
                    + '&lt;'
                    + Application.authorization.email
                    + '&gt;';

                this._dialog.onShow = function () {
                    GUI.show(this._shareViaEmailForm.holder);
                    this._showed = true;
                    this.fireEvent('show');
                }.bindLegacy(this);

                this._dialog.onClose = function () {
                    GUI.hide(this._shareViaEmailForm.holder);
                    this._showed = false;
                    this.fireEvent('hide');
                }.bindLegacy(this);

                this._dialog.show();

                GUI.$('share-via-email-dialog_recipient').focus();
            }
        },

        close : function () {
            if (this._showed) {
                this._dialog.close();
            }
        },

        applyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }
            node.on('click', this.show, this);
        },

        unapplyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }
            node.un('click', this.show, this);
        },

        setActionUrl : function (url) {
            this._actionUrl = url;
        }
    };

    Object.extend(ShareViaEmailFormClass.prototype, superproto);
    Object.extend(ShareViaEmailFormClass.prototype, prototype);

    Application.ShareViaEmailForm = new ShareViaEmailFormClass();
}());