/**
 *
 */
Attachments = new window.Class.create();

/**
 *
 */
Attachments.prototype.init = function (config) {
    var key,
        cfg = {
            attachmentsHolder   : null,
            label               : i18n('Attachments'),
            handlerDeleteAttachments    : null,
            attachments         : [],
            callback            : null
        };
    Object.extend(cfg, config);

    this.id = GUI.getUniqId('-attachments-');
    this.config = cfg;

    this.attachments = this.config.attachments;

    for (key = 0; key < this.attachments.length; key++) {
        // convert size
        this.attachments[key].sizeConv = GUI.convertSize(this.attachments[key].size);

        // convert mime to set icon
        this.attachments[key].mimeConv = this.attachments[key].mime.replace('/', '-');
    }

    this.render();
};

/**
 *
 */
Attachments.prototype.render = function () {
    if (!this.dlg || !this.dlg.rendered) {
        this.renderDialog();

        this.renderToolbar();

        this.renderJsonView();
    } else {
        this.dlg.show();
    }

    this.addInputElement();
    this.update();
};

/**
 *
 * @returns {undefined}
 */
Attachments.prototype.destroy = function () {
    this.dlg.destroy();
    this.dlg = false;
};

/**
 *
 */
Attachments.prototype.renderDialog = function () {
    var dlg;

    dlg = new GUI.Popup.Dialog({
        title       : sprintf(i18n('%s Attachment', '%s Attachments', 0), 0),
        content     : '<div id="itemAttachments">' +
            '<div id="attachemntsToolbar"></div>' +
            '<div id="attachmentsJsonView" class="attachments-grid"></div>' +
            '</div>',
        modal       : true,
        closeOnHide : false,
        toolbuttons : [{
            name    : 'closeBut',
            img     : 'close',
            onClick : function() {
                dlg.close();
            }
        }],
        footerButtons: [{
            name    : 'closeBut',
            obj     : new GUI.ToolBar.Button({
                name        : 'closeBut',
                caption     : i18n('Close'),
                autoDisable : false,
                onClick : function() {
                    dlg.close();
                }
            })
        }],
        dimensions	: {
            width	: 500,
            height  : 300
        }
    });
    dlg.create();
    dlg.show();

    this.dlg = dlg;
};

/**
 *
 */
Attachments.prototype.renderToolbar = function () {
    this.addButtonEl  = new GUI.Element({
        html        : this.attachmentsAttachFileBut,
        withoutSpan : true
    });

    var toolbar = new GUI.ToolBar({
        elements: [{
            name    : 'attach_files',
            obj     : this.addButtonEl
        }, {
            name : 'delete_all_attach',
            obj  : new GUI.ToolBar.Button({
                name        : 'delete_all',
                caption     : i18n('Delete All'),
                autoDisable : false,
                onClick     : this.confirmRemoveAllAttachments.bind(this)
            })
        }]
    });
    toolbar.render('attachemntsToolbar');
};

/**
 *
 */
Attachments.prototype.renderJsonView = function () {
    var JVholder = 'attachmentsJsonView',
        JV = new GUI.JsonView({
            holder      : JVholder,
            preTpl      : this.attachmentsPreTemplate,
            rowTpl      : this.attachmentsTemplate,
            postTpl     : this.attachmentsPostTemplate,
            noDataTpl   : this.attachmentsNoDataTemplate
        });
    JV.render();

    GUI.$(JVholder).on('click', this.onViewListClick.bind(this));

    this.jsonView = JV;
};

/**
 *
 */
Attachments.prototype.update = function () {
    if (this.attachments.length) {
        this.dlg.setTitle(sprintf(i18n('%s Attachment', '%s Attachments', this.attachments.length), this.attachments.length));
    } else {
        this.dlg.setTitle(i18n('Attachments'));
    }

    if (this.config.callback) {
        this.config.callback(this.attachments.length);
    }

    this.jsonView.update({rows: this.attachments});
};

/**
 *
 */
Attachments.prototype.addInputElement = function () {
    var inp, input,
        id = GUI.getUniqId('-attach-');

    inp = document.createElement('INPUT');
    inp.type = 'file';
    inp.id = 'file' + id;
    inp.name = 'file[]';

    input = GUI.Dom.findDescedent(this.addButtonEl.holder, 'input');
    if (input) {
        // insert new input on the place of old input
        input.parentNode.insertBefore(inp, input);
        GUI.hide(input);
    } else {
        // when dialog is created first input is added
        GUI.Dom.findDescedent(this.addButtonEl.holder, 'label').appendChild(inp);
    }

    inp = GUI.Dom.extend(inp);

    inp.on('change', this.selectAttach.bind(this, id));
};

/**
 *
 * @param {Number} id
 * @param {Event} e
 * @returns {undefined}
 */
Attachments.prototype.selectAttach = function (id, e) {
    var i, inp, files, isExistingAttach;

    e = GUI.Event.extend(e);
    inp = e.getTarget();
    files = inp.files;

    if (!files) {
        files = [{
            name: GUI.getNameFromFileSrc(inp.value)
        }];
    }

    for (i = 0; i < this.attachments.length; i++) {

        if (files && files[0].name === this.attachments[i].name) {
            isExistingAttach = true;
            confirmMessage(
                this.appendAttach.bind(this, id, files[0], inp),
                {
                    msg     : i18n('Attachment with same name already exists. Do you want to replace it with this file?'),
                    okButton: i18n('Replace')
                }
            );
        }
    }

    if (!isExistingAttach && files) {
        this.appendAttach(id, files[0], inp);
    }
};

/**
 *
 * @param {Number} id
 * @param {Obect} file
 * @param {HTMLElement} inp
 * @returns {undefined}
 */
Attachments.prototype.appendAttach = function (id, file, inp) {
    var oldDataSizes = 0;
    this.attachments.each(function (file) {
        if (file.size) {
            oldDataSizes += file.size;
        }
    });

    if (file.name.match(/"/)) {
        alert(
            i18n('The name must not contain double quotes. Please rename the file before attaching.')
        );
        return;
    }

    if ((oldDataSizes + file.size) > window.uploadMaxSize) {
        alert(
            sprintf(
                i18n('Combined file size cannot exceed %s'),
                window.uploadMaxSizeShort
            )
        );
        return;
    }

    var i;

    for (i = 0; i < this.attachments.length; i++) {

        if (file.name === this.attachments[i].name) {
            this.attachments.splice(i, 1);
        }
    }

    if (window.FileReader) {
        this.reader = new FileReader();
        this.reader.onload = this.readerOnLoad.bind(this, id, file);
        this.reader.readAsDataURL(file);
    } else {
        this.readerOnLoad(id, {name: GUI.getNameFromFileSrc(inp.value), size: inp.size, type: ''});
    }
    this.config.attachmentsHolder.appendChild(inp);

    this.addInputElement();
};

/**
 * Adds file to attachments
 * @param {Number} id
 * @param {Object} file
 * @returns {undefined}
 */
Attachments.prototype.readerOnLoad = function (id, file) {
    var attributes = {
        id      : id,
        name    : file.name,
        size    : file.size,
        sizeConv: GUI.convertSize(file.size),
        mime    : file.type,
        mimeConv: file.type.replace('/', '-'),
        path    : ''
    };

    // use default if no mime
    if ('' == attributes.mimeConv) {
        attributes.mimeConv = 'default';
    }

    attributes.icon = assets_url + 'images/mimes/' + attributes.mimeConv + '.png';

    this.attachments.push(attributes);

    this.update();

    this.checkMimeTypeIcon(attributes);
};

/**
 *
 * @param {Object} attributes
 * @returns {undefined}
 */
Attachments.prototype.checkMimeTypeIcon = function (attributes) {
    var tempImg = document.createElement('IMG');
    tempImg.src = assets_url + 'images/mimes/' + attributes.mimeConv + '.png';
    tempImg.onerror = function (error) {
        if (error) {
            attributes.icon = assets_url + 'images/mimes/default.png';
            this.update();
        }
    }.bind(this, attributes);
};

/**
 *
 * @param {Event} event
 * @returns {undefined}
 */
Attachments.prototype.onViewListClick = function (event) {
    var e = GUI.Event.extend(event),
        target = e.target;
    e.stop();

    if (target.nodeName === 'I') {
        target = target.parentNode;
    }

    if (target.nodeName === 'A' && target.id.search('remove') !== -1) {
        this.confirmRemoveAttachment(target, target.id.replace('remove', ''));
        return;
    }

    if (target.nodeName === 'A' && target.getAttribute('href') !== '') {
        window.open(e.target.href, '_blank');
        return;
    }
};

/**
 *
 * @param {HTMLElement} el
 * @param {Number} id
 * @returns {undefined}
 */
Attachments.prototype.confirmRemoveAttachment = function (el, id) {
    confirmMessage(
        this.removeAttachment.bind(this, el, id),
        {
            msg: i18n('Are you sure you want to delete this?')
        }
    );
};

/**
 *
 * @param {HTMLElement} el
 * @param {Number} id
 * @returns {undefined}
 */
Attachments.prototype.removeAttachment = function (el, id) {
    var cfg = this.config,
        obj,
        key,
        input,
        selectedAttach,
        queryParams;

    for (key = 0; key < this.attachments.length; key++) {
        obj  = this.attachments[key];

        if ((obj.id + '') === (id + '')) {
            selectedAttach = obj;
            break;
        }
    }

    if (selectedAttach) {
        if (cfg.handlerDeleteAttachments && id.indexOf('-attach-') === -1) {
            queryParams = cfg.handlerDeleteAttachments(selectedAttach);

            new Ajax.Request(queryParams.url, {
                data: queryParams.data,
                onSuccess: queryParams.onSuccess,
                onFailure: queryParams.onFailure
            });
        } else {
            input = GUI.$('file' + id);

            // remove input field
            if (input) {
                input.parentNode.removeChild(input);
            }
        }

        this.attachments.splice(key, 1);

        this.update();
    }
};

/**
 *
 * @param {HTMLElement} el
 * @returns {undefined}
 */
Attachments.prototype.confirmRemoveAllAttachments = function (el) {
    confirmMessage(
        this.removeAllAttachments.bind(this, el),
        {
            msg: i18n('Are you sure you want to delete this?')
        }
    );
};

/**
 *
 * @param {HTMLElement} el
 * @returns {undefined}
 */
Attachments.prototype.removeAllAttachments = function (el) {
    // copy array
    var key, attachmentsList = this.attachments.slice(0);

    for (key = 0; key < attachmentsList.length; key++) {
        this.removeAttachment(null, attachmentsList[key].id.toString());
    }
};

/**
 *
 * @type String
 */
Attachments.prototype.attachmentsPreTemplate =
    '<table class="b-grid">' +
    '<colgroup>' +
    '<col><col><col width="1">' +
    '</colgroup>' +
    '<thead class="b-grid__head">' +
    '<tr>' +
    '<td class="b-grid__cell">' +
    '<span class="b-grid__ttl not-sort">' + i18n('File') + '</span>' +
    '</td>' +
    '<td class="b-grid__cell">' +
    '<span class="b-grid__ttl not-sort">' + i18n('Size') + '</span>' +
    '</td>' +
    '<td class="b-grid__cell cell_actions">' +
    '</td>' +
    '</tr>' +
    '</thead>' +
    '<tbody class="b-grid__body">';

/**
 *
 * @type String
 */
Attachments.prototype.attachmentsTemplate =
    '<tr class="b-grid__row">' +
    '<td class="b-grid__cell">' +
    '<i class="b-icon" style="background-image: url({this.icon});" hint="{this.mime}">&nbsp;</i>' +
    '<a name="JUST_NO_LINK" data-href="{this.path}" style="text-decoration:none; color:inherit;">{this.name}</a>' +
    '</td>' +
    '<td class="b-grid__cell" nowrap="nowrap">{this.sizeConv}</td>' +
    '<td class="b-grid__cell cell_actions b-buttons-list">' +
    '<a href="" name="delete"  id="remove{this.id}" class="b-button b-button_simple">' +
    '<i class="b-button__icon fa fa-times" >&nbsp;</i>' +
    '</a>' +
    '</td>' +
    '</tr>';

/**
 *
 * @type String
 */
Attachments.prototype.attachmentsNoDataTemplate =
    '<tr class="b-grid__row">' +
    '<td class="b-grid__cell" colspan="3">' +
    i18n('No Data') +
    '</td>' +
    '</tr>';

/**
 *
 * @type String
 */
Attachments.prototype.attachmentsPostTemplate =
    '</tbody>' +
    '</table>';

/**
 *
 * @type String
 */
Attachments.prototype.attachmentsAttachFileBut =
    '<label class="b-button b-button_color_primary">' +
    '<i class="b-button__icon fa fa-paperclip">&nbsp;</i>' +
    '<span class="b-button__label">' + i18n('Attach File') + '</span>' +
    '</label>';