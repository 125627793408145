(function () { // variables isolation

    var MarkBestFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        _showed        : false,
        _dialog        : false,
        _disableReload : false,
        _popupWindow   : false,
        _popupHandler  : false,
        _formMask      : false,
        _markBestForm     : {
            holder         : null,
            form           : null
        },
        _actionUrl     : false,

        _setupFormElementListeners : function () {
            this._markBestForm.holder       = GUI.$('mark-best-dialog-form');
            this._markBestForm.form         = GUI.$('mark-best-form');

            this._markBestForm.form.on('submit', this._onMarkBestFormSubmit, this);
        },

        _onMarkBestFormSubmit : function (event) {
            this._formMask.show();

            event.preventDefault();

            window.location.href = this._actionUrl;
        },

        initialize : function () {
            this.addEvents({
                show : true,
                hide : true
            });

            GUI.onDOMReady(function () {
                this._dialog = new Application.CommonDialogClass('mark-best');

                if (!GUI.$('mark-best-form')) {
                    return;
                }

                this._setupFormElementListeners();

                this._formMask = new GUI.Popup.Mask({className : 'mask', animate : false, stylePosition: 'absolute'});
                this._formMask.setElement(this._dialog.getContentHolder());
            }.bindLegacy(this));
        },

        show : function (disableReload) {
            if (!this._showed) {
                this._disableReload = disableReload === true; // as the first parameter may be an event
                                                              // check var for equivalense to true
                this._dialog.close();

                this._dialog.onShow = function () {
                    GUI.show(this._markBestForm.holder);
                    this._showed = true;
                    this.fireEvent('show');
                }.bindLegacy(this);

                this._dialog.onClose = function () {
                    GUI.hide(this._markBestForm.holder);
                    this._showed = false;
                    this.fireEvent('hide');
                }.bindLegacy(this);

                this._dialog.show();
            }
        },

        close : function () {
            if (this._showed) {
                this._dialog.close();
            }
        },

        applyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }
            node.on('click', this.show, this);
        },

        unapplyShowOn : function (node) {
            if (typeof (node) === 'string') {
                node = GUI.$(node);
            }
            node.un('click', this.show, this);
        },

        setActionUrl : function (url) {
            this._actionUrl = url;
        },

        setCallerElement : function (element, message) {
            this._callerElement = element;
            this._callerMessage = message;
        }
    };

    Object.extend(MarkBestFormClass.prototype, superproto);
    Object.extend(MarkBestFormClass.prototype, prototype);

    Application.MarkBestForm = new MarkBestFormClass();
}());