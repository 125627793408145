(function () {
    var superproto = GUI.ActiveElement.prototype;

    /**
     * JavaScript Graphical User Interface
     * GUI.ToolBar.Button implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.ToolBar
     * @extends GUI.ActiveElement
     */
    GUI.ToolBar.Button = Class.create();
    Object.extend(GUI.ToolBar.Button.prototype, superproto);

    /**
     * Static array, contains cached templates
     * @type Array
     */
    GUI.ToolBar.Button._tplCache = new Array(8);

    /**
     *
     */
    GUI.ToolBar.Button.prototype.autoDisable = true;

    /**
     * Css class name of the button, default is 'b-button'
     * @type String
     */
    GUI.ToolBar.Button.prototype.className = 'b-button';

    /**
     * Css class 'pressed' element, default is 'selected'
     * @type String
     */
    GUI.ToolBar.Button.prototype.pressedClass = 'selected';

    /**
     * Css class of the icon, default is 'b-button__icon'
     */
    GUI.ToolBar.Button.prototype.iconCellClass = 'b-button__icon';

    /**
     * Css class of the text, default is 'b-button__label'
     */
    GUI.ToolBar.Button.prototype.textCellClass = 'b-button__label';

    /**
     * Default is 'b-button__icon dropdown_down'
     */
    GUI.ToolBar.Button.prototype.rCellClass = 'b-button__icon';

    /**
     * Returns this
     * @returns {Object} Button object
     */
    GUI.ToolBar.Button.prototype.getSelf = function () { return Button; };

    /**
     *
     */
    GUI.ToolBar.Button.prototype.onClick = function (e) {
        e = GUI.Event.extend(e);
        if (!this.disabled) {
            if (this.autoDisable && !this.canToggle) {
                this.disable();
            }
            if (this.canToggle) {
                this.toggle();
            }
            this.fireEvent('click', this, e);
        }
    };

}());