(function () {
    var superproto = GUI.Utils.Observable.prototype;

    GUI.Router = Class.create();
    Object.extend(GUI.Router.prototype, superproto);

    /**
     * Inits router, adds event on history change and click on the menu
     * @param {Object} cfg
     */
    GUI.Router.prototype.initialize = function (cfg) {
        Object.extend(this, cfg);

        GUI.History.addEventListener('historyChange', this.historyListener.bindLegacy(this));

        this.historyStates = [];

        this.mainMenu = this.appLayout.mainMenu;
    };

    /**
     * Returns hash
     * @returns {String} hash
     */
    GUI.Router.prototype.getHash = function () {
        return this.hash;
    };

    /**
     * Splits string and sets parametres - moduleName, action, args, queryString
     * @param {String} hash_
     */
    GUI.Router.prototype.setParametres = function (hash_) {
        var search_part,
            queryString = '',
            sortIndex = '',
            sortDirection = '',
            hash = hash_;

        if (hash && hash.split) {

            hash = hash.split('?');
            hash[1] = hash.slice(1).join('?');

            if (hash[1]) {
                search_part = hash[1].split(/[&]?sort=/);
                queryString = search_part[0].replace("q=", '');
            }

            if (search_part && search_part[1]) {
                sortIndex = search_part[1].split(':')[0];
                sortDirection = search_part[1].split(':')[1];
            }

            hash = hash[0];

            // other parametres
            hash = hash.split('/');

            this.moduleName = hash[0];
            this.action = hash[1];
            this.args = hash.slice(2);

            this.queryString = queryString;
            this.sortIndex = sortIndex;
            this.sortDirection = sortDirection;
        }
        this.hash = hash_;
    };

    /**
     * Sets parametres and goes to module
     * @param {String} hash
     */
    GUI.Router.prototype.historyListener = function (hash) {
        this.setParametres(hash);
        this.goToModule(this.moduleName, this.action, this.args, this.queryString, this.sortIndex, this.sortDirection);
    };

    /**
     * Sets history "by hand". If current history equal params hash,
     * goes to this module, if not,  add its to history
     * @param {String} hash
     * @param isNewWindow
     */
    GUI.Router.prototype.navigate = function (hash, isNewWindow) {
        if (isNewWindow) {
            window.open('/sadmin#' + hash, '_blank');
            return;
        }

        this.setParametres(hash);

        this.historyStates.push(hash);

        if (GUI.History.getCurrent() === hash) {
            // set selected item in menu
            this.mainMenu.setSelectedItem(this.mainMenu._indexByName[this.moduleName]);
            this.goToModule(this.moduleName, this.action, this.args, this.queryString, this.sortIndex, this.sortDirection);
        } else {
            GUI.History.addHistory(hash);
        }
    };

    /**
     * Goes to the module. Switches layout and calls callbak function
     * @param {String} moduleName
     * @param {String} action
     * @param {Object} args
     * @param {String} queryString
     */
    GUI.Router.prototype.goToModule = function (moduleName) {
        if (this.appLayout.getLayout(moduleName)) {
            this.appLayout.switchToLayout(moduleName);
        }

        // init module
        if (this.callback && typeof (this.callback) === 'function') {
            this.callback.apply(this.callback, arguments);
        }
    };
}());