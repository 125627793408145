(function () {
    var superproto = GUI.Forms.Validation.Validator.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Maxlength implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Validator
    */
    GUI.Forms.Validation.Maxlength = Class.create();
    Object.extend(GUI.Forms.Validation.Maxlength.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_MAXLENGTH'
     * @type String
     */
    GUI.Forms.Validation.Maxlength.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_MAXLENGTH);

    /**
     * Type of the element, 'GUI.Forms.Validation.Maxlength'
     * @type String
     */
    GUI.Forms.Validation.Maxlength.prototype.elementType = 'GUI.Forms.Validation.Maxlength';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Maxlength.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        if (GUI.isSet(this.params)) {
            this.maxLength = parseInt(this.params, 10);
        } else {
            throw new Error(this.elementType + ' error: required parameter not passed in config');
        }
    };

    /**
     * Validates value
     * @param {String|Number} value Value to validate
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Maxlength.prototype.validateFunc = function (value) {
        return (value.length <= this.maxLength);
    };

    /**
     * Returns text of the error message
     * @return {String} error message
     */
    GUI.Forms.Validation.Maxlength.prototype.getErrorMessage = function () {
        //return GUI.formatString(this.errorMessage, this.maxLength);
        return sprintf(this.errorMessage, this.maxLength);
    };
}());