/**
 * JavaScript Graphical User Interface
 * Delayed Task implementation
 *
 * @author Eugene Lyulka
 * @version 2.0
 * @namespace GUI.Utils
 *
 * @param {Function} n
 * @param {Object} scope
 * @param {Object} args
 */

GUI.Utils.DelayedTask = function (fn, scope, args) {
    var d, t, call,
        id = null;

    /**
     * Clears timer, calling a function with the arguments
     */
    call = function () {
        var now = new Date().getTime();
        if (now - t >= d) {
            clearInterval(id);
            id = null;
            fn.apply(scope, args || []);
        }
    };

    /**
     * Cancels any pending timeout and queues a new one
     * @param {Number} delay The milliseconds to delay
     * @param {Function} newFn (optional) Overrides function passed to constructor
     * @param {Object} newScope (optional) Overrides scope passed to constructor
     * @param {Array} newArgs (optional) Overrides args passed to constructor
     */
    this.delay = function (delay, newFn, newScope, newArgs) {
        if (id && delay !== d) {
            this.cancel();
        }
        d = delay;
        t = new Date().getTime();
        fn = newFn || fn;
        scope = newScope || scope;
        args = newArgs || args;
        if (!id) {
            id = setInterval(call, d);
        }
    };

    /**
     * Cancel the last queued timeout
     */
    this.cancel = function () {
        if (id) {
            clearInterval(id);
            id = null;
        }
    };
};