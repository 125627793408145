(function () {
    if (!GUI.Tree.Plugins) {
        GUI.Tree.Plugins = {};
    }

    var superproto = GUI.Inline.prototype;

    GUI.Tree.Plugins.InlineEdit = Class.create();
    Object.extend(GUI.Tree.Plugins.InlineEdit.prototype, superproto);


    /**
     * Shows the region
     * @param {HTMLElement} node
     */
    GUI.Tree.Plugins.InlineEdit.prototype.show = function (node) {
        if (this.visible) {
            this.ClickApply();
        }

        var tree, width, region,
            elem = node.textId,
            value = node.config.text;

        this.node = node;
        this.config.holder = elem;
        this.config.value = value;

        this.fRegion.takeDocument();
        this.fRegion.show();
        this.fRegion.dom.style.background = "white";
        GUI.setOpacity(this.fRegion.dom, 0.4);
        GUI.$(this.fRegion.dom).on('click', this.ClickApply, this);

        tree = node.getOwnerTree();
        width = tree.getClientWidth() - GUI.$(elem).offsetLeft + tree.getScrollLeft() + 9;

        this.sRegion.setDimensions({
            width: width
        });
        this.sRegion.alignTo(elem, 'l-l', GUI.isIE ? [-5, 1] : [-2, 0]);
        this.sRegion.show();

        region = GUI.$('inlineEditDiv');
        GUI.$('inlineEditDiv').setStyle({opacity: 0.1});
        this.anime.clearSubjects();
        this.anime.addSubject(new window.NumericalStyleSubject(region, 'opacity', 0.1, 1));
        this.anime.seekTo(1);

        // <-- html entities decode; LinuZz@27072009
        try {
            GUI.$('tmpDivEntityDecode').innerHTML = '<textarea id="tmpTxtEntityDecode">' + this.config.value + '</textarea>';
            GUI.$('inline_value').value = GUI.$('tmpTxtEntityDecode').value;
        } catch (e) {
            GUI.$('inline_value').value = this.config.value;
        }
        // -->

        //if (GUI.$('inline_value').value !== this.config.value) {
        GUI.$('inline_value').focus();
        GUI.$('inline_value').select();
        //}

        GUI.$('inline_apply').on('click', this.ClickApply, this);
        GUI.$('inline_delete').on('click', this.ClickListener);
        GUI.$('inline_value').on('keyup', this.KeyPressListener);
        this.visible = true;
    };
}());