(function () {
    var superproto = GUI.Forms.Combo.prototype;
    var ComboModernCollection = Class.create();

    GUI.Forms.ComboModern = Class.create();

    Object.extend(GUI.Forms.ComboModern.prototype, superproto);
    Object.extend(ComboModernCollection.prototype, GUI.Utils.Collection.prototype);

    ComboModernCollection.prototype.deepFind = function (fn, scope, itemsList) {
        var i, len, item,
            items = itemsList || this.items;

        for (i = 0, len = items.length; i < len; i++) {
            item = items[i];

            if (item.group === true) {
                return this.deepFind(fn, scope, item.items);
            } else if (fn.call(scope || window, item)) {
                return item;
            }
        }
        return null;
    };

    /**
     * Init component, add events 'listShow', 'listHide', 'beforeSelect', 'select',
     * init selection model, search task
     */
    GUI.Forms.ComboModern.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            listShow    : true,
            listHide    : true,
            beforeSelect: true,
            select      : true,
            emptyField  : true
        });

        this.itemSelector = 'li.' + this.itemClass;

        this.initSelectionModel();

        if (GUI.isArray(this.options)) {
            var options = this.options;
            this.options = new ComboModernCollection();

            this.suspendEvents();
            this.setOptions(options);
            this.resumeEvents();
        } else {
            this.options = new ComboModernCollection();
        }

        this._listItems = this.options.items; // by def

        this.searchTask = new GUI.Utils.DelayedTask(this.doSearch, this);
    };

    /**
     * Add option
     * @param {Object} option Option
     */
    GUI.Forms.ComboModern.prototype.add = function (option) {
         // Force passing by value, not by reference!
        if (option.group) {
            var i;
            for (i = 0; i < option.items.length; i++) {
                this.add(option.items[i]);
            }

            return;
        }

        option = Object.clone(option);

        if (!option.id) {
            option.id = GUI.getUniqId('');
        }
        option.domId = this.id + '-item-'  + option.id;
        this.options.add(option);
        this.onItemAdd(option);
        if (option.selected) {
            this.sm.selectItem(option, true);
        }
        // remove all filters, new options arrived
        this._listItems = this.options.items;
    };

    /**
     * Returns record
     * @param {Object} field Field
     * @param {String} value Value
     * @returns {Boolean} record is present
     */
    GUI.Forms.ComboModern.prototype.findRecord = function (field, value) {
        return this.options.deepFind(function (item) {
            return (item[field] + '') === (value + '');
        });
    };

    /**
     * Add option
     * @param {Object} option Option
     */
    GUI.Forms.ComboModern.prototype.add = function (option) {
         // Force passing by value, not by reference!
        if (option.group) {
            var i;
            for (i = 0; i < option.items.length; i++) {
                if (!option.items[i].id) {
                    option.items[i].id = GUI.getUniqId('');
                }

                option.items[i].domId = this.id + '-item-'  + option.items[i].id;
            }
        }

        option = Object.clone(option);

        if (!option.id) {
            option.id = GUI.getUniqId('');
        }
        option.domId = this.id + '-item-'  + option.id;
        this.options.add(option);
        this.onItemAdd(option);
        if (option.selected) {
            this.sm.selectItem(option, true);
        }
        // remove all filters, new options arrived
        this._listItems = this.options.items;
    };

    /**
     * Returns html of the list
     * @param {Array} items List of items
     * @returns {HTMLElement} html
     */
    GUI.Forms.ComboModern.prototype.getListHtml = function (items) {
        // Generate list html
        var html = new GUI.StringBuffer(),
            listMessageClass = this.listMessageClass,
            hasMessage, bufferingItems,
            simpleList = false,
            i;

        hasMessage = GUI.isSet(this.listMessage) && this.listMessage !== '';

        if (this.icons) {
            listMessageClass += ' b-popup-menu_with-icons ';
        }

        html.append('<div class="' + listMessageClass + '" style="display: ' +
            (hasMessage ? 'block' : '') + ';">' + (hasMessage ? this.listMessage : '') + '</div>');

        items = items || this._listItems;
        bufferingItems = [];

        for (i = 0; i < items.length; i++) {
            if (!items[i].group) {
                simpleList = true;
                bufferingItems.push(items[i]);
            } else {
                if (simpleList === true) {
                    this.getUlList(html, bufferingItems);
                    simpleList = false;
                    bufferingItems = [];
                }

                this.getUlList(html, items[i].items, items[i]);
            }
        }

        if (simpleList === true) {
            this.getUlList(html, bufferingItems);
        }

        return html.toString();
    };


    /**
     *
     * Handler click on trigger
     * @param {Event} e Event
     */
    GUI.Forms.ComboModern.prototype.onTriggerClick = function (e) { // Fires on mousedown only
        /*
         Google chrome && FF3 fire click event when 'return' key is down
        */
        e = GUI.Event.extend(e);

        // e.preventDefault(); // Commented, because when triggerEvent is 'mousedown' then focus is prevented
        if (!this.disabled) {
            this._listItems = this.options.items; // remove filters
            //this.dom.addClass(this.focusClassForDom);
            this.dom.addClass(this.selectedClass);

            if (this.listOpened) {
                this.hideList();
            } else {
                if (this.autocomplete && this._listItems && this._listItems.length) {
                    this.showList();
                    this.clearListMessage();
                    this.updateList();
                } else if (this.autocomplete && this.dataUrl) {
                    this._listItems = [];
                    this.sm.clearSelections();
                    this.options.clear();

                    this.updateList();

                    //this.setListMessage(GUI.formatString(i18n(GUI.i18n.GUI_ENTER_CHARATER), this.minSearchCharacters));
                    this.setListMessage(sprintf(
                        i18n('Please, enter at least %s character', 'Please, enter at least %s characters', this.minSearchCharacters),
                        this.minSearchCharacters
                    ));

                    this.showList();
                } else {
                    if ((this.getValue() === '') && (this.options.length === 0)) {
                        //this.setListMessage(GUI.formatString(i18n(GUI.i18n.GUI_ENTER_CHARATER)), this.minSearchCharacters));
                        this.clearListMessage();
                    } else {
                        this.clearListMessage();
                    }
                    this.showList();
                }


                this.deferFocusList();
            }
        }
    };

    /**
     * Scroll to node, show hint
     * @param {HTMLElelemnt} node Node
     */
    GUI.Forms.ComboModern.prototype.onFocusItem = function (node, set_value) {
        var item = this.getItemByDomId(node.id);
        if (item) {
            // Need to show hint if item content is clipped...
            if (node.scrollWidth > node.offsetWidth) {
                this.showHint(node, item[this.listField], item[this.descriptionField]);
            }
            if (set_value) {
                this.setValue(item.value);
            }
        }
    };

    GUI.Forms.ComboModern.prototype.getUlList = function (buffer, items, settings) {
        var i, len;

        if (!GUI.isSet(this.itemHeight) && this.list.dom) {
            this.itemHeight = this.list.dom.findDescedents('li.' + this.itemClass)[0].offsetHeight;
        }

        settings = Object.extend({
            listHeight:    this.listHeight,
            useIcons:      this.icons,
            scrolled:      true,
            text:          null,
            icon:          null,
            customDraw:    null
        }, settings);

        if (settings.customDraw) {
            settings.customDraw(buffer, items, settings);
        }

        if (settings.text) {
            buffer.append('<div class="b-popup-menu__list-header">' + (settings.icon ? '<i class="icon ' + settings.icon + '"></i>' : '') + '<span class="label">' + settings.text + '</span></div>');
        }

        buffer.append('<ul class="b-popup-menu__list b-popup-menu__list_fixed');
        if (settings.useIcons) {
            buffer.append(' with-icons');
        }
        if (settings.scrolled) {
            buffer.append(' b-popup-menu__list_scrolled');
        }
        if (settings.addedClass) {
            buffer.append(' ' + settings.addedClass);
        }
        buffer.append('"');

        if (settings.listHeight) {
            buffer.append(' style="max-height: ' + settings.listHeight * this.itemHeight + 'px;"');
        }

        buffer.append('>');

        for (i = 0, len = items.length; i < len; i++) {
            buffer.append(this.getListItemHtml(items[i]));
        }
        buffer.append('</ul>');
    };


    /**
     * List show, add events 'mouseover', 'mouseout', 'mousedown', 'click',
     * 'keydown', 'keyup' on dom,  and event 'mousedown' on document
     * @param {Array} items List of the items
     */
    GUI.Forms.ComboModern.prototype.showList = function (items) {
        this.initList(items);

        if (!this.listOpened) {

            if (this.listWidth === 'auto') {
                this.list.config.dimensions.width = this.dom.offsetWidth + 2;
            }
            this.list.show();

            var el_a, item, value, selItem,
                dom = this.list.dom;

            GUI.Dom.extend(dom);

            if (this.listWidth === 'content' && GUI.isIE) {
                dom.style.visibility = 'hidden';
                setTimeout(function () {
                    GUI.setFullWidth(dom, dom.offsetWidth);
                    dom.style.visibility = '';
                }, 1);
            }

            this.listFocusEl = null;

            dom.on('mouseover', this.onListMouseOver, this);
           // dom.on('mouseout', this.onListMouseOut, this);
            dom.on('mousedown', this.onListMouseDown, this);
            dom.on('click', this.onListClick, this);

            document.on('mousedown', this.onDocumentMouseDown, this);
            GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);

            this.listKeyboardEvents = new GUI.Utils.KeyboardEvents(dom, {
                anyKey: true
            });

            this.listKeyboardEvents.keyboardBrowserEvents(false);
            this.listKeyboardEvents.on(GUI.Utils.keys.anyKey, this.onListKeyDown, this);

            // Apply list height limit if exists
            if (GUI.isNumber(this.listHeight) && this.options.length > this.listHeight) {
                // Discover item height if not discovered before
                if (!GUI.isSet(this.itemHeight)) {
                    item = dom.findDescedents('li.' + this.itemClass)[0];
                    this.itemHeight = item.offsetHeight;
                }

                //GUI.setClientHeight(dom, this.itemHeight * this.listHeight);
            }
            this.listOpened = true;
            this.fireEvent('listShow', this);
        }

        this.updateList();
        this.list.alignTo(this.dom, 'tl-bl?');

        (function () {
            if (!this.listOpened) {
                return; // List has been closed while we were waiting
            }

            value = this.getValue();
            selItem = this.findRecord(this.valueField, value);
            if (selItem) {
                var itemDom = this.getItemDom(selItem);
                if (itemDom) {
                    this.selectedId = itemDom.id;
                    GUI.scrollIntoView(this.list.dom, itemDom, 'v');
                }
            }
        }).defer(20, this);
    };

    /**
     * If autocomplete, then put item text into input and select it
     * @param {Event} e Event
     */
    GUI.Forms.ComboModern.prototype._preSelectOption = function () {
        var item, dom;
        if (this.selectedId) {
            item = this.getItemByDomId(this.selectedId);
        }

        if (item) {
            dom = GUI.$(item.domId);
            GUI.scrollIntoView(dom.parentNode, dom);

            if (GUI.isSet(this._lastSearchedText) && this.substituteValue) { // not backspace or delete
                this.fieldEl.value = item[this.textField];
                this.selectText(this._lastSearchedText.length);
            }
        }
    };


    /**
     * Sets content, sets the focus to the last focused if it is
     */
    GUI.Forms.ComboModern.prototype.updateList = function (items) {
        if (this.list) {
            this.list.setContent(this.getListHtml(items));
        }
        var lastFocusedNode = GUI.$(this.selectedId);
        if (lastFocusedNode) {
            this.onFocusItem(lastFocusedNode, false);
        } else {
            this.selectedId = null;
        }
        if (this.listOpened) {
            // Realign if list is opened
            this.list.alignTo(this.dom, 'tl-bl?', [-1, 0]);
        }
    };
})();