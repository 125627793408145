(function () {
    var superproto = GUI.Forms.Validation.Validator.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Custom implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Validator
    */
    GUI.Forms.Validation.Custom = Class.create();
    Object.extend(GUI.Forms.Validation.Custom.prototype, superproto);

    /**
     * Text of error message, Default is i18n.GUI_VALIDATION_CUSTOM
     * @type String
     */
    GUI.Forms.Validation.Custom.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_CUSTOM);

    /**
     * Element type, 'GUI.Forms.Validation.Custom'
     * @type String
     */
    GUI.Forms.Validation.Custom.prototype.elementType = 'GUI.Forms.Validation.Custom';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Custom.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        if (GUI.isSet(this.params)) {
            this.pattern = new RegExp(this.params);
        }
    };

    /**
     * Validates value
     * @param {String|Number} value
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Custom.prototype.validateFunc = function (value) {
        return this.pattern.test(value);
    };

}());