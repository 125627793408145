(function () {
    var superproto = GUI.Forms.TextField.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.TextArea implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.TextArea = Class.create();
    Object.extend(GUI.Forms.TextArea.prototype, superproto);

    /**
     * Number of cols, default is 20
     * @type Number
     */
    GUI.Forms.TextArea.prototype.cols = 20;

    /**
     * Number of rows, default is 4
     * @type Number
     */
    GUI.Forms.TextArea.prototype.rows = 4;

    /**
     * Css class of the field, default is 'b-textarea'
     * @type String
     */
    GUI.Forms.TextArea.prototype.className = 'b-textarea';

    /**
     *
     */
    GUI.Forms.TextArea.prototype.autoresize = false;

    /**
     *
     */
    GUI.Forms.TextArea.prototype.noreturn = false;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.TextArea.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component
     */
    GUI.Forms.TextArea.prototype.initComponent = function () {
        superproto.initComponent.call(this);
    };

    /**
     * Attach event listeners
     */
    GUI.Forms.TextArea.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);

        if (this.autoresize) {
            this.on('blur',  this.postBlur, this);
            this.on('focus', this.preFocus, this);
        }

        if (this.noreturn) {
            this.dom.on('keydown',  this.keyDown, this);
        }
    };

    /**
     * Handelr assign
     * @param {HTMLElement} elem HTML elemnt of the field
     */
    GUI.Forms.TextArea.prototype.onAssign = function (elem) {
        superproto.onAssign.call(this, elem);
        var i, value,
            attrsMap = {
                cols : 'cols',
                rows : 'rows',
                type : 'type',
                autoresize  : 'autoresize',
                noreturn    : 'noreturn'
            };

        // get attributes and update properties
        for (i in attrsMap) {
            value = elem.getAttribute(i);
            if (GUI.isSet(value)) {
                this[attrsMap[i]] = value;
            }
        }
    };

    /**
     * Test if the component can be assigned to the passed element
     * @param {HTMLElement} to
     * @return {Boolean}
     */
    GUI.Forms.TextArea.prototype.onBeforeAssign = function (to) {
        // Can be assigned only to the textareas
        return to.nodeName === 'TEXTAREA';
    };

    /**
     * Return dom of the field
     * @returns {HTMLElement} html
     */
    GUI.Forms.TextArea.prototype.getFieldElement = function () {
        var dom = document.createElement('textarea');
        if (this.name !== null) {
            dom.name = this.name;
        }
        return dom;
    };

    /**
     * Sets cols and rows
     * @param {HTMLElemet} dom Dom of the field
     */
    GUI.Forms.TextArea.prototype.initField = function (dom) {
        superproto.initField.call(this, dom);

        if (GUI.isSet(this.cols)) {
            dom.cols = this.cols;
        }
        if (GUI.isSet(this.rows)) {
            dom.rows = this.rows;
        }
        if (GUI.isSet(this.placeholder)) {
            dom.placeholder = this.placeholder;
        }
        if (GUI.isSet(this.autoresize)) {
            dom.autoresize = this.autoresize;
        }
        if (GUI.isSet(this.noreturn)) {
            dom.noreturn = this.noreturn;
        }
    };

    /**
     *
     * @param {Event} e
     * @returns {Boolean}
     */
    GUI.Forms.TextArea.prototype.keyDown = function (e) {
        e = GUI.Event.extend(e);
        if (e.getCharCode() === e.KEY_RETURN) {
            e.stop();
            return false;
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.TextArea.prototype.postBlur = function () {
        clearTimeout(this.interval);
        this.dom.rows = parseInt(this.countLines(this.dom.value), 10).constrain(this.rows);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.TextArea.prototype.preFocus = function () {
        // for mormal height
        if (GUI.isIE) {
            this.dom.style.lineHeight = 'normal';
        }

        var countRows = parseInt(this.countLines(this.dom.value), 10),
            rows = parseInt(this.rows, 10);

        if (rows === 1 && countRows === 1) {
            this.dom.rows = 1;

        } else {
            if (countRows >= rows) {
                this.dom.rows = countRows + 1;

            } else {
                this.dom.rows = rows;
            }
        }

        clearTimeout(this.interval);

        this.interval = setTimeout(this.preFocus.bindLegacy(this), 100);
    };

    /**
     *
     * @param {String} value
     * @returns {Number} number of lines
     */
    GUI.Forms.TextArea.prototype.countLines = function (value) {
        var hard_lines, str, lines, i, rows,
            dom = this.dom;

        if (!value) {
            return 1;
        }

        hard_lines = 0;
		str = value.split(/\r?\n/);
		hard_lines = str.length;

        function getRowInLine(value_) {
            var l_ = 0,
                d_ = document.createElement('div');

            d_.style.position = 'absolute';
            d_.style.left = '-9999px';
            d_.style.top = '-9999px';
            dom.parentNode.appendChild(d_);
            d_.style.width = 'auto';
            d_.style.whiteSpace = 'nowrap';
            d_.style.wordWrap = 'normal';
            d_.style.fontSize = GUI.Dom.getStyle(dom, 'fontSize');

            d_.innerHTML = value_;

            if (d_.clientWidth > dom.clientWidth) {
                l_ = Math.round(d_.clientWidth / dom.clientWidth);
            }
            dom.parentNode.removeChild(d_);
            return l_;
        }

        lines = 0;

        for (i = 0; i < hard_lines; i++) {
			lines += getRowInLine(str[i]);
		}

        rows = lines + hard_lines;

        return rows;
    };

}());