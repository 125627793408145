(function () {
    var superproto = GUI.Layouts.Layout.prototype;

    /**
    * JavaScript Graphical User Interface
    * Application Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    * @extends GUI.Layouts.Layout
    */
    GUI.Layouts.Application = Class.create();
    Object.extend(GUI.Layouts.Application.prototype, superproto);

    /**
     * Type of the element, default is 'Layouts.Application'
     * @type String
     */
    GUI.Layouts.Application.prototype.elementType = 'Layouts.Application';

    /**
     * Css class of the container, default is ''
     * @type String
     */
    GUI.Layouts.Application.prototype.cls = '';

    /**
     * Css class of the item, default is ''
     * @type String
     */
    GUI.Layouts.Application.prototype.itemCls = '';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Layouts.Application.prototype.monitorResize = true;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Layouts.Application.prototype.animateOpacity = false;

    /**
     * Renders objects
     * @param {Object} ct Container
     * @param {HTMLElement} target Element to render object to
     */
    GUI.Layouts.Application.prototype.render = function (ct, target) {
        // Setup target element
        target.style.position = 'relative';
        target.addClass(this.cls);

        // Render layout
        var i, item, pos,
            items = ct.items.items,
            len = items.length,
            regions = {};

        for (i = 0; i < len; i++) {
            item = items[i];
            pos = item.pos;

            if (!item.rendered) {
                item.render(target, i);
                item.dom.addClass(this.itemCls);
            }

            regions[pos] = new GUI.Layouts.Application.Region(this, item.initialConfig, pos, item.split);
            regions[pos].render(target, item);
        }
        this.regions = regions;
        if (!regions.center) {
            throw new Error('ApplicationLayout.render(): Main region is not defined!');
        }
        this.rendered = true;
    };

    /**
     * Destroys objects
     */
    GUI.Layouts.Application.prototype.destroy = function () {
        var i, region,
            names = ['left', 'right', 'top', 'bottom'];
        for (i = 0; i < names.length; i++) {
            region = this.regions[names[i]];
            if (region) {
                if (region.destroy) {
                    region.destroy();
                }
            }
        }
        superproto.destroy.call(this);
    };

    /**
     * Sets position and dimensions of the regions
     * @param {Object} ct Container
     * @param {HTMLElement} target Element to render object to
     */
    GUI.Layouts.Application.prototype.onLayout = function (ct, target) {
        if (!this.rendered) {
            this.render(ct, target);
        }

        // Layout items
        var s, m, r, ss, centerBox,
            splitterWidth, splitterHeight, totalWidth, totalHeight,
            size = GUI.getClientSize(target),
            w = size.width, h = size.height,
            cWidth = w, cHeight = h, cX = 0, cY = 0,
            regions = this.regions;

        if (w < 20 || h < 20) { // display: none ?
            return; // Nothing to do
        }

        if ((r = regions.top) && r.isVisible()) {
            s = r.getSize();
            m = r.getMargins();

            // Width of the top region is a width of the container
            // minus left and right margins of the region
            s.width = w - (m.left + m.right);

            // Shift top and left position by margins
            s.x = m.left; s.y = m.top;

            // Adjust position and dimensions of the center region
            cY = s.y + s.height + m.bottom;
            cHeight -= cY;

            if (r.split && !r.collapsed) {
                // Splitter exists
                ss = r.splitter.dom.style;
                splitterHeight = 5;

                cY      += splitterHeight;
                cHeight -= splitterHeight;

                // Set splitter dimensions
                ss.left   = s.x + 'px';
                ss.top    = s.y + s.height + 'px';
                ss.width  = s.width.constrain(0) + 'px';
            }

            // Apply position and dimensions to the region
            r.applyLayout(s);
        }

        if ((r = regions.bottom) && r.isVisible()) {
            s = r.getSize();
            m = r.getMargins();

            // Width of the bottom region is a width of the container
            // minus left and right margins of the region
            s.width = w - (m.left + m.right);

            s.x = m.left;
            totalHeight = s.height + m.top + m.bottom;
            s.y = h - totalHeight + m.top;

            if (r.split && !r.collapsed) {
                // Splitter exists
                ss = r.splitter.dom.style;
                splitterHeight = 5;

                cHeight -= splitterHeight;

                // Set splitter dimensions
                ss.left   = s.x + 'px';
                ss.top    = s.y - splitterHeight + 'px';
                ss.width  = s.width.constrain(0) + 'px';
            }

            // Adjust height of the center region
            cHeight -= totalHeight;
            r.applyLayout(s);
        }

        if ((r = regions.left) && r.isVisible()) {
            s = r.getSize();
            m = r.getMargins();

            s.height = cHeight - (m.top + m.bottom);
            s.x = m.left;
            s.y = cY + m.top;
            totalWidth = (s.width + m.left + m.right);
            if (r.split && !r.collapsed) {
                // Splitter exists
                ss = r.splitter.dom.style;
                splitterWidth = 5;

                totalWidth += splitterWidth;

                // Set splitter dimensions
                ss.left        = (s.x + s.width) + 'px';
                ss.top        = s.y + 'px';
                ss.height    = s.height.constrain(0) + 'px';
            }
            cX += totalWidth;
            cWidth -= totalWidth;

            r.applyLayout(s);
        }

        if ((r = regions.right) && r.isVisible()) {
            s = r.getSize();
            m = r.getMargins();

            s.height = cHeight - (m.top + m.bottom);
            totalWidth = (s.width + m.left + m.right);
            s.x = w - totalWidth + m.left;
            s.y = cY + m.top;

            if (r.split && !r.collapsed) {
                // Splitter exists
                ss = r.splitter.dom.style;
                splitterWidth = 5;

                totalWidth += splitterWidth;

                // Set splitter dimensions
                ss.left        = (s.x - splitterWidth) + 'px';
                ss.top        = s.y + 'px';
                ss.height    = s.height.constrain(0) + 'px';
            }
            cWidth -= totalWidth;
            r.applyLayout(s);
        }

        r = regions.center;
        m = r.getMargins();

        centerBox = {
            x   : cX + m.left,
            y   : cY + m.top,
            width  : cWidth - (m.left + m.right),
            height : cHeight - (m.top + m.bottom)
        };
        r.applyLayout(centerBox);

        ss = null;
    };

    /**
     * empty function
     */
    GUI.Layouts.Application.prototype.isValidParent  = function (c, target) {
        return true;
    };

}());

(function () {

    /**
    * JavaScript Graphical User Interface
    * Implementation of the Region of Application Layout
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts.Application
    */
    GUI.Layouts.Application.Region = function (layout, config, pos, split) {
        if (config) {
            Object.extend(this, config);
        }
        this.layout = layout;
        this.position = pos;
        this.split = split;
    };

    /**
     * Template for collapsed state
     * @type String
     */
    GUI.Layouts.Application.Region.prototype.collapsedTemplate =
        '<div class="jsgui-layout-application-item-collapsed"><a href="#" class="jsgui-toolbutton jsgui-toolbutton-expand-{0}"></a></div>';

    /**
     * Default values of margins
     * @type Object
     */
    GUI.Layouts.Application.Region.prototype.defaultMargins = {left: 0, top: 0, right: 0, bottom: 0};

    /**
     * If true sliding is animate
     * @type Boolean
     */
    GUI.Layouts.Application.Region.prototype.animateSliding = window.GUI_FX_ANIMATION === true;

    /**
     * If true toogle is animate
     * @type Boolean
     */
    GUI.Layouts.Application.Region.prototype.animateToggle = window.GUI_FX_ANIMATION === true;

    /**
     * Renders objects
     * @param {HTMLElement} containerEl Element to render object to
     * @param {Object} containerItem Item
     */
    GUI.Layouts.Application.Region.prototype.render = function (containerEl, containerItem) {
        this.targetEl = containerEl;
        this.item     = containerItem;
        this.dom      = containerItem.getDom();

        if (GUI.isString(this.margins)) {
            this.margins = this.layout.parseMargins(this.margins);
        }
        this.margins = Object.extendIf(this.margins || {}, this.defaultMargins);

        if (this.position !== 'center') {
            containerItem.on({
                scope           : this,
                beforeCollapse  : this.onBeforeCollapse,
                collapse        : this.onCollapse,
                beforeExpand    : this.onBeforeExpand,
                expand          : this.onExpand,
                hide            : this.onHide,
                show            : this.onShow
            });

            if (this.collapsable) {
                containerItem.setCollapseHides(true);
            }

            var btn = containerItem.toolButtons && containerItem.toolButtons.toggle;
            if (btn) {
                containerItem.addToolButtonClass('toggle', 'jsgui-toolbutton-collapse-' + this.position);
            }

            if (this.split) {
                this.splitter = new GUI.Splitter({
                    linkedEl: this.item,
                    position: this.position,
                    vertical: this.position === 'top' || this.position === 'bottom'
                });
                this.splitter.render(containerEl);
            }
        }
    };

    /**
     * Destroys objects
     */
    GUI.Layouts.Application.Region.prototype.destroy = function () {
        if (this.position !== 'center' && this.item.un) {
            this.item.un({
                scope           : this,
                beforeCollapse  : this.onBeforeCollapse,
                collapse        : this.onCollapse,
                beforeExpand    : this.onBeforeExpand,
                expand          : this.onExpand,
                hide            : this.onHide,
                show            : this.onShow
            });
        }

        this.item = this.targetEl = this.dom = null;

        if (this.toggleFx) {
            this.toggleFx.destroy();
            this.toggleFx = null;
        }

        if (this.slideFx) {
            this.slideFx.destroy();
            this.slideFx = null;
        }

        if (this.collapsedEl) {
            GUI.destroyNode(this.collapsedEl);
            this.collapsedEl = null;
        }

        if (this.splitter) {
            this.splitter.destroy();
            this.splitter = null;
        }
    };

    /**
     * Sets size, position
     * @param {Object} box
     */
    GUI.Layouts.Application.Region.prototype.applyLayout = function (box) {
        this.lastBox = box; // save box
        if (this.collapsed) {
            this.applyLayoutCollapsed(box);
        } else {
            this.item.setSize(box.width, box.height); // Setting size before position helps to avoid flickering bug in FF
            this.item.setPosition(box.x, box.y);
        }
    };

    /**
     * Sets full width and height of the item
     * @param {Object} box
     */
    GUI.Layouts.Application.Region.prototype.applyLayoutCollapsed = function (box) {
        var el = this.getCollapsedEl(),
            s = el.style;

        s.left = box.x + 'px';
        s.top = box.y + 'px';

        GUI.setFullWidth(el, box.width);
        GUI.setFullHeight(el, box.height);

    };

    /**
     * True if this region is currently visible, else false.
     * @returns {Boolean}
     */
    GUI.Layouts.Application.Region.prototype.isVisible = function () {
        return !this.item.hidden;
    };

    /**
     * Returns the current margins for this region.  If the region is collapsed, the cmargins (collapsed
     * margins) value will be returned, otherwise the margins value will be returned.
     * @returns {Object} An object containing the element's margins: {left: (left margin), top: (top margin),
     * right: (right margin), bottom: (bottom margin)}
     */
    GUI.Layouts.Application.Region.prototype.getMargins = function () {
        return this.collapsed && this.cmargins ? this.cmargins : this.margins;
    };

    /**
     * Returns the current size of this region.  If the region is collapsed, the size of the collapsedEl will
     * be returned, otherwise the size of the region's panel will be returned.
     * @returns {Object} An object containing the element's size: {width: (element width), height: (element height)}
     */
    GUI.Layouts.Application.Region.prototype.getSize = function () {
        if (this.collapsed) {
            var el = this.getCollapsedEl();
            return GUI.getDimensions(el);
        }
        return this.item.getSize();
    };

    /**
     * Prepare animation objects
     */
    GUI.Layouts.Application.Region.prototype.prepareToggleAnimator = function () {
        if (!this.toggleFx) {
            this.toggleFx = new Animator({
                duration    : 500,
                scope       : this,
                onComplete  : this.onToggleFxComplete
            });
        }

        if (!this.toggleFx.isRunning()) {
            this._oldScrollable = this.item.scrollable;
            if (this._oldScrollable) {
                this.item.setScrollable(false); // Prevent FF flicker
            }

            this.toggleFx.clearSubjects();

            var pos = this.item.getPosition(true),
                size = this.item.getSize();

            this._restorePos = pos;

            switch (this.position) {
            case 'left':
                this.toggleFx.addSubject(new window.NumericalStyleSubject(this.dom, 'left', pos.left - size.width, pos.left));
                break;

            case 'right':
                this.toggleFx.addSubject(new window.NumericalStyleSubject(this.dom, 'left', pos.left + size.width, pos.left));
                break;

            case 'top':
                this.toggleFx.addSubject(new window.NumericalStyleSubject(this.dom, 'top', pos.top - size.height, pos.top));
                break;

            case 'bottom':
                this.toggleFx.addSubject(new window.NumericalStyleSubject(this.dom, 'top', pos.top + size.height, pos.top));
                break;
            }

            this.toggleFx.state = this.collapsed ? 1 : 0;
            this.toggleFx.propagate();
        }
    };

    /**
     * Expand/collapse item
     * @patam {Object} fx Animator
     */
    GUI.Layouts.Application.Region.prototype.onToggleFxComplete = function (fx) {
        if (fx.state === 0) {
            // End of slide in
            this.afterCollapse();
        } else if (fx.state === 1) {
            // End of slide out
            this.afterExpand();
        }

        if (this._oldScrollable) {
            this.item.setScrollable(this._oldScrollable);
        }
    };

    /**
     * Hides splitter, sets visibility and zIndex styles
     * @param {Object} p
     * @param {Boolean} animate
     */
    GUI.Layouts.Application.Region.prototype.onBeforeCollapse  = function (p, animate) {
        if (this.splitter) {
            this.splitter.hide();
        }

        this.getCollapsedEl().style.visibility = '';

        this.collapsed = true;

        this.getCollapsedEl().style.visibility = 'visible'; // Show collapsed element
        this.getCollapsedEl().style.zIndex = 102;
        this.dom.style.zIndex = 100; // Need to move panel to front before relayoutting or flicker occurs

        this.layout.layout(); // Panel has been collapsed, recalc layout

        this.onCollapse(this.item);

        this.item.collapsed = true;
        return false; // Prevent panel collapse
    };

    /**
     * If animation is true run animation or call 'afterCollapse'
     * @param {Object} item
     * @param {Boolean} animate
     */
    GUI.Layouts.Application.Region.prototype.onCollapse  = function (item, animate) {
        animate = animate === false ? false : this.animateToggle;
        if (animate) {
            this.dom.addClass('jsgui-fx-noscroll');
            this.prepareToggleAnimator();
            this.toggleFx.seekTo(0); // Run animation
            this.toggleFx.propagate();
        } else {
            this.afterCollapse();
        }

    };

    /**
     * Hides panel, returns panel to its normal position after end of animation
     */
    GUI.Layouts.Application.Region.prototype.afterCollapse = function () {
        this.dom.style.visibility = 'hidden'; // Hide panel
        this.dom.style.zIndex = '';
        this.getCollapsedEl().style.zIndex = '';
        this.dom.removeClass('jsgui-fx-noscroll');

        if (this._restorePos !== null) { // Return panel to its normal position after end of animation
            var s = this.dom.style;
            s.left = this._restorePos.left + 'px';
            s.top = this._restorePos.top + 'px';
        }
    };

    /**
     * Do not allow collapse when panel is slid out
     * @param {Object} item
     * @param {Boolean} animate
     */
    GUI.Layouts.Application.Region.prototype.onBeforeExpand = function (item, animate) {
        if (this.slid) {
            return false;
        }
    };

    /**
     * Shows panel
     * @param {Object} item
     * @param {Boolean} animate
     */
    GUI.Layouts.Application.Region.prototype.onExpand = function (item, animate) {
        var c = this.getCollapsedEl();

        if (this.position === 'left' || this.position === 'right') {
            this.item.setSize(undefined, GUI.getFullHeight(c));
            this.item.setPosition(this.item.x, c.offsetTop);
        } else {
            this.item.setSize(GUI.getFullWidth(c), undefined);
            this.item.setPosition(c.offsetLeft);
        }

        c.style.visibility = 'hidden'; // hide collapsed element
        this.dom.style.zIndex = 100; // move panel to front

        animate = animate === false ? false : this.animateToggle;
        this.collapsed = false;

        if (animate) {
            this.dom.addClass('jsgui-fx-noscroll');
            this.prepareToggleAnimator();
            this.toggleFx.seekTo(1);
            this.toggleFx.propagate();
        } else {
            this.afterExpand();
        }

        this.dom.style.visibility = ''; // Show panel

    };

    /**
     * Shows splitter, clears z-index after relayout to avoid flicker
     */
    GUI.Layouts.Application.Region.prototype.afterExpand = function () {
        if (this.splitter) {
            this.splitter.show();
        }

        this.layout.layout();
        this.dom.style.zIndex = '';
        this.dom.removeClass('jsgui-fx-noscroll');
    };

    /**
     * Returns collapsed element
     * @returns {Object} item
     */
    GUI.Layouts.Application.Region.prototype.getCollapsedEl = function () {
        if (!this.collapsedEl) {
            // Deferred construction
            var html = this.collapsedTemplate.format(this.position),
                el = GUI.Dom.append(this.targetEl, html);

            this.collapsedEl = GUI.Dom.extend(el);
            el.on('click', this.onCollapsedClick, this);
        }
        return this.collapsedEl;
    };

    /**
     * Handler click on collapse button
     * @param {Event} e Event
     */
    GUI.Layouts.Application.Region.prototype.onCollapsedClick = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
        var target = e.target;
        if (target.nodeName === 'A') {
            if (this.slid) {
                this.afterSlideIn();
            }
            this.item.expand();
        } else {
            this.toggleSlid();
        }
    };

    /**
     * Runs slid function, in or out
     */
    GUI.Layouts.Application.Region.prototype.toggleSlid = function () {
        return this[this.slid ? 'slideIn' : 'slideOut']();
    };

    /**
     * Prepares animation objects
     */
    GUI.Layouts.Application.Region.prototype.prepareSlideAnimator = function () {
        if (!this.slideFx) {
            this.slideFx = new Animator({
                duration   : 500,
                scope      : this,
                onComplete : this.onSlideFxComplete
            });
        }

        if (!this.slideFx.isRunning()) {
            this.slideFx.clearSubjects();

            var property, start, left, end;

            switch (this.position) {
            case 'left':
                property = 'left';
                end      = this.collapsedEl.offsetLeft + GUI.getFullWidth(this.collapsedEl); // visible
                start    = end - parseInt(this.dom.style.width, 10); // hidden
                break;

            case 'right':
                property = 'left';
                start    = this.collapsedEl.offsetLeft; // hidden
                end      =  start - parseInt(this.dom.style.width, 10); // visible
                break;

            case 'top':
                property = 'top';
                end      = this.collapsedEl.offsetTop + GUI.getFullHeight(this.collapsedEl); // visible
                start    = end - parseInt(this.dom.style.height, 10); // hidden
                break;

            case 'bottom':
                property = 'top';
                start    = this.collapsedEl.offsetTop; // hidden
                end      = start - parseInt(this.dom.style.height, 10); // visible
                break;
            }

            if (property) {
                this.slideFx.addSubject(new window.NumericalStyleSubject(this.dom, property, start, end));
            }

            if (this.animateOpacity && !GUI.isIE) {
                this.slideFx.addSubject(new window.NumericalStyleSubject(this.dom, 'opacity', 0, 1));
            }

            this.slideFx.state = this.slid ? 1 : 0;
            this.slideFx.propagate();

            if (this.position === 'left' || this.position === 'right') {
                this.item.setPosition(undefined, this.collapsedEl.offsetTop);
            } else {
                this.item.setPosition(this.collapsedEl.offsetLeft, undefined);
            }
        }
    };

    /**
     * Call afterSlidIn or AfterSlideOut methods, sets opacity
     * @param {Object} fx Animator
     */
    GUI.Layouts.Application.Region.prototype.onSlideFxComplete = function (fx) {
        if (fx.state === 0) {
            // End of slide in
            this.afterSlideIn();
        } else if (fx.state === 1) {
            // End of slide out
            this.afterSlideOut();
        }

        if (!GUI.isIE) {
            GUI.setOpacity(this.dom, '');
        }
    };

    /**
     * Slides out region when it is collapsed
     */
    GUI.Layouts.Application.Region.prototype.slideOut = function () {
        var btn = this.item.getToolButtonEl('toggle');
        if (btn) {
            GUI.hide(btn);
        }

        this.collapsedEl.style.zIndex = 102;
        this.dom.style.zIndex = 100; // Move slid panel to front

        if (this.position === 'left' || this.position === 'right') {
            this.item.setHeight(this.collapsedEl.offsetHeight);
        } else {
            this.item.setWidth(this.collapsedEl.offsetWidth);
        }

        this._restorePos = this.item.getPosition(true);
        if (this.animateSliding) {
            this.dom.addClass('jsgui-fx-noscroll');
            this.prepareSlideAnimator();
            this.slideFx.seekTo(1);
            this.slideFx.propagate();

        } else {
            // Align item with collapsed element

            switch (this.position) {
            case 'left': this.item.setPosition(this.collapsedEl.offsetLeft + GUI.getFullWidth(this.collapsedEl), undefined);
                break;
            case 'right': this.item.setPosition(this.collapsedEl.offsetLeft - parseInt(this.dom.style.width, 10), undefined);
                break;
            case 'bottom': this.item.setPosition(undefined, this.collapsedEl.offsetTop - parseInt(this.dom.style.height, 10));
                break;
            case 'top':  this.item.setPosition(undefined, this.collapsedEl.offsetTop + GUI.getFullHeight(this.collapsedEl)); // !!! check!
                break;
            }
        }
        this.dom.style.visibility = '';
        this.slid = true;

        document.on('click', this.onDocumentMouseDown, this);
    };

    /**
     * Removes css class 'jsgui-fx-noscroll'
     */
    GUI.Layouts.Application.Region.prototype.afterSlideOut = function () {
        this.dom.removeClass('jsgui-fx-noscroll');
    };

    /**
     * Slide in region
     * @param {Event} e Event
     */
    GUI.Layouts.Application.Region.prototype.onDocumentMouseDown = function (e) {
        e = GUI.Event.extend(e);
        if (!e.within(this.dom) && !e.within(this.getCollapsedEl())) {
            this.slideIn();
        }
    };

    /**
     * Slides in region when it is collapsed
     */
    GUI.Layouts.Application.Region.prototype.slideIn = function () {
        this.collapsedEl.style.zIndex = 102;
        if (this.animateSliding) {
            this.dom.addClass('jsgui-fx-noscroll');
            this.prepareSlideAnimator();
            this.slideFx.seekTo(0);
            this.slideFx.propagate();
        } else {
            this.afterSlideIn();
        }
    };

    /**
     * Hides region, sets position
     */
    GUI.Layouts.Application.Region.prototype.afterSlideIn = function () {
        this.collapsedEl.style.zIndex = '';
        this.dom.style.zIndex = '';
        this.dom.style.visibility = 'hidden';
        this.dom.removeClass('jsgui-fx-noscroll');

        if (this._restorePos) {
            this.item.setPosition(this._restorePos.left, this._restorePos.top);
        }

        var btn = this.item.getToolButtonEl('toggle');
        if (btn) {
            GUI.show(btn);
        }

        this.slid = false;
        document.un('click', this.onDocumentMouseDown, this);
    };

    /**
     * Hides region and splitter
     */
    GUI.Layouts.Application.Region.prototype.onHide = function () {
        if (this.collapsed) {
            this.getCollapsedEl().hide();
        } else if (this.splitter) {
            this.splitter.hide();
        }
    };

    /**
     * Shows region and splitter
     */
    GUI.Layouts.Application.Region.prototype.onShow = function () {
        if (this.collapsed) {
            this.getCollapsedEl().show();
        } else if (this.splitter) {
            this.splitter.show();
        }
    };

}());