(function () {
    /**
     * JavaScript Graphical User Interface
     * DragDropMgr implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     */
    GUI.Utils.DDM = Class.create();

    /**
     * Default is 3
     * @type Number
     */
    GUI.Utils.DDM.prototype.dragPixelThresh = 3;

    /**
     * Default is 1000
     * @type Number
     */
    GUI.Utils.DDM.prototype.dragTimeThresh = 1000;

    /**
     * Timer object
     * @type Object
     */
    GUI.Utils.DDM.prototype.dragTimeout = null;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Utils.DDM.prototype.stopPropagation = false;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Utils.DDM.prototype.preventDefault = true;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Utils.DDM.prototype.useCache = true;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Utils.DDM.prototype.initialize = function (config) {
        this.handleIds            = {};
        this.registeredDDs        = {};
        this.dragOvers            = {};
        this.locationCache        = {};
        this.dragObj              = null;
        document.on('dom:loaded', this.onLoad, this);
    };

    /**
     * Register drag'n'drop objects
     * @param {Object} ddObj Drag'n'drop object
     * @param {Object} group Group
     */
    GUI.Utils.DDM.prototype.registerDD = function (ddObj, group) {
        if (!this.registeredDDs[group]) {
            this.registeredDDs[group] = {};
        }
        this.registeredDDs[group][ddObj.config.id] = ddObj;
    };

    /**
     * Unregister drag'n'drop objects
     * @param {Object} ddObj Drag'n'drop object
     * @param {Object} group Group
     */
    GUI.Utils.DDM.prototype.unregisterDD = function (ddObj, group) {
        // Stop drag operation
        if (this.dragObj === ddObj) {
            this.stopDrag();
        }
        delete this.registeredDDs[group][ddObj.config.id];
    };

    /**
     * Returns drag'n'dropn object by the id
     * @param {Number|String} id Id of the object
     * @returns {Object} object
     */
    GUI.Utils.DDM.prototype.getDDById = function (id) {
        var i;
        for (i in this.registeredDDs) {
            if (this.registeredDDs[i][id]) {
                return this.registeredDDs[i][id];
            }
        }
    };

    /**
     * Registers handle
     * @param {Number|String} ddId Id of the object
     * @param {Number|String} handleId Id of the handle
     */
    GUI.Utils.DDM.prototype.registerHandle = function (ddId, handleId) {
        if (!this.handleIds[ddId]) {
            this.handleIds[ddId] = {};
        }
        this.handleIds[ddId][handleId] = handleId;
    };

    /**
     * Unregister handle
     * @param {Number|String} ddId Id of the object
     * @param {Number|String} handleId Id of the handle
     */
    GUI.Utils.DDM.prototype.unregisterHandle = function (ddId, handleId) {
        delete this.handleIds[ddId][handleId];
    };

    /**
     * Returns handle if it is exists
     * @param {Number|String} ddId Id of the object
     * @param {Number|String} handleId Id of the handle
     * @returns {Object} handle
     */
    GUI.Utils.DDM.prototype.isHandle = function (ddId, handleId) {
        return (this.handleIds[ddId] && this.handleIds[ddId][handleId]);
    };

    /**
     * Recursively searches the immediate parent and all child nodes for
     * the handle element in order to determine wheter or not it was
     * clicked.
     * @param {HTMLElement} node The html element to inspect
     * @param {Number} id
     */
    GUI.Utils.DDM.prototype.handleWasClicked = function (node, id) {
        if (this.isHandle(id, node.id)) {
            return true;
        } else {
            // check to see if this is a text node child of the one we want
            var p = node.parentNode;
            while (p) {
                if (this.isHandle(id, p.id)) {
                    return true;
                } else {
                    p = p.parentNode;
                }
            }
        }
        return false;
    };

    /**
     * Handles mouse down on dd object
     * @param {Object} e Event
     * @param {Object} ddObj Drag'n'drop object
     */
    GUI.Utils.DDM.prototype.handleMouseDown = function (e, ddObj) {
        var dom, xy, pos;
        this.dragObj = ddObj;
        this.dragObjConfig = ddObj.ddConfig;
        dom = ddObj.getDom(e);

        // save mouse start position
        xy = e.getPageXY();
        this.startX = xy[0];
        this.startY = xy[1];

        pos = GUI.getPosition(dom);
        this.deltaX = this.startX - pos.left;
        this.deltaY = this.startY - pos.top;

        this.dragThreshMet = false;
        this.dragTimeout = setTimeout(function () {
            var DDM = GUI.Utils.DDM;
            DDM.startDrag(DDM.startX, DDM.startY);
        }, this.dragTimeThresh);
    };

    /**
     * Fires, when drag threshold met or on timeout
     * @param {Object} x Left position
     * @param {Object} y Top position
     */
    GUI.Utils.DDM.prototype.startDrag = function (x, y) {
        clearTimeout(this.dragTimeout);
        if (this.dragObj) {
            // Refresh cache if dropping enabled
            if (!this.dragObj.ddConfig.moveOnly) {
                this.refreshCache(this.dragObj.ddConfig.groups);
            }

            // Fire events on draggable object
            if (this.dragObj.onBeforeDragStart(x, y) !== false) {
                this.dragObj.onDragStart(x, y);
                this.dragThreshMet = true;
            } else {
                this.dragObj = null;
            }
        }
    };

    /**
     * Removes drag objects
     * @param {Event} e Event
     */
    GUI.Utils.DDM.prototype.stopDrag = function (e) {
        // Fire the drag end event for the item that was dragged
        if (this.dragObj) {
            if (this.dragThreshMet) {
                this.dragObj.onDragEnd(e);
            }
        }
        this.dragObj = null;
        this.dragOvers = {};
    };

    /**
     * Iterates over all registered Drag'n'Drop objects we are hovering over
     * or dropping on
     * @param {Event} e Event
     * @param {Object} isDrop
     */
    GUI.Utils.DDM.prototype.fireDragEvents = function (e, isDrop) {
        var i, ddo, group, oDD, res,
            pt = new GUI.Utils.Point(e.getPageXY()),
            dc = this.dragObj,
            oldOvers  = [],
            outEvts   = [],
            overEvts  = [],
            dropEvts  = [],
            enterEvts = [],
            acceptsDrop = false,
            len = 0;

        // Check to see if the object(s) we were hovering over is no longer
        // being hovered over so we can fire the onDragLeave event
        for (i in this.dragOvers) {
            ddo = this.dragOvers[i];
            if (!this.isOverTarget(pt, ddo)) {
                outEvts.push(ddo);
            }
            oldOvers[i] = true;
            delete this.dragOvers[i];
        }

        for (group in dc.ddConfig.groups) {
            for (i in this.registeredDDs[group]) {
                oDD = this.registeredDDs[group][i];
                if (oDD.ddConfig.isTarget && oDD !== dc) {
                    if (this.isOverTarget(pt, oDD)) {
                        // look for drag enter and drag over interactions
                        // initial drag over: dragEnter fires
                        if (!oldOvers[oDD.config.id]) {
                            enterEvts.push(oDD);
                        // subsequent drag overs: dragOver fires
                        }
                        overEvts.push(oDD);

                        this.dragOvers[oDD.config.id] = oDD;

                        if (isDrop) {
                            // look for drop interactions
                            dropEvts.push(oDD);
                        }
                    }
                }
            }
        }

        // fire dragout events
        len = 0;
        for (i = 0, len = outEvts.length; i < len; ++i) {
            outEvts[i].onDragLeave(e, this.dragObj);
        }

        // fire enter events
        for (i = 0, len = enterEvts.length; i < len; ++i) {
            // dc.b4DragEnter(e, oDD.id);
            //dc.onDragEnter(e, enterEvts[i].config.id);
            enterEvts[i].onDragEnter(e, this.dragObj);
        }

        // fire over events
        for (i = 0, len = overEvts.length; i < len; ++i) {
            res = overEvts[i].onDragOver(e, this.dragObj, pt);
            if (res === true) {
                acceptsDrop = true;
            }
        }

        // fire drop events
        if (isDrop && acceptsDrop) {
            for (i = 0, len = dropEvts.length; i < len; ++i) {
                dropEvts[i].onDragDrop(e, this.dragObj);
            }
        }

        // notify about a drop that did not find a target
        if (isDrop && !(dropEvts.length && acceptsDrop)) {
            dc.onInvalidDrop(e);
        }
        return acceptsDrop;
    };

    /**
     * Refresh cach
     * @param {Object} groups Groups object
     */
    GUI.Utils.DDM.prototype.refreshCache = function (groups) {
        var sGroup, i, oDD;
        for (sGroup in groups) {
            if ("string" !== typeof sGroup) {
                continue;
            }
            for (i in this.registeredDDs[sGroup]) {
                oDD = this.registeredDDs[sGroup][i];
                this.refreshCacheOne(oDD);
            }
        }
    };

    /**
     * Refresh cach of the object
     * @param {Object} oDD
     */
    GUI.Utils.DDM.prototype.refreshCacheOne = function (oDD) {
        var loc = this.getLocation(oDD);
        if (loc) {
            this.locationCache[oDD.config.id] = loc;
        } else {
            delete this.locationCache[oDD.config.id];
        }
    };

    /**
     * Returns the location of the object
     * @param {Object} oDD
     * @returns {Object} location
     */
    GUI.Utils.DDM.prototype.getLocation = function (oDD) {
        var loc, clip,
            el = oDD.ddConfig ? GUI.$(oDD.ddConfig.targetElId) : oDD,
            pos, x1, x2, y1, y2, t, r, b, l;

        if (!el) {
            // Fix for no element found
            return null;
        }

        pos = GUI.getPosition(el);

        if (!pos) {
            return null;
        }

        x1 = pos[0];
        x2 = x1 + el.offsetWidth;
        y1 = pos[1];
        y2 = y1 + el.offsetHeight;

        t = y1;
        r = x2;
        b = y2;
        l = x1;

        loc = new GUI.Utils.Region(t, r, b, l);
        // Now clip loc if needed
        if (oDD.ddConfig && oDD.ddConfig.clipElId) {
            el = GUI.$(oDD.ddConfig.clipElId);
            pos = GUI.getPosition(el);
            l = pos[0];
            r = l + el.offsetWidth;
            t = pos[1];
            b = t + el.offsetHeight;
            clip = new GUI.Utils.Region(t, r, b, l);
            loc = loc.intersect(clip);
            if (!loc) {
                loc = new GUI.Utils.Point(-10000, -10000);
            }
        }
        return loc;
    };

    /**
     * Handler over target
     * @param {Object} pt Point
     * @param {Object} oTarget
     * @param {Boolean} intersect
     */
    GUI.Utils.DDM.prototype.isOverTarget = function (pt, oTarget, intersect) {
        // use cache if available
        var dc,
            loc = this.locationCache[oTarget.config.id];
        if (!loc || !this.useCache) {
            loc = this.getLocation(oTarget);
            this.locationCache[oTarget.config.id] = loc;
        }

        if (!loc) {
            return false;
        }

        oTarget.cursorIsOver = loc.contains(pt);

        // DragDrop is using this as a sanity check for the initial mousedown
        // in this case we are done.  In POINT mode, if the drag obj has no
        // contraints, we are also done. Otherwise we need to evaluate the
        // location of the target as related to the actual location of the
        // dragged element.
        dc = this.dragObj;
        if (!dc || !dc.getTargetCoord ||
                (!intersect && !dc.constrainX && !dc.constrainY)) {
            return oTarget.cursorIsOver;
        }
    };

    /**
     * Stops event
     * @param {Event} e Event
     */
    GUI.Utils.DDM.prototype.stopEvent = function (e) {
        if (this.stopPropagation) {
            e.stopPropagation();
        }
        if (this.preventDefault) {
            e.preventDefault();
        }
    };

    /**
     * Handling mouse movement
     * @param {Event} e Event
     */
    GUI.Utils.DDM.prototype.onMouseMove = function (e) {
        var xy, acceptsDrop, diffX, diffY;
        if (!this.dragObj) {
            // Nothing to do if we are not dragging anything
            return true;
        }

        e = new GUI.ExtendedEvent(e);

        if (!this.dragThreshMet) {
            // There was mousedown, check if drag threshold met
            xy = e.getPageXY();
            diffX = Math.abs(this.startX - xy[0]);
            diffY = Math.abs(this.startY - xy[1]);

            if (diffX > this.dragPixelThresh ||
                    diffY > this.dragPixelThresh) {
                // Start drag if met
                this.startDrag(this.startX, this.startY);
            }
        } else {
            // Dragging...
            acceptsDrop = false;
            if (!this.dragObj.ddConfig.moveOnly) {
                acceptsDrop = this.fireDragEvents(e, false);
            }

            if (this.dragObj.onBeforeDrag(e) !== false) {
                this.dragObj.onDrag(e, acceptsDrop);
            }
        }
        this.stopEvent(e);
    };

    /**
     * Handling mouseup
     * @param {Object} e Event
     */
    GUI.Utils.DDM.prototype.onMouseUp = function (e) {
        if (!this.dragObj) {
            // Nothing to do if not dragging
            return;
        }
        e = new GUI.ExtendedEvent(e);
        clearTimeout(this.dragTimeout);

        if (this.dragThreshMet) {
            // If dragging
            this.fireDragEvents(e, true);
        }
        this.stopDrag(e);
        this.stopEvent(e);
    };

    /**
     * Fires, when DOM document is ready.
     * Events initialization
     */
    GUI.Utils.DDM.prototype.onLoad = function () {
        document.on('mouseup', this.onMouseUp, this);
        document.on('mousemove', this.onMouseMove, this);
        GUI.Event.on(window, 'unload', this.onUnLoad, this);

        if (GUI.isMobile) {
            document.on('touchend', this.onMouseUp, this);
            document.on('touchmove', this.onMouseMove, this);
        }
    };

    /**
     * Cleans up event listeners and stops drag operation if exists
     * on window unload
     */
    GUI.Utils.DDM.prototype.onUnLoad = function () {
        document.un('mouseup', this.onMouseUp, this);
        document.un('mousemove', this.onMouseMove, this);
        GUI.Event.un(window, 'unload', this.onUnLoad, this);

        if (GUI.isMobile) {
            document.un('touchend', this.onMouseUp, this);
            document.un('touchmove', this.onMouseMove, this);
        }
    };


    GUI.Utils.DDM = new GUI.Utils.DDM();
}());