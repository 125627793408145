var superproto = GUI.Utils.Observable.prototype;

/**
* JavaScript Graphical User Interface
* Element implementation
*
* @author Inuytka
* @version 0.1
* @namespace GUI
* @extends GUI.Observable
*/
GUI.Inline = Class.create();
Object.extend(GUI.Inline.prototype, superproto);

/**
 * Type of the element, default is 'GUI.Inline'
 * @type String
 */
GUI.Inline.prototype.elementType = 'GUI.Inline';

/**
 * Template of the lement
 * @type String
 */
GUI.Inline.prototype.template =
    '<div class="inlineedit" id="inlineEditDiv">' +
    '<i class="b-text-field_advanced b-text-field_size_wide">' +
    '<i id="inline_delete" class="b-text-field__icon b-text-field__icon_visible fa fa-times"><i class="s-icon"></i></i>' +
    '<i id="inline_apply" class="b-text-field__icon b-text-field__icon_visible fa fa-check"><i class="s-icon"></i></i>' +
    '<i class="i-text-field" id="td_inline">' +
    '<input class="b-text-field" id="inline_value" type="text" />' +
    '<div id="tmpDivEntityDecode" style="display: none;"></div>' +
    '</i>' +
    '</i>' +
    '</div>';

/**
 * Initializes object
 * @param {Object} config Configuration object
 */
GUI.Inline.prototype.initialize = function (config) {
    // Call parent initialize meethod
    GUI.Utils.Observable.prototype.initialize.apply(this, arguments);

    this.config = {};

    // Extend parent config
    Object.extend(this.config, {
        width     : 0,
        autoApply : false
    });

    Object.extend(this.config, config);

    this.ClickListener = this.ClickCancel.bindAsEventListener(this);
    this.ClickApplyListener = this.ClickApply.bindAsEventListener(this);
    this.KeyPressListener = this.KeyPress.bindAsEventListener(this);

    this.addEvents({
        apply   : true,
        cancel  : true
    });

    this.fRegion = new GUI.Popup.Region({});
    this.sRegion = new GUI.Popup.Region({
        dimensions: {
            width: this.config.width
        },
        content: this.template
    });

    this.anime = new window.Animator({
        duration    : 300,
        scope       : this,
        onComplete  : function (fx) {
            if (fx.state === 0) {
                this.fRegion.hide();
                this.sRegion.hide();
                fx.clearSubjects();
            } else {
                document.getElementById('inline_value').select();
            }
        }
    });
    this.visible = false;
};

/**
 * Shows the region
 * @param {HTMLElement} node
 */
GUI.Inline.prototype.show = function (node) {
    if (this.visible) {
        this.ClickApply();
    }

    var width, region,
        elem = node,
        value = node.innerHTML;

    this.node = node;
    this.config.holder = elem;
    this.config.value = value;

    this.fRegion.takeDocument();
    this.fRegion.show();
    this.fRegion.dom.style.background = "white";
    GUI.setOpacity(this.fRegion.dom, 0.4);
    GUI.$(this.fRegion.dom).on('click', this.ClickApply, this);

    var nodeSize = node.getBoundingClientRect();
    width = nodeSize.width - GUI.$(node).offsetLeft + node.scrollLeft + 9;

    this.sRegion.setDimensions({
        width: (this.config.width ? this.config.width : width)
    });

    this.sRegion.alignTo(elem, 'l-l', GUI.isIE ? [-5, 1] : [-2, 0]);
    this.sRegion.show();

    region = GUI.$('inlineEditDiv');
    GUI.$('inlineEditDiv').setStyle({opacity: 0.1});
    this.anime.clearSubjects();
    this.anime.addSubject(new window.NumericalStyleSubject(region, 'opacity', 0.1, 1));
    this.anime.seekTo(1);

    // <-- html entities decode; LinuZz@27072009
    try {
        GUI.$('tmpDivEntityDecode').innerHTML = '<textarea id="tmpTxtEntityDecode">' + this.config.value + '</textarea>';
        GUI.$('inline_value').value = GUI.$('tmpTxtEntityDecode').value;
    } catch (e) {
        GUI.$('inline_value').value = this.config.value;
    }
    // -->

    //if (GUI.$('inline_value').value !== this.config.value) {
    GUI.$('inline_value').focus();
    GUI.$('inline_value').select();
    //}

    GUI.$('inline_apply').on('click', this.ClickApply, this);
    GUI.$('inline_delete').on('click', this.ClickListener);
    GUI.$('inline_value').on('keyup', this.KeyPressListener);
    this.visible = true;
};

/**
 * Fires event 'cancel', hides the region
 */
GUI.Inline.prototype.ClickCancel = function () {
    this.fRegion.dom.un('click', this.ClickApply, this);
    GUI.$('inline_apply').un('click', this.ClickApply, this);
    GUI.$('inline_delete').un('click', this.ClickCancel, this);
    GUI.$('inline_value').un('keyup', this.KeyPress, this);
    this.anime.seekTo(0);
    this.fireEvent('cancel', this, this.node);
    this.node = null;
    this.visible = false;
};

/**
 * Fires event 'apply', hides the region
 */
GUI.Inline.prototype.ClickApply = function () {
    var value  = GUI.$('inline_value').value;
    if (value === '') {
        value = this.config.value;
    }

    if (this.config.autoApply) {
        GUI.$(this.config.holder).innerHTML = value;
    }

    this.fireEvent('apply', this, this.node, value);

    this.fRegion.dom.un('click', this.ClickApply, this);
    GUI.$('inline_apply').un('click', this.ClickApply, this);
    GUI.$('inline_delete').un('click', this.ClickCancel, this);
    GUI.$('inline_value').un('keyup', this.KeyPress, this);
    this.node = null;
    this.anime.seekTo(0);
    this.visible = false;
};

/**
 * If key number is 13 run 'apply' method, or if key number is 27 run 'cancel' method
 * @param {Object} event Event
 */
GUI.Inline.prototype.KeyPress = function (event) {
    if (event.keyCode === 13) {
        this.ClickApply();
    } else if (event.keyCode === 27) {
        this.ClickCancel();
    }
    GUI.stopEvent(event);
};

/**
 * Destroys objects
 */
GUI.Inline.prototype.destroy = function () {
    this.anime.destroy();
    this.fRegion.destroy();
    this.sRegion.destroy();
};


