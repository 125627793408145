(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * ToolBox implementation
     * ToolBox is a simple toolbar, that contains ToolButtons
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.ToolBox = Class.create();
    Object.extend(GUI.ToolBox.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.ToolBox.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);
        var cfg, i, item, id;

        this.config = {
            id          : GUI.getUniqId('toolbox-'),
            className   : 'b-toolbox',
            itemClass   : 'b-toolbox__item',
            iconClass   : 'b-toolbox__icon',
            items       : [],
            onClick     : null,
            holder      : null,
            renderAfterBeginHolder: false
        };

        cfg = this.config;
        Object.extend(cfg, config);

        this._indexById = {};
        this._indexByName = {};
        i = cfg.items.length;
        while (i--) {
            item = cfg.items[i];
            id = GUI.getUniqId('toolbox-button-');
            item.id = id;
            this._indexById[id] = this._indexByName[item.name] = i;
        }

        this.addEvents({
            click: true
        });

        if (GUI.isFunction(cfg.onClick)) {
            this.on('click', cfg.onClick, cfg.scope);
        }

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
    };

    /**
     * Destroys objects
     * @param {Boolean} quick
     */
    GUI.ToolBox.prototype.destroy = function (quick) {
        this.purgeListeners();

        if (this.dom) {
            this.removeEventListeners();
            if (!quick) {
                GUI.destroyNode(this.dom);
            }
            this.dom = null;
        }
        this.config.holder = null;
    };

    /**
     * Remove event listeners, destroys node
     */
    GUI.ToolBox.prototype.unrender = function () {
        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
    };

    /**
     * Render dom
     */
    GUI.ToolBox.prototype.render = function (to) {
        if (this.dom) {
            this.unrender();
        }
        this.visible = false;

        var o, i, len, item, ul,
            cfg = this.config;

        if (to) {
            cfg.holder = to;
        } else {
            to = cfg.holder;
        }
        to = GUI.$(to);
        to = GUI.Dom.extend(to);

        ul = document.createElement('UL');
        ul.id = cfg.id;
        ul.className = cfg.className;

        o = new GUI.StringBuffer();

        // render buttons
        for (i = 0, len = cfg.items.length; i < len; i++) {
            item = cfg.items[i];
            o.append('<li class="' + cfg.itemClass + '" id="' + item.id + '"');

            if (item.hidden) {
                o.append(' style="display: none;"');
            }
            if (item.title) {
                o.append(' title="' + item.title + '"');
            }
            if (item.hint) {
                o.append(' hint="' + item.hint + '"');
            }
            o.append('><i ');
            if (item.img) {
                o.append(' class="' + cfg.iconClass + ' ' + item.img + '"');
            }
            o.append('></i></li>');
        }

        if (GUI.isIE) {
            if (cfg.renderAfterBeginHolder && to.firstChild) {
                to.insertBefore(ul, to.firstChild);
            } else {
                to.appendChild(ul);
            }
            ul.innerHTML = o.toString(); // After appendChild to prevent leaks in ie
        } else {
            ul.innerHTML = o.toString(); // Improve performance for other browsers
            if (cfg.renderAfterBeginHolder && to.firstChild) {
                to.insertBefore(ul, to.firstChild);
            } else {
                to.appendChild(ul);
            }
        }
        this.dom = GUI.Dom.extend(ul);
        this.attachEventListeners();
    };

    /**
     * Hides item
     * @param {String} name Name of the item
     */
    GUI.ToolBox.prototype.hideItem = function (name) {
        var item = this.getItemByName(name);
        if (item && !item.hidden) {
            item.hidden = true;
            if (this.dom) {
                GUI.hide(item.id);
            }
        }
    };

    /**
     * Shows item
     * @param {String} name Name of the item
     */
    GUI.ToolBox.prototype.showItem = function (name) {
        var item = this.getItemByName(name);
        if (item && item.hidden) {
            item.hidden = false;
            if (this.dom) {
                GUI.show(item.id);
            }
        }
    };

    /**
     * Returns item by id
     * @param {Number|String} id Id of the item
     * @returns {Object} item
     */
    GUI.ToolBox.prototype.getItemById = function (id) {
        var index = this._indexById[id];
        if (GUI.isNumber(index)) {
            return this.config.items[index];
        }
        return false;
    };

    /**
     * Returns item by name
     * @param {Stirng} name Name of the item
     * @returns {Object} item
     */
    GUI.ToolBox.prototype.getItemByName = function (name) {
        var index = this._indexByName[name];
        if (GUI.isNumber(index)) {
            return this.config.items[index];
        }
        return false;
    };

    /**
     * Attach events listeners
     */
    GUI.ToolBox.prototype.attachEventListeners = function () {
        this.dom.on('click', this.clickHandler, this);
    };

    /**
     * Removes events listeners
     */
    GUI.ToolBox.prototype.removeEventListeners = function () {
        this.dom.un();
    };

    /**
     * Fires event 'click', call handler onClick of the item
     * @param {Event} e Event
     */
    GUI.ToolBox.prototype.clickHandler = function (e) {
        e = GUI.Event.extend(e);
        e.stop();

        var item,
            itemNode = e.target.findParent('li', this.dom);
        if (!itemNode) {
            return;
        }
        item = this.getItemById(itemNode.id);

        if (GUI.isFunction(item.onClick)) {
            item.onClick.call(item.scope, item);
        }
        this.fireEvent('click', this, item);
    };

}());
