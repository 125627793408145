(function () {
    var superproto = GUI.Component.prototype;

    /**
     * JavaScript Graphical User Interface
     * Base BoxComponent implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Component
     */
    GUI.BoxComponent = Class.create();
    Object.extend(GUI.BoxComponent.prototype, superproto);

    /**
     * Css class of the element with auto width
     */
    GUI.BoxComponent.prototype.autoWidthClass = 'jsgui-autowidth';

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.BoxComponent.prototype.initialize = function (config) {
        // Call parent initialize meethod
        superproto.initialize.call(this, config);
    };

    /**
     * Init components, adda events 'resize', 'move'
     */
    GUI.BoxComponent.prototype.initComponent = function () {
        superproto.initComponent.call(this);
        this.addEvents({
            resize  : true,
            move    : true
        });
    };

    /**
     * Sets size
     * @param {HTMLElement} dom
     */
    GUI.BoxComponent.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, dom);
    };

    /**
     * Handler after render element
     */
    GUI.BoxComponent.prototype.onPostAfterRender = function () {
        this.suspendEvents();
        this.setSize(this.width, this.height); // very slow... fixed
        this.resumeEvents();
    };

    /**
     * Returns width of the dom
     * @returns {Number} width
     */
    GUI.BoxComponent.prototype.getWidth = function () {
        return this.dom ? this.dom.offsetWidth : this.width;
    };

    /**
     * Returns height of the dom
     * @returns {Number} height
     */
    GUI.BoxComponent.prototype.getHeight = function () {
        return this.dom ? this.dom.offsetHeight : this.height;
    };

    /**
     * Returns object with width and height
     * @returns {Object} size
     */
    GUI.BoxComponent.prototype.getConfigSize = function () {
        return {
            width  : this.width,
            height : this.height
        };
    };

    /**
     * If dom returns size of the dom, else returns config size
     * @returns {Object} size
     */
    GUI.BoxComponent.prototype.getSize = function () {
        if (!this.dom) {
            return this.getConfigSize();
        }
        return {
            width: this.dom.offsetWidth,
            height: this.dom.offsetHeight
        };
    };

    /**
     * Sets size
     * @param {Number} w Width
     * @param {Number} h Height
     */
    GUI.BoxComponent.prototype.setSize = function (w, h) {
        if (typeof w === 'object') {
            h = w.height;
            w = w.width;
        }

        this.width = w;
        this.height = h;

        if (!this.inDom) {
            return this;
        }
        // Corrections of size
        var rz, style,
            adj = this.adjustSize(w, h),
            aw = adj.width,
            ah = adj.height;

        if (aw !== undefined || ah !== undefined) {
            rz = this.getResizeEl();
            style = rz.style;

            if (!this.deferHeight && aw !== undefined && ah !== undefined) {
                // Replaced with style.width/style.height because getting
                // padding/border at runtime is a very cost operation

                if (typeof aw === 'number') {
                    style.width = aw + 'px';
                } else {
                    style.width = aw;
                }
                if (typeof ah === 'number') {
                    style.height = ah + 'px';
                } else {
                    style.height = ah;
                }
            } else if (!this.deferHeight && ah !== undefined) {
                if (GUI.isNumber(ah)) {
                    ah = ah.constrain(0);
                    style.height = ah + 'px';
                } else {
                    style.height = ah;
                }
            } else if (aw !== undefined) {
                if (typeof aw === 'number') {
                    aw = aw.constrain(0);
                    style.width = aw + 'px';
                } else {
                    style.width = aw;
                }
            }
            if (aw === 'auto') {
                GUI.Dom.addClass(rz, this.autoWidthClass);
            } else {
                GUI.Dom.removeClass(rz, this.autoWidthClass);
            }

            this.onResize(aw, ah, w, h);
            this.fireEvent('resize', this, aw, ah, w, h);
        }
        return this;
    };

    /**
     * Adjusts size
     * @param {Number} w Width
     * @param {Number} h Height
     * @returns {Object} size
     */
    GUI.BoxComponent.prototype.adjustSize = function (w, h) {
        if (this.autoWidth) {
            w = 'auto';
        }
        if (this.autoHeight) {
            h = 'auto';
        }

        if (GUI.isNumber(w)) {
            w = w.constrain(0);
        }

        if (GUI.isNumber(h)) {
            h = h.constrain(0);
        }
        return {width : w, height: h};
    };

    /**
     * Returns the resize element or the dom
     * @returns {HTMLElement} element
     */
    GUI.BoxComponent.prototype.getResizeEl = function () {
        return this.resizeEl || this.dom;
    };

    /**
     * Returns the position element or the dom
     * @returns {HTMLElement} element
     */
    GUI.BoxComponent.prototype.getPositionEl  = function () {
        return this.positionEl || this.dom;
    };

    /**
     * Sets width
     * @param {Number} w Width
     * @returns {Object} element
     */
    GUI.BoxComponent.prototype.setWidth = function (w) {
        return this.setSize(w);
    };

    /**
     * Sets height
     * @param {Number} h Height
     * @returns {Object} element
     */
    GUI.BoxComponent.prototype.setHeight = function (h) {
        return this.setSize(undefined, h);
    };

    /**
     * empty function
     */
    GUI.BoxComponent.prototype.onResize = function () {};

    /**
     * Returns position of the element
     * @param {Boolean} relative True if the element is relative
     * @returns {Object} Left and top values
     */
    GUI.BoxComponent.prototype.getPosition = function (relative) {
        if (!this.inDom) {
            return {
                left : this.x,
                top  : this.y
            };
        }
        var el = this.getPositionEl();
        return relative ? {
            left : parseInt(GUI.Dom.getStyle(el, 'left'), 10),
            top  : parseInt(GUI.Dom.getStyle(el, 'top'), 10)
        } : GUI.getPosition(el);
    };

    /**
     * Sets position
     * @param {Number} x Left position
     * @param {Number} y Top position
     */
    GUI.BoxComponent.prototype.setPosition = function (x, y) {
        if (x && typeof x[1] === 'number') {
            y = x[1];
            x = x[0];
        }

        this.x = x;
        this.y = y;

        if (!this.inDom) {
            return this;
        }

        var el,
            adj = this.adjustPosition(x, y),
            ax = adj.x,
            ay = adj.y;

        el = this.getPositionEl();
        if (ax !== undefined || ay !== undefined) {
            if (ax !== undefined && ay !== undefined) {
                el.style.left = ax + 'px';
                el.style.top = ay + 'px';
            } else if (ax !== undefined) {
                el.style.left = ax + 'px';
            } else if (ay !== undefined) {
                el.style.top = ay + 'px';
            }
            this.onPosition(ax, ay);
            this.fireEvent('move', this, ax, ay);
        }
        return this;
    };

    /**
     * Adjusts position
     * @param {Number} x Left position
     * @param {Number} y Top position
     * @returns {Object}
     */
    GUI.BoxComponent.prototype.adjustPosition  = function (x, y) {
        return {x : x, y: y};
    };

    /**
     * empty function
     */
    GUI.BoxComponent.prototype.onPosition = function () {};

}());