(function () {

    var superproto = {};

    /**
     * JavaScript Graphical User Interface
     * Data pagination implementation
     * This component provides paging of data. It is a toolbar with previous and next page buttons and combo box with a list of pages.
     *
     * @class Paging
     * @author S. [LinuZz] Ostapenko, Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Ajax
     * @extends GUI.Utils.Observable
     */
    GUI.Ajax.Paging = Class.create();

    /**
     * Inheritance from Observable
     */
    superproto = GUI.Utils.Observable.prototype;
    Object.extend(GUI.Ajax.Paging.prototype, superproto);

    /**
     * Initializes objects: config, toolbar.
     * Add events 'load', 'fail', 'render', 'pageSizeChange'.
     * Add event 'sortChange' for rendererObj.
     * @param {Object} config Configuration object: <br />
     *  <b>holder</b>       <i>{String|HTMLElement}</i>
     *      Html element or its id to render component to <br />
     *  <b>cls</b>          <i>{String}</i>
     *      Css class name to apply to the paging bar. <br />
     *  <b>url</b>          <i>{String}</i>
     *      Url to load data from <br />
     *  <b>method</b>       <i>{String}</i>
     *      Method of sending data to use. Can be 'post' or 'get'. Defaults to 'post' <br />
     *  <b>baseParams</b>   <i>{Object}</i>
     *      Base parameters are always sent during every request <br />
     *  <b>params</b>       <i>{Object}</i>
     *      Object that contains parameters to sent. It is cleared each time
     *      after request is sent <br />
     *  <b>rendererObj</b>  <i>{Object}</i>
     *      Object that is used to render data. It must have an update() method
     *      that accepts object with the loaded data. This method will be called
     *      after new data is loaded. If allowMask option is true then rendererObj
     *      must implement mask() method that masks component or getHolder()
     *      method that returns HTMLElement that will be masked using
     *      GUI.Popup.Mask. Also while component initialization event listener
     *      on the sortChange event is attached. It sets new sortIndex and
     *      sortDirection in the baseParams and calls load() method to load
     *      data with new sorting applied <br />
     *  <b>pageSize</b>  <i>{Number}</i>
     *      Sets a number of records per page. This number is sent in request as
     *      pageSize parameter <br />
     *  <b>allowMask</b>    <i>{Boolean}</i>
     *      False to do not use masking while data is loading. Defaults to true <br />
     *  <b>root</b>         <i>{String}</i>
     *      Name of the property that contains data in the json from the server.
     *      Defaults to null, so returned json is a data object. <br />
     *  <b>disabled</b>     <i>{Boolean}</i>
     *      True if paging bar is disabled. Defaults to false. <br />
     *  <b>maskText</b>     <i>{String}</i>
     *      Text in the mask. Defaults to i18n('Loading...'). <br />
     *  <b>comboTpl</b>     <i>{String}</i>
     *      Template used to render combo box items. Defaults to i18n(GUI.i18n.GUI_PAGING_TPL).
     *      There are first, last and total variables that can be used in
     *      the template. They are set to the start, end number of record on
     *      the page and total number of records accordingly. <br />
     */
    GUI.Ajax.Paging.prototype.initialize = function (config) {
        var inpEl, cfg = {}, toolbarElements = [],
            grid = {};

        /**
         * Call parent initialize method
         * @param {Object} this
         * @param {Object} config configuration object
          */
        superproto.initialize.call(this, config);

        /**
         * configuration object
         * @type Object
         */
        this.config = {
            holder      : false,
            url         : false,
            method      : 'post',
            baseParams  : {},
            params      : {},
            pageSize    : 10,
            page        : 1,
            total       : 0,
            rendererObj : false,
            allowMask   : true,
            root        : null,
            disabled    : false,
            simple      : false,
            renderAfterBeginHolder: true,
            maskText    : i18n('Loading...'),
            pageText    : i18n('Page'),
            cls         : 'b-paging',
            align       : 'right',
            showDisplaying  : true,
            showPerPage     : true
        };
        cfg = this.config;
        Object.extend(cfg, config);

        /**
         * Add Events
         */
        this.addEvents({
            load        : true,
            fail        : true,
            render      : true,
            pageSizeChange: true,
            setPage     : true,
            nextClick   : true,
            prevClick   : true,
            startClick  : true,
            endClick    : true
        });

        cfg.pageSize = parseInt(cfg.pageSize, 10);
        cfg.total = parseInt(cfg.total, 10);
        cfg.page = parseInt(cfg.page, 10);

        /**
         * Default is 0
         * @type Number
         */
        this.page = cfg.page;

        /**
         * Default is 0
         * @type Number
         */
        this.total = cfg.total;

        /**
         * Rows per page, default is 10
         * @type Number
         */
        this.pageSize = cfg.pageSize;

        /**
         * Data
         * @type Object
         */
        this.data = {};

        /**
         * Last query parameters. Default is {}
         * @type Object
         */
        this.lastParams = {};

        /**
         * Query response. Default is ''
         * @type String
         */
        this.response = '';

        /**
         * Mask object
         * @type Object
         * @see GUI.Popup.Mask#
         */
        this.mask = new GUI.Popup.Mask({
            text: this.config.maskText
        });

        /**
         * True if paging bar is disabled. Default is false.
         * @type Boolean
         */
        this.disabled = cfg.disabled;

        /**
         * Toolbar of buttons and combo
         * @type Object
         * @see GUI.ToolBar#
         */

        inpEl = document.createElement('INPUT');
        inpEl.type = 'text';
        inpEl.className = 'b-text-field current-page';
		inpEl.hint = i18n('Enter page number and hit Enter');
        inpEl.value = this.page;
        this.inpEl = inpEl;

        this.pageCount = Math.ceil(this.total / this.pageSize);

        if (cfg.simple) {
            this.toolbar = new GUI.ToolBar({
                align       : 'right',
                background  : false,
                className   : '',
                extraClass  : 'b-paging b-paging_simple',
                elements    : [{
                    name: 'displayingEl',
                    obj : new GUI.Element({
                        extraClass  : 'position',
                        html        : sprintf(i18n(GUI.i18n.GUI_PAGING_SIMPLE_TPL), this.page, this.total)
                    })
                }, {
                    name: 'previous',
                    obj : new GUI.ToolBar.Button({
                        iconClass   : 'previous',
						hint	    : i18n('Previous Page'),
                        background  : false,
                        onClick     : this.onPrevClick.bindLegacy(this)
                    })
                }, {
                    name: 'next',
                    obj : new GUI.ToolBar.Button({
                        iconClass   : 'next',
						hint	    : i18n('Next Page'),
                        background  : false,
                        onClick     : this.onNextClick.bindLegacy(this)
                    })
                }]
            });
        } else {
            if (cfg.showDisplaying) {
                toolbarElements.push({
                    name : 'displayingEl',
                    obj  : new GUI.Element({
                        //html: GUI.formatString(i18n(GUI.i18n.GUI_PAGING_TPL), this.page, this.pageSize, this.total)
                        html: sprintf(
                            i18n(GUI.i18n.GUI_PAGING_TPL),
                            this.page,
                            this.pageSize,
                            this.total
                        )
                    })
                });
                toolbarElements.push({
                    obj : new GUI.ToolBar.Separator({extraClass: 'after-text'})
                });
            }

            if (cfg.showPerPage) {
                toolbarElements.push({
                    name: 'perpage',
                    obj : new GUI.Element({
                        html: i18n('Items per Page') + ' '
                    })
                });
                toolbarElements.push({
                    name: 'buttonPerPage',
                    obj : new GUI.ToolBar.MenuButton({
                        caption	    : this.pageSize,
                        background  : false,
                        menu        : {
                            name    : 'menu',
                            onClick : this.onMenuPerPageClick.bindLegacy(this),
                            items   : [{
                                name    : '10',
                                caption : '10'
                            }, {
                                name    : '25',
                                caption : '25'
                            }, {
                                name    : '50',
                                caption : '50'
                            }, {
                                name    : '100',
                                caption : '100'
                            }]
                        }
                    })
                });
                toolbarElements.push({
                    obj : new GUI.ToolBar.Separator()
                });
            }

            toolbarElements.push({
                obj : new GUI.Element({
                    extraClass  : 'after-text',
                    html        : cfg.pageText
                })
            });
            toolbarElements.push({
                name: 'pageEl',
                obj : new GUI.Element({
                    withoutSpan : true,
                    html        : inpEl
                })
            });
            toolbarElements.push({
                name: 'pageCount',
                obj : new GUI.Element({
                    html: sprintf(i18n(GUI.i18n.GUI_PAGING_SIMPLE_TPL), '', this.pageCount).trim()
                })
            });
            toolbarElements.push({
                obj : new GUI.ToolBar.Separator({extraClass: 'after-text'})
            });

            this.toolbar = new GUI.ToolBar({
                extraClass              : cfg.extraClass || 'b-paging b-toolbar',
                clearToolbar            : !cfg.background,
                renderAfterBeginHolder  : cfg.renderAfterBeginHolder,
                align                   : cfg.align,
                elements                : toolbarElements
            });
        }

        grid = cfg.rendererObj;

        if (grid) {
            grid.on('sortChange', this.onSortChange, this);
        }
    };

    /**
     * Change baseParams after sort.
     * Calling method load()
     * @param {Object} obj this
     * @param {String} index field name to sort
     * @param {String} sort direction of sort
     */
    GUI.Ajax.Paging.prototype.onSortChange = function (obj, index, sort) {
        this.config.baseParams.sortIndex = index;
        this.config.baseParams.sortDirection = sort;
        this.load();
    };
    /**
     * Removes event listeners and html markup of the component.
     * @param {Boolean} quick
     */
    GUI.Ajax.Paging.prototype.destroy = function (quick) {
        if (this._request && !this._request.complete) {
            this._request.abort();
            this._request = null;
        }

        this.purgeListeners();

        if (this.mask) {
            this.mask.destroy();
            this.mask = null;
        }

        if (this.toolbar) {
            this.toolbar.destroy(quick);
            this.toolbar = null;
        }
        if (this.config.rendererObj) {
            this.config.rendererObj.un('sortChange', this.onSortChange, this);
            this.config.rendererObj = null;
        }
        this.config.holder = null;
    };

    /**
     * Renders component to the passed holder or takes it from the configuration object.
     * @param {String|HTMLElement} holder If provided replaces holder passed as
     * a parameter in the configuration object. Otherwise, holder from the configuration is used.
     */
    GUI.Ajax.Paging.prototype.render = function (holder) {
        var right_toolbar,
            buttons = {},
            cfg = this.config;

        if (GUI.isSet(holder)) {
            cfg.holder = holder;
        }
        if (!GUI.$(cfg.holder)) {
            console.log(' Error in method render(): No holder \'' + cfg.holder + '\' found');
            return 'No holder';
        }

        this.toolbar.render(cfg.holder);

        if (!cfg.simple) {
            GUI.Dom.extend(this.inpEl);
            this.inpEl.on('change', this.setPage.bindLegacy(this, this.inpEl));

            right_toolbar = document.createElement('I');
            right_toolbar.className = 'g-rtl__cancel';
            this.toolbar.dom.appendChild(right_toolbar);

            // page
            buttons.start = new GUI.ToolBar.Button({
                iconClass   : 'fa fa-chevron-double-left start',
				hint	    : i18n('First Page'),
                background  : false,
                onClick     : this.onStartClick.bindLegacy(this)
            });
            buttons.start.render(right_toolbar);

            buttons.previous = new GUI.ToolBar.Button({
                iconClass   : 'fa fa-chevron-left previous',
				hint	    : i18n('Previous Page'),
                background  : false,
                onClick     : this.onPrevClick.bindLegacy(this)
            });
            buttons.previous.render(right_toolbar);

            buttons.next = new GUI.ToolBar.Button({
                iconClass   : 'fa fa-chevron-right next',
				hint	    : i18n('Next Page'),
                background  : false,
                onClick     : this.onNextClick.bindLegacy(this)
            });
            buttons.next.render(right_toolbar);

            buttons.end = new GUI.ToolBar.Button({
                iconClass   : 'fa fa-chevron-double-right end',
				hint	    : i18n('Last Page'),
                background  : false,
                onClick     : this.onEndClick.bindLegacy(this)
            });
            buttons.end.render(right_toolbar);
        }
        this.buttons = buttons;
        this.update();
        return true;
    };

    /**
     * Update after loading
     */
    GUI.Ajax.Paging.prototype.update = function () {
        var from, to, pageEl,
            displayingEl = this.toolbar.getElement('displayingEl'),
            cfg = this.config;

        this.pageCount = Math.ceil(this.total / this.pageSize);

        from = ((this.page - 1) * this.pageSize) + 1;
        from = from > this.total ? this.total : from;

        if (cfg.simple) {
            if (cfg.showDisplaying) {
                displayingEl.updateHtml(sprintf(i18n(GUI.i18n.GUI_PAGING_SIMPLE_TPL), this.page, this.pageCount));
            }

            this.toolbar.getElement('previous')[(this.page === 1) ? 'disable' : 'enable']();
            this.toolbar.getElement('next')[((this.page) === this.pageCount) ? 'disable' : 'enable']();
        } else {
            to = from + (this.pageSize - 1);
            to = to > this.total ? this.total : to;

            if (cfg.showDisplaying) {
                displayingEl.updateHtml(
                    sprintf(
                        i18n(GUI.i18n.GUI_PAGING_TPL),
                        from,
                        to,
                        this.total
                    )
                );
            }

            this.buttons.start[(this.page === 1) ? 'disable' : 'enable']();
            this.buttons.previous[(this.page === 1) ? 'disable' : 'enable']();
            this.buttons.next[(to === this.total) ? 'disable' : 'enable']();
            this.buttons.end[(to === this.total) ? 'disable' : 'enable']();
            this.inpEl.value = this.page;

            pageEl = this.toolbar.getElement('pageCount');
            pageEl.dom.innerHTML = sprintf(i18n(GUI.i18n.GUI_PAGING_SIMPLE_TPL), '', this.pageCount).trim();
        }
    };

    /**
     * Updates data
     */
    GUI.Ajax.Paging.prototype.updateData = function (data) {
        if (this.config.root) {
            Object.extend(this.data, this.data[this.config.root]);
        }

        this.total = data.total || 0;
        this.pageSize = data.pageSize || 0;
        this.page = data.page || 0;

        this.update();

        if (this.config.rendererObj) {
            this.config.rendererObj.update(data);
        }
    };

    /**
     * Disables component
     */
    GUI.Ajax.Paging.prototype.disable = function () {
        if (this.disabled) {
            return;
        }
        this.toolbar.disable();
        this.disabled = true;
    };

    /**
     * Enables disabled component
     */
    GUI.Ajax.Paging.prototype.enable = function () {
        if (!this.disabled) {
            return;
        }
        this.toolbar.enable();
        this.disabled = false;
    };

    /**
     * Loads new data and updates component that was passed as rendererObj option.
     * Two parameters are always sent in the request:  start and pageSize. Start is the
     * start number of record to get in the response and pageSize is a maximum number of
     * records to get. Response must contain properties named ‘start’ and ‘total’.
     * Fires load event and calls update() method on the rendererObj with new data
     * when request completed successfully and fail event if request failed.
     * Component is rendered again every time new data is loaded, so it also fires render event.
     * If response from the server contains sortIndex property then component calls
     * method setSorting(sortIndex, sortDirection) on the rendererObj
     * with values of sortIndex and sortDirection properties as arguments.
     * @param {Object} config If this object is passed it extends configuration option.
     */
    GUI.Ajax.Paging.prototype.load = function (config) {
        if (config) {
            if (config.baseParams) {
                Object.extend(this.config.baseParams, config.baseParams);
            }
            if (config.params) {
                Object.extend(this.config.params, config.params);
            }
        }

        var params = Object.clone(this.config.baseParams),
            obj = this.config.rendererObj;

        Object.extend(params, {
            page   : this.page,
            pageSize: this.pageSize
        });
        Object.extend(params, this.config.params);

        this.lastParams = config;
        this.config.params = {};
        this.params = params;

        // NaN
        this.params.pageSize = isNaN(this.params.pageSize) ? 10 : this.params.pageSize;

        if (this.config.allowMask) {

            if (!obj && !this.config.maskEl) {
                console.log('No element for the mask, it be cancelled');
                this.config.allowMask = false;
            }

            if (obj) {

                if (GUI.isFunction(obj.mask)) {
                    obj.mask();
                } else {
                    this.mask.setElement((obj.getMaskEl || obj.getHolder).call(obj));
                    this.mask.show();
                }

            } else if (this.config.maskEl) {
                this.mask.setElement(this.config.maskEl);
                this.mask.show();
            }

            this._load();
        } else {
            this._load();
        }
    };

    /**
     * Send request to download data
     * @private
     */
    GUI.Ajax.Paging.prototype._load = function () {
        var self = this,
            params = this.params;

        if (this._request && !this._request.complete) {
            this._request.abort();
        }

        this._request = new Ajax.Request(self.config.url, {
            method      : self.config.method,
            parameters  : GUI.toQueryString(params),
            scope       : this,
            onSuccess   : this.onLoadSuccess,
            onFailure   : this.onLoadFailure
        });
    };

    /**
     * Successful loads data.
     * Fire event 'load'.
     * @param {Object} response server response
     *
     */
    GUI.Ajax.Paging.prototype.onLoadSuccess = function (response, request) {
        var rendererObj = {},
            data = {},
            baseParams = this.config.baseParams,
            sort_index,
            sort_direction;

        this.response = response.responseText;
        this.data = request.responseJson;

        if (this.config.root) {
            Object.extend(this.data, this.data[this.config.root]);
        }

        if (!this.data) {
            return this.onLoadFailure(response);
        }

        this.fireEvent('load', this, this.response, this.data);

        if (this.data) {
            this.page = parseInt(this.data.page, 10);
            this.total = parseInt(this.data.total, 10);
        }

        if (isNaN(this.page)) {
            this.page = 1;
        }
        if (isNaN(this.total)) {
            this.total = 0;
        }

        this.update(); // Putting this before grid update fixes combo.setValue() extra slowdown

        if (this.config.rendererObj) {
            data = this.data;
            rendererObj = this.config.rendererObj;
            rendererObj.update(this.data);

            if (this.data.sortIndex && GUI.isFunction(rendererObj.setSorting)) {
                sort_index = this.data.sortIndex;
                sort_direction = this.data.sortDirection;
                baseParams.sortIndex = sort_index;
                baseParams.sortDirection = sort_direction;
                rendererObj.setSorting(sort_index, sort_direction);
            }
        }

        if (this.config.allowMask) {

            if (GUI.isFunction(this.config.rendererObj.mask)) {
                this.config.rendererObj.unmask();

            } else if (this.mask && this.mask.region && this.mask.region.dom) {
                this.mask.hide();
            }
        }

        return true;
    };

    /**
     * Failure loads data.
     * Fire event 'fail'
     * @param {Object} transport server response
     */
    GUI.Ajax.Paging.prototype.onLoadFailure = function (transport) {
        if (this.config.allowMask) {
            if (GUI.isFunction(this.config.rendererObj.mask)) {
                this.config.rendererObj.unmask();
            } else {
                this.mask.hide();
            }
        }
        this.fireEvent('fail', this, transport, false);
        this.update();
        return true;
    };

    /**
     * Loads data at the specified page number.
     * @param {Number} number Page of data to load beginning from 1.
     */
    GUI.Ajax.Paging.prototype.loadPage = function (number) {
        this.config.baseParams.pageSize = parseInt(number, 10) || 0;
        this.load();
        return true;
    };

    /**
     *
     */
    GUI.Ajax.Paging.prototype.setPage = function (elem, e) {
        var value = parseInt(elem.value, 10);
        if (value <= this.pageCount && value > 0) {
            this.page = elem.value;
            this.update();
            if (this.fireEvent('setPage', this, this.page)) {
                this.load();
            }
        }
    };

    /**
     * Reloads data using last used params.
     */
    GUI.Ajax.Paging.prototype.refresh = function () {
        this.load(this.lastParams);
        return true;
    };

    /**
     * Click on prev button.
     * If page >= pageSize then calling method load() with param page=page-pageSize
     * @param {Object} btn button prev
     */
    GUI.Ajax.Paging.prototype.onPrevClick = function (btn, e) {
        if (this.page <= this.pageCount) {
            this.page--;
            this.update();
            if (this.fireEvent('prevClick', this, this.page, e)) {
                this.load();
            }
        }
        return true;
    };

    /**
     * Click on next button.
     * If page < (total-pageSize) then calling method load() with param page = page+rowspPerPage
     * @param {Object} btn button next
     */
    GUI.Ajax.Paging.prototype.onNextClick = function (btn, e) {
        if (this.page <= (this.total - this.pageSize)) {
            this.page++;
            this.update();
            if (this.fireEvent('nextClick', this, this.page, e)) {
                this.load();
            }

        }
        return true;
    };

    /**
     * Click on start button
     * @param {Object} btn Button start
     */
    GUI.Ajax.Paging.prototype.onStartClick = function (btn, e) {
        this.page = 1;
        this.update();
        if (this.fireEvent('startClick', this, this.page, e)) {
            this.load();
        }
    };

    /**
     * Click on end button
     * @param {Object} btn Button end
     */
    GUI.Ajax.Paging.prototype.onEndClick = function (btn, e) {
        this.page = this.pageCount;
        this.update();
        if (this.fireEvent('endClick', this, this.page, e)) {
            this.load();
        }
    };

    /**
     * Click on menu per page button
     * @param {Object} btn Button
     */
    GUI.Ajax.Paging.prototype.onMenuPerPageClick = function (obj, item) {
        var value;
        value = parseInt(item.name, 10);
        this.pageSize = value;
        this.page = 1;
        this.toolbar.getElement('buttonPerPage').button.setText(this.pageSize);
        this.update();
        this.loadPage(this.page);
    };

    /**
     * Get number of rows per page
     * @returns {Number} number of rows per page
     */
    GUI.Ajax.Paging.prototype.getPageSize = function () {
        return parseInt(this.pageSize, 10) || 0;
    };

    /**
     * Set number of rows per page.
     * Fire event 'pageSizeChange'
     * @param {Number} size new number of rows per page
     */
    GUI.Ajax.Paging.prototype.setPageSize = function (size) {
        if (typeof size !== 'number') {
            return false;
        }
        var oldSize = this.pageSize,
            remainder = this.page % size;

        this.pageSize = size;

        if (remainder !== 0) {
            // need to update page to be at the start offset of the new page
            if ((remainder * 2 >= size) && ((this.page + size - remainder) < this.total)) {
                this.page += size - remainder;
            } else {
                this.page -= remainder;
            }

            this.page = this.page.constrain(0);
        }
        this.fireEvent('pageSizeChange', this, size, oldSize);
        return true;
    };

    /**
     * Set url of the request
     * @param String url
     */
    GUI.Ajax.Paging.prototype.setUrl = function (url) {
        if (GUI.isSet(url) && typeof url === 'string') {
            this.config.url = url;
        }
    };

}());