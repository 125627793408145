var superproto = GUI.Utils.Observable.prototype;

/**
 * JavaScript Graphical User Interface
 * Grid's row selection model implementation
 *
 * @author Eugene Lyulka
 * @version 2.0
 * @namespace GUI.Grid
 * @extends GUI.Utils.Observable
 */
GUI.Grid.RowSelectionModel = Class.create();
Object.extend(GUI.Grid.RowSelectionModel.prototype, superproto);

/**
 * Constructor
 * @param {Object} config Configuration object
 */
GUI.Grid.RowSelectionModel.prototype.initialize = function (config) {
    GUI.Utils.Observable.prototype.initialize.call(this);

    this.config = {
        singleSelect: false
    };

    Object.extend(this.config, config);

    this.selections = new GUI.Utils.Collection();

    this.last = false;
    this.lastActive = false;
    this.singleSelect = this.config.singleSelect;
    this.addEvents({
        /**
         * @event selectionchange
         * Fires when the selection changes
         * @param {SelectionModel} this
         */
        selectionchange: true,
        /**
         * @event beforerowselect
         * Fires when a row is being selected, return false to cancel.
         * @param {SelectionModel} this
         * @param {Row} row Row to be selected
         * @param {Boolean} keepExisting False if other selections will be cleared
         */
        beforerowselect: true,
        /**
         * @event rowselect
         * Fires when a row is selected.
         * @param {SelectionModel} this
         * @param {Row} row The selected row
         */
        rowselect: true,
        /**
         * @event rowdeselect
         * Fires when a row is deselected.
         * @param {SelectionModel} this
         * @param {Row} rowIndex
         */
        rowdeselect: true
    });

};

/**
 * Called by grid internally!
 * @param {Object} grid Object of the grid
 */
GUI.Grid.RowSelectionModel.prototype.init = function (grid) {
    this.grid = grid;
    this.className = 'GUI.Grid.RowSelectionModel';
    this.initEvents();
};

/**
 * Initialize needed events
 */
GUI.Grid.RowSelectionModel.prototype.initEvents = function () {

    this.grid.on('cellClick',
        function (grid, row, col, e) {
            var target, block;
            if (row.noSelect) {
                return;
            }
            // Check if any parent node has attribute noselect, then block selection
            target = e.getTarget();
            block = false;
            while (target && (target.nodeName !== 'TD')) {
                if (target.getAttribute('noselect') === true) {
                    block = true;
                    break;
                }
                target = target.parentNode;
            }
            if (!(col.blockSelection || block)) {
                if (!e.shiftKey && !e.ctrlKey) { // !!!
                    if (row.isSelected()) {
                        this.deselectRow(row);
                    } else {
                        this.selectRow(row, true);
                    }
                }
            }
        }, this);
};

/**
 * Selects row
 * Can't use because of need to implement text selection blocker, otherwise when
 *  we select rows with shift or ctrl we have artefacts
 *  @param {Object} grid Object of the grid
 *  @param {Object} row Current row
 *  @param {Number} colIndex Index of the column
 *  @param {Event} e Event
 */
GUI.Grid.RowSelectionModel.prototype.handleMouseDown = function (grid, row, colIndex, e) {
    var last, isSelected;
    if (!GUI.isLeftClick(e)) {
        return;
    }

    if (e.shiftKey && this.last !== false) {
        last = this.last;
        this.selectRange(last, row, e.ctrlKey);
        this.last = last; // reset the last
    } else {
        isSelected = row.isSelected();
        if (e.ctrlKey && isSelected) {
            this.deselectRow(row);
        } else if (!isSelected || this.getCount() > 1) {
            this.selectRow(row, e.ctrlKey || e.shiftKey);
        }
    }
};

/**
 * Clears all selections. If fast is true fires event 'selectionchange'
 * @param {Boolean} fast
 */
GUI.Grid.RowSelectionModel.prototype.clearSelections = function (fast) {
    if (fast !== true) {
        var s = this.selections;
        s.each(function (row) {
            this.deselectRow(row);
        }, this);
        s.clear();
    } else {
        this.selections.clear();
        this.fireEvent("selectionchange", this);
    }
    this.last = false;
};

/**
 * Returns the selected records
 * @returns {Array} Array of selected records
 */
GUI.Grid.RowSelectionModel.prototype.getSelections = function () {
    return [].concat(this.selections.items);
};

/**
 * Returns the first selected record.
 * @returns {Object} row
 */
GUI.Grid.RowSelectionModel.prototype.getSelected = function () {
    return this.selections.itemAt(0);
};

/**
 * Gets the number of selected rows.
 * @returns {Number}
 */
GUI.Grid.RowSelectionModel.prototype.getCount = function () {
    return this.selections.length;
};

/**
 * Selects a row.
 * @param {Row} row Row to select
 * @param {Boolean} keepExisting (optional) True to keep existing selections
 * @param {Boolean} preventNotify (optional) True to prevent grid notification
 */
GUI.Grid.RowSelectionModel.prototype.selectRow = function (row, keepExisting, preventNotify) {
    if (row.noSelect) {
        return false;
    }

    if (this.fireEvent("beforerowselect", this, row, keepExisting) !== false) {
        if (!keepExisting || this.singleSelect) {
            this.clearSelections();
        }

        this.selections.add(row);
        this.last = this.lastActive = row;
        if (!preventNotify) {
            this.grid.onRowSelect(row);
        }
        this.fireEvent("rowselect", this, row);
        this.fireEvent("selectionchange", this);
        return true;
    } else {
        return false;
    }
};

/**
 * Selects all rows.
 */
GUI.Grid.RowSelectionModel.prototype.selectAll  = function () {
    this.selections.clear();
    var self = this;
    this.grid.rows.each(
        function (row) {
            self.selectRow(row, true);
        }
    );
};

/**
 * Selects a range of rows. All rows in between startRow and endRow are also selected.
 * @param {Number} row1 The index of the first row in the range
 * @param {Number} row2 The index of the last row in the range
 * @param {Boolean} keepExisting (optional) True to retain existing selections
 */
GUI.Grid.RowSelectionModel.prototype.selectRange  = function (row1, row2, keepExisting) {
    var i, startRow, endRow;

    if (!keepExisting) {
        this.clearSelections();
    }
    startRow = this.grid.rows.indexOf(row1);
    endRow = this.grid.rows.indexOf(row2);
    if (startRow <= endRow) {
        for (i = startRow; i <= endRow; i++) {
            this.selectRow(this.grid.rows.itemAt(i), true);
        }
    } else {
        for (i = startRow; i >= endRow; i--) {
            this.selectRow(this.grid.rows.itemAt(i), true);
        }
    }
};

/**
 * Deselects a row.
 * @param {Number} row The index of the row to deselect
 * @param {Boolean} preventNotify
 */
GUI.Grid.RowSelectionModel.prototype.deselectRow  = function (row, preventNotify) {
    if (row.noSelect) {
        return false;
    }
    if (this.last === row) {
        this.last = false;
    }

    if (this.lastActive === row) {
        this.lastActive = false;
    }

    if (row) {
        this.selections.remove(row);
        if (!preventNotify) {
            this.grid.onRowDeselect(row);
        }
        this.fireEvent("rowdeselect", this, row);
        this.fireEvent("selectionchange", this);
        return true;
    } else {
        return false;
    }
};
