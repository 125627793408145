(function () {
    var superproto = GUI.Utils.Observable.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Validator implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Utils.Observable
    */
    GUI.Forms.Validation.Validator = Class.create();
    Object.extend(GUI.Forms.Validation.Validator.prototype, superproto);

    /**
     * Type of the element, 'GUI.Forms.Validation.Validator'
     * @type String
     */
    GUI.Forms.Validation.Validator.prototype.elementType = 'GUI.Forms.Validation.Validator';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Validator.prototype.initialize = function (config) {
        // Initialize config and extend it with passed data
        if (config) {
            Object.extend(this, config);
        }

        // Add events
        this.addEvents({
            beforeValidation: true
        });

        superproto.initialize.call(this);
    };

    /**
     * Destroy objects
     */
    GUI.Forms.Validation.Validator.prototype.destroy = function () {
        this.purgeListeners();
    };

    /**
     * Validates field
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Validator.prototype.validate = function () {
        var value;
        try {
            value = this.field.getValue();
        } catch (e) {
            console.log(e);
            throw e;
        }
        return this.validateCustom(value);
    };

    /**
     * Validates custom value
     * @param {String|Number} value value to validate
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Validator.prototype.validateCustom = function (value) {
        if (this.fireEvent('beforeValidation', this, value) === false) {
            return false;
        }
        var isValid = this.validateFunc(value);
        return isValid;
    };

    /**
     * Helper function to minimize event firing code copy-paste.
     * Only validation code goes here
     * @param {Object} value
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Validator.prototype.validateFunc = function (value) {
        throw 'You need to override validateFunc() method in your validator!';
    };

    /**
     * Returns tetx of the error message
     * @returns String error message
     */
    GUI.Forms.Validation.Validator.prototype.getErrorMessage = function () {
        return this.errorMessage;
    };

}());