(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Base Component implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Component = Class.create();
    Object.extend(GUI.Component.prototype, superproto);

    /**
     * Defalut is 0
     * @type Number
     */
    GUI.Component.AUTO_ID = 0;

    /**
     * Hint to display when the mouse is holded over the field
     * @type String
     */
    GUI.Component.prototype.hint = null;

    /**
     * True if the component is disabled
     * @type Boolean
     */
    GUI.Component.prototype.disabled = false;

    /**
     * True if this component is hidden. Read-only.
     * @type Boolean
     */
    GUI.Component.prototype.hidden = false;

    /**
     * True if the component is inserted into the document's dom tree
     * @type Boolean
     */
    GUI.Component.prototype.inDom = false;

    /**
     * How this component should hidden. Supported values are:
     *  - "visibility" (css visibility),
     *  - "offsets" (negative offset position) and
     *  - "display" (css display).
     *  Defaults to "display".
     *  @type String
     */
    GUI.Component.prototype.hideMode = 'display';

    /**
     * CSS class added to the component when it is disabled.
     * @type String
     */
    GUI.Component.prototype.disabledClass = '';

    /**
     * Default is 20
     * @type Number
     */
    GUI.Component.prototype.deferFocusTime = 20;

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Component.prototype.initialize = function (config) {
        this.plugins = [];
        // Arghh... need to support prev version of lib  // O_o
        if (config) {
            if (!config.listeners) {
                config.listeners = {};
            }

            if (config.onClick) {
                config.listeners.click = config.onClick;
                delete config.onClick;
            }

            if (config.onChange) {
                config.listeners.change = config.onChange;
                delete config.onChange;
            }

            Object.extend(this, config);
        }

        this.initialConfig = config || {};

        // Back support workaround... But creates circular link
        this.config = this;

        this.addEvents({
            'beforeShow'   : true,
            'show'         : true,
            'beforeHide'   : true,
            'hide'         : true,
            'beforeRender' : true,
            'render'       : true,
            'afterRender'  : true,
            'disable'      : true,
            'enable'       : true,
            'beforedestroy': true,
            'destroy'     : true
        });

        this.getId();
        GUI.ComponentMgr.register(this);

        // Call parent initialize meethod
        superproto.initialize.call(this);

        if (this.plugins && !GUI.isArray(this.plugins)) {
            this.plugins = [this.plugins];
        }

        this.initComponent();

        // Initialize plugins
        var i, len;
        if (this.plugins) {
            if (GUI.isArray(this.plugins)) {
                for (i = 0, len = this.plugins.length; i < len; i++) {
                    this.plugins[i].init(this);
                }
            } else {
                this.plugins.init(this);
            }
        }

        // Autoapply/autorender support
        if (this.assignTo) {
            this.assign(this.assignTo);
            delete this.assignTo;
        } else if (this.renderTo) {
            this.render(this.renderTo);
            delete this.renderTo;
        }
    };

    /**
     * Destroys component
     * @param {Boolean} quick
     */
    GUI.Component.prototype.destroy = function (quick) {
        if (this.fireEvent('beforedestroy', this) === false) {
            return;
        }

        if (this.plugins) {
            var i, len;
            if (GUI.isArray(this.plugins)) {
                for (i = 0, len = this.plugins.length; i < len; i++) {
                    if (this.plugins[i].destroy) {
                        this.plugins[i].destroy(this);
                    }
                }
            } else {
                if (this.plugins.destroy) {
                    this.plugins.destroy(this);
                }
            }
            this.plugins = null;
        }

        this.onBeforeDestroy(quick);

        this.onDestroy(quick);
        GUI.ComponentMgr.unregister(this);
        this.fireEvent('destroy', this);
        this.purgeListeners();
        GUI.destroyObject(this); // think ...
    };

    /**
     * Renders component
     * @param {Object} to
     * @param {Object} before
     */
    GUI.Component.prototype.render = function (to, before) {
        if (this.rendered) {
            return;
        }
        if (this.fireEvent('beforerender', this) === false) {
            return;
        }

        if (GUI.isSet(to)) {
            this.holder = to;
        }

        this.holder = GUI.$(this.holder);
        if (!this.holder) {
            console.log('Holder is null.');
            return 'No holder';
        }
        if (GUI.isSet(before)) {
            if (GUI.isNumber(before)) {
                before = this.holder.childNodes[before];
                if (before && before.nodeType === 3) {
                    before = null;
                }
            } else {
                before = GUI.$(before);
            }
        }

        if (this.containerCls) {
            this.holder.addClass(this.containerCls);
        }

        // Generate markup
        var res = this.onRender();

        // That's all if we use partial render

        // Insert rendered element into the dom tree
        if (GUI.isSet(res)) {
            if (before) {
                if (GUI.isString(res)) {
                    // Renderer returned html string
                    this.dom = GUI.Dom.insertBefore(before, res);
                } else {
                    // Renderer returned HTMLElement
                    this.dom = this.holder.insertBefore(res, before);
                }
            } else {
                if (GUI.isString(res)) {
                    // Renderer returned html string
                    this.dom = GUI.Dom.append(this.holder, res); // LEAKS IN IE6!
                } else {
                    // Renderer returned HTMLElement
                    this.dom = this.holder.appendChild(res);
                }
            }
        } else {
            if (!this.dom) {
                throw new Error('Component.render(): no this.dom');
            }
        }
        GUI.Dom.extend(this.dom);

        this.rendered = true;
        this.inDom = true;
        this.fireEvent('render', this);
        this.onAfterRender(this.dom);
        this.onPostAfterRender();
        this.attachEventListeners();
        this.fireEvent('afterrender', this);
        if (this.hidden) {
            this.hide();
        }

        if (this.disabled) {
            this.disable();
        }
        return this;
    };

    /**
     * empty function
     */
    GUI.Component.prototype.onPostAfterRender = function () {};

    /**
     * Removes dom
     * @param {Boolean} quick
     */
    GUI.Component.prototype.unrender = function (quick) {
        this.removeEventListeners();
        if (!quick) {
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
        this.rendered = this.inDom = false;
    };

    /**
     * empty function
     */
    GUI.Component.prototype.assign = function (to) {
        //
    };

    /**
     * Disable this component.
     * @returns {GUI.Component} this
     */
    GUI.Component.prototype.disable  = function () {
        if (this.rendered) {
            this.onDisable();
        }
        this.disabled = true;
        this.fireEvent('disable', this);
        return this;
    };

    /**
     * Enable this component.
     * @returns {Ext.Component} this
     */
    GUI.Component.prototype.enable  = function () {
        if (this.rendered) {
            this.onEnable();
        }
        this.disabled = false;
        this.fireEvent('enable', this);
        return this;
    };

    /**
     * Focused dom
     * @returns {Ext.Component} this
     */
    GUI.Component.prototype.focus = function () {
        if (this.dom) {
            try {
                this.dom.focus();
            } catch (e) {}
        }
        return this;
    };

    /**
     * Focus defer
     * @param {Number} delay
     * @returns {Ext.Component} this
     */
    GUI.Component.prototype.deferFocus = function (delay) {
        delay = (delay !== null) ? delay : this.deferFocusTime;
        this.focus.defer(delay, this);
        return this;
    };

    /**
     * Removes focus from the dom
     * @returns {Ext.Component} this
     */
    GUI.Component.prototype.blur = function () {
        if (this.dom) {
            try {
                this.dom.blur();
            } catch (e) {}
        }
        return this;
    };

    /**
    * Show this component.
    * @returns {Ext.Component} this
    */
    GUI.Component.prototype.show = function () {
        if (this.fireEvent('beforeshow', this) === false) {
            return this;
        }
        this.hidden = false;
        /*if (this.autoRender) {
            this.render(typeof this.autoRender == 'boolean' ? Ext.getBody() : this.autoRender);
        }*/
        if (this.rendered) {
            this.onShow();
        }
        this.fireEvent('show', this);
        return this;
    };

    /**
    * Hide this component.
    * @returns {Ext.Component} this
    */
    GUI.Component.prototype.hide = function () {
        if (this.fireEvent("beforehide", this) === false) {
            return this;
        }
        this.hidden = true;
        if (this.rendered) {
            this.onHide();
        }
        this.fireEvent('hide', this);
        return this;
    };

    /**
     * Sets hint
     * @param {String} v Text of the hint
     * @returns {Ext.Component} this
     */
    GUI.Component.prototype.setHint = function (v) {
        this.hint = v;
        if (this.dom) {
            GUI.Popup.Hint.add(this.dom, v);
        }
        return this;
    };

    /**
     * Returns true if dom is disabled
     * @returns {Boolean}
     */
    GUI.Component.prototype.isDisabled = function () {
        return this.disabled;
    };

    /**
     * Returns true if dom is enabled
     * @returns {Boolean}
     */
    GUI.Component.prototype.isEnabled = function () {
        return !this.disabled;
    };

    /**
     * Returns the id of this component.
     * @returns {String}
     */
    GUI.Component.prototype.getId = function () {
        return this.id || (this.id = "jsgui-cmp-" + (++GUI.Component.AUTO_ID));
    };

    /**
     * Returns the dom
     * @returns {HTMLElement} dom
     */
    GUI.Component.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Adds plugin into array of the plugins
     * @param {Object} obj Plugin
     */
    GUI.Component.prototype.addPlugin = function (obj) {
        this.plugins.push(obj);
    };

    /**
     * Removes plugin from array of the plugins
     * @param {Object} pl Plugin
     */
    GUI.Component.prototype.removePlugin = function (pl) {
        if (this.plugins.remove(pl)) {
            pl.destroy();
            return true;
        }
        return false;
    };

    /**
     * Returns plugin by name
     * @returns {Object} className Name of the plugin
     */
    GUI.Component.prototype.getPlugin = function (className) {
        var plugin, i, len,
            plugins = this.plugins;
        for (i = 0, len = plugins.length; i < len; i++) {
            plugin = plugins[i];
            if (plugin instanceof className) {
                return plugin;
            }
        }
        return null;
    };

    /**
     * Returns plugin by name
     * @returns {Object} className Name of the plugin
     */
    GUI.Component.prototype.getPlugins = function (className) {
        var i, len, plugin,
            plugins = this.plugins,
            res = [];
        for (i = 0, len = plugins.length; i < len; i++) {
            plugin = plugins[i];
            if (plugin instanceof className) {
                res.push(plugin);
            }
        }
        return res;
    };

    /**
     * empty function
     */
    GUI.Component.prototype.initComponent = function () {};

    /**
     * Renders component outside of the dom tree. Returns component's
     * html string or html element to be inserted in the document's dom
     * tree later
     * @returns {String/HTMLElement}
     */
    GUI.Component.prototype.onRender = function () {
        return '<div></div>';
    };

    /**
     * Does operations that require component to be appended to the document's
     * dom tree like attaching events, size calculations, getting css
     * properties, etc.
     */
    GUI.Component.prototype.onAfterRender = function () {
        this.inDom = true;
        if (GUI.isSet(this.hint)) {
            GUI.Popup.Hint.add(this.dom, this.hint);
        }
    };

    /**
     * empty function
     */
    GUI.Component.prototype.onBeforeDestroy = function () {};

    /**
     * Removes events listeners, destroy dom
     * @param {Boolean} fast
     */
    GUI.Component.prototype.onDestroy = function (fast) {
        if (this.dom) {
            if (this.dom.un) {
                this.dom.un();
            }
            if (!fast) {
                GUI.destroyNode(this.dom);
            }
        }
        this.holder = this.dom = null;
    };

    /**
     * empty function
     */
    GUI.Component.prototype.attachEventListeners = function () {};

    /**
     * empty function
     */
    GUI.Component.prototype.removeEventListeners = function () {};

    /**
     * Add css class 'disabled'
     */
    GUI.Component.prototype.onDisable  = function () {
        this.dom.addClass(this.disabledClass);
    };

    /**
     * Removes css class 'disabled'
     */
    GUI.Component.prototype.onEnable  = function () {
        this.dom.removeClass(this.disabledClass);
    };

    /**
     * Removes css class 'hide'
     */
    GUI.Component.prototype.onShow  = function () {
        if (this.hideParent) {
            //this.holder.removeClass('x-hide-' + this.hideMode);
            this.holder.style.display = '';
        } else {
            // this.dom.removeClass('x-hide-' + this.hideMode);
            this.dom.style.display = '';
        }
    };

    /**
     * Add css class 'hide'
     */
    GUI.Component.prototype.onHide  = function () {
        if (this.hideParent) {
            //this.holder.addClass('x-hide-' + this.hideMode);
            this.holder.style.display = 'none';
        } else {
            //this.dom.addClass('x-hide-' + this.hideMode);
            this.dom.style.display = 'none';
        }
    };

}());