/**
 *
 */
(function () {
    window.searchWidget = window.Class.create();

    prototype = {
        //
        initialize: function () {
            this.searchWidget = GUI.$('search-widget');

            this.isSearchComplete = false;

            this.channel = null;
            var channelName = '';

            this.timeoutShowLayer = false;
            this.timeoutHideLayer = false;

            this.ticketForm = null;

            if (GUI.$('search-widget-space-key')) {
                this.spaceKey = GUI.$('search-widget-space-key').value;
            }

            this.placeholderTexts = {
                search      : i18n('Search...'),
                questions   : i18n('Ask your question...'),
                ideas       : i18n('Share your idea...'),
                problems    : i18n('Report your problem...'),
                praise      : i18n('Give your praise...'),
                ticket      : i18n('Request')
            };

            this.feedbackWidget = this.is_in_frame();

            this.searchWidget = GUI.$('search-widget');

            this.continueButton = GUI.$('searchButtonContinue');
            this.searchAgainButton = GUI.$('searchButtonAgain');

            this.searchField = GUI.$('search-widget-input');
            this.searchFieldCancel = GUI.$('text-field-icon-clean');
            this.searchHintBlock = GUI.$('search-field-hint');
            this.searchResults = GUI.$('searchWidgetResults');
            this.topsBlock = GUI.$('feedbackTopBlocks');
            this.widgetDescriptions = GUI.$('widgetDescriptions');
            //this.emptyWidgetSpace = GUI.$('empty-widget-space');

            this.widgetSearchBar = GUI.$('search-widget-bar');

            this.selectedButton = this.searchWidget.findDescedent('a.btn_tab_grey.selected') || this.searchWidget.findDescedents('a.btn_tab_grey')[0];
            channelName = this.getChannelName(this.selectedButton);

            if (channelName !== 'search') {
                this.channel = channelName;
            }

            this.setPlaceHolder(this.placeholderTexts[channelName]);

            if (GUI.$('search-widget-combo-spaces')) {
                this.spaceCombo = new GUI.Forms.Combo({alignTo: 'tr-br?'});
                this.spaceCombo.assign('search-widget-combo-spaces');
            }


            this.addEvents();

            this.mask = new GUI.Popup.Mask({
                element : this.searchWidget,
                text    : i18n('Loading...')
            });

            this.onHistoryChange(location.hash.replace('#', ''));

            if (GUI.isMobile) {
                var meta = document.createElement('meta');
                meta.name = 'viewport';
                meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
        },

        onHistoryChange: function (url) {
            var link;

            if (!url) {
                return;
            }

            if (url === 'support') {
                this.goToAddTicket();
                return;
            }

            link = GUI.$('search-button-' + url);

            if (!link) {
                return;
            }

            this.nameSearchButton = url;

            if (this.selectedButton) {
                this.selectedButton.removeClass('selected');
            }

            link.addClass('selected');
            this.selectedButton = link;
            this.clickButton(url);
        },

        is_in_frame: function () {
            if (top === self) {
                return false;
            } else {
                return true;
            }
        },

        addEvents: function () {
            this.searchWidget.on('click', this.onClickButtons, this);
            this.spaceCombo.on('change', this.onChangeCombo, this);

            this.searchField.on('keypress', this.onKeyPress, this);

            this.searchField.on('change', this.onSearchFieldChange, this);
            this.searchField.on('keyup', this.onSearchFieldChange, this);
            this.searchField.on('paste', this.onSearchFieldChange, this);
            this.searchField.on('input', this.onSearchFieldChange, this);
            this.searchField.on('mouseup', this.onSearchFieldChange, this);

            if (this.feedbackWidget) {
                GUI.History.addEventListener('historyChange', this.onHistoryChange.bindLegacy(this));

                this.topicRegion = new GUI.Popup.Region({
                    className: 'layer btn_super-hover '
                });

                this.layers = new GUI.Popup.Layer({
                    target      : document.body,
                    layer       : this.topicRegion,
                    offset      : [0, 6],
                    position    : 'tl-bl?',
                    delayShow   : 500,
                    delayHide   : 200,
                    disableProcessEvent: {
                        onMouseEnter: true,
                        onMouseLeave: true
                    }
                });

                // this.layers.on('onMouseMove', this.onLayerMouseMove, this);
                // this.layers.on('onShowLayer', this.onShowLayer, this);
                // this.layers.on('onHideLayer', this.onHideLayer, this);
            }
        },

        onLayerMouseMove: function (e) {
            var target = e.target,
                isCorrectTarget = target.nodeName === 'A' && target.parentNode.hasClass('topic-list__line'),
                isDescendant = (!this.topicRegion.dom ? false : GUI.Dom.isDescendant(this.topicRegion.dom, target)),
                isDescendantInParentLayer = (VotingController.layers && VotingController.layers.isShowed()) ?
                    GUI.Dom.isDescendant(VotingController.layers.layer.dom, target) :
                        false;

            if (isCorrectTarget || isDescendant) {
                this.layers.stopTimerForHideLayer();

                if (this.currentLayerTarget !== target) {
                    if (this.currentLayerTarget && isCorrectTarget && this.layers.isShowed()) {
                        this.currentLayerTarget = target;
                        VotingController.layers.hideLayer();
                        this.onShowLayer();
                    } else {
                        this.layers.startTimerForShowLayer();
                        this.layers.stopTimerForHideLayer();
                    }
                }

                this.currentLayerTarget = target;
            } else if (this.currentLayerTarget && !isCorrectTarget && !isDescendant && !isDescendantInParentLayer) {
                this.layers.startTimerForHideLayer();
            }
        },

        onShowLayer: function () {
            var node = this.currentLayerTarget;

            if (!node) {
                this.layers.hideLayer();
                return;
            }

            var href = node.href.split('/'),
                id = href[href.length - 1].split('-')[0].replace('i', ''),
                layer = GUI.$('top-layer-' + id);

            this.topicRegion.setContent(layer.innerHTML);
            GUI.show(this.topicRegion.dom);
            this.topicRegion.dom.setStyle({zIndex: 0});

            this.layers.setPosition(null, node);
            VotingController.init();
            VotingController.layerOffset = [0, 0];
            VotingController.voteRegion.config.parentNode = this.topicRegion.dom;
        },

        onHideLayer: function () {
            this.currentLayerTarget = null;
            VotingController.layers.hideLayer();
        },

        removeEvents: function () {
            this.searchWidget.un('click', this.onClickButtons, this);
            this.searchField.un('keypress', this.onKeyPress, this);
        },

        getChannelName: function (el) {
            var name = '';

            if (!el) {
                return name;
            }

            name = el.getAttribute('name') || '';

            if (name) {
                name = name.toLowerCase().replace(/search-button[\-]?/, '');
            }

            if ('ticket' === name
                && window.enabledChannels.length > 0
            ) {
                name = window.enabledChannels[0];
            }

            return name;
        },

        onChangeCombo: function (e, value, oldValue) {
        //     if (!this.isSearchComplete) {
        //         if (this.isSearch()) {
        //             this.getTopItems('s' + value);
        //         } else {
        //             this.getChannelItems(this.channel);
        //         }
        //     }
        },

        onClickButtons: function (e) {
            var target, link,
                channelName = '';

            if (e) {
                e = GUI.Event.extend(e);
                if (GUI.findParentByTag(e.target, 'ul', this.searchWidget, 'topic-list')) {
                    return;
                }
            }

            target = e.target;

            if (target && target.hasClass('text-field__icon close')) {
                this.clearSearchValue();
                return;
            }

            link = GUI.findParentByTag(target, 'a', this.searchWidget, 'btn');

            channelName = this.getChannelName(link);

            // if tab
            if (channelName === 'continue') {
                this.clickButton(channelName);

            } else if (channelName !== '') {

                this.nameSearchButton = channelName;

                if (this.selectedButton) {
                    this.selectedButton.removeClass('selected');
                }

                link.addClass('selected');
                this.selectedButton = link;
                this.clickButton(channelName, e);
            }
        },

        clickButton: function (channelName, e) {
            var value = '';

            if (channelName === 'continue') {
                if (this.isPraise()) {
                    this.goToAddItem();
                    return;
                }

                this.search();
            } else {
                this.channel = (channelName !== 'search') ? channelName : null;
                GUI.show(this.widgetSearchBar);
                this.setPlaceHolder(this.placeholderTexts[channelName]);

                value = this.getValue();

                if (value.length >= 1 && !this.isPraise() && this.feedbackWidget) {
                    this.getTopItems();
                } else {
                    this.searchResults.innerHTML = '';

                    GUI.show(this.continueButton);
                    GUI.hide(this.searchAgainButton);

                    if (this.feedbackWidget) {
                        if (channelName === 'ticket') {
                            // Ignore
                        } else if (channelName !== 'search') {
                            this.getChannelItems(channelName);
                        } else {
                            this.searchResults.innerHTML = '';
                            this.searchResultsHasChannels = false;
                            this.showTops();
                            VotingController.init();
                        }
                    }
                }
            }
        },

        goToAddTicket: function () {
            var url = route(
                this.feedbackWidget ? 'addTicketFeedback' : 'addTicket',
                {}
            );

            if (this.feedbackWidget && this.spaceKey) {
                url += '?feedbackSpace=' + this.spaceKey;
            }

            window.location= url;
        },

        onSearchFieldChange: function () {
            if(this.searchField.value) {
                this.searchFieldCancel.style.display = 'block';
            } else {
                this.searchFieldCancel.style.display = 'none';
            }

        },

        onKeyPress: function (e) {
            if (e.keyCode === 13 && !this.isPraise()) {
                this.search();
            } else if (this.isPraise() && e.keyCode === 13) {
                this.goToAddItem();
            }
        },

        getValue: function () {
            var inp = this.searchField,
                ph = inp.getAttribute('placeholder');
            return (inp.value !== ph) ? inp.value : '';
        },

        setValue: function (value) {
            this.searchField.value = value || '';
        },

        setPlaceHolder: function (placeholderText) {
            Placeholders.setPlaceholder(this.searchField, placeholderText || '');
        },

        clearSearchValue: function () {
            this.setValue('');
            if (!this.searchResultsHasChannels) {
                this.startOver();
            }
        },

        getSpace: function (index, param) {
            var space = this.spaceKey;

            if (this.spaceCombo) {
                if (!index) {
                    index = this.spaceCombo.selectedIndex;
                }

                if (index && this.spaceCombo.options.items[index]) {
                    if (param) {
                        space = this.spaceCombo.options.items[index][param];
                    } else {
                        space = this.spaceCombo.options.items[index].value;
                    }
                } else {
                    space = this.spaceCombo.getValue();
                }
            }
            return space;
        },

        search: function () {
            // if (!window.isCommunityEnabled) {
            //     this.goToSearchPage();
            //     return;
            // }

            var data  = {},
                space = this.getSpace(),
                value = this.getValue();

            if (value.length < 1) {
                return;
            }

            if (this.feedbackWidget) {
                this.mask.show();
            }

            if (GUI.isSmallMobile) {

                this.searchField.setAttribute('readonly', true);
                setTimeout(function () {
                    this.searchField.removeAttribute('readonly');
                }.bindLegacy(this), 0);
            }

            GUI.hide(this.continueButton);
            GUI.show(this.searchAgainButton);

            data.q = value;
            data.channel = this.channel;
            data.pageSize = (this.feedbackWidget) ? 7 : 5;
            data.space = (space !== 'all') ? space : null;

            this.request = new GUI.Ajax.Request(
                route((this.feedbackWidget ? 'searchFeedback' : 'search'), {page: 1}) + '?format=json',
                {
                    method      : 'POST',
                    data        : data,
                    onSuccess   : this.onSuccessSearch.bindLegacy(this)
                }
            );
        },

        onSuccessSearch: function (xhr, request) {
            var response = xhr.responseText;

            if (this.feedbackWidget) {
                this.mask.hide();
            }

            if (request && request.responseJson && request.responseJson.results === 0) {
                if (this.channel) {
                    this.goToAddItem();
                } else {
                    this.startOver();
                }
            } else if (response) {
                this.isSearchComplete = true;
                this.renderResults(response, request);
            }
        },

        renderResults: function (resultsData) {
            var resultCount, continueAddItemButton,
                resultTemp = document.createElement('div');

            if (this.isSearch()) {
                //this.setColorSearchAgain('blue');
            } else {
                resultTemp.innerHTML = resultsData;
                resultCount = parseInt(resultTemp.querySelector('#search-result-count').innerHTML, 10);

                if (!resultCount
                    && 'updates' !== this.channel
                ) {
                    this.goToAddItem();
                    return;
                } else {
                    //this.setColorSearchAgain('gray');
                }
            }

            this.hideTops();
            this.searchResults.innerHTML = resultsData;
            this.searchResultsHasChannels = false;
            VotingController.init();

            continueAddItemButton = GUI.$('searchContinueAddItemBtn');
            if (continueAddItemButton) {
                GUI.hide(continueAddItemButton);
                GUI.hide(continueAddItemButton.parentNode);
                if (this.channel) {
                    GUI.show(continueAddItemButton.parentNode);
                    GUI.show(continueAddItemButton);
                }
            }

            GUI.show(this.searchResults);
        },

        hideTops: function () {
            if (this.topsBlock) {
                GUI.hide(this.topsBlock);
            }
        },

        showTops: function () {
            if (this.topsBlock) {
                GUI.show(this.topsBlock);
            }
        },

        getChannelItems: function (channelType) {
            var space     = this.getSpace(),
                spaceName = this.getSpace(false, 'spacename');

            if (this.feedbackWidget) {
                this.mask.show();
            }

            new GUI.Ajax.Request(
                route(
                    'feedback',
                    {space: space, name: spaceName, channel : channelType}
                ),
                {
                    onSuccess : this.onLoadChannelItems.bindLegacy(this)
                }
            );
        },

        onLoadChannelItems: function (xhr, request) {
            var response = xhr.responseText;

            if (this.feedbackWidget) {
                this.mask.hide();
            }

            if (response) {
                this.hideTops();
                this.searchResults.innerHTML = response;
                this.searchResultsHasChannels = true;
                VotingController.init();
                GUI.show(this.searchResults);
            }
        },

        goToAddItem: function (channelName) {
            channelName = channelName || this.channel || '';

            if (!channelName) {
                return;
            }

            var routeName, url,
                space     = this.getSpace(),
                spaceName = this.getSpace(false, 'spacename');

            if (space === 'all') {
                space = this.getSpace(1);
                spaceName = this.getSpace(1, 'spacename');
            }

            if (this.feedbackWidget) {
                routeName = 'addItemFeedback';
            } else {
                routeName = 'addItem';
            }

            url = route(routeName, {
                space   : space,
                name    : spaceName,
                channel : channelName
            });

            url += '?title=' + encodeURIComponent(this.getValue());

            if (this.feedbackWidget && this.spaceKey) {
                url += '&feedbackSpace=' + this.spaceKey;
            }

            window.location = url;
        },

        startOver: function () {
            this.isSearchComplete = false;
            GUI.hide(this.searchResults);
            GUI.show(this.continueButton);
            GUI.hide(this.searchAgainButton);

            this.setValue();

            if (this.feedbackWidget) {
                if (this.channel) {
                    this.getChannelItems(this.channel);
                } else {
                    this.showTops();
                }
            }
        },

        goToSearchPage: function () {
            var url = route('search', {}) + '?';

            // space filter
            if ('all' !== this.spaceCombo.getValue()) {
                url += 'space=' + this.spaceCombo.getValue() + '&';
            }

            // channel filter
            // Need ignore channel by task CHD-1954 2
//            if (this.channel) {
//                url += 'channel=' + this.channel + '&';
//            }

            if (this.getValue()) {
                url += 'q=' + this.getValue();
            } else {
                url += 'q=*';
            }

            if (this.feedbackWidget) {
                window.open(url, "_blank");
            } else {
                window.location = url;
            }
        },

        isPraise: function () {
            return this.channel === 'praise';
        },

        isSearch: function () {
            return this.channel === 'search' || this.channel === null;
        },

        getTopItems: function (space) {
            if (this.feedbackWidget) {
                //this.setColorSearchAgain('blue');
                var request = new GUI.Ajax.Request(
                    route('feedback', {
                        spaceName: space || this.space || 'all',
                        channel: this.channel
                    }),
                    {
                        onSuccess: this.onLoadTopItems.bindLegacy(this)
                    }
                );

                this.mask.show();
            }
        },

        onLoadTopItems: function (xhr, request) {
            this.topsBlock.innerHTML = xhr.response;
            GUI.hide(this.searchResults);
            GUI.show(this.topsBlock);

            GUI.hide(this.searchAgainButton);
            GUI.show(this.continueButton);

            if (this.feedbackWidget) {
                this.mask.hide();
                this.isSearchComplete = false;
            }
        },

        setColorSearchAgain: function (color) {
            var btn = this.searchAgainButton.childNodes[1];
            GUI.Dom.removeClass(btn, 'btn-blue');
            GUI.Dom.removeClass(btn, 'btn-gray');

            GUI.Dom.addClass(btn, 'btn-' + color);
        }
    };

    Object.extend(window.searchWidget.prototype, prototype);
}());

GUI.onDOMReady(function () {
    if (GUI.$('search-widget')) {
        window.search = new window.searchWidget();
    }
});
