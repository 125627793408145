(function() { // variables isolation

    var ConfirmFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        _showed        : false,
        _dialog        : false,
        _disableReload : false,
        _popupWindow   : false,
        _popupHandler  : false,
        _formMask      : false,
        _dialog_id     : '',
        _form_id       : '',
        _confirmForm     : {
            holder         : null,
            form           : null
        },
        _actionUrl     :false,
        _setupFormElementListeners : function() {
            this._confirmForm.holder       = GUI.$(this._dialog_id);
            this._confirmForm.form         = GUI.$(this._form_id);

            this._confirmForm.form.on('submit', this._onConfirmFormSubmit, this);
        },

        _onConfirmFormSubmit : function(event) {
            this._formMask.show();

            event.preventDefault();

            window.location.href = this._actionUrl;
        },

        initialize : function() {
            this.addEvents({
                show : true,
                hide : true
            });
        },

        show : function(disableReload) {
            if (!this._showed) {
                this._disableReload = disableReload === true; // as the first parameter may be an event
                                                              // check var for equivalense to true
                this._dialog.close();

                this._dialog.onShow = (function() {
                    GUI.show(this._confirmForm.holder);
                    this._showed = true;
                    this.fireEvent('show');
                }).bindLegacy(this);

                this._dialog.onClose = (function() {
                    GUI.hide(this._confirmForm.holder);
                    this._showed = false;
                    this.fireEvent('hide');
                }).bindLegacy(this);

                this._dialog.show();
            }
        },

        close : function() {
            if (this._showed) {
                this._dialog.close();
            }
        },

        applyShowOn : function(node) {
            if (typeof(node) == 'string')
                node = GUI.$(node);

            node.on('click', this.show, this);
        },

        unapplyShowOn : function(node) {
            if (typeof(node) == 'string')
                node = GUI.$(node);

            node.un('click', this.show, this);
        },

        setActionUrl : function (url) {
            this._actionUrl = url;
        },

        setCallerElement : function (element, message, common_dialog_class, dialog_id, form_id) {
            this._callerElement = element;
            this._callerMessage = message;
            this._dialog_id = dialog_id;
            this._form_id = form_id;
            this._common_dialog_class = common_dialog_class;

            this._dialog = new Application.CommonDialogClass(this._common_dialog_class);

            if (!GUI.$(this._form_id)) {
                return;
            }

            this._setupFormElementListeners();

            this._formMask = new GUI.Popup.Mask({className : 'mask', animate : false, stylePosition: 'absolute'});
            this._formMask.setElement(this._dialog.getContentHolder());
        }
    };

    Object.extend(ConfirmFormClass.prototype, superproto);
    Object.extend(ConfirmFormClass.prototype, prototype);

    Application.ConfirmForm = new ConfirmFormClass();
}());