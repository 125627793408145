(function () {
    var superproto = '';
    /**
     * JavaScript Graphical User Interface
     * Panel implementation
     *
     * class GUI.Panel
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Container
     */
    GUI.Panel = Class.create();

    /**
     * Inheritance from GUI.Utils.Container
     */
    superproto = GUI.Utils.Container.prototype;
    Object.extend(GUI.Panel.prototype, superproto);

    /**
     * Default is 'jsgui-panel'
     * @type String
     */
    GUI.Panel.prototype.baseClass = '';

    /**
     * Default is 'jsgui-panel-header'
     * @type String
     */
    GUI.Panel.prototype.headerClass = '';

    /**
     * Default is 'jsgui-panel-bodywrap'
     * @type String
     */
    GUI.Panel.prototype.bodyWrapClass = '';

    /**
     * Default is 'jsgui-panel-topbar'
     * @type String
     */
    GUI.Panel.prototype.topBarClass = '';

    /**
     * Default is 'jsgui-panel-body'
     * @type String
     */
    GUI.Panel.prototype._bodyClass = '';

    /**
     * Default is 'jsgui-panel-bottombar'
     * @type String
     */
    GUI.Panel.prototype.bottomBarClass = '';

    /**
     * Default is 'jsgui-toolbutton'
     * @type String
     */
    GUI.Panel.prototype.toolButtonClass = '';

    /**
     * Default is 'jsgui-panel-collapsed'
     * @type String
     */
    GUI.Panel.prototype.collapsedClass = '';

    /**
     * Default is 'body'
     * @type String
     */
    GUI.Panel.prototype.parts = 'body';

    /**
     * Default is ''
     * @type String
     */
    GUI.Panel.prototype.caption = '';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Panel.prototype.collapsable = false;

    /**
     * Default is false. True to hide panel when it is collapsed
     * @type Boolean
     */
    GUI.Panel.prototype.collapseHides = false;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Panel.prototype.doubleClickToggles = true;

    /**
     * Default is false. True to automatally show scrolbars when content is overflowed
     * @type Boolean
     */
    GUI.Panel.prototype.scrollable = false;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Panel.prototype.collapsed = false;

    /**
     * Initializes objects.
     * Call parent method init
     */
    GUI.Panel.prototype.initComponent = function () {
        var item, tb, i, btn;
        this.toolbox = [];

        superproto.initComponent.call(this);

        if (this.header) {
            this.parts += ',header';
            delete this.header;
        } else if (this.caption && this.header !== false) {
            this.parts += ',header';
        }

        if (this.toolbox) {
            tb = this.toolbox;
            i = tb.length;

            this.toolButtons = {};
            while (i--) {
                item = tb[i];
                this.toolButtons[item.name] = item;
            }
        }

        if (this.collapsable && !this.toolButtons.toggle) {
            btn = {
                scope   : this,
                handler : this.toggle,
                name    : 'toggle'
            };
            this.toolButtons.toggle = btn;
            this.toolbox.push(btn);
        }
    };

    /**
     * Toggle element.Depending on the value collapsed call methods expand or collapse
     * @returns {Function} method response of method
     */
    GUI.Panel.prototype.toggle = function () {
        return this[this.collapsed ? 'expand' : 'collapse']();
    };

    /**
     * Fire event 'beforecollapse'.
     * Call method onCollapse
     * @returns {Boolean} false if event returns false, else true
     */
    GUI.Panel.prototype.collapse = function () {
        if (this.collapsed || (this.fireEvent('beforecollapse', this) === false)) {
            return false;
        }
        this.onCollapse();
        return true;
    };

    /**
     * If the animation is off call onAfterCollapse
     * @param {Boolean} animate
     */
    GUI.Panel.prototype.onCollapse = function (animate) {
        if (!animate) {
            this.onAfterCollapse();
        }
    };

    /**
     * Sets the value of collapsed is true, hide 'collapse' element.
     * Add css class 'jsgui-panel-collapsed'.
     * Fire event 'collapse'
     */
    GUI.Panel.prototype.onAfterCollapse = function () {
        this.collapsed = true;
        GUI.hide(this[this.collapseEl]);
        this.dom.addClass(this.collapsedClass);
        this.fireEvent('collapse', this);
    };

    /**
     * Fire event 'beforeexpand'.
     * Call method onExpand
     * @returns {Boolean} false if event returns false, else returns true
     */
    GUI.Panel.prototype.expand = function () {
        if (!this.collapsed || (this.fireEvent('beforeexpand', this) === false)) {
            return;
        }
        this.onExpand();
        return true;
    };

    /**
     * If the animation is off remove class 'jsgui-panel-collapsed',
     * show 'collapse' element, call method onAfterExpand
     * @param {Boolean} animate
     */
    GUI.Panel.prototype.onExpand = function (animate) {
        if (!animate) {
            this.dom.removeClass(this.collapsedClass);
            GUI.show(this[this.collapseEl]);
            this.onAfterExpand();
        }
    };

    /**
     * Sets the value of collapsed is true.
     * Fire event 'expand'
     */
    GUI.Panel.prototype.onAfterExpand = function () {
        this.collapsed = false;
        this.fireEvent('expand', this);
    };

    /**
     * Returns html markup for toolbox buttons
     * @returns {String} html
     */
    GUI.Panel.prototype.getToolboxMarkup = function () {
        var i, item,
            html  = new GUI.StringBuffer(),
            tb = this.toolbox,
            len = tb.length,
            str = '';

        for (i = 0; i < len; i++) {
            item = tb[i];
            html.append('<a href="#" class="' + this.toolButtonClass + ' jsgui-toolbutton-' + item.name + '">&#160;</a>');
        }
        return html + str;
    };

    /**
     * Returns html element of button
     * @param {String} name Name of the button
     * @returns {HTMLElement|null} element
     */
    GUI.Panel.prototype.getToolButtonEl = function (name) {
        return this.headerEl ? GUI.Dom.findChild(this.headerEl, 'a.jsgui-toolbutton-' +  name) : null;
    };

    /**
     * Add css class to button
     * @param {String} name Name of the button
     * @param {String} cls Css class
     */
    GUI.Panel.prototype.addToolButtonClass = function (name, cls) {
        var btn = this.getToolButtonEl(name);
        if (btn) {
            GUI.Dom.addClass(btn, cls);
        }
    };

    /**
     * Show dom of the button
     * @param {String} name Name of the button
     */
    GUI.Panel.prototype.showToolButton = function (name) {
        var btn = this.getToolButtonEl(name);
        if (btn) {
            GUI.show(btn);
        }
    };

    /**
     * Hide dom of the button
     * @param {String} name Name of the button
     */
    GUI.Panel.prototype.hideToolButton = function (name) {
        var btn = this.getToolButtonEl(name);
        if (btn) {
            GUI.hide(btn);
        }
    };

    /**
     * Render html
     * @returns {String} html
     */
    GUI.Panel.prototype.onRender = function () {
        var html = new GUI.StringBuffer(),
            str = '', // empty string
            style = '';

        // begin
        html.append('<div id="' + this.id + '" class="' + this.baseClass + '">');

        // header
        if (this.parts.indexOf('header') !== -1) {
            html.append('<div class="' + this.headerClass + '">');
            if (this.toolbox) {
                html.append(this.getToolboxMarkup());
            }
            html.append('<span >' + this.caption + '</span>');
            html.append('</div>');
        }

        // body
        html.append('<div class="' + this.bodyWrapClass + '">');

        // top bar
        if (this.topBar) {
            html.append('<div class="' + this.topBarClass + '"></div>');
        }

        // style
        style = this.style || '';

        // body
        html.append('<div style="' + style + '" class="' + this._bodyClass + (this.bodyClass ? ' ' + this.bodyClass : '') + '">');
        if (this.content) {
            html.append(this.content);
        }
        html.append('</div>');

        // bottom bar
        if (this.bottomBar) {
            html.append('<div class="' + this.bottomBarClass + '"></div>');
        }

        // end
        html.append('</div></div>');

        return html + str;
    };

    /**
     * Create objects and add events to them
     * @param {HTMLElement} el
     */
    GUI.Panel.prototype.onAfterRender = function (el) {
        var bodyWrap, cur;
        // header
        if (this.parts.indexOf('header') !== -1) {
            this.headerEl = GUI.Dom.extend(el.firstChild);
            bodyWrap = this.headerEl.nextSibling;

            GUI.unselectable(this.headerEl);
            this.headerEl.on('click', this.onHeaderClick, this);
            if (this.collapsable && this.doubleClickToggles) {
                this.headerEl.on('dblclick', this.toggle, this);
            }
        } else {
            bodyWrap = el.firstChild;
        }

        this.bodyWrapEl = bodyWrap;
        this.setCollapseHides(this.collapseHides);

        // top bar
        if (this.topBar) {
            this.topBarEl = bodyWrap.firstChild;
            this.bodyEl = this.topBarEl.nextSibling;

            if (GUI.isString(this.topBar)) {
                this.topBarEl.innerHTML = this.topBar;

            } else if (GUI.isArray(this.topBar)) {
                this.topBar = new GUI.ToolBar({
                    align   : 'none',
                    elements: this.topBar
                });
                this.topBar.render(this.topBarEl);

            } else if (this.topBar.render) {
                this.topBar.render(this.topBarEl);
            }
            this.toolbar = this.topBar;
        } else {
            this.bodyEl = bodyWrap.firstChild;
        }

        // bottom
        if (this.bottomBar) {
            this.bottomBarEl = this.bodyEl.nextSibling;
            this.bottomBar.render(this.bottomBarEl);
        }

        // scrollable
        if (this.scrollable) {
            this.bodyEl.style.overflow = 'auto';
        }
    };

    /**
     * Removes event listeners and html markup of the component.
     * @param {Boolean} fast
     */
    GUI.Panel.prototype.onDestroy = function (fast) {
        this.headerEl = this.topBarEl = this.bodyEl = this.bottomBarEl = null;
        superproto.onDestroy.call(this, fast);
    };

    /**
     * Sets overflow parameter
     * @param {Boolean} scrollable If true, scrollable is auto
     */
    GUI.Panel.prototype.setScrollable = function (scrollable) {
        if (typeof scrollable === 'undefined') {
            scrollable = true;
        }
        this.scrollable = scrollable;
        if (this.bodyEl) {
            this.bodyEl.style.overflow =  (scrollable) ? 'auto' : '';
        }
    };

    /**
     * Sets "collapseEl" element
     * @param {String} v
     */
    GUI.Panel.prototype.setCollapseHides = function (v) {
        this.collapseEl  = (this.collapseHides = v) ? 'dom' : 'bodyWrapEl';
    };

    /**
     * Handler of click on the header
     * @param {Event} e
     */
    GUI.Panel.prototype.onHeaderClick = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
        var target = e.target,
            match = '',
            name = '',
            button = '',
            handler = '';

        if (target.nodeName === 'A') {
            match = target.className.match(this.toolButtonClass + '-(\\w+)');
            name = match && match[1];
            button = name && this.toolButtons[name];
            handler = button && button.handler;

            if (handler) {
                handler.call(button.scope, name);
            }
        }
    };

    /**
     * Returns height of the content
     * @returns {Number} height
     */
    GUI.Panel.prototype.getContentHeight = function () {
        if (this.dom) {
            return this.bodyEl.offsetHeight - 1;
        }
    };

    /**
     * Returns widthof the content
     * @returns {Number} width
     */
    GUI.Panel.prototype.getContentWidth = function () {
        if (this.dom) {
            return this.bodyEl.offsetWidth - 2;
        }
    };

    /**
     * Returns size of the content
     * @returns {Object} width, height
     */
    GUI.Panel.prototype.getContentSize = function () {
        return {
            width: this.getContentWidth(),
            height: this.getContentHeight()
        };
    };

    /**
     * Handler of resize of window
     * @param {String|Number} w width
     * @param {String|Number} h height
     */
    GUI.Panel.prototype.onResize = function (w, h) {
        if (w !== undefined || h !== undefined) {
            // Stretch body to accomodate dimensions
            if (typeof w === 'number') {
                var width = this.adjustBodyWidth(w - this.getFrameWidth());
                GUI.setFullWidth(this.bodyEl, width);
            } else if (w === 'auto') {
                this.bodyEl.style.width = w;
            }

            if (typeof h === 'number') {
                GUI.setFullHeight(this.bodyEl, this.adjustBodyHeight(h - this.getFrameHeight()));
            } else if (h === 'auto') {
                this.bodyEl.style.height = h;
            }
            this.fireEvent('bodyresize', this, w, h);
        }
    };

    /**
     * @param {String|Number} h Height
     */
    GUI.Panel.prototype.adjustBodyHeight = function (h) {
        return h;
    };

    /**
     * @param {String|Number} w Width
     */
    GUI.Panel.prototype.adjustBodyWidth = function (w) {
        return w;
    };

    /**
     * Returns frame width as 0
     * @returns {Number} width
     */
    GUI.Panel.prototype.getFrameWidth = function () {
        return 0;
    };

    /**
     * Returns frame height
     * @returns {Number} height
     */
    GUI.Panel.prototype.getFrameHeight = function () {
        var h = 0;
        if (this.headerEl) {
            h += this.headerEl.offsetHeight;
        }
        if (this.topBar) {
            h += this.topBarEl.offsetHeight;
        }
        if (this.bottomBar) {
            h += this.bottomBarEl.offsetHeight;
        }
        return h;
    };

    /**
     * Returns target of layout
     * @returns {HTMLElement} target
     */
    GUI.Panel.prototype.getLayoutTarget = function () {
        return this.bodyEl;
    };

}());