(function () {
    var superproto = GUI.Forms.Field.prototype;

    /**
     * JavaScript Graphical User Interface
     * Forms.CheckBox implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms
     * @extends GUI.Forms.Field
     */
    GUI.Forms.CheckBox = Class.create();
    Object.extend(GUI.Forms.CheckBox.prototype, superproto);

    /**
     * Type of the field, 'checkbox'
     * @type String
     */
    GUI.Forms.CheckBox.prototype.type = 'checkbox';

    /**
     * Css class name of the field, default is ''
     * @type String
     */
    GUI.Forms.CheckBox.prototype.className = '';

    /**
     * Css class that applied when the field is invalid, default is ''
     * @type String
     */
    GUI.Forms.CheckBox.prototype.invalidClass = '';

    /**
     * True if checkbox is checked
     * @param {Boolean} config
     */
    GUI.Forms.CheckBox.prototype.checked = false;

    /**
     * Constructor
     * @param {Object} config
     */
    GUI.Forms.CheckBox.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Check if the checkbox is checked
     * @return {Boolean} true - checked, false - unchecked
     */
    GUI.Forms.CheckBox.prototype.isChecked = function () {
        return (this.dom) ? this.dom.checked : this.checked;
    };

    /**
     * Set checked state, fire event 'change'
     * @param {Boolean} checked Checkbox state
     */
    GUI.Forms.CheckBox.prototype.setChecked = function (checked) {
        if (checked === true || checked === 'true' || checked === 'on') {
            this.checked = true;
        } else if (checked === false || checked === 'false' || checked === 'off') {
            this.checked = false;
        } else {
            this.checked = (checked === undefined) ? true : !!checked;
        }

        if (this.dom) {
            this.dom.checked = this.checked;
        }

        this.fireEvent('change', this, checked);
    };

    /**
     * Call parent attach events listeners
     */
    GUI.Forms.CheckBox.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
    };

    /**
     * Updates component's state from the specified html element
     * @param {HTMLElement} dom Dom element
     */
    GUI.Forms.CheckBox.prototype.onAssign = function (dom) {
        superproto.onAssign.call(this, dom);
        this.checked = dom.checked;
    };

    /**
     * Init field, call parent init field
     * @param {Object} fieldEl Field element
     */
    GUI.Forms.CheckBox.prototype.initField = function (fieldEl) {
        superproto.initField.call(this, fieldEl);
    };

    /**
     * After render field, add event 'click', set checked
     * @param {Object} fieldEl Field Element
     */
    GUI.Forms.CheckBox.prototype.onAfterRender = function (fieldEl) {
        superproto.onAfterRender.call(this, fieldEl);
        fieldEl.on('click', this.onClick, this);
        fieldEl.checked = this.checked; // must ba called after the element is inserted into the dom
    };

    /**
     * Handler click event, fire event 'change'
     * @param {Event} e Event
     */
    GUI.Forms.CheckBox.prototype.onClick = function (e) {
        this.checked = this.dom.checked;
        this.fireEvent('change', this, this.checked);
    };
}());