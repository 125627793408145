(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * JsonView implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.JsonView = Class.create();
    Object.extend(GUI.JsonView.prototype, superproto);

    GUI.JsonView.prototype.useWrapperEl = true;

    GUI.JsonView.prototype.showProxyBeforeMove = true;

    GUI.JsonView.prototype.dragPixelThresh = 3;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.JsonView.prototype.initialize = function (config) {
        var cfg, tplConstructor;
        this.config = {
            id          : GUI.getUniqId('jsonview-'),
            className   : '',
            preTpl      : '',
            rowTpl      : '',
            postTpl     : '',
            noDataTpl   : '',
            wrapAlways  : true,
            holder      : null,
            enableDd    : false,
            rowsIndex   : 'rows',
            unselectEl  : true,
            data        : {
                rows : []
            },
            visible        : true,
            customRenderer : null,
            useNewTemplateEngine: false,

            images      : {
                dropNo          : 'b-icon fa fa-ban fa_14 fa_white',
                dropBetween     : 'b-icon fa fa-arrows-v fa_14 fa_white',
                dropIn          : 'b-icon fa fa-plus fa_14 fa_font_19 fa_white',
                error           : 'b-icon fa fa-exclamation-circle fa_14 fa_white'
            }
        };

        cfg = this.config;
        Object.extend(cfg, config);

        this.visible = cfg.visible;

        tplConstructor = cfg.useNewTemplateEngine ? GUI.Template : GUI.STemplate;

        this.noDataTpl  = new tplConstructor(cfg.noDataTpl);
        this.preTpl     = new tplConstructor(cfg.preTpl);
        this.rowTpl     = new tplConstructor(cfg.rowTpl);
        this.postTpl    = new tplConstructor(cfg.postTpl);

        this.dpDropImgId = cfg.id + '-dragproxy-img';

        this.addEvents({
            render  : true,
            update  : true,
            filterDataRow   : true,
            beforeMove      : true
        });

        // Call parent method
        superproto.initialize.call(this, this.config);
    };

    /**
     * Calls unrender
     * @param {Boolean} fast
     */
    GUI.JsonView.prototype.destroy = function (fast) {
        this.unrender(fast);
        this.config.holder = null;
    };

    /**
     * Show view
     */
    GUI.JsonView.prototype.show = function () {
        if (!this.visible) {
            this.visible = true;
            if (this.dom) {
                GUI.show(this.dom);
            }
        }
    };

    /**
     * Hides view
     */
    GUI.JsonView.prototype.hide = function () {
        if (this.visible) {
            this.visible = false;
            if (this.dom) {
                GUI.hide(this.dom);
            }
        }
    };

    /**
     * Returns holder element
     * @returns {HTMLElement} holder
     */
    GUI.JsonView.prototype.getHolder = function () {
        return this.dom;
    };

    /**
     * Returns data object
     * @returns {Object} data
     */
    GUI.JsonView.prototype.getData = function () {
        return this.config.data;
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonView.prototype.getDom = function (e) {
        var target = e.getTarget();
        return GUI.findParentByTag(target, 'li');
    };

    /**
     * empty function
     */
    GUI.JsonView.prototype.attachEventListeners = function () {
        if (this.config.enableDd) {
            this.dom.on('mousedown', this.onMouseDown, this);
            this.dom.on('mousemove', this.onMouseMove, this);
            document.on('mouseup', this.onMouseUp, this);

            if (GUI.isMobile) {
                this.dom.on('touchstart', this.onMouseDown, this);
                this.dom.on('touchmove', this.onMouseMove, this);
                document.on('touchend', this.onMouseUp, this);
            }
        }
    };

    /**
     * empty function
     */
    GUI.JsonView.prototype.removeEventListeners = function () {};

    /**
     * Create dom elements, attach event listeners, fire event 'render'
     * @param {HTMLElement} to Element to render object to
     */
    GUI.JsonView.prototype.render = function (to) {
        var cfg, div;
        if (this.dom) {
            this.unrender();
        }

        cfg = this.config;
        if (to) {
            cfg.holder = to;
        } else {
            to = cfg.holder;
        }
        to = GUI.$(to);
        this.holder = to;

        if (this.useWrapperEl) {
            div = document.createElement('DIV');
            div.className = cfg.className;

            if (!this.visible) {
                GUI.hide(div);
            }
            to.appendChild(div);

            this.dom = div;
        } else {
            this.dom = to;
        }

        GUI.Dom.extend(this.dom);

        if (cfg.unselectEl) {
            GUI.unselectable(this.dom);
        }

        this.attachEventListeners();

        this.fireEvent('render', this);
    };

    /**
     * Removes event listeners
     * @param {Boolean} fast
     */
    GUI.JsonView.prototype.unrender = function (fast) {
        if (this.dom) {
            this.removeEventListeners();
            if (!fast) {
                GUI.destroyNode(this.dom);
            }
            this.dom = null;
        }
    };

    /**
     * Updates view with data
     * @param {Object} data Data for update
     */
    GUI.JsonView.prototype.update = function (data) {
        var html, rows, hasData, wrap, newTemplate, renderer, i, len, row;

        if (data) {
            this.config.data = data;
        } else {
            data = this.config.data;
        }
        if (!data[this.config.rowsIndex]) {
            console.log('Jsonview: No rows', this, data, this.config.rowsIndex);
            return;
        } else {
            data.rows = data[this.config.rowsIndex];
        }

        if (this.dom) {
            html = new GUI.StringBuffer();
            rows = data.rows;
            hasData = (rows.length > 0);
            wrap = hasData || this.config.wrapAlways;
            newTemplate = this.config.useNewTemplateEngine;

            if (wrap) {
                if (newTemplate) {
                    html.append(this.preTpl(data));
                } else {
                    this.preTpl.bind(data);
                    html.append(this.preTpl.fetch());
                }
            }

            if (hasData) {
                renderer = this.config.customRenderer;
                len = rows.length;

                if (GUI.isFunction(renderer)) {
                    for (i = 0; i < len; i++) {
                        row = rows[i];
                        row.index = i;
                        this.fireEvent('filterDataRow', this, row);
                        html.append(renderer(this, row, i));
                    }
                } else {
                    for (i = 0; i < len; i++) {
                        row = rows[i];
                        row.index = i;
                        this.fireEvent('filterDataRow', this, row);

                        if (newTemplate) {
                            html.append(this.rowTpl(row));
                        } else {
                            this.rowTpl.bind(row);
                            html.append(this.rowTpl.fetch());
                        }
                    }
                }
            } else {
                if (newTemplate) {
                    html.append(this.noDataTpl());
                } else {
                    html.append(this.noDataTpl.fetch());
                }
            }

            if (wrap) {
                if (newTemplate) {
                    html.append(this.postTpl(data));
                } else {
                    this.preTpl.bind(data);
                    html.append(this.postTpl.fetch());
                }
            }
            this.dom.innerHTML = html.toString(); // extra slow innerHTML assignment in IE
        }

        if (this.config.unselectEl) {
            GUI.unselectable(this.dom);
        }

        this.fireEvent('update', this, data);
    };

    /**
     *
     * @param {HTMLElement} target
     * @returns {window.GUI.JsonView.prototype.getDragEl.el}
     */
    GUI.JsonView.prototype.getDragEl = function (target) {
        var el = GUI.findParentByTag(target, 'li');
        return GUI.contains(this.dom, el) ? el : null;
    };

    /**
     *
     * @param {Event} e
     * @returns {undefined}
     */
    GUI.JsonView.prototype.onMouseDown = function (e) {
        var target, xy;

        e = new GUI.ExtendedEvent(e);

        if (e.isRightClick()) {
            return;
        }

        if (this.config.enableDd) {
            e.stop();
            target = e.getTarget();
            this.dragObj = this.getDragEl(target);
            this.startPageXY = [target.offsetLeft, target.offsetTop];
            // debugger;
            xy = e.getPageXY();
            this.startX = xy[0];
            this.startY = xy[1];
        }

        //document.ondragstart = function () { return false; };
        //document.body.onselectstart = function () { return false; };

    };

    /**
     *
     * @param {Event} e
     * @returns {undefined}
     */
    GUI.JsonView.prototype.onMouseMove = function (e) {
        var pt, elUnderMouse, xy, diffX, diffY;

        e = new GUI.ExtendedEvent(e);

        if (!this.dragObj) {
            return;
        }

        pt = new GUI.Utils.Point(e.getPageXY());
        elUnderMouse = this.getDragEl(document.elementFromPoint(pt.x, pt.y));

        xy = e.getPageXY();

        diffX = Math.abs(this.startX - xy[0]);
        diffY = Math.abs(this.startY - xy[1]);

        if (diffX > this.dragPixelThresh ||
                diffY > this.dragPixelThresh) {
            //
        } else {
            return;
        }

        // show proxy
        this.showDragProxy(pt, e);

        if (!this.fireEvent('beforeMove', this, this.dragObj, elUnderMouse)) {

            if (this.showProxyBeforeMove === false) {
                this.hideDragProxy();

            } else {
                GUI.$(this.dpDropImgId).setClass(this.config.images.dropNo);
            }
            return;
        }

        GUI.$(this.dpDropImgId).setClass(this.config.images.dropBetween);
    };

    /**
     *
     * @param {Event} e
     * @returns {undefined}
     */
    GUI.JsonView.prototype.onMouseUp = function (e) {
        var elUnderMouse, offset, pt;

        e = new GUI.ExtendedEvent(e);

        if (!this.config.enableDd || !this.dragObj || !this.lineRegion || !this.dragProxy) {
            this.hideDragProxy();
            this.dragObj = null;
            return;
        }

        pt = new GUI.Utils.Point(e.getPageXY());

        this.lineRegion.hide();

        elUnderMouse = this.getDragEl(document.elementFromPoint(pt.x, pt.y));

        if (!this.fireEvent('beforeMove', this, this.dragObj, elUnderMouse)) {
            this.hideDragProxy();
            this.dragObj = null;
            return;
        }

        offset = this.getOffset(e);

        if (offset && this.dragObj) {
            if (offset.value === 'under') {
                this.dom.firstChild.insertBefore(this.dragObj, elUnderMouse.nextSibling);
            } else if (offset.value === 'over') {
                this.dom.firstChild.insertBefore(this.dragObj, elUnderMouse);
            }

            var i, childs = this.dom.firstChild.childNodes;

            for (i = 0; i < childs.length; i++) {
                childs[i].setAttribute('position', i + 1);
            }

            if (elUnderMouse) {
                this.fireEvent('move', this, this.dragObj, parseInt(this.dragObj.getAttribute('position')));
            } else {
                this.fireEvent('move', this, this.dragObj, 'last');
            }
        }

        this.hideDragProxy();
        this.dragObj = null;
    };

    /**
     *
     * @param {Object} dims
     * @param {Event} e
     * @returns {undefined}
     */
    GUI.JsonView.prototype.showDragProxy = function (dims, e) {
        var topOffset, leftOffset, offset, label;

        if (!this.dragProxy) {
            label = GUI.Dom.findDescedent(this.dragObj, 'span.b-tree__label').innerHTML;

            this.dragProxy = new GUI.Popup.Region({
                className   : 'b-dialog b-dialog_phantom',
                dimensions  : {width    : 'auto'},
                content     :
                    GUI.Popup.Hint.renderHint({
                        text: '<i class="b-icon ' + this.config.images.dropNo + '" id="' + this.dpDropImgId + '">&nbsp;</i> ' + label
                    })
            });
        }

        this.dragProxy.setDimensions({
            left : dims.x + 16,
            top  : dims.y + 16
        });
        this.dragProxy.show();

        if (!this.lineRegion) {
            this.lineRegion = new GUI.Popup.Region({
                content     : '<div style="border-top: 1px solid #A4ACB1;">&nbsp;</div>',
                dimensions  : {
                    height  : '1px',
                    width   : GUI.getClientHeight(this.dom) + 'px'
                }
            });
        }
        this.lineRegion.hide();

        offset = this.getOffset(e);

        if (offset) {
            if (offset.value === 'under') {
                topOffset = offset.pt.y + (offset.h - offset.deltaY);

            } else if (offset.value === 'over') {
                topOffset = offset.pt.y - (offset.h  - offset.deltaY);
            }

            leftOffset = this.dragObj.getBoundingClientRect().left;

            this.lineRegion.setDimensions({top: topOffset, left: leftOffset});
            this.lineRegion.show();
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonView.prototype.hideDragProxy = function () {
        if (this.dragProxy) {
            this.dragProxy.hide();
            this.dragProxy = null;
        }

        if (this.lineRegion) {
            this.lineRegion.hide();
        }
    };

    /**
     *
     * @param {Event} e
     * @returns {GUI.JsonView.prototype.getOffset.offsetObj}
     */
    GUI.JsonView.prototype.getOffset = function (e) {
        var elUnderMouse, loc,
            pt = new GUI.Utils.Point(e.getPageXY()),
            offsetObj = {};

        elUnderMouse = this.getDragEl(document.elementFromPoint(pt.x, pt.y));

        if (elUnderMouse && this.dragObj !== elUnderMouse) {
            offsetObj.h = elUnderMouse.getHeight();
            offsetObj.pt = pt;

            loc = GUI.Utils.DDM.getLocation(elUnderMouse);
            offsetObj.deltaY = offsetObj.pt.y - loc.top;

            if (offsetObj.deltaY > offsetObj.h / 2) {
                offsetObj.value = 'under';

            } else if (offsetObj.deltaY <= offsetObj.h / 2) {
                offsetObj.value = 'over';
            }
        }
        return offsetObj;
    };

}());
