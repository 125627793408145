(function () { // variables isolation

    var UserGroupFormClass = window.Class.create(),
        superproto = new GUI.Utils.Observable(),
        prototype = {
            _templates: {},

            initialize: function () {
                GUI.onDOMReady(() => this.assignFields());
            },

            assignFields: function () {
                if (GUI.$('profile-form')) {
                    jQuery('.block_add').each(function (i, item) {
                        this._templates[jQuery(item).data('type')] = item.innerHTML
                    }.bindLegacy(this));

                    this._form = new GUI.Forms.Form();
                    this._form.sendForm = true;
                    this._form.assign(GUI.$('profile-form'));

                    var profileForm = jQuery('#profile-form');
                    profileForm.on('click', '[data-action=add]', this.onClickAdd.bindLegacy(this));
                    profileForm.on('click', '.forms__remove', this.onClickRemove.bindLegacy(this));

                    this._form.getField('timezoneAuto').on('change', this.onAutoTimezoneChange.bindLegacy(this));
                    this.onAutoTimezoneChange();

                    this._form.on(
                        'valid',
                        function () {
                            GUI.$('save-profile').setAttribute('disabled', 'disabled');
                        }
                    );
                }
            },

            onAutoTimezoneChange: function () {
                if (this._form.getField('timezoneAuto').isChecked()) {
                    this._form.getField('timezone').disable();
                } else {
                    this._form.getField('timezone').enable();
                }
            },

            onClickAdd: function (e) {
                var randomInt = Math.floor(Math.random() * (10000 - 500)) + 500;
                var formItem = jQuery(e.target).parents('.forms__item');
                var type = formItem.find('[data-action=add]').data('type');
                var newItemHtml = this._templates[type].replace(/%index%/g, randomInt);
                jQuery(newItemHtml).insertBefore(formItem.find('[data-action=add]'));

                this._form.reassign(GUI.$('profile-form'));
            },
            
            onClickRemove: function (e) {
                jQuery(e.target).parent('div').remove();
            }
        };

    Object.extend(UserGroupFormClass.prototype, superproto);
    Object.extend(UserGroupFormClass.prototype, prototype);

    Application.UserGroupForm = new UserGroupFormClass();

}());