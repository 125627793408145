(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Portal implementation
     *
     * @author Inna
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.Portal = Class.create();
    Object.extend(GUI.Portal.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Portal.prototype.initialize = function (config) {
        this.config =  {
            id          : GUI.getUniqId('portal-'),
            holder      : null,
            columns     : [],

            dd : {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                targetElId  : null,
                isTarget    : true,
                moveOnly    : false,
                groups      : {
                    'portal' : true
                },
                clipElId    : null,

                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            }
        };
        var cfg = this.config;
        Object.extend(cfg, config);

        superproto.initialize.call(this, cfg);

        // Process items
        this.columns = this.config.columns;
    };

    /**
     * Renders objects
     * @param {HTMLElement} to Element to render objects to
     */
    GUI.Portal.prototype.render = function (to) {
        // render all items
        if (to) {
            this.config.holder = to;
        }
        var widthDiv, i, j, column, div, cell, qbHolder, pos, dim, heightHolder,
            len = this.columns.length,
            holder = GUI.$(this.config.holder),
            widthHolder = GUI.getClientWidth(holder),
            container = document.createElement('div');

        container.id = this.config.id;
        container.style.width = widthHolder + 'px';

        holder.appendChild(container);

        widthDiv = Math.round((widthHolder - (len * 24)) / len);

        for (i = 0; i < len; i++) {
            column = this.columns[i];

            div = document.createElement('div');
            div.id = "column-" + i;
            div.style.width = widthDiv + 'px';
            div.style.padding = "1px 10px";

            container.appendChild(div);
            GUI.addClass(div, 'portal-column');

            // cells
            for (j = 0; j < column.items.length; j++) {
                cell = column.items[j];

                qbHolder = document.createElement('div');
                qbHolder.id = "column_" + i + "-cell_" + j;

                div.appendChild(qbHolder);
                //
                cell.config.portal = this;
                cell.columnId = i;
                cell.cellId = j;
                cell.config.dd.enableDrag = true;

                cell.render(qbHolder);
            }

            this.columns[i].lastCellId = j;
            this.columns[i].dom = div;
            this.columns[i].columnId = i;

            pos = GUI.getPosition(div, true);
            dim = GUI.getDimensions(div);

            this.columns[i].pt = {
                x1: pos.left,
                x2: pos.left + dim.width,
                y1: pos.top,
                y2: pos.top + dim.height
            };
        }
        div = document.createElement('div');
        div.style.clear = "both";
        container.appendChild(div);

        this.dom = GUI.Dom.extend(holder);
        //
        heightHolder = GUI.getFullHeight(GUI.$(this.config.holder));

        GUI.$(this.config.holder).style.height = heightHolder + 'px';
        GUI.$(this.config.holder).style.overflow = 'auto';

        GUI.unselectable(this.dom);
        // Initialize Drag'n'Drop
        this.ddConfig.linkedElId = container.id;
        this.ddConfig.targetElId = container.id;
        this.ddConfig.clipElId   = holder.id;
        this.initDD();
    };

    /**
     * Renders proxy element
     * @param {HTMLElement} parent
     * @param {HTMLElement} node
     */
    GUI.Portal.prototype.renderProxy = function (parent, node) {
        var divProxy = document.createElement('div');

        parent.insertBefore(divProxy, node.parentNode);

        GUI.addClass(divProxy, 'x-portal-proxy');
        this.portalProxy = divProxy;
    };

    /**
     * Shows proxy element
     * @param {HTMLElement} parent
     * @param {HTMLElement} node
     */
    GUI.Portal.prototype.showProxy = function (parent, node) {
        if (!node || !node.parentNode || !parent) {
            return;
        }

        if (parent === node) {
            parent.appendChild(this.portalProxy);
        } else {
            try {
                parent.insertBefore(this.portalProxy, node);
            } catch (e) { console.log(e); }
        }
        GUI.show(this.portalProxy);
    };

    /**
     * Hides proxy element
     */
    GUI.Portal.prototype.hideProxy = function () {
        GUI.hide(this.portalProxy);
    };

    /**
     * Returns current target element
     * @param {Event} e Event
     * @param {Object} pt
     */
    GUI.Portal.prototype.getCurrentTarget = function (e, pt) {
        if (!e.target) {
            return;
        }
        e.target.style.display = 'none';

        var elem = document.elementFromPoint(pt.x, pt.y);
        e.target.style.display = '';

        while (elem !== null && elem.id !== null) {
            if (elem === 'DIV') {
                return elem;
            }
            if (elem.id && elem.id.search('cell') !== -1) {
                return elem;
            }
            if (elem.id && elem.id.search('column') !== -1) {
                return elem;
            }
            if (elem.id && elem.id.search('portal') !== -1) {
                return elem;
            }
            elem = elem.parentNode;
        }
        return null;
    };

    /**
     * Shows proxy element, sets current position
     * @param {Event} e Event
     * @param {Object} ddObj Drag'n'Drop object
     */
    GUI.Portal.prototype.onDragEnter = function (e, ddObj) {
        var w, h;
        if (!this.portalProxy) {
            this.renderProxy(ddObj.dom.parentNode.parentNode, ddObj.dom);
        }

        if (GUI.isIE) {
            w = GUI.getClientWidth(ddObj.dom);
        } else {
            w = ddObj.dom.style.width;
        }
        h = parseInt(GUI.getClientHeight(ddObj.dom), 10) - 4;

        this.portalProxy.style.width = w + 'px';
        this.portalProxy.style.height = h + 'px';

        this.showProxy(ddObj.dom.parentNode.parentNode, ddObj.dom.parentNode);
        this.currentPosition = {x: 0, y: 0};
    };

    /**
     * Sets current position
     * @param {Event} e Event
     * @param {Object} dragObj
     * @param {Object} pt
     */
    GUI.Portal.prototype.onDragOver = function (e, dragObj, pt) {
        var pos, dim, middleY, i, col, node,
            el = this.getCurrentTarget(e, pt),
            columns = this.columns;

        if (el && el.parentNode) {

            if (el.id && el.id.search('cell') !== -1) {
                pos = GUI.getPosition(el, true);
                dim = GUI.getDimensions(el);
                middleY = Math.round(pos.top + (dim.height / 2));

                if (pt.y < middleY) {
                    this.showProxy(el.parentNode, el);
                    this.elemBeforePast = el;
                } else {
                    if (el.nextSibling) {
                        this.showProxy(el.parentNode, el.nextSibling);
                        this.elemBeforePast = el.nextSibling;
                    } else {
                        this.showProxy(el.parentNode, el.parentNode);
                        this.elemBeforePast = false;
                    }
                }
                this.elemToPast = el.parentNode;
            } else if (el.id && el.id.search('portal') !== -1) {
                for (i = 0; i < columns.length; i++) {
                    col = columns[i];
                    if (pt.x > col.pt.x1 && pt.x < col.pt.x2) {
                        node = el.children[col.columnId];
                        this.showProxy(node, node);
                        this.elemToPast = node;
                        this.elemBeforePast = false;
                    }
                }
            }
        }
        this.currentPosition = pt;
    };

})();