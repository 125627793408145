(function () {

    function ExtendedEvent(e) {
        if (e._jsguiExtendedEvent) {
            return e;
        }
        this.e = e;
        this.target = this.getTarget();

        this.ctrlKey = e.ctrlKey || e.metaKey;
        this.shiftKey = e.shiftKey;
        this.altKey = e.altKey;
    }

    /**
     * JavaScript Graphical User Interface
     * Class that enchances and encapsulates standard browser event
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.ExtendedEvent = ExtendedEvent;

    GUI.ExtendedEvent.prototype = {
        // Constants
        _jsguiExtendedEvent: true,

        KEY_BACKSPACE   : 8,
        KEY_TAB         : 9,
        KEY_RETURN      : 13,
        KEY_ESC         : 27,
        KEY_SPACE       : 32,
        KEY_PAGEUP      : 33,
        KEY_PAGEDOWN    : 34,
        KEY_END         : 35,
        KEY_HOME        : 36,
        KEY_LEFT        : 37,
        KEY_UP          : 38,
        KEY_RIGHT       : 39,
        KEY_DOWN        : 40,
        KEY_INSERT      : 45,
        KEY_DELETE      : 46,
        ctrlKey         : false,
        shiftKey        : false,
        altKey          : false,

        /**
         * Gets the character code for the event.
         * @returns {Number}
         */
        getCharCode : function () {
            return this.e.charCode || this.e.keyCode;
        },

        /**
         * Stop event
         */
        stop: function () {
            this.preventDefault();
            this.stopPropagation();
        },

        /**
         * Extends dom with the element
         * @param {HTMLElement} element
         * @returns {Boolean}
         */
        within: function (element) {
            return GUI.Dom.extend(this.target).within(element);
        },

        /**
         * Handler mouse enter
         * @param {HTMLElement} element
         * @returns {Boolean}
         */
        isMouseEnter: function (element) {
            var relTarg = this.getRelatedTarget();
            return !relTarg || !relTarg.within(element);
        },

        /**
         * Handler mouse leave
         * @param {HTMLElement} element
         * @returns {Boolean}
         */
        isMouseLeave: function (element) {
            var relTarg = this.getRelatedTarget();
            return !relTarg || !relTarg.within(element);
        },

        /**
         * True if button
         * @param {Object} event
         * @param {Object} code
         * @returns {Boolean}
         */
        isButton: function (code) {
            if (GUI.isIE) {
                var buttonMap = { 0: 1, 1: 4, 2: 2 };
                ExtendedEvent.prototype.isButton = function (code) {
                    return this.e.button === buttonMap[code];
                };

            } else if (GUI.isSafari) {
                ExtendedEvent.prototype.isButton = function (code) {
                    switch (code) {
                    case 0: return this.e.which === 1 && !this.e.metaKey;
                    case 1: return this.e.which === 1 && this.e.metaKey;
                    default: return false;
                    }
                };
            } else {
                ExtendedEvent.prototype.isButton = function (code) {
                    return this.e.which ? (this.e.which === code + 1) : (this.e.button === code);
                };
            }
            return this.isButton(code);
        },

        /**
         * True if left click
         * @returns {Boolean}
         */
        isLeftClick:   function () { return this.isButton(0); },

        /**
         * True if middle click
         * @returns {Boolean}
         */
        isMiddleClick: function () { return this.isButton(1); },

        /**
         * True if right click
         * @returns {Boolean}
         */
        isRightClick:  function () { return this.isButton(2); },

        /**
         * True if key press
         * @returns {Boolean}
         */
        isNavKeyPress: function () {
            var k = this.e.keyCode;
            return (k >= 33 && k <= 40) || k === this.KEY_RETURN || k === this.KEY_TAB || k === this.KEY_ESC;
        },

        /**
         * True if special key
         * @returns {Boolean}
         */
        isSpecialKey : function () {
            var k = this.e.keyCode;
            return (this.e.type === 'keypress' && this.ctrlKey) || this.isNavKeyPress() ||
                (k >= 16 && k <= 20) || (k >= 44 && k <= 45);
        },

        /**
         * @return {bool}
         */
        isOpenInNewWindow: function () {
            return this.ctrlKey || this.isMiddleClick();
        },

        /**
         * Returns keycode
         * @returns {String|Number} code
         */
        getKey: function () {
            return this.e.keyCode;
        }
    };

    if (GUI.isIE) {
        Object.extend(GUI.ExtendedEvent.prototype, {

            /**
             * Sets return value to false
             */
            preventDefault: function () {
                this.e.returnValue = false;
            },

            /**
             * Cancel bubble
             */
            stopPropagation: function () {
                this.e.cancelBubble = true;
            },

            /**
             * Returns target
             * @returns {HTMLElement} element
             */
            getTarget: function () {
                return GUI.Dom.extend(this.e.srcElement || this.e.target);
            },

            /**
             * Returns related target
             * @returns {HTMLElement}
             */
            getRelatedTarget: function () {
                switch (this.e.type) {
                case 'mouseover':
                case 'mouseenter':
                case 'dragover':
                    return GUI.Dom.extend(this.e.fromElement);

                case 'mouseout':
                case 'mouseleave':
                case 'dragleave':
                    return GUI.Dom.extend(this.e.toElement);
                }
            },

            /**
             * Returns coordinates
             */
            getPageXY: function () {
                var bd = GUI.getBody(),
                    html = document.documentElement,
                    e = this.e;

                return [
                    e.clientX + bd.scrollLeft - html.clientLeft,
                    e.clientY + bd.scrollTop - html.clientTop
                ];
            }
        });
    } else {
        Object.extend(GUI.ExtendedEvent.prototype, {

            /**
             *
             */
            preventDefault: function () {
                this.e.preventDefault();
            },

            /**
             *
             */
            stopPropagation: function () {
                this.e.stopPropagation();
            },

            /**
             *
             */
            getTarget: function () {
                return GUI.Dom.extend(this.e.target);
            },

            /**
             *
             */
            getRelatedTarget: function () {
                var targ = this.e.relatedTarget;
                if (targ) {
                    try {
                        if (targ.nodeType === 3) {
                            // defeat Opera 9.25 bug
                            targ = targ.parentNode;
                        }
                    } catch (e) {
                        // Goes here in FF3 if targ is XULElement with exception
                        // "Permission denied to get property XULElement.nodeType"
                        return null;
                    }
                    return GUI.Dom.extend(targ);
                }
                return null;
            },

            /**
             *
             */
            getPageXY: function () {
                if (GUI.isMobile && this.e instanceof TouchEvent) {
                    if (this.e.touches.length) {
                        return [
                            this.e.touches[0].pageX,
                            this.e.touches[0].pageY
                        ];
                    } else if (this.e.changedTouches.length) {
                        return [
                            this.e.changedTouches[0].pageX,
                            this.e.changedTouches[0].pageY
                        ];
                    }
                }

                return [this.e.pageX, this.e.pageY];
            }
        });
    }

}());