(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Utils.ClickRepeater implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     * @extends GUI.Utils.Observable
     */
    GUI.Utils.ClickRepeater = Class.create();
    Object.extend(GUI.Utils.ClickRepeater.prototype, superproto);

    /**
     * Default is 500
     * @type Number
     */
    GUI.Utils.ClickRepeater.prototype.delay = 500;

    /**
     * Default is 50
     * @type Number
     */
    GUI.Utils.ClickRepeater.prototype.interval = 50;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Utils.ClickRepeater.prototype.initialize = function (config) {
        if (config) {
            Object.extend(this, config);
        }

        this.dom = GUI.$(this.dom);
        GUI.unselectable(this.dom);

        this.addEvents({
            mousedown : true,
            mouseup   : true,
            click     : true
        });

        this.dom.on('mousedown', this.onMouseDown, this);
        this.timerDelegate = this.onTimer.bindLegacy(this);
        superproto.initialize.call(this);
    };

    /**
     * Removes dom
     */
    GUI.Utils.ClickRepeater.prototype.destroy = function () {
        this.dom.un('mousedown', this.onMouseDown, this);
        this.dom = null;
    };

    /**
     * Add events 'mouseup', 'mouseout', fires events 'mousedown', 'click'.
     * Runs timer
     * @param {Event} e Event
     */
    GUI.Utils.ClickRepeater.prototype.onMouseDown = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
        clearTimeout(this.timerId);

        this.time = new Date() - 0;
        if (this.pressedClass) {
            this.dom.addClass(this.pressedClass);
        }

        document.on('mouseup', this.onMouseUp, this);
        this.dom.on('mouseout', this.onMouseOut, this);

        this.fireEvent('mousedown', this);
        this.fireEvent('click', this);

        this.timerId = setTimeout(this.timerDelegate, this.delay);
    };

    /**
     * Fires event 'click', runs timer
     */
    GUI.Utils.ClickRepeater.prototype.onTimer = function () {
        this.fireEvent('click', this);
        this.timerId = setTimeout(this.timerDelegate, this.interval);
    };

    /**
     * Removes events 'mouseup' and 'mouseover' from the document.
     * Fires event 'mouseup'
     */
    GUI.Utils.ClickRepeater.prototype.onMouseUp = function () {
        clearTimeout(this.timerId);
        document.un('mouseup', this.onMouseUp, this);
        if (this._mouseOverAttached) {
            this.dom.un('mouseover', this.onMouseOver, this);
            this._mouseOverAttached = false;
        }
        this.dom.un('mouseout', this.onMouseOut, this);
        this.dom.removeClass(this.pressedClass);
        this.fireEvent('mouseup', this);
    };

    /**
     * Clears timers
     */
    GUI.Utils.ClickRepeater.prototype.onMouseOut = function () {
        clearTimeout(this.timerId); // Clear timer
        if (this.pressedClass) {
            this.dom.removeClass(this.pressedClass);
        }
        if (!this._mouseOverAttached) {
            this.dom.on('mouseover', this.onMouseOver, this);
            this._mouseOverAttached = true;
        } else {
            console.log('bug');
        }

    };

    /**
     * Runs timer
     */
    GUI.Utils.ClickRepeater.prototype.onMouseOver = function () {
        if (this._mouseOverAttached) {
            this.dom.un('mouseover', this.onMouseOver, this);
            this._mouseOverAttached = false;
        } else {
            console.log('bug');
        }

        if (this.pressedClass) {
            this.dom.addClass(this.pressedClass);
        }
        this.timerId = setTimeout(this.timerDelegate, this.interval); // Restore timer
    };

}());