(function () {
    if (!GUI.Forms.Plugins) {
        GUI.Forms.Plugins = {};
    }
    /**
     * JavaScript Graphical User Interface
     * EditorImageManager plugin implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms.Plugins
     */
    GUI.Forms.Plugins.EditorImageManager = Class.create();

    GUI.Forms.Plugins.EditorImageManager.prototype.onlyWeb = false;

    /**
     * Content of dialog
     * @type String
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.dialogContent = {
        content: '<div id="{0}-tabbar"></div>' +
        '<div id="{0}-tab-uploaded">' +
        '<div id="{0}-toolbar-upload" ></div>' +
        '<div class="b-image-manager">' +
        '<div class="b-image-manager__right-side">' +
        '<div class="b-image-manager__case b-image-manager__case_thumbs-area">' +
        '<ul class="b-image-manager__thumbs" id="{0}-images-list">' +
        '<li class="b-image-manager__thumbs__item b-image-manager__thumbs__item_drop">' +
        '<div id="{0}-drop-area" class="b-image-manager__thumbs__thumb-area"></div>' +
        '<div class="b-image-manager__thumbs__desc">' +
        '<span class="b-image-manager__thumbs__desc-txt">' + i18n('Drop Here') + '</span>' +
        '</div>' +
        '</li>' +
        '</ul>' +
        '<div class="b-avatar-select__dropAreaOver"><span><span>Drop Here</span></span></div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div id="{0}-tab-from-web" style="display: none;">' +
        '<div id="{0}-toolbar-web"></div>' +
        '<div class="b-image-manager">' +
        '<div class="b-image-manager__right-side">' +
        '<div class="b-image-manager__case b-image-manager__case_preview-area">' +
        '<div class="b-image-manager__image-preview">' +
        '<div class="b-image-manager__image-preview__container">' +
        '<img id="{0}-web-preview-img" class="b-image-manager__image-preview__image" alt="">' +
        '</div>' +
        '<i id="{0}-web-preview-icon" class="b-image-manager__thumbs__thumb__enlarger"><i class="b-icon fa fa-search fa_white"></i></i>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>'

    };

    /**
     * Constructor
     * @param {Object} cfg Configuration object
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.initialize = function (cfg) {
        if (cfg) {
            Object.extend(this, cfg);
        }

        if (!this.baseParams) {
            this.baseParams = {};
        }
    };

    /**
     * Called by Editor while initializing. Add event 'createToolbar', 'assign'.
     * Add html filters 'img2embed', 'embed2img'.
     * @param {Object} editor Editor
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.init = function (editor) {
        this.editor = editor;

        if (!this.imageHandlerUrl) {
            this.imageHandlerUrl = editor.imageHandlerUrl;
        }

        // Add event listeners
        editor.on('assign', this.onAssign, this);
    };

    /**
     * Sets imageHandlerUrl
     * @param {Object} field
     * @param {Object} node
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onAssign = function (field, node) {
        var attr = node.getAttribute('imageHandlerUrl');
        if (attr) {
            this.imageHandlerUrl = attr;
        }
    };

    /**
     * Called by Editor while destroying.
     * Destroy dialog, remove event listeners 'createToolbar', 'assign',
     * sets editor to null
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.destroy = function () {
        if (this.dlg) {
            this.dlg.destroy();
            this.dlg = null;
        }

        //this.editor.un('assign', this.onAssign, this);
        this.editor = null;
    };

    /**
     * Handles click on the 'Insert/Edit Image' button on the editor's toolbar
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onClick = function (btn) {
        this.filesList = [];

        this.id = 'x-editor-imagedialog';
        this.dlgId = 'jsgui-editor-imagedialog';

        this.tabbarId = this.id + '-tabbar';
        this.tabUploadedId = this.id + '-tab-uploaded';
        this.tabWebId = this.id + '-tab-from-web';

        this.uploadToolbarId = this.id + '-toolbar-upload';
        this.uploadFormId = this.id + '-upload-form';
        this.webToolbarId = this.id + '-toolbar-web';

        this.uploadAreaId = this.id + '-drop-area';
        this.webImgUrl = this.id + '-web-img-url';

        this.createDialog();
        this.createTabbar();
        this.createToolbars();

        // upload tab
        this.listDom = GUI.$(this.id + '-images-list');

        // form
        this.createUploadForm();

        this.uploadFileMask = new GUI.Popup.Mask({element: this.dlg.dom, stylePosition: 'absolute'});

        // web tab
        this.webPreviewImg = GUI.$(this.id + '-web-preview-img');
        this.webPreviewIcon = GUI.$(this.id + '-web-preview-icon');
        this.webImageUrl = GUI.$(this.webImgUrl);

        this.addEventListeners();

        this.loadListImages();

        this.dlg.show();

        this.initDDUpload();

        this.afterRender();
    };

    GUI.Forms.Plugins.EditorImageManager.prototype.afterRender = function () {
    };

    /**
     *
     * @returns {GUI.Popup.Dialog}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.addEventListeners = function () {
        this.listDom.on('click', this.onClickList.bindLegacy(this));
        this.listDom.on('dblclick', this.onDoubleClickList.bindLegacy(this));
        this.webPreviewIcon.on('click', GUI.showPreview.bindLegacy(this, this.webPreviewImg), this);
    };

    /**
     *
     * @returns {GUI.Popup.Dialog}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createUploadForm = function () {
        if (this.uploadForm) {
            this.uploadForm.un('afterSubmit', this.onLoadDDImage, this);
            this.uploadFile.un('change', this.uploadFiles, this);
            this.uploadForm.destroy();
            this.uploadForm = false;
        }

        this.createInput();

        this.uploadForm = new GUI.Forms.Form();
        this.uploadForm.assign(this.uploadFormId);

        this.uploadForm.config.action = this.imageHandlerUrl;
        this.uploadForm.action = this.imageHandlerUrl;
        this.uploadForm.fileUpload = true;

        this.uploadForm.on('afterSubmit', this.onLoadDDImage, this);
        this.uploadFile.on('change', this.uploadFiles, this);
    };

    /**
     *
     * @returns {GUI.Popup.Dialog}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createInput = function () {
        var id = this.idInpFile || 1,
            uploadFile = document.createElement('input');

        GUI.$(this.uploadFormId).innerHTML = '';

        uploadFile.type = 'file';
        uploadFile.name = 'files[]';
        uploadFile.id = 'fileInp-' + id;
        GUI.$(this.uploadFormId).appendChild(uploadFile);

        this.uploadFile = GUI.$('fileInp-' + id);
        this.idInpFile = id + 1;
    };

    /**
     * Creates dialog
     * @returns {GUI.Popup.Dialog}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createDialog = function () {
        var dlg, dialogContent;

        dialogContent = this.dialogContent.content.format(this.id);

        dlg = new GUI.Popup.Dialog({
            id: this.dlgId,
            title: i18n('Insert Image'),
            content: dialogContent,
            modal: true,
            //alwaysOnTop: true,
            dimensions: {
                width: 600
            },
            toolbuttons: [
                {name: 'close', img: 'close'}
            ],
            footerLeftElements: [{
                name: 'tooltip',
                obj: new GUI.Element({
                    className: 'b-toolbar__txt',
                    html: i18n('Hint: Drag and paste images directly into the editor.')
                })
            }],
            footerButtons: [{
                name: 'cancel',
                obj: new GUI.ToolBar.Button({
                    name: 'cancel',
                    text: i18n('Cancel'),
                    background: false,
                    onClick: function () {
                        dlg.close();
                    }.bindLegacy(this)
                })
            }, {
                name: 'insert',
                obj: new GUI.ToolBar.Button({
                    name: 'insert',
                    text: i18n('Insert'),
                    hint: i18n('Please select an image or insert an image URL first'),
                    primary: true,
                    disabled: true,
                    onClick: function (btn) {
                        if (this.insertImage() !== false) {
                            dlg.close();
                        } else {
                            btn.enable();
                        }

                    }.bindLegacy(this)
                })
            }]
        });
        dlg.create();

        this.dlg = dlg;

        this.insertButton = this.dlg.insert;
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createTabbar = function () {
        var tabbar = new GUI.TabBar({
            extraClass: 'b-tabbar_pills b-tabbar_pills_underline b-toolbar_clear',
            holder: this.tabbarId,
            position: 'top',
            background: false,
            elements: [{
                name: 'upload',
                obj: new GUI.TabBar.Tab({
                    name: 'upload',
                    caption: i18n('Upload'),
                    assigned: this.tabUploadedId,
                    extraClass: 'b-button_tab',
                    active: true
                })
            }, {
                name: 'web',
                obj: new GUI.TabBar.Tab({
                    name: 'web',
                    caption: i18n('From the Web'),
                    assigned: this.tabWebId,
                    extraClass: 'b-button_tab'
                })
            }]
        });
        tabbar.render();
        this.tabbar = tabbar;

        if (this.onlyWeb) {
            this.setOnlyWeb();
        }
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createUploadButtonElement = function() {
        return new GUI.Element({
            withoutSpan: true,
            html: '<label class="b-button b-button_color_primary">' +
            '<span class="b-button__label">' + i18n('Upload') + '</span>' +
            '<form id="' + this.uploadFormId + '" name="upload"></form>' +
            '</label>'
        });
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createUploadToolbar = function () {
        var toolbarUpload;

        toolbarUpload = new GUI.ToolBar({
            extraClass: 'b-toolbar_light',
            elements: [{
                name: 'upload',
                obj: this.createUploadButtonElement()
            }, {
                name: 'delete',
                obj: new GUI.ToolBar.Button({
                    name: 'delete',
                    text: i18n('Delete'),
                    autoDisable: false,
                    disabled: true,
                    onClick: function () {
                        this.showConfirmationMessage('', '', 'deleteImg');
                    }.bindLegacy(this)
                })
            }]
        });
        toolbarUpload.render(this.uploadToolbarId);
        this.toolbarUpload = toolbarUpload;
        this.deleteBtn = this.toolbarUpload.getElement('delete');
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createWebToolbar = function () {
        var toolbarWeb;

        toolbarWeb = new GUI.ToolBar({
            extraClass: 'b-toolbar_flex-content b-toolbar_light',
            elements: [{
                name: 'label',
                obj: new GUI.ToolBar.ButtonGroups({
                    items: [
                        new GUI.Element({
                            className: 'b-toolbar__txt',
                            html: i18n('Image URL')
                        })
                    ]
                })
            }, {
                name: 'inp',
                obj: new GUI.ToolBar.ButtonGroups({
                    extraClass: 'b-buttons-list__group_flex',
                    items: [
                        new GUI.Forms.TextField({
                            name: 'textfield-web-img',
                            extraClass: 'b-text-field_size_wide',
                            id: this.webImgUrl,
                            value: '',
                            onChange: function (obj, value) {
                                if (value.trim().length) {
                                    this.insertButton.enable();
                                    this.insertButton.setHint('');
                                } else {
                                    this.insertButton.disable();
                                    this.insertButton.setHint(i18n('Please select an image or insert an image URL first'));
                                }

                            }.bindLegacy(this)
                        })
                    ]
                })
            }, {
                name: 'preview',
                obj: new GUI.ToolBar.ButtonGroups({
                    items: [
                        new GUI.ToolBar.Button({
                            name: 'preview',
                            text: i18n('Preview'),
                            autoDisable: false,
                            primary: true,
                            onClick: this.showWebImage.bindLegacy(this)
                        })
                    ]
                })
            }]
        });
        toolbarWeb.render(this.webToolbarId);
        this.toolbarWeb = toolbarWeb;
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createToolbars = function () {
        this.createUploadToolbar();

        this.createWebToolbar();
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.loadListImages = function (callback) {
        new GUI.Ajax.Request({
            url: this.imageHandlerUrl,
            method: 'post',
            data: {act: 'loadList'},
            scope: this,
            onSuccess: function () {
                this.onLoadListSuccessful.apply(this, arguments);

                if (callback) {
                    callback();
                }
            }.bindLegacy(this),
            onFailure: function () {
                this.showError(i18n(GUI.i18n.GUI_EDITOR_ERROR));
            }
        });
    };

    /**
     * Renders list and shows errors
     * @param {type} response
     * @param {type} request
     * @returns {undefined}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onLoadListSuccessful = function (xhr, response, lastUpload) {
        var res = response.responseJson || response;

        this.setImagesList(response);
        this.renderList(lastUpload);

        if (res.error) {
            this.showError(res.error, 'Error!');
        }
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.setImagesList = function (response) {
        var list = (response.responseJson || response).list || [];
        this.filesList = list;

        if (this.uploadFilesArea) {
            this.uploadFilesArea.namesFileList = list;
        }
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.renderList = function (lastUpload) {
        var i, img, li, n,
            target = this.listDom,
            list = this.filesList;

        this.filesList = list;

        if (target) {
            target.innerHTML = '';
            this.createUploadArea(target);

            for (i = 0; i < list.length; i++) {
                img = list[i];
                img.id = img.id || img.name;
                img.path += '#' + new Date().getTime();

                li = document.createElement('LI');
                li.className = 'b-image-manager__thumbs__item';
                li.id = 'img-' + img.id;
                li.title = img.name;
                target.appendChild(li);

                li.innerHTML = '<div class="b-image-manager__thumbs__thumb-area">' +
                    '<div class="b-image-manager__thumbs__thumb-case">' +
                    '<img class="b-image-manager__thumbs__thumb" alt="" title="' + img.name + '" src="' + img.path + '" >' +
                    '<i class="b-image-manager__thumbs__thumb__enlarger"><i class="b-icon fa fa-search fa_white"></i></i>' +
                    '</div>' +
                    '</div>' +
                    '<div class="b-image-manager__thumbs__desc">' +
                    '<span class="b-image-manager__thumbs__desc-txt">' + img.name + '</span>' +
                    '</div>';
            }

            this.uploadFilesArea.namesFileList = list;

            if (lastUpload) {
                // n = GUI.$('img-' + lastUpload.name);
                n = document.querySelector('li[title="' + lastUpload.name + '"]');
                this.selectItem(n);
            }
        }
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.createUploadArea = function (holder) {
        var li = document.createElement('LI');
        li.className = 'b-image-manager__thumbs__item b-image-manager__thumbs__item_drop';
        holder.appendChild(li);

        li.innerHTML = '<div id="' + this.uploadAreaId + '" class="b-image-manager__thumbs__thumb-area"></div>' +
        '<div class="b-image-manager__thumbs__desc">' +
        '<span class="b-image-manager__thumbs__desc-txt">' + i18n('Drop Here') + '</span>' +
        '</div>';

    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.initDDUpload = function () {
        this.uploadFilesArea = false;

        this.uploadFilesArea = new GUI.DragDropUpload({
            parentNode: this.listDom.parentNode,
            handlerUrl: this.imageHandlerUrl,
            onLoad: this.onLoadDDImage.bindLegacy(this),
            onCancelOverwrite: this.createUploadForm.bindLegacy(this),
            addText: false
        });
        this.uploadFilesArea.show();
    };

    /**
     * Adds node
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onLoadDDImage = function (file, response) {
        if (this.uploadFileMask && this.uploadFileMask.region) {
            this.uploadFileMask.hide();
        }

        this.createUploadForm();
        this.onLoadListSuccessful(null, response, file);
    };
    /**
     * Custom load
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.customLoadImage = function (files, callback) {
        this.uploadFilesArea = false;
        this.uploadFilesArea = new GUI.DragDropUpload({
            // parentNode: this.listDom.parentNode,
            handlerUrl: this.imageHandlerUrl,
            onLoad: this.onCompleteCustomLoadImage.bindLegacy(this, callback),
            onCancelOverwrite: this.createUploadForm.bindLegacy(this),
            addText: false
        });

        this.uploadFilesArea.queuing(files, true);
    };

    GUI.Forms.Plugins.EditorImageManager.prototype.onCompleteCustomLoadImage = function (callback, file, response) {
        this.setImagesList(response);

        if (response.done && callback) {
            var src = response.list[response.list.length - 1].path;
            var alt = response.list[response.list.length - 1].name;

            this.editor.insertContent('<img src="' + src + '" alt="' + alt + '" id="image">');

            var img = jQuery(this.editor.getBody()).find('#image');
            img.on('load', function () {
                this.editor.fire('keyup');
                img.attr('id', '');
            }.bindLegacy(this));

            callback();
        } else {
            var informer = new GUI.Popup.PopupInformer({
                type: 'error'
            });
            informer.setCaption(i18n('Error'));
            informer.setText(response.error);
            informer.show();
        }
    };

    /**
     * Uploads files
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.uploadFiles = function (e) {
        e = GUI.Event.extend(e);

        var inp = e.target;

        if (inp.files) {
            this.uploadFilesArea.queuing(inp.files);

        } else {
            this.uploadFileMask.show();
            this.uploadForm.submit({params: {act: 'upload'}});
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onClickList = function (e) {
        e = GUI.Event.extend(e);
        var target = e.getTarget(),
            parentLi = target.findParent('li', this.listDom, 'b-image-manager__thumbs__item'),
            img = parentLi.querySelector('img');

        this.selectItem(parentLi);

        if (
            target.nodeName.toLowerCase() === 'i' &&
            (
                GUI.hasClass(target, 'b-image-manager__thumbs__thumb__enlarger') ||
                GUI.hasClass(target, 'fa-search')
            )
        ) {
            img.src = this.clearSourceImage(img.src);
            GUI.showPreview(img);
        }
    };


    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.onDoubleClickList = function (e) {
        e = GUI.Event.extend(e);
        var target = e.getTarget(),
            parentLi = target.findParent('li', this.listDom, 'b-image-manager__thumbs__item'),
            img = parentLi.querySelector('img');

        var src = this.clearSourceImage(img.src);
        this.insertImage(src);
        this.dlg.close();
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.selectItem = function (node) {
        if (!node) {
            return;
        }

        if (this.selectedItem) {
            this.selectedItem.removeClass('selected');
        }

        // if drop zone return
        if (GUI.Dom.hasClass(node, 'b-image-manager__thumbs__item_drop')) {
            this.selectedItem = undefined;
            if (this.deleteBtn) {
                this.deleteBtn.disable();
            }
            this.insertButton.disable();
            this.insertButton.setHint(i18n('Please select an image or insert an image URL first'));
            return;
        }

        this.selectedItem = node;
        this.selectedItem.addClass('selected');
        if (this.deleteBtn) {
            this.deleteBtn.enable();
        }
        this.insertButton.enable();
        this.insertButton.setHint('');
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.showWebImage = function () {
        this.webPreviewImg.src = this.webImageUrl.value;
    };

    /**
     *
     * @param {type} src
     * @returns {Boolean}
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.clearSourceImage = function (src) {
        return src.replace(/#[0-9]*/, '');
    };

    /**
     * Inserts selected image into current cursor position in the editor
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.insertImage = function (src_) {
        var img, id, alt = '',
            src = src_ || '',
            placeholder = '',
            activeTab = this.tabbar.activeTab.name;

        if (activeTab === 'upload' && this.selectedItem) {
            src = this.selectedItem.findDescedent('img').src;
            src = src.replace(/http[s]?:/, '');
            alt = "clearscheme";
            id = this.selectedItem.id.replace('img-', '');
            placeholder = 'data-file="___chdfid_' + id + '___"';

        } else if (activeTab === 'web') {
            src = this.webImageUrl.value;
        }

        src = this.clearSourceImage(src);

        if (!src) {
            return false;
        }

        img = '<img ' + placeholder + ' src="' + src + '" alt="' + alt + '">';

        this.editor.insertContent(img);
    };

    /**
     *
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.deleteImg = function () {
        var i, a, item = this.selectedItem,
            id = item.id.replace('img-', ''),
            next = GUI.Dom.findNextSibling(this.selectedItem, 'li')[0],
            prev = GUI.Dom.findPrevSibling(this.selectedItem, 'li')[0];

        if (next) {
            this.selectItem(next);
        } else if (prev) {
            this.selectItem(prev);
        }

        for (i = 0; i < this.filesList.length; i++) {

            if (this.filesList[i].id.toString() === id) {
                this.filesList.splice(i, 1);
            }
        }

        item.parentNode.removeChild(item);

        new GUI.Ajax.Request({
            url: this.imageHandlerUrl,
            data: {act: 'delete', id: id},
            scope: this,
            onSuccess: function (response, request) {
                var res = request.responseJson;

                if (res && res.done) {
                    //
                } else {
                    this.showError(i18n(GUI.i18n.GUI_EDITOR_ERROR));
                }
            },
            onFailure: function () {
                this.showError(i18n(GUI.i18n.GUI_EDITOR_ERROR));
            }
        });
    };

    GUI.Forms.Plugins.EditorImageManager.prototype.clearUploadedFiles = function (doNotTryLoadImageList) {
        if (doNotTryLoadImageList !== true && this.filesList === undefined) {
            this.loadListImages(function () {
                this.clearUploadedFiles(true);
            }.bindLegacy(this));
            return;
        }

        for(var i = 0; i < this.filesList.length; i++) {
            new GUI.Ajax.Request({
                url: this.imageHandlerUrl,
                data: {act: 'delete', name: this.filesList[i]['name']},
                scope: this
            });

            var item = GUI.$('img-' + this.filesList[i]['name']);
            if (item) {
                item.parentNode.removeChild(item);
            }
        }

        this.filesList = [];
    };

    /**
     * Set plugin only web. Call before render.
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.setOnlyWeb = function () {
        this.tabbar.closeTab(this.tabbar.getElement('upload'));
    };

    /**
     * Show confirmation message
     * @param {String} msg Text of message
     * @param {String} title Text of title
     * @param {String} action Can be 'remove'
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.showConfirmationMessage = function (msg, title, action) {
        var msgBox = new GUI.Popup.Dialog({
            alwaysOnTop: true,
            modal: true,
            dimensions: {
                width: 400
            },
            footerButtons: [{
                name: 'ok',
                obj: new GUI.ToolBar.Button({
                    name: 'ok',
                    text: i18n('Delete'),
                    primary: true,
                    onClick: function () {
                        if (action === 'deleteImg') {
                            this.deleteImg();
                        }
                        msgBox.close();
                    }.bindLegacy(this)
                })
            }, {
                name: 'cancel',
                obj: new GUI.ToolBar.Button({
                    name: 'cancel',
                    text: i18n('Cancel'),
                    onClick: function () {
                        msgBox.close();
                    }.bindLegacy(this)
                })
            }]
        });
        msgBox.create();
        msgBox.setTitle(title || i18n(GUI.i18n.GUI_DELETE_MESSAGE_TITLE));
        msgBox.setContent('<div class="default-data-layout">' + (msg || i18n(GUI.i18n.GUI_DELETE_MESSAGE)) + '</div>');
        msgBox.show();
    };

    /**
     * Show error message
     * @param {String} msg Text of message
     * @param {String} title Text of title
     * @param {Function} callback
     * @param {Object} param
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.showError = function (msg, title, callback, param) {
        var msgBox = new GUI.Popup.Dialog({
            id: 'upload-file-error-dlg',
            alwaysOnTop: true,
            modal: true,
            title: title || i18n(GUI.i18n.GUI_EDITOR_ERROR),
            content: '<div class="default-data-layout">' + msg + '</div>',
            dimensions: {
                width: 400
            },
            toolbuttons: [
                {name: 'close', img: 'close'}
            ],
            alignFooterButtons: 'center',
            footerButtons: [{
                name: 'ok',
                obj: new GUI.ToolBar.Button({
                    name: 'ok',
                    text: i18n('OK'),
                    onClick: function () {
                        msgBox.close();
                        if (callback) {
                            callback(param);
                        }
                    }.bindLegacy(this)
                })
            }]
        });
        msgBox.create();
        msgBox.show();
    };

    /**
     * Shows informer
     * @param {String} msg Text of message
     * @param {String} title Text of title
     */
    GUI.Forms.Plugins.EditorImageManager.prototype.showInformer = function (msg, title) {
        if (!this._informer) {
            this._informer = new GUI.Popup.PopupInformer({
                type: 'info'
            });
        }
        this._informer.setCaption(title || '');
        this._informer.setText(msg);
        this._informer.show();
    };

    GUI.Forms.Plugins.EditorImageManager.prototype.uploadBase64Image = function (base64Info, fileName) {
        return new Promise(function (resolve, reject) {
            var form = jQuery('<form></form>')[0];

            var block = base64Info.split(";");
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            var extension = contentType.replace('image/', '');

            var blob = GUI.b64toBlob(realData, contentType);

            var formDataToUpload = new FormData(form);

            formDataToUpload.append("file[]", blob, fileName + '.' + extension);
            formDataToUpload.append('act', 'upload');

            $.ajax({
                url: this.imageHandlerUrl,
                data: formDataToUpload,
                type:"POST",
                contentType:false,
                processData:false,
                cache:false,
                dataType:"json",
                error:function(err){
                    reject(err);
                },
                success:function(data){
                    resolve({
                        list: data.list,
                        extension: extension
                    });
                }
            });
        }.bindLegacy(this));
    };

}());