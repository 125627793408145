GUI.onDOMReady(function () {
    if (GUI.$('satisfaction-rating-section')) {
        SatisfactionFormController.init();
    }
});

const SatisfactionFormController = new GUI.Controller({
    name: 'SatisfactionForm'
});

SatisfactionFormController.init = function () {
    function parseQueryParams(queryStr) {
        let resultObj = {}

        const entries = new URL("http://localhost?" + queryStr).searchParams.entries();
        for (const [key, value] of entries) {
            resultObj[key] = value.toLowerCase();
        }

        return resultObj;
    }

    this._lastSavedState = {};
    this._state= {
        offered                    : false,
        rating                     : null,
        "show-comment"             : false,
        commentRequiredForBadRating: false,
        comment                    : "",
        showCancel                 : false,
        showSave                   : false,
        submitting                 : false
    };

    const uriHash = (new URL(window.location.href)).hash.replace('#', '');
    const queryParams = parseQueryParams(uriHash);

    this.holder = jQuery('#satisfaction-rating-section');
    if (!this.holder) {
        throw Error("Satisfaction form initialization failed: #satisfaction-rating-section not found.");
    }

    this.render();
    let state = _.extend(
        this.getState(),
        window.satisfaction.data,
        queryParams
    );
    this._lastSavedState = state; // remember initial state for in case of reset
    this.setState(state);

    this.initEvents();

    window.location.hash = ""; // clear hash to prevent re-voting on page refresh

    if (queryParams.rating) { // submit vote if rating was in url hash
        this.submit();
    }
};

SatisfactionFormController.getState = function () {
    return Object.clone(this._state);
}

SatisfactionFormController.initEvents = function () {
    this.holder.on("click", "[role=save]", null, () => {
        this.submit();
    });

    this.holder.on("click", "[role=cancel]", null, () => {
        this.setState(this._lastSavedState);
    });

    this.holder.on("click", "[role=rate]", null, (event) => {
        let state = this.getState();

        state["show-comment"] = true;
        state.rating = parseInt(jQuery(event.currentTarget).val());

        this.setState(state);
        this.submit();
    });

    this.holder.on("change keyup", "[name=satisfaction-rating-comment]", null, (event) => {
        let state = this.getState();
        state.comment = jQuery(event.currentTarget).val();
        this.setState(state);
    });
};

SatisfactionFormController.submit = function () {
    let state = this.getState();

    // do not submit bad rating withot comment if commentRequiredForBadRating has been set
    if (state.commentRequiredForBadRating && state.rating < 0 && !state.comment.length) {
        return;
    }

    state.submitting = true;
    state.error = null;
    state.message = "";
    this.setState(state);

    new Ajax.Request(this.holder.attr('action'), {
        data: {
            'satisfaction-rating'        : state.rating,
            'satisfaction-rating-comment': state.comment
        },
        onSuccess: (response, request) => {
            state = _.extend(state, request.responseJson);
            state.submitting = false;
            this._lastSavedState = state;
            this.setState(state);
        },
        onFailure: (response, request) => {
            state = _.extend(state, request.responseJson);
            state.submitting = false;
            this.setState(state);
        }
    });
};

SatisfactionFormController.setState = function (newState, doNotRender) {
    const oldState = this.getState();

    function computeEffects() {
        if (!newState.comment) {
            newState.comment = "";
        }

        newState["show-comment"] = newState["show-comment"] || newState.comment.length > 0 || newState.rating;

        newState.showCancel =
            !newState.submitting
            && newState.commentRequiredForBadRating
            && newState.rating < 0
            && !newState.comment.length && this._lastSavedState.comment.length;

        newState.showSave =
            !newState.submitting
            && newState.comment != (this._lastSavedState.comment ?? "");
    }

    const update = {
        ratingClass: function () {
            const newClass = newState.rating > 0 && "btn-green" || newState.rating < 0 && "btn-red" || "";
            this.holder.find("[role=rate]").removeClass('btn-green').removeClass('btn-blue').removeClass('btn-red');
            this.holder.find(`[role=rate][value="${newState.rating}"]`).addClass(newClass);
        },

        commentValue: function () {
            if (oldState.comment != newState.comment) {
                this.holder.find("[name=satisfaction-rating-comment]").val(newState.comment);
            }
        },

        commentPromptText: function () {
            if (newState.submitting) {
                this.holder.find("[role=textarea-promt]").html(i18n("Saving..."));
                return;
            }

            if (newState.commentRequiredForBadRating && newState.rating < 0 && !newState.comment.length) {
                this.holder.find("[role=textarea-promt]").html(`<span style="color: red;">*</span> ` + i18n("Please, add a comment about the quality of service you received"));
                return;
            }

            if (newState.comment != this._lastSavedState.comment) {
                this.holder.find("[role=textarea-promt]").html(i18n("Please, add a comment about the quality of service you received"));
                return;
            }

            const savedIco = `<i style="color: green;" class="far fa-check"></i> `;

            if (newState.comment.length) {
                this.holder.find("[role=textarea-promt]").html(savedIco + i18n("Your vote has been saved. Your comment about the quality of service you received"));
                return;
            }

            this.holder.find("[role=textarea-promt]").html(savedIco + i18n("Your vote has been saved. You can also add a comment about the quality of service you received"));
        },

        commentBlockVisibility: function () {
            this.holder.find("[role=comment-block]").css("display", newState["show-comment"] ? "block" : "none");
            if (newState["show-comment"]) {
                this.holder.find("[name=satisfaction-rating-comment]").focus();
            }
        },

        bottomButtonsVisibility: function () {
            this.holder.find("[role=save]").css("display", newState.showSave ? "" : "none");
            this.holder.find("[role=cancel]").css("display", newState.showCancel ? "" : "none");
        },

        errorInformer: function () {
            const holder = this.holder.find("[role=informer-holder]");
            if (!newState.error && !newState.message) {
                holder.html("");
                return;
            }

            holder.html(`
                <div class="b-informer b-informer_disabled b-informer_info b-informer_big">
                    <div class="b-informer__data">
                        ${newState.error ? `<b class="b-informer__ttl">${newState.error}</b>` : ''}
                        ${newState.message ? `<p class="b-informer__txt">${newState.message}</p>` : ''}
                    </div>
                </div>
            `);
        }
    }

    computeEffects.call(this);
    this._state = newState;

    if (doNotRender) {
        return;
    }

    // render changes - call all methods of {update}
    _(update).map((method) => method.call(this));
}

SatisfactionFormController.render = function () {
    const tpl = `
        <header class="content-block__header">
            <div class="content-block__header-main">
                <h1>${i18n("How would you rate the support you received?")}</h1>
            </div>
        </header>
                                  
        <div class="content-block__data">
            <form id="satisfaction-rating-form" class="static" method="post">
                <ul class="topic-list forms">
                    <li class="forms__section">
                        <div role="informer-holder"></div>
        
                        <div class="forms__item">                        
                            <button role="rate" type="button" value="1" class="btn rating btn-blue">
                                <span class="btn__label">${i18n("Good")}</span>
                            </button>
        
                            <button role="rate" type="button" value="-1" class="btn rating btn-blue">
                                <span class="btn__label">${i18n("Bad")}</span>
                            </button>
                        </div>
  
                        <div class="forms__item" role="comment-block" >
                            <label role="textarea-promt" class="forms__label_wide" for="satisfaction-rating-comment"></label>
                            <textarea rows="3" class="textarea textarea_size_wide" type="text" name="satisfaction-rating-comment" tabindex="1"></textarea>
                        </div>
    
                        <div class="forms__item" role="comment-block">
                            <button role="save" type="button" class="btn btn-blue" tabindex="1">
                                <span class="btn__label">${i18n("Send")}</span>
                            </button>
                            
                            <button role="cancel" type="button" class="btn btn_simple">
                                <span class="btn__label">${i18n("Cancel")}</span>
                            </button>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    `;
    this.holder.html(tpl);
};