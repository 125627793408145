window.Env = {
    isTestingEnv: function () {
        return window.APPLICATION_ENV && ['production', 'staging', 'demo'].indexOf(window.APPLICATION_ENV) === -1;
    },

    isProductionEnv: function () {
        return window.APPLICATION_ENV && window.APPLICATION_ENV === 'production';
    },

    isStagingEnv: function () {
        return window.APPLICATION_ENV && window.APPLICATION_ENV === 'staging';
    },

    isDevelopmentEnv: function () {
        return window.APPLICATION_ENV && window.APPLICATION_ENV === 'development';
    }
};