(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Container implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Container = Class.create();
    Object.extend(GUI.Container.prototype, superproto);

    /**
     * Type of the element, default is 'GUI.Container'
     * @type String
     */
    GUI.Container.prototype.elementType = 'GUI.Container';

    /**
     * Template
     * @type String
     */
    GUI.Container.prototype.template =
        '<table class="<%=data.cls%>"><tr><td><div class="x-main-container-top"><div class="x-main-container-l">' +
        '<div class="x-main-container-r"><div class="x-main-container-c"/></div></div></div></div>' +
        '<div class="x-main-container-mdl"><div class="x-main-container-l">' +
        '<div class="x-main-container-r"><div class="x-main-container-c">' +
        '<div class="main-pad-header"><table class="pad-header"><tr><td class="pad-header">' +
        '<% if (data.captionImage) { %>' +
        '<img class="pad-header" alt="" src="<%=data.captionImage%>" id="<%=data.captionImageId%>" />' +
        '<% } %>' +
        '<span id="<%=data.captionId%>" class="pad-title"><%=data.caption%></span>' +
        '</td><td id="<%=data.custom_toolBoxId%>" class="ico"></td><td id="<%=data.toolBoxId%>" rowspan="2" class="ico"></td></tr>' +
        '<tr><td colspan="2"><div id="<%=data.pathId%>"></div></td></tr></table></div>' +
        '<div id="<%=data.containerId%>" class="x-main-container-holder"></div>' +
        '</div></div></div></div>' +
        '<div class="x-main-container-btm">' +
        '<div class="x-main-container-l"><div class="x-main-container-r">' +
        '<div class="x-main-container-c"/></div></div></div></td></tr></table>';

    /**
     * Name of the container
     * @type String
     */
    GUI.Container.prototype.name = null;

    /**
     * Caption of the container
     * @type String
     */
    GUI.Container.prototype.caption = '';

    /**
     * Url of th eimage of the caption
     * @type String
     */
    GUI.Container.prototype.captionImage = null;

    /**
     * Array of the elements for path
     * @type Array
     */
    GUI.Container.prototype.path = null;

    /**
     * Url of the icon
     * @type String
     */
    GUI.Container.prototype.icon = null;

    /**
     * Content of the container
     * @type String
     */
    GUI.Container.prototype.content = '';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Container.prototype.visible = true;

    /**
     * Css class of the container, default is ''
     * @type String
     */
    GUI.Container.prototype.cls = '';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Container.prototype.initialize = function (config) {
        if (config) {
            Object.extend(this, config);
        }
        // Call parent method
        superproto.initialize.call(this);

        this.getId();

        this.toolBoxId      = this.id + '-toolbox';
        this.containerId    = this.id + '-container';
        this.captionId      = this.id + '-container-caption';
        this.captionImageId = this.id + '-container-caption-image';

        this.pathId         = this.id + '-container-path';
        this.custom_toolBoxId = this.id + 'custom-toolbox';

        if (this.toolbox) {
            this.toolBox = new GUI.ToolBox(this.toolbox);
        }

        if (this.name) {
            GUI.ComponentMgr.register(this.name, this);
        } else {
            GUI.ComponentMgr.register(this);
        }
    };

    /**
     * Returns id
     * @returns {String|Number} id
     */
    GUI.Container.prototype.getId = function () {
        return this.id || (this.id = "jsgui-cmp-" + (++GUI.Component.AUTO_ID));
    };

    /**
     * Destroys objects, removes events listeners
     */
    GUI.Container.prototype.destroy = function () {
        if (this.dom) {
            this.removeEventListeners();
            if (this.toolBox) {
                this.toolBox.destroy(true);
            }
            GUI.destroyNode(this.dom);
            this.dom = this.pathEl = this.captionEl = this.content = null;
        }
        GUI.ComponentMgr.unregister(this);
        GUI.destroyObject(this);
    };

    /**
     * Renders objects
     * @param {HTMLElement} to Element to redener object to
     */
    GUI.Container.prototype.render = function (to) {
        var div, html;

        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }

        div = document.createElement('DIV');
        div.id = this.id;
        div.className = this.cls;
        if (!this.visible) {
            GUI.hide(div);
        }

        html = GUI.Template(this.template, {
            data    : this
        }, this.elementType);

        to = GUI.$(to);
        if (GUI.isIE) {
            to.appendChild(div);
            div.innerHTML = html;
        } else {
            div.innerHTML = html;
            to.appendChild(div);
        }

        this.dom = div;
        this.captionEl = GUI.$(this.captionId);
        this.captionImageEl = GUI.$(this.captionImageId);

        this.pathEl = GUI.$(this.pathId);

        if (this.toolBox) {
            this.toolBox.render(this.toolBoxId);
        }

        if (this.custom_toolBox) {
            GUI.$(this.custom_toolBoxId).innerHTML = this.custom_toolBox.html || '';
        }

        this.renderContent();
        if (this.icon) {
            this.captionEl.style.backgroundImage = 'url(' + this.icon + ')';
        }
        this.attachEventListeners();
        this.rendered = true;
    };

    /**
     * Renders html of the path
     */
    GUI.Container.prototype.renderPath = function () {
        var o, item, i, len;
        if (GUI.isArray(this.path) && this.dom) {
            if (this.path.length) {
                o = [];

                // draw path
                for (i = 0, len = this.path.length; i < len - 1; i++) {
                    item = this.path[i];
                    o.push('<li><a id="' + item.id + '" href="#">' + item.name + '</a></li>');
                }

                if (i === len - 1) {
                    item = this.path[i];
                    o.push('<li>' + item.name + '</li>');
                }

                o = '<ul class="main-pad-header-menu">' +
                    o.join('<li><img src="mainpad-header-ttl-seprtr.gif" alt="" /></li>') +
                    '</ul>';
                this.pathEl.innerHTML = o;
            } else {
                this.pathEl.innerHTML = '';
            }
        }
    };

    /**
     * Sets path
     * @param {Array} path Elements of the path
     */
    GUI.Container.prototype.setPath = function (path) {
        if (GUI.isArray(path) && path.length) {
            this.path = path;

            var len = path.length;
            while (len--) {
                path[len].id = GUI.getUniqId('path-');
            }
        }
        this.renderPath();
    };

    /**
     * Handler click on the path
     * @param {Event} e Event
     */
    GUI.Container.prototype.onPathClick = function (e) {
        var target, path, elem, len, itemNode;

        e = GUI.Event.extend(e);
        e.preventDefault();
        target = e.target;
        itemNode = target && target.findParent('A', GUI.$(this.pathId));

        if (itemNode) {
            path = this.path;
            len = path.length;

            while (len--) {
                elem = path[len];
                if (elem.id === itemNode.id) {
                    if (GUI.isFunction(elem.onClick)) {
                        elem.onClick(elem);
                    }
                }
            }
        }
    };

    /**
     * Shows dom
     */
    GUI.Container.prototype.show = function () {
        if (this.dom && !this.visible) {
            GUI.show(this.dom);
            this.visible = true;
        }
    };

    /**
     * Hides dom
     */
    GUI.Container.prototype.hide = function () {
        if (this.dom && this.visible) {
            GUI.hide(this.dom);
            this.visible = false;
        }
    };

    /**
     * Renders content
     * @param {String} content Content of the container
     */
    GUI.Container.prototype.renderContent = function (content) {
        var cfg = this.config;
        if (content) {
            cfg.content = content;
        }

        if (GUI.isString(this.content)) {
            GUI.$(this.containerId).innerHTML = this.content;
        } else if (GUI.isFunction(this.content.render)) {
            this.content.render(this.containerId);
        }
    };

    /**
     * Returns id of the container
     * @returns {String|Number} id
     */
    GUI.Container.prototype.getContentId = function () {
        return this.containerId;
    };

    /**
     * Sets caption
     * @param {String} caption Caption of the container
     */
    GUI.Container.prototype.setCaption = function (caption) {
        this.caption = caption;
        if (this.dom) {
            this.captionEl.innerHTML = caption;
        }
    };

    /**
     * Sets caption image
     * @param {String} path Url of the image
     */
    GUI.Container.prototype.setCaptionImage = function (path) {
        this.captionImageEl.src = path;
    };

    /**
     * Adds event 'click'
     */
    GUI.Container.prototype.attachEventListeners = function () {
        this.pathEl.on('click', this.onPathClick, this);
    };

    /**
     * Removes event 'click'
     */
    GUI.Container.prototype.removeEventListeners = function () {
        this.pathEl.un('click', this.onPathClick, this);
    };

    /**
     * Returns dom
     * @returns {HTMLElemnt} dom
     */
    GUI.Container.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * empty function
     */
    GUI.Container.prototype.setSize = function (size) {};

}());
