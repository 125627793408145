(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
    * JavaScript Graphical User Interface
    * Splitter implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Utils.Draggable
    */
    GUI.Splitter = Class.create();
    Object.extend(GUI.Splitter.prototype, superproto);

    /**
     * Css class, default is 'jsgui-splitter'
     * @type String
     */
    GUI.Splitter.prototype.cls = 'b-splitter';

    /**
     * Vertical position, default is false
     * @type Boolean
     */
    GUI.Splitter.prototype.vertical = false;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Splitter.prototype.initialize = function (config) {
        if (config) {
            Object.extend(this, config);
        }

        this.config = {
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                isTarget    : true,
                moveOnly    : true,
                groups      : {
                    'splitter' : true
                },
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            }
        };

        superproto.initialize.call(this, config);
        this.id = GUI.getUniqId('jsgui-splitter-');
    };

    /**
     * Renders objects
     * @param {HTMLElement} to Element to render dom to
     */
    GUI.Splitter.prototype.render = function (to) {
        this.dom = document.createElement('div');
        this.dom.id = this.id;
        this.dom.style.position = 'relative';
        this.dom.style.height = '200px';
        to.appendChild(this.dom);

        var el = document.createElement('I');
        el.className = this.cls;
        this.dom.appendChild(el);

        if (!this.vertical) {
            el.addClass('b-splitter_horizontal');
        }

        GUI.Dom.extend(this.dom);

        this.config.dd.linkedElId = this.id;
        this.config.dd.handleElId = this.id;
        this.initDD();
    };

    /**
     * Destroy objects
     */
    GUI.Splitter.prototype.destroy = function () {
        this.linkedEl = null;
        if (this.dom) {
            this.destroyDD();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
        if (this.dragProxy) {
            this.dragProxy.destroy();
            this.dragProxy = null;
        }
        if (this.overlay) {
            this.overlay.destroy();
            this.overlay = null;
        }
    };

    /**
     * Returns dom
     * @returns {HTMLElement} dom
     */
    GUI.Splitter.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Creates proxy element
     */
    GUI.Splitter.prototype.onDragStart = function () {
       /* if (!this.overlay) {
            this.overlay = new GUI.Popup.Region({
                className   : 'jsgui-overlay'
            });
        }
        this.overlay.takeDocument();
        this.overlay.show();

        if (!this.dragProxy) {
            this.dragProxy = new GUI.Popup.Region({
                className    : this.cls + ' jsgui-splitter-dragproxy jsgui-splitter-' + (this.vertical ? 'vertical' : 'horizontal')
            });
        }
        this.dragProxy.takeRegion(this.dom);
        this.dragProxy.show();*/
    };

    /**
     * Returns new height
     * @param {Number} pos
     * @returns {Number} height
     */
    GUI.Splitter.prototype.getNewHeight = function (pos) {
        var delta = this.position === 'top' ? pos - this.DDM.startY : this.DDM.startY - pos;

        if (typeof (this.linkedEl.minHeight) === 'string') {
            this.linkedEl.minHeight = Math.round((parseInt(GUI.getViewportHeight(), 10) / 100) * parseInt(this.linkedEl.minHeight, 10));
        }
        if (typeof (this.linkedEl.maxHeight) === 'string') {
            this.linkedEl.maxHeight = Math.round((parseInt(GUI.getViewportHeight(), 10) / 100) * parseInt(this.linkedEl.maxHeight, 10));
        }

        return (this.linkedEl.getHeight() + delta).constrain(this.linkedEl.minHeight, this.linkedEl.maxHeight);
    };

    /**
     * Returns new width
     * @param {Number} pos
     * @returns {Number} width
     */
    GUI.Splitter.prototype.getNewWidth = function (pos) {
        var delta = this.position === 'left' ? pos - this.DDM.startX : this.DDM.startX - pos;

        if (typeof (this.linkedEl.maxWidth) === 'string') {
            this.linkedEl.maxWidth = Math.round((parseInt(GUI.getWidth(), 10) / 100) * parseInt(this.linkedEl.maxWidth, 10));
        }
        if (typeof (this.linkedEl.minWidth) === 'string') {
            this.linkedEl.minWidth = Math.round((parseInt(GUI.getWidth(), 10) / 100) * parseInt(this.linkedEl.minWidth, 10));
        }
        return (this.linkedEl.getWidth() + delta).constrain(this.linkedEl.minWidth, this.linkedEl.maxWidth);
    };

    /**
     * Returns position Y
     * @param {Number} height
     * @returns {Number} pos
     */
    GUI.Splitter.prototype.getPositionY = function (height) {
        var delta = this.position === 'top' ? height - this.linkedEl.getHeight() : this.linkedEl.getHeight() - height;
        return this.DDM.startY + delta;
    };

    /**
     * Returns position X
     * @param {Number} width
     * @returns {Number} pos
     */
    GUI.Splitter.prototype.getPositionX = function (width) {
        var delta = this.position === 'left' ? width - this.linkedEl.getWidth() : this.linkedEl.getWidth() - width;
        return this.DDM.startX + delta;
    };

    /**
     * Sets left and top style of the item
     * @param {Event} e Event
     */
    GUI.Splitter.prototype.onDrag = function (e) {
      /*  var pos, newHeight, newWidth,
            style = this.dragProxy.dom.style;

        if (this.vertical) {
            pos = e.getPageXY()[1];
            newHeight = this.getNewHeight(pos);
            style.top = (this.getPositionY(newHeight) - this.DDM.deltaY) + 'px';
        } else {
            pos = e.getPageXY()[0];
            newWidth = this.getNewWidth(pos);
            style.left = (this.getPositionX(newWidth) - this.DDM.deltaX) + 'px';
        }*/
    };

    /**
     * Hides proxy element
     * @param {Event} e Event
     */
    GUI.Splitter.prototype.onDragEnd = function (e) {
        var pos, height, width;
        //this.overlay.hide();
        //this.dragProxy.hide();

        if (this.vertical) {
            pos = e.getPageXY()[1];
            height = this.getNewHeight(pos);
            this.linkedEl.setHeight(height);
        } else {
            pos = e.getPageXY()[0];
            width = this.getNewWidth(pos);
            this.linkedEl.setWidth(width);
        }
        this.linkedEl.ownerCt.doLayout();
    };

    /**
     * Hides dom
     */
    GUI.Splitter.prototype.hide = function () {
        if (this.dom) {
            GUI.hide(this.dom);
        }
    };

    /**
     * Shows dom
     */
    GUI.Splitter.prototype.show = function () {
        if (this.dom) {
            GUI.show(this.dom);
        }
    };

}());