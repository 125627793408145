(function () {
    var Region, Point;

    Region = function (t, r, b, l) {
        this.top = t;
        this[1] = t;
        this.right = r;
        this.bottom = b;
        this.left = l;
        this[0] = l;
    };

    /**
     * JavaScript Graphical User Interface
     * Region implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     */
    Region.prototype = {

        /**
         * Returns object with left, right, top and bottom values.
         * @param {Object} region
         * @returns {Object}
         */
        contains: function (region) {
            return (
                region.left     >= this.left  &&
                region.right    <= this.right &&
                region.top      >= this.top   &&
                region.bottom   <= this.bottom
            );
        },

        /**
         * Returns area
         * @returns {Number}
         */
        getArea: function () {
            return ((this.bottom - this.top) * (this.right - this.left));
        },

        /**
         * Returns height
         * @returns {Number} height
         */
        getHeight: function () {
            return this.bottom - this.top;
        },

        /**
         * Returns width
         * @returns {Number} width
         */
        getWidth: function () {
            return this.right - this.left;
        },

        /**
         * Creates region into the intersection area
         */
        intersect: function (region) {
            var t = Math.max(this.top,    region.top),
                r = Math.min(this.right,  region.right),
                b = Math.min(this.bottom, region.bottom),
                l = Math.max(this.left,   region.left);

            if (b >= t && r >= l) {
                return new Region(t, r, b, l);
            } else {
                return null;
            }
        },

        /**
         * Cerate region into union area
         */
        union: function (region) {
            var t = Math.min(this.top,     region.top),
                r = Math.max(this.right,   region.right),
                b = Math.max(this.bottom,  region.bottom),
                l = Math.min(this.left,    region.left);

            return new Region(t, r, b, l);
        },

        /**
         * Adjusts values
         * @param {Number} t Top
         * @param {Number} l Left
         * @param {Number} b Bottom
         * @param {Number} r Right
         */
        adjust: function (t, l, b, r) {
            this.top += t;
            this.left += l;
            this.right += r;
            this.bottom += b;
            return this;
        }
    };

    /**
     * Returns region
     * @param {Object} el
     * @returns {Object} region
     */
    Region.getRegion = function (el) {
        var p = GUI.getPosition.getXY(el, true),
            t = p[1],
            r = p[0] + el.offsetWidth,
            b = p[1] + el.offsetHeight,
            l = p[0];

        return new Region(t, r, b, l);
    };

    /**
     * JavaScript Graphical User Interface
     * Point implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     */
    Point = function (x, y) {
        if (GUI.isArray(x)) {
            y = x[1];
            x = x[0];
        }
        this.x = this.right = this.left = this[0] = x;
        this.y = this.top = this.bottom = this[1] = y;
    };

    Point.prototype = new Region();

    GUI.Utils.Region = Region;
    GUI.Utils.Point  = Point;
}());