window.route = function (name, params, _addBaseUrl) {
    var route, routeArray, elemRouteArray, clearElemRouteArray,
        elemsRouteArray = [], elemsRouteStr = '', systemElems = [],
        i, a, defParams, length, param,
        routes = window.routes,
        _baseUrl;

    route = routes[name][0];
    defParams = routes[name][1];

    if (name === 'admin'
        ||
        name === 'sadmin'
    ) {
        _baseUrl = app_url;

        elemsRouteStr = name + '/';

        if (params) {
            elemsRouteStr += params.controller + '/' + params.action + '/' + connector(params);

        } else {
            return elemsRouteStr;
        }

    } else {
        if (params && params.ignoreBaseUrl === true) {
            _baseUrl = '/';
        } else {
            _baseUrl = base_url;
        }

        routeArray = route.split('/');

        // set params
        for (i = 0; i < routeArray.length; i++) {
            elemRouteArray = routeArray[i];
            clearElemRouteArray = elemRouteArray.replace(':', '');

            if (elemRouteArray[0] === ':') {

                if (params[clearElemRouteArray]) {
                    elemsRouteArray.push(params[clearElemRouteArray]);
                } else {
                    elemsRouteArray.push(elemRouteArray);
                }
            } else {
                elemsRouteArray.push(elemRouteArray);
                systemElems.push(elemRouteArray);
            }
        }

        splitter(elemsRouteArray, params);
        // delete default values
        length = elemsRouteArray.length - 1;

        while (elemsRouteArray[length] && elemsRouteArray[length][0] === ':') {
            elemsRouteArray.pop();
            length = elemsRouteArray.length - 1;

            elemsRouteStr = elemsRouteArray.join('/');
            if (elemsRouteStr.search(':') !== -1 && GUI.arrayIndexOf(systemElems, elemsRouteArray[length]) !== -1) {
                elemsRouteArray.pop();
                length = elemsRouteArray.length - 1;
            }
        }

        elemsRouteStr = elemsRouteArray.join('/');

        // set default values if needed
        if (elemsRouteStr.search(':') !== -1) {

            for (a in defParams) {
                elemsRouteStr = elemsRouteStr.replace(':' + a, defParams[a]);
            }
        }
    }

    if (elemsRouteStr[elemsRouteStr.length - 1] === '*') {
        elemsRouteStr = elemsRouteStr.replace('*', connector(params));
    }

    function splitter(items, params) {
        var i;

        for (i = 0; i < items.length; i++) {
            switch (items[i]) {
            case ':itemTitle':
                if (params.item && params.title) {
                    items[i] = items[i].replace(items[i], 'i' + params.item + '-' + params.title);
                }
                break;
            case ':categoryTitle':
                if (params.category && params.title) {
                    items[i] = items[i].replace(items[i], 'c' + params.category + '-' + params.title);
                }
                break;
            case ':userKeyName':
                if (params.userKey && params.name) {
                    items[i] = items[i].replace(items[i], 'u' + params.userKey + '-' + params.name);
                }
                break;
            case ':spaceName':
                if (params.space && params.name) {
                    items[i] = items[i].replace(items[i], 's' + params.space + '-' + params.name);
                }
                break;
            }
        }
    }

    function connector(params) {
        var p, str = '';

        for (p in params) {
            if (p !== 'controller' && p !== 'action') {
                str += p + '/' + encodeURIComponent(params[p]) + '/';
            }
        }
        return str;
    }

    if (_addBaseUrl === false) {
        return elemsRouteStr;
    } else {
        return _baseUrl + elemsRouteStr;
    }
};

window.AdminRouter = function (name, params_, systemConfig_) {
    var routes, dataRoute, routePath,
        systemConfig = {},
        params = {},
        routeAdmin = '';

    systemConfig = {
        domain  : true
    };
    Object.extend(systemConfig, systemConfig_);

    Object.extend(params, params_);

    if (systemConfig.domain) {
        routeAdmin = app_url + route('admin').replace('/', '#');
    }

    dataRoute = getRoute(name);
    if (!dataRoute) {
        return name;
    }

    applyParams(params);

    routePath = new GUI.STemplate(dataRoute.tpl, params).fetch();
    routePath = routeAdmin + clearPath(routePath);


    function getRoute(name) {
        routes = {
            tickets: {
                tpl: 'tickets/{this.page}/{this.action}/{this.ticketKey}/{this.searchStr}',
                settings: {
                    page            : {defValue: '',    required: ''},
                    action          : {defValue: '',    required: ''},
                    ticketKey       : {defValue: '',    required: 't'},
                    searchStr       : {defValue: '',    required: ''}
                }
            },
            community   : {
                tpl : 'community/{this.space}/{this.channel}/{this.category}/{this.page}/{this.action}/{this.itemKey}/{this.commentPage}/{this.commentFilter}/{this.searchStr}',
                settings    : {
                    space           : {defValue: '',    required: 's'},
                    channel         : {defValue: 'all', required: ''},
                    category        : {defValue: '',    required: 'c'},
                    page            : {defValue: '',    required: ''},
                    action          : {defValue: '',    required: ''},
                    itemKey         : {defValue: '',    required: 'i'},
                    commentPage     : {defValue: '',    required: ''},
                    commentFilter   : {defValue: '',    required: ''},
                    searchStr       : {defValue: '',    required: ''}
                }
            },
            moderation  : {
                tpl         : 'moderation/{this.filter}',
                settings    : {
                    filter  : {defValue: '',    required: ''}
                }
            },
            spaces      : {
                tpl         : 'spaces/{this.action}/{this.spaceKey}',
                settings    : {
                    action      : {defValue: '',    required: ''},
                    spaceKey    : {defValue: '',    required: 's'}
                }
            },

            userView        : {
                tpl: 'people/{this.type}/{this.userKey}',
                settings: {
                    type: {defValue: 'view', required: ''},
                    userKey: {defValue: '', required: 'u'}
                }
            },

            people      : {
                tpl         : 'people/{this.page}/{this.searchStr}',
                settings    : {
                    page        : {defValue: '',    required: ''},
                    searchStr   : {defValue: '',    required: ''}
                }
            },
            settings    : {
                tpl         : 'settings/{this.category}/{this.action}/{this.id}/{this.rule}',
                settings    : {
                    category    : {defValue: '',    required: ''},
                    action      : {defValue: '',    required: ''},
                    id          : {defValue: '',    required: ''},
                    rule        : {defValue: '',    required: ''}
                }
            }
        };
        return routes[name];
    }

    function applyParams(params) {
        var settingName,
            settings = dataRoute.settings;

        if (settings) {
            for (settingName in settings) {

                if (!params[settingName]) {
                    params[settingName] = settings[settingName].defValue;
                }

                // exist setting name
                if (params[settingName] && settings[settingName].required &&

                        // not equal default value
                        params[settingName] !== settings[settingName].defValue &&

                        // not required value in setting
                        //params[settingName].toString().search(settings[settingName].required) === -1) {
                        params[settingName].toString()[0] !== settings[settingName].required) {

                    // added required value to setting
                    params[settingName] = settings[settingName].required + params[settingName];
                }
            }
        }
    }

    function clearPath(path) {
        return path.trim('/').replace(/(\/){2,}/, '/');
    }

    return routePath;
};
