(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * BreadCrumbs implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.BreadCrumbs = Class.create();
    Object.extend(GUI.BreadCrumbs.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.BreadCrumbs.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);

        // Default config
        this.config =  {
            id      : GUI.getUniqId('breadcrumbs-'),
            name    : 'BreadCrumbs',
            cls     : 'b-breadcrumbs',
            holder  : null,
            items   : []
        };
        var cfg = this.config;
        if (config) {
            Object.extend(cfg, config);
        }

        this.visible = cfg.visible;

        this.addEvents({
            update: true
        });

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
    };

    /**
     * Destroy objects
     */
    GUI.BreadCrumbs.prototype.destroy = function () {
        if (this.config.name) {
            GUI.ComponentMgr.unregister(this);
        }
        this.purgeListeners();
        GUI.destroyObject(this);
    };

    /**
     * Removes events listeners, dom
     */
    GUI.BreadCrumbs.prototype.unrender = function () {
        this.removeEventListeners();
        GUI.remove(this.dom);
        this.dom = null;
    };

    /**
     * Adds event 'click'
     */
    GUI.BreadCrumbs.prototype.attachEventListeners = function () {
        this.dom.on('click', this.onClick, this);
    };

    /**
     * Removes event 'click'
     */
    GUI.BreadCrumbs.prototype.removeEventListeners = function () {
        this.dom.un('click', this.onClick, this);
    };

    /**
     * Renders dom
     * @param {HTMLElement} to Element to render dom to
     * @param {Boolean} before Method of adding html in dom
     */
    GUI.BreadCrumbs.prototype.render = function (to, before) {
        if (this.dom) {
            this.unrender();
        }

        var cfg = this.config,
            items = cfg.items,
            holder,
            ul_el = document.createElement('UL');

        ul_el.id = cfg.id;
        ul_el.className = cfg.cls;

        GUI.hide(ul_el);
        if (to) {
            cfg.holder = to;
        }
        holder = GUI.$(cfg.holder);

        if (before) {
            holder.insertBefore(ul_el, holder.firstChild);
        } else {
            holder.appendChild(ul_el);
        }

        if (cfg.extraClass) {
            ul_el.addClass(cfg.extraClass);
        }

        this.dom = ul_el;
        this.setItems(items);

        // Now can show
        GUI.show(ul_el);
        this.attachEventListeners();
    };

    /**
     *
     */
    GUI.BreadCrumbs.prototype.setItems = function (items) {
        var i, item,
            tpl = new GUI.STemplate('<li class="b-breadcrumbs__item"><a href="#" id="{id}" class="b-breadcrumbs__link {cls}">{cfg.title}</a>{delimeter}</li>'),
            delimeter = ' &gt;',
            len = items.length,
            o = new GUI.StringBuffer();

        this.config.items = items;

        for (i = 0; i < len; i++) {
            item = items[i];

            item.id = item.id || GUI.getUniqId('breadcrumbs-item-');
            tpl.id = item.id;
            tpl.cfg = item;
            tpl.delimeter = delimeter;
            tpl.cls = '';

            if ((i + 1) === len) {
                tpl.delimeter = '';
                tpl.cls = 'selected';
            }

            o.append(tpl.fetch());
        }
        this.dom.innerHTML = o.toString();

       // this.fireEvent('update', this, items);
    };

    /**
     * Handler click on the path
     * @param {Event} e Event
     */
    GUI.BreadCrumbs.prototype.onClick = function (e) {
        var target, items, elem, len, itemNode;

        e = GUI.Event.extend(e);
        e.preventDefault();
        target = e.target;
        itemNode = target && target.findParent('A', GUI.$(this.dom));

        if (itemNode) {
            items = this.config.items;
            len = items.length;

            while (len--) {
                elem = items[len];
                if (elem.id === itemNode.id) {
                    if (GUI.isFunction(elem.onClick)) {
                        elem.onClick(elem);
                    }
                }
            }
        }
    };

}());