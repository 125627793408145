(function () {
    var superproto = GUI.Forms.TriggerField.prototype;

    /**
    * JavaScript Graphical User Interface
    * Forms.ColorPicker implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TriggerField
    */
    GUI.Forms.ColorPicker = Class.create();
    Object.extend(GUI.Forms.ColorPicker.prototype, superproto);

    /**
     * Read-only status, default is true
     */
    //GUI.Forms.ColorPicker.prototype.readOnly = true;

    GUI.Forms.ColorPicker.prototype.width = 'auto';

    /**
     * Css class of the field, default is 'b-text-field_color-picker'
     */
    GUI.Forms.ColorPicker.prototype.wrapperClass = 'b-text-field_color-picker b-text-field_color-picker_advanced';

    /**
     *
     */
    GUI.Forms.ColorPicker.prototype.triggerElClassName = 'i.b-text-field__icon';

    /**
     *
     */
    GUI.Forms.ColorPicker.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_advanced {1}">' +
        '<i class="b-text-field__icon b-text-field__icon_visible"><i class="b-text-field__icon color g-left"></i></i>' +
        '<i class="i-text-field" ></i>' +
        '</i>';

    /**
     * Constructor, call parent constructor
     * @param {Object} config
     */
    GUI.Forms.ColorPicker.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Set value of the field to 'value', sets background color of the element to 'value'
     * @param {Number} Hex color
     */
    GUI.Forms.ColorPicker.prototype.setValue = function (value) {
        value = value || '';

        superproto.setValue.call(this, value);

        if (this.colorEl) {
            this.colorEl.style.backgroundColor = value;
        }
    };

    /**
     * Init component, add event 'select'
     */
    GUI.Forms.ColorPicker.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            select: true
        });
    };

    /**
     *
     * @param {type} to
     * @returns {undefined}
     */
    GUI.Forms.ColorPicker.prototype.onAssign = function (to) {
        superproto.onAssign.call(this, to);

        if (GUI.isSet(to.getAttribute('mini'))) {
            this.mini = true;
        } else {
            this.mini = false;
        }

    };

    /**
     * Call parent method
     * @param {HTMLElement} dom Dom Element
     */
    GUI.Forms.ColorPicker.prototype.onAfterRender = function (dom) {
        this.colorEl = dom.firstChild.firstChild;

        superproto.onAfterRender.call(this, dom);

        if (this.mini) {
            this.dom.addClass('b-text-field_color-picker_mini');
            this.triggerEl = dom;
        }
        
        this.fieldEl.on('change', this.onChangeField, this);
    };

    /**
     * Creates color panel, add event 'select'. Create list, show it.
     * Renders color panel to this list. Add event 'mousedown' on document
     * and on the field element.
     */
    GUI.Forms.ColorPicker.prototype.showColorPanel = function () {
        // Show calendar to select date
        if (!this.colorPanel) {
            this.colorPanel = new GUI.ColorPanel({
                clickEvent    : 'mousedown'
            });
            this.colorPanel.on('select', this.onColorPanelSelect, this);
        }

        if (!this.list) {
            this.list = new GUI.Popup.Region({});
        }

        this.list.show();
        this.colorPanel.render(this.list.getElement());
        this.list.alignTo(this.dom, 'tl-bl?');

        document.on('mousedown', this.onDocumentMouseDown, this);
        GUI.Event.on(this.colorEl.parentNode, 'mousedown', this.onMouseDown, this);

        this.colorPanelOpened = true;
    };

    /**
     * Remove event 'mousedown' from the document and from the field element.
     * Remove color panel, hide list;
     */
    GUI.Forms.ColorPicker.prototype.hideColorPanel = function () {
        document.un('mousedown', this.onDocumentMouseDown, this);
        GUI.Event.un(this.colorEl.parentNode, 'mousedown', this.onMouseDown, this);
        this.colorPanel.unrender();
        this.list.hide();
        this.colorPanelOpened = false;
    };

    /**
     *
     */
    GUI.Forms.ColorPicker.prototype.onChangeField = function () {
        this.setValue(this.fieldEl.value);
    };

    /**
     * Handler trigget click. If trigger isn't disabled hide color panel,
     * else show it.
     * @param {Event} e Event
     */
    GUI.Forms.ColorPicker.prototype.onTriggerClick = function (e) {
        e = new GUI.ExtendedEvent(e);
        //e.preventDefault();

        if (!this.disabled) {
            if (this.colorPanelOpened) {
                this.hideColorPanel();
            } else {
                this.showColorPanel();
            }
        }
    };

    /**
     * Fire event 'select', sets value, hide color panel
     * @param {Object} cp Panel
     * @param {Number} value Hex color
     */
    GUI.Forms.ColorPicker.prototype.onColorPanelSelect = function (cp, value) {
        value = '#' + value;

        this.fireEvent('select', this, value);
        this.setValue(value);
        this.hideColorPanel();
    };

    /**
     * Handler document mouse down. Hide color panel
     * @param {Event} e Event
     */
    GUI.Forms.ColorPicker.prototype.onDocumentMouseDown = function (e) {
        e = new GUI.ExtendedEvent(e);
        if (!(GUI.contains(this.list.dom, e.target) || GUI.contains(this.dom, e.target))) {
            this.hideColorPanel();
        }
    };

    /**
     * Handler mouse down on the field, hide color panel
     * @param {Event} e Event
     */
    GUI.Forms.ColorPicker.prototype.onMouseDown = function (e) {
        if (this.colorPanelOpened) {
            this.hideColorPanel();
        }
    };

}());