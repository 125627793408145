var wys = true;

GUI.ErrorsFileUpload = {
    'ERR_UNKNOWN_UPLOAD_ERROR'      : 'i18n.msg_error_on_sever',
    'ERR_ARTICLE_NOT_EXISTS'        : 'i18n.msg_err_article_not_exist',
    'ERR_UPLOAD_PERMISSIONS_INSUFFICIENT'  : 'i18n.msg_err_permis_upload',
    'WORN_FILE_REWRITED'            : 'i18n.msg_err_file_rewrited',
    'ERR_INCORRECT_MIME'            : 'i18n.msg_err_mime_type',
    'ERR_MAX_FILE_SIZE_EXCEEDED'    : 'i18n.msg_err_max_size',
    'ERR_NOT_ALLOWED_FILE_EXT'      : i18n(GUI.i18n.GUI_FILE_EXTENSION_ERROR), // formatString !!!
    'ERR_ZEROSIZE_UPLOAD'           : 'i18n.msg_err_zero_file',
    'ERR_WRONG_FILENAME'            : 'i18n.msg_err_wrong_name',
    'ERR_PHP_TMP_MISSED'            : 'i18n.msg_err_php_tmp_missed',
    'ERR_PHP_FAILD_WRITE_TO_DISK'   : 'i18n.msg_err_php_failed_write_file',
    'ERR_PHP_EXT'                   : 'i18n.msg_err_php_ext',
    'ERR_POST_MAX_SIZE_EXCEEDED'    : 'i18n.msg_err_post_max_size_exeeded'
};

(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Drag&Drop Upload files
     *
     * @author Inna
     * @version 2.0
     * @namespace GUI.DragDropUpload
     */
    GUI.DragDropUpload = Class.create();
    Object.extend(GUI.DragDropUpload.prototype, superproto);

    /**
     * Allowed size of file, default is 209715200
     * @type Number
     */
    GUI.DragDropUpload.prototype.allowedSize = window.uploadMaxSize;

    var allowedTypeFilesImage = [];
    var shortTypeFilesImage = [];
    for(var key in window.allowedImageMimes) {
        if (!window.allowedImageMimes.hasOwnProperty(key)) {
            continue;
        }

        allowedTypeFilesImage.push('.' + window.allowedImageMimes[key] + ' ' + key);

        if (shortTypeFilesImage.indexOf('.' + window.allowedImageMimes[key]) === -1) {
            shortTypeFilesImage.push('.' + window.allowedImageMimes[key]);
        }
    }

    /**
     * Allowed type of image files
     * @type String
     */
    GUI.DragDropUpload.prototype.allowedTypeFilesImage = allowedTypeFilesImage.join(' ');
    /**
     * Allowed type of image files
     * @type String
     */
    GUI.DragDropUpload.prototype.shortAllowedTypeFilesImage = shortTypeFilesImage.join(' ');
    /**
     * Allowed type of flash files
     * @type String
     */
    GUI.DragDropUpload.prototype.allowedTypeFilesFlash = '.swf application/x-shockwave-flash';

    /**
     * Allowed type of media files
     * @type String
     */
    GUI.DragDropUpload.prototype.allowedTypeFilesMedia =
        '.wav audio/x-wav       .mp3 audio/mpeg     .wmv video/x-ms-wmv' +
        '.avi video/x-msvideo   .mpg video/mpeg     .mp4 video/mpeg' +
        '.mpg4 video/mpeg       .mpeg4 video/mpeg   .mpeg video/mpeg' +
        '.mp2 video/mpeg        .mpa video/mpeg     .mpe video/mpeg' +
        '.mpv2 video/mpeg       .mov video/quicktime.qt video/quicktime' +
        '.lsf video/x-la-asf    .lsx video/x-la-asf .asf video/x-ms-asf' +
        '.asr video/x-ms-asf    .asx video/x-ms-asf .movie video/x-sgi-movie' +
        '.flv video/x-flv';

    /**
     * Allowed type of files
     * @type String
     */
    GUI.DragDropUpload.prototype.allowedTypeFiles =
        'jpg, jpeg, bmp, gif, tiff, tif, txt, rar, zip, pdf, tar.gz, png , ' +
        'rtf, doc, dot, docx, docm, dotx, dotm, xls, xlt, xlsx, xlsm, xltx, ' +
        'xltm, ppt, pot, pptx, pptm, potx, potm, vdx, vsd, htm, html, shtml, ' +
        'shtm, php, asp, cfm, cfml, pl, cgi, aspx, jsp, mp3, divx, pdf, iso, csv, js';

    GUI.DragDropUpload.prototype.namesFileList = [];

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.textDone = '<span class="b-image-manager__drop-list_status green">Done</span>';

    /**
     * @type String
     */
	GUI.DragDropUpload.prototype.textUploading = '<span class="b-image-manager__drop-list_status blue" >Uploading</span>';

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.textChecking = '<span class="b-image-manager__drop-list_status blue">Checking</span>';

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.textQueued = '<span class="b-image-manager__drop-list_status black">Queued</span>';

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.textError = '<span class="b-image-manager__drop-list_status red">Failed</span>';

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.textWarning = '<span class="b-image-manager__drop-list_status red">Warning</span>';

    /**
     * @type String
     */
    GUI.DragDropUpload.prototype.typeFile = '';

    /**
     * Initializes components
     * @param {Object} config
     */
    GUI.DragDropUpload.prototype.initialize = function (config) {
        var cfg = {};

        cfg = {
            id          : GUI.getUniqId('dragdropupload-'),
            className   : '',
            parentNode  : document.body,
            text        : 'Drop Files Here <br /> to Upload Them',
            cls         : '',
            addText     : true,
            onLoad      : false
        };
        Object.extend(cfg, config);

        this.config = cfg;

        this.queue = [];
        this.totalFiles = 0;
        this.totalFilesWithError = 0;
        this.totalFilesWithoutError = 0;

        this.totalFilesUploaded = 0;

        this.totalSize = 0;
        this.loaded_size = [];

        this.requests = [];
        this.count_requests = 0;
    };

    /**
     * Shows region and adds events
     */
    GUI.DragDropUpload.prototype.show = function () {
        var div,
            cfg = this.config;

        if (cfg.addText) {
            div = document.createElement('DIV');
            div.id = cfg.id;
            div.className = 'b-image-manager__drop-file-title';

            GUI.$(cfg.parentNode).appendChild(div);
            this.region = div;

            div.innerHTML = cfg.text;
        }

        this.addListener(GUI.$(cfg.parentNode), "dragover", this.blockEvent.bindLegacy(this));

        if (typeof (window.FileReader) === 'undefined') {
            this.showMessage(i18n('Attention!'), i18n(GUI.i18n.GUI_NOT_SUPPORTED_DND));
            this.supported = false;
        } else {
            this.bindDrop(GUI.$(cfg.parentNode));
        }
    };

    /**
     * Initializes dialog, jsonView and progressBar objects
     */
    GUI.DragDropUpload.prototype.initDlg = function () {
        var dlg = new GUI.Popup.Dialog({
            name        : 'stUpload',
            title       : 'Status',
            modal       : true,
            closeOnEsc  : false,
            unrender    : false,
            autoDestroy : false,
            alwaysOnTop : true,
			toolbuttons : [],
            dimensions  : {
                width   : 500
            },
            alignFooterButtons: 'center',
            footerButtons: [],
            content: '<table class="b-forms__table">' +
                    '<tr class="b-forms__row">' +
                        '<td class="b-forms__cell label">' +
                            '<label for="" class="b-forms__label">Files processed </label>' +
                        '</td>' +
                        '<td class="b-forms__cell">' +
                            '<span class="desc" id="files_processed"></span>' +
                        '</td>' +
                    '</tr>' +
                    '<tr class="b-forms__row">' +
                        '<td class="b-forms__cell label">' +
                            '<label for="" class="b-forms__label">Overall Progress </label>' +
                        '</td>' +
                        '<td class="b-forms__cell">' +
                            '<span class="desc" id="overal_progress"></span>' +
                        '</td>' +
                    '</tr>' +
                '</table>' +
                '<div id="progressBarUploadFiles" class="b-image-manager__drop-progress-bar"></div>' +
                '<div id="JVUploadGears"></div>' +
                '<div id="toolbarUpload" class="b-image-manager__drop-toolbar"></div></div>'
        });

        dlg.create();

        this.progressBar = new GUI.ProgressBar();
		this.progressBar.render('progressBarUploadFiles');

        GUI.$('JVUploadGears').innerHTML = '';

        this.JV = new GUI.JsonView({
			holder      : 'JVUploadGears',
			rowsIndex   : 'files',
			preTpl      : '<table class="b-image-manager__drop-list" width="470px" cellpadding="2"><tr>' +
                '<th style="width: 250px;"><span>File Name</span></th>' +
                '<th><span>Size</span></th>' +
                '<th><span>Progress</span></th>' +
                '<th><span>Status</span></th></tr>',

			rowTpl: '<tr id="tr-upload-{this.index}">' +
                '<td class="b-image-manager__drop-list_name">{this.name}</span></td>' +
                '<td>{this.convertedSize}</span></td>' +
                '<td id="progress_{this.index}">{this.progress}%</td>' +
                '<td class="b-image-manager__drop-list_status" id="status_{this.index}">{this.status}</td>' +
                '</tr>',
			postTpl: '</table>',
			noDataTpl: '<tr><td>' + i18n('No Data') + '</td></tr>'
		});

		this.JV.on('filterDataRow', function (jv, row) {
            if (row.error && row.error_type && (row.status.search('img') === -1)) {
                row.status = row.status + ' <i class="b-image-manager__drop-list_help" hint="' + row.error_type + '" hinttype="help">&nbsp;</i>';
            }
		}.bindLegacy(this));

		this.JV.render();
        this.dlg = dlg;
    };

    /**
     * Converts size
     * @type {Number} size
     * @returns {Number} converted size
     */
    GUI.DragDropUpload.prototype.convertSize = function (size) {
        var i = 0;
		function convert() {
			size = (parseInt(size, 10) / 1024).toFixed(1);
			i++;
			size += '';
			if (size.split('.')[0].length > 3) {
                convert();
            }
		}

		size += '';

		if (size.length > 3) {
            convert();
        }

		switch (i) {
        case 0:
            size += ' bytes';
            break;
        case 1:
            size += ' KB';
            break;
        case 2:
            size += ' MB';
            break;
        case 3:
            size += ' GB';
            break;
        default:
            break;
		}
		return size;
    };

    /**
     * Checks type of the file
     * @type {String} name of the file
     * @returns {Boolean}
     */
    GUI.DragDropUpload.prototype.checkTypeFile = function (name) {
        var ext, types, img, media, flash;

        name = name.toLowerCase().split('.');
		ext = name[name.length - 1];

        if (wys) {
            img = this.allowedTypeFilesImage.toLowerCase();
            media = this.allowedTypeFilesMedia.toLowerCase();
            flash = this.allowedTypeFilesFlash.toLowerCase();

            if (img.search(ext) !== -1) {
                this.typeFile = 'Image';
                types =  img;

            } else if (media.search(ext) !== -1) {
                this.typeFile = 'Media';
                types =  media;

            } else if (flash.search(ext) !== -1) {
                this.typeFile = 'Flash';
                types = flash;

            } else {
                this.typeFile = 'Image';
                types =  img;
            }

        } else {
            types = this.allowedTypeFiles.toLowerCase();
        }

		return (types.search(ext) !== -1);
	};

    /**
     * Checks type of the file
     * @type {String} name of the file
     * @returns {Boolean}
     */
    GUI.DragDropUpload.prototype.checkNameFile = function (name) {
        var check = true,
            files = this.namesFileList || [];

		files.each(function (item) {
            if (item.name === name) {
                check = false;
            }
        }.bindLegacy(this));

        return check;
	};

    /**
     * Adds listener
     * @param {HTMLElement} elem
     * @param {Event} event
     * @param {Function|Method} handler
     */
    GUI.DragDropUpload.prototype.addListener = function (elem, event, handler) {
        if (elem.addEventListener) {
            elem.addEventListener(event, handler, false);

        } else if (elem.attachEvent) {
            elem.attachEvent("on" + event, handler);
        }
    };

    /**
     * Removes listener
     * @param {HTMLElement} elem
     * @param {Event} event
     * @param {Function|Method} handler
     */
    GUI.DragDropUpload.prototype.removeListener = function (elem, event, handler) {
        if (elem.removeEventListener) {
            elem.removeEventListener(event, handler, false);
        } else if (elem.detachEvent) {
            elem.detachEvent("on" + event, handler);
        }
    };

    /**
     * Blocks event
     * @type {Event} e
     */
    GUI.DragDropUpload.prototype.blockEvent = function (e) {
        e = e || window.event;

        if (GUI.isIE || GUI.isOpera || GUI.isSafari) {
            try {
                e.dataTransfer.dropEffect = 'none';
                e.dataTransfer.effectAllowed = 'none';
            } catch (_e) {}
        }

        if (e.preventDefault) {
            e.preventDefault();

        } else {
            e.returnValue = false;
        }

        if (e.stopPropagation) {
            e.stopPropagation();

        } else {
            e.cancelBubble = true;
        }
	};

    /**
     * Adds listener to event drop
     * @param {HTMLElement} el
     */
    GUI.DragDropUpload.prototype.bindDrop = function (el) {
        if (el.addEventListener) {
            el.addEventListener("drop", this.onDragDrop.bindLegacy(this), false);
            var collection = jQuery();

            jQuery(window).on("dragenter", function (e) {
                if (collection.length === 0) {
                    jQuery(el).addClass('dz-drag-hover');
                }
                collection = collection.add(e.target);
            }.bindLegacy(this));

            jQuery(window).on("dragleave", function (e) {
                collection = collection.not(e.target);
                if (collection.length === 0) {
                    jQuery(el).removeClass('dz-drag-hover');
                }
            }.bindLegacy(this));
        }
    };

    /**
     * Checks files on errors, creates queue
     * @param {Event} e event
     */
    GUI.DragDropUpload.prototype.onDragDrop = function (e) {
        var data;

        // this.blockEvent(e);

        this.totalFiles = 0;

        data = e.dataTransfer;
        this.totalFiles = data.files.length;
        this.queuing(data.files);
    };

    /**
     * Queuing the files and start process
     * @param {Array} files array of the files
     * @param ignoreFileName
     */
    GUI.DragDropUpload.prototype.queuing = function (files, ignoreFileName=false) {
        var i, file, errorInFile, errorString, allowedTypeFiles,
            len = files.length;

        if (!files || !len) {
            return false;
        }
        this.queue = [];
        this.totalFiles = len || 0;
        this.totalFilesWithError = 0;
        this.totalFilesWithoutError = 0;

        this.totalFilesUploaded = 0;

        this.totalSize = 0;
        this.loaded_size = [];

        this.requests = [];
        this.count_requests = 0;

        for (i = 0; i < len; i++) {
            file = files[i];
            errorInFile = false;
            errorString = '';
            allowedTypeFiles = '';

            var fileName = file.name;

			if (ignoreFileName && (new RegExp(/image\.([a-z]{3})$/)).test(file.name)) {
			    fileName = file.name.replace(/(.*)\.([a-z]{3})$/, 'image-' + (new Date().getTime()) + '.$2');
            }

            // no size
			if (parseInt(file.size, 10) < 1) {
				errorInFile = true;
				errorString =  'This file is 0 bytes, so it hasn\'t been attached';
			}

            // size of file
			if (parseInt(file.size, 10) > parseInt(this.allowedSize, 10)) {
				errorInFile = true;
				errorString =  sprintf(i18n('File size has to be less than %s.'), this.convertSize(this.allowedSize));
			}

            // type of file
			if (!errorInFile && !this.checkTypeFile(fileName)) {
				errorInFile = true;
                if (wys) {
                    allowedTypeFiles = this['shortAllowedTypeFiles' + this.typeFile] ?
                        this['shortAllowedTypeFiles' + this.typeFile] :
                        this['allowedTypeFiles' + this.typeFile];
                } else {
                    allowedTypeFiles = this.allowedTypeFiles.toLowerCase();
                }
				errorString = GUI.formatString('File of this type is not allowed for upload. Allowed files:<br> {0}', allowedTypeFiles);
			}

            // name of file
			// if (!errorInFile && !this.checkNameFile(fileName)) {
			// 	errorInFile = true;
			// 	errorString = 'existFile';
			// }

            this.queue[i] = {
                index       : i,
                name        : fileName,
                file        : file,
                size        : file.size,
                progress    : 0,
                status      : (errorInFile) ? this.textError : this.textQueued,
				uploaded    : 0,
				error       : errorInFile,
				error_type  : errorString,
                type        : this.typeFile
            };

            if (!errorInFile) {
				this.totalFilesWithoutError++;

			} else {
                this.totalFilesWithError++;
            }
        }


		// if (this.totalFiles === 1 && this.queue[0].error && this.queue[0].error_type === 'existFile') {
        //     this.showConfirmMessage(null, null, this.onConfirmLoadExistFile.bindLegacy(this, this.queue[0]));
        //
		// } else if (this.totalFiles === 1 && this.queue[0].error) {
		if (this.totalFiles === 1 && this.queue[0].error) {
            this.showMessage(false, this.queue[0].error_type);

		} else if (this.totalFiles > 0) {
            this.process();
        }
    };

    /**
     * Creates FileReader object, sends and reads file
     */
    GUI.DragDropUpload.prototype.process = function () {
        var i, item, reader,
            queue = this.queue,
            len = queue.length;

        for (i = 0; i < len; i++) {
			item = queue[i];
            item.convertedSize = this.convertSize(item.file.size);

            if (!item.error) {

                if (item.file.binaryString) {
                    this.send(i, item, {result: item.file.file});

                } else if (window.FileReader) {
                    reader = new FileReader();
                    reader.onload = this.send.bindLegacy(this, i, item, reader);
                    reader.readAsDataURL(item.file);
                }
            }
		}
        this.updateStatus(this.totalSize);
    };

    /**
     * Updates json view, shows dialog, updates some labels
     * @param {Number} size
     */
    GUI.DragDropUpload.prototype.updateStatus = function (size) {
        var text_processed, perc_total,
            queue = this.queue,
            total = this.totalFiles;

        size = size || 0;

        if (!this.dlg) {
            this.initDlg();
        }

        this.dlg.show();

        if (total > 1) {
            GUI.show('JVUploadGears');
			this.JV.update({files: queue, total: total});
			this.dlg.center();
		} else {
			GUI.hide('JVUploadGears');
		}

        if (this.totalFiles === this.totalFilesWithError) {
            text_processed = this.totalFiles  + ' of ' + this.totalFiles;

            if ((this.totalFiles - this.totalFilesWithoutError) > 0) {
                text_processed += ' <span class="b-image-manager__drop-list_status red" >(' +
                    (this.totalFiles - this.totalFilesWithoutError) + ' ' + i18n(GUI.i18n.GUI_FILES_FAILED_UPLOAD) + ')</span>';
            }

            if (!GUI.$('files_processed')) {
                return;
            }

            GUI.$('files_processed').innerHTML = text_processed;

            perc_total = Math.floor((1) / size * 100);
            GUI.$('overal_progress').innerHTML = perc_total + '% (' + this.convertSize(0) + ' of ' + this.convertSize(size) + ')';
        }
    };

    /**
     *
     * @param {type} index
     * @param {type} item
     * @param {type} reader
     * @param {type} progress
     * @returns {undefined}
     */
    GUI.DragDropUpload.prototype.send = function (index, item, reader, progress) {
        var boundary = new Date().getTime(),
            name = '',
            result = '',
            type = '',
            body = '',
            url = '';

        if (this.config.updateParams) {
            this.config.updateParams();
        }

        url = this.config.handlerUrl || 'upload/upload.php';

        this.requests[index] = new GUI.Ajax.Request({
            url          : url,
            requestHeader: true,
            autoSendRequest: false,
            convertData  : false,
            onSuccess    : this.onSuccess.bindLegacy(this, index, item), // onComplete
            onFailure    : this.failRequest
        });

        if (progress !== false) {
            this.requests[index].xhr.upload.onprogress = this.uploadProgress.bindLegacy(this, index);
            this.requests[index].xhr.upload.onload = function (index, event) {
                if (event.total && event.loaded) {
                    this.uploadProgress(index, event);
                }
            }.bindLegacy(this, index);
        }

        this.requests[index].xhr.setRequestHeader("Content-Type", "multipart/form-data, boundary=" + boundary);
        this.requests[index].xhr.setRequestHeader("Cache-Control", "no-cache");

        type = item.file.type;

        result = reader.result;
        result = result.replace('data:' + item.file.type + ';base64,', '');
        result = GUI.base64_decode(result);

        name = GUI.utf8_encode(item.name);


body = '--' + boundary + '\n\
Content-Disposition: form-data; name="act"\n\
\n\
upload\n\
--' + boundary + '\n\
Content-Disposition: form-data; name="file[]"; filename="' + name + '"\n\
Content-Type: ' + type + '\n\
\n\
' + result + '\n\
--' + boundary + '--\n\
';

        this.totalSize += body.length;

        if (this.requests[index].xhr.sendAsBinary) {
            this.requests[index].xhr.sendAsBinary(body);
        }
    };

    /**
     * Success request
     * @param {Number} index index of the item of queue
     * @param {Object} xhr request
     */
    GUI.DragDropUpload.prototype.onSuccess = function (index, item, xhr, response) {
        if (xhr.readyState === 4) {
            if (GUI.$('status_' + index)) {
                GUI.$('status_' + index).innerHTML = this.textDone;
            }
            if (this.config.onLoad) {
                this.config.onLoad(item, response.responseJson);
            }
            this.updateDlg();
        }
    };

    /**
     * Fail request
     */
    GUI.DragDropUpload.prototype.failRequest = function () {
        console.log('fail request');
    };

    /**
     * Upload progress
     * @param {Numner} index index of the item of queue
     * @param {Object} xhr
     */
    GUI.DragDropUpload.prototype.uploadProgress = function (index, xhr) {
        var i, text_processed, perc, perc_total,
            total = xhr.total,
            loaded = xhr.loaded,
            loaded_size_processing = 0;

        if (!GUI.$('files_processed')) {
            return false;
        }

		this.loaded_size[index] = loaded;

        for (i = 0; i < this.totalFiles; i++) {
			if (this.loaded_size[i]) {
                loaded_size_processing += parseInt(this.loaded_size[i], 10);
            }
		}

        if (total === loaded) {
            this.totalFilesUploaded++;
        }

		// set data in uploading dlg
		if (this.totalFiles > 1) {
			text_processed = this.totalFilesUploaded + this.totalFilesWithError  + ' of ' + this.totalFiles;

			if (this.totalFilesWithError > 0) {
                text_processed += ' <span class="b-image-manager__drop-list_status red" >(' +
                    this.totalFilesWithError + ' ' + i18n(GUI.i18n.GUI_FILES_FAILED_UPLOAD) + ')</span>';
            }
			GUI.$('files_processed').innerHTML = text_processed;

			GUI.$('status_' + index).innerHTML = this.textUploading;

			perc = Math.floor((loaded || 1) / total * 100);

			GUI.$('progress_' + index).innerHTML = perc + '%';
			this.queue[index].progress = perc;

            if (perc >= 100) {
                GUI.$('status_' + index).innerHTML = this.textChecking;
            }

		} else {
			GUI.$('files_processed').innerHTML = this.queue[index].name;
		}

		// progress bar
		perc_total = Math.round(((loaded_size_processing || 1) / this.totalSize) * 100);

		GUI.$('overal_progress').innerHTML = perc_total + '% (' + this.convertSize(loaded_size_processing) + ' of ' + this.convertSize(this.totalSize) + ')';

		this.progressBar.update(perc_total);
    };

    /**
     *
     * @returns {unresolved}
     */
    GUI.DragDropUpload.prototype.updateDlg = function () {
        this.count_requests = 0;

        if (!GUI.$('files_processed')) {
            return;
        }

        // rewrite redord
        var text_processed = this.totalFiles  + ' of ' + this.totalFiles;

        if ((this.totalFiles - this.totalFilesWithoutError) > 0) {
            text_processed += ' <span class="b-image-manager__drop-list_status red" >(' +
                (this.totalFiles - this.totalFilesWithoutError) +
                ' ' + i18n(GUI.i18n.GUI_FILES_FAILED_UPLOAD) + ')</span>';
        }

        GUI.$('files_processed').innerHTML = text_processed;
        // close dlg

        if (this.totalFilesWithoutError === this.totalFiles) {
            this.dlg.close();
        }
    };

    /**
     *
     * @param {type} caption
     * @param {type} text
     * @returns {undefined}
     */
    GUI.DragDropUpload.prototype.onConfirmLoadExistFile = function (file) {
        file.error = false;
        file.error_type = false;
        this.totalFilesWithError--;
        this.totalFilesWithoutError++;
        this.process();
    };

    /**
     * Shows informer
     * @param {String} caption caption of the informer
     * @param {String} text text of the informer
     */
    GUI.DragDropUpload.prototype.showInfo = function (caption, text) {
        if (!this._informer) {
            this._informer = new GUI.Popup.PopupInformer({
                type: 'info'
            });
        }
        this._informer.setCaption('Success');
        this._informer.setText('Done');
        this._informer.show();
    };

    /**
     * Shows message
     * @param {String} title title of the message
     * @param {String} content content of the message
     */
    GUI.DragDropUpload.prototype.showMessage = function (title, content) {
        this.message = new GUI.Popup.Dialog({
            alwaysOnTop : true,
            id          : 'upload-file-error-dlg',
            title       : title || 'Error!',
            content     : '',
            dimensions  : {
                width   : 400
            },
            toolbuttons : [
                {name: 'close',     img: 'close'}
            ],
            alignFooterButtons  : 'center',
            footerButtons       : [{
                name : 'butOk',
                obj  : new GUI.ToolBar.Button({
                    caption: 'OK',
                    onClick: function () {
                        this.message.close();
                    }.bindLegacy(this)
                })
            }]
        });
        this.message.create();

        this.message.setTitle(title || 'Error!');
        this.message.setContent('<div class="default-data-layout">' + (content || '') + '</div>');
        this.message.show();
    };

    /**
     * Shows message
     * @param {String} title title of the message
     * @param {String} content content of the message
     */
    // GUI.DragDropUpload.prototype.showConfirmMessage = function (title, content, confirmHandler) {
    //     this.message = new GUI.Popup.Dialog({
    //         alwaysOnTop : true,
    //         id          : 'upload-file-confirm-error-dlg',
    //         title       : title || 'Warning!',
    //         modal       : true,
    //         content     : '',
    //         dimensions  : {
    //             width   : 400
    //         },
    //         toolbuttons : [
    //             {name: 'close',     img: 'close'}
    //         ],
    //         alignFooterButtons  : 'right',
    //         footerButtons       : [{
    //             name    : 'butOk',
    //             obj     : new GUI.ToolBar.Button({
    //                 caption : 'Overwrite',
    //                 primary : true,
    //                 onClick : function () {
    //                     if (confirmHandler) {
    //                         confirmHandler();
    //                     }
    //                     this.message.close();
    //                 }.bindLegacy(this)
    //             })
    //         }, {
    //             name : 'butCancel',
    //             obj  : new GUI.ToolBar.Button({
    //                 caption : 'Cancel',
    //                 onClick : function () {
    //                     if (this.config.onCancelOverwrite) {
    //                         this.config.onCancelOverwrite();
    //                     }
    //                     this.message.close();
    //                 }.bindLegacy(this)
    //             })
    //         }]
    //     });
    //     this.message.create();
    //
    //     this.message.setTitle(title || 'Warning!');
    //     this.message.setContent('<div class="default-data-layout">' + (content || 'A file with the same name already exists, would you like to overwrite it?') + '</div>');
    //     this.message.show();
    // };

}());


(function () {

    GUI.UploadFilesDlg = Class.create();

    // GUI.UploadFilesDlg.prototype

    /**
     * Initializes objects
     * @param {Object} config
     */
    GUI.UploadFilesDlg.prototype.initialize = function (config) {
        this.config = {
            url: ''
        };
        Object.extend(this.config, config);

        this.indexInputFile = 0;

        var
            lbl_uploading_dd = 'Upload Using Drag\'n\'Drop',
            lbl_drop_files_here = 'Drop Files Here <br> to Upload Them',
            lbl_uploading_choosing = 'Upload Choosing Files One by One',
            lbl_upload = 'Upload',
            lbl_cancel = 'Cancel';

        this.dlgUpload = new GUI.Popup.Dialog({
            parentNode  : document.body,
            title       : 'Upload',
            modal       : true,
            alwaysOnTop : true,
            toolbuttons : [{name: 'close', img: 'close'}],
            alignFooterButtons: 'center',
            footerButtons: [{
                name : 'butOk',
                obj  : new GUI.ToolBar.Button({
                    caption: lbl_upload,
                    onClick: function () {
                        this.uploadFiles();
                        this.dlgUpload.close();
                    }.bindLegacy(this)
                })
            }, {
                name : 'butCancel',
                obj  : new GUI.ToolBar.Button({
                    caption: lbl_cancel,
                    onClick: function () {
                        this.dlgUpload.close();
                    }.bindLegacy(this)
                })
            }],
            content: '<div id="infoMessageUploadDlg"></div>' +
                '<div id="contentUpload" class="b-image-manager__upload">' +
                '<table width="100%">' +
                '<tr>' +
                    '<td class="b-image-manager__upload-cell"><span class="b-image-manager__upload-title">1. ' + lbl_uploading_dd + '</span></td>' +
                '</tr>' +
                '<tr>' +
                    '<td><div id="upload_drop_area" class="b-image-manager__upload-area" >' +
                            lbl_drop_files_here +
                    '</div></td>' +
                '</tr>' +
                '<tr>' +
                    '<td class="b-image-manager__upload-cell"><span class="b-image-manager__upload-title">2. ' + lbl_uploading_choosing + '</span></td>' +
                '</tr>' +
                '<tr>' +
                    '<td class="b-image-manager__upload-cell-inp">' +
                        '<form name="formUpload" id="formUpload">' +
                            '<div id="tbody_form_files">' +
                                '<div class="b-image-manager__upload-inp">' +
                                    '<input type="file" name="files[]" id="file-' + this.indexInputFile + '"  />' +
                                '</div>' +
                            '</div>' +
                        '</form>' +
                    '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +
                '<div id="toolbarUploadDlg" class="b-image-manager__upload-toolbar"></div>',
            dimensions: {
                width: 470
            }
        });
        this.dlgUpload.on('close', this.destruct.bindLegacy(this));
        this.dlgUpload.create();

        GUI.$('file-' + this.indexInputFile).on('change', this.createFileInput.bindLegacy(this));
        this.indexInputFile++;

        this.form = new GUI.Forms.Form();
        this.form.assign('formUpload');

        this.form.on('afterSubmit', this.afterSubmitForm.bindLegacy(this));
        //this.form.on('invalidSubmit', this.invalidSubmitForm.bindLegacy(this));

        this.uploadFilesArea = new GUI.DragDropUpload({
            parentNode  : 'upload_drop_area',
            onLoad      : function (file) {
                if (this.dlgUpload.dom) {
                    this.dlgUpload.close();
                }
                if (this.config.onLoad) {
                    this.config.onLoad(file);
                }
            }.bindLegacy(this),
            addText     : false
        });
    };

    /**
     * Destructs objects
     */
    GUI.UploadFilesDlg.prototype.destruct = function () {
        this.form.un('afterSubmit', this.afterSubmitForm.bindLegacy(this));
        this.dlgUpload.un('close', this.destruct.bindLegacy(this));
        this.dlgUpload.destroy();
        this.dlgUpload = false;
    };

    /**
     * Shows dialog
     */
    GUI.UploadFilesDlg.prototype.show = function () {
        this.dlgUpload.show();
        this.uploadFilesArea.show();
    };

    /**
     * Shows informer
     * @param {String} caption caption of the informer
     * @param {String} text text of the informer
     */
    GUI.UploadFilesDlg.prototype.showInfo = function (caption, text) {
        if (!this._informer) {
            this._informer = new GUI.Popup.PopupInformer({
                type: 'info'
            });
        }
        this._informer.setCaption('Success');
        this._informer.setText('Done');
        this._informer.show();
    };

    /**
     * Creates input with type file
     */
    GUI.UploadFilesDlg.prototype.createFileInput = function () {
        var tb, index = this.indexInputFile - 1;

		GUI.$('file-' + index).un('change', this.createFileInput.bindLegacy(this));

		tb = GUI.$('tbody_form_files');

        try {
            GUI.Dom.append(tb, '<div class="b-image-manager__upload-inp">' +
								'<input type="file" name="files[]" id="file-' + this.indexInputFile + '" />' +
							'</div>');
            GUI.$('file-' + this.indexInputFile).on('change', this.createFileInput.bindLegacy(this));

        } catch (e) {
            alert(e);
        }
		this.indexInputFile++;
    };

    /**
     * Uploads form with files
     */
    GUI.UploadFilesDlg.prototype.uploadFiles = function () {
        var url, obj = {};

        url = this.config.url || 'upload/upload.php';

		this.form.submit({
			url     : url,
			params  : obj
		});
    };

    /**
     * Handler after submitting form
     * @param {Objects} an request object
     */
    GUI.UploadFilesDlg.prototype.afterSubmitForm = function (an) {
        var mes, i, types,
            text = '',
            response = an.responseText.evalJSON();

		if (response) {

			if (response.errors && response.errors.length > 0) {

				for (i = 0; i < response.errors.length; i++) {

                    if (response.errors[i].error === 'ERR_NOT_ALLOWED_FILE_EXT') {
                        types = '';
                        GUI.ErrorsFileUpload[response.errors[i].error] = GUI.formatString(i18n(GUI.i18n.GUI_FILE_EXTENSION_ERROR), types);
                    }

                    if (response.errors[i].filename) {
                        text += '<b>' + response.errors[i].filename + ':</b> ';
                    }

                    text += GUI.ErrorsFileUpload[response.errors[i].error] + '<br>';
				}

                mes = new GUI.Popup.Dialog({
                    type        : 'error',
                    alwaysOnTop : true,
                    title       : 'Errors and warnings occured during files upload',
                    content     : '<div style="padding: 5px;">' + text + '</div>',
                    dimensions  : {
                        width   : 400
                    },
                    toolbuttons : [
                        {name: 'close',     img: 'close'}
                    ],
                    alignFooterButtons: 'center',
                    footerButtons: [{
                        name : 'butOk',
                        obj  : new GUI.ToolBar.Button({
                            caption: 'OK',
                            onClick: function () {
                                mes.close();
                            }.bindLegacy(this)
                        })
                    }]
                });
                mes.create();
                mes.show();
            }
            this.showInfo('Success', 'Done');

		} else {
            this.showInfo('Error', an.responseText);
		}
    };
}());