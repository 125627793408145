(function () {
    var superproto = GUI.Popup.Dialog.prototype;

    /**
    * JavaScript Graphical User Interface
    * GUI.Popup.MessageBox implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Popup.Dialog
    */
    GUI.Popup.MessageBox = Class.create();
    Object.extend(GUI.Popup.MessageBox.prototype, superproto);

    /**
     * Url of the folder with images
     */
    GUI.Popup.MessageBox.prototype.imgPath =  window.JSGUI_IMAGES_PATH + 'x-messagebox/';

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Popup.MessageBox.prototype.initialize = function (config) {
        var defCfg, cfg, typeCls, html;

        defCfg = {
            id      : GUI.getUniqId('x-messagebox-'),
            modal   : true,
            type    : 'error',
            title   : '',
            text    : '',
            toolbuttons : [
                {name: 'close', title: "Close\nCloses window", img:  this.imgPath + '../x-dialog/x-dialog-ico-close.gif'}
            ],
            buttons     : [],
            buttonsAlign: 'center',
            contentcls  : '',
            dimensions  : {
                left: -10000,
                top: -10000
            },
            visible     : false,
            animate     : window.GUI_FX_ANIMATION === true // boolean convertation
        };
        Object.extend(defCfg, config);

        // Call parent initialize meethod
        superproto.initialize.call(this, defCfg);

        Object.extend(this.config, defCfg);

        this.config.contentcls = 'jsgui-messagebox-text ' + this.config.contentcls.replace(/jsgui-messagebox-text/, '');

        cfg = this.config;

        this.types = {
            error       : 'x-dialog-type-error',
            information : 'x-dialog-type-info',
            confirmation: 'x-dialog-type-info x-dialog-type-confirmation'
        };

        this.elementType = 'GUI.Popup.MessageBox';
        this.buttonsHolderId = GUI.getUniqId('buttons-holder-');
        this.textId = GUI.getUniqId('text-holder-');

        this.defHandlers = {
            close : this.close
        };

        typeCls = this.types[cfg.type];
        html = '';

        if (typeCls) {
            cfg.typeClass = typeCls;
            html += '<div class="jsgui-messagebox-icon"></div><div id="'
                + this.textId + '" class="' + cfg.contentcls + '">' + cfg.text + '</div>';
        } else {
            // Unknown type...
            html = '<div class="' + cfg.contentcls + '" id="'    + this.textId + '">' + cfg.text + '</div>';
        }
       // html += '<div class="jsgui-clear"></div>';

        if (GUI.isArray(cfg.buttons) && cfg.buttons.length) {
            // add buttons
            html += '<div class="buttons-holder" id="' + this.buttonsHolderId + '" style="padding-bottom: 2px;"></div>';
        }
        cfg.content = html;
    };

    /**
     * Destroys objects
     */
    GUI.Popup.MessageBox.prototype.destroy = function () {
        if (this.rendered) {
            this.unrender();
        }

        if (this.btnToolbar) {
            this.btnToolbar.destroy(true);
        }
        superproto.destroy.call(this);
    };

    /**
     * Unrender objects
     */
    GUI.Popup.MessageBox.prototype.unrender = function () {
        if (this.btnToolbar) {
            this.btnToolbar.unrender(true);
        }
        superproto.unrender.call(this);
    };

    /**
     * Creates objects
     */
    GUI.Popup.MessageBox.prototype.create = function () {
        superproto.create.call(this);
        var b, i,
            cfg = this.config;

        // create toolbar with buttons
        if (GUI.isArray(cfg.buttons) && cfg.buttons.length) {
            this.btnToolbar = new GUI.ToolBar({
                position    : 'horizontal',
                align       : cfg.buttonsAlign,
                className   : ''
            });

            for (i = 0; i < cfg.buttons.length; i++) {
                b = cfg.buttons[i];
                this.btnToolbar.add(b.name, new GUI.ToolBar.Button(b));
            }
            this.btnToolbar.render(this.buttonsHolderId);
        }
    };

    /**
     * Closes dialog and removes glass if it was created
     */
    GUI.Popup.MessageBox.prototype.close = function (btn) {
        if (!GUI.isSet(btn)) {
            btn = 'close';
        }
        if (this.config.animate) {
            var self = this;
            this.fadeEf.options.onComplete = function () {
                self._close(btn);
            };
            this.fadeEf.seekTo(0);
        } else {
            this._close(btn);
        }
    };

    /**
     * Sets text
     * @param {String} text
     */
    GUI.Popup.MessageBox.prototype.setText = function (text) {
        this.config.text = text;
        if (this.dom) {
            GUI.$(this.textId).innerHTML = text;
        }
    };

    /**
     * Hides glass, fire events 'hide', 'close'.
     * Run unrender and destroy methods.
     * @param {Object} btn Button
     */
    GUI.Popup.MessageBox.prototype._close = function (btn) {
        this.toBack();
        if (this.glass) {
            this.glass.hide();
        }
        this.fireEvent('hide', this);
        this.fireEvent('close', this, btn);
        if (this.unrender) {
            this.unrender();
        }
        if (this.config && this.config.autoDestroy) {
            this.destroy();
        }
    };

    /**
     * Adjusts width
     * @param {Number} cw Width
     * @returns {Number} width
     */
    GUI.Popup.MessageBox.prototype.adjustContentWidth = function (cw) {
        return GUI.isBorderBox ? cw : cw - 20;
    };

    /**
     * Adjusts height
     * @param {Number} ch Height
     * @returns {Number} height
     */
    GUI.Popup.MessageBox.prototype.adjustContentHeight = function (ch) {
        return GUI.isBorderBox ? ch : ch - 25; // Paddding 10+15
    };

}());
