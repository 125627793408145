(function () {
    var _windowResizeEvent, _windowResizeTask,
        _resizeMutex = false,
        _lastWidth, _lastHeight;

    /**
     * JavaScript Graphical User Interface
     * DOM Events Management
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.Event = {

        /**
         * Add event 'resize' to window
         * @param {Function} listener
         * @param {Object} scope
         */
        onWindowResize: function (listener, scope) {
            if (!_windowResizeEvent) {
                _windowResizeEvent = new GUI.Utils.Event();
                _windowResizeTask = new GUI.Utils.DelayedTask(GUI.Event._windowResizeHandler);

                GUI.Event.on(window, 'resize', GUI.Event.fireWindowResize);
            }
            _windowResizeEvent.addListener(listener, scope);
        },

        /**
         * Removes event listener
         * @param {Function} listener
         * @param {Object} scope
         */
        unWindowResize: function (listener, scope) {
            _windowResizeEvent.removeListener(listener, scope);
        },

        /**
         *
         */
        _windowResizeHandler: function () {
            var curWidth = GUI.getViewportWidth(),
                curHeight = GUI.getViewportHeight();

            if (curWidth !== _lastWidth || curHeight !== _lastHeight) {
                _windowResizeEvent.fire(_lastWidth = curWidth, _lastHeight = curHeight);
            }
        },

        /**
         *
         */
        fireWindowResize: function () {
            if (_resizeMutex) {
                return; // Not reentrant
            }
            _resizeMutex = true;
            if (_windowResizeEvent) {
                if (GUI.isIE) {
                    // Prevent IE from multiple repetitive calls
                    _windowResizeTask.delay(150);
                } else {
                    GUI.Event._windowResizeHandler();
                }
            }
            _resizeMutex = false;
        },

        /**
         * Attaches event listener to the dom element
         * @param {HTMLElement} element
         * @param {String} eventName
         * @param {Function} handler
         * @param {Object} scope
         */
        on: function (element, eventName, handler, scope) {
            // Check if the element is in the cache
            var elementCache, wrapperId, elementId;

            if (!element._jsguiElementId) {
                elementId = ++GUI.Event._elementsCounter;
                element._jsguiElementId = elementId;
                // Precreate cache
                elementCache = (GUI.Event._elementsCache[elementId] = {});
            } else {
                elementCache = GUI.Event._elementsCache[element._jsguiElementId];
            }

            if (!elementCache[eventName]) {
                // No wrapper, must create
                wrapperId = GUI.Event._addWrapper(element, eventName);
                elementCache[eventName] = wrapperId;
            } else {
                // Wrapper exists
                wrapperId = elementCache[eventName];
            }
            GUI.Event._addListener(wrapperId, handler, scope);
        },

        /**
         * Removes event listener from the dom element
         * @param {HTMLElement} element
         * @param {String} eventName
         * @param {Function} handler
         * @param {Object} scope
         */
        un: function (element, eventName, handler, scope) {
            if (!element) {
                console.log('no element in GUI.Event.un');
                console.trace();
                throw new Error("GUI.Event.un: Element is undefined");
            }

            var wrapperId,
                elementId = element._jsguiElementId,
                elementCache = GUI.Event._elementsCache[elementId];

            if (elementCache === undefined) {
                return false;
            }

            if (typeof eventName === 'undefined') {
                // Remove all event listeners for all attached events
                for (eventName in elementCache) {
                    GUI.Event.un(element, eventName);
                }
            } else {
                wrapperId = elementCache[eventName];

                if (!wrapperId) {
                    // No subscriber - no problem
                    return this;
                }

                if (GUI.Event._removeListener(wrapperId, handler, scope)) {
                    // No handlers, remove wrapper
                    GUI.Event._removeWrapper(element, eventName, wrapperId);
                    delete GUI.Event._elementsCache[elementId][eventName];
                }
            }
            return this;
        },

        /**
         * empty function
         */
        removeAll: function () {
            // Temporarily disabled because it requires to store dom nodes in wrappers
        },

        /**
         * Fires DOM event on the node
         * @param {HTMLElement} element
         * @param {String} eventName
         * @param {Object} data
         */
        fire: function (element, eventName, data) {
            GUI.Event.fire = (document.createEvent) ?
                function (element, eventName, data) {
                    var event,
                        doc = element.nodeType === 9 ? element : element.ownerDocument;

                    event = doc.createEvent("HTMLEvents");
                    data = data || {};
                    if (GUI.Event._isDomEvent(eventName)) {
                        // Simulating standard dom event
                        event.initEvent(eventName, true, true);
                        Object.extend(event, data);
                    } else {
                        // Firing custom dom event
                        event.initEvent('dataavailable', true, true);
                        event.eventName = eventName;
                        event.memo = data;
                    }
                    element.dispatchEvent(event);
                }
                :
                function (element, eventName, data) {
                    var event,
                        doc = element.nodeType === 9 ? element : element.ownerDocument;
                    event = doc.createEventObject();
                    data = data || {};
                    if (GUI.Event._isDomEvent(eventName)) {
                        // Simulating standard dom event
                        Object.extend(event, data);
                        event.eventType = 'on' + eventName;
                    } else {
                        event.eventType = 'ondataavailable';
                        event.eventName = eventName;
                        event.memo = data;
                    }
                    element.fireEvent(event.eventType, event);
                };
            return GUI.Event.fire(element, eventName);
        },

        // Private variables
        _elementsCache      : {},
        _elementsCounter    : 0,
        _listeners          : {},
        _listenersCounter   : 0,
        _wrappers           : {},
        _wrappersCounter    : 0,

        /**
         * Returns true if it is native dom event, false if it is custom event
         * @param {String} eventName
         * @returns {Boolean}
         */
        _isDomEvent: function (eventName) {
            return !eventName.include(':');
        },

        /**
         * GUI.Event dispatcher. Extends browser event, calls attached event listeners
         * with extended event as first argument
         * @param {Number} wrapperId
         * @param {Object} evt
         * @param {Object} sender
         */
        _eventDispatcher: function (wrapperId, evt, sender) {
            var listeners, ls, len, i, listener,
                c = GUI.Event._wrappers[wrapperId];

            evt = evt || window.event;

            if (c.eventName && (evt.eventName !== c.eventName)) {
                return false;
            }

            listeners = GUI.Event._listeners;
            // Need to capture listener id's because listeners can be removed
            // in the listener itself causing a lot of troubles
            ls    = c.listeners;
            len    = ls.length;

            if (!c.reverse) {
                for (i = 0; i < len; i++) {
                    listener = listeners[ls[i]];
                    if (listener) {
                        listener[0].call(listener[1], evt);
                    }
                }
            } else {
                while (len--) { // call in reverse order
                    listener = listeners[ls[len]];
                    if (listener) {
                        listener[0].call(listener[1], evt);
                    }
                }
            }
            return this;
        },

        /**
         * Adds event listener to the specified wrapper
         * @param {String} wrapperId
         * @param {Function} listener
         * @param {Object} scope
         */
        _addListener: function (wrapperId, listener, scope) {
            var id = ++GUI.Event._listenersCounter;

            GUI.Event._listeners[id] = [listener, scope];
            // Add listener id to the wrapper's listeners
            GUI.Event._wrappers[wrapperId].listeners.push(id);
        },

        /*
          TODO: Rewrite listeners queue as a linked list.. hm... a lot of pitfalls exist
          Removing listener from event handler is a very dangerous operation
        */
        /**
         * Removes event listener from the wrapper
         * @param {String} wrapperId
         * @param {Function} listener Listener function to remove. If undefined, then all listeners will be removed
         * @param {Object} scope
         */
        _removeListener: function (wrapperId, listener, scope) {
            var listenerId, l,
                listeners = GUI.Event._wrappers[wrapperId].listeners, i = listeners.length;
            if (listener) {
                while (i--) {
                    listenerId = listeners[i];
                    l = GUI.Event._listeners[listenerId];

                    if ((l[0] === listener) && (l[1] === scope)) {
                        // Delete handler from handlers table
                        delete GUI.Event._listeners[listenerId];
                        // Delete handler from wrapper's listeners
                        listeners.splice(i, 1);
                        break;
                    }
                }
                return (listeners.length === 0);
            }

            // Remove all listeners
            while (i--) {
                // Delete listener from the listeners table
                delete GUI.Event._listeners[listeners[i]];
            }
            GUI.Event._wrappers[wrapperId].listeners = [];
            return true;
        },

        // Wrappers handling
        _getOuterWrapper: function (element, ow) {
            return {
                func: function (e) {
                    var rel = e.relatedTarget;
                    if (!((element === rel) || GUI.contains(element, e.relatedTarget))) {
                        return ow.call(this, e);
                    }
                },
                listeners: []
            };
        },

        /**
         * Creates wrapper function that handles event
         * @param {HTMLElement} element
         * @param {String} eventName
         * @return {Integer} Wrapper's id
         */
        _addWrapper: function (element, eventName) { // slow func...
            var id = ++GUI.Event._wrappersCounter, wrapper;

            if (!GUI.isIE && (eventName === 'mouseenter' || eventName === 'mouseleave')) {
                // Extra wrapper is needed to emulate IE's events
                wrapper = this._getOuterWrapper(element, GUI.Event._getWrapper2(id));
                eventName = (eventName === 'mouseenter') ? 'mouseover' : 'mouseout';
            } else {
                wrapper = GUI.Event._getWrapper(id);
            }

            if (!GUI.Event._isDomEvent(eventName)) {
                wrapper.eventName = eventName;
                eventName = 'dataavailable';
            } else {
                if (eventName === 'unload' && element === window) {
                    wrapper.reverse = true;
                }
            }
            GUI.Event._wrappers[id] = wrapper;
            if (element.addEventListener) {
                element.addEventListener(eventName, wrapper.func, false);
            } else {
                element.attachEvent('on' + eventName, wrapper.func);
            }
            return id;
        },

        /**
         * Removes wrapper function
         * @param {HTMLElement} element
         * @param {String} eventName
         * @param {Integer} wrapperId Wrapper's id
         */
        _removeWrapper: function (element, eventName, wrapperId) {
            var wrapper = GUI.Event._wrappers[wrapperId];
            if (!GUI.isIE && (eventName === 'mouseenter' || eventName === 'mouseleave')) {
                eventName = (eventName === 'mouseenter') ? 'mouseover' : 'mouseout';
            }
            if (!GUI.Event._isDomEvent(eventName)) {
                delete wrapper.eventName;
                eventName = 'dataavailable';
            }
            if (element.removeEventListener) {
                element.removeEventListener(eventName, wrapper.func, false);
            } else {
                element.detachEvent('on' + eventName, wrapper.func);
            }
            //delete wrapper.element;
            delete wrapper.listeners;
            delete GUI.Event._wrappers[wrapperId];
        },

        /**
         * Extends passed browser's event object
         * @param {Event} e Event
         */
        extend: function (e) {
            if (e._jsguiExtendedEvent) {
                return e;
            } else {
                return new GUI.ExtendedEvent(e);
            }
        }
    };

    if (GUI.isIE) {
        Object.extend(GUI.Event, {

            /**
             * Returns wrapper to the event dispatcher
             * Prevents memory leakeage due to closures
             * @return {Object}
             */
            _getWrapper: function (wrapperId) { // very slow :(
                return {
                    func: new Function('evt', 'GUI.Event._eventDispatcher(' + wrapperId + ', evt, this)'),
                    listeners: []
                };
            },

            _getWrapper2: function (wrapperId) { // very slow :(
                return new Function('evt', 'GUI.Event._eventDispatcher(' + wrapperId + ', evt, this)');
            }
        });

    } else {
        Object.extend(GUI.Event, {
            // Speedup versions for not ie
            _getWrapper: function (wrapperId) {
                return {
                    func: function (e) {
                        GUI.Event._eventDispatcher(wrapperId, e, this);
                    },
                    listeners: []
                };
            },

            _getWrapper2: function (wrapperId) {
                return function (e) {
                    GUI.Event._eventDispatcher(wrapperId, e, this);
                };
            }
        });
    }

    // Extend document
    Object.extend(document, {
        fire    : GUI.Event.fire.methodize(),
        on      : GUI.Event.on.methodize(),
        un      : GUI.Event.un.methodize(),
        loaded  : false
    });
}());

document.on('dom:loaded', function () {
    var cls, p,
        bd = GUI.getBody();

    if (!bd) {
        return;
    }
    cls = GUI.isIE ? ("x-ie " + (GUI.isIE6 ? 'x-ie6' : GUI.isIE7 ? 'x-ie7' : GUI.isIE8 ? 'x-ie8' : ''))
        : GUI.isGecko ? "x-gecko"
        : GUI.isOpera ? "x-opera"
        : GUI.isSafari ? "x-safari" + (GUI.isMobileSafari ? " x-safari-mobile" : "")
        : GUI.isChrome ? "x-chrome x-safari" : '';

    if (GUI.isBorderBox) {
        cls += ' x-border-box';
    }

    if (GUI.isStrict) {
        p = bd.parentNode;
        if (p) {
            if (p.className && p.className !== '') {
                p.className += ' x-strict';
            } else {
                p.className = 'x-strict';
            }
        }
    }

    if (GUI.LTR === false) {
        cls += ' g-rtl';
        document.body.parentNode.setAttribute('dir', 'rtl');
    }

    bd.addClass(cls);
});

(function () {
    /* Support for the DOMContentLoaded event is based on work by Dan Webb,
     Matthias Miller, Dean Edwards and John Resig. */
    var timer, defer;
    function fireContentLoadedEvent() {
        if (document.loaded) {
            return;
        }
        if (timer) {
            window.clearInterval(timer);
        } else if (document.removeEventListener) {
            document.removeEventListener("DOMContentLoaded", fireContentLoadedEvent, false);
        }
        document.loaded = true;
        document.fire('dom:loaded');
        document.un('dom:loaded');
    }

    if (document.addEventListener) {
        if (GUI.isSafari) {
            // Found bug! When this fires, we can't read padding via GUI.Dom.getStyle
            // Fixed in 3.0.4 ?
            timer = window.setInterval(function () {
                if (/loaded|complete/.test(document.readyState)) {
                    fireContentLoadedEvent();
                }
            }, 0);
            GUI.Event.on(window, 'load', fireContentLoadedEvent);

        } else {
            document.addEventListener("DOMContentLoaded",
                fireContentLoadedEvent, false);
        }
    } else {
        document.write("<script id=\"__onDOMContentLoaded\" defer=\"defer\" src=\"//:\"><\/script>");
        defer = document.getElementById('__onDOMContentLoaded');
        defer.onreadystatechange = function () {
            if (this.readyState === "complete") {
                this.onreadystatechange = null;
                GUI.remove(defer);
                defer = null;
                fireContentLoadedEvent();
            }
        };
        // Needed because "deferred js" method causes bugs with images dimensions
        //GUI.Event.on(window, 'load', fireContentLoadedEvent);
    }
}());

(function () {
    if (autoDestroy) {
        function cleanUpObjects() {
            GUI.destroyObjects();
        }
        GUI.Event.on(window, 'unload', cleanUpObjects);
    }

    if (GUI.isIE) {
        /**
         * It is needed to clean-up all extensions of the Function's prototype
         * to prevent memory leakage
         */
        function cleanUpFp() {
            var fp = Function.prototype;
            delete fp.defer;
            delete fp.bind;
            delete fp.bindAsEventListener;
            delete fp.methodize;
            GUI.Event.un(window, 'unload', cleanUpFp);
        }
        GUI.Event.on(window, 'unload', cleanUpFp);
    }
}());

// Back support
if (!window.Event) {
    Event = {};
}

Event.observe = GUI.Event.on;
Event.stopObserving = GUI.Event.un;
Event.stop = function (e) { GUI.stopPropagation(e); GUI.preventDefault(e); };