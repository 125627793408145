(function () {
    var superproto = GUI.Layouts.Fit.prototype;

    /**
    * JavaScript Graphical User Interface
    * Card Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Layouts
    * @extends GUI.Layouts.Fit
    */
    GUI.Layouts.Card = Class.create();
    Object.extend(GUI.Layouts.Card.prototype, superproto);

    /**
     * Type of the element, default is 'Layouts.Card
     * @type String
     */
    GUI.Layouts.Card.prototype.elementType = 'Layouts.Card';

    /**
     * True to render each contained item at the time it becomes active, false to render all contained items
     * as soon as the layout is rendered (defaults to false).  If there is a significant amount of content or
     * a lot of heavy controls being rendered into panels that are not displayed by default, setting this to
     * true might improve performance.
     * @type Boolean
     */
    GUI.Layouts.Card.prototype.deferredRender = false;

    /**
     * Defaults to true
     * @type Boolean
     */
    GUI.Layouts.Card.prototype.renderHidden = true;

    /**
     * Sets the active (visible) item in the layout.
     * @param {String|Number} item The string component id or numeric index of the item to activate
     */
    GUI.Layouts.Card.prototype.setActiveItem  = function (item) {
        item = this.container.getComponent(item);
        if (this.activeItem !== item) {
            if (this.activeItem) {
                this.activeItem.hide();
            }
            this.activeItem = item;
            item.show();
            this.layout();
        }
    };

    /**
     * Renders all
     * @param {Object} ct Container
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Card.prototype.renderAll  = function (ct, to) {
        if (this.deferredRender) {
            this.renderItem(this.activeItem, undefined, to);
        } else {
            superproto.renderAll.call(this, ct, to);
        }
    };

}());