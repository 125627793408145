(function () {
    checkFeatureRestriction = function(
        featureName
    ) {
        return (window.planFeatures && window.planFeatures[featureName] === false);
    };

    isAppEnabled = function(name) {
        var i;
        for (i = 0; i < window.appsSettings.length; i ++) {
            if (window.appsSettings[i].name == name) {
                return window.appsSettings[i].installed && window.appsSettings[i].allowed;
            }
        }

        return false;
    };

    onWithFeatureRestrictions = function(
        featureName,
        element,
        eventName,
        handler,
        scope,
        config
    ) {
        if (checkFeatureRestriction(featureName)) {
            onWithFeatureDisabled(
                element,
                eventName,
                handler,
                scope,
                config
            );
        } else {
            element.on(eventName, handler, scope);
        }
    };

    onWithFeatureDisabled = function(
        element,
        eventName,
        handler,
        scope,
        config,
        hint
    ) {
        if (false !== hint) {
            if (element.setHint && typeof element.setHint === 'function') {
                element.setHint(getFeatureNotAvailableText());
            } else {
                element.hint = getFeatureNotAvailableText();
            }
        }

        if (element.addClass && typeof element.addClass === 'function') {
            element.addClass('disabled');
        } else {
            GUI.addClass(element, 'disabled');
        }
        element.on(eventName, function() {
            window.showConfirmFeatureNotAvailableDialog(element, config)
        }, scope);
    };

    closeAllDialogs = function()
    {
        for (i = 0; i < GUI.Utils.WM.accessList.length; i++) {
            GUI.Utils.WM.accessList[i].close();
        }
    };

    getFullFeatureNotAvailableText = function (isOwner) {
        return isOwner
            ? getFeatureNotAvailableText() + ' ' + i18n('Please upgrade your Helprace plan to enable this feature.')
            : getFeatureNotAvailableText() + ' ' + i18n('Please ask the account Owner to upgrade Helprace plan to enable this feature.');
    };

    getFeatureNotAvailableText = function()
    {
        return i18n('This feature is not available in your current plan.');
    };

    getMultipleSpacesFeatureDisabledText = function()
    {
        return i18n('You can only have one space in your plan.');
    };

    getFullMultipleSpacesFeatureDisabledText = function(isOwner)
    {
        return isOwner
            ? getMultipleSpacesFeatureDisabledText() + ' ' + i18n('Please upgrade to unlock more spaces.')
            : getMultipleSpacesFeatureDisabledText() + ' ' + i18n('Please ask account Owner to upgrade plan in order to add more spaces.');
    };

    getFullMultipleSpacesFeatureDisabledSaveItemText = function(isOwner)
    {
        var msg = i18n('No changes allowed in this space. It is disabled because there can only be one space in your plan.');
        return isOwner
            ? msg + ' ' + i18n('Please upgrade.')
            : msg + ' ' + i18n('Please ask account Owner to upgrade plan in order to add more spaces.');
    };

    getSpaceDisabledInformerText = function()
    {
        return i18n('This space is disabled. You can only have one space in your plan.');
    };

    getFullSpaceDisabledInformerText = function(isOwner)
    {
        return isOwner
            ? getFullSpaceDisabledInformerText() + ' ' + i18n('Please upgrade to unlock more spaces.')
            : getFullSpaceDisabledInformerText() + ' ' + i18n('Please ask account Owner to upgrade plan in order to add more spaces.')
    };

}());