(function () {
    var superproto = GUI.Forms.TriggerField.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.DatePicker implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TriggerField
    */
    GUI.Forms.DatePicker = Class.create();
    Object.extend(GUI.Forms.DatePicker.prototype, superproto);

    /**
     * If true field is read only, Default is true
     * @type Boolean
     */
    GUI.Forms.DatePicker.prototype.readOnly = true;

    /**
     * If true field is read only, Default is true
     * @type Boolean
     */
    GUI.Forms.DatePicker.prototype.lastFieldValue = '';

    /**
     * Default is 20
     * @type Number
     */
    GUI.Forms.DatePicker.prototype.size = 20;

    /**
     * Default is 'd/m/Y'
     * @type String
     */
    GUI.Forms.DatePicker.prototype.format = 'd/m/Y';

    /**
     * Css class of the field, Default is 'b-text-field_date-picker'
     * @type String
     */
    GUI.Forms.DatePicker.prototype.wrapperClass = 'b-text-field_date-picker';

    /**
     *
     */
    GUI.Forms.DatePicker.prototype.triggerElClassName = 'i.b-text-field__icon';
    
    /**
     *
     */
    GUI.Forms.DatePicker.prototype.iconClass = 'icon fa fa-calendar-alt';

    /**
     *
     */
    GUI.Forms.DatePicker.prototype.calendarAlign = 'tl-bl?';

    /**
     * Width, default is 150
     * @type Number
     */
    GUI.Forms.DatePicker.prototype.width = 114;
    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.DatePicker.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Sets value
     * @param {String} value
     */
    GUI.Forms.DatePicker.prototype.setValue = function (value, silent) {
        this.lastFieldValue = value;
        var date = GUI.parseDate(value, this.format);

        if (date === false) {
            date = GUI.parseDate('1970-01-01T00:00:00+00:00', this.format);
        }

        superproto.setValue.call(this, date ? date.formatDate(this.format) : '', silent);
        if (this.hiddenField) {
            this.hiddenField.value = GUI.parseDate(this.value, this.format).getTime();
        }
    };

    /**
     * Sets date to today
     */
    GUI.Forms.DatePicker.prototype.setToday = function () {
        this.setValue(new Date());
    };

    /**
     * Sets date to date
     * @param {String} date
     */
    GUI.Forms.DatePicker.prototype.setDate = function (date) {
        this.setValue(date.formatDate(this.format));
    };

    /**
     * Returns date
     * @returns {String} date
     */
    GUI.Forms.DatePicker.prototype.getDate = function () {
        return GUI.parseDate(this.value, this.format);
    };

    /**
     * Init component
     */
    GUI.Forms.DatePicker.prototype.initComponent = function () {
        superproto.initComponent.call(this);
    };

    /**
     * Attach events
     */
    GUI.Forms.DatePicker.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);

        this.dom.on('blur', this.applyDate, this);
        this.dom.on('keyup', this.onKeyUp, this);
    };

    /**
     *  Removes events listeners
     */
    GUI.Forms.DatePicker.prototype.removeEventListeners = function () {
        superproto.removeEventListeners.call(this);

        this.dom.un('blur', this.applyDate, this);
        this.dom.un('keyup', this.onKeyUp, this);
    };

    GUI.Forms.DatePicker.prototype.applyDate = function () {
        if (this.calendar) {
            var date;

            try {
                this.value = GUI.parseDate(this.getValue(), this.format).formatDate(this.format);
            } catch(TypeError) {
                this.setValue(new Date().formatDate(this.format));
            }

            if (this.calendar) {
                this.calendar.setDate(this.getDate());
            }
        }
    };

    /**
     * Sets format
     * @param {HTMLelement} elem
     */
    GUI.Forms.DatePicker.prototype.onAssign = function (elem) {
        superproto.onAssign.call(this, elem);

        var format = elem.getAttribute('format');
        if (format) {
            this.format = String(format);
        }
    };

    /**
     * Render text field
     */
    GUI.Forms.DatePicker.prototype.onRender = function () {
        return superproto.onRender.call(this);
    };

    /**
     * Handler after render
     * @param {HTMLelement} dom Dom
     */
    GUI.Forms.DatePicker.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, dom);
    };

    /**
     * Render calendar, add events 'mousedown' on document and the field element
     */
    GUI.Forms.DatePicker.prototype.showCalendar = function () {
        // Show calendar to select date
        if (!this.calendar) {
            this.calendar = new GUI.Calendar();
            this.calendar.on('dayClick', this.onCalendarSelect, this);
        }

        var date = this.getDate();
        if (date) {
            this.calendar.setDate(date);
        }

        if (!this.list) {
            this.list = new GUI.Popup.Region({});
        }

        this.list.show();
        this.calendar.render(this.list.getElement());
        this.list.alignTo(this.dom, this.calendarAlign);
        GUI.Event.on(document, 'mousedown', this.onDocumentMouseDown, this);
        GUI.Event.on(this.fieldEl, 'mousedown', this.onMouseDown, this);

        this.calendarOpened = true;
    };

    /**
     * Remove event 'mousedown' from document and from the field element,
     * destroy calendar
     */
    GUI.Forms.DatePicker.prototype.hideCalendar = function () {
        GUI.Event.un(document, 'mousedown', this.onDocumentMouseDown, this);
        GUI.Event.un(this.fieldEl, 'mousedown', this.onMouseDown, this);
        this.calendar.unrender();
        this.list.hide();
        this.calendarOpened = false;
    };

    /**
     * Handler trigger click
     * @param {Event} e Event
     */
    GUI.Forms.DatePicker.prototype.onTriggerClick = function (e) {
        e = GUI.Event.extend(e);
        if (!this.disabled && e.isLeftClick()) {
            if (this.calendarOpened) {
                this.hideCalendar();
            } else {
                this.showCalendar();
            }
        }
    };

    /**
     * Sets value, hides calendar
     * @param {Object} cal Object of calendar
     * @param {String} value Value
     */
    GUI.Forms.DatePicker.prototype.onCalendarSelect = function (cal, value) {
        this.setValue(value);
        this.hideCalendar();
    };

    /**
     * Handler document mouse down click
     * @param {Event} e Event
     */
    GUI.Forms.DatePicker.prototype.onDocumentMouseDown = function (e) {
        e = new GUI.ExtendedEvent(e);
        if (!(
                GUI.contains(this.list.dom, e.target)
                || GUI.contains(this.dom, e.target)
            )) {
            this.hideCalendar();
        }
    };

    /**
     * Handler mouse down click
     * @param {Event} e Event
     */
    GUI.Forms.DatePicker.prototype.onMouseDown = function (e) {
        this.applyDate();

        if (this.calendarOpened) {
            this.hideCalendar();
        }
    };


    /**
     *  On Key Down
     */
    GUI.Forms.DatePicker.prototype.onKeyUp = function (e) {
        if (e.keyCode === 13) {
            this.applyDate();
        }
    };
}());