(function () {

    /**
     * JavaScript Graphical User Interface
     * Hint implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Popup
     * @extends GUI.Popup.Region
     */
    GUI.Popup.PopupInformer = Class.create();

    /**
     * Array of the types of informers.
     * Can be error or info
     * @type Array
     */
    GUI.Popup.PopupInformer.prototype.types = {
        error   : {cls :    'b-informer_error', timeout: 5},
        success : {cls :    'success'},
        info    : {cls :    ''}
    };

    /**
     * Template of the informer
     * @type String
     */
    GUI.Popup.PopupInformer.prototype.template =
        '<div class="{cls}">' +
        '<i class="b-informer__icon"></i>' +
        '<div class="b-informer__data">' +
        '<b class="b-informer__ttl">{caption}</b>' +
        '<p class="b-informer__txt">{text}</p>' +
        '</div>' +
        '{close}' +
        '</div>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Popup.PopupInformer.prototype.initialize = function (config) {
        var cfg = {};

        Object.extend(cfg, {
            id          : GUI.getUniqId('popupinformer-'),
            className   : '',
            animate     : false,
            hideDelay   : 3000,
            caption     : null,
            text        : null,
            type        : 'info',
            cls         : 'b-informer b-informer_popup'
        });
        Object.extend(cfg, config);

        this.config = cfg;

        this.createHolder();
    };

    /**
     *
     */
    GUI.Popup.PopupInformer.prototype.createHolder = function () {
        var divHolder = GUI.$('informer-holder');

        if (!divHolder) {
            divHolder = document.createElement('DIV');
            divHolder.className = 'b-informer__holder';
            divHolder.id = 'informer-holder';

            document.body.appendChild(divHolder);
        }

        this.holder = divHolder;
    };

    /**
     * Renders html of the informer
     * @param {Object} hint
     */
    GUI.Popup.PopupInformer.prototype.renderInformer = function (hint) {
        var o = new GUI.StringBuffer(),
            cfg = this.config,
            tpl = new GUI.STemplate(this.template),
            clsplus = this.types[cfg.type];

        if (clsplus) {
            tpl.cls = cfg.cls + ' ' + clsplus.cls;
        } else {
            tpl.cls = cfg.cls;
        }

        tpl.caption = (GUI.isString(cfg.caption) && cfg.caption.length) ? cfg.caption : '';

        tpl.text = (GUI.isString(cfg.text) && cfg.text.length) ? cfg.text : '';

        tpl.close = (cfg.type !== 'success') ? '<i class="b-toolbox__icon close-tips"></i>' : '';

        return tpl.fetch();
    };

    /**
     * Shows infromer
     */
    GUI.Popup.PopupInformer.prototype.show = function () {
        var dims, dimsH,
            tpl = this.renderInformer();

        this.dom = GUI.Dom.append(this.holder, tpl);

        if (this.config.type === 'success') {
            dims = GUI.getDimensions(this.dom);
            dimsH = GUI.getDimensions(this.holder);

            this.dom.style.left = ((dimsH.width / 2) - (dims.width / 2)) + 'px';
        }

        this.onAfterShow();
        this.attachEventListeners();
    };

    /**
     * Runs timer
     */
    GUI.Popup.PopupInformer.prototype.onAfterShow = function () {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        if (this.config.hideDelay > 0) {
            this.timerId = setTimeout(this.hide.bindLegacy(this), this.config.hideDelay);
        }
    };

    /**
     * Hides informer, clear timer
     */
    GUI.Popup.PopupInformer.prototype.hide = function () {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        this.removeEventListeners();

        if (GUI.getCss3Property('animation')) {
            this.dom.addClass('b-informer_close');
            setTimeout(this.hideDom.bindLegacy(this), 300);
        } else {
            this.hideDom();
        }
    };

    /**
     *
     * @param {type} val
     * @returns {undefined}
     */
    GUI.Popup.PopupInformer.prototype.hideDom = function () {
        if (this.dom) {
            GUI.destroyNode(this.dom); // in ie fires window.onresize
            this.dom = null;
        }
    };

    /**
     * Sets caption
     * @param {String} val New text of the caption
     */
    GUI.Popup.PopupInformer.prototype.setCaption = function (val) {
        this.config.caption = val;
    };

    /**
     * Sets text
     * @param {String} val New text
     */
    GUI.Popup.PopupInformer.prototype.setText = function (val) {
        this.config.text = val;
    };

    /**
     * Sets hide delay
     * @param {Number} val Delay
     */
    GUI.Popup.PopupInformer.prototype.setHideDelay = function (val) {
        this.config.hideDelay = parseInt(val, 10);
    };

    /**
     *
     * @param ev
     */
    GUI.Popup.PopupInformer.prototype.onBodyHide = function (ev) {
        if (ev === undefined || GUI.Dom.queryParent(ev.target, '.b-informer__holder') === false) {
            this.hide();
        }
    };

    /**
     * Attach event listener
     */
    GUI.Popup.PopupInformer.prototype.attachEventListeners = function () {
        var icon = GUI.Dom.findDescedent(this.dom, 'i.close-tips');

        setTimeout(function () {
            document.body.on('click', this.onBodyHide, this);
        }.bindLegacy(this), 0);

        if (icon) {
            icon.on('click', this.hide, this);
        }
    };

    /**
     * Removes event listener
     */
    GUI.Popup.PopupInformer.prototype.removeEventListeners = function () {
        var icon = GUI.Dom.findDescedent(this.dom, 'i.close-tips');
        document.body.un('click', this.onBodyHide, this);
        if (icon) {
            icon.un('click', this.hide, this);
        }
    };

}());