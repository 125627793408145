/**
 *
 */
GUI.onDOMReady(function () {
    ShowTicketController.init();
});

/**
 *
 */
ShowTicketController = new GUI.Controller({
    name: 'ShowTicket',
    models: '',
    views: '',

    form: null
});

ShowTicketController.init = function () {
    jQuery("#comments_container a.show_more").on('click', function () {
        jQuery("#comments_container div.replies__item").show();
        jQuery("#comments_container a.show_more").hide();
        return false;
    });

    jQuery("#delete-ticket").on('click', this.onTicketDelete.bindLegacy(this));
};

ShowTicketController.onTicketDelete = function (event) {
    var url = jQuery(event.target).closest("button").data('href');

    Application.ConfirmForm.setCallerElement(event.target, null, 'delete', 'delete-dialog-form', 'delete-form');
    Application.ConfirmForm.setActionUrl(url);

    Application.ConfirmForm.show(true);
};

