(function () {
    var superproto = GUI.Forms.Field.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Hidden implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.Field
    */
    GUI.Forms.Hidden = Class.create();
    Object.extend(GUI.Forms.Hidden.prototype, superproto);


    /**
     * Type of field, 'hidden'
     * @type String
     */
    GUI.Forms.Hidden.prototype.type = 'hidden';

    /**
     * Constructor
     * @param {Object} config
     */
    GUI.Forms.Hidden.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * empty function
     */
    GUI.Forms.Hidden.prototype.setSize = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.Hidden.prototype.setWidth = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.Hidden.prototype.setHeight = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.Hidden.prototype.setInvalid = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.Hidden.prototype.setValid = GUI.emptyFunction;

}());