(function () {
    var superproto = GUI.Utils.Observable.prototype;
    /**
     * JavaScript Graphical User Interface
     * Combo selection model implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms.Combo
     * @extends GUI.Utils.Observable
     */
    GUI.Forms.Combo.SelectionModel = Class.create();
    Object.extend(GUI.Forms.Combo.SelectionModel.prototype, superproto);

    /**
     * Type of the element, 'GUI.Forms.Combo.SelectionModel'
     * @type String
     */
    GUI.Forms.Combo.SelectionModel.prototype.elementType = 'GUI.Forms.Combo.SelectionModel';

    /**
     * If true we can choose one item, default is false
     * @type Boolean
     */
    GUI.Forms.Combo.SelectionModel.prototype.singleSelect = false;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.Combo.SelectionModel.prototype.initialize = function (config) {
        superproto.initialize.call(this);

        if (config) {
            Object.extend(this, config);
        }

        this.selections = new GUI.Utils.Collection();

        this.last = false;
        this.lastActive = false;

        this.addEvents({
            /**
             * @event selectionchange
             * Fires when the selection changes
             * @param {SelectionModel} this
             */
            selectionchange: true,
            /**
             * @event beforeitemselect
             * Fires when a item is being selected, return false to cancel.
             * @param {SelectionModel} this
             * @param {Item} item Item to be selected
             * @param {Boolean} keepExisting False if other selections will be cleared
             */
            beforeitemselect: true,
            /**
             * @event itemselect
             * Fires when a item is selected.
             * @param {SelectionModel} this
             * @param {Item} item The selected item
             */
            itemselect: true,
            /**
             * @event itemdeselect
             * Fires when a item is deselected.
             * @param {SelectionModel} this
             * @param {Item} itemIndex
             */
            itemdeselect: true,
            //
            selectall: true,
            deselectall: true
        });

    };

    /**
     * Called by combo internally!
     * @param {Object} combo
     */
    GUI.Forms.Combo.SelectionModel.prototype.init = function (combo) {
        this.combo = combo;
        this.initEvents();
    };

    /**
     * Initialize needed events
     */
    GUI.Forms.Combo.SelectionModel.prototype.initEvents = function () {
        this.combo.on('itemClick', this.onItemClick, this);
    };

    /**
     * Deselect old item, select new
     * @param {Object} combo Combo object
     * @param {Object} item Item of combo
     * @param {Event} e Event
     */
    GUI.Forms.Combo.SelectionModel.prototype.onItemClick = function (combo, item, e) {
        if (item.selected) {
            if (this.combo.allowDeselect) {
                this.deselectItem(item);
            }
        } else if (!item.selected) {
            this.selectItem(item, true);
        }
    };

    /**
    * Clears all selections
    * @param {Boolean} fast
    */
    GUI.Forms.Combo.SelectionModel.prototype.clearSelections = function (fast) {
        if (fast !== true) {
            var s = this.selections;
            s.each(function (item) {
                this.deselectItem(item);
            }, this);
            s.clear();
        } else {
            this.selections.clear();
            this.fireEvent("selectionchange", this);
        }
        this.last = false;
    };

    /**
    * Returns the selected records
    * @returns {Array} Array of selected records
    */
    GUI.Forms.Combo.SelectionModel.prototype.getSelections = function () {
        return [].concat(this.selections.items);
    };

    /**
    * Returns the first selected record.
    * @returns {Object} selected item
    */
    GUI.Forms.Combo.SelectionModel.prototype.getSelected = function () {
        return this.selections.itemAt(0);
    };

    /**
     * Gets the number of selected items.
     * @return {Number}
     */
    GUI.Forms.Combo.SelectionModel.prototype.getCount = function () {
        return this.selections.length;
    };

    /**
     * Selects a item.
     * @param {Object} item Item to select
     * @param {Boolean} keepExisting (optional) True to keep existing selections
     * @param {Boolean} preventNotify (optional) True to prevent combo notification
     */
    GUI.Forms.Combo.SelectionModel.prototype.selectItem = function (item, keepExisting, preventNotify) {
        if (item.noSelect) {
            return false;
        }
        if (this.fireEvent("beforeitemselect", this, item, keepExisting) !== false) {
            if (!keepExisting || this.singleSelect) {
                this.clearSelections();
            }

            this.selections.add(item);
            item.selected = true;

            this.last = this.lastActive = item;
            if (!preventNotify) {
                this.combo.onItemSelect(item);
            }

            this.fireEvent("itemselect", this, item);
            this.fireEvent("selectionchange", this);
            return true;
        } else {
            return false;
        }
    };

    /**
    * Selects all items.
    */
    GUI.Forms.Combo.SelectionModel.prototype.selectAll = function () {
        this.selections.clear();
        var self = this;
        this.combo.options.each(
            function (item) {
                self.selectItem(item, true);
            }
        );

        this.fireEvent("selectall", this);
    };

    /**
     * Returns true if all items is selected
     * @returns {Boolean}
     */
    GUI.Forms.Combo.SelectionModel.prototype.isAllSelected = function () {
        return this.combo.options.length === this.selections.length;
    };

    /**
    * Selects a range of items. All items in between startItem and endItem are also selected.
    * @param {Number} item1 The index of the first item in the range
    * @param {Number} item2 The index of the last item in the range
    * @param {Boolean} keepExisting (optional) True to retain existing selections
    */
    GUI.Forms.Combo.SelectionModel.prototype.selectRange  = function (item1, item2, keepExisting) {
        if (!keepExisting) {
            this.clearSelections();
        }
        var startItem = this.combo.options.indexOf(item1),
            endItem = this.combo.options.indexOf(item2),
            i = 0;

        if (startItem <= endItem) {
            for (i = startItem; i <= endItem; i++) {
                this.selectItem(this.combo.options.itemAt(i), true);
            }
        } else {
            for (i = startItem; i >= endItem; i--) {
                this.selectItem(this.combo.options.itemAt(i), true);
            }
        }
    };

    /**
     * Deselects a item.
     * @param {Number} item The index of the item to deselect
     * @param {Boolean} preventNotify (optional) True to prevent combo notification
     */
    GUI.Forms.Combo.SelectionModel.prototype.deselectItem  = function (item, preventNotify) {
        if (item.noSelect) {
            return false;
        }

        if (this.last === item) {
            this.last = false;
        }

        if (this.lastActive === item) {
            this.lastActive = false;
        }

        if (item) {
            this.selections.remove(item);
            item.selected = false;
            if (!preventNotify) {
                this.combo.onItemDeselect(item);
            }
            this.fireEvent("itemdeselect", this, item);
            this.fireEvent("selectionchange", this);
            if (this.selections.length === 0) {
                this.fireEvent("deselectall", this);
            }
            return true;
        } else {
            return false;
        }
    };

}());