(function () {
    var superproto = GUI.Forms.Combo.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.List implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.Combo
    */
    GUI.Forms.List = Class.create();
    Object.extend(GUI.Forms.List.prototype, superproto);

    /**
     * Type of field, 'hidden'
     * @type String
     */
    GUI.Forms.List.prototype.type = 'hidden';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.List.prototype.hiddenInput = false;

    /**
     * Css class of the list, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.className = '';

    /**
     * Css class of the list's content, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.contentClass = '';

    /**
     * Css class for invalid field, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.invalidClass = '';

    /**
     * Css class of the item, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.itemClass = '';

    /**
     * css class for selected item, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.itemSelectedClass = '';

    /**
     * Css class for item with min width, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.minWidthClass = '';

    /**
     * Css class for item with auto width, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.autoWidthClass = '';

    /**
     * Css class for hint, default is ''
     * @type String
     */
    GUI.Forms.List.prototype.hintClass = '';

    /**
     * Width of the list, default is 200
     * @type Number
     */
    GUI.Forms.List.prototype.width = 200;

    /**
     * Ability to select multiple, default is false
     * @type Boolean
     */
    GUI.Forms.List.prototype.multiple = false;

    /**
     * Allows deselect the item, default is true
     * @type Boolean
     */
    GUI.Forms.List.prototype.allowDeselect = true;

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.List.prototype.domTemplate =
        '<div class="<%=className%>"><table cellspacing="0" cellpadding="0"><tbody><tr><td valign="top" class="<%=contentClass%>">' +
        '</td></tr></tbody></table></div>';

    /**
     * Constructor
     * @param {Object} config
     */
    GUI.Forms.List.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component
     */
    GUI.Forms.List.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        if (!this.multiple) {
            this.allowDeselect = false;
        }
    };

    /**
     * Returns selection model config
     * @returns {Object} config
     */
    GUI.Forms.List.prototype.getDefaultSmConfig = function () {
        return {
            singleSelect: !this.multiple
        };
    };

    /**
     * Handler on assign
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.List.prototype.onAssign = function (dom) {
        if (dom.size > 0) {
            this.listHeight = dom.size;
        }
        this.multiple = dom.multiple;
        if (this.multiple) {
            this.allowDeselect = true;
        }
        if (this.sm) {
            this.sm.singleSelect = !this.multiple;
        }
        superproto.onAssign.call(this, dom);
    };

    /**
     * Create template, init validators
     * @returns {HTMLElement} html
     */
    GUI.Forms.List.prototype.onRender = function () {
        var html = GUI.Template(this.domTemplate, {
            className   : this.className,
            contentClass: this.contentClass
        });

        this.initValidators();

        return html;
    };

    /**
     * Update items, add events 'click', 'mouseover', 'mouseout'
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.List.prototype.onAfterRender = function (dom) {
        this.dom.id = this.getId();

        this.contentEl = GUI.Dom.findDescedents(dom, '.' + this.contentClass)[0];
        GUI.Dom.extend(this.contentEl);
        this.updateItems();

        if (GUI.isNumber(this.listHeight)) {
            if (!GUI.isSet(this.itemHeight)) {
                this.itemHeight = this.multiple ? 25 : 22;
            }

            this.height = this.listHeight * this.itemHeight;
        }

        this.contentEl.on('click', this.onListClick, this);
        this.contentEl.on('mouseover', this.onListMouseOver, this);
        this.contentEl.on('mouseout', this.onListMouseOut, this);
    };

    /**
     * Returns html of the item
     * @param {Object} item
     * @returns {HTMLElement} item
     */
    GUI.Forms.List.prototype.getListItemHtml = function (item) {
        var itemClass = this.itemClass, itemHtml, descrHtml;

        if (item.selected) {
            itemClass += ' ' + this.itemSelectedClass;
        }

        if (this.multiple) {
            itemHtml = '<div class="x-form-list-item-text"><input type="checkbox"' +
                (item.selected ? ' checked="checked"' : '')
                + ' /><span>' + item[this.listField] + '</span></div>';
        } else {
            itemHtml = '<div class="x-form-list-item-text">' + item[this.listField] + '</div>';
        }

        if (GUI.isSet(item[this.descriptionField])) {
            descrHtml = '<div class="x-form-list-item-description">' + item[this.descriptionField] + '</div>';
        } else {
            descrHtml = '';
        }

        return '<div id="' + item.domId + '" class="' + itemClass + '">' + itemHtml + descrHtml + '</div>';
    };

    /**
     * Remove events
     */
    GUI.Forms.List.prototype.onDestroy = function () {
        this.contentEl.un();
        superproto.onDestroy.call(this);
    };

    /**
     * empty function
     */
    GUI.Forms.List.prototype.attachEventListeners = function () {
        //
    };

    /**
     * Rewrites html in the element's content
     */
    GUI.Forms.List.prototype.updateItems = function () {
        if (this.contentEl) {
            this.contentEl.innerHTML = this.getListHtml();
        }
    };

    /**
     * empty function
     */
    GUI.Forms.List.prototype.initList = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.List.prototype.showList = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.List.prototype.hideList = GUI.emptyFunction;

    /**
     * empty function
     */
    GUI.Forms.List.prototype.updateValue = GUI.emptyFunction;

    /**
     * Check name and type of element
     * @param {HTMLElement} dom Dom
     * Returns {Boolean}
     */
    GUI.Forms.List.prototype.onBeforeAssign = function (dom) {
        return (dom.nodeName === 'SELECT') && (dom.getAttribute('xtype') === 'list');
    };

    /**
     * Returns values
     * @returns {Array} values
     */
    GUI.Forms.List.prototype.getValue = function () {
        var ids = [],
            selections = this.sm.getSelections(),
            valueField = this.getValueField(),
            i = 0,
            len = 0;

        for (i = 0, len = selections.length; i < len; i++) {
            ids[i] = selections[i][valueField];
        }
        return ids;
    };

    /**
     * Sets values
     * @param {Array} values
     */
    GUI.Forms.List.prototype.setValue = function (values) {
        // Need to check items with values passed in the array and uncheck all other items
        var item,
            i = 0,
            len = 0;

        this.sm.clearSelections();
        this.selectedIndex = -1;

        if (values === null) {
            return;
        }
        if (GUI.isArray(values)) {
            for (i = 0, len = values.length; i < len; i++) {
                item = this.getItemByValue(values[i]);
                this.sm.selectItem(item, true);
                this.selectedIndex = this.options.indexOf(item);
            }
        } else {
            item = this.getItemByValue(values);
            this.sm.selectItem(item);
            this.selectedIndex = this.options.indexOf(item);
        }
    };

    /**
     * Delete options and content
     */
    GUI.Forms.List.prototype.clear = function () {
        this.options.clear();
        if (this.contentEl) {
            this.contentEl.innerHTML = '';
        }
    };

    /**
     *  Defer update
     */
    GUI.Forms.List.prototype.beginUpdate = function () {
        this.deferUpdate = true;
    };

    /**
     * Update items
     */
    GUI.Forms.List.prototype.endUpdate = function () {
        this.deferUpdate = false;
        this.updateItems();
    };

    /**
     * Returns dom
     * @returns dom
     */
    GUI.Forms.List.prototype.getListDom = function () {
        return this.dom;
    };

    /**
     * Hadnler item select, check item, validate
     * @param {Object} item
     */
    GUI.Forms.List.prototype.onItemSelect = function (item) {
        this.selectedIndex = this.options.indexOf(item);

        var dom = this.getItemDom(item);
        if (dom) {
            dom.addClass(this.itemSelectedClass);
            if (this.multiple) {
                dom.findDescedent('input').checked = true;
            }

            if (this.validateOnBlur || this.livevalidation) {
                this.validate();
            }
        }
    };

    /**
     * Handle deselect item
     * @param {Object} item
     */
    GUI.Forms.List.prototype.onItemDeselect = function (item) {
        superproto.onItemDeselect.call(this, item);

        if (this.selectedIndex === this.options.indexOf(item)) {
            this.selectedIndex = -1;
        }

        var dom = this.getItemDom(item);
        if (dom) {
            dom.removeClass(this.itemSelectedClass);
            if (this.multiple) {
                dom.findDescedent('input').checked = false;
            }
        }
    };

    /**
     * Sets options, update items
     * @param {Array} options
     */
    GUI.Forms.List.prototype.setOptions = function (options) {
        superproto.setOptions.call(this, options);
        this.updateItems();
    };

}());
