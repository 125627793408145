(function () {
    GUI.Utils.keys = {
		// Alphabet
		a:'a', b:'b', c:'c', d:'d', e:'e',
		f:'f', g:'g', h:'h', i:'i', j:'j',
		k:'k', l:'l', m:'m', n:'n', o:'o',
		p:'p', q:'q', r:'r', s:'s', t:'t',
		u:'u', v:'v', w:'w', x:'x', y:'y', z:'z',

		// Numbers
		n0:'n0', n1:'n1', n2:'n2', n3:'n3', n4:'n4',
		n5:'n5', n6:'n6', n7:'n7', n8:'n8', n9:'n9',

		// Controls
		tab:'tab',   enter:'enter', shift:'shift', backspace:'backspace',
		ctrl:'ctrl', alt  :'alt',   esc  :'esc',   space    :'space',
		menu:'menu', pause:'pause', cmd  :'cmd',

		insert  :'insert',   home:'home', pageup  :'pageup',
		'delete':'delete',   end :'end',  pagedown:'pagedown',

		shiftSemicolon: 'shiftSemicolon',

		// F*
		f1:'f1', f2:'f2', f3:'f3', f4 :'f4',  f5 :'f5',  f6 :'f6',
		f7:'f7', f8:'f8', f9:'f9', f10:'f10', f11:'f11', f12:'f12',
		// numpad
		np0:'np0', np1:'np1', np2:'np2', np3:'np3', np4:'np4',
		np5:'np5', np6:'np6', np7:'np7', np8:'np8', np9:'np9',

        npslash:'npslash',npstar:'npstar',nphyphen:'nphyphen',npplus:'npplus',npdot:'npdot',

		// Lock
		capslock:'capslock', numlock:'numlock', scrolllock:'scrolllock',

		// Symbols
		equals:'equals', hyphen   :'hyphen',    coma  :'coma',          dot:'dot',
		gravis:'gravis', backslash:'backslash', sbopen:'sbopen',        sbclose:'sbclose',
		slash :'slash',  semicolon:'semicolon', apostrophe:'apostrophe', underscore: 'underscore',

		// Arrows
		aleft:'aleft', aup:'aup', aright:'aright', adown:'adown',

		anyKey: 'anykey'
	};

   GUI.Utils.keysToCode = {
		// Alphabet
		a:65, b:66, c:67, d:68, e:69,
		f:70, g:71, h:72, i:73, j:74,
		k:75, l:76, m:77, n:78, o:79,
		p:80, q:81, r:82, s:83, t:84,
		u:85, v:86, w:87, x:88, y:89, z:90,
		// Numbers
		n0:48, n1:49, n2:50, n3:51, n4:52,
		n5:53, n6:54, n7:55, n8:56, n9:57,
		// Controls
		tab:  9, enter:13, shift:16, backspace:8,
		ctrl:17, alt  :18, esc  :27, space    :32,
		menu:93, pause:19, cmd  :91,
		insert  :45, home:36, pageup  :33,
		'delete':46, end :35, pagedown:34,
	   
	    shiftSemicolon: 59,
	   
		// F*
		f1:112, f2:113, f3:114, f4 :115, f5 :116, f6 :117,
		f7:118, f8:119, f9:120, f10:121, f11:122, f12:123,
		// numpad
		np0: 96, np1: 97, np2: 98, np3: 99, np4:100,
		np5:101, np6:102, np7:103, np8:104, np9:105,
		npslash:11,npstar:106,nphyphen:109,npplus:107,npdot:110,
		// Lock
		capslock:20, numlock:144, scrolllock:145,

		// Symbols
		equals: 61, hyphen   :109, coma  :188, underscore: 189,  dot:190,
		gravis:192, backslash:220, sbopen:219, sbclose:221,
		slash :191, semicolon: 186, apostrophe: 222,

		// Arrows
		aleft:37, aup:38, aright:39, adown:40
   };
}());