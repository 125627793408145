(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
    * JavaScript Graphical User Interface.
    *
    * @author Inna
    * @version 2.0
    */
    GUI.Forms.MultivalueAutocompleter = Class.create();
    Object.extend(GUI.Forms.MultivalueAutocompleter.prototype, superproto);

    /**
     * Css class of the element
     */
    GUI.Forms.MultivalueAutocompleter.prototype.wrapperClass = 'b-text-field_advanced b-text-field_multiline';

    /**
     * Css class of the element
     */
    GUI.Forms.MultivalueAutocompleter.prototype.itemClass = 'b-text-field__multilineItem';

    /**
     * Css class of the element
     */
    GUI.Forms.MultivalueAutocompleter.prototype.inputClass = 'b-text-field__multilineItem_input';

    /**
     *
     */
    GUI.Forms.MultivalueAutocompleter.prototype.focusedClass = 'b-text-field_advanced_focus';

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.MultivalueAutocompleter.prototype.domTemplate =
        '<ul class="{this.wrapperClass}">' +
        '<li class="{this.itemClass} {this.inputClass}" >' +
        '<i class="i-text-field"></i>' +
        '</li>' +
        '{this.hiddenEl}' +
        '</ul>';

    /**
     * Item template
     * @type String
     */
    GUI.Forms.MultivalueAutocompleter.prototype.itemTemplate =
        '<li class="{this.itemClass}" index={this.index}>' +
        '<span>{this.value}</span>' +
        '<i class="b-text-field__multilineItemClose b-icon fa fa-times fa_12"></i>' +
        //'<input type="hidden" name="{this.name}" value="{this.value}">' +
        '</li>';

    /**
     *
     */
    GUI.Forms.MultivalueAutocompleter.prototype.hiddenEl =
        '<input type="hidden" name="">';

    /**
     *
     */
    GUI.Forms.MultivalueAutocompleter.prototype.hiddenField = false;

    /**
     * Name for hidden input
     */
    GUI.Forms.MultivalueAutocompleter.prototype.name = 'multi';

    /**
     *
     */
    GUI.Forms.MultivalueAutocompleter.prototype.hidePlaceholderIfNotEmptyValue = false;

    /**
     *
     */
    GUI.Forms.MultivalueAutocompleter.prototype.values = [];

    /**
     * Initialize objects, call parent initialize.
     * @param {Object} cfg Configuration object
     */
    GUI.Forms.MultivalueAutocompleter.prototype.initialize = function (cfg) {
        var config = {
            autocompleterConfig: {
                tabIndex    : 1,
                width       : 'auto',
                name        : '',
                placeholder : 'Type here...',
                title       : '',
                rowTpl      : '<span class="b-popup-menu__button"><span class="label">{this.text}</span></span>',
                usedFieldToApply: 'text'
            },
            searchConfig    : {
                paramName    : 'text',
                url          : '',
                method       : 'POST',
                resultsField : '',
                baseParams   : ''
            }
        };

        this.values = [];

        if (cfg) {

            if (cfg.search) {
                Object.extend(config.searchConfig, cfg.search);
                delete cfg.search;
            }

            if (cfg.autocompleter) {
                Object.extend(config.autocompleterConfig, cfg.autocompleter);
                delete cfg.autocompleter;
            }

            if (cfg.value) {
                if (typeof cfg.value === 'string') {
                    this.values = cfg.value.split(/\s*,\s*/);

                } else if (typeof cfg.value === 'array') {
                    this.values = cfg.value;
                }
            }
        }
        Object.extend(this, cfg);
        this.config = config;



        this.addEvents('onClickDocument', 'onChange');
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.render = function (holder) {
        this.holder = GUI.$(holder || this.holder);
        this.renderDom();
        this.renderAutocompleter();
        this.setValue(this.values);

        this.attachEventListeners();
    };

    /**
     * Renders html
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.renderDom = function () {

        if (this.extraClass) {
            this.wrapperClass += ' ' + this.extraClass;
        }

        if (!this.hiddenField) {
            this.hiddenEl = '';
        }

        this.tpl = new GUI.STemplate(this.domTemplate, this);
        this.tpl = this.tpl.fetch();

        if (this.holder) {
            this.holder.innerHTML = this.tpl;
        }

        this.dom = this.holder.firstChild;
        GUI.Dom.extend(this.dom);

        // hidden field
        if (this.hiddenField) {
            this.hiddenEl = this.dom.findDescedent('input');
            this.hiddenEl.name = this.name;
        }

        this.setWidth();
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.setWidth = function (width) {
        this.dom.style.width = this.width || width || null;
    };

    /**
     * Renders autocompleter's object
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.renderAutocompleter = function () {
        var autocompleter,
            cfg = this.config.autocompleterConfig,
            scfg = this.config.searchConfig;

        this.holderAutocompeter = this.dom.findDescedent('i.i-text-field');

        if (this.listWidth === 'auto') {
            this.listWidth = GUI.Dom.getWidth(this.dom);
        }

        autocompleter = new GUI.Forms.Autocompleter({
            tabIndex        : cfg.tabIndex,
            holder          : this.holderAutocompeter,
            usedFieldToApply: cfg.usedFieldToApply,
            width           : cfg.width,
            listWidth       : this.listWidth,
            name            : cfg.name,
            autocomplete    : true,
            alignDom        : this.dom,
            livevalidation  : false,
            hideEmptyList   : true,
            placeholder     : cfg.placeholder,
            title           : cfg.title,
            rowTpl          : cfg.rowTpl,

            showResultFromEmptyResponse : false,
            disableValidationPopups     : true,

            searchConfig    : {
                paramName    : scfg.paramName,
                url          : scfg.url,
                method       : scfg.method,
                resultsField : scfg.resultsField,
                baseParams   : scfg.baseParams
            }
        });
        autocompleter.render();
        this.autocompleter = autocompleter;
    };

    /**
     * Initializes events
     */
    GUI.Forms.MultivalueAutocompleter.prototype.attachEventListeners = function () {
        document.on('mousedown', this.onDocumentMouseDown, this);

        this.dom.on('click', this.onClickDom, this);

        this.autocompleter.on('select', this.onChangeAutocompleter, this);
        this.autocompleter.on('beforeShow', this.onBeforeShowList, this);

        this.keyboardEvent = new GUI.Utils.KeyboardEvents(this.autocompleter.dom, {
            anyKey: true
        });
        this.keyboardEvent.on(GUI.Utils.keys.anyKey, this.onKeyDown, this);
        //this.autocompleter.dom.on('keydown', this.onKeyDown, this);
    };

    /**
     * Removes events listeners
     */
    GUI.Forms.MultivalueAutocompleter.prototype.removeEventListeners = function () {
        document.un('mousedown', this.onDocumentMouseDown, this);

        this.dom.un('click', this.onClickDom, this);

        this.keyboardEvent.destroy();

        this.autocompleter.un('select', this.onChangeAutocompleter, this);
        this.autocompleter.destroy();
    };

    /**
     *
     * @param {type} e
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.onDocumentMouseDown = function (e) {
        e = GUI.Event.extend(e);

        if (e.target && !e.target.within(this.dom)
                && (!this.autocompleter.list.dom
                    || (this.autocompleter.list.dom && !e.target.within(this.autocompleter.list.dom)))) {

            GUI.Dom.removeClass(this.dom, this.focusedClass);
            this.addItem(this.autocompleter.getValue());

            if (this.getValue() !== '' && this.hidePlaceholderIfNotEmptyValue) {
                GUI.hide(this.autocompleter.dom);
                //this.autocompleter.dom.setAttribute('placeholder', '');
            } else {
                GUI.show(this.autocompleter.dom);
                //this.autocompleter.dom.setAttribute('placeholder', this.config.autocompleterConfig.placeholder);
            }

            this.fireEvent('onClickDocument', this, e);
        }
    };

    /**
     * Hadnler event
     * @param {Event} e
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.onClickDom = function (e) {
        e = GUI.Event.extend(e);
        var target = e.getTarget();

        if (GUI.Dom.hasClass(target, 'close')) {
            this.deleteItem(target.parentNode);

        } else {
            GUI.show(this.autocompleter.dom);
            GUI.Dom.addClass(this.dom, this.focusedClass);
            this.autocompleter.focus();
            //this.autocompleter.dom.setAttribute('placeholder', this.config.autocompleterConfig.placeholder);
        }
    };

    /**
     * After change value of autocompleter
     * @param {Object} obj
     * @param {Object} item
     * @param {String} value
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.onChangeAutocompleter = function (obj, item, value) {
        this.addItem(value);
    };

    /**
     *
     * @returns {Boolean}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.onBeforeShowList = function (obj) {
        if (this.listWidth && this.listWidth === 'auto') {
            obj.listWidth = GUI.Dom.getWidth(this.dom);
        } else if(this.listWidth) {
            obj.listWidth = this.listWidth;
        }

        return true;
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.onKeyDown = function (keyName) {
        var value = this.autocompleter.getValue();

        switch (keyName) {
        case GUI.Utils.keys.backspace:
            if (value === '') {
                this.deleteItem(this.holderAutocompeter.parentNode.previousSibling);
            }
            break;
        case GUI.Utils.keys.tab:
        case GUI.Utils.keys.esc:
            this.autocompleter.hideList();
            break;
        }
    };

    /**
     *
     * @param {String} value
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.addItem = function (value) {
        var i, v, oldValue, newValue;

        if (value !== '') {
            oldValue = this.getValue();

            this.autocompleter.setValue('');

            value = GUI.html_entity_decode(value); // CHD-2115

            value = value.split(/\s*,\s*/);

            for (i = 0; i < value.length; i++) {
                v = value[i].trim();
                this.renderItem(v);
            }

            newValue = this.getValue();

            this.fireEvent('onChange', this, newValue, oldValue);
        }
    };

    /**
     * Renders item's html
     * @param {type} value
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.renderItem = function (value) {
        var tpl = '', oldValue, newValue, value_;

        value_ = value.split(' ').last().replace(/<|>/gim, '');

        if (this.validation === 'email' && !value_.match(/^([^@\s<]+)@(?:[-a-z0-9\-\_\.]+)\.([\.a-z]{2,})?$/gim)) {
            return;
        }

        tpl = new GUI.STemplate(this.itemTemplate, {
            itemClass   : this.itemClass,
            value       : value.htmlentities(),
            index       : this.values.length
        });
        tpl = tpl.fetch();

        GUI.Dom.insertBefore(this.holderAutocompeter.parentNode, tpl);

        oldValue = this.getValue();

        this.values.push(value);

        newValue = this.getValue();

        this.setHiddenInpValue(newValue);
    };

    /**
     *
     * @param {type} node
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.deleteItem = function (node) {
        if (!node) {
            return;
        }

        var newValue, oldValue,
            index = parseInt(node.getAttribute('index'), 10);

        oldValue = this.getValue();

        GUI.remove(node);

        delete this.values[index];

        newValue = this.getValue();

        this.setHiddenInpValue(newValue);

        this.fireEvent('onChange', this, newValue, oldValue);
    };

    /**
     *
     * @param {String} value
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.setHiddenInpValue = function (value) {
        if (this.hiddenField) {
            this.hiddenEl = this.holderAutocompeter.parentNode.nextSibling;
            this.hiddenEl.value = value;
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.clearValues = function () {
        var n, i = 0,
            deleteItems = [],
            nodes = this.dom.childNodes,
            l = this.dom.childNodes.length;

        for (i = 0; i < l; i++) {
            n = nodes[i];

            if (GUI.isSet(n.getAttribute('index'))) {
                deleteItems.push(n);
            }
        }

        for (i = 0; i < deleteItems.length; i++) {
            this.deleteItem(deleteItems[i]);
        }

    };

    /**
     * Sets values
     * @param {Array} data
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.setValue = function (data_) {
        var i, l = 0, data = [],
            newValue,
            oldValue = this.getValue();

        if (typeof data_ === 'string') {
            data = GUI.html_entity_decode(data_).split(/\s*,\s*/);

        } else if (data_.length) {
            data = data_;
        }

        l = data.length;

        this.clearValues();

        for (i = 0; i < l; i++) {
            this.renderItem(data[i]);
        }

        newValue = this.getValue();

        if (newValue !== '' && this.hidePlaceholderIfNotEmptyValue) {
            GUI.hide(this.autocompleter.dom);
            //this.autocompleter.dom.setAttribute('placeholder', '');
        } else {
            GUI.show(this.autocompleter.dom);
            //this.autocompleter.dom.setAttribute('placeholder', this.config.autocompleterConfig.placeholder);
        }

        this.fireEvent('onChange', this, newValue, oldValue);
    };

    /**
     * Returns values of hidden inputs
     * @returns {array} data
     */
    GUI.Forms.MultivalueAutocompleter.prototype.getValue = function () {
        return this.values.clean().join(', ');
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.show = function () {
        GUI.show(this.dom);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.MultivalueAutocompleter.prototype.hide = function () {
        GUI.hide(this.dom);
    };

}());