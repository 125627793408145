GUI.onDOMReady(function() {

    Placeholders = window.Class.create();

    prototype = {
        initialize: function () {
            var i, inp, inpElems;
            inpElems = window.document.getElementsByTagName('input');

            if (this.hasPlaceholder()) {
                return;
            }

            for (i = 0; i < inpElems.length; i++) {
                inp = inpElems[i];
                this.addField(inp);
            }
        },

        addField: function (inp) {
            if (this.hasPlaceholder()) {
                return;
            }

            var attr, value;
            if (!inp) {
                return;
            }

            attr = this.getPlaceholder(inp);

            if (attr) {
                value = inp.value;

                if (inp.getAttribute('type') === 'password') {
                    inp.setAttribute('isPassword', 'true');
                }

                if (value == '' || value == attr) {
                    this.setValue(inp, attr);
                }
                this.addEventListeners(inp);
            }
        },

        addEventListeners : function (inp) {
            GUI.Event.on(inp, 'focus', this.onFocus.bind(this));
            GUI.Event.on(inp, 'keydown', this.onKeyDown.bind(this));
            GUI.Event.on(inp, 'blur', this.onBlur.bind(this));
            GUI.Event.on(inp, 'click', this.onClick.bind(this));
        },

        hasPlaceholder : function () {
            var inp_ = document.createElement('INPUT');
            return 'placeholder' in inp_;
        },

        getPlaceholder : function (inp) {
            return inp.getAttribute('placeholder') || inp.placeholder;
        },

        setPlaceholder : function (inp, placeholderText) {
            inp.setAttribute('placeholder', placeholderText || '');

            if (!this.hasPlaceholder()) {
                this.setValue(inp, placeholderText);
            }
        },

        setValue : function (inp, attr) {
            try {
                if (inp.getAttribute('isPassword') === 'true') {
                    inp.setAttribute('type', 'text');
                }
                inp.value = attr;
                inp.style.color = '#888';
                inp.setAttribute('unselectable', 'on');
            } catch (e) {
                //console.log(e)
            }

        },

        clearValue : function (inp) {
            if (inp.getAttribute('isPassword') === 'true') {
                inp.setAttribute('type', 'password');
            }
            inp.value = '';
            inp.style.color = '';
            inp.setAttribute('unselectable', 'off');
        },

        onFocus : function (e) {
            e = GUI.Event.extend(e);

            var r,
                inp = e.getTarget(),
                attr = this.getPlaceholder(inp);

            if (inp.value == attr || inp.value == '') {
                this.setValue(inp, attr);

                if (inp.createTextRange) {
                    r = inp.createTextRange();
                    r.collapse(true);
                    r.select();

                } else if (inp.selectionStart) {
                    inp.setSelectionRange(0,0);
                }
            }
        },

        onKeyDown : function (e) {
            e = GUI.Event.extend(e);

            var inp = e.getTarget(),
                attr = this.getPlaceholder(inp),
                key = e.getKey();

            attr = this.getPlaceholder(inp);

            if (!e.isSpecialKey() && key != e.KEY_DELETE && key != e.KEY_BACKSPACE) {

                if (inp.value == attr) {
                    this.clearValue(inp);
                }
            }
        },

        onBlur : function (e) {
            e = GUI.Event.extend(e);

            var inp = e.getTarget(),
                attr = this.getPlaceholder(inp);

            if (inp.value == '') {
                this.setValue(inp, attr);
            }
        },

        onClick: function (e) {
            e = GUI.Event.extend(e);

            var inp = e.getTarget();

            if (inp.getAttribute('unselectable') === 'on') {
                inp.setAttribute('unselectable', 'off');
                inp.focus();
            }
        }
    };

    Object.extend(Placeholders.prototype, prototype);

    window.Placeholders = new Placeholders();
});
