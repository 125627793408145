(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Login implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Login = Class.create();
    Object.extend(GUI.Forms.Validation.Login.prototype, superproto);

    /**
     * Text of the message, default is 'i18n.GUI_VALIDATION_LOGIN'
     * @type String
     */
    GUI.Forms.Validation.Login.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_LOGIN);

    /**
     * Type of the element, 'GUI.Forms.Validation.Login'
     * @type String
     */
    GUI.Forms.Validation.Login.prototype.elementType = 'GUI.Forms.Validation.Login';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Login.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        this.pattern = /(^[A-Za-z]+[A-Za-z0-9._\-]*$)|(^([^@\s]+)@((?:[-a-z0-9]+)+([\.a-z]{3,})?)$)/;
    };
}());