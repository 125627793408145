(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Calendar implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Calendar = Class.create();
    Object.extend(GUI.Calendar.prototype, superproto);

    /**
     * Template
     * @type String
     */
    GUI.Calendar.prototype.template =
        '<div class="b-datepicker__period b-buttons-list" id="{this.periodId}"></div>' +
        '<table id="{this.daysTableId}" class="b-datepicker__table" cellpadding="0" cellspacing="0">' +
        '<thead id="{this.daysHeadId}" class="b-datepicker__head">' +
        '<tr class="b-datepicker__row"></tr>' +
        '</thead>' +
        '<tbody id="{this.daysBodyId}" class="b-datepicker__body"></tbody>' +
        '</table>' +
        '<div class="navigation b-buttons-list">' +
        '<button class="b-button b-button_simple" id="{this.prevBtnId}">' +
        '<i class="b-button__icon previous">&nbsp;</i>' +
        '</button>' +
        '<button class="b-button b-button_simple" id="{this.todayBtnId}">' +
        '<span class="b-button__label">' + i18n('Today') + '</span>' +
        '</button>' +
        '<button class="b-button b-button_simple" id="{this.nextBtnId}">' +
        '<i class="b-button__icon next">&nbsp;</i>' +
        '</button>' +
        '</div>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Calendar.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);
        this.config =  {
            id          : GUI.getUniqId('calendar-'),
            holder      : null,
            visible     : true,
            date        : null,
            disabled    : [],
            events      : [],
            offset      : 0, // 0 - Su,Mo,... 1 - Mo,Tu,..
            daysOfWeek  : [ 
                            // TRANSLATORS: Short form for Sunday
                            i18n('SU'),
                            // TRANSLATORS: Short form for Monday
                            i18n('MO'),
                            // TRANSLATORS: Short form for Tuesday
                            i18n('TU'),
                            // TRANSLATORS: Short form for Wednesday
                            i18n('WE'),
                            // TRANSLATORS: Short form for Thursday
                            i18n('TH'),
                            // TRANSLATORS: Short form for Friday
                            i18n('FR'),
                            // TRANSLATORS: Short form for Saturday
                            i18n('SA')],
            monthNames  : [
                i18n('January'),
                i18n('February'),
                i18n('March'),
                i18n('April'),
                pgettext('Long form (same as the short form in English, but may be different in other languages)', 'May'),
                i18n('June'),
                i18n('July'),
                i18n('August'),
                i18n('September'),
                i18n('October'),
                i18n('November'),
                i18n('December')
            ],
            nextMonth   : i18n('Next month'),
            prevMonth   : i18n('Prev month'),
            today       : i18n('Today')
        };
        var cfg = this.config;
        Object.extend(cfg, config);

        this.setDate((cfg.date) ? cfg.date : new Date());

        this.baseId         = cfg.id + '-day';
        this.daysTableId    = cfg.id + '-days';
        this.daysHeadId     = cfg.id + '-days-head';
        this.daysBodyId     = cfg.id + '-days-body';
        //this.monthComboId   = cfg.id + '-monthcombo';
        //this.yearComboId    = cfg.id + '-yearcombo';
        this.periodId       = cfg.id + '-period';
        this.nextBtnId      = cfg.id + '-btn-next';
        this.prevBtnId      = cfg.id + '-btn-prev';
        this.todayBtnId     = cfg.id + '-btn-today';

        this.visible = cfg.visible;

        this.dayClickListener        = this.dayClickHandler.bindAsEventListener(this);
        this.nextBtnClickListener    = this.nextBtnClickHandler.bindAsEventListener(this);
        this.prevBtnClickListener    = this.prevBtnClickHandler.bindAsEventListener(this);
        this.todayBtnClickListener   = this.todayBtnClickHandler.bindAsEventListener(this);

        this.addEvents({
            change  : true,
            dayClick: true
        });

    };

    /**
     * Destroys objects and dom, removes listeners
     */
    GUI.Calendar.prototype.destroy = function () {
        if (!this.dom) {
            return;
        }

        this.removeEventListeners();
        GUI.destroyNode(this.dom);
        this.config.holder = null;
        this.dom = null;
    };

    /**
     * Destroys combo, dom
     */
    GUI.Calendar.prototype.unrender = function () {
        if (this.dom) {
            this.removeEventListeners();
           // this.monthCombo.destroy(true);
            this.yearCombo.destroy(true);
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
    };

    /**
     * Renders objects, creates combo with dates and years.
     * @param {HTMLElement} to Element to render item to
     */
    GUI.Calendar.prototype.render = function (to) {
        var cfg, div, tpl, i, monthes, mNames;
        if (this.dom) {
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
        cfg = this.config;

        div = document.createElement('DIV');
        div.className = 'b-datepicker';
        GUI.Dom.extend(div);
        if (GUI.isString(cfg.style)) {
            div.addClass(cfg.style);
        }
        tpl = new GUI.STemplate(this.template, this);
        tpl.cfg = cfg;

        if (to) {
            cfg.holder = to;
        }

        GUI.hide(div);

        // Render
        GUI.$(cfg.holder).appendChild(div);

        div.innerHTML = tpl.fetch();
        this.dom = div;

        this.monthCombo = new GUI.Forms.Combo({
            wrapperClass: 'month',
            width: ''
        });
        monthes = [];
        mNames = this.config.monthNames;
        for (i = 0; i < mNames.length; i++) {
            monthes.push({
                text    : mNames[i],
                value   : i
            });
        }
        this.monthCombo.setOptions(monthes);
        this.monthCombo.select(this.curMonth);
        this.monthCombo.render(GUI.$(this.periodId));
        this.monthCombo.on('change', this.onMonthChange.bindLegacy(this));

        /* temporary */
        this.yearCombo = new GUI.Forms.SpinEdit({
            wrapperClass   : 'year',
            min     : 1900,
            max     : 2099
        });

        this.yearCombo.setValue(this.curYear);
        this.yearCombo.render(GUI.$(this.periodId));
        this.yearCombo.on('change', this.onYearChange.bindLegacy(this));

        this.update();

        // Show rendered quickbar
        if (this.visible) {
            GUI.show(div);
        }

        this.attachEventListeners();
    };

    /**
     * Sets current month, updates calendar.
     * @param {Object} combo Object of combo
     * @param {String} val New value
     */
    GUI.Calendar.prototype.onMonthChange = function (combo, val) {
        this.curMonth = val;
        this.update();
    };

    /**
     * Sets current year, updates calendar.
     * @param {Object} combo Object of combo
     * @param {String} val New value
     */
    GUI.Calendar.prototype.onYearChange = function (combo, val) {
        this.curYear = val;
        this.update();
    };

    /**
     * Returns first day of the month
     * @param {String} year
     * @param {String} month
     * @returns {Stirng} day
     */
    GUI.Calendar.prototype.getFirstDay = function (year, month) {
        var firstDate = new Date(year, month, 1);
        return firstDate.getDay();
    };

    /**
     * Returns number of days of the month
     * @param {String} theYear
     * @param {String} theMonth
     * @returns {String} num
     */
    GUI.Calendar.prototype.getMonthLen = function (theYear, theMonth) {
        var nextMonth = new Date(theYear, theMonth + 1, 1);
        nextMonth.setHours(nextMonth.getHours() - 3);
        return nextMonth.getDate();
    };

    /**
     * Updates calendar
     */
    GUI.Calendar.prototype.update = function () {
        var offset, startIndex, len, last, index,
            days, prevDays, day, dayNum, oldTbody, today,
            thead, oldThead, tbody, tr, td, rows, row, col, cell,
            date = this.date,
            selectedDayNum = date.getDate();

        this.monthCombo.select(this.curMonth);
        this.yearCombo.setValue(this.curYear);

        offset        = this.config.offset;
        startIndex    = this.getFirstDay(this.curYear, this.curMonth) - offset;

        if (startIndex < 0) {
            startIndex +=  7;
        }

        days        = this.getMonthLen(this.curYear, this.curMonth);
        prevDays    = this.getMonthLen(this.curYear, this.curMonth - 1);

        // render days theader
        thead = document.createElement('thead');
        thead.id = this.daysHeadId;
        thead.className = 'b-datepicker__head';

        tr = document.createElement('TR');
        tr.className = 'b-datepicker__row';
        thead.appendChild(tr);

        len = 7;
        last = (offset > 0) ? (offset - 1) : (offset + 6);
        index = offset;

        while (len--) {
            td = document.createElement('TD');
            td.className = 'b-datepicker__cell';
            tr.appendChild(td);

            if (index > 6) {
                index -= 7;
            }

            day = document.createTextNode(this.config.daysOfWeek[index]);
            td.appendChild(day);
            index++;
        }

        oldThead = GUI.$(this.daysHeadId);
        GUI.replace(oldThead, thead);

        // render days tbody
        tbody = document.createElement('tbody');
        tbody.id = this.daysBodyId;
        tbody.className = 'b-datepicker__body';

        rows = 6;
        index = 0;

        for (row = 0; row < rows; row++) {
            tr = document.createElement('TR');
            tr.className = 'b-datepicker__row';

            tbody.appendChild(tr);

            for (col = 0; col < 7; col++, index++) {
                td = document.createElement('TD');
                td.className = 'b-datepicker__cell';
                tr.appendChild(td);

                dayNum = index - startIndex + 1;

                if (dayNum >= 1 && dayNum <= days) {
                    if (dayNum === selectedDayNum) {
                        GUI.Dom.addClass(td, 'selected');
                    }
                    day = document.createTextNode(dayNum);
                    td.appendChild(day);
                    td.id = this.baseId + dayNum;
                } else {
                    GUI.Dom.addClass(td, 'disabled');
                    day = (dayNum > days)
                        ? (dayNum - days)
                        : (prevDays + dayNum);
                    day = document.createTextNode(day);
                    td.appendChild(day);
                }
            }
        }

        oldTbody = GUI.$(this.daysBodyId);
        GUI.replace(oldTbody, tbody);

        today = new Date();
        if (this.curYear === today.getFullYear() && this.curMonth === today.getMonth()) {
            day = today.getDate();
            cell = GUI.$(this.baseId + day);
            if (cell) {
                cell.addClass('current');
            }
        }
    };

    /**
     * Shows dom
     */
    GUI.Calendar.prototype.show = function () {
        if (!this.visible) {
            if (this.dom) {
                GUI.show(this.dom);
            }
            this.visible = true;
        }
    };

    /**
     * Hides dom
     */
    GUI.Calendar.prototype.hide = function () {
        if (this.visible) {
            if (this.dom) {
                GUI.hide(this.dom);
            }
            this.visible = false;
        }
    };

    /**
     * Updates height of the content, subjects.
     */
    GUI.Calendar.prototype.prepareFx = function () {
        if (!this.config.height && !this.fx.isRunning()) {
            if (this.contentHeight === null) {
                this.updateContentHeight();
                this.fx.clearSubjects();
                this.fx.addSubject(new window.NumericalStyleSubject(GUI.$(this.contentId), 'height', 0, this.contentHeight));
            }
            if (this.collapsed) {
                this.fx.state = 1.0;
            }
        }
    };

    /**
     * Attach events listeners
     */
    GUI.Calendar.prototype.attachEventListeners = function () {
        Event.observe(GUI.$(this.daysTableId),  'click', this.dayClickListener);
        Event.observe(GUI.$(this.nextBtnId),    'click', this.nextBtnClickListener);
        Event.observe(GUI.$(this.prevBtnId),    'click', this.prevBtnClickListener);
        Event.observe(GUI.$(this.todayBtnId),   'click', this.todayBtnClickListener);
    };

    /**
     * Removes events listeners
     */
    GUI.Calendar.prototype.removeEventListeners = function () {
        Event.stopObserving(GUI.$(this.daysTableId), 'click', this.dayClickListener);
        Event.stopObserving(GUI.$(this.nextBtnId),   'click', this.nextBtnClickListener);
        Event.stopObserving(GUI.$(this.prevBtnId),   'click', this.prevBtnClickListener);
        Event.stopObserving(GUI.$(this.todayBtnId),  'click', this.todayBtnClickListener);
    };

    /**
     * Sets date
     * @param {Object} date
     */
    GUI.Calendar.prototype.setDate = function (date) {
        this.date = new Date(date.toDateString());
        this.curYear = date.getFullYear();
        this.curMonth = date.getMonth();
        this.curDay = date.getDate();

        if (this.dom) {
            this.update();
        }
    };

    /**
     * Sets current day, fires events 'change', 'dayClick'
     * @param {Event} e Event
     */
    GUI.Calendar.prototype.dayClickHandler = function (e) {
        e = GUI.Event.extend(e);
        var day,
            td = e.target.findParent('TD', GUI.$(this.daysTableId));

        if (td) {
            GUI.Dom.extend(td);
            if (td.parentNode.parentNode.tagName.toLowerCase() === 'thead') {
                return;
            }

            // remove selected
            var current_td;
            if ((current_td = GUI.$(this.baseId + this.curDay))) {
                GUI.Dom.removeClass(current_td, 'selected');
            }

            // sets day
            if (td.hasClass('current')) {
                day = parseInt(td.innerHTML, 10);
            } else {
                GUI.Dom.addClass(td, 'selected');
                day = parseInt(td.innerHTML, 10);
            }

            if (day && !td.hasClass('disabled')) {
                this.curDay = day;
                this.date = new Date(this.curYear, this.curMonth, day);
                this.fireEvent('change', this, this.date);
                this.fireEvent('dayClick', this, this.date);
            }
        }
    };

    /**
     * Sets next date
     * @param {Event} e Event
     */
    GUI.Calendar.prototype.nextBtnClickHandler = function (e) {
        var newDate = new Date(this.curYear, this.curMonth + 1, this.curDay);
        this.setDate(newDate);
    };

    /**
     * Sets prev date
     * @param {Event} e Event
     */
    GUI.Calendar.prototype.prevBtnClickHandler = function (e) {
        var newDate = new Date(this.curYear, this.curMonth - 1, this.curDay);
        this.setDate(newDate);
    };

    /**
     * Sets todayy day
     * @param {Event} e Event
     */
    GUI.Calendar.prototype.todayBtnClickHandler = function (e) {
        var date = new Date();
        if ((this.curYear === date.getFullYear())
                && (this.curMonth === date.getMonth())) {
            this.setDate(date);
            this.fireEvent('change', this, this.date);
            this.fireEvent('dayClick', this, this.date);
        } else {
            this.setDate(date);
        }
    };

    /**
     * Returns cell
     * @param {Event} e Event
     * @returns {HTMLElement} cell
     */
    GUI.Calendar.prototype.getEventCell = function (e) {
        var target = GUI.getEventTarget(e);
        return GUI.findParentByTag(target, 'td', GUI.$(this.daysBodyId));
    };

}());
