(function () {
    var superproto = GUI.Forms.Validation.Number.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Integer implementation
    *
    * @author Sergey Ostapenko
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Number
    */
    GUI.Forms.Validation.Integer = Class.create();
    Object.extend(GUI.Forms.Validation.Integer.prototype, superproto);

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Integer.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        this.pattern =  /^\d*$/;
    };

}());