(function () {
    var superproto = GUI.Resizer.prototype;

    /**
     * JavaScript Graphical User Interface
     * Paging Resizer implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Resizer
     */
    GUI.PagingResizer = Class.create();
    Object.extend(GUI.PagingResizer.prototype, superproto);

    /**
     * Vertical position, default is false
     * @type Boolean
     */
    GUI.PagingResizer.prototype.vertical = false;

    /**
     * Offset Y, default is -30
     * @type Number
     */
    GUI.PagingResizer.prototype.offsetY = -30;

    /**
     * Css class, default is 'jsgui-pagingresizer'
     * @type String
     */
    GUI.PagingResizer.prototype.addClass = '';

    /**
     * Text of the hint
     * @type String
     */
    GUI.PagingResizer.prototype.hint = 'Drag to change number of records per page displayed';

    /**
     * Default is 25
     * @type Number
     */
    GUI.PagingResizer.prototype.step = 25;

    /**
     * Minimum number of remaining rows, default is 5
     * @type Number
     */
    GUI.PagingResizer.prototype.minPageSize = 5;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.PagingResizer.prototype.autoRefresh = true;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.PagingResizer.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);

       // this.dragProxyTemplate = '<div class="jsgui-resizer-shadow"></div><div class="' + this.baseClass + ' jsgui-pagingresizer"><div></div></div>';

        this.dragproxyTemplate = '<i class="b-resizer b-resizer_selected"><i class="b-resizer__shadow"></i><i class="b-resizer__snatch"></i></i>';

        if (!this.paging) {
            throw new Error('GUI.Resizer: no paging was specified');
        }

        this.addEvents({
            'pageSizeChange': true
        });
    };

    /**
     * Sets current rows
     */
    GUI.PagingResizer.prototype.onResizeStart = function () {
        this._currentRows = (GUI.isArray(this.paging) ? this.paging[0] : this.paging).getPageSize();
    };

    /**
     * Returns normalized step
     * @param {Number} deltaStep
     * @returns {Number} step
     */
    GUI.PagingResizer.prototype.adjustDeltaStep = function (deltaStep) {
        var newRows = (this._currentRows + deltaStep).constrain(this.minPageSize);
        return newRows - this._currentRows;
    };

    /**
     * Returns info about update
     * @param {Number} deltaStep
     * @returns {String} info
     */
    GUI.PagingResizer.prototype.updateInfo = function (deltaStep) {
        return 'Change page size from <b>' + this._currentRows + '</b> to <b>'
            + (this._currentRows + deltaStep) + '</b> records per page';
    };

    /**
     * Updates paging with passed new rows
     * @param {Object} paging
     * @param {newRows}
     */
    GUI.PagingResizer.prototype.updatePaging = function (paging, newRows) {
        if (GUI.isArray(paging)) {
            var i = paging.length;
            while (i--) {
                this.updatePaging(paging[i], newRows);
            }
            return;
        }

        paging.setPageSize(newRows);
        if (this.autoRefresh) {
            paging.load();
        }
    };

    /**
     * Update paging, fires event 'pageSizeChange'
     * @param {Number} delateStep step
     */
    GUI.PagingResizer.prototype.onResizeEnd = function (deltaStep) {
        if (deltaStep !== 0) {
            var newRows = this._currentRows + deltaStep;

            this.updatePaging(this.paging, newRows);
            this.fireEvent('pageSizeChange', this, newRows, this._currentRows);
        }
    };

    /**
     * Sets paging to null
     */
    GUI.PagingResizer.prototype.onBeforeDestroy = function () {
        this.paging = null;
    };

}());