(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * QuickBar implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.QuickBar = Class.create();
    Object.extend(GUI.QuickBar.prototype, superproto);

    /**
     *
     * @type
     */
    GUI.QuickBar.prototype.template =
        '<li id="{this.quickbarId}" class="b-quickbar__item b-quickbar__item " >' +
        '<div id="{this.toolBoxId}" class="b-quickbar__ttl">' +
        '<span id="{this.titleId}" class="b-quickbar__ttl-txt">{cfg.title}</span>' +
        '</div>' +
        '<div id="{this.bodyId}" class="b-quickbar__data">' +
        '<div id="{this.captionDataId}" class="b-quickbar__data__ttl">' +
        '<i id="{this.imgId}" class="b-quickbar__data__ttl-ico" style="background-image: url({cfg.icon})">&nbsp;</i>' +
        '<span id="{this.captionId}" class="b-quickbar__data__ttl-txt">{cfg.caption}</span>' +
        '</div>' +
        '<div id="{this.toolbarId}"></div>' +
        '<div id="{this.contentDataId}">{cfg.content}</div>' +
        '</div>' +
        '</li>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.QuickBar.prototype.initialize = function (config) {
        var cfg, id, fxConfig;
        // Call parent method
        this.config =  {
            holder          : null,
            height          : null,
            icon            : '',
            caption         : '',
            content         : '',
            visible         : true,
            lazyRender      : true,
            canCollapse     : true,
            //collapsableCls  : '',
            collapsed       : false,
            animate         : window.GUI_FX_ANIMATION === true, // boolean convertation
            style           : null,
            //contentcls      : '',
            //contentStyle    : '',
            toolbar         : null,
            toolbarHeight   : 27,
            headerHeight    : 42,
            hiddenTitle     : false,
            name            : null,
            toolbox         : null,
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                targetElId  : null,
                isTarget    : true,
                moveOnly    : false,
                groups      : {
                    'portal' : true
                },
                clipElId    : null,
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            },
            reserveSpaceForAnimation: false
        };
        cfg = this.config;
        Object.extend(cfg, config);

        superproto.initialize.call(this, cfg); // Draggable

        id = this.getId();
        this.quickbarId   = id + '-quickbar';
        this.titleId      = id + '-quickbar-title';
        this.captionDataId = id + '-quickbar-caption-data';
        this.captionId    = id + '-quickbar-caption';
        this.imgId        = id + '-quickbar-ico';
        this.bodyId       = id + '-quickbar-body';
        this.toolbarId    = id + '-quickbar-toolbar';
        this.toolBoxId    = id + '-quickbar-toolbox';
        this.contentDataId = id + '-quickbar-contentdata';

        this.visible    = cfg.visible;

        this.toolbar    = null;
        this.toolbox    = null;
        this.collapsed  = cfg.collapsed;

        if (GUI.isArray(cfg.toolbox)) {
            this.toolbox = new GUI.ToolBox({
                items: cfg.toolbox
            });
        }

        if (!cfg.caption || cfg.caption === '') {
            cfg.caption = false;
        }

        if (GUI.isArray(cfg.toolbar)) {
            this.toolbar = new GUI.ToolBar({
                elements    : cfg.toolbar,
                type        : 'dialog'
            });
        } else {
            cfg.toolbarHeight = 0;
        }

        this.addEvents({
            collapse : true,
            expand   : true,
            resize   : true
        });

        if (cfg.animate) {
            this.addEvents({
                fxComplete: true
            });
            fxConfig = {
                duration    : 500,
                scope       : this,
                onComplete  : function (fx) {
                    this.fireEvent('fxComplete', fx);
                }
            };

            this.fx = new Animator(fxConfig);

            this.on('fxComplete', function (fx) {
                if (fx.state) {
                    this._expand();
                } else {
                    this._collapse();
                }
            });
        }

        if (!cfg.lazyRender) {
            this.render();
        }

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        } else {
            GUI.ComponentMgr.register(this);
        }

    };

    /**
     * Returns id of quickbar
     * @param {String|Number} id
     */
    GUI.QuickBar.prototype.getId = function () {
        return this.config.id || (this.config.id = "jsgui-cmp-" + (++GUI.Component.AUTO_ID));
    };

    /**
     * Destroy objects
     */
    GUI.QuickBar.prototype.destroy = function (quick) {
        this.config.holder = null;

        if (this.fx) {
            this.fx.destroy();
            this.fx = null;
        }

        GUI.ComponentMgr.unregister(this);

        if (this.toolbar) {
            this.toolbar.destroy(true);
            this.toolbar = null;
        }

        this.purgeListeners();

        if (this.dom) {
            this.removeEventListeners();
            if (!quick) {
                GUI.destroyNode(this.dom);
            }
            this.dom = null;
        }
    };

    /**
     * Renders dom
     * @param {HTMLElement} to Element to render dom to
     */
    GUI.QuickBar.prototype.render = function (to) {
        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
        var tpl,
            cfg = this.config,
            ul_el = document.createElement('UL');

        // Render
        if (to) {
            cfg.holder = to;
        }
        GUI.hide(cfg.holder);

        ul_el.className = 'b-quickbar';

        GUI.$(cfg.holder).appendChild(ul_el);

        tpl = new GUI.STemplate(this.template, this);
        tpl.cfg = cfg;
        ul_el.innerHTML = tpl.fetch();

        this.dom = GUI.Dom.extend(ul_el);

        if (cfg.hiddenTitle && this.collapsed) {
            this.collapsed = false;
        }

        if (cfg.hiddenTitle) {
            GUI.hide(this.titleId);
        }

        if (!cfg.caption) {
            GUI.hide(this.captionDataId);
        }

        // render toolbox
        if (this.toolbox) {
            this.toolbox.render(GUI.$(this.toolBoxId));
        }

        // render toolbar if needed
        if (this.toolbar) {
            this.toolbar.render(GUI.$(this.toolbarId));
        }

        if (cfg.canCollapse && this.collapsed) {
            GUI.hide(this.bodyId);
        } else {
            GUI.$(this.quickbarId).addClass('selected');
        }

        this.setDimensions(cfg.width, cfg.height);

        // Show rendered quickbar
        if (this.visible) {
            GUI.show(cfg.holder);
        }

        this.attachEventListeners();
        GUI.unselectable(this.toolBoxId);
        GUI.unselectable(this.toolbarId);
        this.setScrollable(this.config.scrollable);

        if (cfg.dd.enableDrag) {
            // Initialize Drag'n'Drop
            this.ddConfig.linkedElId    = this.titleId;
            this.ddConfig.targetElId    = this.titleId;
            this.ddConfig.clipElId      = cfg.portal.ddConfig.clipElId;
            this.ddConfig.groups        = cfg.portal.ddConfig.groups;

            this.initDD();
            this.setHandleElId(this.titleId);
        }

        this.rendered = true;
    };

    /**
     * Sets height of the content
     */
    GUI.QuickBar.prototype.updateContentHeight = function () {
        var h = this.collapsed ? GUI.getFullHeight(GUI.$(this.bodyId)) : GUI.$(this.bodyId).getHeight();
        this.bodyHeight = h;
    };

    /**
     * Returns height of the body
     * @param {Number} height
     */
    GUI.QuickBar.prototype.getBodyHeightFromFull = function (height) {
        height -= this.config.headerHeight;
        height -= GUI.getVerticalPadding(this.dom);
        return (height >= 0) ? height : 0;
    };

    /**
     * Returns full height of the body
     * @param {Number} height
     */
    GUI.QuickBar.prototype.getFullHeightFromBody = function (height) {
        height += this.config.headerHeight;
        height += GUI.getVerticalPadding(this.dom);
        return height;
    };

    /**
     * Sets dimensions of the body, fires events 'resize', 'contentresize'
     * @param {Number} width
     * @param {Number} height
     */
    GUI.QuickBar.prototype.setDimensions = function (width, height) {
        var s, contentWidth, contentHeight;

        if (GUI.isSet(width)) {
            this.config.width = width;
            if (this.dom) {
                s = GUI.$(this.contentDataId).style;
                if (GUI.isNumber(width)) {
                    contentWidth = width - 2;
                    contentWidth = contentWidth.constrain(0); // TODO: min/max width

                    if (GUI.isBorderBox) {
                        s.width = contentWidth + 2 + 'px';
                    } else {
                        s.width = contentWidth + 'px';
                    }
                    if (!GUI.isSet(this.config.scrollable)) {
                        this.setScrollable(true);
                    }
                } else {
                    s.width = contentWidth = width;
                }
            }
        }

        if (GUI.isSet(height)) {
            this.config.height = height;
            if (this.dom) {
                s = GUI.$(this.contentDataId).style;
                if (GUI.isNumber(height)) {
                    contentHeight = this.getBodyHeightFromFull(height) - this.config.toolbarHeight - 1; // 1px padding on content

                    contentHeight = contentHeight.constrain(0); // TODO: min/max height
                    s.height = contentHeight + 'px';
                    if (!GUI.isSet(this.config.scrollable)) {
                        this.setScrollable(true);
                    }
                } else {
                    s.height = contentHeight = height;
                }
            }
        }
        this.fireEvent('resize', width, height);
        this.fireEvent('contentresize', contentWidth, contentHeight);
    };

    /**
     * Fires event 'contentresize'
     */
    GUI.QuickBar.prototype.fireContentResize = function () {
        var cfg = this.config,
            dims = {
                width  : cfg.width - 2,
                height : this.getBodyHeightFromFull(cfg.height) - cfg.toolbarHeight - 1
            };
        if (GUI.isIE) {
            dims.height--; // Strange bug
        }
        this.fireEvent('contentresize', dims.width, dims.height);
    };

    /**
     * Sets height of the body
     * @param {Number} height
     */
    GUI.QuickBar.prototype.setHeight = function (height) {
        this.setDimensions(undefined, height);
    };

    /**
     * Sets width of the body
     * @param {Number} width
     */
    GUI.QuickBar.prototype.setWidth = function (width) {
        this.setDimensions(width, undefined);
    };

    /**
     * Sets scrollable to passing value
     * @param {Boolean} src
     */
    GUI.QuickBar.prototype.setScrollable = function (scr) {
        scr = GUI.isSet(scr) ? !!scr : true;
        this.config.scrollable = scr;

        if (this.dom) {
            GUI.$(this.contentDataId).style.overflow = scr ? 'auto' : 'hidden';
        }
    };

    /**
     * Shows dom
     */
    GUI.QuickBar.prototype.show = function () {
        if (this.dom && !this.visible) {
            GUI.show(this.dom);
            this.visible = true;
        }
    };

    /**
     * Hides dom
     */
    GUI.QuickBar.prototype.hide = function () {
        if (this.dom && this.visible) {
            GUI.hide(this.dom);
            this.visible = false;
        }
    };

    /**
     * Updates animation objects
     */
    GUI.QuickBar.prototype.prepareFx = function () {
        if (this.fx.isRunning()) {
            return;
        }
        var h,
            startHeight = (GUI.isIE) ? 1 : 0; // IE hack, 0 doesn't work

        if (this.config.height) {
            this.fx.clearSubjects();
            this.fx.addSubject(new window.NumericalStyleSubject(GUI.$(this.bodyId), 'height', startHeight, this.getBodyHeightFromFull(this.config.height)));
        } else {
            if (this.bodyHeight === undefined) {
                this.updateContentHeight();
                this.fx.clearSubjects();
                if (!GUI.isNumber(this.bodyHeight)) {
                    alert(this.bodyHeight);
                }
                this.fx.addSubject(new window.NumericalStyleSubject(GUI.$(this.bodyId), 'height', startHeight, this.bodyHeight));
            }
        }

        /*if (this.config.reserveSpaceForAnimation) {
            if (this.config.height !== undefined) {
                h = this.config.height;
            } else if (!this.collapsed) {
                h = GUI.getDimensions(this.outerDiv).width;
            } else {
                h = this.outerDiv.offsetHeight;
            }
            this.outerDiv.style.height = h + 'px';
        }*/

        GUI.$(this.contentDataId).style.overflow = 'hidden'; // Fixes animation visual glitches
        if (this.collapsed) {
            this.fx.state = 1.0;
        }
    };

    /**
     * Returns state of the variable 'collapsed'
     * @returns {Boolean} collapsed
     */
    GUI.QuickBar.prototype.isCollapsed = function () {
        return this.collapsed;
    };

    /**
     * True if the variable 'collapsed' is false
     * @returns {Boolean} collapsed
     */
    GUI.QuickBar.prototype.isExpanded = function () {
        return !this.collapsed;
    };

    /**
     * Hides collapse image, shows expand, fire event 'collapse'
     */
    GUI.QuickBar.prototype.collapse = function () {
        if (!this.config.canCollapse) {
            return 0;
        }

        if (!this.collapsed) {
            if (this.dom) {
                this.collapsed = true;
                if (this.config.animate && this.fx) {
                    this.prepareFx();
                    this.fx.seekTo(0);
                    this.fireEvent('collapse', this);
                } else {
                    this._collapse();
                    this.fireEvent('collapse', this);
                }
            } else {
                this.collapsed = true;
                this.fireEvent('collapse', this);
            }
        }
        return true;
    };

    /**
     * Sets height
     */
    GUI.QuickBar.prototype._collapse = function () {
        GUI.hide(GUI.$(this.bodyId));
        GUI.$(this.bodyId).style.height = '';
        GUI.$(this.quickbarId).removeClass('selected');
        if (!this.config.height) {
            this.suspendEvents();
            this.setHeight('');
            this.resumeEvents();
        }

        /*if (this.config.reserveSpaceForAnimation) {
            this.outerDiv.style.height = '';
        }*/
    };

    /**
     * Hides expand image, shows collapse, fires event 'expand'
     */
    GUI.QuickBar.prototype.expand = function () {
        if (!this.config.canCollapse) {
            return 0;
        }

        if (this.collapsed) {
            if (this.dom) {
                this.collapsed = false;
                GUI.$(this.quickbarId).addClass('selected');
                // Check if quickbar fits into container after expanding
                var cont = GUI.getScrollableContainer(this.dom);

                if (this.config.animate && this.fx) {
                    this.prepareFx();
                    GUI.$(this.bodyId).style.height = (GUI.isIE) ? '1px' : '0'; // IE hack, 0 doesn't work
                    GUI.show(GUI.$(this.bodyId));

                    this.fx.seekTo(1);
                    this.fireEvent('expand', this);
                } else {
                    GUI.show(GUI.$(this.bodyId));
                    this._expand();
                    this.fireEvent('expand', this);
                }
            } else {
                this.collapsed = false;
                this.fireEvent('expand', this);
            }
        }
        return true;
    };

    /**
     * Updates height. If elements's height smaller, than conatiner's,
     * display element at the bottom of visible area. Else display it at the top.
     * @param {Object} elem
     * @param {HTMLElement} cont
     */
    GUI.QuickBar.prototype.fitHeight = function (elem, cont) {
        var eHeight, cTop, cHeight, off;
        if (!this.config.height) {
            this.updateContentHeight();
        }

        eHeight = (this.config.height) ? this.config.height
            : this.getFullHeightFromBody(this.bodyHeight);
        this.bodyHeight = null;

        // Reserve space for expanding...
        this.dom.parentNode.style.height =
            GUI.getInnerNodesHeight(this.dom.parentNode)
            - this.dom.offsetHeight
            + 1 + eHeight + 'px';

        if (!cont) {
            cont = elem.parentNode;
        }

        cTop = cont.scrollTop;
        cHeight = GUI.$(cont).offsetHeight;
        off = GUI.getRelativeOffset(elem, cont);

        if ((off[1] >= cTop) && (off[1] + eHeight <= cTop + cHeight)) {
            return true;
        }

        if (eHeight < cHeight) {
            // If elements's height smaller, than conatiner's, display
            // element at the bottom of visible area
            cont.scrollTop = off[1] - cHeight + eHeight;
        } else {
            // Else display it at the top
            cont.scrollTop = off[1];
        }
        return false;
    };

    /**
     * Sets height of the body
     */
    GUI.QuickBar.prototype._expand = function () {
        if (!this.config.height) {
            this.suspendEvents();
            this.setHeight('');
            this.resumeEvents();
        }
        GUI.$(this.bodyId).style.height = '';
        if (this.config.height) {
            GUI.$(this.contentDataId).style.overflow = 'auto'; // return overflow state
        }
        // Repaint the element
        GUI.repaint(this.dom);

       /* if (this.config.reserveSpaceForAnimation) {
            this.outerDiv.style.height = '';
        }*/
    };

    /**
     * Sets title of the quickbar
     * @param {String} text
     */
    GUI.QuickBar.prototype.setTitle = function (text) {
        var cfg = this.config;
        cfg.title = text;
        if (this.dom && GUI.isString(text) && GUI.$(this.titleId)) {
            GUI.$(this.titleId).innerHTML = text;
        }
    };

    /**
     * Sets caption of the quickbar
     * @param {String} text
     */
    GUI.QuickBar.prototype.setCaption = function (text) {
        var cfg = this.config;
        cfg.caption = text;
        if (this.dom && GUI.isString(text) && GUI.$(this.captionId)) {
            GUI.$(this.captionId).innerHTML = text;
        }
    };

    /**
     * Sets content
     * @param {String} content
     */
    GUI.QuickBar.prototype.setContent = function (content) {
        this.config.content = content;
        if (this.dom && GUI.isString(content)) {
            GUI.$(this.contentDataId).innerHTML = content;
        }
        if (!this.config.height) {
            // Reset content height cache
            this.bodyHeight = null;
        }
    };

    /**
     * Return holder of the content
     * @returns {HTMLElement} holder
     */
    GUI.QuickBar.prototype.getContentHolder = function () {
        return GUI.$(this.contentDataId);
    };

    /**
     * Return height of the content
     * @returns {HTMLElement} holder
     */
    GUI.QuickBar.prototype.getContentHeight = function (parentNode) {
        var toolboxHeight = 0,
            toolbarHeight = 0,
            parentNodeHeight = parseInt(parentNode.style.height) || GUI.getClientHeight(parentNode);

        if (this.toolbox) {
            toolboxHeight = GUI.getClientHeight(this.toolbox.dom.parentNode);
        }

        if (this.toolbar) {
            toolbarHeight = GUI.getClientHeight(this.toolbar.dom.parentNode);
        }

        return Math.ceil(parentNodeHeight - toolboxHeight - toolbarHeight);
    };

    /**
     * Attach events listeners
     */
    GUI.QuickBar.prototype.attachEventListeners = function () {
        if (this.config.dd.enableDrag) {
            return;
        }
        if (this.config.canCollapse) {
            var title = GUI.Dom.findDescedents(this.dom, 'div.b-quickbar__ttl');
            if (title.length) {
                GUI.Event.on(title[0], 'click', this.clickHandler, this);
            }
            title = null;
        }
    };

    /**
     * Removes events listeners
     */
    GUI.QuickBar.prototype.removeEventListeners = function () {
        if (this.config.canCollapse) {
            var title = GUI.Dom.findDescedents(this.dom, 'div.b-quickbar__ttl');
            if (title.length) {
                GUI.Event.un(title[0], 'click', this.clickHandler, this);
            }
        }
    };

    /**
     * Expand or collapse item
     * @param {Event} e Event
     */
    GUI.QuickBar.prototype.clickHandler = function (e) {
        if (this.collapsed) {
            this.expand();
        } else {
            this.collapse();
        }
    };

    /**
     * empty function
     */
    GUI.QuickBar.prototype.onResize = function (w, h) {};

    /**
     * Returns dom
     * @returns {HTMLElement} dom
     */
    GUI.QuickBar.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Creates proxy element and hides main window
     */
    GUI.QuickBar.prototype.onDragStart = function () {
        if (!this.config.dd.enableDrag) {
            return;
        }
        var cfg = this.config;

        // Create drag proxy and hide main window
        if (!this.dragProxy) { // lazy init
            this.dragProxy = new GUI.Popup.Region({
                className  : 'x-window-proxy',
                zIndex     : this.config.zIndex + 5
            });
        }
        this.dragProxy.takeRegion(this.dom.firstChild);
        GUI.hide(this.dom);
        this.dragProxy.show();
    };

    /**
     * Sets left and top style of the item
     * @param {Event} e Event
     */
    GUI.QuickBar.prototype.onDrag = function (e) {
        if (!this.config.dd.enableDrag) {
            return;
        }
        var xy = e.getPageXY(),
            x = xy[0],
            y = xy[1],
            style = this.dragProxy.dom.style;

        x -= this.DDM.deltaX;
        y -= this.DDM.deltaY;

        if (this.config.constrainViewport) {
            x = x.constrain(0, this.maxX);
            y = y.constrain(0, this.maxY);
        }

        style.left = x + 'px';
        style.top = y + 'px';
    };

    /**
     * Hides proxy element and shows dom
     * @param {Event} e Event
     */
    GUI.QuickBar.prototype.onDragEnd = function (e) {
        if (!this.config.dd.enableDrag) {
            return;
        }
        if (e) {
            var xy = e.getPageXY(),
                x = xy[0],
                y = xy[1];

            x -= this.DDM.deltaX;
            y -= this.DDM.deltaY;

            if (this.config.constrainViewport) {
                x = x.constrain(0, this.maxX);
                y = y.constrain(0, this.maxY);
            }
        }
        this.dragProxy.hide();

        if (this.config.portal.elemBeforePast && this.config.portal.elemToPast) {
            GUI.$(this.config.portal.elemToPast).insertBefore(this.dom.parentNode, this.config.portal.elemBeforePast);
        } else {
            GUI.$(this.config.portal.elemToPast).appendChild(this.dom.parentNode);
        }

        this.config.portal.hideProxy();

        GUI.show(this.dom);
    };

}());