(function () {
    var CommonDialogClass = Class.create();

    var prototype = {

        _setupCloseDialogListeners : function () {
            var close_btn = GUI.$(this._dialogType + '-dialog_close-btn');
            if (close_btn) {
                close_btn.on('click', this.close, this);
            }

            var cancel_btn = GUI.$(this._dialogType + '-dialog_cancel-btn');
            if (cancel_btn) {
                cancel_btn.on('click', this.close, this);
            }

        },

        initialize : function (type) {
            this._dialogType = null;
            this._region     = null;
            this._showed     = false;

            this.setDialogType(type);

            this._nodes = {
                mask : false,
                root : false
            };

            this._nodes.mask = GUI.$(this._dialogType + '-dialog_mask');
            this._nodes.root = GUI.$(this._dialogType + '-dialog');

            this._region = new GUI.Popup.Region();

            this._setupCloseDialogListeners();
        },

        getContentHolder : function () {
            return this._nodes.root;
        },


        show : function () {
            if (!this._showed) {
                GUI.show(this._nodes.mask);
                GUI.show(this._nodes.root);

                this._showed = true;

                this.onShow();

                this.resize();
                GUI.Event.on(window, 'resize', this.resize.bindLegacy(this));
            }
        },

        resize : function () {
            if (this._showed) {
                /* \/ a bit of crazy positioning \/ */
                this._region.show();

                this._region.takeRegion(this._nodes.root);
                this._region.alignTo(document.body, 'c-c');
                var dimensions = this._region.getDimensions();
                this._nodes.root.style.left = dimensions.left.constrain(0) + 'px';
                this._nodes.root.style.top  = dimensions.top.constrain(0)  + 'px';

                this._region.hide();
                /* /\ a bit of crazy positioning /\ */
            }
        },

        close : function () {
            if (this._showed) {
                GUI.hide(this._nodes.mask);
                GUI.hide(this._nodes.root);

                this._showed = false;

                if (this.onClose) {
                    this.onClose();
                }
            }
        },

        onClose : function () {
            throw new Error('This method must be overloaded');
        },

        onShow : function () {
            throw new Error('This method must be overloaded');
        },

        setCaption : function (caption) {
            if (GUI.$(this._dialogType + '-dialog-caption')) {
                GUI.$(this._dialogType + '-dialog-caption').innerHTML = caption;
            }
        },

        setDialogType : function (type) {
            this._dialogType = type;
        }
    };

    Object.extend(CommonDialogClass.prototype, prototype);
    Application.CommonDialogClass = CommonDialogClass;
}());