(function () { // variables isolation

    var LoginFormClass = window.Class.create();

    var prototype = {
        mask            : false,
        email           : '',
        signUpLink      : false,
        backToLoginLink : false,
        dialog          : false,
        isShowed        : false,
        holder          : false,
        frame           : false,
        onSuccessLogin  : false, // can be overridden dynamically

        _setListeners : function () {
            this.dialog.onClose = this.onClose.bindLegacy(this);
            this.dialog.onShow  = this.onShow.bindLegacy(this);

            this.onSuccessLogin = this.defaultCallback;

            Application.on('login', function () {
                if (this.onSuccessLogin) {
                    this.onSuccessLogin();
                }
            }.bindLegacy(this));

            this.backToLoginLink.on('click', function () {
                this.loadFrame(this.getFrame());
            }.bindLegacy(this));

            setTimeout(function () { // wait until Application.AuthPanel will also be created
                if (this.signUpLink) {
                    this.signUpLink.on(
                        'click',
                        Application.AuthPanel.signUp.bindLegacy(Application.AuthPanel)
                    );
                }
            }.bindLegacy(this), 0);
        },

        _createFrame : function (holder) {
            var frame = document.createElement('iframe');
            frame.style.width  = '1px';
            frame.style.height = '1px';
            frame.style.border = 'none';
            frame.id = 'auth_iframe';

            if (holder.firstChild) {
                holder.insertBefore(frame, holder.firstChild);
            } else {
                holder.appendChild(frame);
            }

            return frame;
        },

        _fitAuthForm : function() {
            var height = 0;

            if (Application.settings.internalLogin
                || Application.forceLogin
                || Application.adminRoute
            ) {
                height += 178;
            } else if (Application.settings.socialAuth) {
                height += 44;
            }
            if (Application.settings.socialAuth) {
                height += 46;
            }

            this.getFrame().style.width  = '300px';
            this.getFrame().style.height = height + 'px';
            GUI.hide(this.backToLoginLink);
            if (this.signUpLink) {
                GUI.show(this.signUpLink);
            }
            this.dialog.resize();
        },

        _fitForgotPasswordForm : function() {
            this.getFrame().style.width  = '300px';
            this.getFrame().style.height = '200px';
            if (this.signUpLink) {
                GUI.hide(this.signUpLink);
            }
            GUI.show(this.backToLoginLink);
            this.dialog.resize();
        },

        defaultCallback : function () {
            window.location.reload();
        },

        initialize : function (config) {
            if (!config || !config.dialog) {
                this.dialog = new Application.CommonDialogClass('auth');
            }

            Object.extend(this, config);

            this.mask = new GUI.Popup.Mask({
                className : 'mask', animate : false, stylePosition: 'absolute'
            });
            this.mask.setElement(GUI.$('auth-dialog'));

            this._setListeners();
        },

        show : function (callback, email, showMaskAndNotRemoveDialog) {
            this.close();

            this.email = email && email.toString() === email ? email : '';

            if (!callback) {
                callback = this.defaultCallback;
            }

            this.onSuccessLogin = callback;
            this.showMaskAndNotRemoveDialog = showMaskAndNotRemoveDialog;

            if (Application.autoAuthPoupupUrl
                && !Application.forceLogin
            ) {
                Application.openAuthPopup(
                    Application.autoAuthPoupupUrl,
                    this.silentAuth.bindLegacy(this)
                );
            } else {
                this.dialog.show(); // will call onShow

                setTimeout(function () {
                    this.getFrame().contentWindow.postMessage('focusLogin', '*');
                }.bindLegacy(this), 1000);
            }
        },

        showForgotPassword : function (callback, email) {
            this.close();

            this.email = email && email.toString() === email ? email : '';
            this.forgotPassword = true;

            if (!callback) {
                callback = this.defaultCallback;
            }

            this.onSuccessLogin = callback;

            this.dialog.show(); // will call onShow
        },

        close : function () {
            if (this.showed) {
                this.dialog.close(); // will call onClose
            }
        },

        loadFrame : function (frame, params) {
            this.mask.show();

            if (!params) {
                params = {};
            }

            if (Application.forceLogin) {
                params.forceLogin = true;
            }

            if (Application.adminRoute) {
                params.adminRoute = true;
            }

            frame.src = route(
                'auth',
                params
            );
        },

        loadForgotPasswordFrame : function (frame, params) {
            this.mask.show();

            if (!params) {
                params = {};
            }

            frame.src = route(
                'forgotPassword',
                params
            );
        },

        onFrameLoad : function (frame) {
            var win, data;

            this.mask.hide();

            this._fitAuthForm();

            win = frame.contentWindow || frame;

            try {
                data = win.name.evalJSON();
                if (data.id) {
                    data.email = data.emails.primary.address;
                    Application.authorization = data;
                    Application.fireEvent('login');

                    if (this.showMaskAndNotRemoveDialog) {
                        this.mask.show();
                    } else {
                        this.close();
                    }

                    return;
                }
            } catch (e) {}

            try {
                if (-1 !== win.location.href.indexOf(
                    route(
                        'forgotPassword', {}
                    )
                )) {
                    this._fitForgotPasswordForm();
                }
            } catch (e) {}
        },

        getFrame : function () {
            if (!this.frame) {
                this.frame = this._createFrame(this.holder);

                GUI.$(this.frame).on('load', this.onFrameLoad.bindLegacy(this, this.frame));
            }

            return this.frame;
        },

        onClose : function (callback) {
            this.showed = false;

            GUI.hide(this.holder);

            if (Application.authorization && Application.authorization.id && callback) {
                callback();
            }
        },

        onShow : function () {
            this.dialog.setCaption(i18n('Login'));

            GUI.show(this.holder);

            var param = this.email ? {email: this.email} : {};
            if (this.forgotPassword) {
                this.loadForgotPasswordFrame(this.getFrame(), param);
                this.forgotPassword = false;
            } else {
                this.loadFrame(this.getFrame(), param);
            }

            this.showed = true;
        },

        silentAuth: function () {
            var holder = document.createElement('div');
            holder.style.position = 'absolute';
            holder.style.top      = 0;
            holder.style.left     = '-10px';
            holder.style.width    = 0;
            holder.style.height   = 0;
            holder.style.overflow = 'hidden';
            document.body.appendChild(holder);

            var frame = this._createFrame(holder);

            GUI.$(frame).on('load', this.onFrameLoad.bindLegacy(this, frame));
            this.loadFrame(frame);
        }
    };

    Object.extend(LoginFormClass.prototype, prototype);

    GUI.onDOMReady(function () {
        if (!window.doNotInitAuth) {
            Application.LoginForm = new LoginFormClass({
                holder          : GUI.$('auth-dialog_login-form'),
                signUpLink      : GUI.$('login-dialog_register'),
                backToLoginLink : GUI.$('login-dialog_login-link')
            });
        }

        window.addEventListener('message', function (e) {
            if (e.data === 'focusLogin') {
                GUI.$('login-dialog_email').focus();
            }
        });

        Application.sendConfirmation = function (email, account, ajax) {
            var routeName = 'sendSignUpConfirmationEmail';

            if (ajax) {
                routeName = 'sendEmailForVerificationAjax';
            }

            new GUI.Ajax.Request(route(routeName, {ignoreBaseUrl: true}), {
                method : 'POST',
                data   : {
                    email         : email,
                    targetAccount : account
                },
                onSuccess : function (an, response) {
                    if (response) {
                        alert('You should get the confirmation email within a few minutes.');
                    } else {
                        alert('Umm... Can not send email. Already activated or invalid address?');
                    }
                }.bindLegacy(this)
            });
        };
        Application.openAuthPopup = function(url, callback) {
            if (typeof (url) !== "string") {
                url = '';
            }

            var popupWindow, popupHandler;
            popupWindow  = window.open(url, "chd_login_popup", "resizable=1,width=1000,height=600"),
            popupHandler = window.setInterval(function () {
                if (popupWindow && popupWindow.closed) {
                    window.clearInterval(popupHandler);
                    if (callback) {
                        callback();
                    } else {
                        window.location.reload();
                    }
                }
            }, 1000);
        };

        if (!window.doNotInitAuth) {
            if (Application.forceLogin) {
                Application.LoginForm.show();
            }
        }
    });
} ());