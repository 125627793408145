(function() { // variables isolation

    var PasswordFormClass = window.Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        //_dialog   : null,
        //_showed   : false,
        //_formMask : null,
        _passwordForm : {
            holder : null,
            form   : null
        },

        _setupFormElementListeners : function() {
            this._passwordForm.holder = GUI.$('password_password-form');
            this._passwordForm.form   = GUI.$('password-form');

            if (undefined == this._passwordForm.holder || undefined == this._passwordForm.form) {
                return;
            }

            for (var key = 0; key < this._passwordForm.form.elements.length; key ++) {
                var element = this._passwordForm.form.elements[key];
                if (element.type && (element.type == 'text' || element.type == 'password')) {
                    this._setupInputListeners(element);
                }
            }
        },

        _setupInputListeners : function(element) {
            // add inline hints
            if (GUI.Dom.hasClass(element, 'text-field_default_value')) {
                var hint = element.value; // closure - each input will have own hint value
                element.on('focus', function() {
                    if (element.value == hint) {
                        GUI.Dom.removeClass(element, 'text-field_default_value');
                        element.value = '';
                    }
                }, this);
                element.on('blur', function() {
                    if (element.value == '') {
                        GUI.Dom.addClass(element, 'text-field_default_value');
                        element.value = hint;
                    }
                }, this);
            }

            // hide all side hints
            var holder = element.parentNode.parentNode;

            // show side hint on input's focus if there is no validation error
            element.on('focus', function() {
                var hint = GUI.Dom.findDescedent(holder, 'p.forms__notif');
                if (hint && 0 == GUI.Dom.findDescedents(holder, 'p.forms__notif_negative').length) {
                    GUI.show(hint);
                    GUI.Dom.findDescedents(holder, 'p.forms__notif_positive').each(GUI.hide);
                }
            }, this);

            // validate element on input's blur
            element.on('blur', function() {
                var data = {};
                if (!GUI.Dom.hasClass(element, 'text-field_default_value')) {
                    data[element.name] = element.value;
                } else {
                    data[element.name] = '';
                }

                if ('' == data[element.name])
                    return;

                new GUI.Ajax.Request(GUI.$('passwordValidationUrl').value, {
                    method : 'post',
                    data   : data,
                    onSuccess : (function(response) {
                        var hint = GUI.Dom.findDescedent(holder, 'p.forms__notif');
                        GUI.Dom.findDescedents(holder, 'p.forms__notif_negative').each(GUI.remove);
                        response = response.responseText.evalJSON();
                        if (true !== response) {
                            if (hint) {
                                GUI.hide(hint);
                                for (fieldName in response) {
                                    error = '';
                                    for (errorType in response[fieldName]) {
                                        if ('' != error) {
                                            error += '<br />';
                                        }
                                        error += response[fieldName][errorType];
                                    }
                                    GUI.Dom.append(
                                        hint.parentNode,
                                        '<p class="forms__notif forms__notif_negative">' +
                                            error +
                                            '</p>'
                                    );
                                }
                            }
                        } else {
                            GUI.Dom.findDescedents(holder, 'p').each(GUI.hide);
                            GUI.Dom.findDescedents(holder, 'p.forms__notif_negative').each(GUI.remove);
                            GUI.Dom.findDescedents(holder, 'p.forms__notif_positive').each(GUI.show);
                        }
                    }).bindLegacy(this)
                });
            },
            this);
        },

        initialize : function() {
            GUI.onDOMReady((function() {
                this._setupFormElementListeners();

            }).bindLegacy(this));
        }

    }

    Object.extend(PasswordFormClass.prototype, superproto);
    Object.extend(PasswordFormClass.prototype, prototype);

    Application.PasswordFormClass = new PasswordFormClass();

}());