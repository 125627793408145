(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * JsonViewCheckboxed implementation
     *
     * @author Inna
     * @version 2.0
     * @namespace GUI
     */
    GUI.JsonViewCheckboxed = Class.create();
    Object.extend(GUI.JsonViewCheckboxed.prototype, superproto);

    GUI.JsonViewCheckboxed.prototype.wrapperListClass = 'b-content-scrollable';

    GUI.JsonViewCheckboxed.prototype.preTplClassName = 'b-topic-list';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.JsonViewCheckboxed.prototype.initialize = function (config) {
        Object.extend(this, config);

        this.id = null;

        this.data = [];
        this.checkedItems = [];
        this.actionPopupMenus = [];

        this.addEvents(
            'onUpdateList',
            'onFilterDataRow',
            'onPagingLoad',
            'onMouseEnter',
            'onMouseLeave',
            'onClickList',
            'onClickCheckAll',
            'onClickGroupActions',
            'onClickAction',
            'onCheckboxChange'
        );

        // Call parent method
        superproto.initialize.call(this);

        this.initToolbar();
        this.initJsonView();
    };

    /**
     * Calls unrender
     * @param {Boolean} fast
     */
    GUI.JsonViewCheckboxed.prototype.destroy = function (fast) {
        this.removeEventListeners();
        this.unrender(fast);
        this.holder = null;
    };

    /**
     * Create dom elements, attach event listeners, fire event 'render'
     * @param {HTMLElement} to Element to render object to
     */
    GUI.JsonViewCheckboxed.prototype.render = function (to) {
        this.JV.render();
        this.toolbar.render(this.holder);

        this.initGroupActions();
        this.attachEventListeners();
    };

    /**
     * Removes event listeners
     * @param {Boolean} fast
     */
    GUI.JsonViewCheckboxed.prototype.unrender = function (fast) {
        this.JV.unrender();
        this.paging.destroy();
        this.toolbar.destroy();
    };

    /**
     * Updates view with data
     * @param {Object} data Data for update
     */
    GUI.JsonViewCheckboxed.prototype.update = function (data) {
        this.data = data || [];
        this.JV.update(this.data);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.initToolbar = function () {
        this.paging = new GUI.Ajax.Paging({
            url             : this.settings.paging.url,
            extraClass      : 'b-paging',
            showDisplaying  : false,
            showPerPage     : false,
            maskEl          : this.holder,
            //pageText        : i18n('P'),
            pageText        : '',
            pageSize        : 20
        });

        this.toolbar = new GUI.ToolBar({
            type        : '',
            className   : 'b-toolbar',
            extraClass  : 'b-toolbar_bottom b-toolbar_topic-list-bottom',
            sidebar     : this.paging,
            elements    : [{
                name: 'checkAll',
                obj : new GUI.Element({
                    html    : '<label class="b-checkbox b-checkbox_collapsedSingle"><input type="checkbox" class="checkbox" hint="' + i18n('Select all items on this page') + '"><i class="b-checkbox__pseudo"></i></label>'
                })
            }, {
                name: 'groupActions',
                obj : new GUI.ToolBar.MenuButton({
                    name        : 'groupActions',
                    iconClass   : 'fa fa-cog',
                    hint	    : i18n('Bulk actions on selected'),
                    disabled    : true,
                    menu        : {
                        name        : 'groupActions',
                        items       : [],
                        onClick : function (menu, item) {
                            this.fireEvent('onClickGroupActions', this, item.name, this.checkedItems, this);
                        }.bindLegacy(this)
                    }
                })
            }]
        });
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.initJsonView = function () {
        this.listHolder = document.createElement('DIV');
        GUI.Dom.addClass(this.listHolder, this.wrapperListClass);
        this.holder.appendChild(this.listHolder);

        this.JV = new GUI.JsonView({
            holder      : this.listHolder,
            preTpl      : '<ul class="' + (this.settings.jsonview.preTplClassName || this.preTplClassName) + '">',
            rowTpl      : this.settings.jsonview.rowTpl,
            postTpl     : '</ul>',
            noDataTpl   : '<span class="b-topic-list__nodata">' + i18n('Nothing here') + '</span>'
        });
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.initGroupActions = function () {
        this.checkAll = this.toolbar.getElement('checkAll');
        this.checkAllEl = GUI.Dom.extend(this.checkAll.getDom().firstChild);

        this.groupActions = this.toolbar.getElement('groupActions');
        this.setGroupActionsItems();
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.attachEventListeners = function () {
        this.JV.on('update', this.onUpdateList, this);
        this.JV.dom.on('click', this.onClickList, this);
        this.JV.dom.on('mouseenter', this.onMouseEnter, this);
        this.JV.dom.on('mouseleave', this.onMouseLeave, this);
        this.JV.on('filterDataRow', this.onFilterDataRow, this);

        this.paging.on('load', this.onPagingLoad, this);

        this.checkAllEl.on('click', this.onClickGroupAction, this);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.removeEventListeners = function () {
        this.JV.un('update', this.onUpdateList, this);
        this.JV.dom.un('click', this.onClickList, this);
        this.JV.un('filterDataRow', this.onFilterDataRow, this);

        this.paging.un('load', this.onPagingLoad, this);
    };

    /**
     *
     * @param {type} jv
     * @param {type} row
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.onFilterDataRow = function (jv, row) {
        this.fireEvent('onFilterDataRow', this, jv, row);
    };

    /**
     *
     * @param {type} pagingObj
     * @param {type} responseText
     * @param {type} responseJson
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.onPagingLoad = function (pagingObj, responseText, responseJson) {
        this.fireEvent('onPagingLoad', pagingObj, responseText, responseJson);
    };

    /**
     *
     * @param {type} e
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.onClickList = function (e) {
        e = GUI.Event.extend(e);
        var id,
            target = e.getTarget(),
            name = target.nodeName.toLowerCase(),
            pName = target.parentNode.nodeName.toLowerCase(),
            dom = GUI.findParentByTag(target, 'li', this.holderList);

        // get id
        if (dom && dom.getAttribute('data-id')) {
            id = dom.getAttribute('data-id');
        }

        // set checked items
        if (name === 'input') {
            this.setCheckedStatus(id, target.checked);
            this.updateGroupElements();

        // show action for item
        } else if (name === 'button' || pName === 'button') {
            this.showActions((name === 'button') ? target : target.parentNode, id);

        } else {
            this.fireEvent('onClickList', this, this.JV, id, target, dom, e);
        }
    };

    /**
     *
     * @param e
     */
    GUI.JsonViewCheckboxed.prototype.onMouseEnter = function (e) {
        e = GUI.Event.extend(e);
        var id,
            target = e.getTarget(),
            dom = GUI.findParentByTag(target, 'li', this.holderList);

        // get id
        if (dom && dom.getAttribute('data-id')) {
            id = dom.getAttribute('data-id');
        }

        this.fireEvent('onMouseEnter', this, this.JV, id, target, dom, e);
    };

    /**
     *
     * @param e
     */
    GUI.JsonViewCheckboxed.prototype.onMouseLeave = function (e) {
        e = GUI.Event.extend(e);
        var id,
            target = e.getTarget(),
            dom = GUI.findParentByTag(target, 'li', this.holderList);

        // get id
        if (dom && dom.getAttribute('data-id')) {
            id = dom.getAttribute('data-id');
        }

        this.fireEvent('onMouseLeave', this, this.JV, id, target, dom, e);
    };

    /**
     *
     * @param {type} channel
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.setGroupActionsItems = function () {
        this.groupActions.menu.setItems(this.settings.toolbar.groupActions || []);
    };

    /**
     *
     * @param {type} jv
     * @param {type} data
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.onUpdateList = function (jv, data) {
        var i, checkBackup = this.checkedItems.clone();
        this.deselectAllItems();

        for (i = 0; i < checkBackup.length; i++) {
            this.setCheckedStatus(checkBackup[i], true);
        }

        this.updateGroupElements();

        this.fireEvent('onUpdateList', this, jv, data);
    };

    /**
     *
     * @param {int} id
     * @param {bool} state
     */
    GUI.JsonViewCheckboxed.prototype.setCheckedStatus = function (id, state) {
        var query;

        id = parseInt(id, 10);

        if (state === true) {
            if (this.checkedItems.indexOf(id) !== -1) {
                return false; // Item already selected
            }

            if (query = this.getCheckboxElement(id)) {
                query.checked = true;
                this.checkedItems.push(id);
            }

        } else {
            if (this.checkedItems.indexOf(id) === -1) {
                return false; // Item not in list
            }

            this.checkedItems.splice(this.checkedItems.indexOf(id), 1);
            if (query = this.getCheckboxElement(id)) {
                query.checked = false;
            }
        }

        this.fireEvent('onCheckboxChange', id, state);
    };

    /**
     *
     * @param id
     * @returns {Element}
     */
    GUI.JsonViewCheckboxed.prototype.getCheckboxElement = function (id) {
        return this.JV.dom.querySelector('li[data-id="' + id + '"] input[type="checkbox"]');
    };

    /**
     *
     */
    GUI.JsonViewCheckboxed.prototype.updateGroupElements = function () {
        if (this.checkedItems.length >= 1) {
            this.toogleGroupActions('enable');
        } else {
            this.toogleGroupActions('disable');
        }

        this.checkAllEl.checked = (this.checkedItems.length === this.data.rows.length);
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.onClickGroupAction = function () {
        var i,
            data = this.data.rows;

        for (i = 0; i < data.length; i++) {
            this.setCheckedStatus(data[i].getId(), this.checkAllEl.checked);
        }

        this.updateGroupElements();
    };

    //
    /**
     *
     * @param {type} toogled
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.toogleGroupActions = function (toogled) {
        this.groupActions[toogled || 'enable']();
    };

    /**
     *
     * @param {type} actionDom
     * @param {type} id
     * @returns {undefined}
     */
    GUI.JsonViewCheckboxed.prototype.showActions = function (actionDom, id) {
        var menu, actions;

        actionDom.addClass('b-button_visible');
        actions = this.settings.toolbar.itemActions || this.settings.toolbar.groupActions;

        menu = this.actionPopupMenus[id];
        if (!menu) {
            menu = new GUI.Popup.Menu({
                name        : 'actions',
                className   : 'b-popup-menu_style_combo',
                hideOnDocumentClick	: true,
                items       : actions,
                onClick     : function (menu, action) {
                    this.fireEvent('onClickAction', menu, action, id);
                }.bindLegacy(this)
            });

            menu.on('hide', function () {
                actionDom.removeClass('b-button_visible');
            });
            this.actionPopupMenus[id] = menu;
        }
        menu.alignTo(actionDom, 'tl-bl?', [4, -2]);
        menu.show();
    };

    GUI.JsonViewCheckboxed.prototype.deselectAllItems = function () {
        var i, removeItems = this.checkedItems.clone();

        for (i = 0; i < removeItems.length; i++) {
            this.setCheckedStatus(removeItems[i], false);
        }

        this.checkedItems = [];
        this.checkAllEl.checked = false;
        this.toogleGroupActions('disable');
    };

}());
