(function () {

    /**
     * JavaScript Graphical User Interface
     * Hint implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Popup
     */
    GUI.Popup.Hint = Class.create();

    /**
     * Constancts
     */
    GUI.Popup.Hint.prototype.POSITION_CENTER = 'center';
    GUI.Popup.Hint.prototype.POSITION_RIGHT = 'right';
    GUI.Popup.Hint.prototype.POSITION_LEFT = 'left';

    /**
     * Css class of the item, default is 'b-hint'
     * @type String
     */
    GUI.Popup.Hint.prototype.className = 'b-hint';

    /**
     * Default is 50
     * @type Number
     */
    GUI.Popup.Hint.prototype.delay = 50;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Popup.Hint.prototype.followMouse = false; // does not implemented

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Popup.Hint.prototype.visible = false;

    /**
     * Constructor
     * @param {Object} config
     */
    GUI.Popup.Hint.prototype.initialize = function () {
        //
    };

    /**
     * Called when dom is ready
     */
    GUI.Popup.Hint.prototype.init = function () {
        var dom = document.createElement('div');
        dom.style.position = 'absolute';
        dom.className = 'b-hint b-hint_dark';

        document.body.appendChild(dom);
        this.dom = GUI.Dom.extend(dom);
        this.attachEventListeners();
        this.hide();
    };

    /**
     * Destroys objects
     */
    GUI.Popup.Hint.prototype.destroy = function () {
        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
    };

    /**
     * Renders dom
     * @param {Object} hint
     */
    GUI.Popup.Hint.prototype.renderHint = function (hint) {
        var html = '';
        hint.caption = hint.caption || '';
        if (hint.caption.trim()) {
            html += '<b class="b-hint__ttl">' + hint.caption + '</b>';
        }
        if (hint.text && hint.text.length) {
            html += '<p class="b-hint__txt">' + hint.text.replace(/(\r?\n)/g, '<br />') + '</p>';
        }
        if (hint.icon) {
            html += '<i class="b-toolbox__icon close-tips"></i>';
            this.dom.addClass('b-hint_closable');
        }
        return html;
    };

    /**
     * Adds a hint to the node
     * @param {HTMLElement} node
     * @param {String} caption Text of the caption
     * @param {String} text
     */
    GUI.Popup.Hint.prototype.add = function (node, caption, text) {
        var hint = caption;
        if (text && text.length) {
            if (caption && caption.length) {
                hint += '\n';
            }
            hint += text;
        }
        if (hint && hint.length) {
            node.setAttribute('hint', hint);

        } else if (node.getAttribute('hint')) {
            if (node.hint) {
                node.hint = null;
            } else {
                node.removeAttribute('hint');
            }
        }
    };

    /**
     * Return true if hint is exists
     */
    GUI.Popup.Hint.prototype.has = function (node) {
        var hint = node.hint || node.getAttribute('hint');
        return GUI.isString(hint) && hint.length;
    };

    /**
     * Returns object width data of the hint, caption and text.
     * @param {HTMLElement} node
     * @returns {Object} hint
     */
    GUI.Popup.Hint.prototype.get = function (node) {
        var hint = node.hint || node.getAttribute('hint'),
            firstCr = hint.split(/\n|<br[^>]*>/),
            caption = '',
            text    = hint,
            icon    = node.getAttribute('hintCloseIcon');

        if (firstCr.length > 1) {
            caption = firstCr.shift();
            text    = firstCr.join("<br />");
        }
        
        if (hint && hint.length) {
            return {
                caption : caption,
                text    : text,
                icon    : icon
            };
        }
        return false;
    };

    /**
     * Shows hint
     * @param {HTMLElement} t Node with hint
     */
    GUI.Popup.Hint.prototype.show = function (t) {
        var hint, div, maxWidth, icon;

        this._hintTarget = t;
        hint = this.get(t);
        this.dom.innerHTML = this.renderHint(hint);

        // test if hint is not clipped
        this.dom.visibility = 'hidden';
        this.setPosition(0, 0);

        // Check for hint type
        this.type = t.getAttribute('hinttype') || 'hint';
        this.followMouse = t.getAttribute('followmouse') || false;
        this.hintPosition = t.getAttribute('hintposition') || GUI.Popup.Hint.POSITION_CENTER;

        div = this.dom;
        div.style.width = '';

        maxWidth = t.getAttribute('hintmaxwidth');
        if (maxWidth) {
            maxWidth = parseInt(maxWidth, 10);
        }
        if (!maxWidth) {
            maxWidth = (this.type === 'help') ? 250 : 1000;
        }

        if (t.getAttribute('hintclass')) {
            div.className = t.getAttribute('hintclass');
        } else {
            div.className = 'b-hint b-hint_dark';
        }

        // Fix width
        if (div.offsetWidth > maxWidth) {
            maxWidth -= 23;
            maxWidth = maxWidth.constrain(0);
            div.style.width = maxWidth + 'px';
        }

        this._updatePosition();
        this.dom.visibility = '';
        this.visible = true;

        this.dom.removeClass('b-hint_dark_hide');
        this.dom.addClass('b-hint_dark_show');
        clearTimeout(this.timerId);
    };

    /**
     * Updates position of the hint
     */
    GUI.Popup.Hint.prototype._updatePosition = function () {
        var x, y, h, hintTargetData, offsetY, hintData, currentArrowClass = 'b-arrow_',
            hintPositionCenter, hintRightSide, hintLeftSide,
            vw = GUI.getViewportWidth(),
            vh = GUI.getViewportHeight(),
            scroll = GUI.getScroll(GUI.getBody()),
            hintTarget = this._hintTarget,
            type = hintTarget.getAttribute('hinttype') || 'help',
            w = this.dom.offsetWidth;

        if (!this.visible) {
            this.hideDom();
        }

        if (type === 'hint') {
            h = this.dom.offsetHeight;

            x = (this.pointerX + w > vw)
                ? vw - w - 1 // clip at the right, move lefter to fit on screen
                : this.pointerX; // no clipping

            y = (this.pointerY + 21 + h > vh)
                ? this.pointerY - h // clipping at the bottom, move upper
                : this.pointerY + 21; // no clip, display right under the cursor

        } else if (type === 'help' && this.hintPosition === GUI.Popup.Hint.POSITION_CENTER) {
            hintTargetData = hintTarget.getBoundingClientRect();
            hintData = this.dom.getBoundingClientRect();

            offsetY = 4;

            if (hintTargetData.bottom + hintData.height + offsetY <= vh) {
                // Hint bottom
                y = hintTargetData.bottom + offsetY;
                currentArrowClass += 'top';

            } else if (hintTargetData.top - hintData.height >= 0) {
                // Hint top
                y = hintTargetData.top - hintData.height - offsetY;
                currentArrowClass += 'bottom';

            } else {
                // Set at middle. Only for protection
                y = hintTargetData.top + parseInt(hintTargetData.height / 2, 10) - parseInt(hintData.height / 2, 10);
            }

            hintPositionCenter = hintTargetData.left + parseInt(hintTargetData.width / 2);
            hintLeftSide = hintPositionCenter - parseInt(hintData.width / 2);
            hintRightSide = hintPositionCenter + parseInt(hintData.width / 2);

            if (hintLeftSide >= 0 && hintRightSide <= vw) {
                // align center
                x = hintPositionCenter - (hintData.width / 2);

            } else if (hintTargetData.left + hintData.width <= vw) {
                // align left
                x = hintTargetData.left;
                currentArrowClass += '_left';

            } else if (hintRightSide > vw) {
                // align right side
                x = hintTargetData.right - hintData.width;
                currentArrowClass += '_right';

            }
        } else if (type === 'help') {
            var pos, hintHeight, targetWidth, offsetX,
                t = this._hintTarget;

            pos = t.getBoundingClientRect();
            hintHeight = this.dom.offsetHeight;

            y = pos.top + parseInt(pos.height / 2, 10) - parseInt(hintHeight / 2, 10);

            targetWidth = t.offsetWidth;
            offsetX = 3;

            if (this.hintPosition === GUI.Popup.Hint.POSITION_RIGHT) {
                currentArrowClass = 'b-arrow_left';

                if (pos.left + targetWidth + offsetX + w <= vw) {
                    x = pos.left + targetWidth + offsetX;
                } else if (pos.left - w - offsetX >= 0) {
                    x = pos.left - w - offsetX;
                    currentArrowClass = 'b-arrow_right';
                } else {
                    x = vw - w;
                    currentArrowClass = 'b-arrow_right';
                }
            } else if (this.hintPosition === GUI.Popup.Hint.POSITION_LEFT) {
                currentArrowClass = 'b-arrow_right';

                if (pos.left - w - offsetX >= 0) {
                    x = pos.left - w - offsetX;
                } else if (pos.left + targetWidth + offsetX + w <= vw) {
                    x = pos.left + targetWidth + offsetX;
                    currentArrowClass = 'b-arrow_left';
                } else  {
                    x = vw - w;
                    currentArrowClass = 'b-arrow_right';
                }
            }
        }

        this.dom.addClass('b-arrow');
        this.dom.addClass(currentArrowClass);
        x += scroll.left;
        y += scroll.top;

        this.setPosition(x, y);
    };

    /**
     * Sets position to -10000
     */
    GUI.Popup.Hint.prototype.hide = function () {
        this.visible = false;
        this.hideDom();
    };
    
    GUI.Popup.Hint.prototype.hideDom = function () {
        this.setPosition(-10000, -10000);
        this.dom.removeClass('b-hint_dark_hide');

        this.dom.removeClass('b-arrow');
        this.dom.removeClass('b-arrow_left');

        this.dom.removeClass('b-arrow_top');
        this.dom.removeClass('b-arrow_top_left');
        this.dom.removeClass('b-arrow_top_right');

        this.dom.removeClass('b-arrow_bottom');
        this.dom.removeClass('b-arrow_bottom_left');
        this.dom.removeClass('b-arrow_bottom_right');

        clearTimeout(this.timerId);
    };

    /**
     * Hides hint
     */
    GUI.Popup.Hint.prototype.onScroll = function () {
        if (this.visible) {
            this.hide();
        }
    };

    /**
     * Attach events listeners
     */
    GUI.Popup.Hint.prototype.attachEventListeners = function () {
        var elem = GUI.getBody();
        GUI.Dom.extend(elem);

        if (GUI.isMobile) {
            elem.on('touchstart', this.onTouchStart, this);
            elem.on('touchend', this.onTouchEnd, this);
        } else {
            elem.on('mousedown', this.onMouseDown, this);
            elem.on('mousemove', this.onMouseMove, this);
            elem.on('mouseover', this.onMouseOver, this);
            elem.on('mouseout', this.onMouseOut, this);
        }
        elem.on('unload', this.destroy, this);
        GUI.Event.on(window, 'scroll', this.onScroll, this);

        window.setInterval(this.hintPoller.bindLegacy(this), this.delay);
    };

    /**
     * Removes events listeners
     */
    GUI.Popup.Hint.prototype.removeEventListeners = function () {
        var elem = GUI.getBody();
        GUI.Dom.extend(elem);
        if (GUI.isMobile) {

        } else {
            elem.un('mousedown', this.onMouseDown, this);
            elem.un('mousemove', this.onMouseMove, this);
            elem.un('mouseover', this.onMouseOver, this);
            elem.un('mouseout', this.onMouseOut, this);
        }

        elem.un('unload', this.destroy, this);
        GUI.Event.un(window, 'scroll', this.onScroll, this);
    };

    /**
     * Sets position
     * @param {Number} x
     * @param {Number} y
     */
    GUI.Popup.Hint.prototype.setPosition = function (x, y) {
        if (this.dom) {
            var s = this.dom.style;
            s.left = x + 'px';
            s.top = y + 'px';
        }
    };

    /**
     * Hides hint
     * @param {Object} e Event
     */
    GUI.Popup.Hint.prototype.onMouseDown = function (e) {
        e = GUI.Event.extend(e);

        if (this.visible) {
            if (this.type === 'help' && e.within(this._hintTarget)) {
                return;
            }
            this.hide();
        }
    };

    /**
     * Saves last hover element
     * @param {Object} e Event
     */
    GUI.Popup.Hint.prototype.onMouseOver = function (e) {
        this.lastTarget = e.srcElement || e.target; // save last hover target
    };

    /**
     * Hides hint
     * @param {Object} e Event
     */
    GUI.Popup.Hint.prototype.onMouseOut = function (e) {
        if (this.visible) {
            e = GUI.Event.extend(e);
            if (e.isMouseLeave(this._hintTarget)) {
                // Hide hint
                this.hide();
            }
        }

        this.lastTarget = null; // save last hover target
    };

    /**
     * Updates position
     * @param {Object} e Event
     */
    GUI.Popup.Hint.prototype.onMouseMove = function (e) {
        if (e.target && e.target.disabled && !this.lastTarget) {
            this.onMouseOver(e);
        }

        if (this.visible) {
            if (this.followMouse) {
                // Update hint position
                this.pointerX = e.clientX;
                this.pointerY = e.clientY;
                this._updatePosition();
            }
        } else {
            // else save timestamp and mouse position
            this.lastMoveTs = new Date();
            this.pointerX = e.clientX;
            this.pointerY = e.clientY;
        }
    };

    GUI.Popup.Hint.prototype.onTouchStart = function (e) {
        if (this.visible) {
            if (this.type === 'help' && e.within(this._hintTarget)) {
                return;
            }

            this.hide();
            return;
        }

        e.timeStamp = new Date().getTime();
        this.lastTargetForMove = e;
    };

    GUI.Popup.Hint.prototype.onTouchEnd = function (e) {
        e.timeStamp = new Date().getTime();

        if (this.lastTargetForMove && e.timeStamp >= this.lastTargetForMove.timeStamp + 700 ) {
            this.lastTarget = e.target;
            this.lastMoveTs = new Date().getTime();
            this.lastTargetForMove = null;
        } else {
            this.lastTarget = null;
        }
        
    };

    /**
     * Shows hint
     */
    GUI.Popup.Hint.prototype.hintPoller = function () {
        if (this.lastMoveTs && (new Date() - this.delay >= this.lastMoveTs)) {
            if (this.lastTarget && !jQuery(this.lastTarget).hasClass('ui-sortable-helper')) {
                // Check if the last hovered target has hint attached
                var t = this.lastTarget, lim = 9;
                while (t && t.tagName && lim) {
                    if (this.has(t)) {
                        // Found hint
                        this.show(t);
                        break;
                    }
                    t = t.parentNode; lim--;
                }
                this.lastTarget = null;
            }
            this.lastMoveTs = null;
        }
    };

    GUI.Popup.Hint = new GUI.Popup.Hint();

    document.on('dom:loaded', function () {
        GUI.Popup.Hint.init();
    });
}());