(function () {

    var superproto = GUI.BoxComponent.prototype;

    /**
     * JavaScript Graphical User Interface
     * Grid implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.Grid = Class.create();
    Object.extend(GUI.Grid.prototype, superproto);

    /**
     * Text of the message of empty grid, default is 'There is no data to display'
     * @type String
     */
    GUI.Grid.prototype.msg = i18n('There is no data to display');

    /**
     * Column with checkboxes, default is false
     * @type Boolean
     */
    GUI.Grid.prototype.checkbox = false;

    /**
     * If true, in the grid can only select one line, default is false
     * @type Boolean
     */
    GUI.Grid.prototype.singleSelect = false;

    /**
     * Align in the grid, default is 'left'
     * @type String
     */
    GUI.Grid.prototype.align = 'left';

    /**
     * Vertical align of the grid, default is 'middle'
     * @type String
     */
    GUI.Grid.prototype.valign = 'middle';

    /**
     * Color of the hover, default is ''
     * @type String
     */
    GUI.Grid.prototype.hoverColor = '';

    /**
     * If true, bottom toolbar wil be hidden, default is false
     * @type Boolean
     */
    GUI.Grid.prototype.bottomHidden = false;

    /**
     * Css class of the hint, default is ''
     * @type String
     */
    GUI.Grid.prototype.hintClass = '';

    /**
     * Offset hint on X, default is 1
     * @type Number
     */
    GUI.Grid.prototype.hintOffsetX = 1;

    /**
     * Offset hint on Y, default is -4
     * @type Number
     */
    GUI.Grid.prototype.hintOffsetY = -4;

    /**
     * Theme class, default is '', for gray theme 'b-grid_theme-gray'
     * @type String
     */
    GUI.Grid.prototype.themeClass = '';

    /**
     * Delay hint, default is 50
     * @type Number
     */
    GUI.Grid.prototype.hintDelay = 50;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Grid.prototype.customRowHidden = false;

    /**
     * Top bar
     * @type NULL
     */
    GUI.Grid.prototype.tbar = null;

    /**
     * Bottom bar
     * @type NULL
     */
    GUI.Grid.prototype.bbar = null;

    /**
     * If false, header text will be cut, default is false
     * @type Boolean
     */
    GUI.Grid.prototype.cutHeaderText = false;

    /**
     * If true cutHeaderText can be only such as it is in config, default is true
     * @type Boolean
     */
    GUI.Grid.prototype.cutHeaderTextReadonly = false;

    /**
     * If false only 'No data' message is visible, default is true
     * @type Boolean
     */
    GUI.Grid.prototype._firstUpdate = true;

    /**
     * Constructor.
     * @param {Object} config Configuration object
     */
    GUI.Grid.prototype.initialize = function (config) {
        // Call parent initialize meethod
        superproto.initialize.call(this, config);
    };

    /**
     * Init components, add events
     */
    GUI.Grid.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        //back support
        this.config = this;

        this.addEvents({
            hdClick         : true,
            hdMouseOver     : true,
            hdMouseOut      : true,
            rowMouseOver    : true,
            rowMouseOut     : true,
            rowClick        : true,
            rowDblClick     : true,
            cellMouseOver   : true,
            cellMouseOut    : true,
            cellMouseDown   : true,
            cellClick       : true,
            cellDblClick    : true,
            customRowClick  : true,
            customRowDblClick   : true,
            customRowMouseOver  : true,
            customRowMouseOut   : true,
            customCellClick     : true,
            customCellDblClick  : true,
            customCellMouseOver : true,
            customCellMouseOut  : true,
            sortChange          : true,
            render              : true,
            update              : true,
            afterUpdate         : true,
            noData              : true,
            dataAvailable       : true,
            windowResize        : true
        });

        if (!this.data) {
            this.data = {};
        }

        // theme class
        this.themeClass = this.config.themeClass || this.themeClass;

        // Init selection model
        if (!this.sm) {
            if (this.checkbox) {
                this.sm = new GUI.Grid.CheckBoxSelectionModel({
                    singleSelect: this.singleSelect
                });
            } else {
                this.sm = new GUI.Grid.RowSelectionModel({
                    singleSelect: this.singleSelect
                });
            }
        }
        this.sm.init(this);

        // Init rows
        this.rows = new GUI.Utils.Collection();

        // Init bars
        if (this.bbar && !GUI.isArray(this.bbar)) {
            this.bbar = [this.bbar];
        }
        this.showHintDelegate = this.showHint.bindLegacy(this);
    };

    /**
     * Render html
     * @returns {HTMLElement} html
     */
    GUI.Grid.prototype.onRender = function () {
        return '';
    };

    /**
     * Removes objects
     * @param {Boolean} quick
     */
    GUI.Grid.prototype.onDestroy = function (quick) {
        this.gridHolder = this.gridEl = this.headEl = this.bodyEl = null;
    };

    /**
     * Rendering bar
     * @param {Object} bar Object of the bar
     * @param {HTMLElement} to Element to render bar to
     */
   /* GUI.Grid.prototype._renderBar = function (bar, to) {
        var i = 0,
            len = 0;

        if (GUI.isArray(bar)) {
            for (i = 0, len = bar.length; i < len; i++) {
                bar[i].render(to);
            }
        } else if (GUI.isFunction(bar.render)) {
            this.bbar.render(to);
        }
    };*/

    /**
     * Renders bottom bar
     */
    GUI.Grid.prototype.onAfterRender = function (dom) {
        if (this.bbar) {
            // Render bottom bar
            this.bbarEl = dom;
        }

        // Attach event listeners
        this.gridHolder = this.holder;
        this.gridHolder.on('click', this.onClick, this);
        this.gridHolder.on('dblclick', this.onDblClick, this);
        this.gridHolder.on('mouseover', this.onMouseOver, this);
        this.gridHolder.on('mouseout', this.onMouseOut, this);
        this.gridHolder.on('mouseleave', this.onMouseLeave, this);

        this.cutHeaderText = this.cutHeaderText.toString() === 'true' ? true : false;
        this.setCutHeaderText(this.cutHeaderText);

        this.update();
    };

    /**
     * Sets param cutHeaderText
     * @param {Boolean} val
     */
    GUI.Grid.prototype.setCutHeaderText = function (val) {
        if (this.cutHeaderTextReadonly) {
            val = this.cutHeaderText; // Use config value
        } else {
            this.cutHeaderText = val;
        }
        if (this.dom) {
            this.gridHolder[!val ? 'removeClass' : 'addClass']('jsgui-grid-donotcutheadertext');
        }
    };

    /**
     * Updates data
     * @param {Object} d Data for update
     */
    GUI.Grid.prototype.update = function (d) {
        var data, head, body;
        // Clear selections fast
        this.sm.clearSelections(true);

        if (typeof d !== 'undefined') {
            this.data.rows = d.rows;
            this.data.groupActions = d.groupActions;

            this.config.sortIndex = d.sortIndex;
            this.config.sortDirection = d.sortDirection;
        }

        data = this.data;

        if (data && data.rows && data.rows.length > 0) {
            // Need to render header and body
            //GUI.hide(this.id + '-nodata-msg');
            GUI.show(this.gridHolder);

            head = this.renderHeader();
            body = this.renderBody(); // very slow in ie
        } else {
            head = body = '';
            if (!this._firstUpdate) {
                // Only 'No data' message is visible
                GUI.hide(this.gridHolder.firstChild);
                //GUI.show(this.id + '-nodata-msg');
            } else {
                this._firstUpdate = false;
            }
            this.renderHeader();
        }

        this.gridHolder.innerHTML = '<table class="b-grid ' + this.themeClass + ' ">' + head + body + '</table>';

        this.gridEl = this.gridHolder.firstChild;
        this.headEl = this.gridEl.tHead;
        this.bodyEl = this.gridEl.tBodies[0];

        this.fireEvent('update', this);
    };

    /**
     * Switch curent header sorting
     * @param {Object} col
     */
    GUI.Grid.prototype.switchSorting = function (col) {
        var sorting, td;

        sorting = 'none';
        td = this.headEl.rows[0].cells[col.domIndex];

        GUI.removeClass(td.childNodes[0], col.sorting);

        switch (col.sorting) {
        case 'asc':
            sorting = 'desc';
            break;
        case 'desc':
            sorting = 'asc';
            break;
        default:
            sorting = 'desc';
            break;
        }
        this.resetSorting();

        GUI.addClass(td.childNodes[0], sorting);
        col.sorting = sorting;

        this.fireEvent('sortChange', td, col.index, sorting);
    };

    /**
     * Sets the header caption
     * @param {Number} dataIndex Index of the data
     * @param {String} caption A new caption
     */
    GUI.Grid.prototype.setHeaderCaption = function (dataIndex, caption) {
        var col, tr, td;

        col = this.getColumnByDataIndex(dataIndex);
        if (col) {
            if (col.caption !== caption) {
                col.caption = caption;
                tr = this.headEl ? this.headEl.rows[0] : null;
                if (tr) {
                    td = tr.cells[col.domIndex];
                    if (td) {
                        td.getElementsByTagName('span')[0].innerHTML = caption;
                    }
                }
            }
        }
    };

    /**
     * Returns column by index
     * @param {Number} ind
     * @returns {Object} column
     */
    GUI.Grid.prototype.getSortColumnByIndex = function (ind) {
        var len, col;

        len = this.columns.length;
        while (len--) {
            col = this.columns[len];
            if (ind === col.index) {
                return col;
            }
        }
    };

    /**+
     * Sets sorting
     * @param {Number} ind Index of the column
     * @param {String} dir Direction of sort
     */
    GUI.Grid.prototype.setSorting = function (ind, dir) {
        var col, tr;
        this.resetSorting();
        col = this.getSortColumnByIndex(ind);
        if (col && col.sortable) {
            col.sorting = dir;
            tr = this.headEl ? this.headEl.rows[0] : null;
            if (tr && col.domIndex !== null) {
                GUI.Dom.addClass(tr.cells[col.domIndex].firstChild, dir);
            }
        }
    };

    /**
     * Resets sort
     */
    GUI.Grid.prototype.resetSorting = function () {
        var tr, h, col;

        tr = this.headEl ? this.headEl.rows[0] : null;
        if (!GUI.isSet(tr)) {
            return 'No tr';
        }
        for (h = 0; h < this.visibleColumnsNum; h++) {
            col = this.visibleColumns[h];
            if (!GUI.isSet(col)) {
                return 'No column ';
            }
            if (col.sortable && (col.sorting !== 'none')) {
                GUI.Dom.removeClass(tr.cells[col.domIndex].firstChild, col.sorting);
                col.sorting = 'none';
            }
        }
    };

    /**
     * Renders header, returns html of columns and headers
     * @returns {HTMLElement} html
     */
    GUI.Grid.prototype.renderHeader = function () {
        var columns, i, col, cols, headers, colsCount,
            domIndex, cellCls;

        columns = this.data.columns;
        cols = new GUI.StringBuffer();
        headers = new GUI.StringBuffer();
        colsCount = columns.length;
        domIndex = 0;

        this.visibleColumns = [];

        headers.append('<thead class="b-grid__head"><tr>');
        for (i = 0; i < colsCount; i++) {
            col = columns[i];
            col._index = i;
            col.domIndex = null; // reset dom index
            if (col.visible === false) {
                continue; // hidden column
            }

            col.visible = true;
            this.visibleColumns[domIndex] = col; // Save pointer to column
            col.domIndex = domIndex++;

            if (col.editable && col.editor) {
                col.blockSelection = true; // Block selection if cell is editable
            }

            // Render cols
            if (GUI.isSet(col.width)) {
                cols.append('<col width="' + col.width + '"/>');
            } else {
                cols.append('<col />');
            }
            // Render header
            cellCls = 'b-grid__cell';
            if (col.headerCls) {
                cellCls += ' ' + col.headerCls;
            }

            // Apply sorting class if sortable
            if (typeof col.sortable === 'undefined') {
                col.sortable = true;
            }

            headers.append('<td class="' + cellCls + '"');

            if (col.title) {
                headers.append(' title="' + col.title + '" ');
            }

            headers.append('>');

            if (col.rawCaption) {
                headers.append(col.caption + '</td>');
            } else {
                headers.append('<span class="b-grid__ttl nowrap_false');
                if (col.sortable) {
					if (col.sorting == 'asc') {
                        headers.append(' asc');
					} else if (col.sorting == 'desc') {
                        headers.append(' desc');
					}
                } else {
                    headers.append(' not-sort');
                }
                headers.append('">' + col.caption + '</span></td>');
            }

            // Some interesing addition :-)
            // Get custom rendered columns data
            // Hmm... Rows are not available...
            /*if (col.renderer) {
                col.renderer(this.data.data, this, col, null);
            }*/
        }
        headers.append('</tr></thead>');

        // Store number of visible columns
        this.visibleColumnsNum = domIndex;
        this.columns = columns;

        return cols.toString() + headers.toString();
    };

    /**
     * Renders body, returns rows html
     * @returns {HTMLElement} html
     */
    GUI.Grid.prototype.renderBody = function () {
        var i, row, rows, rowsCount, rowConfig, gridRow, rowsBuffer, domIndex;

        rows = this.data.rows;
        rowsCount = 0;
        if (rows && rows.length) {
            rowsCount = rows.length;
        }

        // Destroy any existing rows
        this.rows.each(function (row) { row.destroy(); });
        this.rows.clear();

        // Iterates over data rows, create according objects and render them
        rowConfig = {
            grid            : this,
            valign          : this.valign,
            customRenderer  : this.customRenderer
        };

        // At first create row to get row template;
        gridRow = new GUI.Grid.Row(rowConfig);
        rowConfig.rowTemplate = gridRow.generateRowTemplate();
        // And destroy it because it attaches listener to grid's afterrender
        gridRow.destroy();

        rowsBuffer = new GUI.StringBuffer();
        domIndex = 0;
        rowsBuffer.append('<tbody class="b-grid__body">');
        for (i = 0; i < rowsCount; i++) {
            row = rows[i];
            rowConfig.id = row.id;
            rowConfig.index = i;
            rowConfig.domIndex = domIndex;
            rowConfig.renderer = row.renderer;
            rowConfig.customRowCls = row.customRowCls || '';
            gridRow = new GUI.Grid.Row(rowConfig);

            domIndex += gridRow.render(rowsBuffer, row, i); // slow in ie
            this.rows.add(row.id, gridRow);
        }
        rowsBuffer.append('</tbody>');

        return rowsBuffer.toString();
    };

    /**
     * Sets width of the columns
     * @param {Number} index Index of the column
     * @param {Number} width Width for changing
     */
    GUI.Grid.prototype.setColumnWidth = function (index, width) {
        var col = this.data.columns[index];
        if (col && col.visible) {
            if (GUI.isNumber(width)) {
                col.width = width;
            } else {
                col.width = null;
            }
            if (this.headEl) {
                this.gridEl.childNodes[col.domIndex].width = width;
            }
        }
    };

    /**
     * Returns width of the column
     * @param {Number} index Index of the column
     * @returns {Number} width
     */
    GUI.Grid.prototype.getColumnWidth = function (index) {
        var col, tr, td;

        col = this.config.data.columns[index];
        if (col && col.visible) {
            if (this.headerTable) {
                tr = this.headerTable.firstChild.firstChild;
                if (tr) {
                    td = tr.childNodes[index];
                    return td.offsetWidth;
                }
            }
        }
        return 0;
    };

    /**
     * Shows hint
     */
    GUI.Grid.prototype.showHint = function () {
        var div, span;
        this.hintTimer = null;

        if (this.hoverTr.parentNode === this.bodyEl) {
            div = GUI.Dom.findDescedents(this.hoverTd, 'div.ovf');
            if (div.length) {
                div = div[0];
                if (div.firstChild.offsetWidth > div.offsetWidth) {
                    this._showHint(div, div.firstChild.innerHTML);
                }
            }
        } else {
            // header
            div = GUI.Dom.findDescedents(this.hoverTd, 'div.x-grid-text-case');
            if (div.length) {
                div = div[0];
                span = GUI.Dom.findDescedents(div, 'span');
                if (span.length) {
                    span = span[0];
                    if (span.offsetWidth > div.offsetWidth) {
                        this._showHint(span, span.innerHTML);
                    }
                }
            }
        }
    };

    /**
     * Creates and shows popup region, add events
     * @param {Object} node
     * @param {String} text
     */
    GUI.Grid.prototype._showHint = function (node, text) {
        var dom, pos, docWidth, hintWidth, newLeft;
        if (this.hintVisible) {
            this.hideHint();
        }

        if (!this.hintList) {
            this.hintList = new GUI.Popup.Region({
                className : 'region ' + this.hintClass
            });
        }

        this.hintList.setContent(GUI.Popup.Hint.renderHint({
            text          : text,
            contentHeight : node.offsetHeight
        }));

        this.hintList.alignTo(node, 'tl-tl', [ this.hintOffsetX, this.hintOffsetY ]);
        this.hintList.show();
        dom = GUI.Dom.extend(this.hintList.dom);
        pos = GUI.getPosition(dom);
        docWidth = GUI.getViewportWidth();
        hintWidth = dom.firstChild.offsetWidth;
        if (pos.left + hintWidth > docWidth) {
            newLeft = docWidth - hintWidth;
            this.hintList.setDimensions({
                left: newLeft
            });
        }

        this.hintVisible = true;
        this.hintAssignedNode = node;
        // Attach events to proxy
        dom.on('mouseleave', this.onHintMouseLeave, this);
        dom.on('click', this.hideHint, this);
    };

    /**
     * Hides hint
     */
    GUI.Grid.prototype.hideHint = function () {
        if (this.hintVisible) {
            // Remove event listeners
            this.hintList.dom.un();
            this.hintList.hide();
            this.hintVisible = false;
            this.hintAssignedNode = null;
        }
    };

    /**
     * Returns row by id
     * @param {Number} id
     * @returns {Object} row
     */
    GUI.Grid.prototype.getRow = function (id) {
        return this.rows.get(id);
    };

    /**
     * Returns column by name
     * @param {String} name name of the column
     * @returns {Object} column
     */
    GUI.Grid.prototype.getColumnByDataIndex = function (name) {
        var len = this.columns.length, col;
        while (len--) {
            col = this.columns[len];
            if (name === col.dataIndex) {
                return col;
            }
        }
        return false;
    };

    /**
     * Handler click
     * @param {Event} e Event
     */
    GUI.Grid.prototype.onClick = function (e) {
        var target, td, tr, rowId, row, col;

        e = GUI.Event.extend(e);
        // find cell
        target = e.getTarget();

        td = target.findParent('td', this.gridEl, 'b-grid__cell');
        if (td) {
            tr = td.parentNode;
            if (tr.parentNode === this.bodyEl) {
                // Data cell click
                if (GUI.Dom.hasClass(tr, 'custom')) {
                    // Custom rendered row
                    // TODO: get row id from prevSibling tr....
                    this.fireEvent('customRowClick', this, null, e);
                } else {
                    // Normal row
                    rowId = tr.id.substring(this.id.length + 5);
                    row = this.rows.get(rowId);
                    col = this.visibleColumns[td.cellIndex];

                    if (col.onClick) {
                        col.onClick.call(col, this, row, td, e);
                    }
                    this.fireEvent('cellClick', this, row, col, e, td);
                }
            } else {
                col = this.visibleColumns[td.cellIndex];
                // Switch sorting if column is sortable
                if (this.fireEvent('beforeSortChange', this, col, e, td) !== false && col.sortable) {
                    this.switchSorting(col);
                }

                this.fireEvent('hdClick', this, col, e, td);
            }
        }
    };

    /**
     * Handler double click
     * @param {Event} e Event
     */
    GUI.Grid.prototype.onDblClick = function (e) {
        var target, td, tr, col, row, rowId;

        e = GUI.Event.extend(e);
        // find cell
        target = e.getTarget();
        td = target.findParent('td', this.gridEl, 'b-grid__cell');
        if (td) {
            tr = td.parentNode;
            if (tr.parentNode === this.bodyEl) {
                // Data cell click
                rowId = tr.id.substring(this.id.length + 5);
                if (rowId !== '') {
                    // Normal row
                    row = this.rows.get(rowId);
                    col = this.visibleColumns[td.cellIndex];
                    this.fireEvent('cellDblClick', this, row, col, e, td);
                } else {
                    // Custom rendered row
                    // TODO: get row id from prevSibling tr....
                    this.fireEvent('customRowDblClick', this, null, e);
                }
            }
        }
    };

    /**
     * Hides hint
     */
    GUI.Grid.prototype.onHintMouseLeave = function () {
        this.hideHint();
    };

    /**
     * SHows hint
     * @param {Event} e Event
     * @param {HTMLElement} tr
     * @param {HTMLElement} td
     */
    GUI.Grid.prototype.onHeadMouseOver = function (e, tr, td) {
        if (this.hoverTd !== td) {
            var col = this.visibleColumns[td.cellIndex];
            this.hoverTd = td;

           /* if (col.sortable) {
                GUI.Dom.addClass(td, 'hover');
            }*/

            if (this.hintTimer) {
                clearTimeout(this.hintTimer);
                this.hintTimer = null;
            }

            // Defer hint showing if needed
            this.hintTimer = setTimeout(this.showHintDelegate, this.hintDelay);
        }
        this.hoverTr = tr;
    };

    /**
     * Remove/add css class 'hover', fire event 'cellMouseOver'
     * @param {Event} e Event
     * @param {HTMLElement} tr
     * @param {HTMLElement} td
     */
    GUI.Grid.prototype.onBodyMouseOver = function (e, tr, td) {
        var rowId, col;

        if (tr.id) {
            rowId = tr.id.substring(this.id.length + 5);
        } else {
            rowId = '';
        }

        if (rowId === '') {
            // Custom rendered row
            /*if (this.hoverTr) {
                GUI.Dom.removeClass(tr, 'hover');
                this.hoverTr = null;
            }*/
            this.hoverTr = null;
            if (this.hintTimer) {
                clearTimeout(this.hintTimer);
                this.hintTimer = null;
            }
            // TODO: get row id from prevSibling tr....
            return;
        }

        // Normal row
        col = this.visibleColumns[td.cellIndex];

        if (this.hoverTr !== tr) {
            // DataRowMouseEnter
            //GUI.Dom.addClass(tr, 'hover');
            this.hoverTr = tr;
        }

        if (this.hoverTd !== td) {
            // DataCellMouseEnter
            this.hoverTd = td;

            if (this.hintTimer) {
                clearTimeout(this.hintTimer);
                this.hintTimer = null;
            }

            // Defer hint showing if needed
            if (col.showHint) {
                this.hintTimer = setTimeout(this.showHintDelegate, this.hintDelay);
            }
        }
        this.fireEvent('cellMouseOver', this, rowId, col, td, e);
    };

    /**
     * Handler mouse over
     * @param {Event} e Event
     */
    GUI.Grid.prototype.onMouseOver = function (e) {
        var target, td, tr;

        e = GUI.Event.extend(e);
        // find cell
        target = e.getTarget();
        td = target.findParent('td', this.gridEl, 'b-grid__cell');
        if (td) {
            tr = td.parentNode;
           /* if (this.hoverTr && this.hoverTr !== tr) {
                GUI.Dom.removeClass(this.hoverTr, 'hover');
            }
            if (this.hoverTd && this.hoverTd.parentNode && this.hoverTd.parentNode.parentNode === this.headEl && this.hoverTd !== td) {
                GUI.Dom.removeClass(this.hoverTd, 'hover');
            }*/

            if (tr.parentNode === this.bodyEl) {
                this.onBodyMouseOver(e, tr, td);
            } else if (tr.parentNode === this.headEl) {
                this.onHeadMouseOver(e, tr, td);
            }
        }
    };

    /**
     * Handler mouse out
     * @param {Event} e Event
     */
    GUI.Grid.prototype.onMouseOut = function (e) {
        var target, td, tr, rowId, col;

        e = GUI.Event.extend(e);
        // find cell
        target = e.target;
        td = target.findParent('td', this.gridEl, 'b-grid__cell');
        if (td) {
            tr = td.parentNode;
            if (tr.id) {
                rowId = tr.id.substring(this.id.length + 5);
            } else {
                rowId = '';
            }

            if (tr.parentNode === this.bodyEl) {
                if (rowId !== '') {
                    if (e.isMouseLeave(td)) {
                        col = this.visibleColumns[td.cellIndex];
                        this.fireEvent('cellMouseOut', this, rowId, col, td, e);
                    }
                }
            }
        }
    };

    /**
     * Remove css class 'hover' from tr
     */
   /* GUI.Grid.prototype.unhoverTr = function () {
        GUI.Dom.removeClass(this.hoverTr, 'hover');
        this.hoverTr = null;
    };*/

    /**
     * Remove css class 'hover' from td
     */
   /* GUI.Grid.prototype.unhoverTd = function () {
        GUI.Dom.removeClass(this.hoverTd, 'hover');
        this.hoverTd = null;
    };*/

    GUI.Grid.prototype.onMouseLeave = function (e) {
        // Going out of grid
        e = GUI.Event.extend(e);
        if (this.hintVisible) {
            if (!e.isMouseLeave(this.hintList.dom)) {
                return;
            }
        }
        this.hoverTr = null;
        this.hoverTd = null;

        /*if (this.hoverTd) {
            this.unhoverTd();
        }

        if (this.hoverTr) {
            this.unhoverTr();
        }*/

        if (this.hintTimer) {
            clearTimeout(this.hintTimer);
            this.hintTimer = null;
        }
    };

    /**
     * Marks row as selected
     * @param {Object} row
     */
    GUI.Grid.prototype.onRowSelect = function (row) {
        row.select();
    };

    /**
     * Unmarks selected row
     * @param {Object} row
     */
    GUI.Grid.prototype.onRowDeselect  = function (row) {
        row.deselect();
    };

    /**
     * Returns element of the mask
     * @returns {HTMLElement} holder
     */
    GUI.Grid.prototype.getMaskEl = function () {
        return this.gridHolder.parentNode;
    };

    /**
     * Sets custom row hidden
     * @param {Boolean} val
     */
    GUI.Grid.prototype.setCustomRowHidden = function (val) {
        this.customRowHidden = val;
    };

    /**
     * Returns holder of the grid
     * @returns {HTMLElement} holder
     */
    GUI.Grid.prototype.getHolder = function () {
        return this.holder;
    };

}());