/*
 * jQuery autoResize (textarea auto-resizer)
 * @copyright James Padolsey http://james.padolsey.com
 * @version 1.04
 */

(function($){

    $.fn.autoResize = function(options) {

        // Just some abstracted details,
        // to make plugin users happy:
        var settings = $.extend({
            onResize : function(){

			},
            animate : false,
            animateDuration : 150,
            animateCallback : function(){},
            disableEnter: false,
            extraSpace : 20,
            limit: 1000,
            addedLines: 1,
            maxWidth: null,
            minWidth: null
        }, options);

        // Only textarea's auto-resize:
        this.filter('textarea').each(function(){

                // Get rid of scrollbars and disable WebKit resizing:
            var textarea = $(this).css({resize:'none','overflow-y':'hidden'}),

                // Cache original height, for use later:
                origHeight = textarea.height(),


                // Need clone of textarea, hidden off screen:
                clone = (function(){

                    // Properties which may effect space taken up by chracters:
                    var props = ['height','width','lineHeight','textDecoration','letterSpacing'],
                        propOb = {};

                    // Create object of styles to apply:
                    $.each(props, function(i, prop){
                        propOb[prop] = textarea.css(prop);
                    });

                    // Clone the actual textarea removing unique properties
                    // and insert before original textarea:
                    return textarea.clone().removeAttr('id').removeAttr('name').addClass('g-hide-offsets').css(propOb).attr('tabIndex','-1').insertBefore(textarea);
                })(),
                mirror = (function () {
                    if(settings.maxWidth || settings.minWidth) {
                        // Init mirror
                        var mirror = $('<span style="position:absolute; top:-999px; left:0; white-space:pre;"/>');

                        // Copy to mirror
                        $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], function (i, val) {
                            mirror[0].style[val] = textarea.css(val);
                        });
                        if (settings.minWidth) {
                            mirror.css({'minWidth': settings.minWidth});
                            textarea.css({'minWidth': settings.minWidth});
                        }

                        if (settings.maxWidth) {
                            mirror.css({'maxWidth': settings.maxWidth});
                            textarea.css({'maxWidth': settings.maxWidth});
                        }

                        mirror.insertBefore(textarea);

                        return mirror;
                    }
                })(),
                lastScrollTop = null,
                updateSize = function() {
                    // Prepare the clone:
                    if (settings.maxWidth || settings.minWidth) {
                        mirror.text($(this).val());
                        clone.width(mirror.width() + 10);
                        textarea.width(mirror.width() + 10);
                    }

                    clone.css({
                        minHeight: 'inherit',
                        maxHeight: 'inherit',
                        height: 'inherit'
                    });

                    clone.height(0).val($(this).val() + "\n".repeat(settings.addedLines)).scrollTop(10000);

                    // Find the height of text:
                    var scrollTop = Math.max(clone.scrollTop(), origHeight) + settings.extraSpace,
                        toChange = $(this).add(clone);

                    // Don't do anything if scrollTip hasen't changed:
                    if (lastScrollTop === scrollTop) { return; }
                    lastScrollTop = scrollTop;

                    // Check for limit:
                    if ( scrollTop >= settings.limit ) {
                        $(this).css('overflow-y','');
                        return;
                    }
                    // Fire off callback:
                    settings.onResize.call(this);

                    // Either animate or directly apply height:
                   settings.animate && textarea.css('display') === 'block' ?
                        toChange.stop().animate({height:scrollTop}, settings.animateDuration, settings.animateCallback)
                        : toChange.height(scrollTop);
                };

            // Bind namespaced handlers to appropriate events:
            textarea
                .unbind('.dynSiz')
                .bind('input.dynSiz', updateSize)
                .bind('change.dynSiz', updateSize);

            setTimeout(updateSize.bind(textarea)(), 0);
        });

        // Chain:
        return this;
    };
})(jQuery);

(function($){

$.fn.autoResizeInput = function(o) {

    o = $.extend({
        maxWidth: 1000,
        minWidth: 0,
        comfortZone: 70
    }, o);

    this.filter('input:text').each(function(){

        var minWidth = o.minWidth || $(this).width(),
            val = null,
            input = $(this),
            testSubject = $('<tester/>').css({
                position: 'absolute',
                top: -9999,
                left: -9999,
                width: 'auto',
                fontSize: input.css('fontSize'),
                fontFamily: input.css('fontFamily'),
                fontWeight: input.css('fontWeight'),
                letterSpacing: input.css('letterSpacing'),
                whiteSpace: 'nowrap'
            }),
            check = function() {

                if (val === (val = input.val())) {return;}

                // Enter new content into testSubject
                var escaped = val.replace(/&/g, '&amp;').replace(/\s/g, '&nbsp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                testSubject.html(escaped);

                // Calculate new width + whether to change
                var testerWidth = testSubject.width(),
                    newWidth = (testerWidth + o.comfortZone) >= minWidth ? testerWidth + o.comfortZone : minWidth,
                    currentWidth = input.width(),
                    isValidWidthChange = (newWidth < currentWidth && newWidth >= minWidth)
                                         || (newWidth > minWidth && newWidth < o.maxWidth);

                // Animate width
                if (isValidWidthChange) {
                    input.width(newWidth);
                }

            };

        testSubject.insertAfter(input);
        $(this).bind('keydown keyup update change input', check);
        check();

    });

    return this;

};

})(jQuery);