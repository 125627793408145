GUI.onDOMReady(function() {
    var nav = GUI.Dom.findDescedent(document.body, 'nav.breadcrumbs');

    if (!nav)
        return;

    var breadcrumbs__list = GUI.Dom.findDescedent(nav, 'ul.breadcrumbs__list'),
        hoverNode = '',
        selectedNode = '',
        selectedLi;

    GUI.unselectable(nav);

    GUI.Event.on(breadcrumbs__list, 'mouseover', addRemoveHoverForBreadcrumbItem);
    GUI.Event.on(breadcrumbs__list, 'click', addRemoveSelectedForBreadcrumbItem);

    Application.addEventForDocument('mousedown', bodyClickHandler);

    function addRemoveHoverForBreadcrumbItem(e){
        e = GUI.Event.extend(e);
        var node = e.target;
        if (node.className == 'breadcrumbs__menu-icon') {
            hoverNode = node.parentNode.parentNode;
            hoverNode.addClass('hover');
        } else if (hoverNode) {
            hoverNode.removeClass('hover');
            hoverNode = '';
        }
    }

    function addRemoveSelectedForBreadcrumbItem(e){
        e = GUI.Event.extend(e);
        var node = e.target;

        if (node.nodeName.toLowerCase() !== 'a') {
            node = GUI.findParentByTag(node, 'A');
        }

        if (selectedLi) {
            selectedLi.removeClass('selected');
        }

        selectedLi = GUI.findParentByTag(node, 'LI');

        if (node && node.hasClass('btn_super-hover-item selected') ) {

            if (selectedNode) {
                GUI.hide(selectedNode);
                selectedNode = '';
            }
            selectedNode = GUI.Dom.findNextSibling(node.parentNode, 'ul.breadcrumbs__dropdown')[0];

            if (selectedNode) {
                selectedNode.style.display = 'block';
                selectedLi.addClass('selected');
            }
        }
    }

    function bodyClickHandler(e){
        e = GUI.Event.extend(e);
        if (!e.within(selectedNode) && selectedNode) {
            GUI.hide(selectedNode);
        }
        if (!e.within(selectedLi) && selectedLi) {
            selectedLi.removeClass('selected');
        }
    }
});