(function () {
    var superproto = GUI.Forms.CheckBox.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Radio implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.CheckBox
    */
    GUI.Forms.Radio = Class.create();
    Object.extend(GUI.Forms.Radio.prototype, superproto);

    /**
     * Type of the element, 'radio'
     * @type String
     */
    GUI.Forms.Radio.prototype.type = 'radio';

    /**
     * Css class of the field, default is ''
     * @type String
     */
    GUI.Forms.Radio.prototype.className = '';

    /**
     * Css class tha applied when the field is invalid, default is ''
     * @type String
     */
    GUI.Forms.Radio.prototype.invalidClass = '';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.Radio.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

}());