(function () {
    var superproto = GUI.Utils.Observable.prototype,
        i18n = GUI.i18n;

    /**
     * JavaScript Graphical User Interface
     * Wizard implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Wizard = Class.create();
    Object.extend(GUI.Wizard.prototype, superproto);

    /**
     * Template
     * @type String
     */
    GUI.Wizard.prototype.tabTemplate =
        '<li id="{0}" class="b-wizard__tab">' +
        '<span class="ttl">{2}</span>' +
        '<p id="{0}-info" class="desc">{3}</p>' +
        '<i class="step-count">{1}</i>' +
        '</li>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Wizard.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);

        this.config = {
            id              : GUI.getUniqId('wizard-'),
            className       : 'b-wizard',
            steps           : null,
            stepTpl         : i18n.GUI_WIZARD_TPL,
            holder          : null,
            name            : null,
            onRender        : null,
            onNextStep      : null,
            onPrevStep      : null,
            onLastStep      : null,
            onBeforeNextStep : null,
            onBeforePrevStep : null,
            onBeforeLastStep : null
        };

        var cfg = this.config;
        Object.extend(cfg, config);

        this.currentStep = -1;
        this.tabsId = cfg.id + '-tabs';

        this.addEvents({
            nextStep        : true,
            prevStep        : true,
            beforeNextStep  : true,
            beforePrevStep  : true,
            render          : true,
            beforeLastStep  : true,
            lastStep        : true
        });

        if (GUI.isFunction(cfg.onRender)) {
            this.on('render', cfg.onRender);
        }
        if (GUI.isFunction(cfg.onNextStep)) {
            this.on('nextStep', cfg.onNextStep);
        }
        if (GUI.isFunction(cfg.onPrevStep)) {
            this.on('prevStep', cfg.onPrevStep);
        }
        if (GUI.isFunction(cfg.onLastStep)) {
            this.on('lastStep', cfg.onLastStep);
        }
        if (GUI.isFunction(cfg.onBeforeNextStep)) {
            this.on('beforeNextStep', cfg.onBeforeNextStep);
        }
        if (GUI.isFunction(cfg.onBeforePrevStep)) {
            this.on('beforePrevStep', cfg.onBeforePrevStep);
        }
        if (GUI.isFunction(cfg.onBeforeLastStep)) {
            this.on('beforeLastStep', cfg.onBeforeLastStep);
        }

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
    };

    /**
     * Destroy objects
     */
    GUI.Wizard.prototype.destroy = function () {
        if (this.dom) {
            this.removeEventListeners();
            GUI.remove(this.dom);
            this.dom = null;
        }
    };

    /**
     * Returns html of the tab
     * @param {Object} tab
     */
    GUI.Wizard.prototype.getTabHtml = function (tab) {
        return this.tabTemplate.format(tab.id, tab.step, tab.title, tab.info || '');
    };

    /**
     * Renders dom
     * @param {HTMLElement} to Element to render dom to
     */
    GUI.Wizard.prototype.render = function (to) {
        var i, len, step, tpl, cfg, o, tabsHtml, contentHtml;
        if (this.dom) {
            this.removeEventListeners();
            GUI.remove(this.dom);
            this.dom = null;
        }
        this.visible = false;

        cfg = this.config;
        if (to) {
            cfg.holder = to;
        } else if (cfg.holder) {
            to = cfg.holder;
        }

        to = GUI.$(to);
        if (!to) {
            throw new Error("Wizard: Can't render to null");
        }

        o = [];
        o.push('<div id="', cfg.id, '" class="', cfg.className, '">');
        o.push('<table class="b-wizard__layout">');
        o.push('<tr>');
        o.push('<td class="b-wizard__layout__sidebar">');
        o.push('<ul id="', this.tabsId, '" class="b-wizard__tabs">');

        // render steps
        tabsHtml = [];
        for (i = 0, len = cfg.steps.length; i < len; i++) {
            step = cfg.steps[i];
            step.number     = i + 1;
            step.id = cfg.id + '-step-' + step.number.toString();
            step.tabId      = step.id + '-tab';
            step.contentId  = step.id + '-content';

            tabsHtml.push(this.getTabHtml({
                id          : step.tabId,
                title       : step.title,
                info        : step.descr,
                step        : step.number,
                hidden      : step.hidden
            }));

        }

        o.push(tabsHtml.join(''));
        o.push('</ul>');
        o.push('</td>');
        o.push('<td class="b-wizard__layout__content">');
        o.push('<div class="b-wizard__steps">');

        // set content
        for (i = 0, len = cfg.steps.length; i < len; i++) {
            step = cfg.steps[i];
            step.id = cfg.id + '-step-' + step.number.toString();

            o.push('<div id="', step.id, '" class="b-wizard__step" style="display: none;">');
            o.push('<div class="b-wizard__step-ttl">');

            o.push('<span class="b-wizard__step-count">');
            tpl = GUI.STemplate(this.config.stepTpl);
            tpl.step = step.number;
            tpl.total = len;
            o.push(tpl.fetch());
            o.push('</span>');

            o.push('<span class="b-wizard__step-ttl-txt">', step.title, '</span>');
            o.push('</div>');

            o.push('<div id="', step.contentId, '" class="b-wizard__step-content">');
            if (GUI.isString(step.content)) {
                o.push(step.content);
            }
            o.push('</div>');
            o.push('</div>');
        }

        o.push('</div>');
        o.push('</td>');
        o.push('</tr>');
        o.push('</table>');
        o.push('</div>');
        o.push('');

        if (GUI.isIE) {
            this.dom = GUI.$(to);
            to.innerHTML = o.join('');
        } else {
            to.innerHTML = o.join('');
            this.dom = GUI.$(to);
        }
        this.attachEventListeners();

        this.nextStep();
        this.fireEvent('render', this);
    };

    /**
     * Returns tab node of the step
     * @returns {HTMLElement} node
     */
    GUI.Wizard.prototype.getStepTabNode = function (step) {
        if (this.dom && step.id) {
            return GUI.$(step.id + '-tab');
        }
        return false;
    };

    /**
     * Returns content node of the step
     * @returns {HTMLElement} node
     */
    GUI.Wizard.prototype.getStepContentNode = function (step) {
        if (GUI.isString(step)) {
            step = this.getStep(step);
        }

        if (this.dom && step.id) {
            return GUI.$(step.id + '-content');
        }
        return false;
    };

    /**
     * Sets step, hides old step and shows new
     * @param {Number} num
     */
    GUI.Wizard.prototype.setStep = function (num) {
        var oldStep, newStep;
        if (this.currentStep === num) {
            return;
        }
        if (this.currentStep > -1 && this.currentStep < this.config.steps.length) {
            // Make old tab not active
            oldStep = this.config.steps[this.currentStep];
            GUI.removeClass(oldStep.tabId, 'selected');
            // Hide old content
            GUI.hide(oldStep.id);
        }

        this.currentStep = num;

        if (num > -1 && num < this.config.steps.length) {
            newStep = this.config.steps[num];
            if (newStep) {
                // Make new tab active
                GUI.removeClass(newStep.tabId, 'passed');
                GUI.addClass(newStep.tabId, 'selected');
                // Show new content
                GUI.show(newStep.id);
            }
        }
    };

    /**
     * Handler next step
     */
    GUI.Wizard.prototype.nextStep = function () {
        if (this.currentStep === this.config.steps.length) {
            return;
        }
        var nextStep,
            curStep = this.config.steps[this.currentStep];

        if (!this.fireEvent('beforeNextStep', this, curStep)) {
            return false;
        }

        if (this.currentStep === this.config.steps.length - 2) {
            if (!this.fireEvent('beforeLastStep', this, curStep)) {
                return false;
            }
        }

        if (this.currentStep > -1 && this.currentStep < this.config.steps.length) {
            GUI.removeClass(curStep.tabId, 'selected');
            GUI.addClass(curStep.tabId, 'passed');
        }

        if (this.currentStep + 1 < this.config.steps.length) {
            this.setStep(this.currentStep + 1);
            nextStep = this.config.steps[this.currentStep];
            this.fireEvent('nextStep', this, nextStep);
            if (this.currentStep === this.config.steps.length - 1) {
                this.fireEvent('lastStep', this, nextStep);
            }
        } else {
            this.fireEvent('nextStep', this, null);
        }
        return true;
    };

    /**
     * Handler prev step
     */
    GUI.Wizard.prototype.prevStep = function () {
        if (this.currentStep < 1) {
            return;
        }
        var prevStep,
            curStep = this.config.steps[this.currentStep];

        if (this.fireEvent('beforePrevStep', this, curStep)) {
            if (this.currentStep > 0 && this.currentStep < this.config.steps.length) {
                GUI.removeClass(curStep.tabId, 'passed');
                GUI.removeClass(curStep.tabId, 'selected');
            }
            this.setStep(this.currentStep - 1);
            prevStep = this.config.steps[this.currentStep];
            this.fireEvent('prevStep', this, prevStep);
            return true;
        }
        return false;
    };

    /**
     * Returns true if next is exists
     */
    GUI.Wizard.prototype.hasNext = function () {
        return this.currentStep < this.config.steps.length - 1;
    };

    /**
     * Returns true if prev is exists
     */
    GUI.Wizard.prototype.hasPrev = function () {
        return this.currentStep > 0;
    };

    /**
     * Hides step by the name
     * @param {String} name
     */
    GUI.Wizard.prototype.hideStep = function (name) {
        var step = this.getStep(name);
        if (step) {
            step.skip = true;
        }
        if (this.dom) {
            GUI.hide(step.tabId);
        }
    };

    /**
     * Show step by the name
     * @param {String} name
     */
    GUI.Wizard.prototype.showStep = function (name) {
        var step = this.getStep(name);
        if (step) {
            step.skip = false;
        }
        if (this.dom) {
            GUI.show(step.tabId);
        }
    };

    /**
     * Returns step by the name
     * @param {String} name
     */
    GUI.Wizard.prototype.getStep = function (name) {
        var step,
            steps = this.config.steps,
            len = steps.length;

        while (len--) {
            step = steps[len];
            if (step.name === name) {
                return step;
            }
        }
        return false;
    };

    /**
     * Sets description of the step
     * @param {String} name
     * @param {String} descr
     */
    GUI.Wizard.prototype.setStepDescription = function (name, descr) {
        var step = this.getStep(name);
        if (step) {
            step.descr = descr;
            if (this.dom) {
                GUI.$(step.tabId + '-info').innerHTML = descr;
            }
        }
    };

    /**
     * empty function
     */
    GUI.Wizard.prototype.attachEventListeners = function () {};

    /**
     * empty function
     */
    GUI.Wizard.prototype.removeEventListeners = function () {};

}());