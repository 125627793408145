(function() { // variables isolation

    window.ExternalAccountsClass = window.Class.create();

    prototype = {
        initialize : function () {
            var el, i,
                buttons = [];
            buttons.push('add_oauth_facebook');
            buttons.push('add_oauth_google');
            buttons.push('add_oauth_twitter');

            var showOAuthPopup = function(event) {
                event.preventDefault();
                this.showPopup(event.currentTarget.href);
            }

            for (i = 0; i < buttons.length; i++) {
                el = GUI.$(buttons[i]);
                if (el) {
                    el.on('click', showOAuthPopup, this);
                }
            }

            if (GUI.$('add_oauth_openid')) {
                GUI.$('add_oauth_openid').on('click', function(event) {
                    event.preventDefault();
                    if (event.currentTarget.form.openId.value == '')
                        return;

                    this.showPopup(event.currentTarget.href);
                    event.currentTarget.form.submit();
                }, this);
            }
        },

        showPopup : function(url) {
            if (typeof(url) != "string") {
                url = '';
            }
            this._popupWindow = window.open(url, "oAuthPopup", "resizable=1,width=1000,height=600");

            this._popupHandler = window.setInterval((function() {
                if (this._popupWindow.closed) {
                    window.clearInterval(this._popupHandler);
                    this.confirmOAuth();
                }
            }).bindLegacy(this), 1000);
        },

        _onAuthResponse : function(answer) {
            window.location.reload();
        },

        confirmOAuth : function() {
            new GUI.Ajax.Request(Application.settings.baseUrl + 'auth/confirm', {
                method : 'POST',
                data   : {},
                onSuccess : this._onAuthResponse.bindLegacy(this)
            });
        }
    }

    Object.extend(window.ExternalAccountsClass.prototype, prototype);
}());

GUI.onDOMReady(function() {
    Application.ExternalAccounts = new window.ExternalAccountsClass();
});