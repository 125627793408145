(function () { // variables isolation

    var AuthPanelClass = window.Class.create();

    var prototype = {
        holder                    : false,
        loader                    : false,
        myRequestsBtn             : false,
        loginBtn                  : false,
        logoutBtn                 : false,
        signUpBtn                 : false,
        userProfileDropDownHolder : false,
        userProfileDropDown       : false,
        userProfileMenu           : false,
        staffConsoleBtn           : false,
        nameHolder                : false,
        avatarHolder              : false,
        userDropDownList          : false,

        _setListeners : function () {
            if (this.loginBtn) {
                this.loginBtn.on('click', this.login.bindLegacy(this, null));
            }

            if (this.myRequestsBtn) {
                this.myRequestsBtn.on('click', this.goToMyRequests.bindLegacy(this));
            }

            if (this.signUpBtn) {
                this.signUpBtn.on('click', this.signUp.bindLegacy(this));
            }
        },

        initialize : function (config) {
            Object.extend(this, config);

            if (this.holder) {
                this._setListeners();
                GUI.show(this.holder);
            }

            if (this.userProfileDropDown) {
                this.dropDownMenu = new GUI.Popup.Menu({
                    name: 'dropDownMenu',
                    parentNode: this.userProfileDropDown.parentNode,
                    openOnHover: false,
                    hideOnDocumentClick: true,
                    items: window.adminPanelMenu
                });

                this.userProfileDropDown.on('click', this.showMenu, this);
                this.dropDownMenu.on('hide', this.hideMenu, this);
            }

        },

        goToMyRequests : function () {
            window.location.href = route(
                'addTicket', {}
            );
        },

        login : function(callback, email) {
            Application.LoginForm.show(callback, email);
        },

        showForgotPassword : function(callback, email) {
            Application.LoginForm.showForgotPassword(callback, email);
        },

        authenticate : function (callback, email) {
            if (!Application.authorization || !Application.authorization.id) {
                this.login(callback, email);
            } else {
                callback();
            }
        },

        logout : function() {
            window.location.href = route('logout', {});
        },

        signUp : function() {
            Application.RegistrationForm.show();
        },

        onAuthenticated : function () {
            if (this.holder) {
                GUI.hide(this.loader);

                if (this.loginBtn) {
                    GUI.hide(this.loginBtn);
                }
                if (this.signUpBtn) {
                    GUI.hide(this.signUpBtn);
                }

                if (!this.avatarHolder) {
                    this.avatarHolder = true;
                    jQuery(this.userProfileDropDown).html(
                        '<span class="btn__icon" id="auth-panel__welcome-avatar" data-auth-ready="1">' +
                        Application.authorization.avatarTpl.replace(/\{\$size\}/g, '32px') +
                        '</span>'
                    );
                }

                this.userProfileDropDown.title = Application.authorization.name;

                if (this.myRequestsBtn) {
                    GUI.show(this.myRequestsBtn);
                }

                GUI.show(this.userProfileDropDownHolder);

                if (!window.adminPanelMenu.length) {
                    new GUI.Ajax.Request(
                        route('getMenuItems'),
                        {
                            method    : 'POST',
                            data      : {},
                            onSuccess : function(xhr, request) {
                                window.adminPanelMenu = request.responseJson.menuItems;
                                this.dropDownMenu.setItems(window.adminPanelMenu);
                            }.bindLegacy(this)
                        }
                    );
                }
            }
            if (checkFeatureRestriction('featureImageUploadEnabled')) {
                if (Application.authorization.role !== 'user') {
                    GUI.Forms.Plugins.EditorImageManager.prototype.afterRender = function () {
                        this.tabbar.switchTo('web');
                        this.tabbar.getElement('upload').on(
                            'click',
                            function() {
                                this.tabbar.switchTo('web');
                            }.bindLegacy(this)
                        );

                        onWithFeatureRestrictions(
                            'featureImageUploadEnabled',
                            this.tabbar.getElement('upload'),
                            'click',
                            function() {}
                        );
                    };
                    GUI.DragDropUpload.prototype.process = function() {
                        window.showConfirmFeatureNotAvailableDialog();
                    }
                } else {
                    GUI.Forms.Plugins.EditorImageManager.prototype.onlyWeb = true;
                }
            }

            setTimeout(function () {
                var x = window.scrollX, y = window.scrollY;
                GUI.$('search-widget-input') && GUI.$('search-widget-input').focus();
                window.scrollTo(x, y);
            }, 300);
        },

        onNotAuthenticated : function () {
            if (this.holder) {
                GUI.hide(this.loader);
                GUI.hide(this.userProfileDropDownHolder);

                if (this.myRequestsBtn) {
                    GUI.show(this.myRequestsBtn);
                }
                if (this.loginBtn) {
                    GUI.show(this.loginBtn);
                }
                if (this.signUpBtn) {
                    GUI.show(this.signUpBtn);
                }
            }
            if (checkFeatureRestriction('featureImageUploadEnabled')) {
                GUI.Forms.Plugins.EditorImageManager.prototype.onlyWeb = true;
            }
        },

        onAuthCheck : function () {
            this.onNotAuthenticated();
            Application.LoginForm.silentAuth();

            setTimeout(function () {
                var x = window.scrollX, y = window.scrollY;
                GUI.$('search-widget-input') && GUI.$('search-widget-input').focus();
                window.scrollTo(x, y);
            }, 300);
        },

        onLogin : function () {
            this.onAuthenticated();
        },

        showMenu: function () {
            this.dropDownMenu.show();
            GUI.Dom.addClass(this.userProfileDropDownHolder, 'selected');
        },

        hideMenu: function () {
            GUI.Dom.removeClass(this.userProfileDropDownHolder, 'selected');
        }
    };

    Object.extend(AuthPanelClass.prototype, prototype);

    GUI.onDOMReady(function () {
        if (!window.doNotInitAuth) {
            Application.AuthPanel = new AuthPanelClass({
                holder                    : GUI.$('auth-panel__auth-menu'),
                loader                    : GUI.$('auth-panel__loader'),
                myRequestsBtn             : GUI.$('auth-panel__my-requests-btn'),
                loginBtn                  : GUI.$('auth-panel__login-btn'),
                logoutBtn                 : GUI.$('auth-panel__logout-btn'),
                signUpBtn                 : GUI.$('auth-panel__signup-btn'),
                userProfileDropDownHolder : GUI.$('auth-panel__user-profile-dropdowrn-holder'),
                userProfileDropDown       : GUI.$('auth-panel__user-profile-dropdown-btn'),
                nameHolder                : GUI.$('auth-panel__welcome-name'),
                avatarHolder              : GUI.$('auth-panel__welcome-avatar')
            });

            Application.on('authenticated',    Application.AuthPanel.onAuthenticated.bindLegacy(Application.AuthPanel));
            Application.on('notauthenticated', Application.AuthPanel.onNotAuthenticated.bindLegacy(Application.AuthPanel));
            Application.on('checkauth',        Application.AuthPanel.onAuthCheck.bindLegacy(Application.AuthPanel));
            Application.on('login',            Application.AuthPanel.onLogin.bindLegacy(Application.AuthPanel));
        }
    });
} ());