/**
 *
 */
Templates = {

    getStatusTpl : function (value) {
        var tpl = '<span class="{this.className}">{this.status}</span>',
            data = {
                answered    : {
                    className   : '',
                    status      : pgettext('Topic status (question)', 'Answered')
                },
                under_review    : {
                    className   : '',
                    status      : pgettext('Topic status (idea)', 'Under Review')
                },
                planned    : {
                    className   : '',
                    status      : pgettext('Topic status (idea)', 'Planned')
                },
                started    : {
                    className   : '',
                    status      : pgettext('Topic status (idea)', 'Started')
                },
                completed    : {
                    className   : '',
                    status      : pgettext('Topic status (idea)', 'Completed')
                },
                declined    : {
                    className   : '',
                    status      : pgettext('Topic status (idea)', 'Declined')
                },
                acknowledged    : {
                    className   : '',
                    status      : pgettext('Topic status (problem)', 'Acknowledged')
                },
                in_progress    : {
                    className   : '',
                    status      : pgettext('Topic status (problem)', 'In Progress')
                },
                solved    : {
                    className   : '',
                    status      : pgettext('Topic status (problem)', 'Solved')
                },
                questions    : {
                    className   : '',
                    status      : pgettext('Topic status', 'Unanswered')
                },
                no_status    : {
                    className   : '',
                    status      : pgettext('Topic status', 'No Status')
                },
                empty    : {
                    className   : '',
                    status      : ''
                }
            };
        tpl = new GUI.STemplate(tpl, data[value] || {status: value});
        return tpl.fetch();
    }
};