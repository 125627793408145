(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Draggable implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     * @extends GUI.Utils.Observable
     */
    GUI.Utils.Draggable = Class.create();
    Object.extend(GUI.Utils.Draggable.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Utils.Draggable.prototype.initialize = function (config) {
        var defCfg = {
            dd: {
                linkedElId      : null,
                dragElId        : null,
                handleElId      : null,
                targetElId      : null,
                isTarget        : false,
                moveOnly        : true,
                disabled        : false,
                groups          : {
                    'default' : true
                },
                clipElId        : null,
                hasOuterHandles : false
            }
        };
        Object.extend(defCfg, config);
        this.config = defCfg;

        // Call parent method
        superproto.initialize.call(this);
        // Aliases
        this.DDM = GUI.Utils.DDM;
        this.ddConfig = this.config.dd;

        this.addEvents('dragover');
    };

    /**
     * Initialize drag'n'drop
     */
    GUI.Utils.Draggable.prototype.initDD = function () {
        var i, el;
        for (i in this.ddConfig.groups) {
            this.DDM.registerDD(this, i);
        }

        el = GUI.$(this.ddConfig.linkedElId);
        if (!el) {
            console.log('no linkedEl', this.ddConfig.linkedElId);
            return;
        }
        if (!this.ddConfig.targetElId) {
            this.ddConfig.targetElId = this.ddConfig.linkedElId;
        }

        el.on('mousedown', this.onDdMouseDown, this);
        if (GUI.isMobile) {
            el.on('touchstart', this.onDdMouseDown, this);
        }
    };

    /**
     * Destroys objects
     */
    GUI.Utils.Draggable.prototype.destroyDD = function () {
        var i;

        GUI.$(this.ddConfig.linkedElId).un('mousedown', this.onDdMouseDown, this);
        if (GUI.isMobile) {
            GUI.$(this.ddConfig.linkedElId).un('touchstart', this.onDdMouseDown, this);
        }

        for (i in this.ddConfig.groups) {
            this.DDM.unregisterDD(this, i);
        }
    };

    /**
     * Sets id of the handle
     * @param {Number|String} id
     */
    GUI.Utils.Draggable.prototype.setHandleElId = function (id) {
        this.ddConfig.handleElId = id;
        this.DDM.registerHandle(this.ddConfig.linkedElId, id);
    };

    /**
     * Unset handle element id
     * @param {Number|String} id
     */
    GUI.Utils.Draggable.prototype.unsetHandleElId = function (id) {
        if (this.ddConfig.handleElId === id) {
            this.ddConfig.handleElId = null;
        }
        this.DDM.unregisterHandle(this.ddConfig.linkedElId, id);
    };

    /**
     * Sets outer handle element id
     * @param {Number|String} id
     */
    GUI.Utils.Draggable.prototype.setOuterHandleElId = function (id) {
        GUI.$(id).on('mousedown', this.onDdMouseDown, this);
        this.setHandleElId(id);
        this.ddConfig.hasOuterHandles = true;
    };

    /**
     * Unsets outer handle element id
     * @param {Number|String} id
     */
    GUI.Utils.Draggable.prototype.unsetOuterHandleElId = function (id) {
        GUI.$(id).un('mousedown', this.onDdMouseDown, this);
        this.unsetHandleElId(id);
        this.ddConfig.hasOuterHandles = false;
    };

    /**
     * Sets start position
     * @param {Event} e Event
     */
    GUI.Utils.Draggable.prototype.setStartPosition = function (e) {
        var dom = GUI.$(this.ddConfig.linkedElId);
        this.startPageXY = [dom.offsetLeft, dom.offsetTop];
    };

    /**
     * Validate drag
     * @param {Event} e Event
     */
    GUI.Utils.Draggable.prototype.dragValidator = function (e) {
        var target = e.getTarget();
        return (
        // Added to fix bug in Opera 9.50 in the tree. Checkboxes do not work without this fix
            ((target.nodeName !== 'INPUT') || (target.type !== 'checkbox')) &&
                (this.ddConfig.linkedElId === this.ddConfig.handleElId ||
                this.DDM.handleWasClicked(target, this.ddConfig.linkedElId))
        );
    };

    /**
     * Enables Drag'n'drop
     */
    GUI.Utils.Draggable.prototype.enableDD = function () {
        this.ddConfig.disabled = false;
    };

    /**
     * Disabled Drag'n'drop
     */
    GUI.Utils.Draggable.prototype.disableDD = function () {
        this.ddConfig.disabled = true;
    };

    /**
     * Call DDM handler is we clicked in a right place :)
     * @param {Object} e Event
     */
    GUI.Utils.Draggable.prototype.onDdMouseDown = function (e) {
        if (this.ddConfig.disabled) {
            return true;
        }
        e = new GUI.ExtendedEvent(e);

        this.DDM.refreshCacheOne(this);
        var pt = new GUI.Utils.Point(e.getPageXY());
        if (this.ddConfig.hasOuterHandles || this.DDM.isOverTarget(pt, this)) {
            if (this.dragValidator(e)) {
                this.setStartPosition(e);
                this.DDM.handleMouseDown(e, this);
                this.DDM.stopEvent(e);
            }
        }
        return this;
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onBeforeDragStart = function (e) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDragStart = function (e) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onBeforeDrag = function (e) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDrag = function (e, dropAccepted) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onBeforeDragEnd = function (e) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDragEnd = function (e) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDragEnter = function (e, dragObj) {
        //
    };

    /**
     * Fire event 'dragOver'
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'drop object
     */
    GUI.Utils.Draggable.prototype.onDragOver = function (e, dragObj) {
        this.fireEvent('dragOver', this, dragObj, e);
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDragLeave = function (e, dragObj) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onDragDrop = function (e, dragObj) {
        //
    };

    /**
     * empty function
     */
    GUI.Utils.Draggable.prototype.onInvalidDrop = function (e) {
        //
    };

}());