(function () {
    var superproto = GUI.Forms.TextField.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.TriggerField implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.TriggerField = Class.create();
    Object.extend(GUI.Forms.TriggerField.prototype, superproto);

    /**
     * Css class of the field
     * @type String
     */
    GUI.Forms.TriggerField.prototype.wrapperClass = '';

    /**
     *
     */
    GUI.Forms.TriggerField.prototype.textFieldClass = '';

    /**
     * Css class of the field
     * @type String
     */
    GUI.Forms.TriggerField.prototype.longClass = '';

    /**
     * Event of the trigger, 'mousedown'
     * @type String
     */
    GUI.Forms.TriggerField.prototype.triggerEvent = 'mousedown';

    /**
     *
     */
    GUI.Forms.TriggerField.prototype.triggerElClassName = 'i.b-text-field__icon';

    /**
     *
     */
    GUI.Forms.TriggerField.prototype.iconClass = 'icon';

    /**
     * Url of the image trigger, 'GUI.emptyImageUrl'
     * @type String
     */
    GUI.Forms.TriggerField.prototype.triggerImg = GUI.emptyImageUrl;

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.TriggerField.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_advanced {1}">' +
        '<i class="b-text-field__icon {3}"></i>' +
        '<i class="i-text-field"></i>' +
        '</i>';

    /**
     * Width, default is 150
     * @type Number
     */
    GUI.Forms.TriggerField.prototype.width = 159;

    /**
     * If true hidden input is present, default is false
     * @type Boolean
     */
    GUI.Forms.TriggerField.prototype.hiddenInput = false;

    /**
     * Click on trigger, default is true
     * @type Boolean
     */
    GUI.Forms.TriggerField.prototype.triggerOnFieldClick = true;

    /**
     * Constructor
     * @param {Object} config
     */
    GUI.Forms.TriggerField.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component, add event 'trigger'
     */
    GUI.Forms.TriggerField.prototype.initComponent = function () {
        superproto.initComponent.call(this);
        this.addEvents({
            trigger: true
        });
    };

    /**
     * Handler on assign
     * @param {String|HTMLElement} to Assign field to the element
     */
    GUI.Forms.TriggerField.prototype.onAssign = function (to) {
        superproto.onAssign.call(this, to);
    };

    /**
     * Handler on render
     * @returns {HTMLElement} dom Dom of the elements
     */
    GUI.Forms.TriggerField.prototype.onRender = function () {
        // Render text field
        this.fieldEl = superproto.onRender.call(this);

        // Render wrapper
        var dom, wrapper,
            tmp = GUI.getFly(),
            html = this.domTemplate.format(this.id, this.wrapperClass, this.textFieldClass, this.iconClass);

        tmp.innerHTML = html; // TODO: Check IE leaks
        dom = tmp.removeChild(tmp.firstChild);

        // Now wrap textfield
        wrapper = GUI.Dom.findDescedents(dom, 'i.i-text-field')[0];
        wrapper.appendChild(this.fieldEl);

        if (this.hiddenInput && this.name) {
            this.fieldEl.name = '';
            this.hiddenField = GUI.createInput(this.name);
            this.hiddenField.type = 'hidden';
            this.hiddenField._jsguiComponent = true;
            wrapper.appendChild(this.hiddenField);
        }
        return dom;
    };

    /**
     * Handler on after render
     * @param {HtmlElement} dom Dom of the elements
     */
    GUI.Forms.TriggerField.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, this.fieldEl);

        this.triggerEl = GUI.Dom.findDescedents(dom, this.triggerElClassName)[0];
        GUI.Dom.extend(this.triggerEl);
    };

    /**
     * Destroy the trigger element
     * @param {Boolean} quick
     */
    GUI.Forms.TriggerField.prototype.onDestroy = function (quick) {
        if (this.triggerEl) {
            this.triggerEl.un();
            this.triggerEl = null;
        }
        superproto.onDestroy.call(this, quick);
    };

    /**
     * Returns size
     * @param {Number} w Width
     * @param {Number} h Height
     */
    GUI.Forms.TriggerField.prototype.adjustSize = function (w, h) {
        return {
            width  : w,
            height : h
        };
    };

    /**
     * Attach events listeners
     */
    GUI.Forms.TriggerField.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
        this.attachTriggerEvents();
    };

    /**
     * Attach trigger events
     */
    GUI.Forms.TriggerField.prototype.attachTriggerEvents = function () {
        this.triggerEl.on(this.triggerEvent, this.onTriggerClick, this);
        this.triggerEl.on('click', this.preventDefaultEvent);

        if (this.triggerOnFieldClick) {
            this.fieldEl.on(this.triggerEvent, this.onTriggerClick, this);
        }
    };

    /**
     * @param {Event} e Event
     */
    GUI.Forms.TriggerField.prototype.preventDefaultEvent = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
    };

    /**
     *  Removes events listeners
     */
    GUI.Forms.TriggerField.prototype.removeEventListeners = function () {
        this.triggerEl.un();
        superproto.removeEventListeners.call(this);
    };

    /**
     * Sets the focus to element
     */
    GUI.Forms.TriggerField.prototype.focus = function () {
        if (this.fieldEl) {
            try {
                this.fieldEl.focus();
            } catch (e) {}
        }
    };

    /**
     * Adds class disabledClass
     */
    GUI.Forms.TriggerField.prototype.onDisable = function () {
        superproto.onDisable.call(this);
        if (this.dom) {
            this.dom.setAttribute("disabled", "disabled");
        }
    };

    /**
     * Removes class disabledClass
     */
    GUI.Forms.TriggerField.prototype.onEnable = function () {
        superproto.onEnable.call(this);
        if (this.dom) {
            this.dom.removeAttribute("disabled", "");
        }
    };

    /**
     * empty function
     * @param {Event} e Event
     */
    GUI.Forms.TriggerField.prototype.onTriggerClick = function (e) {

    };

    /**
     * Returns dom
     * @returns {HTMLElement} dom Dom of the element
     */
    GUI.Forms.TriggerField.prototype.getStylingEl = function () {
        return this.dom;
    };

}());