/**
 *
 * @type Class GUI.TimeAgo
 */
GUI.TimeAgo = Class.create();

/**
 * Scheme default | reduced | static | static-time
 * @type String
 * @deprecated
 */
GUI.TimeAgo.prototype.scheme = 'default'; // reduced

/**
 *
 * @returns {undefined}
 */
GUI.TimeAgo.prototype.initialize = function () {
    setInterval(this.refreshPageTime.bindLegacy(this), 60000);
};

/**
 *
 * @param {HTMLElement} dom_
 * @param {String} scheme
 * @param {String} prefixTitle
 * @returns {undefined}
 */
GUI.TimeAgo.prototype.init = function (dom_, prefixTitle) {
    var dom = dom_ || document.body,
        elements = GUI.Dom.findDescedents(dom, '.g-date');

    this.prefixTitle = prefixTitle || '';

    elements.each(this.refresh.bindLegacy(this));
};

/**
 *
 * @type Object
 */
GUI.TimeAgo.prototype.settings = {
    refreshMillis   : -6000,
    allowFuture     : false,
    strings : {
        prefixAgo       : null,
        prefixFromNow   : null,
        // TRANSLATORS: Part of a phrase, which specifies a moment of time in the past. E.g. '[13 days] ago' or '[2 months] ago'
        suffixAgo       : i18n('ago'),
        // TRANSLATORS: Part of a phrase, which specifies a moment of time in the future. E.g. '[13 days] from now' or '[2 months] from now'
        suffixFromNow   : i18n('from now'),
        // TRANSLATORS: a very short time ago (a few seconds).
        seconds         : i18n('just now'),
        //minute          : i18n(GUI.i18n.GUI_TIMEAGO_MINUTE),
        //minutes         : i18n(GUI.i18n.GUI_TIMEAGO_MINUTES),
        //hour            : i18n(GUI.i18n.GUI_TIMEAGO_HOUR),
        //hours           : i18n(GUI.i18n.GUI_TIMEAGO_HOURS),
        //day             : i18n(GUI.i18n.GUI_TIMEAGO_DAY),
        //days            : i18n(GUI.i18n.GUI_TIMEAGO_DAYS),
        //month           : i18n(GUI.i18n.GUI_TIMEAGO_MONTH),
        //months          : i18n(GUI.i18n.GUI_TIMEAGO_MONTHS),
        //year            : i18n(GUI.i18n.GUI_TIMEAGO_YEAR),
        //years           : i18n(GUI.i18n.GUI_TIMEAGO_YEARS),
        numbers         : []
    },
    shortMonth  : {
                // TRANSLATORS: Short version of 'January'
        1   : i18n("Jan"),
                // TRANSLATORS: Short version of 'February'
        2   : i18n("Feb"),
                // TRANSLATORS: Short version of 'March'
        3   : i18n("Mar"),
                // TRANSLATORS: Short version of 'April'
        4   : i18n("Apr"),
        5   : pgettext('Short form (same as the long form in English, but may be different in other languages)', "May"),
                // TRANSLATORS: Short version of 'June'
        6   : i18n("Jun"),
                // TRANSLATORS: Short version of 'July'
        7   : i18n("Jul"),
                // TRANSLATORS: Short version of 'August'
        8   : i18n("Aug"),
                // TRANSLATORS: Short version of 'September'
        9   : i18n("Sep"),
                // TRANSLATORS: Short version of 'October'
        10  : i18n("Oct"),
                // TRANSLATORS: Short version of 'November'
        11  : i18n("Nov"),
                // TRANSLATORS: Short version of 'December'
        12  : i18n("Dec")
    }
};

/**
 *
 * @param {HTMLElement} elem
 * @returns {HTMLElement} elem
 */
GUI.TimeAgo.prototype.refresh = function (elem) {
    var data = this.prepareData(elem),
        scheme = elem.getAttribute('scheme');

    if (!elem.getAttribute('scheme')) {
        return;
    }

    if (!isNaN(data)) {

        if (scheme === 'default') {
            elem.innerHTML = this.inWords(this.distance(data));

        } else if (scheme === 'reduced') {
            elem.innerHTML = this.prepareReducedDate(data);

        } else if (scheme === 'static' || scheme === 'static-time') {
            elem.innerHTML = this.prepareStaticDate(data, scheme);
        }
    }
    return elem;
};

GUI.TimeAgo.prototype.refreshPageTime = function () {
        var elements = document.querySelectorAll('.g-date');

        for (var i = 0; i < elements.length; i++) {
            this.refresh(elements[i]);
        }
};

/**
 *
 * @param {Date} data
 * @returns {String}
 */
GUI.TimeAgo.prototype.prepareStaticDate = function (data, scheme) {
    var text = '',
        text_ = [];

    // formate date
    text_ = data.toLocaleFormat('%m %d, %y %H:%M').split(' ');

    // add short month
    text = this.settings.shortMonth[parseInt(text_[0], 10)] + ' ' + text_[1]  + ' ' + text_[2];

    // add time
    if (scheme === 'static-time') {
        text += ' ' + text_[3];
    }

    return text;
};

/**
 *
 * @param {Date} data
 * @returns {String}
 */
GUI.TimeAgo.prototype.prepareReducedDate = function (data) {
    var text = '',
        now = new Date();

    // %d.%m.%y %H:%M:%s
    if (data.getDate() === now.getDate() && data.getFullYear() === now.getFullYear() && data.getMonth() === now.getMonth()) {
        text = data.toLocaleFormat('%H:%M');

    } else if (data.getFullYear() === now.getFullYear()) {
        text = data.toLocaleFormat('%m %d').split(' ');
        text = this.settings.shortMonth[parseInt(text[0], 10)] + ' ' + text[1];

    } else {
        text = data.toLocaleFormat('%m %d, %y').split(' ');
        text = this.settings.shortMonth[parseInt(text[0], 10)] + ' ' + text[1]  + ' ' + text[2];
    }
    return text;
};

/**
 *
 * @param {HTMLElement} element
 * @returns {Date}
 */
GUI.TimeAgo.prototype.prepareData = function (element) {
    var time = this.datetime(element),
        textTitle = time.toLocaleDateString(),
        timeText = time.toLocaleTimeString(),
        prefixTitle = element.getAttribute('prefix-title');

    timeText = timeText.split(' ');
    timeText[1] = timeText[1] || '';

    timeText = timeText[0].split(':').slice(0, 2).join(':') + ' ' + timeText[1];

    textTitle += ' ' + timeText.trim();

    if (prefixTitle) {
        textTitle = prefixTitle + ' ' + textTitle;
    }

    if (GUI.isIE) {
        element.title = textTitle;
    } else {
        element.setAttribute('title', textTitle);
    }
    return time;
};

/**
 *
 * @param {HTMLElement} elem
 * @returns {Date}
 */
GUI.TimeAgo.prototype.datetime = function (elem) {
    var iso8601, attrDateTime, attrTitle,
        node = GUI.$(elem);

    attrDateTime = node.datetime || node.getAttribute('datetime');
    attrTitle = node.title || node.getAttribute('title');

    iso8601 = attrDateTime || attrTitle;

    return this.parse(iso8601);
};

/**
 *
 * @param {Date} iso8601
 * @returns {Date}
 */
GUI.TimeAgo.prototype.parse = function (iso8601) {
    var s = iso8601 + ''.trim();

    s = s.replace(/\.\d\d\d+/, ""); // remove milliseconds
    s = s.replace(/-/, "/").replace(/-/, "/");
    s = s.replace(/T/, " ").replace(/Z/, " UTC");
    s = s.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400

    return new Date(s);
};

/**
 *
 * @param {Number} distanceMillis
 * @returns {String}
 */
GUI.TimeAgo.prototype.inWords = function (distanceMillis) {
    var seconds, minutes, hours, days, years, words,
        strings = this.settings.strings,
        prefix = strings.prefixAgo,
        suffix = strings.suffixAgo;

    if (this.settings.allowFuture) {
        if (distanceMillis < 0) {
            prefix = strings.prefixFromNow;
            suffix = strings.suffixFromNow;
        }
        distanceMillis = Math.abs(distanceMillis);
    }

    seconds = distanceMillis / 1000;
    minutes = seconds / 60;
    hours = minutes / 60;
    days = hours / 24;
    years = days / 365;

    function substitute(stringOrFunction, number) {
        var string, value;
        string = GUI.isFunction(stringOrFunction) ? stringOrFunction(number, distanceMillis) : stringOrFunction;
        value = (strings.numbers && strings.numbers[number]) || number;

        return string.replace(/%d/i, value);
    }

    if (seconds < 45) {
        words = substitute(strings.seconds, Math.round(seconds));
        return words.trim();
    }

    words = (seconds < 90 && substitute(
            i18n('a minute', '%d minutes', 1),
            1
        )) ||
        (minutes < 45 && substitute(
            i18n('a minute', '%d minutes', Math.round(minutes)),
            Math.round(minutes)
        )) ||
        (minutes < 90 && substitute(
            i18n('an hour', '%d hours', 1),
            1
        )) ||
        (hours < 24 && substitute(
            i18n('an hour', '%d hours', Math.round(hours)),
            Math.round(hours)
        )) ||
        (hours < 48 && substitute(
            i18n('a day', '%d days', 1),
            1
        )) ||
        (days < 30 && substitute(
            i18n('a day', '%d days', Math.floor(days)),
            Math.floor(days)
        )) ||
        (days < 60 && substitute(
            i18n('a month', '%d months', 1),
            1
        )) ||
        (days < 365 && substitute(
            i18n('a month', '%d months', Math.floor(days / 30)),
            Math.floor(days / 30)
        )) ||
        (years < 2 && substitute(
            i18n('a year', '%d years', 1),
            1
        )) ||
        (substitute(
            i18n('a year', '%d years', Math.floor(years)),
            Math.floor(years)
        ));

    return [prefix, words, suffix].join(" ").trim();
};

/**
 *
 * @param {Date} date
 * @returns {Date}
 */
GUI.TimeAgo.prototype.distance = function (date) {
    return (new Date().getTime() - date.getTime());
};

/**
 *
 */
GUI.TimeAgo = new GUI.TimeAgo();
