/**
 *
 */
GUI.onDOMReady(function () {
    VotingController.init();
});

/**
 *
 */
VotingController = new GUI.Controller({
    name    : 'Voting'
});

/**
 *
 */
VotingController.routes = {
    item  : {
        'vote'    : 'voteItem',
        'unvote'  : 'unvoteItem'
    },
    comment  : {
        'vote'    : 'voteComment',
        'unvote'  : 'unvoteComment'
    }
};

/**
 *
 */
VotingController.init = function () {
    if (this.voting_blocks) {
        this.removeEventsListeners();
    }
    this.layerOffset = [1, 3];
    this.blockVoteActions = false;

    this.voting_blocks = GUI.Dom.findDescedents(document.body, 'div.vote_block');

    if (!this.voting_blocks || !this.voting_blocks.length) {
        return;
    }

    this.addEventsListeners();

    this.voteRegion = new GUI.Popup.Region({
        className: 'layer selected',
        id: 'vote-layer'
    });

    this.layers = new GUI.Popup.Layer({
        target      : document.body,
        layer       : this.voteRegion,

        offset      : [1, 6],
        position    : 'tr-br?',

        disableProcessEvent: {
            onMouseEnter: true,
            onMouseLeave: true
        }
    });

    this.layers.on('onMouseMove', this.onMouseMove, this);
};

VotingController.onMouseMove = function (e) {
    var target = e.target,
        el =
            GUI.findParentByTag(target, 'div', null, 'vote_block') ||
                GUI.findParentByTag(target, 'div', null, 'layer');

    if (this.layers.permanentShow) {
        return;
    }

    if (el) {
        var popupEl,
            blockId_ = el.id.split('_'),
            type = blockId_[1],
            id = blockId_[2];


        popupEl = GUI.$('popup-hover_' + type + '_' + id);

        if (!popupEl || !popupEl.innerHTML) {
            return;
        }

        this.layers.showLayer();

        this.layers.setPosition(null, el);
        this.voteRegion.setContent(popupEl.innerHTML);
        this.voteRegion.dom.style.zIndex = 0;
        this.hoverEl = popupEl;
    } else if (
        this.voteRegion.dom &&
        GUI.Dom.isDescendant(this.voteRegion.dom, e.target)
    ) {
        this.layers.stopTimerForHideLayer();
    } else if (this.hoverEl) {
        this.hoverEl = null;
        this.layers.startTimerForHideLayer();
    }
};

VotingController.hideLayerOnClick = function (e) {
    if (
        this.layers.permanentShow &&
        this.voteRegion.dom &&
        !GUI.Dom.isDescendant(this.voteRegion.dom, e.target)
    ) {
        this.hideVoteArea(this.voteObj.dom);
    }
};

/**
 *
 */
VotingController.addEventsListeners = function () {
    var i, block,
        blocks = this.voting_blocks;

    for (i = 0; i < blocks.length; i++) {
        block = blocks[i];

        if (!block.getAttribute("data-disabled")) {
            block.on('click', this.onClickVoteBlock.bindLegacy(this, block));
            block.on('touchend', this.onClickVoteBlock.bindLegacy(this, block));
        }
    }

    Application.addEventForDocument('click', this.hideLayerOnClick.bindLegacy(this));
    Application.addEventForDocument('touchend', this.hideLayerOnClick.bindLegacy(this));
};

/**
 *
 */
VotingController.removeEventsListeners = function () {
    var i, block,
        blocks = this.voting_blocks;

    for (i = 0; i < blocks.length; i++) {
        block = blocks[i];
        if (!block.getAttribute("data-disabled")) {
            block.un('click', this.onClickVoteBlock.bindLegacy(this, block));
        }
    }

    this.voting_blocks = null;
};

/**
 *
 */
VotingController.onClickVoteBlock = function (block, e) {
    e = GUI.Event.extend(e);
    e.stop();
    e.stopPropagation();

    var blockId_, actionUrl,
        voteObj = {},
        blockId = block.id;

    if (this.voteObj) {
        this.hideVoteArea(this.voteObj.dom);
    }

    blockId_ = blockId.split('_');

    actionUrl = GUI.Dom.findDescedent(block, 'a');
    actionUrl.href = actionUrl.getAttribute('data-href');
    if ('disabled' === actionUrl.getAttribute('disabled')) {
        return;
    }

    if (actionUrl.id.search('vote_action') !== -1 || actionUrl.href !== '#') {
        actionUrl = actionUrl.href;
    }

    voteObj.dom = block;
    voteObj.type = blockId_[0];
    voteObj.voting_type = blockId_[1];
    voteObj.itemId = blockId_[2];
    voteObj.use_area = blockId_[3] !== 'noarea';
    voteObj.action = actionUrl;
    this.voteObj = voteObj;

    if (block.parentNode && block.parentNode.getAttribute('votingType') === 'ajax') {
        this.voteType = 'ajax';
    } else {
        this.voteType = 'link';
    }

    if (voteObj.type === 'item') {
        if (voteObj.voting_type === 'support' || voteObj.use_area === false) {
            this.voteAction(voteObj);
        } else if (voteObj.voting_type === 'custom-positive-negative'
                || voteObj.voting_type === 'custom-positive'
                || voteObj.voting_type === 'support-not-support') {
            if (voteObj.action.search('unvote') !== -1) {
                this.voteAction(voteObj);
            } else {
                this.showVoteArea(voteObj);
            }
        }
    } else if (voteObj.type === 'comment') {
        if (voteObj.voting_type === 'boolean') {
            if (voteObj.action.search('unvote') !== -1) {
                this.voteAction(voteObj);
            } else {
                this.showVoteArea(voteObj);
            }
        } else if (voteObj.voting_type === 'simple') {
            this.voteAction(voteObj);
        }
    }
};

/**
 *
 */
VotingController.voteAction = function (voteObj) {
    Application.AuthPanel.authenticate(function(voteObj) {
        this.searchStr = (window.search) ?  window.search.getValue() : false;

        if (this.blockVoteActions) {
            return false;
        }
        this.blockVoteActions = true;

        if (this.voteType === 'ajax') {
            this.voteAjaxAction(voteObj);
        } else {
            window.location.href = voteObj.action;
        }
    }.bindLegacy(this, voteObj));
};

/**
 *
 */
VotingController.voteAjaxAction = function (voteObj) {
    this.hideVoteArea(this.voteBlock);

    this.blockVoteActions = true;
    new Ajax.Request(voteObj.action, {
        data        : {},
        onSuccess   : this.onVoteSuccess.bindLegacy(this),
        onFailure   : this.onVoteFail.bindLegacy(this)
    });
};

/**
 *
 */
VotingController.onVoteSuccess = function (xhr, response) {
    var str;
    response = response.responseJson;
    this.blockVoteActions = false;
    if (response) {

        if (window.search) {

            if (window.search.feedbackWidget) {
                str = this.searchStr || window.search.getValue();
                if (str.length) {
                    window.search.search();
                } else if (window.search.channel) {
                    window.search.clickButton(window.search.channel);
                }
            } else {
                str = this.searchStr || window.search.getValue();
                if (str.length) {
                    window.search.search();
                }
            }
        }
    } else {
        window.location.reload();
    }
};

/**
 *
 */
VotingController.onVoteFail = function (xhr, response) {
    if (response && response.responseText) {
        alertMessage('', response.responseText);
    }
};

/**
 *
 */
VotingController.showVoteArea = function (voteObj) {
    var popupEl = GUI.$('popup-click_' + voteObj.voting_type + '_' + voteObj.itemId);

    voteObj.dom.addClass('selected');

    this.layers.permanentShowLayer();

    this.voteRegion.setContent(popupEl.innerHTML);

    this.voteRegion.dom.setStyle({
        'min-width': '151px',
        zIndex: 0
    });

    this.layers.setPosition(null, voteObj.dom, this.layerOffset);

    this.voteRegion.dom.on('click', this.onClickPopupEl.bindLegacy(this, voteObj));
};

/**
 *
 */
VotingController.onClickPopupEl = function (voteObj, e) {
    e = GUI.Event.extend(e);
    var target = e.getTarget();

    if (target.nodeName !== 'A') {
        target = target.parentNode;
    }

    if (target.nodeName === 'A') {
        this.voteAction({
            action  : target.getAttribute('data-href'),
            type    : voteObj.type
        });
    }
};

/**
 *
 */
VotingController.hideVoteArea = function (voteElem_) {
    var i,
        voteElem = voteElem_ || {};

    if (voteElem) {
        // hide layer
        this.layers.hideLayer();

        if (voteElem.removeClass) {
            voteElem.removeClass('selected');
        }
    }
};