(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Main Menu implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.MainMenu = Class.create();
    Object.extend(GUI.MainMenu.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.MainMenu.prototype.initialize = function (config) {
        var cfg, i, j, item, id;
        // Call parent method
        superproto.initialize.call(this);

        this.config = {
            id              : GUI.getUniqId('menu-'),
            name            : 'mainmenu',
            className       : 'b-menu',
            classNameList   : 'b-menu__list',
            classNameItem   : 'b-menu__item',
            classNameSidebar: 'b-menu__sidebar b-menu__menu',
            /*classNameSidebarList: 'b-menu__list_no-shadow',*/
            items           : [],
            openOnHover     : false,
            mainMenu        : true,
            sidebar         : false,
            showDescription : true,
            icons           : true
        };
        cfg = this.config;
        Object.extend(cfg, config);

        this._indexById = {};
        this._indexByName = {};

        i = cfg.items.length;

        while (i--) {
            item = cfg.items[i];
            id = GUI.getUniqId('mainmenu-item-');
            item.id = id;
            this._indexById[id] = i;
            if (item.name) {
                this._indexByName[item.name] = item;
            }

            if (item.sub) {
                item.sub.openOnHover = this.config.openOnHover;
                item.sub.hideOnDocumentClick = this.config.hideOnDocumentClick;
                item.sub.mainMenu = this.config.mainMenu;
                item.sub.showDescription = this.config.showDescription;
                item.sub.icons = (item.sub.icons !== undefined ? item.sub.icons : this.config.icons);

                // menu items passed, need to create menu instance
                item.sub = new GUI.Popup.Menu(item.sub);
            }

            if (item.hoverItems) {
                var g = item.hoverItems.length;

                while(g--) {
                    if (!item.hoverItems[g].id) {
                        item.hoverItems[g].id = GUI.getUniqId('mainmenu-item-');
                    }
                }
            }
        }

        // create sidebar sub menu
        j = (cfg.sidebar && cfg.sidebar.items) ? cfg.sidebar.items.length : 0;
        while (j--) {
            item = cfg.sidebar.items[j];
            id = GUI.getUniqId('mainmenu-sidebar-item-');
            item.id = id;

            if (item.name) {
                this._indexByName[item.name] = item;
            }

            if (item.sub) {
                item.sub.openOnHover = this.config.openOnHover;
                item.sub.hideOnDocumentClick = this.config.hideOnDocumentClick;
                item.sub.mainMenu = this.config.mainMenu;
                item.sub.showDescription = this.config.showDescription;
                //item.sub.icons = this.config.icons;

                // menu items passed, need to create menu instance
                item.sub = new GUI.Popup.Menu(item.sub);
            }

            if (item.hoverItems) {
                var g = item.hoverItems.length;

                while(g--) {
                    if (!item.hoverItems[g].id) {
                        item.hoverItems[g].id = GUI.getUniqId('mainmenu-item-');
                    }
                }
            }
        }

        this.addEvents({
            click               : true,
            beforeClickMainMenu : true,
            changeLayout        : true
        });

        if (GUI.isFunction(cfg.onClick)) {
            this.on('click', cfg.onClick);
        }

    };

    /**
     * Renders objects
     * @param {HTMLElement} to Element to render object to
     */
    GUI.MainMenu.prototype.render = function (to) {
        var div,
            cfg = this.config,
            o = [];

        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
        this.visible = false;

        div = document.createElement('DIV');
        div.className = cfg.className;

        o.push('<div class="b-menu__main b-menu__menu">');
        
        // avatar
        if (cfg.avatar) {
            o.push(this.renderAvatar());
        }

        o.push(this.renderItems(cfg.items));

        o.push('</div>');
        
        // right sidebar
        if (cfg.sidebar) {
            o.push('<div class="' + cfg.classNameSidebar + '">');

            o.push(this.renderItems(cfg.sidebar.items, cfg.classNameSidebarList));
            
            o.push('</div>');
        }


        GUI.$(to).appendChild(div);
        div.innerHTML = o.join('');

        this.dom = GUI.$(to);
        this.attachEventListeners();
    };


    /**
     *
     */
    GUI.MainMenu.prototype.renderAvatar = function () {
        return '<div class="b-menu__avatar">' +
            '<img src="' + (this.config.avatar || '') + '" name="avatar" />' +
            '</div>';
    };

    /**
     * Renders items of the menu
     * @param {Array} items
     * @param {Array} list of the css classes
     * @param {String} css class of the item
     */
    GUI.MainMenu.prototype.renderItems = function (items, classList, classItem) {
        var i, id, len, item, o = [], imgClass = '';
        classList = classList || '';
        classItem = classItem || '';

        o.push('<ul class="' + this.config.classNameList + ' ' + classList + '">');
        for (i = 0, len = items.length; i < len; i++) {
            item = items[i];
            item.id  = id = item.id || GUI.getUniqId('mainmenu-item-');

            imgClass = '';
            if (item.icon || item.iconTpl) {
                imgClass = ' b-menu__item_img b-menu__item_avatar';
            }

            if (!item.className) {
                item.className = '';
            }

            if (!item.hint) {
                item.hint = '';
            } else {
                item.hint = ' hint="' + item.hint.replace(/"/g, '&quot;') + '" ';
            }

            o.push('<li class="b-menu__node b-menu__node_hide">');
            
            o.push('<div class="' + this.config.classNameItem + ' ' + classItem + imgClass + ' ' + item.className +'" hintposition="right" name="' + item.name + '" ' + item.hint);
            o.push(' id="' + id + '" ');

            if (item.hidden) {
                o.push(' style="display: none;"');
            }
            if (item.title) {
                o.push(' title="' + item.title + '"');
            }
            o.push('>');

            if (item.icon) {
                o.push('<img name="' + item.name + '" src="' + item.icon + '" />');
            }
            if (item.iconTpl) {
                o.push(item.iconTpl);
            }
            if (item.iconClass) {
                o.push('<i class="b-menu__item__icon ' + item.iconClass + '"></i>');
            }
            if (item.render) {
                o.push(item.render());
            }
            if (item.caption) {
                o.push('<span class="b-menu__item__label">' + item.caption + '</span>');
            }

            o.push('</div>');

            if (item.hoverItems) {
                o.push('<ul class="b-menu__subList b-menu__subList_' + (item.hoverItems.length) + '">');

                var x, lenHover, hoverItem;
                for (x = 0; x < item.hoverItems.length; x++) {
                    hoverItem = item.hoverItems[x];
                    var xid = hoverItem.id || GUI.getUniqId('mainmenu-item-');

                    o.push('<li class="b-menu__subList__item"');

                    if (hoverItem.hint) {
                        o.push(' hint="' +  hoverItem.hint + '"');
                    }

                    if (hoverItem.title) {
                        o.push(' title="' +  hoverItem.title + '"');
                    }

                    o.push('>');
                    o.push('<div ');
                    o.push('name="' + hoverItem.name + '" ');
                    o.push('class="b-menu__item" id="' + xid + '">');

                    if (hoverItem.icon) {
                        o.push('<img name="' + hoverItem.name + '" src="' + hoverItem.icon + '" />');
                    }
                    if (hoverItem.iconClass) {
                        o.push('<i class="b-menu__item__icon ' + hoverItem.iconClass + '"></i>');
                    }
                    if (hoverItem.caption) {
                        o.push(hoverItem.caption);
                    }

                    o.push('</div>');
                    o.push('</li>');
                }

                o.push('</ul>');
            }

            o.push('</li>');
        }
        o.push('</ul>');
        return o.join('');
    };

    /**
     * Returns item by name
     * @param {String} name Name of the item
     * @returns {Object} item
     */
    GUI.MainMenu.prototype.getItemByName = function (name) {
        return this._indexByName[name];
    };

    /**
     * Hides item by name
     * @param {String} name Name of th eitem
     */
    GUI.MainMenu.prototype.hideItem = function (name) {
        var elem,
            item = this.getItemByName(name);

        if (item) {
            item.hidden = true;
            if (this.dom) {
                elem = GUI.$(item.id);
                GUI.hide(elem);
            }
        }
    };

    /**
     * Shows item by name
     * @param {String} name Name of the item
     */
    GUI.MainMenu.prototype.showItem = function (name) {
        var elem,
            item = this.getItemByName(name);

        if (item) {
            item.hidden = false;
            if (this.dom) {
                elem = GUI.$(item.id);
                GUI.show(elem);
            }
        }
    };

    /**
     * Shows sub menu by item id
     * @param {String|Number} itemId
     */
    GUI.MainMenu.prototype.showSubMenu = function (itemId) {
        var itemNode = GUI.$(itemId),
            name = itemNode.getAttribute('name'),
            item = this.getItemById(itemId) || this.getSidebarItem(name),
            sub = item.sub,
            offset = [0, 0];

        if (this.subMenu && (this.subMenu !== sub)) {
            this.hideSubMenu();
        }

        var alignTo = 'tr-tl?';
        if (item.sidebar && GUI.LTR) {
            alignTo = 'bl-br';
        } else if (item.sidebar && GUI.LTR === false) {
            alignTo = 'br-bl';
        }

        sub.alignTo(itemNode, alignTo, [0, 0]);

        sub.show(this, itemId);
        this.subMenu = sub;
        this.activeItem = itemId;
        itemNode.addClass('active');

    };

    /**
     * Hides current sub menu
     */
    GUI.MainMenu.prototype.hideSubMenu = function () {
        if (this.subMenu) {
            this.subMenu.hide();
            this.subMenu = null;
        }
    };

    /**
     * empty function
     */
    GUI.MainMenu.prototype.hideParent = function () {};

    /**
     * Handler child elements hiding
     * @param {Object} menu Object of the menu
     */
    GUI.MainMenu.prototype.onChildHide = function (menu) {
        if (this.activeItem) {
            GUI.$(this.activeItem).removeClass('active');
            this.activeItem = null;
        }
        if (this.selectedSidebarItem) {
            if (this.selectedSidebarItem.parent) {
                GUI.$(this.getItemByName(this.selectedSidebarItem.parent).id).addClass('selected');
            } else {
                GUI.$(this.selectedSidebarItem.id).removeClass('selected');
            }
        }
    };

    /**
     * Returns menu
     * @returns {Object} menu
     */
    GUI.MainMenu.prototype.getRootMenu = function () {
        return this;
    };

    /**
     * Sets the visibility of the description
     * @param {Boolean} val
     */
    GUI.MainMenu.prototype.setShowDescription = function (val) {
        this.config.showDescription = val;
    };

    /**
     * Returns item by id
     * @param {String|Number} id
     * @returns {Object} item
     */
    GUI.MainMenu.prototype.getItemById = function (id) {
        var index = this._indexById[id];
        if (GUI.isNumber(index)) {
            return this.config.items[this._indexById[id]];
        }
        return false;
    };

    /**
     * Returns item by id
     * @param {String|Number} id
     * @returns {Object} item
     */
    GUI.MainMenu.prototype.getSidebarItem = function (name) {
        var item,
            items = this.config.sidebar.items;

        if (GUI.isString(name)) {
            for (item in items) {
                if (items[item].name === name) {
                    items[item].sidebar = true;
                    return items[item];
                }
            }
        }
        return false;
    };

    /**
     * Returns item by id
     * @param {String|Number} id
     * @returns {Object} item
     */
    GUI.MainMenu.prototype.getSidebarId = function (id) {
        var item,
            items = this.config.sidebar.items;

        if (GUI.isString(id)) {
            for (item in items) {
                if (items[item].id === id) {
                    items[item].sidebar = true;
                    return items[item];
                }
            }
        }
        return false;
    };

    /**
     * Attach events listeners
     */
    GUI.MainMenu.prototype.attachEventListeners = function () {
        GUI.Dom.extend(this.dom);
        this.dom.on('click', this.menuClickHandler, this);
        this.dom.on('mouseover', this.mouseOverHandler, this);
        this.dom.on('mouseout', this.mouseOutHandler, this);
    };

    /**
     * Removes events listeners
     */
    GUI.MainMenu.prototype.removeEventListeners = function () {
        this.dom.un();
    };

    /**
     * If sub menu it will be shows, call hanldler of the item if it is exists
     * @param {Event} e Event
     */
    GUI.MainMenu.prototype.menuClickHandler = function (e) {
        e = new GUI.ExtendedEvent(e);

        var item,
            cfg = this.config,
            itemEl = e.getTarget().name === 'logo' ? e.getTarget() : GUI.findParentByClassName(e.getTarget(), this.dom, cfg.classNameItem);

        if (!itemEl) {
            return;
        }

        item = this.getItemById(itemEl.id);
        item = item || this.getSidebarItem(itemEl.getAttribute('name'));

        if (!item) {
            try {
                var parentId = jQuery(itemEl).parents('li.b-menu__node').children('div').attr('id');

                var targetItems = this.getItemById(parentId) || this.getSidebarId(parentId);

                _.each(targetItems.hoverItems, function (hoverItem) {
                    if (hoverItem.id === itemEl.id) {
                        item = hoverItem;
                    }
                });
            } catch (e) {
                item = false;
            }
        }

        if (!e.isOpenInNewWindow() && !this.fireEvent('beforeClickMainMenu', this, item, e)) {
            return;
        }

        if (itemEl.name) {
            if (itemEl.name === 'logo' && typeof cfg.logoAction === 'function') {
                cfg.logoAction();
                return;
            }
            if (itemEl.name === 'avatar' && typeof cfg.avatarAction === 'function') {
                cfg.avatarAction();
                return;
            }
        }

        if (item) {
            e.stop();
        }

        if (!e.isOpenInNewWindow() && !item.sub) {
            this.setSelectedItem(item, true);
        }

        if (item.sub && !this.config.openOnHover) {
            this.showSubMenu(item.id);
        } else {
            if (GUI.isFunction(item.handler)) {
                item.handler(item);
            }
        }

        if (!item.hoverItems) {
            jQuery('#' + item.id).parents('.b-menu__node').addClass('b-menu__node_hide');
        }

        this.fireEvent('click', this, item, e);
    };

    /**
     *
     */
    GUI.MainMenu.prototype.setSelectedItem = function (item, isClick) {
        var item_ = item;
        this.fireEvent('changeLayout', !!isClick);

        // Reset menu selected for dashboard and others undefined items
        if (item == undefined && this.selectedItem) {
            var selectedElement = GUI.$(this.selectedItem.id);

            if (selectedElement) {
                selectedElement.removeClass('selected');
            }
            this.selectedItem = null;
            return;
        }

        // the same
        if (!item_ || this.selectedItem === item) { //  || this.getSidebarItem(item_.name)
            return;
        }

        if (item.noSelect !== true) {
            // remove selected class
            jQuery(this.dom).find('.selected').removeClass('selected');

            // set selected item
            if (item.sidebar) {
                this.selectedSidebarItem = item_;
                this.selectedItem = null;
            } else {
                this.prevSelectedItem = this.selectedItem;
                this.selectedItem = item_;
                this.selectedSidebarItem = null;
            }

            // add selected class
            if (item.parent) {
                GUI.$(this.getItemByName(item.parent).id).addClass('selected');
            } else {
                GUI.$(item_.id).addClass('selected');
            }
        }
    };

    /**
     * Revert selected item to previous without events and triggers
     */
    GUI.MainMenu.prototype.revertSelectedItem = function () {
        this.setSelectedItem(this.prevSelectedItem, true);
    };

    /**
     * If we are over item, that has submenu, show it
     * @param {Event} e Event
     */
    GUI.MainMenu.prototype.mouseOverHandler = function (e) {
        e = GUI.Event.extend(e);
        var item,
            itemEl = e.target;

        if (itemEl && e.isMouseEnter(itemEl)) {
            item = this.getItemById(itemEl.id);

            if (item.sub) {
                if (!item.sub.isVisible() && this.config.openOnHover) {
                    this.showSubMenu(itemEl.id);
                }
            }
        }

        if (itemEl) {
            jQuery(itemEl).parents('.b-menu__node').removeClass('b-menu__node_hide');
        }
    };

    /**
     * If we have opened submenu and we did not moved to it, hide submenu
     * @param {Event} e Event
     */
    GUI.MainMenu.prototype.mouseOutHandler = function (e) {
        e = GUI.Event.extend(e);
        var relatedTarget,
            itemEl = e.target;

        if (itemEl && e.isMouseLeave(itemEl)) {
            if (this.subMenu && this.subMenu.dom) {
                // if we have opened submenu
                relatedTarget = e.getRelatedTarget();
                if (!GUI.contains(this.subMenu.dom, relatedTarget) && this.config.openOnHover) {
                    // and we did not moved to it, hide submenu
                    this.hideSubMenu();
                }
            }
        }

        if (itemEl) {
            jQuery(itemEl).parents('.b-menu__node').addClass('b-menu__node_hide');
        }
    };

    /**
     * Returns dom
     * @returns {HTMLElement} dom
     */
    GUI.MainMenu.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Sets variable 'openOnHover' to passed value
     * @param {Boolean} val
     */
    GUI.MainMenu.prototype.setOpenOnHover = function (val) {
        this.config.openOnHover = val;
        this.setPropertyItems(this.config.items, val, 'openOnHover');
    };

    /**
     * Sets variable 'hideOnDocumentClick' to passed value
     * @param {Boolean} val
     */
    GUI.MainMenu.prototype.setHideOnDocumentClick = function (val) {
        this.config.hideOnDocumentClick = val;
        this.setPropertyItems(this.config.items, val, 'hideOnDocumentClick');
    };

    /**
     * Sets properties of the items
     * @param {Array} items
     * @param {String|Number|Object} val
     * @param {String} property
     */
    GUI.MainMenu.prototype.setPropertyItems = function (items, val, property) {
        var item,
            i = items.length;

        while (i--) {
            item = items[i];
            if (item.sub) {
                item.sub.config[property] = val;
                if (item.sub.config.items && item.sub.config.items.length > 0) {
                    this.setPropertyItems(item.sub.config.items, val, property);
                }
            }
        }
    };

    GUI.MainMenu.prototype.setBadge = function (itemName, badgeText) {
        badgeText = badgeText > 99 ? '99+' : badgeText

        var template = '<span class="b-badge b-badge_warning b-badge_rightTop">' + badgeText + '</span>',
            item     = this.getItemByName(itemName);

        if (!badgeText) {
            jQuery("#" + item.id).find(".b-badge").remove();
            return;
        }

        if (jQuery("#" + item.id + ' .b-badge').length) {
            jQuery("#" + item.id + ' .b-badge').html(badgeText);
        } else {
            jQuery("#" + item.id).append(template);
        }
    }

}());
