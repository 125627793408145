(function () {
    var superproto = GUI.Element.prototype;

    /**
    * JavaScript Graphical User Interface
    * GUI.ToolBar.Spacer implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.ToolBar
    * @extends GUI.Element
    */
    GUI.ToolBar.Spacer = Class.create();
    Object.extend(GUI.ToolBar.Spacer.prototype, superproto);

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.ToolBar.Spacer.prototype.initialize = function (config) {
        // Call parent initialize meethod
        superproto.initialize.call(this, config);

        // Extend parent config
        Object.extend(this.config, {
            id  : GUI.getUniqId('toolbar-spacer-')
        });
        Object.extend(this.config, config);

        this.elementType = 'GUI.ToolBar.Spacer';
    };

    /**
     * Renders DOM tree and sets this.dom to root of this tree
     * @param {HTMLElement} to DOMNode object
     */
    GUI.ToolBar.Spacer.prototype._render = function (to) {
        if (!to) {
            throw new Error('Can not render to:' + to);
        }
        to.width = '100%';
    };

}());
