(function () {

    if (!GUI.Forms.Plugins) {
        GUI.Forms.Plugins = {};
    }

    /**
     * JavaScript Graphical User Interface
     * EditorImageManager plugin implementation
     * Table of Content
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms.Plugins
     */
    GUI.Forms.Plugins.EditorToc = Class.create();

    /**
     * Dialog content
     * @type String
     */
    GUI.Forms.Plugins.EditorToc.prototype.dialogContent = { content:
        '<form id="x-editor-tocdialog-form" class="x-editor-tocdialog-form" style="padding: 5px 5px 0; margin: 0; line-height: 1.5;">' +
            '<table class="x-editor-toc" cellspacing="8">' +
                '<tbody>' +
                    '<tr valign="top">' +
                        '<td>' +
                            '<label>' + i18n(GUI.i18n.GUI_EDITOR_SHOW_LEVELS) + ':</label>' +
                        '</td>' +
                        '<td>' +
                            '<input id="x-editor-toc-1level" type="text" xtype="spin" name="levels" value="3" min="1" max="6" style="width:40px;" />' +
                        '</td>' +
                    '</tr>' +
                    '<tr valign="top">' +
                        '<td>' +
                            '<label>' + i18n(GUI.i18n.GUI_EDITOR_TYPE) + ':</label>' +
                        '</td>' +
                        '<td>' +
                            '<label><input type="radio" name="type" value="numbered" checked="checked" />&nbsp;' + i18n(GUI.i18n.GUI_EDITOR_NUMBERED) + '</label><br/>' +
                            '<label><input type="radio" name="type" value="markered" />&nbsp;' + i18n(GUI.i18n.GUI_EDITOR_MARKERED) + '</label>' +
                        '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td colspan="2">' +
                            '<label><input type="checkbox" name="makeLinks" checked="checked" />&nbsp;' + i18n(GUI.i18n.GUI_EDITOR_GENERATE_LINKS) + '</label><br/>' +
                        '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td colspan="2" id="x-editor-tocdialog-toolbar" style="padding-top: 8px;" ></td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</form>'};

    /**
     * Constructor
     * @param {Object} cfg Configuration object
     */
    GUI.Forms.Plugins.EditorToc.prototype.initialize = function (cfg) {

    };

    /**
     * Called by Editor while initializing, add event 'createToolbar'
     * @param {Object} editor Editor
     */
    GUI.Forms.Plugins.EditorToc.prototype.init = function (editor) {
        this.editor = editor;
        editor.on('createToolbar', this.onCreateToolbar, this);
    };

    /**
     * Called by Editor while destroying.
     * Destroy dialog, editor, remove event 'createToolbar'
     */
    GUI.Forms.Plugins.EditorToc.prototype.destroy = function () {
        if (this.dialog) {
            this.dialog.destroy();
            this.dialog = null;
        }
        this.editor.un('createToolbar', this.onCreateToolbar, this);
        this.editor = null;
    };

    /**
     * Called by Editor before rendering toolbars. Adds three buttons
     * to the "toc" toolbar
     */
    GUI.Forms.Plugins.EditorToc.prototype.onCreateToolbar = function () {
        var btn1, btn2,
            tb = this.editor.toolbars.toc,
            createMode = false;

        if (!tb) {
            tb = this.editor.toolbars.toc = new GUI.ToolBar({
                align: 'none'
            });
            createMode = true;
        }

        btn1 = new GUI.ToolBar.Button({
            name        : 'inserttoc',
            clickEvent  : 'mousedown',//'click',
            caption     : i18n(GUI.i18n.GUI_EDITOR_INSERT_TOC),
            hint        : i18n(GUI.i18n.GUI_EDITOR_INSERT_TOC),
            iconClass   : 'x-editor-button-toc',
            listeners   : {
                scope : this,
                click : this.onInsertTocClick
            }
        });

        btn2 = new GUI.ToolBar.Button({
            name        : 'edittoc',
            clickEvent  : 'mousedown',
            caption     : i18n(GUI.i18n.GUI_EDITOR_EDIT_TOC),
            hint        : i18n(GUI.i18n.GUI_EDITOR_EDIT_TOC),
            iconClass   : 'x-editor-button-toc',
            listeners   : {
                scope : this,
                click : this.onEditTocClick
            }
        });

        if (createMode) {
            tb.add(0, btn1);
            tb.add(1, btn2);
        } else {
            tb.insert(-1, 0, btn2);
            tb.insert(-1, 0, btn1);
        }

        if (createMode) {
            tb.add(null, new GUI.ToolBar.Separator());
            this.editor.renderToolbars.splice(8, 0, 'toc');
        }
    };

    /**
     * Handles click on the 'Insert Toc' button on the editor's toolbar.
     * @param {Object} btn Button
     */
    GUI.Forms.Plugins.EditorToc.prototype.onInsertTocClick = function (btn) {
        this.editor.saveRange();
        this.editMode = false;
        this.dialog = this.createDialog();
        this.dialog.deferShow();
    };

    /**
     * Handles click on the 'Edit Toc' button on the editor's toolbar
     * @param {Object} btn Button
     */
    GUI.Forms.Plugins.EditorToc.prototype.onEditTocClick = function (btn) {
        this.editor.saveRange();
        var toc = this.getSelectedToc();
        if (toc) {
            this.editMode = true;
            this.editedNode = toc;
            this.dialog = this.createDialog();
            this.dialog.deferShow();
        }
    };

    /**
     * Creates dialog, toolbar, form
     * @returns {GUI.Popup.Dialog}
     */
    GUI.Forms.Plugins.EditorToc.prototype.createDialog = function () {
        var dlg, tb, form,
            dialogContent = this.dialogContent,
            option = {};

        dlg = new GUI.Popup.Dialog({
            id          : 'jsgui-editor-tocdialog',
            title       : this.editMode ? i18n(GUI.i18n.GUI_EDITOR_EDIT_TOC) : i18n(GUI.i18n.GUI_EDITOR_INSERT_TOC),
            content     : dialogContent,
            modal       : true,
            alwaysOnTop : true,
            toolbuttons : [{name: 'close',  img: JSGUI_IMAGES_PATH + 'x-dialog/x-dialog-ico-close.gif'}]
        });

        dlg.create();

        tb = new GUI.ToolBar({
            className   : 'x-toolbar form-buttons',
            align       : 'center',
            elements    : [{
                obj: new GUI.ToolBar.Button({
                    xtype   : 'OK',
                    width   : 60,
                    listeners: {
                        scope: this,
                        click: function () {
                            if (this.editMode) {
                                this.updateToc();
                                this.dialog.close();
                            } else {
                                res = this.insertToc();
                                this.dialog.close();
                            }
                        }
                    }
                })
            }, {
                obj: new GUI.ToolBar.Button({
                    xtype   : 'CANCEL',
                    width   : 60,
                    listeners: {
                        click: function () {
                            dlg.close();
                        }
                    }
                })
            }]
        });
        tb.render('x-editor-tocdialog-toolbar');
        dlg.toolbar = tb;

        form = new GUI.Forms.Form({
            assignTo    : 'x-editor-tocdialog-form',
            listeners   : {
                beforesubmit: function () { return false; }
            }
        });
        dlg.form = form;

        if (this.editMode) { // try to determine settings
            if (this.editedNode.nodeName === 'UL') {
                option.type = 'markered';
            } else if (this.editedNode.nodeName === 'OL') {
                option.type = 'numbered';
            }

            if (this.editedNode.getElementsByTagName('a').length > 0) {
                option.makeLinks = true;
            } else {
                option.makeLinks = false;
            }

            // Levels can not be detected
            form.setFieldsValues(option);
        }
        return dlg;
    };

    /**
     * Returns selected toc
     */
    GUI.Forms.Plugins.EditorToc.prototype.getSelectedToc = function () {
        var sel = this.editor.getSelectionBounds(),
            node;

        if (sel && sel.root) {
            node = sel.root;
            while (node) {
                if ((node.nodeName === 'UL' || node.nodeName === 'OL')
                        && GUI.Dom.hasClass(node, 'jsgui-wysiwyg-toc', true)) {
                    return node;
                }
                node = node.parentNode;
            }
        }
        return null;
    };

    /**
     * Create toc, select node in the editor, update textarea
     */
    GUI.Forms.Plugins.EditorToc.prototype.updateToc = function () {
        var toc = this._createToc();
        this.editedNode.parentNode.replaceChild(toc, this.editedNode);
        this.editor.selectNode(toc);
        this.editor.updateTextarea();
    };

    /**
     * Insert toc, select node in th editor
     */
    GUI.Forms.Plugins.EditorToc.prototype.insertToc = function () {
        var toc = this._createToc();
        this.editor.pasteNode(toc);
        if (!GUI.isIE) {
            this.editor.selectNode(toc);
        }
    };

    /**
     * Create toc
     * @returns {HTMLElement} toc
     */
    GUI.Forms.Plugins.EditorToc.prototype._createToc = function () {
        var i, j, toc, allTags, len, h, tag, code, level, li,
            options     = this.dialog.form.getValues(),
            d           = this.editor.iDoc,
            tocTag      = 'ol',
            levels      = parseInt(options.levels, 10),
            makeLinks   = !!options.makeLinks,
            type        = options.type,
            idPrefix    = '';

        if (type === 'numbered') {
            tocTag = 'ol';
        } else if (type === 'markered') {
            tocTag = 'ul';
        }

        toc = d.createElement(tocTag);
        toc.className = 'jsgui-wysiwyg-toc';

        allTags = d.body.getElementsByTagName('*'); // grab all tags
        len = allTags.length;

        if (makeLinks) {
            idPrefix = 'jsgui-autotocid-' + (new Date() - 0) + '-';
        }

        for (i = 0; i < len; i++) {
            h = allTags[i];
            tag = h.nodeName;
            if (tag.charAt(0) === 'H') {
                code = tag.charCodeAt(1);
                if ((code >= 48) && (code <= 57)) {
                    // found heading
                    level = parseInt(tag.charAt(1), 10);
                    if (level <= levels) {
                        // add to toc
                        li = d.createElement('li');

                        if (makeLinks) {
                            if (!h.id) {
                                h.id = idPrefix + i;
                            }
                            li.innerHTML = '<a href="#' + h.id + '">' + h.innerHTML + '</a>';
                        } else {
                            li.innerHTML = h.innerHTML;
                        }
                        toc.appendChild(li);
                    }
                }
            }
        }
        return toc;
    };

}());