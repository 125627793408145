(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * ColorPanel implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.ColorPanel = Class.create();
    Object.extend(GUI.ColorPanel.prototype, superproto);

    /**
     * Css class of the element, default is ''
     * @type String
     */
    GUI.ColorPanel.prototype.cls = '';

    /**
     * Default is true
     * @type Boolean
     */
    GUI.ColorPanel.prototype.visible = true;

    /**
     * Holder element
     * @type String
     */
    GUI.ColorPanel.prototype.holder = null;

    /**
     * Value
     * @type String
     */
    GUI.ColorPanel.prototype.value = null;

    /**
     * Css style
     * @type String
     */
    GUI.ColorPanel.prototype.style = null;

    /**
     * Event on the click, default is 'click'
     * @type String
     */
    GUI.ColorPanel.prototype.clickEvent = 'click';

    /**
     * Num of the columns, default is 10
     * @type Number
     */
    GUI.ColorPanel.prototype.columns = 10;

    /**
     * Regexp for rgb color, defaul's /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i
     * @type Regexp
     */
    GUI.ColorPanel.prototype.rgbRe = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i;

    /**
     * Regexp for hex color, default is  /^\#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/
     * @type Regexp
     */
    GUI.ColorPanel.prototype.hexRe = /^\#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

    /**
     * Template
     * @type String
     */
    GUI.ColorPanel.prototype.template =
        '<div class="b-colorpanel" id="<%=data.phId%>">' +
        '<table class="b-colorpanel__table default-colors" cellpadding="1" cellspacing="1">' +
        '<tr>' +
        '<% for (var i=0, len=data.columns; i<len; i++) { %>' +
        '<td class="b-colorpanel__cell" style="background-color: #<%=data.themeColors[i] %>;"></td>' +
        '<% } %>' +
        '</tr>' +
        '</table>' +
        '<table class="b-colorpanel__table default-colors" cellpadding="1" cellspacing="1">' +
        '<% for (var i = 0, j, columns, index, rows = Math.ceil(data.themeColors.length / data.columns)-1; i < rows; i++) { ' +
        'columns = data.columns; %>' +
        '<tr>' +
        '<% for (j = 0; j < columns; j++) {' +
        'index = i + 1 + "" + j; %>' +
        '<td  class="b-colorpanel__cell" ' +
        ' style="background-color: #<%=data.themeColors[index] %>;"></td>' +
        '<% } %>' +
        '</tr>' +
        '<% } %>' +
        '</table>' +
        '<table class="b-colorpanel__table" cellpadding="1" cellspacing="1">' +
        '<tr>' +
        '<% for (var i = 0, len = data.columns; i < len; i++) { %>' +
        '<td class="b-colorpanel__cell" style="background-color: #<%=data.defaultColors[i] %>;"></td>' +
        '<% } %>' +
        '</tr>' +
        '</table>' +
        '</div>';

    /**
     * Type of the element, default is 'GUI.ColorPanel'
     * @type String
     */
    GUI.ColorPanel.prototype.elementType = 'GUI.ColorPanel';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.ColorPanel.prototype.initialize = function (config) {
        // 60 colours
        this.themeColors = [
            'ffffff', '000000', 'eeece1', '1f497d', '4f81bd', 'c0504d', '9bbb59', '8064a2', '4bacc6', 'fff000',
            'f2f2f2', '7f7f7f', 'ddd9c3', 'c6d9f0', 'dbe5f1', 'f2dcdb', 'ebf1dd', 'e5e0ec', 'dbeef3', 'fdeada',
            'd8d8d8', '595959', 'c4bd97', '8db3e2', 'b8cce4', 'e5b9b7', 'd7e3bc', 'ccc1d9', 'b7dde8', 'fbd5b5',
            'bfbfbf', '3f3f3f', '938953', '548dd4', '95b3d7', 'd99694', 'c3d69b', 'b2a2c7', '92cddc', 'fac08f',
            'a5a5a5', '262626', '494429', '17365d', '366092', '953734', '76923c', '5f497a', '31859b', 'e36c09',
            '7f7f7f', '0c0c0c', '1d1b10', '0f243e', '244061', '632423', '4f6128', '3f3151', '205867', '974806'
        ];

        // 10 colours
        this.defaultColors = ['c00000', 'f00000', 'ffc000', 'ff0000',
            '92d050', '00b050', '00b0f0', '0070c0', '002060', '7030a0'];

        this.id = GUI.getUniqId('colorpanel-');

        this.addEvents({
            select: true
        });

        if (config) {
            Object.extend(this, config);
        }

        // Call parent method
        superproto.initialize.call(this);

        this.phId = this.id + '-ph';

    };

    /**
     * Destroys objects
     */
    GUI.ColorPanel.prototype.destroy = function () {
        this.purgeListeners();

        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = this.phEl = null;
        }
    };

    /**
     * Renders element, attach events listeners
     * @param {HTMLElement} to Element to render dom to
     */
    GUI.ColorPanel.prototype.render = function (to) {
        if (this.dom) {
            this.unrender();
        }

        var html = GUI.Template(this.template, {
            data : this,
            i18n : GUI.i18n
        }, this.elementType);

        this.dom = GUI.Dom.append(GUI.$(to), html);
        GUI.Dom.extend(this.dom);
        this.phEl = GUI.$(this.phId);

        if (!this.visible) {
            GUI.hide(this.dom);
        }
        this.attachEventListeners();
    };

    /**
     * Removes events listeners, destroys dom
     */
    GUI.ColorPanel.prototype.unrender = function () {
        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = this.phEl = null;
        }
    };

    /**
     * Shows dom, sets visible to true
     */
    GUI.ColorPanel.prototype.show = function () {
        if (!this.visible) {
            if (this.dom) {
                GUI.show(this.dom);
            }
            this.visible = true;
        }
    };

    /**
     * Hides dom, sets visible to false
     */
    GUI.ColorPanel.prototype.hide = function () {
        if (this.visible) {
            if (this.dom) {
                GUI.hide(this.dom);
            }
            this.visible = false;
        }
    };

    /**
     * Attach events listeners
     */
    GUI.ColorPanel.prototype.attachEventListeners = function () {
        this.phEl.on(this.clickEvent, this.colorClickHandler, this);
    };

    /**
     * Removes events listeners
     */
    GUI.ColorPanel.prototype.removeEventListeners = function () {
        this.phEl.un(this.clickEvent, this.colorClickHandler, this);
    };

    /**
     * Sets value to color
     * @param {String|Number} color
     */
    GUI.ColorPanel.prototype.setColor = function (color) {
        this.value = color;
    };

    /**
     * Converts a number to a 2 digit hex string
     * @param {Number} number
     * @returns {Number} digits
     */
    GUI.ColorPanel.prototype.toColorPart = function (number) {
        if (number > 255) {
            number = 255;
        }
        var digits = number.toString(16);
        if (number < 16) {
            return '0' + digits;
        }
        return digits;
    };

    /**
     * Parses color
     * @param {String} string Color
     * @reutrns {String} color
     */
    GUI.ColorPanel.prototype.parseColor = function (string) {
        var match, i, part,
            color = '';

        if (match = this.rgbRe.exec(string)) {
            for (i = 1; i <= 3; i++) {
                part = Math.max(0, Math.min(255, parseInt(match[i], 10)));
                color += this.toColorPart(part);
            }
            return color;
        }
        if (match = this.hexRe.exec(string)) {
            if (match[1].length === 3) {
                for (i = 0; i < 3; i++) {
                    color += match[1].charAt(i) + match[1].charAt(i);
                }
                return color;
            }
            return match[1];
        }
        return false;
    };

    /**
     * Parses color, sets color, fires event 'select'
     * @param {Event} e Event
     */
    GUI.ColorPanel.prototype.colorClickHandler = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();

        var value,
            target = e.target,
            bgColor = target.style.backgroundColor;

        if ((target.nodeName === 'TD') && bgColor) {
            value = this.parseColor(bgColor);
            if (value) {
                this.setColor(value);
                this.fireEvent('select', this, this.value);
            }
        }
    };

}());