(function () {
    var superproto = GUI.Application.prototype;

    GUI.Model = Class.create();
    Object.extend(GUI.Model.prototype, superproto);

    /**
     * Type of the module, default is 'Model'
     * @type String
     */
    GUI.Model.prototype.type = 'Model';

    /**
     * Initializes model(s)
     * @param {String|Array} config
     */
    GUI.Model.prototype.initialize = function (config) {
        Object.extend(this, config);
        superproto.create.call(this);

        this.addEvents('onLoadData', 'failLoadData');
    };

    /**
     * Returns model by the name
     * @param {String} name of the model
     */
    GUI.Model.prototype.getModel = function (name) {
        if (!GUI.isSet(name)) {
            return 'Name id undefined';
        }
        return superproto.getModule.call(this, 'Model', name);
    };

    /**
     * Returns view by the name
     * @param {String} name of the view
     * @returns {Object} view
     */
    GUI.Model.prototype.getView = function (name) {
        if (!GUI.isSet(name)) {
            return 'Name is undefined';
        }
        return superproto.getModule.call(this, 'View', name);
    };

    /**
     *
     */
    GUI.Model.prototype.getZendError = function (text) {
        if (!text) {
            return;
        }
        var errorReg = /(<title>Zend Framework Default Application<\/title>[\s]*<\/head>[\s]*<body>)([\s|\S]*)(<\/pre>[\s]*<\/body>[\s]*<\/html>)/gi,
            errorRegStart = /<title>Zend Framework Default Application<\/title>[\s]*<\/head>[\s]*<body>/gi,
            errorRegEnd = /<\/pre>[\s]*<\/body>[\s]*<\/html>/gi,
            txt;
        txt = text.match(errorReg)[0];
        txt = txt.replace(errorRegStart, '');
        txt = txt.replace(errorRegEnd, '');

        return (txt === '' ? text : txt);
    };

    /**
    *
    */
    GUI.Model.prototype.onLoadFailure = function (name, xhr, response) {
        var responseText = this.getZendError(xhr.responseText);
        // alert(responseText);
        this.fireEvent('failLoadData', xhr, responseText, this);
    };

    /**
     *
     */
    GUI.Model.prototype.onLoad = function (xhr, response) {
        var resp;

        if (response.responseJson) {
            resp = response.responseJson;
        } else {
            resp = this.getZendError(xhr.responseText);
        }
        this.fireEvent('onLoadData', xhr, resp, response, this);
    };

}());
