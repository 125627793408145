/**
 * JavaScript Graphical User Interface
 * Grid's row implementation
 *
 * @author Eugene Lyulka
 * @version 0.2
 * @namespace GUI.Grid
 */
GUI.Grid.Row = Class.create();

/**
 * Array of horizontal aligns
 * @type Object
 */
GUI.Grid.Row.prototype.hAligns = {
    left   : 'halign-l',
    center : 'halign-c',
    right  : 'halign-r'
};

/**
 * Array of vertical aligns
 * @type Object
 */
GUI.Grid.Row.prototype.vAligns = {
    top     : 'valign-t',
    middle  : 'valign-m',
    bottom  : 'valign-b'
};

/**
 * Id of the row, default is null
 * @type Number
 */
GUI.Grid.Row.prototype.id = null;

/**
 * Index of the row, default is null
 * @type Number
 */
GUI.Grid.Row.prototype.index = null;

/**
 * Object of the grid, default is null
 * @type Object
 */
GUI.Grid.Row.prototype.grid = null;

/**
 * Vertical align, default is 'middle'
 * @type String
 */
GUI.Grid.Row.prototype.valign = 'middle';

/**
 * Custom render row, default is null
 * @type Function
 */
GUI.Grid.Row.prototype.customRenderer = null;

/**
 * Visibility row, default is true
 * @type Boolean
 */
GUI.Grid.Row.prototype.visible = true;

/**
 * If true row is selected, default is false
 * @type Boolean
 */
GUI.Grid.Row.prototype.selected = false;

/**
 * If true row is rendered, default is false
 * @type Boolean
 */
GUI.Grid.Row.prototype.rendered = false;

/**
 * If true row cannot be selected, default is false
 * @type Boolean
 */
GUI.Grid.Row.prototype.noSelect = false;

/**
 * Custom css class of the row, default is ''
 * @type String
 */
GUI.Grid.Row.prototype.customRowCls = '';

/**
 * Class constuctor
 * @param {Object} config  Row init config
 */
GUI.Grid.Row.prototype.initialize = function (cfg) {
    if (cfg) {
        Object.extend(this, cfg);
    }
    this.domId = this.grid.id + '-row-' + this.id;
};

/**
 * Selects row: sets selected property to true, mark row as selected
 */
GUI.Grid.Row.prototype.select = function () {
    if (!this.selected) {
        GUI.addClass(this.domId, 'b-grid__row_selected');
        this.selected = true;
    }
};

/**
 * Unselects row: sets selected property to false, remove row marking
 */
GUI.Grid.Row.prototype.deselect = function () {
    if (this.selected) {
        GUI.removeClass(this.domId, 'b-grid__row_selected');
        this.selected = false;
    }
};

/**
 * Returns true if row is selected
 * @return {Boolean}
 */
GUI.Grid.Row.prototype.isSelected = function () {
    return this.selected;
};

/**
 * Generates template to be used by default row renderer to render rows
 */
GUI.Grid.Row.prototype.generateRowTemplate = function () {
    var i, tpl, columns, len, col, cellCls, dataIndex;

    tpl = new GUI.StringBuffer();
    tpl.append('<tr id="{this.domId}" class="b-grid__row {rowCls} {this.customRowCls}" >');

    columns = this.grid.visibleColumns;
    len = columns.length;

    for (i = 0; i < len; i++) {
        col = columns[i];

        // css
        cellCls = 'b-grid__cell';

        if (col.cellCls) {
            cellCls += ' ' + col.cellCls;
        }

        if (col.align) {
            cellCls += ' ' + col.align;
        }

        // td
        tpl.append('<td class="' + cellCls);
        if (col.nowrap) {
            tpl.append(' nowrap_false');
        }
        tpl.append('"');

        // hint
        if (col.editable) {
            //tpl.append(' hint="' + i18n('Double click to edit') + '" ');
            tpl.append(' hint="' + 'Double click to edit' + '" ');
        }
        tpl.append('>');
        tpl.append('');

        // data
        dataIndex = col.dataIndex;
        if (col.renderer) {
            dataIndex += '_rendered';
        }

        if (col.title) {
            tpl.append('<a class="title');
            if (col.titleSmall) {
                tpl.append(' title_small');
            } else if (col.titleBig) {
                tpl.append(' title_big');
            }
            tpl.append('" href="#">{data.' + dataIndex + '}</a>');
        } else {
            tpl.append('{data.' + dataIndex + '}');
        }

        tpl.append('</td>');
    }
    tpl.append('</tr>');
    return tpl.toString();
};

/**
 * Improved renderer that uses row template to render rows
 *
 * @param {GUI.StringBuffer} to Buffer to render html to
 * @param {Object} data Data object
 * @param {Number} index Row index
 */

GUI.Grid.Row.prototype.render = function (to, data, index) {
    var rowCls, columns, i, col, _data,
        dataIndex, tmpNode, res, tpl;

    this.noSelect = !!data.noSelect;
    this.data = data.data;

    rowCls = '';
    if (index % 2) {
        rowCls += ' b-grid__row_even';
    }

    // Render columns with custom renderer
    columns = this.grid.visibleColumns;

    i = columns.length;
    _data = data.data;

    tmpNode = GUI.Dom.getFlyNode('div');
    while (i--) {
        // Very slow loop under all browsers, twices update time
        col = columns[i];
        if (col.renderer) {
            dataIndex = col.dataIndex;
            res = col.renderer(_data[dataIndex], this, col, tmpNode) || ''; // renderer call is very expensive
            _data[dataIndex + '_rendered'] = res !== null ? res : 'DOM';
        }
    }

    tpl = new GUI.STemplate(this.rowTemplate, this);
    tpl.data = this.data;
    tpl.rowCls = rowCls;
    to.append(tpl.fetch());

    if (data.renderer) {
        // Call custom row renderer
        this.customRowId = this.grid.id + '-customrow-' + this.id;
        to.append('<tr id="' + this.customRowId +
            '" class="x-grid-row custom"' +
            (this.grid.customRowHidden ? ' style="display: none;"' : '')
            + '><td class="x-grid-cell" colspan="' +
            this.grid.visibleColumnsNum + '">'
            );
        if (this.customRenderer) {
            tmpNode = document.createElement('div');
            res = this.customRenderer(this, columns, this.grid, tmpNode); // pass row instead of data?
            if (res) {
                to.append(res);
            } else {
                to.append(tmpNode.innerHTML);
            }
        }
        to.append('</td></tr>');
        return 2;
    }
    return 1; // Number of real rows rendered
};

/**
 * Updates already rendered row with new data, saving time on deleting/creating dom tree
 * @param {Object} data new data to render
 */
GUI.Grid.Row.prototype.update = function (data) {
    if (!this.rendered) {
        return false;
    }
    // TODO update
};

/**
 * Destroys row and it's rendered dom tree if needed
 * @param {Boolean} destroyDom
 */
GUI.Grid.Row.prototype.destroy = function (destroyDom) {
    this.grid = null;
};

/**
 * Makes hidden row visible
 */
GUI.Grid.Row.prototype.show = function () {
    if (!this.visible) {
        if (this.rendered) {
            GUI.show(this.domId);
        }
        this.visible = true;
    }
};

/**
 * Hides row
 */
GUI.Grid.Row.prototype.hide = function () {
    if (this.visible) {
        if (this.rendered) {
            GUI.hide(this.domId);
        }
        this.visible = false;
    }
};

/**
 * empty function
 */
GUI.Grid.Row.prototype.onClick = function (event, row) {
    //
};

/**
 * empty function
 */
GUI.Grid.Row.prototype.onMouseOver = function (event, row) {
    //
};

/**
 * empty function
 */
GUI.Grid.Row.prototype.onMouseOut = function (event, row) {
    //
};


