(function () {
    //var superproto = GUI.ToolBar.prototype;

    /**
    * JavaScript Graphical User Interface
    * HeaderMenu implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.ToolBar
    */
    GUI.HeaderMenu = Class.create();
   // Object.extend(GUI.HeaderMenu.prototype, superproto);

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.HeaderMenu.prototype.initialize = function (config) {
        // Call parent initialize meethod
       // superproto.initialize.call(this, config);

        var cfg = {
            id        : GUI.getUniqId('headermenu-'),
            className : ''
        };
        this.config = cfg;

        Object.extend(this.config, config);
        this.elements = config.elements;

        this.elementType = 'GUI.HeaderMenu';
    };

    /**
     * Adds element to tabbar
     * @param {String} name
     * @param {Object} elem
     */
    GUI.HeaderMenu.prototype.add = function (name, elem) {
        // Call parent method
        GUI.Bar.prototype.add.apply(this, arguments);

        // Render new element if bar is already rendered
        if (!this.rendered) {
            return;
        }
        var li = document.createElement('LI');
        li.className = 'header-menu';
        this.dom.appendChild(li);
        elem.render(li);
    };

    /**
     * Removes element from tabbar
     * @param {String | Number} key String identifier or index number of element to remove
     */
    GUI.HeaderMenu.prototype.remove = function (key) {
        GUI.Bar.prototype.remove.apply(this, arguments);
    };

    /**
     * Renders DOM tree and sets this.dom to root of this tree
     * @return {Object} containerNode DOMNode object
     */
    GUI.HeaderMenu.prototype.render = function (containerNode) {
        this.elements.reverse();
        this.elements.each(function (elem) {
            elem.obj.render(containerNode);
        });

        this.dom = GUI.$(containerNode);

        GUI.unselectable(this.dom);
        return this.dom;
    };

}());