(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Minlength implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Validator
    */
    GUI.Forms.Validation.Selected = Class.create();
    Object.extend(GUI.Forms.Validation.Selected.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_SELECTED'
     * @type String
     */
    GUI.Forms.Validation.Selected.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_SELECTED);

    /**
     * Type of the element, 'GUI.Forms.Validation.Selected'
     * @type String
     */
    GUI.Forms.Validation.Selected.prototype.elementType    = 'GUI.Forms.Validation.Selected';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Selected.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);
    };

    /**
     * Validates value
     * @param {String|Number} value Value to validate
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Selected.prototype.validateFunc = function (value) {
        var sel,
            f = this.field;
        if (f.getSelections) {
            sel = f.getSelections();
            return sel && (sel.length > 0);
        } else {
            return f.selectedIndex > -1;
        }
    };
}());