/**
 * GUI.Dom - Dom specific functions
 */
(function () {
    /**
     * JavaScript Graphical User Interface
     * Dom specific functions
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.Dom = {
        /**
         *
         */
        _whereTable: {
            beforeBegin : 'previousSibling',
            afterBegin  : 'firstChild',
            beforeEnd   : 'lastChild',
            afterEnd    : 'nextSibling'
        },

        /**
         * Inserts an element at the specified location.
         * @param {HTMLElement} node
         * @param {String} where
         * Specifies where to insert the HTML element, using one of the following values:
         * 'beforeBegin'    Inserts fragment immediately before the object.
         * 'afterBegin'        Inserts fragment after the start of the object,
         *                     but before all other content in the object.
         * 'beforeEnd'        Inserts fragment immediately before the end of
         *                     the object, but after all other content in the object.
         * 'afterEnd'        Inserts fragment immediately after the end of the object.
         *
         * @param {HTMLElement/DocumentFragment} fragment
         * Object that specifies the element to be inserted adjacent to the node
         *
         * @return {HTMLElement}
         */
        insertAdjacentElement: function (node, where, fragment) {
            // First call, setup browser-specific implementation
            if (window.HTMLElement && window.HTMLElement.prototype.insertAdjacentElement) {
                // Use native method
                GUI.Dom.insertAdjacentElement = function (node, where, fragment) {
                    if (node.insertAdjacentElement) {
                        node.insertAdjacentElement(where, fragment);
                        return node[GUI.Dom._whereTable[where]];
                    } else { // Added because of bugs in Google Chrone, when node argument is a text node
                        return GUI.Dom._insertAdjacentElement_emulation(node, where, fragment);
                    }
                };
            } else {
                // Use emulation
                GUI.Dom.insertAdjacentElement = GUI.Dom._insertAdjacentElement_emulation;
            }
            return GUI.Dom.insertAdjacentElement(node, where, fragment);
        },

        /**
         *
         */
        _insertAdjacentElement_emulation: function (node, where, fragment) {
            switch (where) {
            case 'beforeBegin':
                node.parentNode.insertBefore(fragment, node);
                return node.previousSibling;

            case 'afterBegin':
                node.insertBefore(fragment, node.firstChild);
                return node.firstChild;

            case 'beforeEnd':
                node.appendChild(fragment);
                return node.lastChild;

            case 'afterEnd':
                if (node.nextSibling) {
                    node.parentNode.insertBefore(fragment, node.nextSibling);
                } else {
                    node.parentNode.appendChild(fragment);
                }
                return node.nextSibling;

            default:
                throw new Error("GUI.Dom.insertAdjacentElement(): invalid where value: " + where);
            }
        },

        /**
         * Inserts the given HTML text into the element at the location.
         * @param {HTMLElement} node
         * @param {String} where
         * Specifies where to insert the HTML element, using one of the following values:
         * 'beforeBegin'    Inserts fragment immediately before the object.
         * 'afterBegin'        Inserts fragment after the start of the object,
         *                     but before all other content in the object.
         * 'beforeEnd'        Inserts fragment immediately before the end of
         *                     the object, but after all other content in the object.
         * 'afterEnd'        Inserts fragment immediately after the end of the object.
         *
         * @param {String} html
         * Specifies the HTML text to insert. The string can be a combination
         * of text and HTML tags. This must be well-formed, valid HTML
         * or this method will fail.
         *
         * @return {HTMLElement}
         */
        insertAdjacentHTML: function (node, where, html) {
            // First call, setup browser-specific implementation
            if (document.createElement('div').insertAdjacentHTML) {
                // Use native method
                GUI.Dom.insertAdjacentHTML = GUI.Dom._insertAdjacentHTML_native;
            } else {
                // Use emulation
                GUI.Dom.insertAdjacentHTML = GUI.Dom._insertAdjacentHTML_emulation;
            }
            return GUI.Dom.insertAdjacentHTML(node, where, html);
        },

        /**
         * Moved function out of insertAdjacentHTML to avoid memory leakage in ie6
         * @param {HTMLElement} node
         * @param {String} where Specifies where to insert the HTML element
         * @param {String} html Specifies the HTML text to insert
         */
        _insertAdjacentHTML_native: function (node, where, html) {
            node.insertAdjacentHTML(where, html);
            return node[GUI.Dom._whereTable[where]];
        },

        /**
         * Inserts the given HTML text into the element at the location.
         * @param {HTMLElement} node
         * @param {String} where Specifies where to insert the HTML element
         * @param {String} html Specifies the HTML text to insert
         */
        _insertAdjacentHTML_emulation: function (node, where, html) {
            var els = [], el, i, res,
                element = document.createElement('div');
            element.innerHTML = html;

            el = element.firstChild;

            while (el) {
                els.push(el);
                el = el.nextSibling;
            }

            for (i = 0; i < els.length; i++) {
                res = GUI.Dom.insertAdjacentElement(node, where, els[i]);
            }
            return res;
        },

        /**
         * Inserts the text into the element at the location.
         * @param {HTMLElement} node
         * @param {String} where Specifies where to insert the HTML element
         * @param {String} text Specifies the HTML text to insert
         */
        insertAdjacentText: function (node, where, text) {
            // First call, setup browser-specific implementation
            if (document.createElement('div').insertAdjacentHTML) {
                // Use native method
                GUI.Dom.insertAdjacentText = function (node, where, text) {
                    node.insertAdjacentText(where, text);
                };
            } else {
                // Use emulation
                GUI.Dom.insertAdjacentText = function (node, where, text) {
                    var fragment = document.createTextNode(text);
                    GUI.Dom.insertAdjacentElement(where, fragment);
                };
            }
            return GUI.Dom.insertAdjacentText(node, where, html);
        },

        /**
         * Insert the html into the node
         * @param {HTMLElement} node
         * @param {String} html Specifies the HTML text to insert
         */
        insertBefore: function (node, html) {
            return GUI.Dom.insertAdjacentHTML(node, 'beforeBegin', html);
        },

        /**
         * Insert the html before end the node
         * @param {HTMLElement} node
         * @param {String} html Specifies the HTML text to insert
         */
        append: function (node, html) {
            return GUI.Dom.insertAdjacentHTML(node, 'beforeEnd', html);
        },

        /**
         * Insert the html after begin the node
         *
         * @param {HTMLElement} node
         * @param {String} html Specifies the HTML text to insert
         */
        prepend: function (node, html) {
            return GUI.Dom.insertAdjacentHTML(node, 'afterBegin', html);
        },

        /**
         * Checks if the specified CSS class exists on this element's DOM node.
         * @param {DOMNode} node DOM Node
         * @param {String} className The CSS class to check for
         * @returns {Boolean} True if the class exists, else false
         */
        hasClass: function (node, className) {
            node = GUI.$(node);
            return className && (' ' + node.className + ' ').indexOf(' ' + className + ' ') !== -1;
        },

        /**
         * Optimized version to check for multiple css classes
         * @param {DOMNode} node DOM Node
         * @param {Array} classes Array of the CSS classes to check for
         */
        hasClasses: function (node, classes) {
            node = GUI.$(node);
            var i = classes.length,
                nodeClass = ' ' + node.className + ' ';

            while (i--) {
                if (!classes[i] || nodeClass.indexOf(' ' + classes[i] + ' ') === -1) {
                    return false;
                }
            }
            return true;
        },

        /**
         * Adds one or more CSS classes to the element. Duplicate classes are automatically filtered out.
         * @param {DOMNode} node DOM Node
         * @param {String} className The CSS class to add
         * @returns {HTMLElement} node
         */
        addClass : function (node, className) {
            node = GUI.$(node);
            if (className && !GUI.Dom.hasClass(node, className)) {
                node.className = node.className + ' ' + className;
            }
            return node;
        },

        /**
         * Sets css class of the node
         * @param {DOMNode} node DOM Node
         * @param {String} className The CSS class to add
         * @returns {HTMLElement} node
         */
        setClass : function (node, className) {
            node = GUI.$(node);
            if (typeof (className) === 'string') {
                node.className = className;
            } else { // arr
                node.className = className.join(' ');
            }
            return node;
        },

        /**
         *
         */
        _classReCache: {},

        /**
         * Removes one or more CSS classes from the element.
         * @param {DOMNode} node DOM Node
         * @param {String} className The CSS class to remove
         */
        removeClass : function (node, className) {
            node = GUI.$(node);
            if (!className || !node.className) {
                return node;
            }

            if (GUI.Dom.hasClass(node, className)) {
                var re = GUI.Dom._classReCache[className];
                if (!re) {
                    re = new RegExp('(?:^|\\s+)' + className + '(?:\\s+|$)', "g");
                    GUI.Dom._classReCache[className] = re;
                }
                node.className = node.className.replace(re, " ");
            }
            return node;
        },

        /**
         * Sets style of the element
         * @param {HTMLElement} element
         * @param {Object} styles
         * @returns {HTMLElement} element
         */
        setStyle: function (element, styles) {
            var i;
            element = GUI.$(element);
            for (i in styles) {
                element.style[i] = styles[i];
            }
            return element;
        },

        /**
         * Returns true if container has element
         * @returns {Boolean}
         */
        within: function (element, container) {
            return element === container || GUI.contains(container, element);
        },

        /**
         * Returns dimensions of the node
         * @returns {Object} dim
         */
        getDimensions: function (node) {
            return {
                width  : node.offsetWidth,
                height : node.offsetHeight
            };
        },

        /**
         * Returns width of the node
         * @returns {Number} width
         */
        getWidth: function (node) {
            return node.offsetWidth;
        },

        /**
         * Returns height of the node
         * @returns {Number} height
         */
        getHeight: function (node) {
            return node.offsetHeight;
        },

        /**
         *
         */
        unitPattern: /\d+(px|em|%|en|ex|pt|in|cm|mm|pc)$/i,

        /**
         *
         */
        addUnits: function (v, defaultUnit) {
            if (v === '' || v === 'auto') {
                return v;
            }

            if (v === undefined) {
                return '';
            }

            if (typeof v === "number" || GUI.Dom.unitPattern.test(v)) {
                return v + (defaultUnit || 'px');
            }
            return v;
        },

        /**
         * Adjusts width
         * @param {Number|String} width
         * @returns {Number} width
         */
        adjustWidth: function (width) {
            if (typeof width === "number") {
                if (width < 0) {
                    width = 0;
                }
            }
            return width;
        },

        /**
         * Sets width
         * @param {Number|String} w Width
         * @param {Boolean} autoAdjustBox
         */
        setWidth: function (w, autoAdjustBox) {
            if (autoAdjustBox) {
                w = GUI.Dom.adjustWidth(w);
            }
            w.style.width = w;
        },

        /**
         * Returns dom
         * @param {String} HTML text
         * @returns {HTMLElement}
         */
        getDom: function (html) {
            var div;
            if (!GUI.Dom._getDomNode) {
                div = GUI.Dom._getDomNode = document.createElement('div');
            } else {
                div = GUI.Dom._getDomNode;
            }
            div.innerHTML = html;
            return div.firstChild;
        },

        /**
         * Returns node that is reused
         */
        _flyNodes: {},

        /**
         *
         */
        getFlyNode: function (tag) {
            if (!GUI.Dom._flyNodes[tag]) {
                // create
                var node = document.createElement(tag);
                node.id = 'jsgui-dom-fly-node';
                GUI.Dom._flyNodes[tag] = node;
            }
            return GUI.Dom._flyNodes[tag];
        },

        // Memory leakage workaround in IE6. Any reference to GUI.Event directly causes leakage.
        /**
         * Call GUI.Event.on
         */
        on: function () {
            GUI.Event.on.apply(this, arguments);
        },

        /**
         * Call GUI.Event.un
         */
        un: function () {
            GUI.Event.un.apply(this, arguments);
        },

        /**
         * empty function
         */
        query: function () {
            // peppy.query.apply(this, arguments);
        },

        /**
         *
         */
        queryParent: function (elem, selector, to) {
            // Parse selector, recognized "tag.class", "tag" or ".class"
            var arr = selector.split('.', 2), tag, cls;
            if (arr[1]) {
                tag = arr[0].length > 0 ? arr[0].toUpperCase() : null;
                cls = arr[1];
            } else {
                tag = arr[0].toUpperCase();
                cls = null;
            }
            to = to || document.body;

            if (tag && cls) {
                while (elem && (elem !== to)) {
                    if (!elem.tagName) {
                        return false;
                    }

                    if (elem.nodeName === tag) {
                        if (GUI.Dom.hasClass(elem, cls)) {
                            return elem;
                        }
                    }
                    elem = elem.parentNode;
                }
                return false;

            } else if (tag) {
                while (elem && (elem !== to)) {
                    if (!elem.tagName) {
                        return false;
                    }

                    if (elem.nodeName === tag) {
                        return elem;
                    }
                    elem = elem.parentNode;
                }
                return false;
            } else if (cls) {
                while (elem && (elem !== to)) {
                    if (!elem.tagName) {
                        return false;
                    }

                    if (GUI.Dom.hasClass(elem, cls)) {
                        return elem;
                    }
                    elem = elem.parentNode;
                }
                return false;
            }
        },

        /**
         * Finds first matching descedent of the node
         * @param {Object} parent
         * @param {String} search Ex.: 'div', '.item', 'div.item', 'a.item.disabled'
         */
        findDescedent: function (parent, search) {
            // Parse search string into tag name and class
            var i, node, nodes, len,
                classes = search.split('.'),
                tagName = classes.shift(),
                res = [];

            if (!tagName) {
                tagName = '*';
            }

            nodes = parent.getElementsByTagName(tagName); // Searching in descedent nodes
            len = nodes.length;

            for (i = 0; i < len; i++) {
                node = nodes[i];
                if (GUI.Dom.hasClasses(node, classes)) {
                    return node;
                }
            }
            return null;
        },

        /**
         * Find nodes, matching search string in all descedents of parent node
         * @param {Object} parent
         * @param {String} search Ex.: 'div', '.item', 'div.item', 'a.item.disabled'
         */
        findDescedents: function (parent, search) {
            // Parse search string into tag name and class
            var i, node, nodes, len,
                classes = search.split('.'),
                tagName = classes.shift(),
                res = [];

            if (!tagName) {
                tagName = '*';
            }

            nodes = parent.getElementsByTagName(tagName); // Searching in descedent nodes
            len = nodes.length;

            for (i = 0; i < len; i++) {
                node = nodes[i];
                if (GUI.Dom.hasClasses(node, classes)) {
                    res.push(node);
                }
            }
            return res;
        },

        /**
         *
         * @param parent
         * @param child
         * @returns {boolean}
         */
        isDescendant: function (parent, child) {
            if (parent == child) {
                return true;
            }

             var node = child.parentNode;
             while (node != null) {
                 if (node == parent) {
                     return true;
                 }
                 node = node.parentNode;
             }
             return false;
        },

        /**
         * Find nodes, matching search string in all direct childs of the parent node
         * @param {Object} parent
         * @param {String} search Ex.: 'div', '.item', 'div.item', 'a.item.disabled'
         */
        findChild: function (parent, search) {
            // Parse search string into tag name and class
            var i, node,
                classes = search.split('.'),
                tagName = classes.shift(),
                nodes = parent.childNodes, // Searching in child nodes
                len = nodes.length;

            if (tagName) {
                tagName = tagName.toUpperCase();
            }

            for (i = 0; i < len; i++) {
                node = nodes[i];
                if (tagName && node.nodeName !== tagName) {
                    continue;
                }

                if (GUI.Dom.hasClasses(node, classes)) {
                    return node;
                }
            }
            return null;
        },

        /**
         * Find nodes, matching search string in all direct childs of the parent node
         * @param {Object} parent
         * @param {String} search Ex.: 'div', '.item', 'div.item', 'a.item.disabled'
         */
        findChilds: function (parent, search) {
            // Parse search string into tag name and class
            var i, node,
                classes = search.split('.'),
                tagName = classes.shift(),
                res = [],
                index = 0,
                nodes = parent.childNodes, // Searching in child nodes
                len = nodes.length;

            if (tagName) {
                tagName = tagName.toUpperCase();
            }

            for (i = 0; i < len; i++) {
                node = nodes[i];
                if (tagName && node.nodeName !== tagName) {
                    continue;
                }

                if (GUI.Dom.hasClasses(node, classes)) {
                    res[index++] = node;
                }
            }
            return res;
        },

        /**
         *
         */
        findNextSibling: function (node, search) {
            // Parse search string into tag name and class
            var classes = search.split('.'),
                tagName = classes.shift(),
                res = [],
                index = 0;

            if (tagName) {
                tagName = tagName.toUpperCase();
            }

            while (node = node.nextSibling) { // Searching in next siblings
                if (tagName && node.nodeName !== tagName) {
                    continue;
                }

                if (GUI.Dom.hasClasses(node, classes)) {
                    res[index++] = node;
                }
            }
            return res;
        },

        /**
         *
         */
        findPrevSibling: function (node, search) {
            // Parse search string into tag name and class
            var classes = search.split('.'),
                tagName = classes.shift(),
                res = [],
                index = 0;

            if (tagName) {
                tagName = tagName.toUpperCase();
            }

            while ((node = node.previousSibling) !== null) { // Searching in previous siblings
                if (tagName && node.nodeName !== tagName) {
                    continue;
                }

                if (GUI.Dom.hasClasses(node, classes)) {
                    res[index++] = node;
                }
            }
            return res;
        },


        getCaretPosition: function (input) {
            var bookmark, range,
                caretPosition = 0;

            input = GUI.$(input);

            if (document.selection && document.selection.createRange) {
                range = document.selection.createRange();
                bookmark = range.getBookmark();
                caretPosition = bookmark.charCodeAt(2) - 2;
            } else {
                if (input.setSelectionRange) {
                    caretPosition = input.selectionStart;
                }
            }

            return caretPosition;
        },

        setCaretPosition: function (input, caretPosition) {
            var range, oSel;

            // IE Support
            if (document.selection) {
                input.focus();

                // IE, you are beautiful.
                // Need timeout after focus, to set caret position
                setTimeout(function () {
                    // First, needset caret to zero position.
                    // Only after this magic, can set cursor to position
                    oSel = document.selection.createRange();
                    oSel.collapse(true);
                    oSel.moveStart('character', -input.value.length);
                    oSel.moveStart('character', 0);
                    oSel.moveEnd('character', 0);
                    oSel.select();

                    oSel.collapse(true);
                    oSel.moveStart('character', -input.value.length);
                    oSel.moveStart('character', caretPosition);
                    oSel.moveEnd('character', 0);
                    oSel.select();

                }, 0);
            } else if (input.selectionStart || input.selectionStart == '0') {
                if (input.createTextRange) {
                    range = input.createTextRange();
                    range.move('character', caretPosition);
                    range.select();
                } else {
                    if (input.selectionStart) {
                        input.focus();
                        input.setSelectionRange(caretPosition, caretPosition);
                    } else {
                        input.focus();
                    }
                }
            } else {
                input.focus();
            }
        }
    };

    if (GUI.isIE) {
        GUI.Dom.getStyle = function (element, style, doNotExtend) {
            if (!doNotExtend) {
                element = GUI.$(element);
            }
            style = (style === 'float' || style === 'cssFloat') ? 'styleFloat' : style.camelize();
            var value = element.style[style];
            if (!value && element.currentStyle) {
                value = element.currentStyle[style];
            }

            if (style === 'opacity') {
                if (value = (element.getStyle('filter') || '').match(/alpha\(opacity=(.*)\)/)) {
                    if (value[1]) {
                        return parseFloat(value[1]) / 100;
                    }
                }
                return 1.0;
            }
            return value;
        };

        Object.extend(GUI.Dom, {
            getOuterHTML: function (node) {
                return node.outerHTML;
            }
        });

    } else {
        GUI.Dom.getStyle = function (element, name) {

            element = GUI.$(element);
            name = (name === 'float') ? 'cssFloat' : name.camelize();
            var css,
                value = element.style[name];
            if (!value) {
                css = document.defaultView.getComputedStyle(element, null);
                value = css ? css[name] : null;
            }
            if (name === 'opacity') {
                return value ? parseFloat(value) : 1.0;
            }
            return value === 'auto' ? null : value;
        };

        Object.extend(GUI.Dom, {
            _emptyTags: {
                'IMG'   : true,
                'BR'    : true,
                'INPUT' : true,
                'META'  : true,
                'LINK'  : true,
                'PARAM' : true,
                'HR'    : true
            },

            getOuterHTML: function (node) {
                var attr, i,
                    attrs = node.attributes,
                    len = attrs.length,
                    str = "<" + node.tagName;

                for (i = 0; i < len; i++) {
                    attr = attrs[i];
                    str += ' ' + attr.name + "=\"" + attr.value + "\"";
                }

                return GUI.Dom._emptyTags[node.tagName]
                    ? str + ">"
                    : str + ">" + node.innerHTML + "</" + node.tagName + ">";
            }
        });
    };

    GUI.Dom._elementExtensions = {
        // Strange... Two this methods cause leakage if they reference GUI.Event.*
        on              : GUI.Dom.on.methodize(),
        un              : GUI.Dom.un.methodize(),

        addClass        : GUI.Dom.addClass.methodize(),
        setClass        : GUI.Dom.setClass.methodize(),
        removeClass     : GUI.Dom.removeClass.methodize(),
        hasClass        : GUI.Dom.hasClass.methodize(),
        getStyle        : GUI.Dom.getStyle.methodize(),
        setStyle        : GUI.Dom.setStyle.methodize(),
        within          : GUI.Dom.within.methodize(),
        getDimensions   : GUI.Dom.getDimensions.methodize(),
        getWidth        : GUI.Dom.getWidth.methodize(),
        getHeight       : GUI.Dom.getHeight.methodize(),
        findDescedent   : GUI.Dom.findDescedent.methodize(),
        findDescedents  : GUI.Dom.findDescedents.methodize(),
        findChild       : GUI.Dom.findChild.methodize(),
        findParent      : GUI.findParentByTag.methodize(),
        queryParent     : GUI.Dom.queryParent.methodize()
    };


    if (!GUI.ElementExtensions && document.createElement('div')['__proto__']) {
        window.HTMLElement = {};
        window.HTMLElement.prototype = document.createElement('div')['__proto__'];
        GUI.ElementExtensions = true;
    }

    // Extend HTMLElement's prototype if possible
    if (GUI.ElementExtensions) {
        Object.extend(HTMLElement.prototype, GUI.Dom._elementExtensions);
    }

    // Back support
    GUI.addClass = GUI.Dom.addClass;
    GUI.hasClass = GUI.Dom.hasClass;
    GUI.removeClass = GUI.Dom.removeClass;
}());

(function () {
    /**
     * Extends dom node
     * @param {Object} node
     */
    GUI.Dom.extend = GUI.SpecificElementExtensions ?
        function (node) {return node; }
        :
        function (node) {
            if (!node || node._jsguiExtended || node === window) {
                return node;
            }
            Object.extend(node, GUI.Dom._elementExtensions);
            node._jsguiExtended = 1;
            return node;
        };
}());

// Back support
if (!window.Element) {
    Element = {};
}
Element.observe = GUI.Event.on;
Element.stopObserving = GUI.Event.un;