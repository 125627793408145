(function () {
    if (!GUI.Tree.Plugins) {
        GUI.Tree.Plugins = {};
    }

    /**
     * JavaScript Graphical User Interface
     * TreeActions implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Tree.Plugins
     */
    GUI.Tree.Plugins.TreeActions = Class.create();

    /**
     * Constructor
     * @param {Object} cfg Configuration object
     */
    GUI.Tree.Plugins.TreeActions.prototype.initialize = function (cfg) {
        this.showMenuAnimation = false;

        if (cfg) {
            Object.extend(this, cfg);
        }

        this.hideAction = true;
        this.regions = {};
        this.nodes = {};
    };

    /**
     * Called by Tree while initializing
     * @param {Object} tree Object of the tree
     */
    GUI.Tree.Plugins.TreeActions.prototype.init = function (tree) {
        this.tree = tree;

        tree.config.css.tree += ' b-tree_actions';
        tree.config.actions = true;

        tree.addEvents({
            'action' : true
        });
        tree.on('nodeclick', this.onNodeClick, this);
    };

    /**
     * Called by Tree while destroying
     */
    GUI.Tree.Plugins.TreeActions.prototype.destroy = function () {
        this.tree.un('nodeclick', this.onNodeClick, this);
        this.tree = null;
    };

    /**
     * Fires event 'action'
     * @param {Object} tree Tree objects
     * @param {Object} node Object node
     * @param {Event} e Event
     */
    GUI.Tree.Plugins.TreeActions.prototype.onNodeClick = function (tree, node, e) {
        var target = e.getTarget(),
            actions = node.config.actions;

        if (target.hasClass('act')) {
            if (actions && actions.length) {
                this.showActionsMenu(actions, target, node);
            }
        }
    };

    /**
     *
     */
    GUI.Tree.Plugins.TreeActions.prototype.showActionsMenu = function (actions, target, node) {
        var items = [];

        target.addClass('selected');

        this.renderMenu();
        this.menu.setItems([]);

        this.actionNode = node;

        // set items
        items = this.generateItems(actions);
        this.menu.setItems(items);

        // align to dom
        this.menu.alignTo(target, 'tl-bl?');
        this.menu.show();
        this.menu.on('hide', function () {
            target.removeClass('selected');
        });
    };

    /**
     *
     */
    GUI.Tree.Plugins.TreeActions.prototype.renderMenu = function () {
        if (this.menu) {
            return this.menu;
        }
        this.menu = new GUI.Popup.Menu({
            className   : '',
            icons       : false,
            hideOnDocumentClick : true,
            animate     : this.showMenuAnimation
        });
        this.menu.on({
            scope       : this,
            click       : this.onMenuItemClick
        });
    };

    /**
     *
     */
    GUI.Tree.Plugins.TreeActions.prototype.generateItems = function (actions) {
        var action, k,
            items = [],
            i = actions.length;

        while (i--) {
            action = actions[i];

            if (action.subActions) {
                for (k = 0; k < action.subActions.length; k++) {
                    action.subActions[k].action = action.subActions[k].name;
                    action.subActions[k].icon = action.subActions[k].img;
                }
            }

            items[i] = {
                action  : action.name,
                name    : action.name,
                caption : action.text,
                icon    : action.img,
                disabled: action.disabled,
                sub     : (action.subActions) ? {items: action.subActions} : null
            };
        }
        return items;
    };

    /**
     *
     */
    GUI.Tree.Plugins.TreeActions.prototype.onMenuItemClick = function (menu, item) {
        this.tree.fireEvent('action', this.tree, item.action, this.actionNode);
    };
}());