(function () {
    GUI.Utils.globalEvents = new GUI.Utils.Observable();

    GUI.Utils.globalEvents.names = {
        hidePopupElements: 'hidePopupElements',
        tinymceMouseEnter: 'tinymceMouseEnter',
        tinymceMouseLeave: 'tinymceMouseLeave'
    };

    GUI.Utils.globalEvents.addEvents({
        hidePopupElements: true,
        tinymceMouseEnter: true,
        tinymceMouseLeave: true
    });
})();