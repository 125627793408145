import {hotkeys, Keys, KEY, SETTINGS} from "library/keyboard";

(function () {
    var superproto = GUI.Popup.Region.prototype;

    /**
     * JavaScript Graphical User Interface
     * Menu implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Popup
     * @extends GUI.Popup.Region
     */
    GUI.Popup.Menu = Class.create();
    Object.extend(GUI.Popup.Menu.prototype, superproto);

    /**
     *
     * @type {number}
     */
    GUI.Popup.Menu.prototype.focusedItemPosition = -1;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Popup.Menu.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);
        var i, j, group, cfg = this.config, items = [];

        Object.extend(cfg, {
            id              : GUI.getUniqId('menu-'),
            className       : '',
            name            : null,
            items           : [],
            animate         : window.GUI_FX_ANIMATION === true,
            effect          : ['slide'],
            lazyRender      : true,
            destroyOnHide   : true,
            onClick         : null,
            hideOnDocumentClick : false,
            preloadImages   : true,
            showDescription : true,
            enableScrollers : false,
            scrollerHeight  : 8,
            scrollStep      : 24,
            listHeight      : 14,
            hideOnChildHide : true,
            canBeSelected   : false,
            hintType        : false,
            hintPosition    : false,
            maxHintWidth    : false,
            hideAfterClick  : true,
            messageItemCaption  : i18n(GUI.i18n.GUI_POPUP_MENU_NO_ITEMS),
            hotkeysScope: GUI.getUniqId('popup-menu-'),
            hotkeysInput    : false,
        });
        if (!GUI.isIE) {
            cfg.effect.push('fade');
        }
        Object.extend(cfg, config);

        this._indexById = {};
        this._indexByName = {};

        if (cfg.groups) {
            for (i = 0; i < cfg.groups.length; i++) {
                group = cfg.groups[i];
                for (j = 0; j < group.items.length; j++) {
                    items.push(group.items[j]);
                }
            }
            this.setItems(items);
        } else {
            this.setItems(cfg.items);
        }

        this.addEvents('click', 'mouseOver', 'mouseleave', 'beforeShow', 'show', 'beforeItemRender');

        if (GUI.isFunction(cfg.onClick)) {
            this.on('click', cfg.onClick);
        }

        if (cfg.animate) {
            this.addEvents({
                fxComplete: true
            });
            this.fx = new Animator({
                duration    : 250,
                scope       : this,
                onComplete  : function (fx) {
                    this.fireEvent('fxComplete', fx);
                }
            });
            if (GUI.isString(cfg.effect)) {
                cfg.effect = [ cfg.effect ];
            }
            this.on('fxComplete', this.onFxComplete, this);
        }

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
        if (!cfg.lazyRender) {
            if (cfg.destroyOnHide) {
                throw new Error("destroyOnHide option can only be used with lazyRender");
            }
            this.render();
        }

        GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hide, this);
    };

    /**
     * Sets items
     * @param {Array} items
     */
    GUI.Popup.Menu.prototype.setItems = function (items) {
        var i, id, item;

        if (items.length === 0) {
            this._indexById = [];
            this._indexByName = [];
        }

        i = items.length;
        while (i--) {
            item = items[i];

            if (item.sub) {
                item.sub.openOnHover = this.config.openOnHover;
                item.sub.hideOnDocumentClick = this.config.hideOnDocumentClick;
                item.sub = new GUI.Popup.Menu(item.sub);
            }
            id = item.id || GUI.getUniqId('menu-item-');
            item.id = id;
            this._indexById[id] = i;

            if (GUI.isString(item.name) && (item.name.length > 0)) {
                if (!GUI.isSet(this._indexByName[item.name])) {
                    this._indexByName[item.name] = item;
                }
            }
        }

        this.config.items = items;
        this.focusedItemPosition = -1;

        if (this.dom) {
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
    };

    /**
     * Returns item by name
     * @param {String} name Name of the item
     * @returns {Object} item
     */
    GUI.Popup.Menu.prototype.getItemByName = function (name) {
        return this._indexByName[name];
    };

    /**
     * Hides item by name
     * @param {String} name Name of the item
     */
    GUI.Popup.Menu.prototype.hideItem = function (name) {
        var item = this.getItemByName(name);
        if (item) {
            item.hidden = true;
            if (item.dom) {
                item.dom.style.display = 'none';
            }
        }
    };

    /**
     * Shows item by name
     * @param {String} name Name of the item
     */
    GUI.Popup.Menu.prototype.showItem = function (name) {
        var item = this.getItemByName(name);
        if (item) {
            item.hidden = false;
            if (item.dom) {
                item.dom.style.display = '';
            }
        }
    };

    /**
     * Sets item caption
     * @param {String} name Name of the item
     * @param {String} caption New caption
     */
    GUI.Popup.Menu.prototype.setItemCaption = function (name, caption) {
        var item = this.getItemByName(name);
        if (item) {
            item.caption = caption;
        }
    };

    /**
     * Sets item caption
     * @param {String} name Name of the item
     */
    GUI.Popup.Menu.prototype.select = function (name) {
        var item = this.getItemByName(name);
        this.selectItem(item);
    };

    /**
     * Sets item caption
     * @param {Object} item Option item
     */
    GUI.Popup.Menu.prototype.selectItem = function (item) {
        if (this.selectedItem) {
            this.selectedItem.dom.removeClass('selected');
        }

        if (item) {
            if (item.dom) {
                item.dom.addClass('selected');
                item.selected = true;

                this.selectedItem = item;

                this.focusedItemPosition = this.config.items.indexOf(item);

                if (item.dom.parentNode && GUI.hasClass(item.dom.parentNode, 'b-popup-menu__list_scrolled')) {
                    GUI.scrollIntoView(item.dom.parentNode, item.dom);
                }

            } else {
                this.selectedItem = undefined;
                this.focusedItemPosition = -1;
            }
        } else {
            this.selectedItem = undefined;
            this.focusedItemPosition = -1;
        }
    };

    /**
     * Initializes animation object
     */
    GUI.Popup.Menu.prototype.initFx = function () {
        var d, tableEl,
            menu = this.dom,
            cfg = this.config;
        // Clear old
        this.fx.clearSubjects();

        // Fade effect
        if (cfg.effect.include('fade')) {
            GUI.setOpacity(menu, 0.1);
            this.fx.addSubject(new window.NumericalStyleSubject(menu, 'opacity', 0.1, 1.0));
        }

        // Slide effect
        if (cfg.effect.include('slide')) {
            d = GUI.getDimensions(menu);
            tableEl = menu.firstChild;

            tableEl.style.width = d.width + 'px';
            tableEl.style.height = d.height + 'px';

            menu.style.overflow = 'hidden';
            menu.style.width = 1;
            menu.style.height = 1;
            this.fx.addSubject(new window.NumericalStyleSubject(menu, 'width', 1, d.width));
            this.fx.addSubject(new window.NumericalStyleSubject(menu, 'height', 1, d.height));
        }
    };

    /**
     * Sets opacity, width. Clears animation ojects
     * @param {Object} fx Animator
     */
    GUI.Popup.Menu.prototype.onFxComplete = function (fx) {
        if (!this.dom) {
            return;
        }

        var effects = this.config.effect,
            menuStyle = this.dom.style;
            //tableStyle = this.dom.firstChild.style;

        if (effects.include('fade')) {
            if (fx.state === 0) {
                GUI.setOpacity(this.dom, '');
            }
        }

        if (effects.include('slide')) {
            //tableStyle.width = tableStyle.height = '';
            //menuStyle.width = menuStyle.height = '';
        }

        if (fx.state === 0) {
            menuStyle.visibility = 'hidden'; // !important
            this._hide();
            fx.clearSubjects(); // clear dom references to avoid memory leaks
        }
    };

    /**
     * Shows the item
     * @param {Object} parent
     * @param {Number|String} parentItemId Id of the parent item
     */
    GUI.Popup.Menu.prototype.show = function (parent, parentItemId) {
        if (this.dom && this.visible) {
            // We are already rendered and visible
            return;
        }

        hotkeys.setScope(this.config.hotkeysScope);

        this.parent = parent;

        var style, i, selectedDoms,
            cfg = this.config;

        this.fireEvent('beforeShow');

        if (!this.dom && cfg.lazyRender) {
            // Perform lazy rendering id needed
            this.render();
        }

        selectedDoms = this.dom.querySelectorAll('li.selected');
        i = selectedDoms.length;

        while (i--) {
            GUI.Dom.removeClass(selectedDoms[i], 'selected');
        }

        this.focusedItemPosition = -1;
        //this.updateFocusPosition(1);

        if (this.selectedItem) {
            this.selectItem(this.selectedItem);
        }

        style = this.dom.style;
        style.visibility = 'hidden';
        style.display = '';
        if (cfg.setzIndex) {
            style.zIndex = GUI.Popup.Region.getzIndex() + 23;
        }
        this.attachEventListeners();

        // Add effects
        if (this.fx) {
            this.initFx();
            this.fx.seekTo(1);
        }
        style.visibility = '';
        this.visible = true;

        // Apply list height limit if exists

        this.fireEvent('show', this);
    };

    /**
     * Sets description
     * @param {String} val New description
     */
    GUI.Popup.Menu.prototype.setShowDescription = function (val) {
        var menu  = this.getRootMenu();
        menu.config.showDescription = val;
    };

    /**
     * Return html of the item
     * @param {Object} item
     * @returns {String} html
     */
    GUI.Popup.Menu.prototype.renderList = function (elem, items, icons, configs) {
        configs = configs ? configs : {};

        var ul, li, i, len, o, item, hintInfo,
            cfg = this.config;

        elem = GUI.Dom.extend(elem);

        ul = document.createElement('UL');
        ul.className = 'b-popup-menu__list';
        elem.appendChild(ul);
        ul = GUI.Dom.extend(ul);

        if (icons) {
            ul.addClass('with-icons');
        }

        if (cfg.groups) {
            ul.addClass('group');
        }

        for (i = 0, len = items.length; i < len; i++) {
            item = items[i];
            
            if (item.group) {
                this.renderList(elem, item.items, item.icons, item);
                continue;
            }

            li = document.createElement('LI');

            if (item.separator) {
                li.className = 'b-popup-menu__item_separator';
                ul.appendChild(li);
                continue;
            }

            li.className = 'b-popup-menu__item';
            li.id = item.id;

            li = GUI.Dom.extend(li);

            if (item.disabled || item.showAsDisabled) {
                li.addClass('disabled');
            }
            if (item.selected) {
                li.addClass('selected');
                this.selectedItem = item;
            }

            if (item.noSelect) {
                li.addClass('noselect');
            }

            if (item.extraClass) {
                li.addClass(item.extraClass);
            }

            if (item.title) {
                li.setAttribute('title', item.title);
            }

            if (item.hidden) {
                li.style.display = 'none';
            }
            
            if (false === this.fireEvent('beforeItemRender', li, item)) {
                item.dom = li;
                ul.appendChild(li);
                continue;
            }


            o = new GUI.StringBuffer();

            if (item.href) {
                if (item.sub) {
                    throw new Error("Menu item with `href` cannot keep submenu items.");
                }

                o.append('<a class="b-popup-menu__button ' + (item.sub ? 'parent' : '') + ' ' + (item.spanExtraClass || '') + '" href="' + item.href + '" ');

                if (item.target) {
                    o.append('target="' + item.target + '" ');
                }
            } else {
                o.append('<span class="b-popup-menu__button ' + (item.sub ? 'parent' : '') + ' '  + (item.spanExtraClass || '') +  '" ');
            }

            if (item.hint) {
                hintInfo = ['hint="' + item.hint + '"'];

                if (this.config.hintType) {
                    hintInfo.push('hinttype="' + this.config.hintType + '"');
                }

                if (this.config.hintPosition) {
                    hintInfo.push('hintposition="' + this.config.hintPosition + '"');
                }

                if (this.config.maxHintWidth) {
                    hintInfo.push('hintmaxwidth="' + this.config.maxHintWidth + '"');
                }

                o.append(' ' + (hintInfo.join(" ")));
            }
            o.append(' >');

            if (icons && (item.iconClass || item.icon)) {
                o.append('<i class="icon ' + (item.iconClass || '') + '" ');

                if (item.icon) {
                    o.append(' style="background-image: url(' + item.icon + ');" ');
                }
                o.append(' ></i>');
            } else if (icons && item.img) {
                o.append('<i class="icon"><img src="' + item.img + '" alt=""></i>');
            } else if (icons && item.tpl) {
                o.append('<i class="icon">' + item.tpl + '</i>');
            }

            o.append('<span class="label ');

            if (item.className) {
                o.append(item.className);
            }

            o.append(' ">' + (item.caption || item.text) + '</span>');

            if (cfg.showDescription && item.description) {
                o.append('<span class="description">' + item.description + '</span>');
            }

            if (item.href) {
                o.append('</a>')
            } else {
                o.append('</span>');
            }

            li.innerHTML = o.toString();
            item.dom = li;
            //
            ul.appendChild(li);
        }

        this.dom.style.visible = '';

        var listHeight = configs.listHeight ? configs.listHeight : cfg.listHeight;
        var scrolled = configs.scrolled ? configs.scrolled : true;

        var cleanItems = [];
        items.forEach(function (item) {
            if (!item.separator) {
                cleanItems.push(item);
            }
        });

        if (GUI.isNumber(listHeight) && cleanItems.length > listHeight) {
            // Discover item height if not discovered before
            if (!GUI.isSet(this.itemHeight)) {
                var item = this.dom.findDescedents('li.b-popup-menu__item')[0];
                this.itemHeight = item.offsetHeight;
            }
            GUI.setClientHeight(ul, this.itemHeight * this.config.listHeight);

            if (scrolled) {
                ul.addClass('b-popup-menu__list_scrolled');
            }
        }

        this.dom.style.visible = 'hidden';
    };

    /**
     * Renders dom
     */
    GUI.Popup.Menu.prototype.render = function () {
        if (this.dom) {
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }

        this.visible = false;

        var menu, i, div, pNode, btn, dim,
            cfg = this.config;

        if (!cfg.parentNode) {
            cfg.parentNode = document.body;
        }

        menu = document.createElement('DIV');
        menu.className = this.className || 'b-popup-menu';
        menu.id = cfg.id;

        // width
        if (cfg.width) {
            menu.style.width = cfg.width + 'px';
        }
        // max width
        if (cfg.maxWidth) {
            menu.style.maxWidth = cfg.maxWidth + 'px';
        }
        // min width
        if (cfg.minWidth) {
            menu.style.minWidth = cfg.minWidth + 'px';
        }
        // height
        if (cfg.height) {
            menu.style.height = cfg.height + 'px';
        }
        cfg.parentNode.appendChild(menu);
        menu = GUI.Dom.extend(menu);

        // icons
        if (cfg.icons) {
            menu.addClass('b-popup-menu_with-icons');
        }
        // main menu class
        if (cfg.mainMenu) {
            menu.addClass('b-popup-menu_main-menu');
        }

        if (cfg.className) {
            menu.addClass(cfg.className);
        }

        pNode = menu;
        this.dom = GUI.$(menu);

        // scroll class
        if (cfg.enableScrollers) {
            menu.addClass('b-popup-menu_scrollable');
            div = document.createElement('DIV');
            div.className = 'buttons-case';
            menu.appendChild(div);
            pNode = div;
        } 

        if (cfg.disableDivScroll) {
            pNode.style.overflow = 'visible';
        }

        this.renderList(pNode, cfg.items, cfg.icons);

        if (cfg.enableScrollers) {
            btn = document.createElement('BUTTON');
            btn.className = 'scroll_up';
            pNode.appendChild(btn);

            btn = document.createElement('BUTTON');
            btn.className = 'scroll_down';
            pNode.appendChild(btn);
        }


        menu.style.visibility = 'hidden';
        if (!cfg.parentNode) {
            cfg.parentNode = document.body;
        }
        cfg.parentNode.appendChild(menu);

        GUI.unselectable(this.dom);

        // Why this need?
        // if (this.alignArgs && this.alignArgs[1] && this.alignArgs[2] && this.alignArgs[1].search('br') !== -1) {
        //     this.alignArgs[2][0] = -this.dom.offsetWidth + 18;
        // }

        if (GUI.isArray(this.alignArgs) && this.alignArgs.length) {
            this._alignTo.apply(this, this.alignArgs);
            this.alignArgs = null;
        }
        GUI.hide(menu);
    };

    /**
     * Applies scrollers
     * @param {Number} y
     */
    GUI.Popup.Menu.prototype.applyScrollers = function (y) {
        this.wrapperEl.style.height = 'auto';
        var maxHeight,
            fullHeight = this.wrapperEl.offsetHeight;

        // determine max possible height
        if (this.config.maxHeight) {
            maxHeight = this.config.maxHeight;
        } else {
            maxHeight = GUI.getViewportHeight() - y - 3 * this.config.scrollerHeight;
        }

        if (fullHeight > maxHeight && maxHeight > 0) {
            this._currentMax = maxHeight;
            this.wrapperEl.style.height = maxHeight + 'px';
            this.createScrollers();
        } else {
            this.wrapperEl.style.height = fullHeight;
            if (this.scroller) {
                this.scroller.top && GUI.hide(this.scroller.top);
                this.scroller.bottom && GUI.hide(this.scroller.bottom);
            }
        }
    };

    /**
     * Returns scroller node
     * @param {Boolean} top Position
     * @returns {HTMLElement} div
     */
    GUI.Popup.Menu.prototype._getScroller = function (top) {
        var div = document.createElement('div');
        div.className = 'menu-scroller menu-scroller-' + (top ? 'top' : 'bottom');
        div.innerHTML = '<div>&nbsp;</div>';
        return div;
    };

    /**
     * Creates scrollers
     */
    GUI.Popup.Menu.prototype.createScrollers = function () {
        if (!this.scroller) {
            var bottomScroller, ClickRepeater,
                topScroller = this._getScroller(true);

            this.wrapperEl.parentNode.insertBefore(topScroller, this.wrapperEl);

            bottomScroller = this._getScroller(false);
            this.wrapperEl.parentNode.appendChild(bottomScroller);

            this.scroller = {
                position    : 0,
                top         : topScroller,
                bottom      : bottomScroller
            };

            ClickRepeater = GUI.Utils.ClickRepeater;

            this._topRepeater = new ClickRepeater({
                dom         : topScroller,
                pressedClass: 'menu-scroller-pressed',
                listeners   : {
                    scope : this,
                    click : this.onTopScrollerClick
                }
            });

            this._bottomRepeater = new ClickRepeater({
                dom         : bottomScroller,
                pressedClass: 'menu-scroller-pressed',
                listeners   : {
                    scope : this,
                    click : this.onBottomScrollerClick
                }
            });
        }
    };

    /**
     * Sets position of the dom
     * @param {Array} pos New position of the dom
     */
    GUI.Popup.Menu.prototype.setPosition = function (pos) {
        var style,
            dim = this.config.dimensions;

        dim.left = pos[0];
        dim.top = pos[1];

        if (this.dom) {
            style = this.dom.style;
            style.left = pos[0] + 'px';
            style.top  = pos[1] + 'px';
        }
    };

    /**
     * Hides the item
     * @param {Boolean} fast
     */
    GUI.Popup.Menu.prototype.hide = function (fast, e) {
        if (!this.dom) {
            return;
        }
        if (this.subMenu) {
            this.hideSubMenu();
        }
        this.removeEventListeners();

        if (this.fx && !fast) {
            this.fx.seekTo(0);
        } else {
            this._hide(e);
        }
        if (this.parent && GUI.isFunction(this.parent.onChildHide)) {
            this.parent.onChildHide(this);
        }
    };

    /**
     * Hides and destroy dom
     */
    GUI.Popup.Menu.prototype._hide = function (e) {
        if (this.visible) {
            hotkeys.removeScope(this.config.hotkeysScope);
        }

        GUI.hide(this.dom);

        this.fireEvent('hide', this, e);
        this.visible = false;
        if (this.config.destroyOnHide) {
            GUI.destroyNode(this.dom);
            this.scroller = null;
            this.dom = null;
        }
        this.fireEvent('afterHide', e);
    };

    /**
     * empty function
     */
    GUI.Popup.Menu.prototype.onChildHide = function (menu) {
        // nothing to do
    };

    /**
     * Show sub menu
     * @param {Number|String} itemId Id of the item
     */
    GUI.Popup.Menu.prototype.showSubMenu = function (itemId, cfg) {
        var offset,
            item = this.getItemById(itemId),
            sub = item.sub;

        if (cfg.mainMenu) {
            sub.config.mainMenu = cfg.mainMenu;
        }
        if (cfg.icons) {
            sub.config.icons = cfg.icons;
        }
        if (cfg.openOnHover) {
            sub.config.openOnHover = cfg.openOnHover;
        }
        if (cfg.hideOnDocumentClick) {
            sub.config.hideOnDocumentClick = cfg.hideOnDocumentClick;
        }

        if (this.submenu !== sub) {
            this.hideSubMenu();
            offset = [0, 0]; //GUI.isIE ? [-3, -1] : [2, 0];
            sub.alignTo(GUI.$(itemId), 'tl-tr?', offset);
            sub.show(this, itemId);
            this.subMenu = sub;
            this.parentItemId = itemId;
        }
    };

    /**
     * Hides sub menu
     */
    GUI.Popup.Menu.prototype.hideSubMenu = function () {
        if (this.subMenu) {
            this.subMenu.hide();
            this.subMenu = null;
        }
    };

    /**
     * Hides parent item
     */
    GUI.Popup.Menu.prototype.hideParent = function () {
        if (this.config.hideOnChildHide === false) {
            return;
        }

        this.hide();

        if (this.parent && this.parent.hideParent) {
            this.parent.hideParent();
        }
    };

    /**
     * Disable item of the menu
     * @param {String} id of the item
     */
    GUI.Popup.Menu.prototype.disable = function (id) {
        if (GUI.$(id)) {
            GUI.$(id).addClass('disabled');
            this.getItemById(id).disabled = true;
        }
    };

    /**
     * Enable item of the menu
     * @param {String} id of the item
     */
    GUI.Popup.Menu.prototype.enable = function (id) {
        if (GUI.$(id)) {
            GUI.$(id).removeClass('disabled');
            this.getItemById(id).disabled = false;
        }
    };

    /**
     * Returns root item
     */
    GUI.Popup.Menu.prototype.getRootMenu = function () {
        if (this.parent && this.parent.getRootMenu) {
            return this.parent.getRootMenu();
        } else {
            return this;
        }
    };

    /**
     * Returns item by id
     * @param {Number|String} id Id of the item
     * @returns {Object} item
     */
    GUI.Popup.Menu.prototype.getItemById = function (id) {
        var index = this._indexById[id];
        if (GUI.isNumber(index)) {
            return this.config.items[this._indexById[id]];
        }

        var deepItems = _.where(this.config.items, {group: true});
        var sizeDeepItems = deepItems.length;

        while(sizeDeepItems--) {
            var elements = _.where(deepItems[sizeDeepItems].items, {id: id});
            if (elements.length) {
                return elements[0];
            }
        }

        return false;
    };

    /**
     * Returns state of visibility
     * @returns {Boolean} visible
     */
    GUI.Popup.Menu.prototype.isVisible = function () {
        return this.dom && this.visible;
    };

    /**
     * Attach events listeners
     */
    GUI.Popup.Menu.prototype.attachEventListeners = function () {
        if (this.keyboardEvents) {
            this.removeEventListeners();
        }

        if (this.config.hideOnDocumentClick) {
            if (GUI.isIE) {
                GUI.Dom.on(document.body, 'mousedown', this.bodyClickHandler, this);
            } else if (GUI.isMobile) {
                GUI.Dom.on(document, 'touchstart', this.bodyClickHandler, this);
            } else {
                GUI.Dom.on(document, 'mousedown', this.bodyClickHandler, this);
            }
        }
        GUI.Dom.extend(this.dom);
        this.dom.on('click', this.menuClickHandler, this);
        this.dom.on('mouseover', this.mouseOverHandler, this);
        this.dom.on('mouseout', this.mouseOutHandler, this);

        hotkeys.on(new Keys(
            [KEY.adown],
            hotkeys.getScope(),
            (e) => this.keyboardFocusDown(e),
            [(this.config.hotkeysInput ? SETTINGS.PROCESS_INPUT_KEYBOARD : null)]
        ));
        hotkeys.on(new Keys(
            [KEY.aup],
            hotkeys.getScope(),
            (e) => this.keyboardFocusUp(e),
            [(this.config.hotkeysInput ? SETTINGS.PROCESS_INPUT_KEYBOARD : null)]
        ));

        hotkeys.on(new Keys([KEY.aleft], hotkeys.getScope(), (e) => this.hideOnlyCurrentSubNode(e)));
        hotkeys.on(new Keys([KEY.aright], hotkeys.getScope(), (e) => this.showOnlyCurrentSubNode(e)));

        hotkeys.on(new Keys(
            [KEY.enter],
            hotkeys.getScope(),
            (e) => this.keyboardFocusSelect(e),
            [(this.config.hotkeysInput ? SETTINGS.PROCESS_INPUT_KEYBOARD : null)]
        ));

        hotkeys.on(new Keys(
            [KEY.esc],
            hotkeys.getScope(),
            (e) => this.hideParent(e),
            [(this.config.hotkeysInput ? SETTINGS.PROCESS_INPUT_KEYBOARD : null)]
        ));
        hotkeys.on(new Keys(
            [KEY.tab],
            hotkeys.getScope(),
            (e) => this.hideParent(e),
            [(this.config.hotkeysInput ? SETTINGS.PROCESS_INPUT_KEYBOARD : null)]
        ));
    };

    /**
     * Removes events listeners
     */
    GUI.Popup.Menu.prototype.removeEventListeners = function () {
        if (this.config.hideOnDocumentClick && this.config.destroyOnHide) {
            GUI.Dom.un((GUI.isIE) ? document.body : document, 'mousedown', this.bodyClickHandler, this);
        }
        this.dom.un();
    };

    /**
     *
     */
    GUI.Popup.Menu.prototype.keyboardFocusDown = function (e) {
        e.preventDefault();
        this.updateFocusPosition(1);
    };

    /**
     *
     */
    GUI.Popup.Menu.prototype.keyboardFocusUp = function (e) {
        e.preventDefault();
        this.updateFocusPosition(-1);
    };

    /**
     *
     * @param direction
     */
    GUI.Popup.Menu.prototype.updateFocusPosition = function (direction) {
        var i, focusedItem,
            hasNormalItems = false;

        this.updateFocusPositionByDirection(direction);

        // this is not magic, slowly iterate and think as code
        focusedItem = this.config.items[this.focusedItemPosition];
        if (focusedItem.separator || focusedItem.disabled || focusedItem.noSelect || focusedItem.group) {
            // Iterate all items for search normal item for select
            for (i = 0; i < this.config.items.length; i++) {
                this.updateFocusPositionByDirection(direction);

                // Check next item (by direction) for normal state
                focusedItem = this.config.items[this.focusedItemPosition];
                if (!(focusedItem.separator || focusedItem.disabled || focusedItem.noSelect || focusedItem.group)) {
                    hasNormalItems = true;
                    break;
                }
            }
        } else {
            hasNormalItems = true;
        }

        if (!hasNormalItems) {
            return; // We don`t has normal items for select
        }

        this.selectItem(
            this.config.items[this.focusedItemPosition]
        );
    };

    /**
     *
     */
    GUI.Popup.Menu.prototype.hideOnlyCurrentSubNode = function (e) {
        e && e.preventDefault();

        if (this.parent) {
            this.hide();
        }
    };

    /**
     *
     */
    GUI.Popup.Menu.prototype.showOnlyCurrentSubNode = function (e) {
        e && e.preventDefault();

        var item = this.config.items[this.focusedItemPosition];
        if (item && item.sub) {
            this.showSubMenu(item.id, this.config);
        }
    };


    /**
     *
     * @param direction
     */
    GUI.Popup.Menu.prototype.updateFocusPositionByDirection = function (direction) {
        this.focusedItemPosition += direction;

        if (this.focusedItemPosition >= this.config.items.length) {
            this.focusedItemPosition = 0;
        }

        if (this.focusedItemPosition < 0) {
            this.focusedItemPosition = this.config.items.length - 1; // set up index
        }
    };

    /**
     *
     */
    GUI.Popup.Menu.prototype.keyboardFocusSelect = function (e) {
        e && e.preventDefault();

        this.openMenuItem(this.config.items[this.focusedItemPosition], e);
    };


    /**
     * Hides item
     * @param {Event} e Event
     */
    GUI.Popup.Menu.prototype.bodyClickHandler = function (e) {
        e = GUI.Event.extend(e);
        if (this.dom) {
            var close = this.mouseOverMenu(e, this);
            if (!close) {
                this.hide(undefined, e);
            }
        }
    };

    /**
     * Handler mouse over on menu
     * @param {Event} e Event
     * @param {Object} menu Menu object
     */
    GUI.Popup.Menu.prototype.mouseOverMenu = function (e, menu) {
        try {
            var i, ret = e.within(menu.getDom());
        } catch (e) {
            window.TrackJS && window.TrackJS.track(e);
            window.TrackJS && window.TrackJS.console.info(e);
            return false;
        }

        for (i = 0; i < menu.config.items.length; i++) {
            if (menu.config.items[i].sub) {
                ret = ret || this.mouseOverMenu(e, menu.config.items[i].sub);
            }
        }
        return ret;
    };

    /**
     * Handler click on menu
     * @param {Event} e Event
     */
    GUI.Popup.Menu.prototype.menuClickHandler = function (e) {
        e = GUI.Event.extend(e);

        var item,
            itemUl = e.target.findParent('li', this.dom, 'b-popup-menu__item');

        if (!itemUl) {
            return;
        }

        item = this.getItemById(itemUl.id);
        this.openMenuItem(item, e);
    };

    /**
     *
     * @param item
     * @param e
     */
    GUI.Popup.Menu.prototype.openMenuItem = function (item, e) {
        if (!item) {
            return;
        }

        if (!item.href) {
            e.preventDefault();
        }

        if (item.disabled || item.noSelect) {
            return;
        }

        if (item.message) {
            this.hide();
            return;
        }

        if (item.sub && !this.config.openOnHover) {
            this.showSubMenu(item.id, this.config);

        } else {
            if (this.canBeSelected) {
                this.select(item.name);
            }

            if (GUI.isFunction(item.handler)) {
                item.handler(item);
            }
            if (this.parent && this.parent.getRootMenu) {
                // Throw event to root menu
                this.parent.getRootMenu().fireEvent('click', this, item, e);
            } else {
                this.fireEvent('click', this, item, e);
            }

            if (GUI.isIE && item.href) {
                location.href = item.href;
            }

            if (this.config.hideAfterClick) {
                this.hideParent();
            }
        }
    };

    /**
     * Handler mouse over
     * @param {Event} e Event
     */
    GUI.Popup.Menu.prototype.mouseOverHandler = function (e) {
        e = GUI.Event.extend(e);
        var itemUl = e.target.findParent('li', this.dom, 'b-popup-menu__item');

        if (itemUl) {
            if (e.isMouseEnter(itemUl)) {
                this.onItemMouseEnter(itemUl);
            }
        }

        this.fireEvent('mouseOver', this, e);
    };

    /**
     * Handler mouse enter
     * @param {HTMLElement} itemDiv Div element
     */
    GUI.Popup.Menu.prototype.onItemMouseEnter = function (itemDiv) {
        var item = this.getItemById(itemDiv.id);
        if (item.disabled || item.message) {
            return;
        }

        if (this.subMenu && (this.subMenu !== item.sub)) {
            // and we did not moved from it, to item, that displays
            // this submenu, hide submenu
            if (this.config.openOnHover) {
                this.hideSubMenu();
            }
        }

        if (item.sub) {
            if (!item.sub.isVisible() && this.config.openOnHover) {
                // if we are over item, that has submenu, show it if it's not
                this.showSubMenu(itemDiv.id, this.config);
            }
        }
    };

    /**
     * Handler mouse out
     * @param {Event} e Event
     */
    GUI.Popup.Menu.prototype.mouseOutHandler = function (e) {
        e = GUI.Event.extend(e);

        var itemDiv = e.target.findParent('li', this.dom, 'b-popup-menu__item'),
            relatedTarget = e.getRelatedTarget();

        if (this.subMenu) {
            // if we have opened submenu
            if (!GUI.contains(this.subMenu.dom, relatedTarget) && this.config.openOnHover && !this.config.hideOnDocumentClick) {
                // and we did not moved to it, hide submenu
                this.hideSubMenu();
            }
        }

        if (e.isMouseLeave(this.dom)) {
            if (this.parent) {
                // We are submenu and mouse leaved us. Hide us if leaved not to
                // parent or sub menu
                if (this.subMenu && GUI.contains(this.subMenu.dom, relatedTarget)) {
                    // going to submenu, not hiding
                    return;
                }
                if (GUI.contains(this.parent.dom, relatedTarget)) {
                    // going to parent menu, not hiding
                    return;
                }
                if (this.config.openOnHover) {
                    this.hideParent();
                }
            }
            this.fireEvent('mouseleave', this, e);
        }
    };

    /**
     * Sets scroll top
     * @param {Event} e Event
     * @param {Object} scroller
     */
    GUI.Popup.Menu.prototype.onTopScrollerClick = function (e, scroller) {
        this.wrapperEl.scrollTop -= this.config.scrollStep;
    };

    /**
     * Sets scroller top
     * @param {Event} e Event
     * @param {Object} scroller
     */
    GUI.Popup.Menu.prototype.onBottomScrollerClick = function (e, scroller) {
        this.wrapperEl.scrollTop += this.config.scrollStep;
    };

    /**
     * Adds class 'x-menu-scroller-hover' to the scroller
     * @param {Event} e Event
     * @param {Object} scroller
     */
    GUI.Popup.Menu.prototype.onScrollerMouseEnter = function (e, scroller) {
        GUI.Dom.addClass(scroller, 'x-menu-scroller-hover');
    };

    /**
     * Removes class 'x-menu-scroller-hover' from the scroller
     * @param {Event} e Event
     * @param {Object} scroller
     */
    GUI.Popup.Menu.prototype.onScrollerMouseLeave = function (e, scroller) {
        GUI.Dom.removeClass(scroller, 'x-menu-scroller-hover');
    };

    GUI.Popup.Menu.prototype.suspendEvents = function () {

    };

    GUI.Popup.Menu.prototype.resumeEvents = function () {

    };

    GUI.Popup.Menu.prototype.showMask = function () {
        if (this.dom) {
            this.mask = new GUI.Popup.Mask();
            this.mask.setElement(this.dom);
            this.mask.show();
        }
    };

    GUI.Popup.Menu.prototype.hideMask = function () {
        if (this.mask) {
            this.mask.destroy();
            this.mask = null;
        }
    };

    GUI.Popup.Menu.prototype.destroy = function () {
        superproto.destroy.apply(this);
        if (this.dom) {
            GUI.destroyNode(this.dom);
        }
    };

}());
