(function () {
    /**
     * JavaScript Graphical User Interface
     * Component Manager implementation
     * All components have to be registered in component manager.
     * It provides global component registry. All registered components
     * can be accessed by their name
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.ComponentMgr = Class.create();

    /**
     * Constructor
     */
    GUI.ComponentMgr.prototype.initialize = function () {
        this._components = [];
        this._indexByName = {};

        // Global components namespace
        window.Cmp = {};
    };

    /**
     * Registered object
     * @param {String} name Name of the object
     * @param {Object} object Object to register
     */
    GUI.ComponentMgr.prototype.register = function (name, object) {
        if (!GUI.isString(name)) {
            // Component passed
            object = name;
            name = name.getId();
        }
        if (!name) {
            return;
        }
        var index,
            regName = this.isRegistered(object);
        if (regName) {
            throw new Error("Object is already registered with name: " + regName);
        } else {
            index = this._components.length;
            this._components[index] = object;
            this._indexByName[name] = index;
            // Insert into global namespace
            window.Cmp[name] = object;
        }
    };

    /**
     * Unregistered object
     * @param {Object} object Object to unregister
     */
    GUI.ComponentMgr.prototype.unregister = function (object) {
        var index,
            regName = this.isRegistered(object);
        if (!regName) {
            console.log("Object is not registered to unregister!", object);
        } else {
            index = this._indexByName[regName];
            delete this._components[index];
            delete this._indexByName[regName];
            // Delete from global namespace
            delete window.Cmp[regName];
        }
    };

    /**
     * Returns name if object is registered
     * @param {Object} object Object for check
     * @returns {String} name
     */
    GUI.ComponentMgr.prototype.isRegistered = function (object) {
        var i = this._components.length;
        while (i--) {
            if (this._components[i] === object) {
                return this._getNameByIndex(i);
            }
        }
        return false;
    };

    /**
     * Returns object by the name
     * @param {String} name
     * @returns {Object} object
     */
    GUI.ComponentMgr.prototype.get = function (name) {
        var index = this._indexByName[name];
        if (GUI.isNumber(index)) {
            return this._components[index];
        } else {
            return false;
        }
    };

    /**
     * Returns name of the object by the index
     * @param {Number} index
     * @returns {String} name
     */
    GUI.ComponentMgr.prototype._getNameByIndex = function (index) {
        var name;
        for (name in this._indexByName) {
            if (this._indexByName[name] === index) {
                return name;
            }
        }
        return false;
    };


    GUI.ComponentMgr = new GUI.ComponentMgr();

    GUI.getCmp = GUI.ComponentMgr.get.bindLegacy(GUI.ComponentMgr);

}());
