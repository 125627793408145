(function () {
    var superproto = GUI.Forms.TextField.prototype;

    /**
    * JavaScript Graphical User Interface.
    * Forms.SearchField implementation.
    *
    * @author Inyutka ^)
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.SearchField = Class.create();
    Object.extend(GUI.Forms.SearchField.prototype, superproto);

    /**
     * Css class of the element
     */
    GUI.Forms.SearchField.prototype.wrapperClass = 'b-text-field_search';

    /**
     * Browser title
     */
    GUI.Forms.SearchField.prototype.title = '';

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.SearchField.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_advanced {1}">' +
        '<i id="{2}" class="b-text-field__icon b-text-field__icon_visible fa fa-search">' +
        '<i class="s-icon"></i>' +
        '</i>' +
        '<i id="{3}" class="b-text-field__icon b-text-field__icon_label">' +
        '<span class="b-badge">1</span>' +
        '</i>' +
        '<i id="{4}" class="b-text-field__icon clean"></i>' +
        '<i class="i-text-field"></i>' +
        '</i>';

    /**
     * Initialize objects, call parent initialize.
     * @param {Object} cfg Configuration object
     */
    GUI.Forms.SearchField.prototype.initialize = function (cfg) {
        superproto.initialize.call(this, cfg);
    };

    /**
     * Call parent initComponent, create search task, list, json view,
     * template. Add events 'beforeApply', 'applySuccess', 'applyFailure',
     * 'searchSuccess', 'select'
     */
    GUI.Forms.SearchField.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.data = null;

        // Add events
        this.addEvents({
            beforeApply     : true,
            applySuccess    : true,
            applyFailure    : true,
            searchSuccess   : true,
            blur            : true,
            escape          : true,
            keyUp           : true,
            searchOnEnter   : true
        });
    };

    /**
     * Handler on render
     * @returns {HTMLElement} dom Dom of the elements
     */
    GUI.Forms.SearchField.prototype.onRender = function () {
        // Render text field
        this.fieldEl = superproto.onRender.call(this);

        var dom, wrapper, tmp, html;

        this.searchIconId = this.id + '-search-icon';
        this.clearIconId = this.id + '-clear-icon';
        this.countIconId = this.id + '-count-icon';

        // Render wrapper
        tmp = GUI.getFly();
        html = this.domTemplate.format(
            this.id,
            this.wrapperClass,
            this.searchIconId,
            this.countIconId,
            this.clearIconId
        );

        tmp.innerHTML = html; // TODO: Check IE leaks
        dom = tmp.removeChild(tmp.firstChild);

        // Now wrap textfield
        wrapper = GUI.Dom.findDescedents(dom, 'i.i-text-field')[0];
        wrapper.appendChild(this.fieldEl);

        if (this.maxWidth) {
            dom.style.maxWidth = this.maxWidth + 'px';
        }

        return dom;
    };

    /**
     * Handler on after render
     * @param {HtmlElement} dom Dom of the elements
     */
    GUI.Forms.SearchField.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, this.fieldEl);

        if (this.title) {
            this.dom.setAttribute('title', this.title);
        }

        this.searchIconEl = this.dom.firstChild;
        if (!this.config.searchIcon) {
            GUI.hide(this.searchIconEl);
        } else {
            this.searchIconEl.on('click', this.doSearch, this);
        }

        if (this.searchIconTitle) {
            this.searchIconEl.setAttribute('title', this.searchIconTitle);
        }

        this.countIconEl = this.dom.firstChild.nextSibling;
        if (this.countIconTitle) {
            this.countIconEl.setAttribute('title', this.countIconTitle);
        }
        this.hideCount();

        this.clearIconEl = this.dom.firstChild.nextSibling.nextSibling;
        if (this.clearIconTitle) {
            this.clearIconEl.setAttribute('title', this.clearIconTitle);
        }
        this.hideClearIconEl();

        if (this.config.clearIcon) {
            this.clearIconEl.on('click', this.cancelSearch, this);
        }

        if (this.value) {
            this.onChange(null, this.value);
        }
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.setCount = function (total) {
        if (this.config.countIcon && this.countIconEl && this.countIconEl.firstChild) {
            this.countIconEl.firstChild.innerHTML = parseInt(total, 10);
            this.showCount();
        }
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.hideCount = function () {
        GUI.hide(this.countIconEl);
    };
    /**
     *
     */
    GUI.Forms.SearchField.prototype.showCount = function () {
        GUI.show(this.countIconEl);
    };


    /**
     * Hide search results, show loader, send request
     */
    GUI.Forms.SearchField.prototype.doSearch = function (e) {
        this.fireEvent('beforeDoSearch');
        var params = {};

        if (this.searchMethod) {
            this.searchMethod(e);
            return;
        } else if (!this.searchConfig) {
            return;
        }
        params[this.searchConfig.paramName] = this.getValue();

        if (this.searchConfig.baseParams) {
            Object.extend(params, this.searchConfig.baseParams);
        }

        this.abortRequest();

        this._request = new Ajax.Request(this.searchConfig.url, {
            parameters  : GUI.toQueryString(params),
            method      : this.searchConfig.method || 'post',
            scope       : this,
            onSuccess   : this.onSearchSuccess,
            onFailure   : this.onSearchFailure
        });
    };

    /**
     * Abort the request
     */
    GUI.Forms.SearchField.prototype.abortRequest = function () {
        if (this._request && !this._request.complete) {
            this._request.abort();
        }
    };

    /**
     * Abort the request and clear value of the field
     */
    GUI.Forms.SearchField.prototype.cancelSearch = function () {
        this.abortRequest();
        this.setValue('');
        this.doSearch();
        this.fireEvent('cancelSearchClick');
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.showClearIconEl = function () {
        GUI.show(this.clearIconEl);
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.hideClearIconEl = function () {
        GUI.hide(this.clearIconEl);
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.focus = function () {
        superproto.focus.call(this);
        this.fieldEl.focus();
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.blur = function () {
        superproto.blur.call(this);
        this.fieldEl.blur();
        this.fireEvent('blur');
    };

    /**
     * Hide loader, show search results if it is not empty or hide list.
     * Fire event 'searchSuccess'
     * @param {Object} transport
     * @param {Object} request
     */
    GUI.Forms.SearchField.prototype.onSearchSuccess = function (transport, request) {
        try {
            var data = request.responseJson;
            this.data = data;

            if (data.total) {
                this.setCounts(data.total);
            }

            this.fireEvent('searchSuccess', this, data);
        } catch (e) { }
    };

    /**
     * empty function
     */
    GUI.Forms.SearchField.prototype.onSearchFailure = function () {
        //
    };

    /**
     * Initialize events
     */
    GUI.Forms.SearchField.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
        this.dom.on('keyup', this.onKeyUp2, this);
        this.on('change', this.onChange, this);
    };

    /**
     * Call parent remove events listeners
     */
    GUI.Forms.SearchField.prototype.removeEventListeners = function () {
        superproto.removeEventListeners.call(this);
        this.dom.un();
    };

    /**
     * If searchFor add text to this element.
     * @param {Event} e Event
     *
     */
    GUI.Forms.SearchField.prototype.onKeyUp2 = function (e) {
        e = GUI.Event.extend(e);

        if (this.getValue()) {
            this.showClearIconEl();
        } else {
            this.hideClearIconEl();
        }

        switch (e.e.keyCode) {
        case e.KEY_RETURN:
            this.doSearch(e);
            this.fireEvent('searchOnEnter');
            break;

        case e.KEY_ESC:
            this.fireEvent('escape');
            return;
            break;
        }

        this.fireEvent('keyUp', e);
    };

    /**
     *
     */
    GUI.Forms.SearchField.prototype.onChange = function (field, value, oldValue) {
        if (value === '' || !value) {
            this.hideClearIconEl();
        } else {
            this.showClearIconEl();
        }
    };

}());