(function () {
var superproto = GUI.Forms.TriggerField.prototype;

/**
 * JavaScript Graphical User Interface
 * Forms.Combo implementation
 *
 * @author Lucenko Viacheslav
 * @version 2.0
 * @namespace GUI.Forms
 * @extends GUI.Forms.TriggerField
 */

GUI.Forms.SelectBox = Class.create();
Object.extend(GUI.Forms.SelectBox.prototype, superproto);

Object.extend(GUI.Forms.SelectBox.prototype, {
    type: 'selectBox',
    listHeight: 8,
    listWidth: 'auto',
    listClass: 'b-popup-menu b-popup-menu_main-menu b-popup-menu_style_combo',
    itemClass: 'b-popup-menu__item',
    selectedClass: 'b-text-field_advanced_focus',
    textFieldClass: '',
    hintClass: '',
    invalidClass: '',
    hintOffsetX: -7,
    hintOffsetY: 2,
    valueField: 'value',
    listField: 'text',
    textField: 'text',
    descriptionField: 'description',
    placeholder: '',
    hiddenInput: true,
    autoWidth: false,
    autoWidthOnBlur: false,
    autoReapplyAutoWidth: false,
    minWidth: 100,
    maxWidth: 200,
    width: 150,
    triggerEvent: 'click',
    triggerElClassName: 'i.b-text-field__icon',
    value: '',
    autoSetDefaultValue: false,
    icons: false,
    autocomplete: false,
    selectOnFocus: true,
    showListOnClick: true,
    minSearchCharacters: 1,
    searchDelay: 1,
    showHintsForLongText: true,
    triggerOnFieldClick: true,
    options: null,
    listParentNode: document.body,
    clearSearchOnClose: false,

    domTemplate: '<i id="{0}" class="b-text-field_advanced b-text-field_combo {1}">' +
                 '<i class="b-text-field__icon dropdown"></i>' +
                 '<i class="b-text-field__icon {3}"></i>' +
                 '<i class="i-text-field">' +
                 '<i class="b-text-field b-text-field_clone {2}"></i>' +
                 '</i>' +
                 '</i>',

    initialize: function () {
        superproto.initialize.apply(this, arguments);
    },

    initComponent: function () {
        superproto.initComponent.call(this);

        this.addEvents({
            beforeListShow  : true,
            listShow        : true,
            afterListShow   : true,

            beforeSelect    : true,
            select          : true,
            afterSelect     : true,

            beforeListHide  : true,
            afterListHide   : true,

            hideEsc         : true,
        });
    },

    onBeforeAssign: function (dom) {
        return dom.nodeName === 'SELECT' && !dom.multiple;
    },

    onAssign: function (elem) {
        superproto.onAssign.call(this, elem);
        var listWidth, listHeight, minWidth, maxWidth, option, len, optionText, hint, hintposition,
            options = [], icons, optionIcon, optionImg, optionDisabled, title,
            i = 0;

        this.autoWidth = !!elem.getAttribute('autoWidth');

        listWidth = elem.getAttribute('listWidth');
        if (GUI.isString(listWidth) && listWidth.length) {
            this.listWidth = parseInt(listWidth, 10);
        }

        listHeight = elem.getAttribute('listHeight');
        if (GUI.isString(listHeight) && listHeight.length) {
            this.listHeight = parseInt(listHeight, 10);
        }

        minWidth = elem.getAttribute('minWidth');
        if (GUI.isSet(minWidth)) {
            this.minWidth = parseInt(minWidth, 10);
        }

        maxWidth = elem.getAttribute('maxWidth');
        if (GUI.isSet(maxWidth)) {
            this.maxWidth = parseInt(maxWidth, 10);
        }

        icons = elem.getAttribute('icons');
        if (GUI.isSet(icons)) {
            this.icons = true;
        }

        title = elem.getAttribute('title');
        if (GUI.isSet(title)) {
            this.title = title;
        }

        hint = elem.getAttribute('hint');
        if (GUI.isSet(hint)) {
            this.hint = hint;
        }

        // Load options
        options = elem.options;
        this.suspendEvents();
        for (i = 0, len = options.length; i < len; i++) {
            option = options[i];

            if (option.getAttribute('separator')) {
                this.add({separator: true});
                continue;
            }

            optionText = option.getAttribute('text');
            optionIcon = option.getAttribute('icon');
            optionImg = option.getAttribute('img');
            optionDisabled = option.getAttribute('disabled');
            hint = option.getAttribute('hint');
            hintposition = option.getAttribute('hintposition');

            var attrKey,
                params = {};

            for (attrKey = 0; attrKey < option.attributes.length; attrKey++) {
                params[option.attributes[attrKey].nodeName] = option.attributes[attrKey].nodeValue;
            }

            this.add(Object.extend(params, {
                text    : optionText || option.text,
                value   : option.value,
                icon    : optionIcon || option.icon,
                img     : optionImg || option.img,
                disabled : optionDisabled || false,
                hint    : hint,
                hintPosition: hintposition,
                selected: option.defaultSelected || option.selected // Shit... Browsers sets selected on onLoad, but we  run after domload :-(. So defaultselected = true and selected = false...
            }));
        }
        this.resumeEvents();
    },

    onAfterRender: function (dom) {
        var focusEl;
        this.iconEl = GUI.Dom.findDescedents(dom, 'i.b-text-field__icon ' + this.iconClass)[0];
        this.divEl = GUI.Dom.findDescedents(dom, 'i.b-text-field b-text-field_clone')[0];

        if (!GUI.LTR) {
            this.divEl.setAttribute('dir', 'auto');
        }

        focusEl = GUI.Dom.extend(dom.getElementsByTagName('input')[0]);
        focusEl.addClass(this.textFieldClass);
        focusEl.on('focus', this.onFocus, this);
        focusEl.on('blur', this.onBlur, this);
        this.focusEl = focusEl;

        this.fieldEl.on('focus', this.focus, this);
        this.fieldEl.on('blur', this.blur, this);

        if (GUI.isMobile && !this.autocomplete) {
            this.fieldEl.setAttribute('readonly', true);
        }

        if (this.title) {
            this.dom.setAttribute('title', this.title);
        }

        if (this.autocomplete && this.placeholder) {
            this.fieldEl.setAttribute('placeholder', '');
        }

        if (this.autoWidth) {
            this.doAutoWidth();
        }

        if (this.hint) {
            this.dom.setAttribute('hint', this.hint);
        }

        if (this.unselectable) {
            if (GUI.isIE) {
                this.dom.unselectable = 'on';
                this.divEl.unselectable = 'on';
            }
        }

        if (this.width) {
            this.dom.style.width = GUI.isNumber(this.width) ? this.width + 'px' : this.width;
        }

        if (this.minWidth) {
            this.dom.style.minWidth = GUI.isNumber(this.minWidth) ? this.minWidth + 'px' : this.minWidth;
        }

        if (this.maxWidth) {
            this.dom.style.maxWidth = GUI.isNumber(this.maxWidth) ? this.maxWidth + 'px' : this.maxWidth;
        }

        this.list = new GUI.Popup.Menu({
            className: this.listClass,
            disableDivScroll: true,
            icons: this.icons,
            destroyOnHide: false,
            hideOnDocumentClick: false,
            hintPosition: 'right',
            canBeSelected: true,
            hideAfterClick: false,
            setzIndex: true,
            listHeight: this.config.listHeight,
            parentNode: this.config.listParentNode,
            onClick: this.onMenuSelect.bindLegacy(this),
            hotkeysInput: true,
        });

        if (GUI.isArray(this.options)) {
            var options = this.options;
            this.options = new GUI.Utils.Collection();
            this.setOptions(options);
        } else {
            this.options = new GUI.Utils.Collection();
        }

        superproto.onAfterRender.call(this, dom);
    },

    attachEventListeners: function () {
        this.divEl.on('click',  this.onClick,    this);
        this.iconEl.on('click',  this.onClick,    this);

        this.triggerEl.on(this.triggerEvent, this.onTriggerClick, this);
        this.triggerEl.on('click', this.preventDefaultEvent);

        if (this.triggerOnFieldClick) {
            this.fieldEl.on(this.triggerEvent, this.onTriggerClick, this);
        }

        if (this.defaultText) {
            this.on('blur',  this.postBlur, this);
            this.on('focus', this.preFocus, this);
            this.applyDefaultText();
        }

        GUI.Dom.on((GUI.isIE) ? document.body : document, 'click', this.bodyClickHandler, this);
    },

    detachEventListeners: function () {
        this.divEl.un('click',  this.onClick,    this);
        this.iconEl.un('click',  this.onClick,    this);

        this.triggerEl.un(this.triggerEvent, this.onTriggerClick, this);
        this.triggerEl.un('click', this.preventDefaultEvent);

        if (this.triggerOnFieldClick) {
            this.fieldEl.un(this.triggerEvent, this.onTriggerClick, this);
        }

        if (this.defaultText) {
            this.un('blur',  this.postBlur, this);
            this.un('focus', this.preFocus, this);
        }

        GUI.Dom.un((GUI.isIE) ? document.body : document, 'click', this.bodyClickHandler, this);
    },

    onClick: function (e) {
        if (this.disabled) {
            return;
        }

        this.dom.addClass(this.selectedClass);

        if (this.list.isVisible()) {
            this.hideList();

        }

        if (this.showListOnClick) {
            this.showList();

            if (!this.autocomplete) {
                this.dom.addClass(this.selectedClass);
            }
        }

        if (this.autocomplete) {
            this.showTextField();
        }
    },

    onTriggerClick: function () {
        if (this.disabled) {
            return false;
        }

        if (this.list.isVisible()) {
            this.hideList();
            return;
        }

        this.dom.addClass(this.selectedClass);

        this.showList();
        if (this.autocomplete) {
            this.showTextField();
        }
    },

    bodyClickHandler: function (e) {
        e = GUI.Event.extend(e);

        if (e.target.tagName === 'svg' || e.target.tagName === 'path') {
            return;
        }

        if (this.list.dom) {
            if (e.target.tagName !== 'svg' && e.within(this.dom)) {
                return;
            }

            var close = this.list.mouseOverMenu(e, this.list);
            if (!close) {
                this.hideList();
            }
        }

        this.dom.removeClass(this.selectedClass);
    },

    add: function (option) {
        option = Object.clone(option);

        if (!option.id) {
            option.id = GUI.getUniqId('');
        }

        option.domId = this.id + '-item-'  + option.id;
        this.options.add(option);

        this.list.setItems(this.options.items);
    },

    clear: function () {
        this.options.clear();
        this.list.setItems([]);

        this.setValue('', true);
    },

    setOptions: function (options) {
        this.options.clear();

        for (i = 0, len = options.length; i < len; i++) {
            this.add(options[i]);
        }

        if (this.autoWidth && this.autoReapplyAutoWidth && this.dom) {
            this.doAutoWidth();
        }
    },

    showList: function (items) {
        items = items || this.options.items;

        this.fireEvent('beforeListShow', items, function (newItems) {
            items = newItems;
        });

        if (this.listWidth === 'auto') {
            this.list.config.width = this.dom.getBoundingClientRect().width; //GUI.getClientWidth(this.dom);
        } else {
            this.list.config.width = this.listWidth;
        }

        this.hideList();
        this.options.clear();
        this.setOptions(items);

        this.list.alignTo(this.dom, 'tl-bl?');
        this.list.show();
        this.list.suspendEvents();

        this.list.selectItem(
            this.findItemByValue(this.value)
        );

        this.fireEvent('listShow');
    },

    hideList: function () {
        if (false === this.fireEvent('beforeListHide')) {
            return;
        }

        this.list.hide();
    },

    setValue: function (value) {
        this.value = value;

        this.selectItem(
            this.findItemByValue(value)
        );
    },

    getValue: function () {
        return this.value;
    },

    findItemByValue: function (value) {
        return this.options.find(function (item) {
            if (item.value === value) {
                return true;
            }
        }, this);
    },

    selectItem: function (findedItem) {
        if (findedItem) {
            if (this.list) {
                this.list.selectItem(findedItem);
            }

            findedItem.selected = true;
            this.value = findedItem.value;
            this.fieldEl.value = findedItem.value;
            this.divEl.innerHTML = findedItem[this.textField] || this.placeholder;

            if (this.iconEl) {
                if (findedItem.iconClass) {
                    this.iconEl.className = "b-text-field__icon icon " + findedItem.iconClass;
                    this.iconEl.innerHTML = '';
                    this.iconEl.style.display = '';

                } else if (findedItem.img) {
                    this.iconEl.className = 'b-text-field__icon icon';
                    this.iconEl.innerHTML = '<img src="' + findedItem.img + '">';
                    this.iconEl.style.display = '';

                } else if(findedItem.tpl) {
                    this.iconEl.className = 'b-text-field__icon icon';
                    this.iconEl.innerHTML = findedItem.tpl;
                    this.iconEl.style.display = '';

                } else {
                    this.iconEl.style.display = 'none';
                }
            }
        } else {
            if (this.list) {
                this.list.selectItem(null);
            }

            this.value = null;
            this.fieldEl.value = '';
            this.divEl.innerHTML = this.placeholder;

            if (this.iconEl) {
                if (this.iconClass) {
                    this.iconEl.className = "b-text-field__icon icon " + this.iconClass;
                    this.iconEl.style.display = '';

                } else {
                    this.iconEl.style.display = 'none';
                }
            }
        }
    },

    onMenuSelect: function (menu, item, e) {
        e.preventDefault();
        e.stopPropagation();

        this._searchText = '';


        this.fireEvent('beforeSelect', item, function (newItem) {
            item = newItem;
        });

        if (!item) {
            return;
        }

        this.selectItem(item);

        if (this.autocomplete) {
            this.hideTextField();
        }

        this.hideList();

        this.fireEvent('afterSelect', item);
    },

    showTextField: function () {
        var selItem = this.list.selectedItem;

        this.fieldEl.value = this._searchText
            ? this._searchText
                : GUI.html_entity_decode(selItem ? selItem[this.textField] : '');

        this.dom.removeClass('b-text-field_combo');
        this.dom.addClass('b-text-field_combo_editable');

        GUI.hide(this.divEl);

        this.textFieldVisible = true;

        this.fieldEl.un();
        this.fieldEl.on('keydown', this.onInputKeyDown, this);
        this.fieldEl.on('blur', this.onBlur, this);
        this.fieldEl.select();
    },

    hideTextField: function () {
        this.dom.removeClass('b-text-field_combo_editable');
        this.dom.addClass('b-text-field_combo');
        GUI.show(this.divEl);

        if (this.fieldEl.listeners) {
            this.fieldEl.un('keydown', this.onInputKeyDown, this);
            this.fieldEl.un('blur', this.onBlur, this);
        }

        this.textFieldVisible = false;
    },

    onInputKeyDown: function (e) {
        e = GUI.Event.extend(e);

        this._lastInputKey = e.getKey();
        switch(e.e.which) {
            case GUI.Utils.keysToCode.enter:
                e.preventDefault();
                this.list.keyboardFocusSelect(e);
                break;
            case GUI.Utils.keysToCode.esc:
            case GUI.Utils.keysToCode.tab:
                e.preventDefault();
                this.fieldEl.blur();
                if (this.config.clearSearchOnClose) {
                    this.setValue('');
                    this.fieldEl.value = '';
                    this.searchTask();
                }
                this.hideList();
                this.fireEvent('hideEsc');

                break;
            case GUI.Utils.keysToCode.adown:
            case GUI.Utils.keysToCode.aup:
            case GUI.Utils.keysToCode.aleft:
            case GUI.Utils.keysToCode.aright:
                // do nothing
                break;
            default:
                if (this.timeoutDelay) {
                    clearTimeout(this.timeoutDelay);
                }

                this.timeoutDelay = setTimeout(function () {
                    this.timeoutDelay = null;
                    if (this.fieldEl.value !== '') {
                        if (this.autocomplete && !e.isSpecialKey()) {
                            this.searchTask.bindLegacy(this).defer(this.searchDelay);
                        }
                    } else {
                        this.searchTask();
                    }
                }.bindLegacy(this), this.searchDelay);
                break;
        }
    },

    onBlur: function () {
        this.dom.removeClass(this.selectedClass);

        if (this.list.isVisible()) {
            superproto.onBlur.call(this);
            if (this.autocomplete && this.textFieldVisible) {
                this.hideTextField();
            }
        }
    },

    searchTask: function () {
        this._searchText = this.fieldEl.value;
        this.fireEvent('search', this.afterSearchComplete.bindLegacy(this), this._searchText);
    },

    afterSearchComplete: function (options) {
        if (!options.length) {
            options = [{
                noSelect: true,
                text: i18n('Nothing found')
            }]
        }

        this.showList(options);
    },

    getSearchText: function () {
        return this._searchText;
    },

    focus: function () {
        this.onTriggerClick();
        this.triggerEl.focus();
    },

    setDivText: function (text) {
        this.divEl.innerHTML = text;
    },

    showMask: function () {
        this.list.showMask();
    },

    hideMask: function () {
        this.list.hideMask();
    },

    destroy: function () {
        this.list.destroy();
        this.detachEventListeners();
        superproto.destroy.apply(this, arguments);
    }
});
    
})();