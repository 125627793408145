(function () {
    var superproto = GUI.Forms.TextField.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.PasswordField implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.PasswordField = Class.create();
    Object.extend(GUI.Forms.PasswordField.prototype, superproto);

    /**
     * Type of the field, 'password'
     */
    GUI.Forms.PasswordField.prototype.type = 'password';

    /**
     * Css class of the field, default is 'b-text-field'
     */
    GUI.Forms.PasswordField.prototype.className = 'b-text-field';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.PasswordField.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

}());