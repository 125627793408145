(function () { // variables isolation

    var EmailsFormClass = window.Class.create(),
        superproto = new GUI.Utils.Observable(),
        prototype = {
            //_dialog   : null,
            //_showed   : false,
            //_formMask : null,
            _emailsForm : {
                holder : null,
                form   : null
            },

            confirmDelete : function (element) {
                var url = element.href;

                Application.ConfirmForm.setCallerElement(element, '', 'delete', 'delete-dialog-form', 'delete-form');
                Application.ConfirmForm.setActionUrl(url);

                Application.ConfirmForm.show(true);
            }
        };

    Object.extend(EmailsFormClass.prototype, superproto);
    Object.extend(EmailsFormClass.prototype, prototype);

    Application.EmailsForm = new EmailsFormClass();

}());