import {hotkeys, Keys, KEY, MODIFIER} from "library/keyboard";

(function () {
    var superproto = GUI.Popup.Region.prototype;

    /**
     * JavaScript Graphical User Interface
     * GUI.Popup.Dialog implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Popup.Region
     */
    GUI.Popup.Dialog = Class.create();
    Object.extend(GUI.Popup.Dialog.prototype, superproto);

    /**
     * Template of the dialog
     * @type String
     */
    GUI.Popup.Dialog.prototype.dialogTemplate =
        '<div id="{this.iconsBarId}" class="b-dialog__ttl">' +
        '<span id="{this.titleId}" class="b-dialog__ttl-txt">{title}</span>' +
        '</div>' +
        '<div id="{this.contentDataId}" >' +
        '<div id="{this.contentId}" class="b-dialog__content"> {content} </div>' +
        '</div>';

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Popup.Dialog.prototype.initialize = function (config) {
        var defCfg, cfg, i, t,
            titleId, tb, tbItem;

        this.types = {
            error       : '',
            information : ''
        };

        defCfg = {
            id          : GUI.getUniqId('dialog-'),
            cls         : 'b-dialog',
            modal       : false,
            title       : '',
            alignFooterButtons: 'right',
            toolbuttons : [
                {name: 'close', img: 'close'}
            ],
            dimensions  : {
                left : 0,
                top  : 0
            },
            /**
             * True to do not allow user to drag window outside of viewport
             */
            constrainViewport   : true,
            zIndex      : 5000,
            usePopupMenu: false,
            disabledDD  : false,
            visible     : false,
            animate     : window.GUI_FX_ANIMATION === true, // boolean convertation
            toolbar     : null,
            toolbarHeight   : 35,
            hotkeysScope: GUI.getUniqId('popup-dialog-'),

            // Drag'n'Drop configuration
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                isTarget    : true,
                moveOnly    : true,
                groups      : {
                    'dialog' : true
                },
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            },
            alwaysOnTop     : false,
            minimizeClass   : '',
            maximizeClass   : '',
            autoDestroy     : false,
            unrender        : true,
            allowScrollbars : true,
            closeOnEsc      : true,
            autoCenter      : true
        };

        Object.extend(defCfg, config);

        // Init dd id's
        defCfg.dd.linkedElId = defCfg.id;

        titleId = GUI.getUniqId('dialog-title-');
        defCfg.dd.handleElId = titleId;

        // Call parent initialize meethod
        superproto.initialize.call(this, defCfg);

        Object.extend(this.config, defCfg);
        cfg = this.config;

        if (GUI.isIE) {
            cfg.animate = false;
        }

        if (cfg.usePopupMenu) {
            cfg.zIndex = 95;
        }

        this.WM             = GUI.Utils.WM;
        this.elementType    = 'GUI.Popup.Dialog';
        this.alignArgs      = null;
        this.visible        = cfg.visible;
        this.scrollable     = false;
        this.state          = 'normal'; // normal, minimized, maximized
        this.iconsBarId     = GUI.getUniqId('dialog-iconsbar-');
        this.contentId      = GUI.getUniqId('dialog-content-');
        this.contentDataId  = GUI.getUniqId('dialog-content-data-');
        this.footerId       = GUI.getUniqId('footer-buttons-');
        this.titleId        = titleId;
        this.glass          = null;
        this.rendered       = false;
        this.restoreDimensions    = null;
        this.closeOnHide    = false;
        this.alwaysOnTop    = cfg.alwaysOnTop;

        this.beforeLimitDim = {};
        this.originalDim    = {};

        this.defHandlers = {
            close     : this.close,
            minimize  : this.minimize,
            maximize  : this.maximize,
            restore   : this.restore
        };

        tb = this.config.toolbuttons;
        if ((typeof this.config.maximizable === 'undefined') && GUI.isArray(tb)) {
            i = tb.length;
            while (i--) {
                tbItem = tb[i];
                if (tbItem.name === 'maximize') {
                    this.config.maximizable = true;
                    break;
                } else if (tbItem.name === 'close') {
                    tbItem.title = i18n('Close') + ' (Esc)';
                }
            }
        }

        // Add events
        this.addEvents({
            close        : true,
            dialogAction : true,
            resize       : true,
            beforeClose  : true,
            restore      : true,
            maximize     : true,
            closeOnEsc   : true
        });

        if (!GUI.isSet(this.config.dimensions.width)) {
            this.config.dimensions.width = '';
        }

        if (!GUI.isSet(this.config.dimensions.height)) {
            this.config.dimensions.height = '';
        }

        if (cfg.type) {
            t = this.types[cfg.type];
            if (t) {
                cfg.typeClass = t;
            }
        }
    };

    /**
     * Creates dialog window, renders toolbox, toolbar if needed.
     * Initializes Drag'n'Drop, creates animator objects.
     */
    GUI.Popup.Dialog.prototype.create = function () {
        var maskZIndex, tpl, node, a, footer, footerSidebar, i, len, buttons, footerElements,
            cfg = this.config;

        if (!cfg.parentNode) {
            cfg.parentNode = document.body;
        }

        // If modal, precreate mask
        if (cfg.modal) {
            maskZIndex = (GUI.isNumber(cfg.zIndex))
                ? cfg.zIndex - 1
                : GUI.Popup.Region.getzIndex();

            this.glass = new GUI.Popup.Region({
                parentNode  : cfg.parentNode,
                zIndex      : maskZIndex,
                className   : 'b-mask'
            });
            this.glass.takeDocument(true);
        }

        // Create dialog window
        tpl = new GUI.STemplate(this.dialogTemplate, this);
        tpl.title = cfg.title;

        // output content
        if (typeof cfg.content === 'string') {
            tpl.content = cfg.content;
        } else if (cfg.content.innerHTML !== undefined) {
            tpl.content = cfg.content.innerHTML;
        } else {
            throw new Error(this.elementType + ' create() error: cfg.content is not string or domnode!');
        }

        // creates toolbar
        if (GUI.isArray(cfg.toolbar)) {
            if (!GUI.isSet(cfg.toolbarHeight)) {
                throw new Error('Dialog: You have to specify toolbarHeight when using toolbar!');
            }

            // tabbar
            if (cfg.tabbar) {
                this.tabbar = new GUI.TabBar({
                    background  : false,
                    elements	: cfg.tabbar
                });
            }

            // toolbar
            this.toolbar = new GUI.ToolBar({
                type        : 'dialog',
                //background  : true,
                //radius      : 'top',
                //extraClass  : (this.tabbar) ? 'b-toolbar_with-tabs' : '',
                sidebar     : this.tabbar,
                elements    : cfg.toolbar
            });
        } else {
            cfg.toolbarHeight = -2;
        }

        // Create new dom node
        node = document.createElement('div');
        node.id = cfg.id;
        node.style.position = 'absolute';
       // console.log(cfg.zIndex)
        node.style.zIndex = cfg.zIndex || GUI.Popup.Region.getzIndex();
        node.className = cfg.cls;

        GUI.hide(node);

        if (GUI.isIE) {
            cfg.parentNode.appendChild(node); // Before innerHTML assignment to prevent IE memory leaks, do not move after!
            node.innerHTML = tpl.fetch();
        } else {
            node.innerHTML = tpl.fetch(); // Performance mode for other browsers
            cfg.parentNode.appendChild(node);
        }

        if (cfg.typeClass) {
            GUI.Dom.extend(node.firstChild).addClass(cfg.typeClass);
        }

        this.dom = GUI.Dom.extend(node);
       // console.log(this.dom.style.zIndex);
        a = document.createElement('a');
        a.className = 'g-focus';
        a.href = '#';
        a.tabIndex = -1;
        node.appendChild(a);
        a.innerHTML = '&#160;';
        this.focusEl = a;

        if (this.scrollable) {
            GUI.$(this.contentId).style.overflow = 'auto';
        }

        // Render toolbox
        this.toolBox = new GUI.ToolBox({
            items   : cfg.toolbuttons,
            scope   : this,
            renderAfterBeginHolder  : true,
            onClick : this.onToolBoxClick
        });
        this.toolBox.render(GUI.$(this.iconsBarId));

        // render toolbar if needed
        if (this.toolbar) {
            this.toolbar.render(GUI.$(this.contentDataId));
        }

        buttons = cfg.footerButtons;
        footerElements = cfg.footerLeftElements;

        footer = document.createElement('DIV');
        footer.id = this.footerId;
        footer.className = 'b-toolbar b-buttons-list';

        footerSidebar = document.createElement('DIV');
        footerSidebar.id = this.footerId + '-sidebar';
        footerSidebar.className = 'b-toolbar__sidebar';

        footer.appendChild(footerSidebar);

        GUI.$(this.contentDataId).appendChild(footer);
        this.footer = footer;
        this.footerSidebar = footerSidebar;

        var footerActive = false;

        if (buttons && buttons.length) {

            len = buttons.length;
            for (i = 0; i < len; i++) {
                buttons[i].obj.render(footerSidebar);
                if (buttons[i].name) {
                    this[buttons[i].name] = buttons[i].obj;
                }
            }
            cfg.toolbarHeight = 36;
            footerActive = true;
        }

        if (footerElements && footerElements.length) {
            len = footerElements.length;
            for (i = 0; i < len; i++) {
                footerElements[i].obj.render(footer);
                if (footerElements[i].name) {
                    this[footerElements[i].name] = footerElements[i].obj;
                }
            }
            cfg.toolbarHeight = 36;

            footerActive = true;
        }

        if (footerActive === false) {
            this.config.footerButtons = false;
            this.footer.style.display = 'none';
            cfg.toolbarHeight = 0;
        }

        this.attachEvents();

        // Initialize Drag'n'Drop
        if (!this.config.disabledDD) {
            this.initDD();
            this.setHandleElId(this.titleId);
        } else {
            GUI.addClass(
                document.getElementById(this.iconsBarId),
                'b-dialog__ttl_noMouseMove'
            );
        }

        this.WM.register(this);
        this.rendered = true;
        this.fireEvent('create', this);
    //    console.log(this.dom.style.zIndex);
        this.dimension = Object.clone(cfg.dimensions);

        this.setDimensions(cfg.dimensions);

        // Create animator
        if (cfg.animate) {
            this.initFx();
        }

        if (this.visible) {
            this.show();
        }
    };

    /**
     * Initialize animation objects
     */
    GUI.Popup.Dialog.prototype.initFx = function () {
        this.fadeEf = new Animator({
            duration    : 500,
            scope       : this,
            onComplete  : this.onFadeEfComplete
        });
        // Causes font rendering glitches if 0 < opacity < 1 in FF3
        this.fadeEf.addSubject(new window.NumericalStyleSubject(this.dom, 'opacity', 0.1, 1));

        this.fadeClose = new Animator({
            duration    : 500,
            scope       : this,
            onComplete  : this.onFadeCloseComplete
        });
        this.fadeEf.addSubject(new window.NumericalStyleSubject(this.dom, 'opacity', 0.1, 1));
    };

    /**
     * If state of the animator is true run show else hide method.
     * @param {Object} fx Animator
     */
    GUI.Popup.Dialog.prototype.onFadeEfComplete = function (fx) {
        if (fx.state) {
            this._show();
        } else {
            this._hide();
        }
    };

    /**
     * Run close method
     * @param {Object} fx Animator
     */
    GUI.Popup.Dialog.prototype.onFadeCloseComplete = function (fx) {
        this._close();
    };

    /**
     * Unrenders objects
     */
    GUI.Popup.Dialog.prototype.unrender = function () {
        if (this.rendered) {
            this.WM.unregister(this);
            // Destroy Drag'n'Drop
            this.destroyDD();

            this.removeEvents();

            if (this.fadeEf) {
                this.fadeEf.destroy();
            }
            if (this.toolBox) {
                this.toolBox.unrender();
            }
            if (this.toolbar) {
                this.toolbar.unrender();
            }
            if (this.tabbar) {
                this.tabbar.unrender();
            }

            if (this.glass && this.glass.dom) {
                this.glass.hide();
            }

            GUI.destroyNode(this.dom);

            this.dom = this.focusEl = null;

            this.rendered = false;

            hotkeys.removeScope(this.config.hotkeysScope);
        }
    };

    /**
     * Destroys objects
     */
    GUI.Popup.Dialog.prototype.destroy = function () {
        if (this.fadeEf) {
            this.fadeEf.destroy();
        }

        if (this.glass) {
            this.glass.destroy();
        }

        if (this.dragProxy) {
            this.dragProxy.destroy();
            this.dragProxy = null;
        }

        if (this.toolBox) {
            this.toolBox.destroy(true);
            this.toolBox = null;
        }
        if (this.toolbar) {
            this.toolbar.destroy(true);
            this.toolbar = null;
        }

        if (this.tabbar) {
            this.tabbar.destroy(true);
            this.tabbar = null;
        }

        if (this.rendered) {
            this.WM.unregister(this);

            if (!this.config.disabledDD) {
                this.destroyDD();
            }

            this.removeEvents();

            GUI.destroyNode(this.dom);
            this.dom = this.focusEl = null;
            this.rendered = false;
        }

        hotkeys.removeScope(this.config.hotkeysScope);

        this.purgeListeners();
    };

    /**
     * Sets content
     * @param {String} content Text of the new content
     */
    GUI.Popup.Dialog.prototype.setContent = function (content) {
        if (GUI.isString(content)) {
            this.config.content = content;
            if (this.dom) {
                GUI.$(this.contentId).innerHTML = content;
            }
        } else if (GUI.isString(content.innerHTML)) {
            GUI.$(this.contentId).innerHTML = content.innerHTML;
        }
    };

    /**
     * Appends html element without recreating dom nodes as while innerHTML updating.
     * @param {HTMLElement} content
     */
    GUI.Popup.Dialog.prototype.appendContent = function (content) {
        if (GUI.isString(content)) {
            GUI.$(this.contentId).innerHTML += content;
        } else if (GUI.isObject(content)) {
            GUI.$(this.contentId).appendChild(content);
        }
    };

    /**
     * Sets title
     * @param {String} title
     */
    GUI.Popup.Dialog.prototype.setTitle = function (title) {
        this.config.title = title;
        if (this.dom) {
            var span = GUI.$(this.titleId);
            span.innerHTML = title;
        }
    };

    GUI.Popup.Dialog.prototype.removeButtons = function () {
        if (!this.config.footerButtons) {
            return;
        }

        this.config.footerButtons.forEach(function (item) {
            if (!this[item.name]) {
                // Next
                return;
            }

            this[item.name].destroy();
            delete this[item.name];
        }.bindLegacy(this));

        this.config.footerButtons = false;
        this.footer.style.display = 'none';
        this.config.toolbarHeight = 0;
    };

    GUI.Popup.Dialog.prototype.removeFooterElements = function () {
        if (!this.config.footerLeftElements) {
            return;
        }

        this.config.footerLeftElements.forEach(function (item) {
            if (!this[item.name]) {
                // Next
                return;
            }

            this[item.name].destroy();
            delete this[item.name];
        }.bindLegacy(this));
    };

    GUI.Popup.Dialog.prototype.createButtons = function (buttons) {
        var i,
            len = buttons.length;

        if (len) {
            this.config.footerButtons = buttons;
            this.footer.style.display = 'block';
            this.config.toolbarHeight = 36;
        }

        for (i = 0; i < len; i++) {
            buttons[i].obj.render(this.footerSidebar);
            if (buttons[i].name) {
                this[buttons[i].name] = buttons[i].obj;
            }
        }

        this.setDimensions(
            this.dimension
        );
    };

    GUI.Popup.Dialog.prototype.createElements = function (elements) {
        var i,
            len = elements.length;

        if (len) {
            this.config.footerLeftElements = elements;
            this.footer.style.display = 'block';
            this.config.toolbarHeight = 36;
        }

        for (i = 0; i < len; i++) {
            elements[i].obj.render(this.footer);
            if (elements[i].name) {
                this[elements[i].name] = elements[i].obj;
            }
        }

        this.setDimensions(
            this.dimension
        );
    };

    /**
     * Closes dialog and removes glass if it was created
     * @param {Object} retVal
     */
    GUI.Popup.Dialog.prototype.close = function (retVal) {
        this.returnValue = retVal;

        if (this.fireEvent('beforeClose', this) === false) {
            return;
        }
        if (this.config.animate) {
            this.closeOnHide = true;
            this.hide();
        } else {
            this._close();
        }
    };

    /**
     * Hides glass if ot was created, fires events 'hide', 'close',
     * run unrender and destroy methods.
     */
    GUI.Popup.Dialog.prototype._close = function () {
        if (this.dom) {
            GUI.hide(this.dom);
        }
        // this.removeSizeLimits();  // FAQ-2809 slows the closing of any dialog
        this.toBack();
        this.visible = false;
        if (this.glass) {
            this.glass.hide();
        }
        this.fireEvent('hide', this);
        this.fireEvent('close', this, this.returnValue);

        if (this.unrender && this.config.unrender) {
            this.unrender();
        }
        if (this.config && this.config.autoDestroy) {
            this.destroy();
        }
    };

    /**
     * Saves dimensions into array
     */
    GUI.Popup.Dialog.prototype.saveDimensions = function () {
        this.restoreDimensions = this.getDimensions();

        if (!GUI.isSet(this.config.dimensions.height)) {
            this.restoreDimensions.height = '';
        }
        if (!GUI.isSet(this.config.dimensions.width)) {
            this.restoreDimensions.width = '';
        }
    };

    /**
     * Maximizes dialog window
     */
    GUI.Popup.Dialog.prototype.maximize = function () {
        if (this.state === 'maximized') {
            return;
        }

        if (this.state === 'minimized') {
            // minimized, so change restore button to minimize
            this.toolBox.showItem('minimize');
            GUI.show(GUI.$(this.contentDataId));
        } else {
            // From normal state
            // Save position and dimensions
            this.removeSizeLimits();
            this.saveDimensions();
        }

        var vw = GUI.getViewportWidth() - 2,
            vh = GUI.getViewportHeight() - 2,
            dim = {
                left    : 0,
                top     : 0,
                width   : vw, // shadow
                height  : vh
            };

        this.state = 'maximized';

        this.setDimensions(dim);
        // Replace maximize with restore
        this.disableDD();

        this.toolBox.hideItem('maximize');
        this.toolBox.showItem('restore'); // restore

        this.onWindowResize(vw, vh);

        this.fireEvent('maximize', this);
    };

    /**
     * Returns id of the content
     * @returns {String} id
     */
    GUI.Popup.Dialog.prototype.getContentId  = function () {
        return this.contentId;
    };

    /**
     * Minimizes dialog window
     */
    GUI.Popup.Dialog.prototype.minimize = function () {
        if (this.state === 'minimized') {
            return;
        }

        if (this.state === 'normal') {
            // Save position and dimensions
            this.saveDimensions();
            // TODO: set minimized dimensions ?
        } else {
            // restore dimensions
            // maximized, so change restore button to maximize
            this.toolBox.showItem('maximize');
            this.enableDD();
        }
        this.state = 'minimized';
        GUI.hide(GUI.$(this.contentDataId));

        this.setDimensions({
            width   : '200px', // auto
            height  : 'auto'
        });

        this.center();

        // Replace minimize button with restore
        this.toolBox.hideItem('minimize');
        this.toolBox.showItem('restore');
    };

    /**
     * Restores dialog window to its normal state
     */
    GUI.Popup.Dialog.prototype.restore = function () {
        if (this.state === 'normal') {
            return;
        }

        this.toolBox.hideItem('restore');
        if (this.state === 'minimized') {
            this.config.dimensions = this.getDimensions();
            // Replace restore button with minimized
            this.toolBox.showItem('minimize');
            GUI.show(GUI.$(this.contentDataId));

        } else if (this.state === 'maximized') {
            // Replace restore button with maximized
            this.toolBox.showItem('maximize');
            GUI.$(this.contentId).style.height = '';
            this.enableDD();
        }

        this.state = 'normal';
        this.setDimensions(this.restoreDimensions);
        this.onWindowResize(GUI.getViewportWidth() - 2, GUI.getViewportHeight() - 2);

        this.fireEvent('restore', this);
    };

    /**
     * Moves dialog to front
     */
    GUI.Popup.Dialog.prototype.toFront = function () {
        if (!this.config.usePopupMenu) {
            this.WM.bringToFront(this);
        }
    };

    /**
     * Moves dialog to back
     */
    GUI.Popup.Dialog.prototype.toBack = function () {
        // Save current code
        // this.WM.sendToBack(this);
    };

    /**
     * empty function
     */
    GUI.Popup.Dialog.prototype.setActive = function (active) {
        // TODO: fire events and change view of window
    };

    /**
     * Defer show the dialog
     */
    GUI.Popup.Dialog.prototype.deferShow = function () {
        this.show.defer(10, this);
    };

    /**
     * Shows dialog
     * @param {Object} animateFrom
     */
    GUI.Popup.Dialog.prototype.show = function (animateFrom) {
        this.dom.style.visibility = 'hidden';
        GUI.show(this.dom);

        this.visible = true;

        if (this.config.autoCenter) {
            this.alignTo(document.body, 'c-c', {});
        }

        if (this.config.modal) {
            this.glass.show();
            GUI.unselectable(this.glass.dom);
        }

        this.toFront();
        if (this.config.animate) {
            GUI.setOpacity(this.dom, 0);
            this.dom.style.visibility = ''; // Unhide dialog
            // this.deferFocus(); maybe create problems
            this.fadeEf.seekTo(1);
        } else {
            this.dom.style.visibility = '';
            // this.deferFocus(); maybe create problems
            this._show();
        }
        this.onWindowResize(GUI.getViewportWidth() - 2, GUI.getViewportHeight() - 2);
        this.focus();

        hotkeys.setScope(this.config.hotkeysScope);

        hotkeys.on(new Keys(
            [KEY.enter],
            hotkeys.getScope(),
            (e) => this.pressPrimaryButton(e),
            [MODIFIER.ctrl]
        ));
    };

    /**
     * Fires event 'shows'
     */
    GUI.Popup.Dialog.prototype._show = function () {
        this.fireEvent('show', this);
    };

    /**
     * Hides dialog
     */
    GUI.Popup.Dialog.prototype.hide = function () {
        this.visible = false;
        if (this.config.animate) {
            this.fadeEf.seekTo(0);
        } else {
            this._hide();
        }
    };

    /**
     * Close dialog if variable closeOnHide is true. If dialog is model hides glass.
     * Hides dom, fires event 'hide'
     */
    GUI.Popup.Dialog.prototype._hide = function () {
        if (this.closeOnHide) {
            this.closeOnHide = false;
            return this._close();
        }

        if (this.config.modal) {
            this.glass.hide();
        }

        hotkeys.removeScope(this.config.hotkeysScope);

        GUI.hide(this.dom);
        this.removeSizeLimits();
        this.toBack();
        this.fireEvent('hide', this);
    };

    /**
     * Sest dimensions
     * @param {HTMLElement} node
     * @param {Object} dim
     * @param {String} val
     */
    GUI.Popup.Dialog.prototype.setDim = function (node, dim, val) {
        var style = node.style;
        if (GUI.isNumber(val)) {
            if (dim === 'width') {
                node.style.width = this.adjustWidth(val) + 'px';
            } else if (dim === 'height') {
                if (this.config.dimensions.isMaxHeight) {
                    node.style['max-height'] = this.adjustHeight(val) + 'px';
                } else {
                    node.style.height = this.adjustHeight(val) + 'px';
                }
            } else {
                style[dim] = val + 'px';
            }
        } else if (GUI.isString(val)) {
            style[dim] = val;
        }
    };

    /**
     * Returns height of the content
     * @param {Number} h Height
     * @returns {Number} height
     */
    GUI.Popup.Dialog.prototype.getContentHeightFromFull = function (h) {
        h = h - 37 - this.config.toolbarHeight; // 37 - title
        return (h > 0) ? h : 0;
    };

    /**
     * Returns width of the content
     * @param {Number} w Width
     * @returns {number} width
     */
    GUI.Popup.Dialog.prototype.getContentWidthFromFull = function (w) {
        // w -= (this.state === 'maximized') ? 5 : 20;
        return w.constrain(0);
    };

    /**
     * Adjusts width
     * @param {Number} cw Width
     * @returns {Number} width
     */
    GUI.Popup.Dialog.prototype.adjustContentWidth = function (cw) {
        return cw;
    };

    /**
     * Adjusts height
     * @param {Number} ch Height
     * @returns {Number} height
     */
    GUI.Popup.Dialog.prototype.adjustContentHeight = function (ch) {
        return ch;
    };

    /**
     * Sets position and dimensions of region, fires event 'resize'
     * @param {Object} dime
     */
    GUI.Popup.Dialog.prototype.setDimensions = function (dim) {
        Object.extend(this.config.dimensions, Object.clone(dim));
        var name, node, w, h;

        if (this.dom) {
            for (name in dim) {
                this.setDim(this.dom, name, dim[name]);
            }

            node = GUI.$(this.contentId);

            if (GUI.isNumber(dim.width)) {
                w = this.getContentWidthFromFull(dim.width); // 20-borders 20-22 ???
                w = this.adjustContentWidth(w);
                w = parseInt(w, 10);
                this.setDim(node, 'width', (w < 0) ? 0 : w);
            } else if (GUI.isString(dim.width)) {
                this.setDim(node, 'width', dim.width);
            }

            if (GUI.isNumber(dim.height)) {
                h = this.getContentHeightFromFull(dim.height);  // 28-title, 14-bottom
                h = this.adjustContentHeight(h);
                this.setDim(node, 'height', h);
            } else if (GUI.isString(dim.height)) {
                this.setDim(node, 'height', dim.height);
            }
            this.fireEvent('resize', this);
        } else {
            this.fireEvent('resize', this);
        }
    };

    /**
     * Returns zIndex
     * @returns {Number} z-index
     */
    GUI.Popup.Dialog.prototype.getZIndex = function () {
        return this.config.zIndex;
    };

    /**
     * Sets zIndex
     * @param {Number} value
     */
    GUI.Popup.Dialog.prototype.setZIndex = function (value) {
        this.config.zIndex = value;
        if (this.dom) {
            if (this.config.modal) {
                this.glass.setZIndex(value - 1);
            }
            this.dom.style.zIndex = value;
        }
    };

    /**
     * Focused dialog
     */
    GUI.Popup.Dialog.prototype.focus = function () {
        try {
            this.focusEl.focus();
        } catch (e) {}
    };

    /**
     * Defer focus
     */
    GUI.Popup.Dialog.prototype.deferFocus = function () {
        this.focus.defer(20, this);
    };

    /**
     * Attaches event listeners to rendered dom tree
     */
    GUI.Popup.Dialog.prototype.attachEvents = function () {
        GUI.Event.onWindowResize(this.onWindowResize, this);

        this.dom.on('mousedown', this.onMouseDown, this);
        if (this.config.maximizable) {
            GUI.$(this.titleId).on('dblclick', this.onTitleDblClick, this);
        }
    };

    /**
     * Removes event listeners from rendered dom tree
     */
    GUI.Popup.Dialog.prototype.removeEvents = function () {
        GUI.Event.unWindowResize(this.onWindowResize, this);
        this.dom.un('mousedown', this.onMouseDown, this);
        if (this.config.maximizable) {
            GUI.$(this.titleId).un('dblclick', this.onTitleDblClick, this);
        }
    };

    /**
     * Restore or maximize dialog
     */
    GUI.Popup.Dialog.prototype.onTitleDblClick = function () {
        switch (this.state) {
        case 'minimized':
        case 'maximized':
            this.restore();
            break;

        case 'normal':
            this.maximize();
            break;
        }
    };

    /**
     * Front dialog
     * @param {Event} e Event
     */
    GUI.Popup.Dialog.prototype.onMouseDown = function (e) {
        this.toFront();
    };

    /**
     * Handles clicks on toolbox
     * @param {Object} tb Toolbox object
     * @param {Object} item
     */
    GUI.Popup.Dialog.prototype.onToolBoxClick = function (tb, item) {
        if (!this.fireEvent('dialogAction', this, item.name)) {
            // Cancel default handler
            return;
        }
        if (typeof this.defHandlers[item.name] === 'function') {
            this.defHandlers[item.name].call(this);
        }

    };

    /**
     * Recenters dialog if browser window was resized
     * @param {Number} vw Width
     * @param {Number} vh Height
     */
    GUI.Popup.Dialog.prototype.onWindowResize = function (vw, vh) {
        var w, h, node, cw, ch, csw, csh,
            newDim = {};

        if (this.state === 'maximized') {
            newDim.width = vw - 2;
            newDim.height = vh - 2;
        } else {
            w = this.dom.offsetWidth;
            h = this.dom.offsetHeight;

            if (w > vw) {
                // Need to limit width
                newDim.width = vw;
                if (this.beforeLimitDim.width === null) {
                    // Enabling limiting by width
                    this.beforeLimitDim.width = w;
                    this.originalDim.width    = this.config.dimensions.width || '';
                }
            } else if (w < vw) {
                // can grow, but check if we are limited by width..
                if (this.beforeLimitDim.width !== null) {
                    if (vw < this.beforeLimitDim.width) {
                        // If we are growing and smaller, than we were before limiting, grow
                        newDim.width = vw;
                    } else {
                        // else we are growing to saved dimensions, so return to them and remove limiting
                        newDim.width = this.originalDim.width;
                        delete this.beforeLimitDim.width;
                        delete this.originalDim.width;
                    }
                }
            }

            if (h > vh) {
                // Need to limit height
                newDim.height = vh;
                if (this.beforeLimitDim.height === null) {
                    // Enabling limiting by height
                    this.beforeLimitDim.height = h;
                    this.originalDim.height    = this.config.dimensions.height || '';
                }
            } else if (h < vh) {
                // can grow, but check if we are limited by height..
                if (this.beforeLimitDim.height !== null) {
                    if (vh < this.beforeLimitDim.height) {
                        // If we are growing and smaller, than we were before limiting, grow
                        newDim.height = vh;
                    } else {
                        // else we are growing to saved dimensions, so return to them and remove limiting
                        newDim.height = this.originalDim.height;
                        delete this.beforeLimitDim.height;
                        delete this.originalDim.height;
                    }
                }
            }
        }
        this.setDimensions(newDim);

        // Need to look if content is greater, than window size
        // and if its true, make window scrollable
        node = GUI.$(this.contentId);
        cw = node.offsetWidth;
        ch = node.offsetHeight;
        csw = node.scrollWidth;
        csh = node.scrollHeight;

        if (this.config.allowScrollbars) {
            node.style.overflow = "auto";
            this.scrollable = true;
        }

        if ((this.state !== 'maximized') && this.config.autoCenter) {
            this.center();
        }

        if (this.config.modal) {
            this.glass.takeDocument(true);
        }
    };

    /**
     * Sets dimensions with original size
     */
    GUI.Popup.Dialog.prototype.removeSizeLimits = function () {
        var originalSize = {};

        if (this.beforeLimitDim.width) {
            originalSize.width = this.originalDim.width;

            delete this.beforeLimitDim.width;
            delete this.originalDim.width;
        }

        if (this.beforeLimitDim.height) {
            originalSize.height = this.originalDim.height;

            delete this.beforeLimitDim.height;
            delete this.originalDim.height;
        }

        this.setDimensions(originalSize);
    };

    /**
     *
     * @param {type} e
     * @returns {undefined}
     */
    GUI.Popup.Dialog.prototype.onKeyDown = function (e) {
        //
    };

    /**
     * If key is escape and variable closeOnEsc is true close dialog.
     * @param {type} e
     * @returns {}
     */
    GUI.Popup.Dialog.prototype.onKeyUp = function (e) {
        e = GUI.Event.extend(e);
        if (e.getKey() === e.KEY_ESC) {
            if (this.config.closeOnEsc) {
                this._close();
            } else {
                this.fireEvent('closeOnEsc');
            }
        }
    };

    /**
     *
     * @param {type} e
     * @returns {undefined}
     */
    GUI.Popup.Dialog.prototype.onKeyPress = function (e) {
        //
    };

    /**
     * Positions region at the window center
     */
    GUI.Popup.Dialog.prototype.center = function () {
        this._alignTo(document.body, 'c-c');
    };

    /**
     * Creates proxy element, show it.
     */
    GUI.Popup.Dialog.prototype.onDragStart = function () {
        var dims,
            cfg = this.config;
        // Create drag proxy and hide main window
        if (!this.dragProxy) { // lazy init
            this.dragProxy = new GUI.Popup.Region({
                className   : 'b-dialog b-dialog_phantom',
                zIndex      : this.config.zIndex + 5
            });
        }
        this.dragProxy.takeRegion(this.dom);

        this.hideByOffset();

        this.dragProxy.show();
        // this.dragProxy.dom.style.border = '1px solid blue';

        if (cfg.constrainViewport) {
            dims = this.getDimensions();
            this.shiftX = GUI.getScroll(document.body).left;
            this.shiftY = GUI.getScroll(document.body).top;
            this.maxX = GUI.getViewportWidth()  - dims.width + this.shiftX;
            this.maxY = GUI.getViewportHeight() - dims.height + this.shiftY;
        }
    };

    /**
     * Sets left and top styles of the proxy element
     * @param {Object} e Event
     */
    GUI.Popup.Dialog.prototype.onDrag = function (e) {
        var xy = e.getPageXY(),
            x = xy[0],
            y = xy[1],
            style = this.dragProxy.dom.style;

        x -= this.DDM.deltaX;
        y -= this.DDM.deltaY;

        if (this.config.constrainViewport) {
            x = x.constrain(this.shiftX, this.maxX);
            y = y.constrain(this.shiftY, this.maxY);
        }

        style.left = x + 'px';
        style.top = y + 'px';
    };

    /**
     * Sets dimensions of the dialog, hides proxy element
     * @param {Object} e Event
     */
    GUI.Popup.Dialog.prototype.onDragEnd = function (e) {
        if (e) {
            var xy = e.getPageXY(),
                x = xy[0],
                y = xy[1];

            x -= this.DDM.deltaX;
            y -= this.DDM.deltaY;

            if (this.config.constrainViewport) {
                x = x.constrain(this.shiftX, this.maxX);
                y = y.constrain(this.shiftY, this.maxY);
            }

            this.setDimensions({
                left : x,
                top  : y
            });
        }
        this.dragProxy.hide();
    };
    
    GUI.Popup.Dialog.prototype.updateResizePositions = function () {
        this.onWindowResize(GUI.getViewportWidth() - 2, GUI.getViewportHeight() - 2);
    };

    GUI.Popup.Dialog.prototype.pressPrimaryButton = function (e) {
        for (let item of this.config.footerButtons) {
            if (!item.obj.primary) {
                continue;
            }

            item.obj.onClick(e.originalEvent);
            return;
        }
    }

}());
