(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * GroupActionsBar implementation
     * Grid plugin
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Grid
     */
    GUI.Grid.GroupActionsBar = Class.create();
    Object.extend(GUI.Grid.GroupActionsBar.prototype, superproto);

    /**
     * Constructor
     * @param {Object} cfg Configuration object
     */
    GUI.Grid.GroupActionsBar.prototype.initialize = function (cfg) {
        this.items = [];
        if (cfg) {
            Object.extend(this, cfg);
        }

        this.id = GUI.getUniqId('action-');
        this.selectedRowsId = 'selectedRows-' + this.id;

        this.disabledStack = [];
        this.disabled = true;
        if (cfg && cfg.grid) {
            this.setGrid(cfg.grid);
        }
    };

    /**
     * Called internally by grid
     * @param {Object} grid Objet of the grid
     */
    GUI.Grid.GroupActionsBar.prototype.setGrid = function (grid) {
        this.dom = null;
        this.grid = grid;

        Object.extend(this, grid.data.groupActions);
        // Add groupAction event to grid
        grid.addEvents({
            groupAction: true
        });

        // Render group actions bar when grid is rendered
        grid.on('update', this.update.bindLegacy(this));
        grid.sm.on('selectionchange', function (sm) {
            var countSelectedRows = sm.getCount();
            if (countSelectedRows > 0) {
                this.enable();
            } else {
                this.disable();
            }
            if (GUI.$(this.selectedRowsId)) {
                GUI.$(this.selectedRowsId).innerHTML = i18n('Selected:') + ' ' + countSelectedRows;
            }
        }, this);
    };

    /**
     * Renders group actions bar
     * @param {HTMLelement} to Element to render bar to
     */
    GUI.Grid.GroupActionsBar.prototype.render = function (to) {
        if (this.dom) {
            console.log('Group actions bar already rendered!');
            return;
        }

        to = GUI.$(to);
        if (!to) {
            console.log('GA holder is not set:', to);
            return;
        }

        var i, item, tpl,
            menu_items = [],
            items = this.items,
            len = items.length;

        for (i = 0; i < len; i++) {
            item = items[i];
            menu_items.push({
                caption : item.text,
                icon    : item.img,
                name    : item.name,
                disabled: item.disabled
            });
        }

        this.button = new GUI.ToolBar.MenuButton({
            name    : 'bulk_actions',
            caption : i18n('Bulk Actions'),
            menu    : {
                name    : 'menu',
                onClick : function (menu, item) {
                    this.grid.fireEvent('groupaction', this.grid, item.name, this.grid.sm.getSelections());
                }.bindLegacy(this),
                items   : menu_items
            }
        });
        this.button.render(to);
        tpl = '<span id="' + this.selectedRowsId + '" class="selected-txt">' + i18n('Selected:') + ': 0</span>';

        this.dom = GUI.Dom.append(to, tpl);
        GUI.Dom.extend(this.dom);

        if (this.disabled) {
            this.disabled = false;
            this.disable();
        }
    };

    /**
     * Updates data
     */
    GUI.Grid.GroupActionsBar.prototype.update = function () {
        var ga, i, item,
            menu_items = [],
            items = this.items,
            len = items.length;

        if (this.updateFromServer) {
            ga = this.grid.config.data.groupActions;
            if (ga) {
                this.items = ga.items;
                for (i = 0; i < len; i++) {
                    item = items[i];
                    menu_items.push({
                        caption : item.text,
                        icon    : item.img,
                        name    : item.name,
                        disabled: item.disabled
                    });
                }
                this.button.menu.setItems(menu_items);
            } else {
                this.items = [];
            }
        }

        if (!this.dom) {
            return; // only update actions, nothing more to do
        }
    };

    /**
     * Destroys objects
     */
    GUI.Grid.GroupActionsBar.prototype.destroy = function () {
        if (this.dom) {
            GUI.destroyNode(this.dom);
        }
        this.gaHolder = this.dom = null;
        if (this.button) {
            this.button.destroy();
        }
    };

    /**
     * Disables bar
     */
    GUI.Grid.GroupActionsBar.prototype.disable = function () {
        if (this.disabled) {
            return;
        }
        this.disabled = true;
        this.button.disable();
        this.dom.parentNode.addClass('disabled');
    };

    /**
     * Enables bar
     */
    GUI.Grid.GroupActionsBar.prototype.enable = function () {
        if (!this.disabled) {
            return;
        }
        this.disabled = false;
        this.button.enable();
        this.dom.parentNode.removeClass('disabled');
    };

    /**
     * Return action by name
     * @param {String} name Name of the action
     */
    GUI.Grid.GroupActionsBar.prototype.getAction = function (name) {
        var i, len, items = this.items;

        if (!items) {
            return false;
        }

        for (i = 0, len = items.length; i < len; i++) {
            if (items[i].name === name) {
                return items[i];
            }
        }
        return false;
    };

    /**
     * Disables action by name
     * @param {String} name Name of the action
     */
    GUI.Grid.GroupActionsBar.prototype.disableAction = function (name) {
        var i,
            action = this.getAction(name),
            items = this.button.menu.config.items,
            len = items.length;

        if (!action) {
            return false;
        }

        for (i = 0; i < len; i++) {
            if (items[i].name == name) {
                items[i].disabled = true;
            }
        }
    };

    /**
     * Enables action by name
     * @param {String} name Name of the action
     */
    GUI.Grid.GroupActionsBar.prototype.enableAction = function (name) {
        var i,
            action = this.getAction(name),
            items = this.button.menu.config.items,
            len = items.length;

        if (!action) {
            return false;
        }
        for (i = 0; i < len; i++) {
            if (items[i].name == name) {
                items[i].disabled = false;
            }
        }
    };

}());
