import jQuery from '../../../public/js/library/jquery/jquery.js';
window.jQuery = jQuery;

import '../../../public/js/library/jquery/jquery-autoresize.js';
import '../../../public/js/library/env.js';
import '../../../public/js/library/routes.js';
import '../../../public/js/library/actorInfo.js';
import '../../../public/js/library/placeholders.js';
import '../../../public/js/library/attachments.js';
import '../../../public/js/library/features.js';
import '../../../public/js/library/tinymce/prism/prism.js';
import '../../../public/js/library/google-analytics.js';
import '../../../public/js/library/tinymce/tinymce.min';
import '../../../public/js/library/tinymce/plugins/codemirror/plugin';