(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Tree container implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.Tree = Class.create();
    Object.extend(GUI.Tree.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Tree.prototype.initialize = function (config) {
        var cfg, defCfg, id, i, len;

        defCfg =  {
            dataUrl         : null,
            method          : 'post',
            checkBox        : false,
            inlineEdit      : false,
            enableDd        : false,
            alwaysExpanded  : false,
            root            : null, // Root node
            rootVisible     : true,
            expandIcons     : true,
            expandButton    : false,
            collapseButton  : false,
            checkParentIfChildsChecked  : false,
            allowDisabledCheckbox       : false,
            allowDisabledNodeSelection  : false,

            scroll          : false,
            nowarap         : false,

            resizeTextHeaders   : true,
            sizeTextRootLevel   : '', // big
            sizeTextFirstLevel  : '', // middle
            sizeTextSecondLevel : '', // small

            showEditableHint    : true,

            css             : {
                tree    : 'b-tree',
                node    : 'b-tree__node'
            },
            hintClass       : '',
            hintOffset      : [-2, -3],

            // Drag'n'Drop configuration
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                targetElId  : null,
                isTarget    : true,
                moveOnly    : false,
                groups      : {
                    'tree' : true
                },
                clipElId    : null,
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            },
            scrollable: true
        };
        Object.extend(defCfg, config);

        if (defCfg.scroll) {
            defCfg.css.tree += ' b-tree_scroll';
        }

        if (defCfg.nowrap) {
            defCfg.css.tree += ' b-tree_nowrap';
        }

        // Call parent method
        superproto.initialize.call(this, defCfg);

        cfg = this.config;
        Object.extend(cfg, defCfg);

        id = this.getId();
        this.contentId = id + '-content';

        this.hintVisible = false;
        this.hintAssignedNode = null;

        this.addEvents({
            beforeappend    : true,
            append          : true,
            beforeremove    : true,
            remove          : true,
            beforeinsert    : true,
            insert          : true,
            beforemove      : true,
            move            : true,
            nodemouseover   : true,
            nodebeforemouseout : true,
            nodemouseout    : true,
            nodeclick       : true,
            nodedblclick    : true,
            nodeDragOver    : true,
            select          : true,
            checkedchange   : true,
            beforeChangeText: true,
            changeText      : true,
            editingCanceled : true,
            beforenodeload  : true,
            nodeLoad        : true,
            nodeLoadFailed  : true,
            nodeexpand      : true,
            nodecollapse    : true
        });

        this.nodeHash        = {};
        this.checkedNodes    = new GUI.Utils.Collection();
        this.selectedNode    = null;

        if (cfg.root) {
            if (!(cfg.root instanceof GUI.Tree.Node)) {
                cfg.root = new GUI.Tree.Node(cfg.root);
            }
            this.setRootNode(cfg.root);
        }
        // Init plugins
        this.plugins = cfg.plugins;
        if (this.plugins) {
            if (GUI.isArray(this.plugins)) {
                for (i = 0, len = this.plugins.length; i < len; i++) {
                    this.plugins[i].init(this);
                }
            } else {
                this.plugins.init(this);
            }
        }
        GUI.ComponentMgr.register(this);
    };

    /**
     * Enable Drag'n'Drop
     */
    GUI.Tree.prototype.enableDD = function () {
        superproto.enableDD.call(this);
        this.config.enableDd = true; // To disable nodes dnd
    };

    /**
     * Disabled Drag'n'Drop
     */
    GUI.Tree.prototype.disableDD = function () {
        superproto.disableDD.call(this);
        this.config.enableDd = false; // To enable nodes dnd
    };

    /**
     * Returns id of the tree
     * @returns {Number|String} id
     */
    GUI.Tree.prototype.getId = function () {
        return this.config.id || (this.config.id = "jsgui-cmp-" + GUI.getUniqId());
    };

    /**
     * Returns value of the param inlineEdit
     * @returns {Boolean}
     */
    GUI.Tree.prototype.isEditable = function () {
        return this.config.inlineEdit;
    };

    /**
     * Generates tree html and returns html string
     * @returns {String} html
     */
    GUI.Tree.prototype._getHtml = function () {
        var html,
            styles = [],
            classes = [this.config.css.tree];

        classes = classes.join(' ');

        html = '<div id="' + this.config.id + '"><ul id="' + this.contentId + '" class="' + classes + '" style="' + styles.join(';') + '"></ul></div>';

        return html;
    };

    /**
     * Renders dom, initialize Drag'n'Drop
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Tree.prototype.render = function (to) {
        if (this.rendered) {
            this.unrender();
        }

        var s, cfg = this.config;

        if (to) {
            cfg.holder = to;
        } else {
            to = cfg.holder;
        }
        to = GUI.$(to);
        if (!to) {
            return false;
        }
        this.dom = GUI.Dom.extend(GUI.Dom.append(to, this._getHtml()));

        this.contentEl = GUI.$(this.contentId);
        this.rendered = true;

        this.setDimensions(cfg.width, cfg.height);

        s = this.dom.style;
        if (cfg.scrollable) {
            s.overflow = 'auto';
        } else {
            s.overflow = 'hidden';
        }

        this.attachEventListeners();
        if (this.root) {
            this.root.render(this.contentId);
        }

        GUI.unselectable(this.dom);
        // Initialize Drag'n'Drop
        this.ddConfig.linkedElId    = this.contentId;
        this.ddConfig.targetElId    = this.contentId;
        this.ddConfig.clipElId      = cfg.id;
        this.initDD();
    };

    /**
     * Sets width of the tree
     * @param {Number} w Width
     */
    GUI.Tree.prototype.setWidth = function (w) {
        this.setDimensions(w, undefined);
    };

    /**
     * Sets height of the tree
     * @param {Number} h Height
     */
    GUI.Tree.prototype.setHeight = function (h) {
        this.setDimensions(undefined, h);
    };

    /**
     * Returns the height of the tree
     * @returns {Number} height
     */
    GUI.Tree.prototype.getHeight = function () {
        if (this.rendered && !GUI.isNumber(this.config.height)) {
            return this.dom.offsetHeight;
        }
        return this.config.height;
    };

    /**
     * Returns width of the tree
     * @param {Number} width
     */
    GUI.Tree.prototype.getClientWidth = function () {
        return this.rendered ? this.dom.clientWidth : 0;
    };

    /**
     * Returns scroll left
     * @returns {Number} scroll
     */
    GUI.Tree.prototype.getScrollLeft = function () {
        return this.rendered ? this.dom.scrollLeft : 0;
    };

    /**
     * Returns height of the scroll
     * @returns {Number} height
     */
    GUI.Tree.prototype.getScrollHeight = function () {
        return this.rendered ? this.contentEl.offsetHeight : this.config.height;
    };

    /**
     * Sets max heigt of the dom
     * @param {Number} h Height
     */
    GUI.Tree.prototype.setMaxHeight = function (h) {
        this.maxHeight = h;
        if (this.rendered) {
            this.dom.style.maxHeight = h + 'px';
        }
    };

    /**
     * Sets dimensions, fires event 'resize'
     * @param {Number} w Width
     * @param {Number} h Height
     */
    GUI.Tree.prototype.setDimensions = function (w, h) {
        var s = this.dom ? this.dom.style : null;

        if (GUI.isSet(w)) {
            this.config.width = w;
            if (s) {
                s.width = GUI.isNumber(w) ? (w > 0 ? w : 0) + 'px' : w;
            }
        }

        if (GUI.isSet(h)) {
            this.config.height = h;
            if (s) {
                s.height = GUI.isNumber(h) ? (h > 0 ? h : 0) + 'px' : h;
            }
        }
        this.fireEvent('resize', w, h);
    };

    /**
     * Removes objects
     */
    GUI.Tree.prototype.unrender = function () {
        if (this.rendered) {
            if (this.root) {
                // Pass true to perform quick unrender to remove all nodes
                // markup at once, gains much performance boost
                this.root.unrender(true);
            }
            // Destroy DD
            this.destroyDD();
            this.removeEventListeners();
            GUI.destroyNode(this.dom);
            this.dom = null;
            this.rendered = false;
        }
    };

    /**
     * Destroys object
     * @param {Boolean} quick
     */
    GUI.Tree.prototype.destroy = function (quick) {
        this.purgeListeners();

        if (this.inlineEditor) {
            this.inlineEditor.destroy();
            this.inlineEditor = null;
        }

        if (this.root) {
            // Pass true to perform quick destroy to remove all nodes
            // markup at once, gains much performance boost
            this.root.destroy(true);
            this.root = null;
        }

        if (this.rendered) {
            // Destroy DD
            this.destroyDD();
            this.removeEventListeners();
            if (!quick) {
                GUI.destroyNode(this.dom);
            }
            this.dom        = null;
            this.contentEl    = null;
            this.lineRegion = null;
        }
        this.config.holder = null;
        this.nodeHash = null;
        GUI.ComponentMgr.unregister(this);
    };

    /**
     * Expand all nodes
     */
    GUI.Tree.prototype.expandAll = function () {
        this.root.expand(true);
    };

    /**
     * Collapse all nodes
     */
    GUI.Tree.prototype.collapseAll = function () {
        this.root.collapse(true);
    };

    /**
     * Returns container
     * @returns {HTMLElement} container
     */
    GUI.Tree.prototype.getContainer = function () {
        if (this.dom) {
            return GUI.$(this.contentId);
        }
        return null;
    };

    /**
     * Sets the root node to the passing node
     * @param {Object} node New root node
     */
    GUI.Tree.prototype.setRootNode = function (node) {
        this.root = node;
        node.isRoot = true;
        this.registerNode(node);
        node.setOwnerTree(this);
    };

    /**
     * Adds node to the array of the nodes
     * @param {Object} node Node to add
     */
    GUI.Tree.prototype.registerNode = function (node) {
        this.nodeHash[node.id] = node;
    };

    /**
     * Removes node from the array of the nodes
     * @param {Object} node Node to remove
     */
    GUI.Tree.prototype.unregisterNode = function (node) {
        this.unselectNode(node);
        this.removeFromChecked(node);
        delete this.nodeHash[node.id];
    };

    /**
     * Returns the node by id
     * @param {Number} id
     * @returns {Number|String} id
     */
    GUI.Tree.prototype.getNodeById = function (id) {
        return this.nodeHash[id];
    };

    /**
     *
     */
    GUI.Tree.prototype.getNodeByField = function (field, value) {
        var node,
            nodes = this.nodeHash;

        for (node in nodes) {
            if (nodes[node].config[field] === value) {
                return nodes[node];
            }
        }
        return false;
    };

    /**
     * Counts the number of nodes
     * @returns {Number} num
     */
    GUI.Tree.prototype.getNodesCount = function () {
        var i = 0, id;
        for (id in this.nodeHash) {
            i++;
        }
        return i;
    };

    /**
     * Shows hint of the node
     * @param {Object} treeNode Node object
     */
    GUI.Tree.prototype.showHint = function (treeNode) {
        GUI.$(treeNode.textId).setAttribute('title', treeNode.config.text);
        return;

        var node, dom, depth, hintClass;

        if (this.hintVisible) {
            if (this.hintAssignedNode === treeNode) {
                return; // already shown
            }
            this.hideHint();
        }

        depth = treeNode.getDepth();

        if (depth === 0) {
            hintClass = 'b-hint b-hint_tree_header_big';
            this.config.hintOffset = [-2, -4];
        } else if (depth === 1) {
            hintClass = 'b-hint b-hint_tree_header_middle';
            this.config.hintOffset = [-2, -3];
        } else if (depth === 2) {
            hintClass = 'b-hint b-hint_tree_header_small';
            this.config.hintOffset = [-2, -2];
        } else {
            hintClass = 'b-hint b-hint_tree_default';
            this.config.hintOffset = [-2, -1];
        }

        if (!this.hintList) { // Deferred region creation
            this.hintList = new GUI.Popup.Region({
                className: 'b-hint'
            });
        }

        this.hintList.setContent(GUI.Popup.Hint.renderHint({
            text: treeNode.config.text
        }));

        node = GUI.$(treeNode.textId);

        this.hintList.alignTo(node, 'l-l', this.config.hintOffset);
        this.hintList.show();

        this.hintList.dom.className = hintClass;

        this.hintVisible = true;
        this.hintAssignedNode = treeNode;
        // Attach events to proxy
        dom = GUI.Dom.extend(this.hintList.dom);
        dom.on('mousedown', this.onHintMouseDown, this);
        dom.on('click', this.onHintClick, this);
        dom.on('mouseout', this.onHintMouseOut, this);

        treeNode.setOuterHandleElId(this.hintList.config.id);
    };

    /**
     * Handler mouse down
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onHintMouseDown = function (e) {
        e = GUI.Event.extend(e);
        e.stopPropagation();
    };

    /**
     * Selects node, hides hint
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onHintClick = function (e) {
        e = GUI.Event.extend(e);
        // Proxy event to node
        this.selectNode(this.hintAssignedNode);
        this.hintAssignedNode.onClick(e);
        this.ignoreNextMouseOver = true;
        this.hideHint();
    };

    /**
     * Handler double click on the hint
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onHintDblClick = function (e) {
        e = GUI.cloneEvent(e);
        e.target = GUI.$(this.hintAssignedNode.textId);
        e = GUI.Event.extend(e);
        this.hintAssignedNode.onDblClick(e);
        this.hideHint();
    };

    /**
     * Hides hint
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onHintMouseOut = function (e) {
        e = GUI.Event.extend(e);
        // blur item
        if (e.isMouseLeave(this.hintList.dom)) {
            if (this.hintAssignedNode.onMouseOut(e) !== false) {
                this.hideHint();
            }
        }
    };

    /**
     * Removes event listeners from the dom of the hint,
     * hides hint list
     */
    GUI.Tree.prototype.hideHint = function () {
        if (this.hintVisible) {
            // Remove event listeners
            this.hintAssignedNode.unsetOuterHandleElId(this.hintList.config.id);

            var dom = this.hintList.dom;
            dom.un();

            this.hintList.hide();
            this.hintVisible = false;
            this.hintAssignedNode = null;
        }
    };

    /**
     * Sets inline edit
     * @param {Boolean} val
     */
    GUI.Tree.prototype.setInlineEdit = function (val) {
        val = GUI.isSet(val) ? val : true;
        if (this.config.inlineEdit !== val) {
            this.config.inlineEdit = val;
            if (val) {
                // add hint
                if (this.config.showEditableHint) {
                    this.root.cascade(function (node) {
                        //node.setHint(i18n('Double click to edit'));
                        node.setHint('Double click to edit');
                    });
                }
            } else {
                // remove hint
                this.root.cascade(function (node) {
                    node.setHint('');
                });
            }
        }
    };

    /**
     * Shows editor, adds events 'apply', 'cancel'
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.showEditor = function (node) {
        if (!node.config.readOnly) {
            GUI.scrollIntoView(this.dom, GUI.$(node.textId));
            if (!this.inlineEditor) { // Lazy creation
                this.inlineEditor = new GUI.Tree.Plugins.InlineEdit({
                    width: 170,
                    autoApply: false
                });
                this.inlineEditor.on('apply', this.onEditorApply, this);
                this.inlineEditor.on('cancel', this.onEditorCancel, this);
            }
            this.inlineEditor.show(node);
        }
    };

    /**
     * Returns true if the root is visible
     * @returns {Boolean} true
     */
    GUI.Tree.prototype.isRootVisible = function () {
        return this.config.rootVisible;
    };

    /**
     * Unselect previous node,
     * expands nodes, fires event 'select'
     * @param {Object} node Node object
     * @param {Boolean} silent If false fires event
     */
    GUI.Tree.prototype.selectNode = function (node, silent, e) {
        if (GUI.isString(node) || GUI.isNumber(node)) {
            node = this.getNodeById(node);
        }

        if (!node) {
            return;
        }


        if (this.selectedNode) {
            this.unselectNode(this.selectedNode);
        }
        this.selectedNode = node;
        // Expand all parent nodes up to the root to display selected node

        if (node.parentNode) {
            node.parentNode.bubble(function () {
                if (this.expanded) {
                    return false;
                } else {
                    this.expand(false, true);
                }
            });
        }
        node.onSelect();
        if (!silent) {
            this.fireEvent('select', this, node, e);
        }

    };

    /**
     * Sets selected node to null
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.unselectNode = function (node) {
        node = node || this.selectedNode;
        if (node && (this.selectedNode === node)) {
            node.onUnselect();
            this.selectedNode = null;
        }
    };

    /**
     * Returns selected node
     * @returns {Object} node
     */
    GUI.Tree.prototype.getSelectedNode = function () {
        return this.selectedNode;
    };

    /**
     * Adds node to array of the checked nodes
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.addToChecked = function (node) {
        this.checkedNodes.add(node);
    };

    /**
     * Removes node from array of the checked nodes
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.removeFromChecked = function (node) {
        this.checkedNodes.remove(node);
    };

    /**
     * Returns array of checked nodes
     * @param {Boolean} ignoreDisabled Ignore disabled nodes
     * @returns {Array} nodes
     */
    GUI.Tree.prototype.getCheckedNodes = function (ignoreDisabled) {
        // Filter out disabled nodes
        var i, res = [];
        if (ignoreDisabled) {
            i = 0;
            this.checkedNodes.each(function (item) {
                if (!item.disabled) {
                    res[i++] = item;
                }
            });
        } else {
            res = res.concat(this.checkedNodes.items);
        }
        return res;
    };

    /**
     * Clears checked nodes in the tree
     * @param {Boolean} ignoreDisabled Ignore disabled nodes
     */
    GUI.Tree.prototype.clearChecked = function (ignoreDisabled) {
        var checked = this.getCheckedNodes(ignoreDisabled),
            i = checked.length;

        while (i--) {
            checked[i].setChecked(false);
        }
    };

    /**
     * Attach events listeners on 'click', 'dblclick', 'mouseover', 'mouseout'
     */
    GUI.Tree.prototype.attachEventListeners = function () {
        // Test.. Attach to table inside scrollable div to fix scrollbar issues
        var dom = GUI.Dom.extend(this.dom.firstChild);
        dom.on('click', this.onClick, this);
        dom.on('touchend', this.onClick, this);
        dom.on('dblclick', this.onDblClick, this);
        dom.on('mouseover', this.onMouseOver, this);
        dom.on('mouseout', this.onMouseOut, this);
    };

    /**
     * Removes events listeners
     */
    GUI.Tree.prototype.removeEventListeners = function () {
        var dom = GUI.Dom.extend(this.dom.firstChild);
        dom.un('click', this.onClick, this);
        dom.un('dblclick', this.onDblClick, this);
        dom.un('mouseover', this.onMouseOver, this);
        dom.un('mouseout', this.onMouseOut, this);
    };

    /**
     * Returns node
     * @param {Event} e Event
     * @returns {Object} node
     */
    GUI.Tree.prototype.getNode = function (e) {
        if (!e.target || !GUI.isSet(e.target.findParent)) {
            return;
        }

        var match,
            elem = e.target.findParent('li', this.contentEl, 'b-tree__node');
        if (elem) {
            match = elem.id.match(/^jsgui-tree-node-(.*)$/);
            if (match) {
                return this.getNodeById(match[1]);
            }
        }
        return null;
    };

    /**
     * Returns scroll position
     * @returns {Array} position
     */
    GUI.Tree.prototype.getScroll = function () {
        if (this.dom) {
            var scrollDiv = this.dom.firstChild;
            return [
                scrollDiv.scrollLeft, scrollDiv.scrollTop
            ];
        }
    };

    /**
     * Sets icon, expand/collapse, select node
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onClick = function (e) {
        e = GUI.Event.extend(e);

        var target, nodeName,
            node = this.getNode(e);

        if (!node) {
            return;
        }

        if (node.config.unselectable) {
            return;
        }

        target = e.target;
        nodeName = target.nodeName;

        if (nodeName === 'INPUT' && target.type === 'checkbox') {
            this.onCheckBoxClick(node, e);
        } else if (nodeName === 'I') {
            //if ((' ' + target.className).indexOf('b-tree__icon expander') !== -1) {
            if (target.hasClass('exp')) {
                node.onExpandIconClick(e);
            } else {
                if (target.hasClass('fld')) {
                    node.onIconClick(e);
                }
                if ((!node.disabled || this.config.allowDisabledNodeSelection) && !target.hasClass('act')) {
                    this.selectNode(node, false, e);
                }
            }
        } else if (nodeName === 'A' && target.id === 'jsgui-tree-expand-all') {
            this.expandAll();
        } else if (nodeName === 'A' && target.id === 'jsgui-tree-collapse-all') {
            this.collapseAll();
        } else if (nodeName === 'BUTTON') {
            return;
        } else {
            if (!node.disabled || this.config.allowDisabledNodeSelection) {
                this.selectNode(node, false, e);
            }
        }
        // Proxy event to node
        node.onClick(e);
    };

    /**
     * Node calls this, when it is checked or unchecked
     * Adds/removes
     * @param {Object} node
     * @param {Boolean} checked
     */
    GUI.Tree.prototype.onCheckNode = function (node, checked) {
        if (checked) {
            this.addToChecked(node);
        } else {
            this.removeFromChecked(node);
        }

        this.fireEvent('checkedchange', this, node);
    };

    /**
     * Sets checked
     * @param {Object} node Node object
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onCheckBoxClick = function (node, e) {
        if (node.disabled) {
            return; // no reaction when node is disabled
        }

        var allChecked, i, len,
            checked = GUI.$(node.checkBoxId).checked;
        if (checked) {
            this.addToChecked(node);
        } else {
            this.removeFromChecked(node);
        }
        node.setChecked(checked);

        // Inyutko ^) give free to the checkboxes!!!!
        if (node.parentNode && !this.config.freeCheckbox) {
            if (!checked) {
                // Uncheck parent until it is already unchecked
                node.parentNode.bubble(function () {
                    if (this.checked) {
                        this.setChecked(false, false, true);
                    } else {
                        return false;
                    }
                });
            } else if (this.config.checkParentIfChildsChecked) {
                // Check parent if all childs are checked until parent
                // already checked
                node.parentNode.bubble(function () {
                    allChecked = true;
                    for (i = 0, len = this.childNodes.length; i < len; i++) {
                        if (!this.childNodes[i].checked) {
                            allChecked = false;
                            break;
                        }
                    }
                    if (!this.checked) {
                        this.setChecked(true, false, true);
                    } else {
                        return false;
                    }
                });
            }
        }
    };

    /**
     * Handler double click
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onDblClick = function (e) {
        e = GUI.Event.extend(e);
        if (e.target.nodeName === 'SPAN') {
            var node = this.getNode(e);
            if (node) {
                node.onDblClick(e);
            }
        }
    };

    /**
     * Handler apply of the ditor, fires event 'changeText', sets text
     * @param {Object} editor Editor bject
     * @param {Object} node  Node object
     * @param {String} text New text
     */
    GUI.Tree.prototype.onEditorApply = function (editor, node, text) {
        GUI.assert(node !== null, 'Strange bug. Applying tree node editing changes, but node is null');
        var i, len,
            oldText = node.getText();

        if (false !== this.fireEvent('beforeChangeText', this, node, text, oldText)) {
            node.setText(text); // Bug: sometimes node is null...
            if (this.plugins) {
                if (GUI.isArray(this.plugins)) {
                    for (i = 0, len = this.plugins.length; i < len; i++) {
                        if (GUI.isSet(this.plugins[i].actionClassPrefix) && this.plugins[i].actionClassPrefix === 'x-tree-action-name-') {
                            //this.plugins.showActions('selected', node);
                        }
                    }
                } else {
                    //this.plugins.showActions('selected', node);
                }
            }
            this.fireEvent('changeText', this, node, text, oldText);
        }
    };

    /**
     * Fires event 'editingCanceled'
     * @param {Object} editor Editor object
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.onEditorCancel = function (editor, node) {
        this.fireEvent('editingCanceled', this, node);
    };

    /**
     * Fires event 'nodeMouseOver', sets hoverNode to this node
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onMouseOver = function (e) {
        e = GUI.Event.extend(e);
        if (this.DDM.dragObj) {
            // Disable mouseover while dragging smth
            return;
        }
        if (this.ignoreNextMouseOver) {
            this.ignoreNextMouseOver = false;
            return;
        }

        var node = this.getNode(e);
        if (node) {
            this.hoverNode = node;
            node.onMouseOver(e);
            this.fireEvent('nodeMouseOver', this, node, e);
        }
    };

    /**
     * Call node onMouseOut
     * @param {Event} e Event
     */
    GUI.Tree.prototype.onMouseOut = function (e) {
        e = GUI.Event.extend(e);
        var node = this.getNode(e);
        if (node) {
            node.onMouseOut(e);
        }
    };

    /**
     * Region, used to show place, where dragged node would be inserted
     * @returns {Object} region
     */
    GUI.Tree.prototype.getLineRegion = function () {
        if (!this.lineRegion) { // Deferred region creation
            this.lineRegion = new GUI.Popup.Region({
                content     : '<div style="border-top: 1px solid #A4ACB1;"></div>',
                dimensions  : {
                    height: '1px'
                }
            });
        }
        return this.lineRegion;
    };

    /**
     * Sets position of the region
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     */
    GUI.Tree.prototype.onDragEnter = function (e, dragObj) {
        // Cache node regions
        var p = GUI.getPosition(this.dom, true);

        this.getLineRegion().setDimensions({
            width : this.dom.offsetWidth,
            left  : p.left
        });
    };

    /**
     * Check if drop can be accepted
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     * @param {Object} pt
     */
    GUI.Tree.prototype.onDragOver = function (e, dragObj, pt) {
        if (!this.config.canDropForeignNode) {
            if (dragObj.getOwnerTree && dragObj.getOwnerTree() !== this) {
                return false;
            }
        }
    };

    /**
     * Fire event 'nodeDragOver'
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     * @param {Object} pt
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.onNodeDragOver = function (e, dragObj, pt, node) {
        return this.fireEvent('nodeDragOver', this, node, dragObj, e, pt);
    };

    /**
     * Fires event 'treeDragOver'
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     * @param {Object} pt
     * @param {Object} moveDest
     * @param {Object} node Node object
     */
    GUI.Tree.prototype.onTreeDragOver = function (e, dragObj, pt, moveDest, node) {
        // for customize hint images, such a images.dropNo
        return this.fireEvent('treeDragOver', this, node, dragObj, e, pt, moveDest);
    };

    /**
     * Hides region
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     */
    GUI.Tree.prototype.onDragLeave = function (e, dragObj) {
        if (this.lineRegion) {
            this.lineRegion.hide();
        }
    };

    /**
     * Hides region
     * @param {Event} e Event
     * @param {Object} dragObj Drag'n'Drop object
     */
    GUI.Tree.prototype.onDragDrop = function (e, dragObj) {
        if (this.lineRegion) {
            this.lineRegion.hide();
        }
    };

}());
