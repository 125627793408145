var superproto = GUI.Grid.RowSelectionModel.prototype;

/**
 * JavaScript Graphical User Interface
 * Grid's checkbox selection model implementation
 *
 * @author Eugene Lyulka
 * @version 2.10
 * @namespace GUI.Grid
 * @extends GUI.Grid.RowSelectionModel
 */
GUI.Grid.CheckBoxSelectionModel = Class.create();
Object.extend(GUI.Grid.CheckBoxSelectionModel.prototype, superproto);

/**
 * If true, row has caption, default is true
 * @type Boolean
 */
GUI.Grid.CheckBoxSelectionModel.prototype.rawCaption = true;

/**
 * Caption template
 * @type String
 */
GUI.Grid.CheckBoxSelectionModel.prototype.caption = '<input id="grid-select-all-cb" class="checkbox" type="checkbox" />';

/**
 * Width, default is 23
 * @type Number
 */
GUI.Grid.CheckBoxSelectionModel.prototype.width = 23;

/**
 * If true, column can be sort, default is false
 * @type Boolean
 */
GUI.Grid.CheckBoxSelectionModel.prototype.sortable = false;

/**
 * Align in column, default is 'center'
 * @type String
 */
GUI.Grid.CheckBoxSelectionModel.prototype.align = 'center';

/**
 * Index of the data, default is 'checkbox'
 * @type String
 */
GUI.Grid.CheckBoxSelectionModel.prototype.dataIndex = 'checkbox';

/**
 * If true row has handler, default is true
 * @type Boolean
 */
GUI.Grid.CheckBoxSelectionModel.prototype.rawRenderer = true;

/**
 * Css name of the header, default is 'checkbox'
 * @type String
 */
GUI.Grid.CheckBoxSelectionModel.prototype.headerCls = 'cell_checkbox';

/**
 * Css class name of the cell, default is 'cell_checkbox'
 * @type String
 */
GUI.Grid.CheckBoxSelectionModel.prototype.cellCls = 'cell_checkbox';

/**
 * Handler row render
 * @param {Object} data
 * @param {Object} row
 * @param {Object} col
 * @param {Object} cell
 * @returns {HTMLElement} html
 */
GUI.Grid.CheckBoxSelectionModel.prototype.renderer = function (data, row, col, cell) {
    if (!row.noSelect) {
        var cbId = this.getCheckboxId(row.id);
        row.checkboxId = cbId;
        return '<input id="' + cbId + '" type="checkbox" class="checkbox" />';
    } else {
        return '';
    }
};

/**
 * Returns id for the checkbox
 * @param {String|Number} rowId
 * @returns {String} row id
 */
GUI.Grid.CheckBoxSelectionModel.prototype.getCheckboxId = function (rowId) {
    return this.grid.id + '-checkbox-' + rowId;
};

/**
 * Called by grid internally!
 * @param {Object} grid
 */
GUI.Grid.CheckBoxSelectionModel.prototype.init = function (grid) {
    GUI.Grid.RowSelectionModel.prototype.init.call(this, grid);
    this.className = 'GUI.Grid.CheckBoxSelectionModel';
    this.cbId = GUI.getUniqId('grid-select-all-cb-');
    this.caption = '<input id="' + this.cbId + '" type="checkbox" class="checkbox" />';
    // Add sm column to columns
    grid.config.data.columns.unshift(this);
};

/**
 * Initialize needed events
 */
GUI.Grid.CheckBoxSelectionModel.prototype.initEvents = function () {
    GUI.Grid.RowSelectionModel.prototype.initEvents.call(this);
    this.grid.on('hdClick', this.onHeaderCellClick, this);
};

/**
 * Handler click on the header of the cell
 * @param {Object} grid
 * @param {Object} hd
 * @param {Event} e
 */
GUI.Grid.CheckBoxSelectionModel.prototype.onHeaderCellClick = function (grid, hd, e) {
    if (hd === this && !e.shiftKey && !e.ctrlKey) {
        var t = e.getTarget();
        if (t.nodeName === 'INPUT') {
            if (t.checked) {
                this.selectAll();
            } else {
                this.clearSelections();
            }
        }
    }
};

/**
 * Selects a row
 * @param {Object} row
 * @param {Object} keepExisting True to keep existing selections
 * @param {Object} preventNotify True to prevent grid notification
 */
GUI.Grid.CheckBoxSelectionModel.prototype.selectRow = function (row, keepExisting, preventNotify) {
    if (GUI.Grid.RowSelectionModel.prototype.selectRow.call(this, row, keepExisting, preventNotify)) {
        GUI.$(row.checkboxId).checked = true;
        // If all rows are checked, check select all checkbox
        if (this.selections.getCount() === this.grid.rows.getCount()) {
            GUI.$(this.cbId).checked = true;
        }
        return true;
    } else {
        return false;
    }
};

/**
 * Deselects a row.
 * @param {Number} row The index of the row to deselect
 */
GUI.Grid.CheckBoxSelectionModel.prototype.deselectRow  = function (row, preventNotify) {
    // uncheck select all if was checked
    if (this.selections.getCount() === this.grid.rows.getCount()) {
        GUI.$(this.cbId).checked = false;
    }
    GUI.Grid.RowSelectionModel.prototype.deselectRow.call(this, row, preventNotify);
    GUI.$(row.checkboxId).checked = false;
};


