(function () {
    var observable = new GUI.Utils.Observable();
    observable.addEvents('invalidjson', 'beforerequest', 'requestcomplete');

    /**
     * @private
     * Format object to queryString
     * @param {Array} uri return param
     * @param {Object} object object for formatting
     * @param {String} prepend
     */
    function _toQueryString(uri, object, prepend) {
        var i, len, value,
            key = '';

        switch (typeof object) {

        case 'number':
        case 'string':
        case 'boolean':
            // Number, string or boolean
            if (prepend === '') {
                throw new Error("At first object must be passed!");
            }
            uri.push(prepend + '=' + encodeURIComponent(object));
            break;

        case 'object':
            if (object !== null && object.constructor === Array) {
                // Array
                if (prepend === '') {
                    throw new Error("At first object must be passed!");
                }
                i = 0;
                len = object.length;
                for (i; i < len; i++) {
                    _toQueryString(uri, object[i], prepend + '[' + i + ']');
                }

            } else {
                // Object
                for (key in object) {
                    value = object[key];
                    // Prototype hack
                    if (typeof value === 'function') {
                        continue;
                    }

                    _toQueryString(uri, value,
                            prepend === '' ? key : (prepend + '[' + key + ']')
                        );
                }
            }
            break;
        }
    }

    var isSafari = (/webkit|khtml/).test(navigator.userAgent.toLowerCase());

    /**
     * JavaScript Graphical User Interface
     * Ajax.Request implementation
     *
     * @class
     * @author Eugene Lyulka
     * @version 2.0
     * @name Request
     * @namespace GUI.Ajax
     */
    function Request(url, options) {
        // Support of prototype api...
        if (typeof url === 'string') {
            this.url = url;
        } else {
            options = url;
        }

        if (options) {
            this.applyOptions(options);
        }

        this.method = this.method.toUpperCase();
        this.complete = false;
        // Support of deprecated options
        this.data = this.data || this.postBody || this.parameters;

        // Convert parameters to text if needed
        if (typeof this.data !== 'string' && this.convertData) {
            this.data = this.toQueryString(this.data);
        }

        // Append parameters to the url if get
        if (this.data && (this.method === 'GET')) {
            this.url += ((this.url.lastIndexOf('?') > -1) ? "&" : "?") + this.data;
            this.data = null;
        }

        if (observable.fireEvent('beforeRequest', this) === false) {
            return false;
        }

        /*if (document.body) {
            this.maskElem = document.createElement('DIV');
            this.maskElem.className = 'b-mask__request';
            document.body.appendChild(this.maskElem);
            this.mask = new GUI.Popup.Mask({
                element: this.maskElem,
                stylePosition: 'absolute',
                text: i18n('Loading...')
            });
            this.mask.show();
        }*/

        this.xhr = this.getTransport();

        if (this.username) {
            this.xhr.open(this.method, this.url, this.async, this.username, this.password);

        } else if (this.url && this.method) {
            this.xhr.open(this.method, this.url, this.async);
        }

        Request.counts = Request.counts || 0;
        Request.counts += 1;
        // GUI.Ajax.Request.counts
        //console.log('before')
        //console.log(Request.counts)

        this.xhr.onreadystatechange = this.getBind(this.onReadyStateChange);

        if (this.onProgress) {
            this.xhr.upload.onprogress = this.getBind(this.onProgress);
        }

        if (this.requestHeader) {
            // this.xhr.setRequestHeader(this.requestHeader[0], this.requestHeader[1])
        } else {
            try {
                this.setRequestHeaders();
            } catch (e) {}
        }

        if (this.async && (this.timeout > 0)) {
            this.timeoutId = setTimeout(this.getBind(this._onTimeout), this.timeout);
        }

        if (this.autoSendRequest) {
            try {
                this.xhr.send(this.data);
            } catch (e1) {}
        }

        // firefox 1.5 doesn't fire statechange for sync requests
        if (!this.async) {
            this.onReadyStateChange();
        }
    }

    Request.prototype = {
        // Prototype compatible
        /**
         * Can be 'post' or 'get'. Default is 'post'
         * @type String
         */
        method      : 'post',
        /**
         * Default is 'application/x-www-form-urlencoded'
         * @type String
         */
        contentType : 'application/x-www-form-urlencoded',
        /**
         * Default is 'UTF-8'
         * @type String
         */
        encoding    : 'UTF-8',
        //
        onComplete  : null,
        onFailure   : null,

        // need to deprecate.. they are both equal to data
        parameters  : null,
        postBody    : null,

        // New params
        data        : null,

        //
        autoSendRequest: true,

        // converting data
        convertData : true,
        /**
         * Default is true
         * @type Boolean
         */
        async       : true,
        /**
         * Default is 0
         * @type Number
         */
        timeout     : 0,
        /**
         * Default is null
         * @type String|null
         */
        username    : null,
        /**
         * Default is null
         * @type String|null
         */
        password    : null,
        /**
         * Default is true
         * @type Boolean
         */
        jsonExpected: true,
        /**
         * Default is false
         * @type Boolean
         */
        complete    : false,

        /**
         * Apply options
         * @param {Object} options
         */
        applyOptions: function (options) {
            if (options && typeof options === 'object') {
                var property;
                for (property in options) {
                    this[property] = options[property];
                }
            }
            return this;
        },
        /**
         * Apply arguments to method
         * @param {Function} method
         */
        getBind: function (method) {
            var obj = this;
            return function () {
                return method.apply(obj, arguments);
            };
        },
        /**
         * @public
         * Format object to queryString
         * @param {Object} object
         */
        toQueryString: function (object) {
            var uri = [];
            _toQueryString(uri, object, '');
            return uri.join('&');
        },
        /**
         * Abort request
         */
        abort: function () {
            this.xhr.onreadystatechange = this.stub;
            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }
            this.xhr.abort();
        },
        /**
         * Get transport
         * @returns {Object} xhr XMLHttpRequest object
         */
        getTransport: function () {
            var xhr = null,
                func = function () { return null; };

            if (window.XMLHttpRequest) {
                if (!XMLHttpRequest.prototype.sendAsBinary) {
                    XMLHttpRequest.prototype.sendAsBinary = function (datastr) {
                        function byteValue(x) {
                            return x.charCodeAt(0) & 0xff;
                        }
                        var ords = Array.prototype.map.call(datastr, byteValue),
                            ui8a = new Uint8Array(ords);
                        this.send(ui8a.buffer);
                    };
                }

                xhr = new XMLHttpRequest();

                func = function () { return new XMLHttpRequest(); };

            } else if (window.ActiveXObject) {
                try {
                    xhr = new ActiveXObject('Microsoft.XMLHTTP');
                    func = function () { return new ActiveXObject('Microsoft.XMLHTTP'); };
                } catch (e) {}
            }

            Request.prototype.getTransport = func;
            return xhr;
        },
        /**
         * Set request headers
         */
        setRequestHeaders: function () {
            this.xhr.setRequestHeader('Content-Type', this.contentType +
                    (this.encoding ? '; charset=' + this.encoding : ''));

            // Set header so the called script knows that it's an XMLHttpRequest
            this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            this.xhr.setRequestHeader('Accept', 'text/javascript, text/html, application/xml, text/xml, */*');
        },
        /**
         * Set onReadyStateChange = 'timeout' if not complete request
         */
        _onTimeout: function () {
            if (this.xhr) {
                this.abort();
            }
            if (!this.complete) {
                this.onReadyStateChange('timeout');
            }
        },
        /**
         * Request is ready
         * Fire events 'invalidjson', 'requestComplete'
         */
        onReadyStateChange: function (isTimeout) {
            isTimeout = isTimeout === 'timeout';

            if (this.complete || !this.xhr || ((this.xhr.readyState !== 4) && (!isTimeout))) {
                return;
            }
            /*if (this.mask) {
                this.mask.hide();
                document.body.removeChild(this.maskElem);
            }*/
            this.complete = true;
            Request.counts -= 1;

           // console.log('after')
           // console.log(Request.counts)

            var status = (isTimeout && 'Timeout') ||
                (!this.isSuccessCode() && 'Failure') ||
                'Success',
                handler = 'on' + status;

            if (!isTimeout && this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }

            // check for valid json
            if (!isTimeout && this.jsonExpected) {
                this.responseJson = null;
                try {
                    this.responseJson = this.xhr.responseText.evalJSON();
                } catch (e) {}
                if (this.responseJson === null) {
                    observable.fireEvent('invalidjson', this.xhr, this);
                }
            }

            if (this[handler]) {
                this[handler].call(this.scope, this.xhr, this);
            }
            if (this.onComplete) {
                this.onComplete.call(this.scope, this.xhr, this);
            }

            observable.fireEvent('requestComplete', this);

            this.responseJson = null;
            this.xhr.onreadystatechange = this.stub;
            this.xhr = null;
        },
        /**
         * Empty function
         */
        stub: function () {},
        /**
         * Check request status
         * @returns {bool}
         */
        isSuccessCode: function () {
            try {
                var status = this.xhr.status;
                return (!status && location.protocol === "file:") ||
                    (status >= 200 && status < 300) || status === 304 || status === 1223 ||
                    (isSafari && status === undefined);
            } catch (e) {}
            return false;
        }
    };

    Request.on = observable.on.bindLegacy(observable);
    Request.un = observable.on.bindLegacy(observable);   // Request.active ...
    Request._observable = observable;

    if (typeof GUI === 'undefined') { GUI = {}; }
    if (typeof GUI.Ajax === 'undefined') { GUI.Ajax = {}; }

    GUI.Ajax.Request = Request;
    window.Ajax = GUI.Ajax;
}());