(function () {
    var superproto = GUI.Application.prototype;

    GUI.DataGateway = Class.create();
    Object.extend(GUI.DataGateway.prototype, superproto);

    /**
     * Type of the module, default is 'DataGateway'
     * @type String
     */
    GUI.DataGateway.prototype.type = 'DataGateway';

    /**
     * Initializes model(s)
     * @param {String|Array} config
     */
    GUI.DataGateway.prototype.initialize = function (config) {
        Object.extend(this, config);
        superproto.create.call(this);

        this.addEvents('onLoadData', 'failLoadData');
    };

    /**
     * Returns model by the name
     * @param {String} name of the model
     */
    GUI.DataGateway.prototype.getDataGateway = function (name) {
        if (!GUI.isSet(name)) {
            return 'Name of DataGateway id undefined';
        }
        return superproto.getModule.call(this, 'DataGateway', name);
    };

    /**
     * Returns factory by the name
     * @param {String} name of the factory
     * @returns {Object} factory
     */
    GUI.DataGateway.prototype.getFactory = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Factory', name);
    };

    /**
     *
     */
    GUI.DataGateway.prototype.getZendError = function (text) {
        if (!text) {
            return;
        }
        var errorReg = /(<title>Zend Framework Default Application<\/title>[\s]*<\/head>[\s]*<body>)([\s|\S]*)(<\/pre>[\s]*<\/body>[\s]*<\/html>)/gi,
            errorRegStart = /<title>Zend Framework Default Application<\/title>[\s]*<\/head>[\s]*<body>/gi,
            errorRegEnd = /<\/pre>[\s]*<\/body>[\s]*<\/html>/gi,
            txt;
        txt = text.match(errorReg)[0];
        txt = txt.replace(errorRegStart, '');
        txt = txt.replace(errorRegEnd, '');

        return (txt === '' ? text : txt);
    };

    /**
     *
     * @param {type} name
     * @param {type} xhr
     * @param {type} response
     * @returns {undefined}
     */
    GUI.DataGateway.prototype.onLoadFailure = function (name, xhr, response) {
        var responseText = this.getZendError(xhr.responseText);
        this.fireEvent('failLoadData', xhr, responseText, this);
    };

    /**
     *
     */
    GUI.DataGateway.prototype.onLoad = function (xhr, response) {
        var resp;

        if (response.responseJson) {
            resp = response.responseJson;
        } else {
            resp = this.getZendError(xhr.responseText);
        }
        this.fireEvent('onLoadData', xhr, resp, response, this);
    };

}());
