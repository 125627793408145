/**
* @class GUI.Utils.Observable
* Abstract base class that provides a common interface for publishing events. Subclasses are expected to
* to have a property "events" with all the events defined.<br>
* For example:
* <pre><code>
Employee = function (name) {
this.name = name;
this.addEvents({
"fired" : true,
"quit" : true
});
}
Ext.extend(Employee, GUI.Utils.Observable);
</code></pre>
*/

/**
 * JavaScript Graphical User Interface
 * Observable implementation
 *
 * @author Eugene Lyulka
 * @version 2.0
 * @namespace GUI.Utils
 */

GUI.Utils.Observable = Class.create();

GUI.Utils.Observable.prototype = {

    /**
     * Initialize objects
     */
    initialize: function () {
        if (this.listeners) {
            this.on(this.listeners);
            delete this.listeners;
        }
        GUI.registerObject(this);
    },

    // * @param {String} eventName
    // * @param {Object...} args Variable number of parameters are passed to handlers

    /**
    * Fires the specified event with the passed parameters (minus the event name).
    * @return {Boolean} returns false if any of the handlers return false otherwise it returns true
    */
    fireEvent : function () {
        if (this.eventsSuspended !== true) {
            var ce = this.events[arguments[0].toLowerCase()];
            if (typeof ce === "object") {
                return ce.fire.apply(ce, Array.prototype.slice.call(arguments, 1));
            }
        }
        return true;
    },

    /**
     *
     */
    filterOptRe : /^(?:scope|delay|buffer|single)$/,

    /**
    * Appends an event handler to this component
    * @param {String}   eventName The type of event to listen for
    * @param {Function} fn The method the event invokes
    * @param {Object}   scope (optional) The scope in which to execute the handler
    * function. The handler function's "this" context.
    * @param {Object}   o (optional) An object containing handler configuration
    * properties. This may contain any of the following properties:<ul>
    * <li>scope {Object} The scope in which to execute the handler function. The handler function's "this" context.</li>
    * <li>delay {Number} The number of milliseconds to delay the invocation of the handler after te event fires.</li>
    * <li>single {Boolean} True to add a handler to handle just the next firing of the event, and then remove itself.</li>
    * <li>buffer {Number} Causes the handler to be scheduled to run in an {@link GUI.Utils.DelayedTask} delayed
    * by the specified number of milliseconds. If the event fires again within that time, the original
    * handler is <em>not</em> invoked, but the new handler is scheduled in its place.</li>
    * </ul><br>
    * <p>
    * <b>Combining Options</b><br>
    * Using the options argument, it is possible to combine different types of listeners:<br>
    * <br>
    * A normalized, delayed, one-time listener that auto stops the event and passes a custom argument (forumId)
    <pre><code>
    el.on('click', this.onClick, this, {
    single: true,
    delay: 100,
    forumId: 4
    });
    </code></pre>
    * <p>
    * <b>Attaching multiple handlers in 1 call</b><br>
    * The method also allows for a single argument to be passed which is a config object containing properties
    * which specify multiple handlers.
    * <pre><code>
    el.on({
    'click': {
    fn: this.onClick,
    scope: this,
    delay: 100
    },
    'mouseover': {
    fn: this.onMouseOver,
    scope: this
    },
    'mouseout': {
    fn: this.onMouseOut,
    scope: this
    }
    });
    </code></pre>
    * <p>
    * Or a shorthand syntax which passes the same scope object to all handlers:
    <pre><code>
    el.on({
    'click': this.onClick,
    'mouseover': this.onMouseOver,
    'mouseout': this.onMouseOut,
    scope: this
    });
    </code></pre>
    */
    addListener : function (eventName, fn, scope, o) {
        var e, ce;
        if (typeof eventName === "object") {
            o = eventName;
            for (e in o) {
                if (this.filterOptRe.test(e)) {
                    continue;
                }
                if (typeof o[e] === "function") {
                    // shared options
                    this.addListener(e, o[e], o.scope,  o);
                } else {
                    // individual options
                    this.addListener(e, o[e].fn, o[e].scope, o[e]);
                }
            }
            return this;
        }
        o = (!o || typeof o === "boolean") ? {} : o;
        eventName = eventName.toLowerCase();
        ce = this.events[eventName] || true;
        if (typeof ce === "boolean") {
            ce = new GUI.Utils.Event(this, eventName);
            this.events[eventName] = ce;
        }
        ce.addListener(fn, scope, o);

        return this;
    },

    /**
    * Removes a listener
    * @param {String}   eventName The type of event to listen for
    * @param {Function} fn The handler to remove
    * @param {Object}   scope  (optional) The scope (this object) for the handler
    */
    removeListener : function (eventName, fn, scope) {
        var e, ce, o = eventName;
        if (typeof eventName === 'object') {
            for (e in o) {
                if (this.filterOptRe.test(e)) {
                    continue;
                }
                if (typeof o[e] === "function") {
                    // shared options
                    this.removeListener(e, o[e], o.scope);
                } else {
                    // individual options
                    this.removeListener(e, o[e].fn, o[e].scope);
                }
            }
            return;
        }
        eventName = eventName.toLowerCase();
        ce = this.events[eventName];
        if (typeof ce === "object") {
            ce.removeListener(fn, scope);
        }
    },

    /**
    * Removes all listeners for this object
    */
    purgeListeners : function () {
        var evt;
        for (evt in this.events) {
            if (typeof this.events[evt] === "object") {
                this.events[evt].clearListeners();
            }
        }
    },

    /**
    * Used to define events on this Observable
    * @param {Object|String} o The object with the events defined or
    * multiple string arguments with names of events to add
    */
    addEvents : function (o) {
        if (!this.events) {
            this.events = {};
        }

        var name, i,
            a = arguments,
            events = this.events;

        if (typeof o === 'string') {
            for (i = 0; name = a[i]; i++) {
                events[name] = true;
            }
        } else {
            Object.extendIf(events, o);
        }
    },

    /**
    * Checks to see if this object has any listeners for a specified event
    * @param {String} eventName The name of the event to check for
    * @returns {Boolean} True if the event is being listened for, else false
    */
    hasListener : function (eventName) {
        if (!this.events) {
            this.events = {};
        }
        eventName = eventName.toLocaleLowerCase();
        var e = this.events[eventName];
        return typeof e === "object" && e.listeners.length > 0;
    },

    /**
     * Suspend the firing of all events. (see {@link #resumeEvents})
     */
    suspendEvents : function () {
        this.eventsSuspended = true;
    },

    /**
     * Resume firing events.
     * @link #suspendEvents
     */
    resumeEvents : function () {
        this.eventsSuspended = false;
    },

    /**
    * Starts capture on the specified Observable. All events will be passed
    * to the supplied function with the event name + standard signature of the event
    * <b>before</b> the event is fired. If the supplied function returns false,
    * the event will not fire.
    * @param {Observable} o The Observable to capture
    * @param {Function} fn The function to call
    * @param {Object} scope (optional) The scope (this object) for the fn
    */
    capture: function (o, fn, scope) {
        throw new Exception('Observable.capture is not implemented!');
    },

    /**
    * Removes <b>all</b> added captures from the Observable.
    * @param {Observable} o The Observable to release
    * @static
    */
    releaseCapture: function (o) {
        o.fireEvent = GUI.Utils.Observable.prototype.fireEvent;
    },

    /**
     * Catch target event and fire event from this class
     * @param {String} eventName
     * @param {GUI.Utils.Observable} targetListening
     */
    passEvent: function (eventName, targetListening) {
        this.addEvents(eventName);

        fn = function () {
            var args = Array.prototype.slice.call(arguments, 0);
            return this.fireEvent.apply(this, [eventName].concat(args));
        };

        if (!this._passEventCallback) {
            this._passEventCallback = {};
        }

        if (!this._passEventCallback[eventName]) {
            this._passEventCallback[eventName] = [];
        }

        this._passEventCallback[eventName].push(fn);

        targetListening.on(eventName, fn, this);
    },

    /**
     * Stop pass event listen
     * @param {String} eventName
     * @param {GUI.Utils.Observable} targetListening
     */
    unPassEvent: function (eventName, targetListening) {
        if (!this._passEventCallback) {
            this._passEventCallback = {};
        }

        if (!this._passEventCallback[eventName]) {
            return;
        }

        this._passEventCallback[eventName].each(function (fn) {
            targetListening.un(eventName, fn, this);
        }.bindLegacy(this));

        delete this._passEventCallback[eventName];
    }
};

// Add aliases to addListener and removeListener
GUI.Utils.Observable.prototype.on = GUI.Utils.Observable.prototype.addListener;
GUI.Utils.Observable.prototype.un = GUI.Utils.Observable.prototype.removeListener;

(function () {
    var createBuffered, createSingle, createDelayed;

    createBuffered = function (h, o, scope) {
        var task = new GUI.Utils.DelayedTask();
        return function () {
            task.delay(o.buffer, h, scope, Array.prototype.slice.call(arguments, 0));
        };
    };

    createSingle = function (h, e, fn, scope) {
        return function () {
            e.removeListener(fn, scope);
            return h.apply(scope, arguments);
        };
    };

    createDelayed = function (h, o, scope) {
        return function () {
            var args = Array.prototype.slice.call(arguments, 0);
            setTimeout(function () {
                h.apply(scope, args);
            }, o.delay || 10);
        };
    };

    /**
     * JavaScript Graphical User Interface
     * Event implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     */
    GUI.Utils.Event = Class.create();

    GUI.Utils.Event.prototype = {
        /**
         * Initialize object
         * @param {Object} obj
         * @param {String} name
         */
        initialize: function (obj, name) {
            this.name = name;
            this.obj = obj;
            this.listeners = [];
        },

        /**
         * Add listener
         * @param {Function} fn
         * @param {Boolean} scope
         * @param {Array} options
         */
        addListener : function (fn, scope, options) {
            var l, h,
                o = options || {};
            scope = scope || this.obj;
            if (!this.isListening(fn, scope)) {
                l = {fn: fn, scope: scope, options: o};
                h = fn;
                if (o.delay) {
                    h = createDelayed(h, o, scope);
                }
                if (o.single) {
                    h = createSingle(h, this, fn, scope);
                }
                if (o.buffer) {
                    h = createBuffered(h, o, scope);
                }
                l.fireFn = h;
                if (!this.firing) { // if we are currently firing this event, don't disturb the listener loop
                    this.listeners.push(l);
                } else {
                    this.listeners = this.listeners.slice(0);
                    this.listeners.push(l);
                }
            }
        },

        /**
         * Returns listener
         * @param {Function} fn
         * @param {Boolean} scope
         * @returns {Number}
         */
        findListener : function (fn, scope) {
            scope = scope || this.obj;
            var i, len, l,
                ls = this.listeners;
            for (i = 0, len = ls.length; i < len; i++) {
                l = ls[i];
                if (l.fn === fn && l.scope === scope) {
                    return i;
                }
            }
            return -1;
        },

        /**
         * Return true if listening
         * @param {Function} fn
         * @param {Boolean} scope
         */
        isListening : function (fn, scope) {
            return this.findListener(fn, scope) !== -1;
        },

        /**
         * Removes listener
         * @param {Function} fn
         * @param {Boolean} scope
         */
        removeListener : function (fn, scope) {
            var index;
            if ((index = this.findListener(fn, scope)) !== -1) {
                if (!this.firing) {
                    this.listeners.splice(index, 1);
                } else {
                    this.listeners = this.listeners.slice(0);
                    this.listeners.splice(index, 1);
                }
                return true;
            }
            return false;
        },

        /**
         * Clear listeners
         */
        clearListeners : function () {
            this.listeners = [];
        },

        /**
         * Firing event
         */
        fire : function () {
            var scope, args, i, l,
                ls = this.listeners,
                len = ls.length;

            if (len > 0) {
                this.firing = true;
                args = Array.prototype.slice.call(arguments, 0);
                for (i = 0; i < len; i++) {
                    l = ls[i];
                    if (l.fireFn.apply(l.scope || this.obj || window, arguments) === false) {
                        this.firing = false;
                        return false;
                    }
                }
                this.firing = false;
            }
            return true;
        }
    };
}());
