import '../../../public/js/front/search.js';
import '../../../public/js/front/advanced-search-form.js';
import '../../../public/js/front/tpls.js';
import '../../../public/js/front/application.js';
import '../../../public/js/front/Controllers/voting.js';
import '../../../public/js/front/common-dialog.js';
import '../../../public/js/front/auth/login-form.js';
import '../../../public/js/front/auth/auth-panel.js';
import '../../../public/js/front/auth/user-registration-form.js';
import '../../../public/js/front/auth/informers.js';
import '../../../public/js/front/user/user-password-form.js';
import '../../../public/js/front/user/user-profile-form.js';
import '../../../public/js/front/user/user-emails-form.js';
import '../../../public/js/front/user/user-group-data.js';
import '../../../public/js/front/user/load-avatar.js';
import '../../../public/js/front/profile-layer.js';
import '../../../public/js/front/ac-layer.js';
import '../../../public/js/front/breadcrumb.js';
import '../../../public/js/front/paging.js';
import '../../../public/js/front/BookmarkApp.js';
import '../../../public/js/front/Controllers/add-edit-item.js';
import '../../../public/js/front/Controllers/add-edit-ticket.js';
import '../../../public/js/front/Controllers/view-item.js';
import '../../../public/js/front/Controllers/add-ticket-reply.js';
import '../../../public/js/front/Controllers/satisfaction-form.js';
import '../../../public/js/front/Controllers/show-ticket.js';
import '../../../public/js/front/share-via-email-dialog.js';
import '../../../public/js/front/confirm-form.js';
import '../../../public/js/front/mark-best-form.js';
import '../../../public/js/front/external-accounts.js';
import '../../../public/js/front/alerts.js';
import '../../../public/js/front/common.js';