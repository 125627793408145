(function () {
    /**
     * GUI.Grid.ActionColumn.prototype.
     * JavaScript Graphical User Interface
     * ActionColumn implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Grid
     */
    GUI.Grid.ActionColumn = Class.create();

    /**
     * Caption of the column
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.caption = i18n('Action');

    /**
     * If true, column can be sort, default is false
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.sortable = false;

    /**
     * Align in column, default is 'left'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.align = 'left';

    /**
     * Index of the data, default is 'actions'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.dataIndex = 'actions';

    /**
     * If true selection is blocked, default is true
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.blockSelection = true;

    /**
     * Overflow, default is true
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.overflow = true;

    /**
     * Class of the header element
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.headerCls = 'cell_actions';

    /**
     * Width of the column, default is 1 (Need 1px, because IE6 ignores 0)
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.width = 1;

    /**
     * Max width of the column, default is 0
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.maxWidth = 0;

    /**
     * Min width of the column, default is 0
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.minwidth = 0;

    /**
     * Width of the image, default is 20
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.imageWidth = 20;

    /**
     * Default is true
     * @type boolean
     */
    GUI.Grid.ActionColumn.prototype.lightMenu = true;

    /**
     * If true row has own function, default is true
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.rawRenderer = true;

    /**
     * Mode of the column, can be 'imagetext', 'image', 'text', 'menu',
     * default is 'imagetext'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.mode = 'imagetext';

    /**
     * Css class of the cell
     * @type String
     */
    GUI.Grid.ActionColumn.prototype.cellCls = 'cell_actions b-buttons-list';

    /**
     * If true, mode taken from server, default is true
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.useServerMode = true;

    /**
     * If true menu will be shown on hover, default is false
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.showMenuOnHover = false;

    /**
     * If true menu animation will be shown, default is false
     * @type Boolean
     */
    GUI.Grid.ActionColumn.prototype.showMenuAnimation = false;

    /**
     * Delay before show menu, default is 400
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.showMenuDelay = 400;

    /**
     * Delay before hide menu, default is 400
     * @type Number
     */
    GUI.Grid.ActionColumn.prototype.hideMenuDelay = 400;

    /**
     * Constructor. Initializes config. Grid is unavailable at this time
     * @param {Object} cfg Configuration object
     */
    GUI.Grid.ActionColumn.prototype.initialize = function (cfg) {
        if (cfg) {
            Object.extend(this, cfg);
        }
        if (!this.id) {
            this.id = GUI.getUniqId('actionbar-');
        }
    };

    /**
     * Called by grid internally!
     * @oaram {Object} grid Object of the grid
     */
    GUI.Grid.ActionColumn.prototype.init = function (grid) {
        this.grid = grid;
        // Add action event to grid
        grid.addEvents({
            action: true
        });

        this.html = new GUI.StringBuffer();
        this.showMenuTask = new GUI.Utils.DelayedTask(this.showActionMenu, this);
        this.hideMenuTask = new GUI.Utils.DelayedTask(this.hideActionMenu, this);

        grid.on({
            scope: this,
            cellMouseOver: this.onGridCellMouseOver,
            cellMouseOut: this.onGridCellMouseOut,
            cellClick: this.onGridCellClick
        });
    };

    /**
     * Renders action bar in row. Set in constructor to point to needed renderer
     * @param {Object} data Action data in format:
     * {
     *         mode: <mode>, // modes: 'text', 'image', 'imagetext', 'menu'
     *         items: [ {name: <name> , text: <caption>, img: <image url>}, ... ]
     * }
     * @param {Object} row
     * @param {Object} col
     * @param {Object} cell
     * @returns {HTMLElement} html
     */
    GUI.Grid.ActionColumn.prototype.renderer = function (data, row, col, cell) {
        // Determine mode and call appropriate renderer
        var mode = (this.useServerMode && data.mode) || this.mode;
        return this['_' + mode + 'Renderer'](data, row, col, cell);
    };

    /**
     * Template for mode 'imagetext'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype._imagetextTemplate =
        '<button class="b-button b-button_simple" hint="{1}" {0} mode="imagetext" actionname="{4}">' +
        '<i class="b-button__icon" style="background-image: url({2});">&nbsp;</i>' +
        '<span class="b-button__label">{3}</span>' +
        '</button>';

    /**
     * Render mode 'imagetext'
     * @param {Object} data
     * @param {Object} row
     * @param {Object} col
     * @param {Object} cell
     * @returns {HTMLElement} html
     */
    GUI.Grid.ActionColumn.prototype._imagetextRenderer = function (data, row, col, cell) {
        var i, action,
            actions = data.items,
            len = actions.length,
            html = this.html;

        html.clear();

        for (i = 0; i < len; i++) {
            action = actions[i];
            html.append(this._imagetextTemplate.format(
                (action.disabled ? ' disabled="disabled"' : ''),
                action.hint || '',
                action.img,
                action.text,
                action.name
            ));
        }
        return html.toString();
    };

    /**
     * Template for mode 'image'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype._imageTemplate =
        '<button class="b-button b-button_icon-only b-button_simple" hint="{1}" {0} mode="image" actionname="{3}">' +
        '<i class="b-button__icon" style="background-image: url({2});">&nbsp;</i>' +
        '</button>';

    /**
     * Render mode 'image'
     * @param {Object} data
     * @param {Object} row
     * @param {Object} col
     * @param {Object} cell
     * @returns {HTMLElement} html
     */
    GUI.Grid.ActionColumn.prototype._imageRenderer = function (data, row, col, cell) {
        var i, action,
            actions = data.items,
            len = actions.length,
            html = this.html;

        html.clear();
        for (i = 0; i < len; i++) {
            action = actions[i];
            html.append(this._imageTemplate.format(
                (action.disabled ? ' disabled="disabled"' : ''),
                action.hint || '',
                action.img,
                action.name
            ));
        }

        return html.toString();
    };

    /**
     * Template for mode 'text'
     * @type String
     */
    GUI.Grid.ActionColumn.prototype._textTemplate =
        '<button class="b-button b-button_simple" hint="{1}" {0} mode="text" actionname="{3}">' +
        '<span class="b-button__label">{2}</span>' +
        '</button>';

    /**
     * Render mode 'text'
     * @param {Object} data
     * @param {Object} row
     * @param {Object} col
     * @param {Object} cell
     * @returns {HTMLElement} html
     */
    GUI.Grid.ActionColumn.prototype._textRenderer = function (data, row, col, cell) {
        var i, action,
            actions = data.items,
            len = actions.length,
            html = this.html;

        html.clear();

        for (i = 0; i < len; i++) {
            action = actions[i];
            html.append(this._textTemplate.format(
                (action.disabled ? ' disabled="disabled"' : ''),
                action.hint || '',
                action.text,
                action.name
            ));
        }
        return html.toString();
    };

    /**
     *
     */
    GUI.Grid.ActionColumn.prototype._menuTemplate =
        '<button class="b-button b-button_simple" modeName="menu">' +
        '<i class="b-button__icon dropdown_down"></i>' +
        '</button>';

    /**
     * Render mode 'menu'
     * @param {Object} data
     * @param {Object} row
     * @param {Object} col
     * @param {Object} cell
     * @returns {HTMLElement} html
     */
    GUI.Grid.ActionColumn.prototype._menuRenderer = function (data, row, col, cell) {
        return this._menuTemplate;
    };

    /**
     * Destroy objects
     */
    GUI.Grid.ActionColumn.prototype.destroy = function () {
        this.showMenuTask.cancel();
        this.hideMenuTask.cancel();
        this.grid.un({
            scope           : this,
            cellMouseOver   : this.onGridCellMouseOver,
            cellMouseOut    : this.onGridCellMouseOut,
            cellClick       : this.onGridCellClick
        });
    };

    /**
     * Sets mode
     * @param {String} mode Mode of the column
     */
    GUI.Grid.ActionColumn.prototype.setMode = function (mode) {
        this.mode = mode;
    };

    /**
     * Show action menu
     * @param {HTMLelement} target
     * @param {String} rowId
     */
    GUI.Grid.ActionColumn.prototype.showActionMenu = function (target, rowId) {
        if (!target) {
            return;
        }

        var action, k,
            row = this.grid.rows.get(rowId),
            actions = row.data.actions.items,
            items = [],
            i = actions.length;

        if (!this.menu) {
            this.menu = new GUI.Popup.Menu({
                className   : '',
                hideOnDocumentClick : true,
                animate     : this.showMenuAnimation
            });
            this.menu.on({
                scope       : this,
                click       : this.onMenuItemClick,
                mouseover   : this.onMenuMouseOver,
                mouseleave  : this.onMenuMouseLeave
            });
        }
        this.menu.setItems([]);
        // Generate items
        while (i--) {
            action = actions[i];

            if (action.sub && action.sub.items) {
                action.subActions = action.sub.items;
            }

            if (action.subActions) {
                for (k = 0; k < action.subActions.length; k++) {
                    action.subActions[k].action = action.subActions[k].name;
                    action.subActions[k].icon = action.subActions[k].img;
                }
            }

            items[i] = {
                action  : action.name,
                name    : action.name,
                caption : action.text,
                icon    : action.img,
                iconClass: action.iconClass,
                hint    : action.hint,
                disabled: action.disabled,
                sub     : (action.subActions) ? {items: action.subActions, rowId: rowId} : null
            };
        }

        this.menu.setItems(items);

        this.menu.rowId = rowId;

        if (target.nodeName.toLowerCase() === 'i') {
            target = target.parentNode;
        }

        this.menu.alignTo(target, 'tr-br?');
        this.menu.show();
        this.menu.dom.addClass('b-popup-menu_style_combo');
    };

    /**
     * Hides action menu
     */
    GUI.Grid.ActionColumn.prototype.hideActionMenu = function () {
        if (this.menu && this.menu.isVisible()) {
            this.menu.hide();
        }
    };

    /**
     * Fires event 'action'
     * @param {Object} menu
     * @param {Object} item
     */
    GUI.Grid.ActionColumn.prototype.onMenuItemClick = function (menu, item, e) {
        this.grid.fireEvent('action', this.grid, item.action, (menu.rowId || menu.config.rowId), undefined, e);
    };

    /**
     * Handler click on cell. Click in our cell, determines render mode.
     * Click on the action bar, match class name, display menu with actions.
     * Get the name of the action from the target classname and fires it
     *
     * @param {Object} grid
     * @param {Object} row
     * @param {Object} col
     * @param {Event} event
     */
    GUI.Grid.ActionColumn.prototype.onGridCellClick = function (grid, row, col, event) {
        if (col !== this) {
            return;
        }
        event.preventDefault();

        var mode, actionname,
            target = event.getTarget(),
            button = target.findParent('button', null, 'b-button');

        if (button && button.disabled !== true) {

            mode = this.mode; //(GUI.isIE) ? button.mode : button.getAttribute('mode');
            actionname = button.getAttribute('actionname');

            if (mode && mode === 'menu') {
                this.showMenuTask.delay(0, null, null, [target, row.id]);
            } else {
                button.setAttribute('disabled', 'disabled');
                grid.fireEvent('action', grid, actionname, row.id, button, event);
            }
        }
    };

    /**
     * Hanlders mouse over on cell
     * @param {Object} grid
     * @param {String} rowId
     * @param {Object} col
     * @param {Object} td
     * @param {Event} e
     */
    GUI.Grid.ActionColumn.prototype.onGridCellMouseOver = function (grid, rowId, col, td, e) {
        if ((this === col) && this.showMenuOnHover && (this.mode === 'menu')) {
            var ac = e.target.findParent('div', td, 'x-actions');
            this.hideMenuTask.cancel(); // cancel hide, delay show
            this.showMenuTask.delay(this.showMenuDelay, null, null, [e.target, rowId]);
        }
    };

    /**
     * Handler mouse out from cell.
     * @param {Object} grid
     * @param {String} rowId
     * @param {Object} col
     * @param {Object} td
     * @param {Event} e
     */
    GUI.Grid.ActionColumn.prototype.onGridCellMouseOut = function (grid, rowId, col, td, e) {
        if ((this === col) && this.showMenuOnHover && (this.mode === 'menu')) {
            var ac = e.target.findParent('div', td, 'x-actions');
            this.showMenuTask.cancel();
            this.hideMenuTask.delay(this.hideMenuDelay);
        }
    };

    /**
     * Handlers mouse over on menu
     * @param {Object} menu
     * @param {Event} e
     */
    GUI.Grid.ActionColumn.prototype.onMenuMouseOver = function (menu, e) {
        this.hideMenuTask.cancel();
    };

    /**
     * Handlers mouse leave menu
     * @param {Object} menu
     * @param {Event} e
     */
    GUI.Grid.ActionColumn.prototype.onMenuMouseLeave = function (menu, e) {
        if (this.showMenuOnHover) {
            this.hideMenuTask.delay(this.hideMenuDelay);
        }
    };

    /**
     *
     */
    GUI.Grid.ActionColumn.prototype.setIconMenu = function (imgClass) {
        if (imgClass) {
            this._menuTemplate = this._menuTemplate.replace('dropdown_down', imgClass);
        }
    };

}());