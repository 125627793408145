(function () {
    /**
     * JavaScript Graphical User Interface
     * Button types implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     */
    GUI.ButtonTypes = {

        /**
         * Type - OK, caption - OK, img - ''
         * @type Object
         */
        OK: {
            caption : i18n('OK'),
            hint    : i18n('OK'),
            img     : ''
        },

        /**
         * Type CANCEL, caption - Cancel, img - ''
         * @type Object
         */
        CANCEL: {
            caption : i18n('Cancel'),
            hint    : i18n('Cancel'),
            img     : ''
        },

        /**
         * Type - CLOSE, caption - Close, img = ''
         * @type Object
         */
        CLOSE: {
            caption : i18n('Close'),
            hint    : i18n('Close'),
            img     : ''
        },

        /**
         * Type - ADD, caption - Add, img- ''
         * @type Object
         */
        ADD: {
            caption : i18n('Add'),
            hint    : i18n('Add'),
            img     : ''
        },

        /**
         * Type - EDIT, caption - Edit, img- ''
         * @type Object
         */
        EDIT: {
            caption : i18n('Edit'),
            hint    : i18n('Edit'),
            img     : ''
        },

        /**
         * Type - DELETE, caption - Delete, img- ''
         * @type Object
         */
        DELETE: {
            caption : i18n('Delete'),
            hint    : i18n('Delete'),
            img     : ''
        },

        /**
         * Type - PRINT, caption - Print, img- ''
         * @type Object
         */
        PRINT: {
            caption : i18n('Print'),
            hint    : i18n('Print'),
            img     : ''
        },

        /**
         * Type - INSERT, caption - Insert, img- ''
         * @type Object
         */
        INSERT: {
            caption : i18n('Insert'),
            hint    : i18n('Insert'),
            img     : ''
        },

        /**
         * Type - CREATE, caption - Create, img- ''
         * @type Object
         */
        CREATE: {
            caption : i18n('Create'),
            hint    : i18n('Create'),
            img     : ''
        },

        /**
         * Type - APPLY, caption - Apply, img- ''
         * @type Object
         */
        APPLY: {
            caption : i18n('Apply'),
            hint    : i18n('Apply'),
            img     : ''
        }
    };
}());
