/**
 *
 */
GUI.onDOMReady(function () {
    if (GUI.$('add-item-form')) {
        AddEditItemController.init();
    }
});

/**
 *
 */
AddEditItemController = new GUI.Controller({
    name: 'AddEditItem',
    models: '',
    views: '',

    form: null
});

/**
 *
 */
AddEditItemController.init = function () {

    this.assignDomElements();

    this.showHideVoting();

    this.showHideCategories();

    this.addEvents();

    Application.LoginForm.defaultCallback = function () {
    }; // disable page reload after login

    Placeholders.addField(this.title.dom);
    Placeholders.addField(this.tags.dom);
    this.textarea.on('afterRenderEditor', this.onRequestCreate.bindLegacy(this));

    Application.initReCaptcha();

    window.addEventListener('message', function(e) {
        if (e.data.feedback) {
            this.form.getField('viaValue').setValue(e.data.href);
        }
    }.bindLegacy(this));
};


/**
 *
 */
AddEditItemController.assignDomElements = function () {
    var formDom = GUI.$('add-item-form'),
        txtArea = formDom.getElementsByTagName('textarea')[0];

    txtArea.setAttribute('cssForIframe', window.cssForIframe);

    this.form = new GUI.Forms.Form();
    this.form.assign('add-item-form');

    // Current page have "/add/" route parametr
    if (location.pathname.search('/add') === 0) {
        this.form.getField('title').focus();
    }

    this.spacesCombo = this.form.getField('space');

    this.space = this.spacesCombo
        ? this.spacesCombo.getValue()
        : GUI.$('space_key').innerHTML;

    this.spaceName = this.spacesCombo
        ? this.spacesCombo.options.items[this.spacesCombo.selectedIndex].spacename
        : GUI.$('space_name').innerHTML;
    this.channelsCombo = this.form.getField('channelType');

    this.title = this.form.getField('title');
    this.textarea = this.form.getField('body');
    this.tags = this.form.getField('tags');

    this.categoriesSection = GUI.$('add-item-category-block');
    this.categoriesCombo = this.form.getField('category');

    this.votingElement = GUI.$('add-item-voting');
    this.votingField = GUI.$('add-item-vote');
    this.votingToolbar = GUI.$('add-item-voting-panel');

    this.queueFiles = [];
    this.attachButton = GUI.$('add-item-attachment');

    this.helpSubmitBlock = GUI.$('help-submit-block');

    this.helpSubmitBlock.innerHTML = i18n('This topic will be posted publicly.');

    this.submitButton = GUI.$('add-item-post');

    this.ACButton = GUI.$('ac-button');
    this.ACCounter = GUI.$('ac-counter');

    this._form = GUI.$('add-item-form');

    this.acLayer = new AC_Layer();
};

/**
 *
 */
AddEditItemController.addEvents = function () {
    if (this.spacesCombo) {
        this.spacesCombo.on('change', this.onChangeCombo.bindLegacy(this, 'space'));
    }
    this.channelsCombo.on('change', this.onChangeCombo.bindLegacy(this, 'channel'));

    if (this.attachButton) {
        onWithFeatureRestrictions(
            'featureAttachmentsEnabled',
            this.attachButton.parentNode,
            'click',
            this.chooseAttachment.bindLegacy(this)
        );
    }

    if (this.votingToolbar) {
        this.votingToolbar.on('click', this.onClickVotingToolbar.bindLegacy(this));
    }

    this.submitButton.on('click', this.beforeSubmit.bindLegacy(this));
    this.title.fieldEl.on('keyup', this.onChangeData, this);
    this.title.on('keyup', this.onChangeData, this);
    this.title.on('invalid', function () {
        document.body.scrollTop = 0;
    });

    this.textarea.on('keyup', this.onChangeData, this);
    this.tags.fieldEl.on('keyup', this.onChangeData, this);
};

/**
 *
 */
AddEditItemController.removeEvents = function () {
    this.spacesCombo.un('change', this.onChangeCombo.bindLegacy(this));
    this.channelsCombo.un('change', this.onChangeCombo.bindLegacy(this));

    //this.inpAttachments[0].un('change', this.chooseAttachment.bindLegacy(this));

    this.submitButton.un('click', this.beforeSubmit.bindLegacy(this));
    this.title.fieldEl.un('keyup', this.onChangeData, this);
    this.textarea.un('keyup', this.onChangeData, this);
    this.tags.fieldEl.un('keyup', this.onChangeData, this);
};

/**
 *
 * @returns {undefined}
 */
AddEditItemController.onChangeData = function () {
    if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
    }

    this.requestTimeout = setTimeout(this.onRequestCreate.bindLegacy(this), 1000);
};

/**
 *
 * @returns {undefined}
 */
AddEditItemController.onRequestCreate = function () {
    var text = this.title.getValue() + ' ' + this.textarea.getValue() + ' ' + this.tags.getValue();
    if (this.channelsCombo.getValue() === 'praise') {
        GUI.$('search-result').innerHTML = '';
        return;
    }

    if (this.request && !this.request.complete) {
        this.request.abort();
        this.request = null;
    }

    if (text.trim().length < 2) {
        return;
    }

    this.request = new GUI.Ajax.Request(
        //route('getRelatedForText'),
        route('getRelatedForText' + (top === self ? '' : 'Feedback')),
        {
            method: 'POST',
            data: {
                text: text,
                space: this.spacesCombo ? this.spacesCombo.getValue() : GUI.$('space_key').innerHTML
            },
            onSuccess: this.onSuccessSearch.bindLegacy(this)
        }
    );
};

/**
 *
 * @param {type} xhr
 * @param {type} request
 * @returns {undefined}
 */
AddEditItemController.onSuccessSearch = function (xhr, request) {
    GUI.$('search-result').innerHTML = xhr.responseText;
};


/**
 *
 */
AddEditItemController.beforeSubmit = function (event) {
    event.stopPropagation();
    event.preventDefault();
    var e = GUI.Event.extend(event);
    e.stop();
    e.stopPropagation();
    e.preventDefault();

    if (this.submitButton.getAttribute('disabled')) {
        return false;
    }

    if (!this.title.validate()) {
        return false;
    }

    this.checkEmail();
    return false;
};

AddEditItemController.checkEmail = function() {
    var emailField = this.form.getField('email');

    if (window.itemAddByEmail && emailField) {
        if (!emailField.validate()) {
            return false;
        }

        new GUI.Ajax.Request(route('checkUserEmailExists', {}), {
            method: 'post',
            data: {email: emailField.getValue()},
            onSuccess: function (xhr, response) {
                if (response.responseJson && response.responseJson.active) {
                    Application.AuthPanel.authenticate(
                        this.executeCaptcha.bindLegacy(this),
                        emailField.getValue()
                    );
                } else {
                    this.executeCaptcha();
                }
            }.bindLegacy(this)
        });
    } else {
        AddEditItemController.captchaSubmit();
    }
}

AddEditItemController.executeCaptcha = function() {
    if (window.APPLICATION_ENV === 'staging' || window.APPLICATION_ENV === 'production') {
        grecaptcha.execute(GUI.$("submitItemReCaptcha").getAttribute('data-id'));
    } else {
        this.captchaSubmit();
    }
}

AddEditItemController.captchaSubmit = function () {
    if (window.itemAddByEmail) {
        this.submitForm();
    } else {
        Application.AuthPanel.authenticate(
            //this._form.submit.bindLegacy(this._form)
            this.submitForm.bindLegacy(this)
        );
    }

    //this.submitButton.setAttribute('disabled', 'disabled');
};

/**
 *
 */
AddEditItemController.onChangeCombo = function (type, combo, value, prevValue) {
    var stopper,
        space = this.spacesCombo
            ? this.spacesCombo.getValue()
            : this.space,
        spaceName = this.spacesCombo
            ? this.spacesCombo.options.items[this.spacesCombo.selectedIndex].spacename
            : this.spaceName,
        feedbackSpace = this.form.getField('feedbackSpace')
            ? this.form.getField('feedbackSpace').getValue()
            : null;

    if (this.channelsCombo.getValue() === 'request') {
        if (top === self) {
            this.form.dom.action = route('addTicket', {});
        } else {
            this.form.dom.action = route('addTicketFeedback', {});

            if (feedbackSpace) {
                this.form.dom.action += '?feedbackSpace=' + feedbackSpace;
            }
        }
        stopper = document.createElement('input');
        stopper.name = 'stopper';
        stopper.value = '1';
        stopper.type = 'hidden';

        this.form.dom.appendChild(stopper);
        this.form.addField(stopper);

        //this.form.dom.submit();
        this.submitForm();

        return;
    }

    this.onRequestCreate();

    new GUI.Ajax.Request(
        route('getChannelsAndCategoriesDataFront', {
            space: space,
            name: spaceName,
            channel: this.channelsCombo.getValue()
        }),
        {
            method: 'get',
            onSuccess: function (xhr, response) {
                var data = '';

                if (response) {
                    data = response.responseJson;
                    this.setChannels(data.channels, prevValue);
                    this.setCategories(data.categories);
                }

            }.bindLegacy(this)
        }
    );
};

/**
 *
 */
AddEditItemController.setCategories = function (data) {
    data = data || [];

    this.categoriesCombo.setOptions(data);
    this.categoriesCombo.select(0);

    this.showHideCategories();
};

/**
 *
 */
AddEditItemController.showHideCategories = function () {
    if (this.categoriesCombo.options.length <= 2) {
        GUI.hide(this.categoriesSection);
    } else {
        GUI.show(this.categoriesSection);
    }
};

/**
 *
 */
AddEditItemController.setChannels = function (data, prevValue) {
    var item, settings,
        combo = this.channelsCombo;

    data.each(function (item) {
        item.icon = 'fa fa_icon_' + item.value;
    });

    if (Application.settings.ticketsEnabled) {
        data.push({
            settings: {},
            text: i18n("Submit a Request"),
            value: 'request',
            icon: 'fa fa_icon_tickets'
        });
    }

    combo.setOptions(data || []);

    item = combo.getItemByValue(combo.getValue());
    if (null === item) {
        combo.selectItem(0);
        item = combo.getItemByValue(combo.getValue());
    }

    settings = item.settings;

    this.showHideVoting(settings.itemVotingType);

    this.helpSubmitBlock.innerHTML = i18n('This topic will be posted publicly.');
};

/**
 *
 */
AddEditItemController.showHideVoting = function (type) {
    if (!this.votingElement) {
        return;
    }
    var itemVotingType = type || (this.votingElement && this.votingElement.getAttribute('currentChannelItemVotingType'));

    if (itemVotingType === 'custom-positive' || itemVotingType === 'custom-positive-negative') {
        GUI.show(this.votingElement);
        this.toggleButton(GUI.$('add-item-vote_1'));
    } else {
        this.votingField.value = 1;
        GUI.hide(this.votingElement);
    }
};

AddEditItemController.submitForm = function () {
    this.submitButton.setAttribute('disabled', 'disabled');
    this._form.submit();
};

AddEditItemController.chooseAttachment = function () {
    if (!this.attachments) {
        this.attachments = new Attachments();
        this.attachments.init({
            attachmentsHolder: GUI.$('listNamesAttachments'),
            attachments: window.editItemAttachList, // window.editItemAttachList creating in save-item.tpl
            handlerDeleteAttachments: this.onDeleteHandler.bindLegacy(this),
            callback: this.setAttachmentsCount.bindLegacy(this)
        });
    }

    this.attachments.render();
};

AddEditItemController.setAttachmentsCount = function (count) {
    var text = '';

    if (count > 0) {
        text = sprintf(i18n('%s Attachment', '%s Attachments', count), count);
    } else {
        text = i18n('Attachments');
    }

    this.attachButton.innerHTML = text;
};

AddEditItemController.onDeleteHandler = function (data) {
    return {
        url: route('deleteAttachment', {
            id: data.id,
            itemId: data.item
        })
    };
};

/**
 *
 */
AddEditItemController.onClickVotingToolbar = function (e) {
    e = GUI.Event.extend(e);
    var target = e.getTarget(),
        targetName = target.nodeName.toLowerCase();

    if (targetName === 'span') {
        target = target.parentNode;
        targetName = target.nodeName.toLowerCase();
    }

    if (targetName === 'a') {
        this.toggleButton(target);
    }
};

/**
 *
 */
AddEditItemController.toggleButton = function (btn) {
    if (this._toggledButton) {
        this._toggledButton.removeClass('btn_toggled');
    }
    this._toggledButton = btn;
    this._toggledButton.addClass('btn_toggled');

    this.votingField.value = btn.getAttribute('votevalue');
};

/**
 *
 */
AddEditItemController.deleteExistingAttachment = function (input) {
    var showAttach = function () {
            GUI.show(input.parentNode);
        },
        hideAttach = function () {
            GUI.hide(input.parentNode);
        };

    hideAttach();

    new GUI.Ajax.Request(
        route('deleteAttachment', {
            id: input.getAttribute('attachmentid'),
            itemId: input.getAttribute('itemid')
        }),
        {
            onSuccess: function (an) {
                var response = an.responseText.evalJSON();
                if (!response || !response.done) {
                    showAttach();
                }
            }.bindLegacy(this),
            onFailure: showAttach.bindLegacy(this)
        }
    );
};

/**
 *
 */
confirmMessage = function (callback, config) {
    if (!config) {
        config = {};
    }

    var msgBox,
        title = config.title || i18n('Delete'),
        msg = config.msg || i18n('Are you sure you want to delete this?'),
        okButton = config.okButton || i18n('Delete'),
        cancelButton = config.cancelButton || i18n('Cancel');

    msg = '<div class="default-data-layout">' + msg + '</div>';

    msgBox = this._errMessageBox = new GUI.Popup.Dialog({
        alwaysOnTop: true,
        title: title,
        content: msg,
        modal: true,
        autoDestroy: true,
        dimensions: {
            width: 400
        },
        toolbuttons: [],
        footerButtons: [{
            name: 'ok',
            obj: new GUI.ToolBar.Button({
                text: okButton,
                primary: true,
                onClick: function () {
                    if (callback) {
                        callback();
                    }
                    msgBox.close();
                }
            })
        }, {
            name: 'cancel',
            obj: new GUI.ToolBar.Button({
                text: cancelButton,
                onClick: function () {
                    msgBox.close();
                }
            })
        }]
    });
    msgBox.create();
    msgBox.show();
};