(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * ProgressBar implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.ProgressBar = Class.create();
    Object.extend(GUI.ProgressBar.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.ProgressBar.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this);

        // Default config
        this.config =  {
            id      : GUI.getUniqId('progressbar-'),
            cls     : 'b-progressbar',
            holder  : null,
            width   : null,
            height  : 16,
            value   : 0
        };
        var cfg = this.config;
        if (config) {
            Object.extend(cfg, config);
        }

        this.visible = cfg.visible;

        this.addEvents({
            update: true
        });

        if (cfg.name) {
            GUI.ComponentMgr.register(cfg.name, this);
        }
    };

    /**
     * Destroy objects
     */
    GUI.ProgressBar.prototype.destroy = function () {
        if (this.config.name) {
            GUI.ComponentMgr.unregister(this);
        }
        this.purgeListeners();
        GUI.destroyObject(this);
    };

    /**
     * Removes events listeners, dom
     */
    GUI.ProgressBar.prototype.unrender = function () {
        this.removeEventListeners();
        GUI.remove(this.dom);
        this.dom = null;
    };

    /**
     * Renders dom
     * @param {HTMLElement} to Element to render dom to
     * @param {Boolean} before Method of adding html in dom
     */
    GUI.ProgressBar.prototype.render = function (to, before) {
        if (this.dom) {
            this.unrender();
        }

        var cfg = this.config,
            i_el = document.createElement('I'),
            tpl = new GUI.STemplate('<i id="{cfg.id}-inner" class="b-progressbar__line"></i><i id="{cfg.id}-text" class="b-progressbar__txt"></i>');

        tpl.cfg  = cfg;
        i_el.id   = cfg.id;
        i_el.className     = cfg.cls;
        i_el.innerHTML     = tpl.fetch();

        GUI.hide(i_el);
        if (to) {
            cfg.holder = to;
        }

        if (before) {
            GUI.$(cfg.holder).insertBefore(i_el, before);
        } else {
            GUI.$(cfg.holder).appendChild(i_el);
        }
        this.dom = i_el;
        this._applyValue();

        // Now can show
        GUI.show(i_el);
    };

    /**
     * Updates bar, fires event 'update'
     * @param {Number} value
     * @param {String} text
     */
    GUI.ProgressBar.prototype.update = function (value, text) {
        var cfg = this.config;
        value = Math.max(0, Math.min(value, 100)); // Constrain value to 0..100
        cfg.value = value;

        // Update view if widget is rendered
        if (this.dom) {
            this._applyValue();
        }
        this.fireEvent('update', value);
    };

    /**
     * Applies value
     */
    GUI.ProgressBar.prototype._applyValue = function () {
        var cfg = this.config;
        GUI.$(cfg.id + '-inner').style.width = cfg.value.toString() + '%';
        GUI.$(cfg.id + '-text').innerHTML = cfg.value.toString() + '%';
    };

}());