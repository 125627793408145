(function() { // variables isolation
    window.SearchFilterClass = window.Class.create();

    prototype = {
        _mainForm : null,

        _filterForm : null,

        _form : null,

        _previousValues : {
            search : '',
            filter : {
                space    : '',
                channel  : '',
                category : '',
                time     : '',
                user     : ''
            },
            'in' : []
        },

        _applyInputsSync: function() {
            this._mainForm.search.on('change', function(ev) {
                this._filterForm.q.value = ev.target.value;
            }, this);
        },

        _setupFiltersForm: function() {
            this._form = new GUI.Forms.Form();
            this._form.assign(this._filterForm);

            this._form.on('beforeSubmit', function() {

                var params = {content:[]};
                var values = this._form.getValues();
                for (var fieldName in values) {
                    if (!values.hasOwnProperty(fieldName) || fieldName == 'content')
                        continue;

                    if (values[fieldName] != '') {
                        params[fieldName] = values[fieldName];
                    }
                }

                var content = this._form.getField('content');
                for (var index in content) {
                    if (!content.hasOwnProperty(index)) continue;

                    if (content[index].isChecked()) {
                        params.content.push(content[index].getValue());
                    }
                }

                if (params.content.length == content.length) {
                    delete params.content;
                } else {
                    params.content = params.content.join(',');
                }

                var url = '' + route('search', []) + '?' + GUI.toQueryString(params);
                window.location.href = url;

                return false;
            }, this);

            GUI.hide('adv-search-categories-holder');

            // set value to '*' to raise onchange event for channels loading
            // after setting real combo value
            this._form.getField('space').setValue('*');

            this._form.getField('space').on(
                'change',
                this._onSpaceSelect,
                this
            );

            this._form.getField('channel').on(
                'change',
                this._onChannelSelect,
                this
            );

            Object.extend(
                this._previousValues,
                GUI.$('adv-search-filters-selected-values').value.evalJSON()
            );

            this._form.getField('space').setValue(this._previousValues.filter.space);

            this._form.getField('time').setValue(this._previousValues.filter.time);

            GUI.$('adv-search-reset').on('click', this.reset, this);
        },

        _onSpaceSelect: function(combo) {
            var url = 'getChannelsList';

            var channelsCombo = this._form.getField('channel');
            channelsCombo.setOptions([channelsCombo.options.items[0]]);
            channelsCombo.select(0);

            if (combo.getValue() == '' && combo.options.items.length != 2) {
                url = 'getAllChannelsList';
            } else if(combo.options.items.length == 2) {
                combo.select(1);
            }

            channelsCombo.disable();
            new GUI.Ajax.Request(
                route(url, {space: combo.getValue(), name: combo.options.items[combo.selectedIndex].spacename}), {
                    method: 'GET',
                    onSuccess: function(xhr, response) {
                        var options = response.responseJson;

                        if (options) {
                            options.unshift(channelsCombo.options.items[0]);

                            for (var i = 0; i < options.length; i++) {
                                if (options[i].value) {
                                    options[i].icon = 'fa fa_icon_' + options[i].value;
                                } else {
                                    options[i].icon = 'fa fa-globe';
                                }
                            }

                            channelsCombo.setOptions(options);
                            channelsCombo.enable();
                        }

                        if (options && options.length == 2) {
                            // all + 1 channel
                            GUI.hide('adv-search-channel-holder');
                        } else {
                            GUI.show('adv-search-channel-holder');
                        }

                        if (channelsCombo.getItemByValue(this._previousValues.filter.channel)
                            && options && options.length != 2
                        ) {
                            channelsCombo.setValue(this._previousValues.filter.channel);
                        } else if (options && options.length == 2) {
                            channelsCombo.setValue(options[1].value);
                        }
                    }.bindLegacy(this)
                }
            );
        },

        _onChannelSelect: function(combo) {
            var categoriesCombo = this._form.getField('category');
            categoriesCombo.setOptions([categoriesCombo.options.items[0]]);
            categoriesCombo.select(0);

            if (combo.getValue() != ''
                && this._form.getField('space').getValue() != ''
            ) {
                categoriesCombo.disable();
                categoriesCombo.disable();
                new GUI.Ajax.Request(
                    route('getCategoriesList', {
                        space   : this._form.getField('space').getValue(),
                        name    : this._form.getField('space').options.items[this._form.getField('space').selectedIndex].spacename,
                        channel : combo.getValue()
                    }), {
                        method: 'GET',
                        onSuccess: function(xhr, response) {
                            var categories = response.responseJson;
                            var options = [];
                            options.unshift(categoriesCombo.options.items[0])
                            for (var i = 0; i < categories.length; i ++) {
                                options.push({
                                    // Jquery html entity encode, unicorn power!
                                    text: jQuery('<div/>').text(categories[i].name).html(),
                                    value: categories[i].name
                                });
                            }

                            if (options.length == 1) {
                                GUI.hide('adv-search-categories-holder');
                            } else {
                                GUI.show('adv-search-categories-holder');
                            }

                            categoriesCombo.setOptions(options);
                            // @todo: tree?
                            categoriesCombo.enable();

                            if (categoriesCombo.getItemByValue(this._previousValues.filter.category)) {
                                categoriesCombo.setValue(this._previousValues.filter.category);
                            }
                        }.bindLegacy(this)
                    }
                );
            } else {
                GUI.hide('adv-search-categories-holder');
            }
        },

        _reassignMainFormSubmit: function() {
            this._mainForm.on('submit', function(ev) {
                this._form.submit();

                var e = GUI.Event.extend(ev);
                e.stop();
            }, this);
        },

        _initUserAutocompleter:function() {
            var autocompleter = new GUI.Forms.Autocompleter({
                width       : '100%',
                wrapperClass: "b-text-field text-field text-field_header_small text-field_size_wide",
                autocomplete: true,
                name        : "user",
                searchConfig: {
                    paramName: 'adv-search-user',
                    url: route('userAutocompleter', {}),
                    method: 'POST'
                },
                value: this._previousValues.filter.user
            });
            autocompleter.render('adv-search-user-autocompleter');
            this._form.addField(autocompleter);
        },

        reset: function(){
            this._form.reset();
            GUI.Dom.findDescedents(
                GUI.$('adv-search-checkboxes'),
                'input'
            ).each(function(el) {
                if (!el.disabled) {
                    el.checked = true;
                }
            });
        },

        initialize : function(config) {
            if (!config.filterForm) {
                throw new Error('Filter form not defined!');
            }

            if (!config.mainForm) {
                throw new Error('Main form not defined!');
            }

            this._filterForm = config.filterForm;
            this._mainForm   = config.mainForm;

            this._setupFiltersForm();

            this._applyInputsSync();
            this._reassignMainFormSubmit();
//            this._initUserAutocompleter();

            this.searchField = GUI.$('adv-search-input');
            this.searchFieldCancel = GUI.$('text-field-icon-clean');
            
            this.searchField.on('change', this.onSearchFieldChange, this);
            this.searchField.on('keyup', this.onSearchFieldChange, this);
            this.searchField.on('paste', this.onSearchFieldChange, this);
            this.searchField.on('input', this.onSearchFieldChange, this);
            this.searchField.on('mouseup', this.onSearchFieldChange, this);
        },
        
        onSearchFieldChange: function () {
            if(this.searchField.value) {
                this.searchFieldCancel.style.display = 'block';
            } else {
                this.searchFieldCancel.style.display = 'none';
            }

        }
    }

    Object.extend(window.SearchFilterClass.prototype, prototype);
}());

GUI.onDOMReady(function() {
    if (GUI.$('adv-search-filters') && GUI.$('adv-search-input-form')) {
        window.searchFilterForm = new window.SearchFilterClass({
            filterForm  : GUI.$('adv-search-filters'),
            mainForm    :   GUI.$('adv-search-input-form')
        });
    }
});