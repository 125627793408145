(function () {
    var LoadAvatarDialogClass = Class.create();

    var superproto = new GUI.Utils.Observable();

    var prototype = {
        _showed        : false,
        _dialog        : false,
        _disableReload : false,
        _popupWindow   : false,
        _popupHandler  : false,
        _formMask      : false,
        _loadAvatarForm     : {
            holder         : null,
            form           : null
        },

        _setupFormElementListeners : function () {
            this._loadAvatarForm.holder       = GUI.$('load-avatar-dialog-form');
            this._loadAvatarForm.form         = GUI.$('load-avatar-form');

            this._loadAvatarForm.form.on('submit', this.save, this);
        },

        initialize: function () {
            this.addEvents({
                show : true,
                hide : true,
                load : true,
                error: true
            });

            GUI.onDOMReady(function () {
                this._dialog = new Application.CommonDialogClass('load-avatar');

                if (!GUI.$('load-avatar-form')) {
                    return;
                }
                this._setupFormElementListeners();

                this._formMask = new GUI.Popup.Mask({className : 'mask', animate : false, stylePosition: 'absolute'});
                this._formMask.setElement(this._dialog.getContentHolder());
            }.bindLegacy(this));
        },

        show: function () {
            if (!this._showed) {
                this._dialog.close();

                this._dialog.onShow = function () {
                    this.onShow();
                }.bindLegacy(this);

                this._dialog.onClose = function () {
                    this.el.value = '';
                    this.jcrop.destroy();
                    GUI.hide(this._loadAvatarForm.holder);
                    this._showed = false;
                    this.fireEvent('hide');
                }.bindLegacy(this);

                this._dialog.show();
            }
        },

        onShow: function () {
            GUI.show(this._loadAvatarForm.holder);
            this._showed = true;
            this.fireEvent('show');

            this.jcrop = GUI.Jcrop(
                GUI.$('resizeAvatar'),
                {
                    aspectRatio : 1,
                    shade       : 0,
                    boxHeight   : 500,
                    onChange    : updatePreview,
                    onSelect    : updatePreview,
                    bgColor     : 'white'
                }
            );
            this.jcrop.setSelect([ 0, 0, 200, 200 ]);

            function updatePreview(c) {
                var rx, ry, bounds, boundx, boundy;
                if (parseInt(c.w, 10) > 0) {
                    rx = 48 / c.w;
                    ry = 48 / c.h;

                    bounds = this.getBounds();
                    boundx = bounds[0];
                    boundy = bounds[1];

                    Object.extend(GUI.$('preAvatar').style, {
                        width       : Math.round(rx * boundx) + 'px',
                        height      : Math.round(ry * boundy) + 'px',
                        marginLeft  : '-' + Math.round(rx * c.x) + 'px',
                        marginTop   : '-' + Math.round(ry * c.y) + 'px'
                    });
                }
            }
        },

        onLoadAvatar: function (e, el) {
            var htmlForm, formObj,
                inp  = e.target || e.srcElement;

            if (inp.files) {
                this.file = inp.files[0];
            } else {
                this.file = {name: GUI.getNameFromFileSrc(inp.value), size: inp.size};
            }

            this.el = el;
            this.parentEl = this.el.parentNode;

            if (window.FileReader) {
                this.reader = new FileReader();
                this.reader.onload = this.readerOnLoad.bindLegacy(this);
                this.reader.readAsDataURL(this.file);
            } else {

                htmlForm = document.createElement('FORM');
                htmlForm.id = 'upload-avatar';
                htmlForm.name = 'upload-avatar';
                htmlForm.style.display = 'none';
                htmlForm.action = uploadAvatarUrl;

                document.body.appendChild(htmlForm);

                htmlForm.appendChild(inp);
                this.inp = inp;

                formObj = new GUI.Forms.Form();
                formObj.assign(htmlForm);
                formObj.fileUpload = true;
                formObj.action = uploadAvatarUrl;

                formObj.on('afterSubmit', function (form, response) {
                    this.setImages(response.responseJson.filename, response.responseJson.url);
                }.bindLegacy(this));

                formObj.submit();
            }
        },

        readerOnLoad: function () {
            var body, result,
                type = this.file.type,
                name = GUI.utf8_encode(this.file.name),
                boundary = new Date().getTime();

            if (this.file.size > 921600) {
                Application.errorInfo(
                            sprintf(i18n('File size has to be less than %s.'),
                                    '1 MB'
                            )
                        );
                return;
            }

            this.request = new GUI.Ajax.Request({
                url             : uploadAvatarUrl,
                requestHeader   : true,
                autoSendRequest : false,
                convertData     : false,
                onSuccess       : this.onSuccessLoadAvatar.bindLegacy(this),
                onFailure       : this.onFailRequest
            });

            this.request.xhr.setRequestHeader("Content-Type", "multipart/form-data, boundary=" + boundary);
            this.request.xhr.setRequestHeader("Cache-Control", "no-cache");

            result = this.reader.result;
            result = result.replace('data:' + type + ';base64,', '');
            result = GUI.base64_decode(result);

body = '--' + boundary + '\n\
Content-Disposition: form-data; name="act"\n\
\n\
upload\n\
--' + boundary + '\n\
Content-Disposition: form-data; name="avatar"; filename="' + name + '"\n\
Content-Type: ' + type + '\n\
\n\
' + result + '\n\
--' + boundary + '--\n\
';

            if (this.request.xhr.sendAsBinary) {
                this.request.xhr.sendAsBinary(body);
            }
        },

        onSuccessLoadAvatar: function (xhr) {
            var res = xhr.responseText.evalJSON();

            if (res.error) {
                Application.errorInfo(res.error);

            } else {
                this.res = res;
                this.setImages(res.filename, res.url);
            }
        },

        setImages: function (filename, url) {
            var img, img2;
            this.filename = filename;

            img = GUI.$('resizeAvatar');
            Object.extend(img.style, {
                width   : '',
                height  : ''
            });
            img2 = GUI.$('preAvatar');

            img.onload = this.show.bindLegacy(this);
            img.setAttribute('src', url + '?' + new Date().getTime());
            img2.setAttribute('src', url + '?' + new Date().getTime());
        },

        onFailRequest: function (request, response) {
            if (request.responseText) {
                Application.errorInfo(request.responseText);
            }
        },

        save  : function (event) {
            if (GUI.isIE8) {
                event = GUI.Event.extend(event);
            }
            event.preventDefault();

            this._formMask.show();

            var coor = this.jcrop.tellSelect();

            new GUI.Ajax.Request({
                url      : resizeAvatarUrl,
                data     : {x1: coor.x, y1: coor.y, x2: coor.x2, y2: coor.y2, filename: this.filename},
                onSuccess : this._onResponse.bindLegacy(this)
            });
        },

        _onResponse : function (answer) {
            this._formMask.hide();
            answer = answer.responseText.evalJSON();

            if (answer.done) {
                this.fireEvent('load', this.res.aws);
                this.close();
            } else {
                this.fireEvent('error');
                 Application.errorInfo(answer.error);
            }
        },

        close : function () {
            this.el.value = '';
            if (this._showed) {
                this._dialog.close();
            }
        }
    };

    Object.extend(LoadAvatarDialogClass.prototype, superproto);
    Object.extend(LoadAvatarDialogClass.prototype, prototype);

    Application.LoadAvatarForm = new LoadAvatarDialogClass();

}());