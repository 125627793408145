(function () {
    var superproto = GUI.Application.prototype;

    GUI.Service = Class.create();
    Object.extend(GUI.Service.prototype, superproto);

    /**
     * Type of the module, default is 'Service'
     * @type String
     */
    GUI.Service.prototype.type = 'Service';

    /**
     * Initializes controller
     * @param {Object} config
     */
    GUI.Service.prototype.initialize = function (config) {
        Object.extend(this, config);
        superproto.create.call(this);
    };

    /**
     * Returns gateway by the name
     * @param {String} name of the gateway
     * @returns {Object} gateway
     */
    GUI.Service.prototype.getGateway = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Gateway', name);
    };

    /**
     * Returns factory by the name
     * @param {String} name of the factory
     * @returns {Object} factory
     */
    GUI.Service.prototype.getFactory = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Factory', name);
    };

}());



