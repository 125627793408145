(function () {
    var superproto = GUI.Utils.Observable.prototype;

    GUI.Application = Class.create();
    Object.extend(GUI.Application.prototype, superproto);

    /**
     * Collection of controllers
     * @type Object
     */
    GUI.Application.prototype.controllers = {};

    /**
     * Collection of models
     * @type Object
     */
    GUI.Application.prototype.models = {};

    /**
     * Collection of views
     * @type Object
     */
    GUI.Application.prototype.views = {};

    /**
     * Creates module, registers it in the GUI.ComponentMgr
     * @param {String} typ;e of the module
     * @param {String} name of the module
     * @param {Object} description of the module
     */
    GUI.Application.prototype.create = function () {
        //this[this.type.toLowerCase() + 's'][this.name] = window[this.name + this.type];
        //GUI.ComponentMgr.register(this.name + this.type, window[this.name + this.type]);
    };

    /**
     * Initializes module(s)
     * @param {String} type of the module
     * @param {String|Array} name of module(s)
     */
    GUI.Application.prototype._init = function (type, name) {
        superproto.initialize.call(this);

        var contr,
            module = type.toLowerCase() + 's';

        if (typeof (name) === 'string') {
            this[module][name].init();
        } else if (typeof (name) === 'object' && name.length) {
            name.each(function (contr) {
                if (this[module][contr]) {
                    this[module][contr].init();
                }
            }.bindLegacy(this));
        } else {
            for (contr in this[module]) {
                this[module][contr].init();
            }
        }
    };

    /**
     * Returns module by the name
     * @param {String} type of the module
     * @param {String} name of the module
     * @returns {Object} module
     */
    GUI.Application.prototype.getModule = function (type, name) {
        return window[name + type];
    };

    /**
     * Adds event, first param must be a name of event, and last param must be object of the event
     *
     */
    GUI.Application.prototype.addEvent = function (event) {
        var data = arguments,
            last = data.length - 1,
            e = data[last][0],
            target,
            attr;

        if (e !== event[0]) {
            e = GUI.Event.extend(e);
            target = e.getTarget();

            attr = target.getAttribute('stopEvent');

            if (!attr || attr !== 'false') {
                e.stop();
            }

            data[last] = e;
        }

        this.fireEvent.apply(this, data);
    };

}());



