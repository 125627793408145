(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Email implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Email = Class.create();
    Object.extend(GUI.Forms.Validation.Email.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_EMAIL'
     * @type String
     */
    GUI.Forms.Validation.Email.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_EMAIL);

    /**
     * Element type, 'GUI.Forms.Validation.Email'
     * @type String
     */
    GUI.Forms.Validation.Email.prototype.elementType = 'GUI.Forms.Validation.Email';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Email.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        this.pattern = /@/i;
    };

}());

