(function () {
    var superproto = GUI.Forms.Validation.Validator.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Regexp implementation
    *
    * @author Sergey Ostapenko
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Regexp = Class.create();
    Object.extend(GUI.Forms.Validation.Regexp.prototype, superproto);


    /**
     * Text of error message, Default is i18n.GUI_VALIDATION_CUSTOM
     * @type String
     */
    GUI.Forms.Validation.Regexp.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_CUSTOM);

    /**
     * Type of the element, 'GUI.Forms.Validation.Regexp'
     * @type String
     */
    GUI.Forms.Validation.Regexp.prototype.elementType = 'GUI.Forms.Validation.Regexp';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Regexp.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        if (GUI.isSet(this.params)) {
            var params = [".*" , null];

            try {
                params = Object.extend(params, this.params.split("|"));
                this.pattern = new RegExp(GUI.base64_decode(params[0]), params[1] || undefined);
            } catch (e) {
                console.log(e);
                console.trace("Pattern error");
            }
        }
    };

    GUI.Forms.Validation.Regexp.prototype.validateFunc = function (value) {
        if (!value) {
            return true;
        }
        return this.pattern.test(value);
    };
}());