(function () {
    if (!GUI.Forms.Plugins) {
        GUI.Forms.Plugins = {};
    }
    /**
     * JavaScript Graphical User Interface
     * EditorImageManager plugin implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Forms.Plugins
     */
    GUI.Forms.Plugins.LinkToContent = Class.create();

    GUI.Forms.Plugins.LinkToContent.prototype.formName = 'contentForm';

    GUI.Forms.Plugins.LinkToContent.prototype.id = GUI.getUniqId('linktocontent-');

    /**
     * Content of dialog
     * @type String
     */
    GUI.Forms.Plugins.LinkToContent.prototype.template =
        '<form id="{this.id}-contentForm" name="contentForm" ><table class="b-forms__table">' +
            '<tbody>' +
            '<tr class="b-forms__row b-forms__row_no-border-top">' +
                '<td class="b-forms__cell label">' +
                    '<label for="{this.id}-linkto" class="b-forms__label">Link to</label>' +
                '</td>' +
                '<td class="b-forms__cell">' +
                    '<div class="cell-icon">' +
                        '<div id="searchResults" class="item-search-result">' +
                            '<i id="{this.id}-iconClass" class="cell-icon__icon"></i>' +
                            '<div class="cell-icon__label">' +
                                '<span id="{this.id}-itemName" class="b-title b-title_small"></span>&nbsp;&nbsp;&nbsp;' +
                                '<span id="{this.id}-itemId" class="desc"></span>' +
                                '<p id="{this.id}-pathItem" class="desc"></p>' +
                            '</div>' +
                        '</div>' +
                        '<div id="{this.id}-autoCompliterField" class="autocompliter"></div>' +
                        '&nbsp;<i id="{this.id}-spaces_combo"></i>' +
                        '&nbsp;<i id="{this.id}-spaces_type"></i><br>' +
                        '<a href="#" class="b-button b-button_regular" id="{this.id}-recently-viewed">'+
                            '<span class="b-button__label">recently viewed</span>'+
                        '</a>' +
                        '<div id="{this.id}-jsonview-recently-viewed"></div>' +
                    '</div>' +
                '</td>' +
            '</tr>' +
            '<tr class="b-forms__row">' +
                '<td class="b-forms__cell label">' +
                    '<label for="{this.id}-linktext" class="b-forms__label">Link Text</label>' +
                '</td>' +
                '<td class="b-forms__cell">' +
                    '<input id="{this.id}-linktext" name="linkText" class="item-title" type="text" style="width: 500px;" />' + // linkText
                    /*'<ul class="b-forms__checkbox-list">' +
                        '<li class="item">' +
                            '<span class="b-checkbox">' +
                                '<input id="{this.id}-sticktitle" class="b-checkbox__input" type="checkbox" />' +
                                '<i class="b-checkbox__pseudo"></i>' +
                                '<label for="{this.id}-sticktitle" class="b-checkbox__label">' +
                                    'Stick to item title' +
                                    '<p class="desc">If item title changes, lint text will be updated as well.</p>' +
                                '</label>' +
                            '</span>' +
                        '</li>' +
                    '</ul>' +*/
                '</td>' +
            '</tr>' +
            '<tr class="b-forms__row">' +
                '<td class="b-forms__cell label">' +
                    '<label for="" class="b-forms__label">Advanced</label>' +
                '</td>' +
                '<td class="b-forms__cell">' +
                    '<ul class="b-forms__checkbox-list">' +
                        '<li class="item">' +
                            '<span class="b-checkbox">' +
                                '<input id="{this.id}-newwindow" class="b-checkbox__input" type="checkbox" name="target" checked="checked" />' + // target
                                '<i class="b-checkbox__pseudo"></i>' +
                                '<label for="{this.id}-newwindow" class="b-checkbox__label">' +
                                    'New window<p class="desc">Set to open this link in new window/tab.</p>' +
                                '</label>' +
                            '</span>' +
                        '</li>' +
                        '<li class="item">' +
                            '<span class="b-checkbox">'+
                                '<input id="{this.id}-nofollow" class="b-checkbox__input" type="checkbox" name="nofollow" checked="checked" />' + // nofollow
                                '<i class="b-checkbox__pseudo"></i>' +
                                '<label for="{this.id}-nofollow" class="b-checkbox__label">' +
                                    'Nofollow<p class="desc">Set no follow attribute for this link.</p>' +
                                '</label>' +
                            '</span>' +
                        '</li>' +
                    '</ul>' +
                '</td>' +
            '</tr>' +
        '</tbody></table></form>';

    /**
     * Called by Editor while initializing.
     * @param {Object} editor Editor
     * @param {Object} dlg Dialog
     * @param {String} text
     */
    GUI.Forms.Plugins.LinkToContent.prototype.init = function(cfg) {
        var formContent, template, comboSpaces, autocompleter, comboTypes,
            channelType, channels = [{
                value   : '',
                text    : i18n('All Types')
            }];

        this.editor = cfg.editor;
        this.dlg = cfg.dlg;

        this.cfg = cfg;

        GUI.$(cfg.holder).addClass('insert-link-layout');

        template = new GUI.STemplate(this.template, this);
        GUI.$(cfg.holder).innerHTML = template.fetch();

        // form
        formContent = new GUI.Forms.Form({
            assignTo    : this.id + '-contentForm',
            listeners   : {
                beforeSubmit: function () {return false;}
            }
        });
        this.dlg.formContent = formContent;

        // combo with spaces
        comboSpaces = new GUI.Forms.Combo({
            holder  : this.id + '-spaces_combo',
            autoWidth: true,
            options : []
        });
        comboSpaces.render();
        comboSpaces.on('change', this.onChangeComboSpace.bindLegacy(this));
        this.comboSpaces = comboSpaces;
        this.loadSpaces();

        if (window.Channels) {
            for (channelType in window.Channels) {
                channels.push({'text': window.Channels[channelType].title, value: channelType});
            }
        }
        // types
        comboTypes = new GUI.Forms.Combo({
            autoWidth: true,
            holder  : this.id + '-spaces_type',
            options : channels
        });
        comboTypes.render();
        comboTypes.on('change', this.onChangeComboType.bindLegacy(this));
        this.comboTypes = comboTypes;

        // autocompliter
        autocompleter = new GUI.Forms.Autocompleter({
            width       : '180px',
            listWidth   : 483,
            maxContentHeight: GUI.constrain(400, 200, Math.round(GUI.getDocumentHeight()/3)),
            autocomplete    : true,
            list_item_class : 'b-auto-completer__item',
            list_class      : 'b-auto-completer__list',
            wrappedListClass: 'b-auto-completer__popup',
            rowTpl          : Templates.getAutocompleterItemTpl({
                                    'iconClass' : '{this.data.iconClass}',
                                    'text'      : '{this.text}',
                                    'path'      : Templates.getPathTpl({space: '{this.data.spaceName}', channel: '{this.data.channelName}', category: '{this.data.categoryName}'})
                                }),
            searchConfig: {paramName: 'searchText',
                url     : this.editor.searchItemUrl,
                method  : 'POST',
                baseParams: {
                    spaceId: 0
                }
            }
        });
        autocompleter.render(this.id + '-autoCompliterField');
        autocompleter.on('select', this.onSelectAutocompliter.bindLegacy(this));

        this.autocompleter = autocompleter;

        this.jsonviewId = this.id + '-jsonview-recently-viewed';
        this.elShowLayer = GUI.$(this.id + '-recently-viewed');

        this.dlg.on('show', this.onShow.bindLegacy(this));
        this.dlg.on('beforeclose', this.destroy.bindLegacy(this));

        this.dlg.insert.on('click', this.insert.bindLegacy(this));
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.insert = function () {
        var data, editNode,
            cfg = this.cfg;

        editNode = cfg.editNode;

        if (this.dlg.tabbar.activeTab.name == 'content') {
            data = this.dlg.formContent.getValues();

            data.href = route('showItem', {
                item    : this.selectedItem.id,
                title   : this.selectedItem.data.urlTitle,
                page    : 1
            });

            if (!cfg.editMode) {
                editNode = this.editor.iDoc.createElement('a');
            }
            editNode.innerHTML = (cfg.img) ? cfg.htmlImg: data.linkText;

            if (data.href.match(cfg.emailRegexp) != null) {
                editNode.href = 'mailto:' + data.href;
            } else {
                editNode.href = data.href;
            }

            if (data.target === true) {
                editNode.target = '_blank';
            } else {
                editNode.removeAttribute('target');
            }

            if (data.nofollow === true) {
                editNode.rel = 'nofollow';
            } else {
                editNode.removeAttribute('rel');
            }

            if (!cfg.editMode) {
                if (this.editor.iWin.getSelection) { // ff
                    if (!cfg.collapsed) {
                        cfg.range.deleteContents();
                    }
                    cfg.range.insertNode(editNode); // link does not gets css styling till 'display' style is toggled ?
                    this.editor.selectNode(editNode);

                } else if (this.editor.iDoc.selection) { // ie
                    this.editor.iWin.focus();
                    if (cfg.controlSelection) {
                        cfg.controlNode.parentNode.replaceChild(editNode, cfg.controlNode);
                    } else {
                        cfg.range.pasteHTML(editNode.outerHTML);
                        cfg.range.select();
                    }
                }
            }
            this.editor.updateTextarea();
            this.dlg.close();
        }
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onShow = function () {
        var cfg = this.cfg;

        GUI.hide(GUI.$('searchResults'));

        if (cfg.editMode) {
            // apply
            this.dlg.formContent.setFieldsValues({
                linkText: cfg.selHtml,
                href    : decodeURI(cfg.editNode.href.replace('mailto:', '')),
                target  : cfg.editNode.target == '_blank' ? true : false,
                nofollow: cfg.editNode.rel == 'nofollow' ? true : false
            });
            // TODO: Detect anchors
        } else {
            this.dlg.formContent.setFieldsValues({
                linkText: cfg.selHtml,
                href    : ''//'http://' + cfg.selText
            });
        }
    };

    /**
     *
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.destroy = function () {
        this.recentlyLayer.hideRecently();
        this.elShowLayer.un('click', this.showRecently.bindLegacy(this));
        if (this.recentlyRegion) {
            this.recentlyRegion.hideByOffset();
        }
        this.comboSpaces.un('change', this.onChangeComboSpace.bindLegacy(this));
        this.comboTypes.un('change', this.onChangeComboType.bindLegacy(this));
        this.dlg.un('beforeclose', this.destroy.bindLegacy(this));
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.setLinkTo = function (value, href) {
        var args = href.split('/'),
            id = args[5].replace('i', '');

        this.autocompleter.minSearchCharacters = 1000;
        this.autocompleter.dom.value = value;

        this._loadItemRequest = new Ajax.Request(route('admin', {controller: 'item', action: 'get'}), {
            parameters  : GUI.toQueryString({'id': id}),
            onSuccess   : function (xhr, request) {
                var response = request.responseJson.data;
                this.onSelectAutocompliter({}, {
                    id      : id,
                    text    : value,
                    data    : {
                        iconClass   : response.channelType,
                        channelName : response.channel.name,
                        categoryName: (response.category) ? response.category.name : '',
                        spaceName   : response.channel.space.name
                    }
                });
                this.autocompleter.minSearchCharacters = 3;
            }.bindLegacy(this),
            onFailure   : this.onLoadFailure
        });
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onSelectAutocompliter = function (acp, item, value) {
        this.selectedItem = item;

        GUI.$(this.id + '-iconClass').className = 'cell-icon__icon ' + item.data.iconClass;
        GUI.$(this.id + '-itemName').innerHTML = item.text;
        GUI.$(this.id + '-itemId').innerHTML = '(ID:&nbsp;' + item.id + ')';
        GUI.$(this.id + '-pathItem').innerHTML = Templates.getPathTpl({space: item.data.spaceName, channel: item.data.channelName, category: item.data.categoryName});
        GUI.show(GUI.$('searchResults'));
        GUI.$(this.id + '-linktext').value = item.text;
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onChangeComboSpace = function (combo, value) {
        this.autocompleter.searchConfig.baseParams.spaceId = value;
        this.adjustWidthAutocompliter();
        this.autocompleter.doSearch();
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onChangeComboType = function (combo, value) {
        this.autocompleter.searchConfig.baseParams.channelType = value;
        this.adjustWidthAutocompliter();
        this.autocompleter.doSearch();
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.adjustWidthAutocompliter = function () {
        // 490 - width of the parent td
        this.autocompleter.setWidth(490 - GUI.getClientWidth(this.comboSpaces.dom) - GUI.getClientWidth(this.comboTypes.dom));
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.loadSpaces = function () {
        this._loadRequest = new Ajax.Request(this.editor.config.loadSpaceUrl, {
            parameters  : GUI.toQueryString({'account': 1}),
            onSuccess   : this.onLoadSuccess.bindLegacy(this),
            onFailure   : this.onLoadFailure
        });
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onLoadSuccess = function (response, request) {
        var response = response.responseText,
            options = response.evalJSON();
        this.comboSpaces.setOptions(options);
        this.adjustWidthAutocompliter();
    };

    /**
     *
     */
    GUI.Forms.Plugins.LinkToContent.prototype.onLoadFailure = function (response, request) {
        //
    };
}());
