
if (typeof(wrap) == 'undefined') wrap = {};

wrap.i18n = function() {}

wrap.i18n.prototype.i18n = function(str1, str2, str3, str4) {
    if (! this.gt)
        throw new Error("i18n not initialized");
    // var n, context, singular, plural;
    if (typeof(str4) != 'undefined') {
        // number, context, singular, plural
        return this.gt.npgettext(str2, str3, str4, str1);
    } else if (typeof(str3) != 'undefined') {
        // singular, plural, number
        return this.gt.ngettext(str1, str2, str3);
    } else if (typeof(str2) != 'undefined') {
        // context, msgid
        return this.gt.pgettext(str1, str2);
    } else if (typeof(str1) != 'undefined') {
        // msgid
        return this.gt.gettext(str1);
    } else {
        // nothing passed in; return blank string.
        // XXX: we could error here, but that may cause more harm than good.
        return '';
    }
}

wrap.i18n.instances = [];

wrap.i18n.init = function (root, domain, locale_data) {
    var obj, index,
        gt_args = {};

    if (typeof root == 'undefined') {
        root = self;
        if (!root) throw new Error("Platform unknown");
    }

    gt_args.domain = (!domain) ? 'messages' : domain;
    gt_args.locale_data = (!locale_data) ? undefined : locale_data;

    obj = new wrap.i18n();
    obj.gt = new Gettext(gt_args);
    if (typeof(obj.gt) == 'undefined')
        throw new Error("Unable to initialize Gettext object");

    window.pgettext = obj.gt.pgettext.bindLegacy(obj.gt);
    window.npgettext = obj.gt.npgettext.bindLegacy(obj.gt);

    wrap.i18n.instances.push(obj);
    index = wrap.i18n.instances.length -1;

    root['i18n'] = new Function("str1", "str2", "str3", "str4", "return wrap.i18n.instances[" + index + "].i18n(str1, str2, str3, str4);");
}