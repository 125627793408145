/**
* JavaScript Graphical User Interface
* ToolBar implementation
*
* @author Eugene Lyulka
* @version 2.0
* @namespace GUI
* @extends GUI.Bar
*/
GUI.ToolBar = Class.create();
Object.extend(GUI.ToolBar.prototype, GUI.Bar.prototype);

/**
 * Positions of the toolbar, can be 'horizontal', 'vertical',, default is 'horizontal'
 * @type Array
 */
GUI.ToolBar.prototype.positions = ['horizontal', 'vertical'];

/**
 * Aligns of the toolbar, can be for horizontal position 'left', 'right', 'center'.
 * Default is 'left'.
 * @type Array
 */
GUI.ToolBar.prototype.aligns = {
    horizontal : ['left', 'right', 'center'],
    vertical   : ['top', 'middle', 'bottom']
};

/**
 * Css class of the toolbar, default is 'b-buttons-list'
 * @type String
 */
GUI.ToolBar.prototype.className = 'b-buttons-list';

GUI.ToolBar.prototype.extraClass = '';

/**
 * Css class of the toolbar with background, default is 'b-toolbar'
 * @type String
 */
GUI.ToolBar.prototype.backgroundClass = 'b-toolbar';

/**
 * Css class of the toolbar with center alignment,
 * default is 'b-buttons-list_center'
 * @type String
 */
GUI.ToolBar.prototype.centerClass = 'center';

/**
 * Css class of the toolbar with right alignment,
 * default is 'b-buttons-list_right'
 * @type String
 */
GUI.ToolBar.prototype.rightClass = 'right';

/**
 * Css class for the vertical toolbar, default is 'b-buttons-list_vertical'
 * @type String
 */
GUI.ToolBar.prototype.verticalClass = 'b-buttons-list_vertical';

/**
 * Css class for vertical buttons list, default is 'b-buttons-list__data'
 * @type String
 */
GUI.ToolBar.prototype.buttonsListVerticalClass = 'b-buttons-list__data';

/**
 * Css class for button in the vertical list, default is 'b-button_tab_minimal_vertical'
 * @type String
 */
GUI.ToolBar.prototype.buttonVerticalClass = 'b-button_tab_minimal_vertical';

/**
 * Css class for selected button in the vertical list, default is 'selected'
 * @type String
 */
GUI.ToolBar.prototype.pressedClassForVertical = 'selected';

/**
 * Css class for the toolbar with clear style, default is 'b-toolbar_clear'
 * @type String
 */
GUI.ToolBar.prototype.clearClass = 'b-toolbar_clear';

/**
 * Initializes object
 * @param {Object} config Configuration object
 */
GUI.ToolBar.prototype.initialize = function (config) {
    // Call parent initialize meethod
    GUI.Bar.prototype.initialize.apply(this, arguments);

    var elems, len, i, elem,
        cfg = this.config;

    // Extend parent config
    Object.extend(cfg, {
        id          : GUI.getUniqId('toolbar-'),
        className   : this.className,
        extraClass  : this.extraClass,
        position    : 'horizontal',
        background  : true,
        sidebar     : false,
        type        : 'default',
        renderAfterBeginHolder: true
    });

    Object.extend(this.config, config);

    if (!GUI.isSet(cfg.align)) {
        cfg.align = (cfg.position === 'horizontal') ? 'left' : 'top';
    }
    this.elementType = 'GUI.ToolBar';

    // Add elements from config if passed
    if ((typeof (elems = cfg.elements) === 'object') && ((len = cfg.elements.length) > 0)) {
        for (i = 0; i < len; i++) {
            elem = elems[i];
            this.add(elem.name, elem.obj);
        }
    }

    if (cfg.sidebar) {
        this.sidebar = cfg.sidebar;
    }
};

/**
 * Adds element to tabbar
 * @param {String} name
 * @param {Object} elem
 */
GUI.ToolBar.prototype.add = function (name, elem) {
    // Call parent method
    GUI.Bar.prototype.add.apply(this, arguments);

    // Render new element if bar is already rendered
    if (!this.rendered) {
        return;
    }
    var td, tr,
        tbody = this.dom.firstChild;

    if (this.config.position === 'horizontal') {
        // horizontal bar, append to tr new td
        td = document.createElement('td');
        tbody.firstChild.appendChild(td);
        elem.render(td);
    } else {
        // vertical bar, append tr/td
        tr = document.createElement('tr');
        td = tr.appendChild(document.createElement('td'));
        tbody.appendChild(tr);
        elem.render(td);
    }
};

/**
 * Removes element from tabbar
 * @param {String|Number} key String identifier or index number of element to remove
 */
GUI.ToolBar.prototype.remove = function (key) {
    // If already rendered then remove in dom
    if (this.rendered) {
        var td, tr,
            elem = this.getElement(key);
        if (elem === -1) {
            throw new Error('trying to remove non-existent tab');
        }

        elem = elem.getDom();

        if (this.config.position === 'horizontal') {
            // horizontal bar, remove td
            td = elem.parentNode;
            td.parentNode.removeChild(td);
        } else {
            // vertical bar, remove tr/td
            tr = elem.parentNode.parentNode;
            tr.parentNode.removeChild(tr);
        }
    }
    // Call parent method
    GUI.Bar.prototype.remove.apply(this, arguments);
};

/**
 * If not rendered call parent insert method
 */
GUI.ToolBar.prototype.insert = function () {
    if (this.rendered) {
        throw new Exception("GUI.Toolbar.insert() is unsupported currently when toolbar has been rendered");
    }
    // Call parent method
    GUI.Bar.prototype.insert.apply(this, arguments);
};

/**
 * Sets bar position.
 * @param {String} pos Tabbar position: one of values from GUI.TabBar.positions:
 *
 */
GUI.ToolBar.prototype.setPosition = function (pos) {
    if (this.positions.include(pos)) {
        this.config.position = pos;
        return;
    }
    throw new Error('setPosition(): unknow position type specified: ' + pos);
};

/**
 * Sets bar alignment.
 * @param {String} align Tabbar alignment: one of values from GUI.TabBar.aligns:
 *
 */
GUI.ToolBar.prototype.setAlign = function (align) {
    var cfg = this.config;
    if (!cfg.position) {
        throw new Error('setAlign(): align can be set only when position is set');
    }

    if (this.dom && (align === 'none')) {
        this.dom.align = '';
        return;
    }

    if (this.aligns[cfg.position].include(align)) {
        cfg.align = align;
        if (this.dom) {
            if (cfg.position === 'horizontal') {
                if (align === 'center') {
                    this.dom.addClass(this.centerClass);
                } else if (align === 'right') {
                    this.dom.addClass(this.rightClass);
                }
            }
        }
        return;
    }
    throw new Error('setAlign(): incorrect align - ' + align);
};

/**
 * Renders DOM tree and sets this.dom to root of this tree
 * @param {HTMLElement} containerNode Node of the container for the toolbar
 * @returns {Object} DOMNode object
 */
GUI.ToolBar.prototype._render = function (containerNode) {
    var div_vertical, div_sidebar,
        cfg = this.config,
        buttonVerticalClass = this.buttonVerticalClass,
        pressedClassForVertical = this.pressedClassForVertical,
        div = document.createElement('DIV');

    this.dom = GUI.Dom.extend(div);
    div.className = cfg.className;

    if (cfg.extraClass) {
        div.addClass(cfg.extraClass);
    }

    // add clear style
    if (cfg.clearToolbar) {
        if (cfg.background) {
            div.addClass(this.backgroundClass);
        }

        div.addClass(this.clearClass);
    } else {
        // add background style
        if (cfg.background) {
            div.addClass(this.backgroundClass);
        }

        // add type
        if (cfg.type) {
            switch (cfg.type) {
                case 'grid': div.addClass('b-toolbar_type_grid');
                    break;
                case 'hint': div.addClass('b-toolbar_type_hint');
                    break;
                case 'dialog': div.addClass('b-toolbar_type_dialog');
                    break;
                case 'wysiwyg': div.addClass('b-toolbar_type_wysiwyg');
                    break;
                default: '';
            }
        }

        if (cfg.corner) {
            if (cfg.corner.search('right') != -1) {
                div.addClass('b-arrow b-arrow_top_right');
            }
            if (cfg.corner.search('bottom') != -1) {
                div.addClass('b-arrow b-arrow_bottom_right');
            }
        }
    }
    if (cfg.renderAfterBeginHolder) {
        containerNode.insertBefore(div, containerNode.firstChild);
    } else {
        containerNode.appendChild(div);
    }

    if (this.sidebar) {
        div_sidebar = document.createElement('DIV');
        div_sidebar.className = 'b-toolbar__sidebar';
        div.appendChild(div_sidebar);

        this.sidebar.render(div_sidebar);
    }

    if (cfg.position === 'horizontal') {
        // horizontal bar
        this.elements.each(function (elem) {
            if (this.disabled) {
                elem.disable();
            }
            elem.render(div);
        }, this);
    } else {
        // vertical bar
        div.addClass(this.verticalClass);

        div_vertical = document.createElement('DIV');
        div_vertical.className = this.buttonsListVerticalClass;
        div.appendChild(div_vertical);

        this.elements.each(function (elem, i) {
            elem.extraClass = buttonVerticalClass;
            elem.pressedClass = pressedClassForVertical;
            elem.canToggle  = true;
            elem.id = GUI.getUniqId('button-' + i + '-');
            if (this.disabled) {
                elem.disable();
            }
            elem.render(div_vertical);
        });
        div.on('click', this.toggledElem, this);
    }
    this.setAlign(cfg.align);

    return this.dom;
};
/**
 * Toggled element
 * @type Object
 */
GUI.ToolBar.prototype.toggled = false;

/**
 *
 */
GUI.ToolBar.prototype.toggledElem = function (e) {
    e = GUI.Event.extend(e);
    var target, index, elem;

    target = e.target.findParent('button');
    if (target) {
        index = target.id.split('-')[1];
        elem = this.elements.get(index);

        if (!elem.disabled) {
            if (this.toggled && this.toggled !== elem) {
                this.toggled.toggle(false);
            }
            this.toggled = elem;
        }
    }
};

/**
 * Destroys objects
 * @param {Boolean} quick
 */
GUI.ToolBar.prototype.destroy = function (quick) {
    GUI.Bar.prototype.destroy.call(this, quick);
    if (this.dom) {
        if (!quick) {
            GUI.destroyNode(this.dom);
        }
        this.dom = null;
    }
};

/**
 * Disables elements
 * @param {Boolean}
 */
GUI.ToolBar.prototype.disable = function (flag) {
    GUI.Bar.prototype.disable.call(this, flag);
    if (this.sidebar) {
        this.sidebar.disable();
    }
    return true;
};

/**
 * Enables elements of the toolbar and sidebar
 */
GUI.ToolBar.prototype.enable = function () {
    GUI.Bar.prototype.enable.call(this);
    if (this.sidebar) {
        this.sidebar.enable();
    }
};

/**
 *
 */
GUI.ToolBar.prototype.setCorner = function (corner) {
    var action = (corner[0] == '+') ? 'addClass' : 'removeClass';

    if (corner.search('right') != -1) {
        this.dom[action]('b-arrow b-arrow_top_right');
    }
    if (corner.search('bottom') != -1) {
        this.dom[action]('b-arrow b-arrow_bottom_right');
    }
};

