(function () {
    var superproto = GUI.Forms.TextField.prototype;

    /**
    * JavaScript Graphical User Interface.
    * Forms.Autocompleter implementation.
    * Autocompleter looks like a standard text field. But as you type in text,
    * suggestions are offered based on the server-side query. When return is
    * pressed the value of the text field is applied by sending its value to
    * the server.
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.Autocompleter = Class.create();
    Object.extend(GUI.Forms.Autocompleter.prototype, superproto);

    /**
     * True to automatically apply value that user has been selected from the list. Defaults to false.
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.autoApply = true;

    /**
     * The number of seconds to delay search of suggestions. Default is 0 ms.
     * @type Number
     */
    GUI.Forms.Autocompleter.prototype.searchDelay = 0;

    /**
     * Object of the templates
     * @type Object
     */
    GUI.Forms.Autocompleter.prototype.templates = {
        pre     : '',
        post    : '',
        row     : '<li class="this.list_item_class{this.disabled}" id="this.rowPrefix{this.index}"{this.disabled}>this.rowTpl</li>',
        list    : '<div id="{this.loaderId}"></div><div id="{this.contentId}"><ul id="{this.jsonviewId}" class="{this.list_class}"></ul></div>',
        nodata  : i18n('No Data')
    };

    GUI.Forms.Autocompleter.prototype.rowTpl =
        '<span class="b-popup-menu__button"><span class="label">{this.text}</span></span>';

    /**
     * Css class of the element
     */
    GUI.Forms.Autocompleter.prototype.wrapperClass = 'b-text-field_search';

    /**
     *
     */
    GUI.Forms.Autocompleter.prototype.wrappedListClass = 'b-popup-menu b-popup-menu_main-menu b-popup-menu_style_combo';

    /**
     *
     */
    GUI.Forms.Autocompleter.prototype.list_class = 'b-popup-menu__list';

    /**
     *
     */
    GUI.Forms.Autocompleter.prototype.list_item_class = 'b-popup-menu__item';

    /**
     * If content of the popup suggestions list exceeds specified height
     * then scroll bar appears. Not set by default, there is no limit.
     * @type Number
     */
    GUI.Forms.Autocompleter.prototype.maxContentHeight = null;

    /**
     * List opened, default is false
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.listOpened = false;

    /**
     * Selected index, default is -1
     * @type Number
     */
    GUI.Forms.Autocompleter.prototype.selectedIndex = -1;

    /**
     * Minimum length of characters to search, default is 3
     * @type Number
     */
    GUI.Forms.Autocompleter.prototype.minSearchCharacters = 3;

    /**
     * Show loader, default is true
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.loader = true;

    /**
     * Show element Search for, default is false
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.searchFor = false;

    /**
     * Hide the list if it is empty, default is false
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.hideEmptyList = false;

    /**
     * List will be positioned automatically, default is true
     * @type Boolean
     */
    GUI.Forms.Autocompleter.prototype.autoApplyPosition = true;

    /**
     * Used for ololo
     * @type {string}
     */
    GUI.Forms.Autocompleter.prototype.usedFieldToApply = 'text';

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.Autocompleter.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_advanced {1}">' +
        '<i class="b-text-field__icon fa fa-search b-text-field__icon_visible"><i class="s-icon"></i></i>' +
        '<i class="b-text-field__icon clean"></i>' +
        '<i class="i-text-field"></i>' +
        '</i>';

    /**
     * Initialize objects, call parent initialize.
     * @param {Object} cfg Configuration object
     */
    GUI.Forms.Autocompleter.prototype.initialize = function (cfg) {
        if (cfg) {
            // back configuration options support
            if (cfg.apply) {
                cfg.applyConfig = cfg.apply;
                delete cfg.apply;
            }
            if (cfg.search) {
                cfg.searchConfig = cfg.search;
                delete cfg.search;
            }
        }
        superproto.initialize.call(this, cfg);
    };

    /**
     * Call parent initComponent, create search task, list, json view,
     * template. Add events 'beforeApply', 'applySuccess', 'applyFailure',
     * 'searchSuccess', 'select'
     */
    GUI.Forms.Autocompleter.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        var preTpl, wrappedRow, postTpl;

        this.jsonviewId = this.id + '-jsonview';
        this.loaderId = this.id + '-loader';

        this.searchTask = new GUI.Utils.DelayedTask(this.doSearch, this);

        if (this.autocomplete) {
            this.list = new GUI.Popup.Region({
                className: this.wrappedListClass
            });

            this.rowPrefix = this.id + '-row-';
            this.contentId = this.id + '-content';

            preTpl = this.templates.pre;
            postTpl = this.templates.post;

            wrappedRow = this.templates.row;
            wrappedRow = wrappedRow.replace('this.rowTpl', this.rowTpl);
            wrappedRow = wrappedRow.replace('this.list_item_class', this.list_item_class);
            wrappedRow = wrappedRow.replace('this.rowPrefix', this.rowPrefix);

            this.jsonview = new GUI.JsonView({
                holder      : this.jsonviewId,
                preTpl      : preTpl,
                rowTpl      : wrappedRow,
                postTpl     : postTpl,
                noDataTpl   : this.templates.nodata,
                visible     : false
            });
            this.jsonview.useWrapperEl = false;

            this.listTpl = new GUI.STemplate(this.templates.list, this);
        }

        this.data = null;

        // Add events
        this.addEvents({
            filterDataRow   : true,
            beforeApply     : true,
            applySuccess    : true,
            applyFailure    : true,
            searchSuccess   : true,
            select          : true,
            beforeShow      : true
        });
    };

    /**
     * Handler on render
     * @returns {HTMLElement} dom Dom of the elements
     */
    GUI.Forms.Autocompleter.prototype.onRender = function () {
        // Render text field
        this.fieldEl = superproto.onRender.call(this);
        var dom, wrapper, tmp, html;

        if (this.autocomplete) {
            dom = this.fieldEl;
        } else {
            // Render wrapper
            tmp = GUI.getFly();
            html = this.domTemplate.format(this.id, this.wrapperClass);

            tmp.innerHTML = html; // TODO: Check IE leaks
            dom = tmp.removeChild(tmp.firstChild);

            // Now wrap textfield
            wrapper = GUI.Dom.findDescedents(dom, 'i.i-text-field')[0];
            wrapper.appendChild(this.fieldEl);
        }
        return dom;
    };

    /**
     * Handler on after render
     * @param {HtmlElement} dom Dom of the elements
     */
    GUI.Forms.Autocompleter.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, this.fieldEl);
        if (!this.autocomplete) {
            this.searchEl = GUI.Dom.findDescedents(dom, 'i.b-text-field__icon .fa.fa-search')[0];
            this.searchEl.on('click', this.doSearch, this);
            this.cancelEl = GUI.Dom.findDescedents(dom, 'i.b-text-field__icon clean')[0];
            this.cancelEl.on('click', this.cancelSearch, this);
        }
    };

    /**
     * Fire event 'beforeApply', send request.
     */
    GUI.Forms.Autocompleter.prototype.apply = function () {
        var params = {},
            value = this.getValue(),
            row = false;

        if (this.data && this.selectedIndex > -1) {
            row = this.data.rows[this.selectedIndex];
        }

        if (this.fireEvent('beforeApply', this, value, row) === false) {
            return;
        }

        if (!this.applyConfig) {
            return;
        }

        params[this.applyConfig.paramName] = value;
        if (this.applyConfig.baseParams) {
            Object.extend(params, this.applyConfig.baseParams);
        }

        if (this.applyConfig.url) {
            new Ajax.Request(this.applyConfig.url, {
                parameters  : GUI.toQueryString(params),
                method      : this.applyConfig.method || 'post',
                scope       : this,
                onSuccess   : this.onApplySuccess,
                onFailure   : this.onApplyFailure
            });
        }
    };

    /**
     * Fires event 'applySuccess'.
     * @param {Object} response Response of request
     * @param {Object} request Request's object
     */
    GUI.Forms.Autocompleter.prototype.onApplySuccess = function (response, request) {
        try {
            var data = request.responseJson;
            this.fireEvent('applySuccess', this, data);
        } catch (e) {
            console.log('Exception in onApplySuccess: ', e);
        }
    };

    /**
     * Fire event 'applyFailure'
     */
    GUI.Forms.Autocompleter.prototype.onApplyFailure = function () {
        this.fireEvent('applyFailure', this);
    };

    /**
     * Hide search results, show loader, send request
     */
    GUI.Forms.Autocompleter.prototype.doSearch = function () {
        if (this.autocomplete) {
            if (this.getValue().trim().length < this.minSearchCharacters) {
                this.hideList();
                return false;
            }
            if (!this.hideEmptyList) {
                this.hideSearchResults();
                this.showLoader();
            }
        }

        var params = {};
        if (!this.searchConfig) {
            console.log('searchConfig is empty');
            return;
        }
        params[this.searchConfig.paramName] = this.getValue();

        if (this.searchConfig.baseParams) {
            Object.extend(params, this.searchConfig.baseParams);
        }

        this.abortRequest();

        this._request = new Ajax.Request(this.searchConfig.url, {
            parameters  : GUI.toQueryString(params),
            method      : this.searchConfig.method,
            scope       : this,
            onSuccess   : this.onSearchSuccess,
            onFailure   : this.onSearchFailure
        });
    };

    /**
     * Abort the request
     */
    GUI.Forms.Autocompleter.prototype.abortRequest = function () {
        if (this._request && !this._request.complete) {
            this._request.abort();
        }
    };

    /**
     * Abort the request and clear value of the field
     */
    GUI.Forms.Autocompleter.prototype.cancelSearch = function () {
        this.abortRequest();
        this.setValue('');
    };

    /**
     * Hide loader, show search results if it is not empty or hide list.
     * Fire event 'searchSuccess'
     * @param {Object} transport
     * @param {Object} request
     */
    GUI.Forms.Autocompleter.prototype.onSearchSuccess = function (transport, request) {
        try {
            var data = request.responseJson;
            this.data = data;

            if (this.autocomplete) {
                this.hideLoader();

                if (this.hideEmptyList) {
                    if (data.total > 0) {
                        this.showList();
                        this.showSearchResults(data);
                    } else {
                        this.hideList();
                    }
                } else {
                    this.showSearchResults(data);
                }
            }

            this.fireEvent('searchSuccess', this, data);
        } catch (e) { }
    };

    /**
     * empty function
     */
    GUI.Forms.Autocompleter.prototype.onSearchFailure = function () {
        //
    };

    /**
     * If the list is opened, return.
     * Set dimensions of the list, show it, hide loader, renedr json view,
     * attach event listeners
     */
    GUI.Forms.Autocompleter.prototype.showList = function () {
        if (this.listOpened) {
            return;
        }

        if (!this.fireEvent('beforeShow', this)) {
            return;
        }

        this.list.setContent(this.listTpl.fetch());

        if (this.autoApplyPosition) {
            this.alignDom = this.alignDom || this.dom;
            this.list.alignTo(this.alignDom, 'tl-bl?', this.listOffset);
        }

        this.list.show();

        if (this.listWidth) {
            var listWidth = this.listWidth;

            if (listWidth === 'auto') {
                listWidth = false;
            }

            this.list.setDimensions({
                width: listWidth || GUI.getFullWidth(this.dom)
            });
        }

        this.listOpened = true;
        this.hideLoader();
        this.hideSearchResults();
        this.jsonview.render();
        this.attachListEventListeners();
    };

    /**
     * Remove event listeners, unrender json view, list hide
     */
    GUI.Forms.Autocompleter.prototype.hideList = function () {
        if (!this.listOpened) {
            return;
        }
        this.removeListEventListeners();
        this.jsonview.unrender();
        this.list.hide();
        this.listOpened = false;
    };

    /**
     * Show list and loader
     */
    GUI.Forms.Autocompleter.prototype.showLoader = function () {
        if (!this.listOpened) {
            this.showList();
        }
        if (this.loader) {
            GUI.show(this.loaderId);
        }
    };

    /**
     * Hide loader
     */
    GUI.Forms.Autocompleter.prototype.hideLoader = function () {
        if (this.listOpened) {
            if (this.loader) {
                GUI.hide(this.loaderId);
            }
        }
    };

    /**
     * Show list, update and show json view
     * @param {Object} data Data for json view
     */
    GUI.Forms.Autocompleter.prototype.showSearchResults = function (data) {
        var i, div, h;

        if (this.hideEmptyList && data.rows && data.rows.length === 0) {
            return false;
        }

        if (!this.listOpened) {
            this.showList();
        }
        this.selectedIndex = -1;

        for (i = 0; i < data.rows.length; i++) {
            this.fireEvent('filterDataRow', this, data.rows[i]);
            data.rows[i].disabled = data.rows[i].disabled ? ' disabled' : '';
        }

        this.jsonview.update(data);
        if (this.searchFor && GUI.$(this.searchForId)) {
            GUI.$(this.searchForId).innerHTML =  this.getValue();
        }
        if (GUI.isNumber(this.maxContentHeight)) {
            // Check if content height is smaller, than maxContentHeight
            div = GUI.$(this.contentId);
            h = GUI.getClientHeight(this.jsonview.dom);

            if (h > this.maxContentHeight) {
                div.style.overflow  = 'auto';
                div.style.height    = this.maxContentHeight.toString() + 'px';
            }
        }
        this.jsonview.show();
    };

    /**
     * Hide json view
     */
    GUI.Forms.Autocompleter.prototype.hideSearchResults = function () {
        this.jsonview.hide();
    };

    /**
     * Initialize events
     */
    GUI.Forms.Autocompleter.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
        if (this.autocomplete) {

            this.keyboardEventsDown = new GUI.Utils.KeyboardEvents(this.dom, {
                anyKey: true
            });
            this.keyboardEventsUp = new GUI.Utils.KeyboardEvents(this.dom, {
                byEvent: 'keyup',
                anyKey: true
            });

            this.keyboardEventsDown.on(GUI.Utils.keys.anyKey, this.onKey, this);
            this.keyboardEventsUp.on(GUI.Utils.keys.anyKey, this.onKeyUp2, this);

        }
    };

    /**
     * Call parent remove events listeners
     */
    GUI.Forms.Autocompleter.prototype.removeEventListeners = function () {
        superproto.removeEventListeners.call(this);
        if (this.autocomplete) {
            this.keyboardEventsDown.destroy();
            this.keyboardEventsUp.destroy();
        }
    };

    /**
     * Attach events listeners, add 'mousedown' on document,
     * 'mouseover', 'mouseout', 'click' on json view
     */
    GUI.Forms.Autocompleter.prototype.attachListEventListeners = function () {
        document.on('mousedown', this.onDocumentMouseDown, this);
        var jv = GUI.Dom.extend(this.jsonview.dom);
        jv.on('mouseover', this.onJVMouseOver, this);
        jv.on('mouseout',  this.onJVMouseOut, this);
        jv.on('click',     this.onJVClick, this);
        GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);
    };

    /**
     * Remove events listeners, 'mousedown', 'mouseover', 'mouseout', 'click'
     */
    GUI.Forms.Autocompleter.prototype.removeListEventListeners = function () {
        document.un('mousedown', this.onDocumentMouseDown, this);
        var jv = this.jsonview.dom;
        jv.un('mouseover',    this.onJVMouseOver, this);
        jv.un('mouseout',    this.onJVMouseOut, this);
        jv.un('click',        this.onJVClick, this);
        GUI.Utils.globalEvents.un(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);
    };

    /**
     * Set value, fire event 'select', hide list, if autoApply call apply,
     * if searchApply call searchApply
     */
    GUI.Forms.Autocompleter.prototype.select = function () {
        if (this.selectedIndex > -1) {
            var data = this.jsonview.getData(),
                // CHD-1914 Remove tags clear for autocomplete input.
                // For email format "Name <mail@mail.com>"
                text = data.rows[this.selectedIndex][this.usedFieldToApply].toString();

            if (data.rows[this.selectedIndex].disabled) {
                return false;
            }

            if (data.rows[this.selectedIndex].id !== 'searchFor') {
                this.setValue(GUI.html_entity_decode(text)); // CHD-2115
            }
            this.fireEvent('select', this, data.rows[this.selectedIndex], text);
        }
        this.hideList();
        if (this.autoApply) {
            this.apply();
        }
        if (this.selectedIndex === -1 && this.searchApply) {
            this.searchApply(this, this.getValue());
        }
    };

    /**
     * Add class 'hover', scroll to row
     * @param {Number} index Inde of the row
     */
    GUI.Forms.Autocompleter.prototype.selectRow = function (index) {
        var id, row;
        if (this.selectedIndex > -1) {
            id = this.rowPrefix + this.selectedIndex.toString();
            row = GUI.$(id);
            if (row) {
                GUI.removeClass(row, 'selected');
            }
        }
        if (index > -1) {
            id = this.rowPrefix + index.toString();
            row = GUI.$(id);
            if (row) {
                GUI.addClass(row, 'selected');
                this.selectedIndex = index;
                // Need to scroll to item
                GUI.scrollIntoView(GUI.$(this.contentId), row);
            }
        } else {
            this.selectedIndex = -1;
        }
    };

    /**
     * Select next item
     */
    GUI.Forms.Autocompleter.prototype.moveSelectionNext = function () {
        var data = this.jsonview.getData(),
            len = data.rows.length;
        if (len === 0) {
            this.selectedIndex = -1;
        } else if (this.selectedIndex + 1 < len) {
            this.selectRow(this.selectedIndex + 1);
        } else {
            this.selectRow(0);
        }
    };

    /**
     * Select prev item
     */
    GUI.Forms.Autocompleter.prototype.moveSelectionPrev = function () {
        var data = this.jsonview.getData(),
            len = data.rows.length;
        if (len === 0) {
            this.selectedIndex = -1;
        } else if (this.selectedIndex - 1 > -1) {
            this.selectRow(this.selectedIndex - 1);
        } else {
            this.selectRow(len - 1);
        }
    };

    /**
     * If searchFor add text to this element.
     * @param keyName
     * @param e
     * @param ctrl
     * @param shift
     */
    GUI.Forms.Autocompleter.prototype.onKeyUp2 = function (keyName, e, ctrl, shift) {
        e = GUI.Event.extend(e);
        if (this.searchFor && GUI.$(this.searchForId)) {
            GUI.$(this.searchForId).innerHTML =  this.getValue();
        }
        if (!e.isNavKeyPress()) {
            this.searchTask.delay(this.searchDelay);
        }
    };

    /**
     *
     * @param keyName
     * @param e
     * @param ctrl
     * @param shift
     */
    GUI.Forms.Autocompleter.prototype.onKey = function (keyName, e, ctrl, shift) {
        e = GUI.Event.extend(e);
        switch (keyName) {
        case GUI.Utils.keys.adown:
            if (!this.listOpened) {
                // force searchTask
                this.searchTask.delay(0);
            } else {
                // List is opened, navigate through it
                this.moveSelectionNext();
            }
            break;

        case GUI.Utils.keys.aup:
            if (this.listOpened) {
                // List is opened, navigate through it
                this.moveSelectionPrev();
            }
            break;

        case GUI.Utils.keys.enter:
            if (this.listOpened) {
                // List is opened, navigate through it
                this.select();
            } else {
                if (this.autoApply) {
                    this.apply();
                }
                if (this.searchApply) {
                    this.searchApply(this, this.getValue());
                }
            }
            break;

        case GUI.Utils.keys.tab:
        case GUI.Utils.keys.esc:
            if (this.listOpened) {
                this.hideList();
            }
            break;
        }
    };

    /**
     * Hide list
     * @param {Event} e Event
     */
    GUI.Forms.Autocompleter.prototype.onDocumentMouseDown = function (e) {
        e = GUI.Event.extend(e);

        if ((this.listOpened && e.target.within(this.list.dom)) || e.target.within(this.dom)) {
            return;
        }
        this.hideList();
    };

    /**
     * Returns event row
     * @param {Event} e Event
     * @returns {HTMLElement} div Element, where the event occured
     */
    GUI.Forms.Autocompleter.prototype.getEventRow = function (e) {
        return e.target.findParent('li', this.jsonview.dom);
    };

    /**
     * Returns row index
     * @param {Object} row Row
     * @returns {Number} index Index of the row
     */
    GUI.Forms.Autocompleter.prototype.getRowIndex = function (row) {
        var tmp = row.id.split(this.rowPrefix, 2);
        return tmp[1];
    };

    /**
     * Select row
     * @param {Event} e Event
     */
    GUI.Forms.Autocompleter.prototype.onJVMouseOver = function (e) {
        e = GUI.Event.extend(e);
        var row = this.getEventRow(e),
            index;
        if (row) {
            if (e.isMouseEnter(row)) {
                index = this.getRowIndex(row);
                this.selectRow(index);
            }
        }
    };

    /**
     * Deselect combo (select item with index -1)
     * @param {Event} e Event
     */
    GUI.Forms.Autocompleter.prototype.onJVMouseOut = function (e) {
        e = GUI.Event.extend(e);
        var row = this.getEventRow(e);
        if (row) {
            if (e.isMouseLeave(row)) {
                this.selectRow(-1);
            }
        }
    };

    /**
     * Select row
     * @param {Event} e Event
     */
    GUI.Forms.Autocompleter.prototype.onJVClick = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();

        var row = this.getEventRow(e);
        if (row) {
            this.select();
        }
    };

}());