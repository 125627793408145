/**
 *
 */
GUI.onDOMReady(function () {
    if (GUI.$('add-ticket')) {
        NewTicketController.init();
    }
});

/**
 *
 */
NewTicketController = new GUI.Controller({
    name    : 'NewTicketReply',
    models  : '',
    views   : '',

    form    : null
});

/**
 *
 * @returns {undefined}
 */
NewTicketController.init = function () {
    Application.LoginForm.defaultCallback = function() {}; // disable page reload after login

    GUI.$('add-ticket-description').setAttribute('cssForIframe', window.cssForIframe);
    this.form = new GUI.Forms.Form();
    this.form.assign('add-ticket');

    this.title = this.form.getField('subject');
    this.textarea = this.form.getField('content');

    this.submitButton = GUI.$('add-ticket-submit');

    this.addEventListeners();

    this.textarea.on('afterRenderEditor', this.onRequestCreate.bindLegacy(this));

    if (this.title) {
        this.title.focus();
    }

    Application.initReCaptcha();

    jQuery('#fields-add textarea').autoResize(
        {
            extraSpace: 1
        }
    );

    window.addEventListener('message', function(e) {
        if (e.data.feedback) {
            this.form.getField('viaValue').setValue(e.data.href);
        }
    }.bindLegacy(this));
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.addEventListeners = function () {
    if (GUI.$('add-ticket-attachment-button')) {
        onWithFeatureRestrictions(
            'featureAttachmentsEnabled',
            GUI.$('add-ticket-attachment-button'),
            'click',
            this.onClickAttachment.bindLegacy(this)
        );
    }

    this.submitButton.addEventListener('click', this.onClickSubmitForm.bindLegacy(this));

    if (this.title) {
        this.title.fieldEl.on('keyup', this.onChangeData, this);
        this.title.on('invalid', function () {
            document.body.scrollTop = 0;
        });
    }

    this.textarea.on('keyup', this.onChangeData, this);

    this.form.getField('channelType').on('change', this.onChangeChannel, this);

    Application.on('login', function () {
        GUI.$('email-block').style.display = 'none';
        GUI.$('add-ticket-email').value = Application.authorization.email;
        GUI.$('add-ticket-description').style.height = '304px';
    }.bindLegacy(this));
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.onClickSubmitForm = function (event) {
    var f, params = {};

    event = GUI.Event.extend(event);
    event.preventDefault();
    event.stopPropagation();

    if (this.submitButton.getAttribute('disabled')) {
        return false;
    }

    if (this.form.validate()) {
        if (this.textarea.getValue() === '' && (GUI.$('listNamesAttachments').innerHTML !== '' || jQuery('[name=subject]').val())) {
            params.noContent = true;

            f = document.createElement('INPUT');
            f.type = 'hidden';
            f.name = 'noContent';
            f.value = 'true';
            GUI.$('add-ticket').appendChild(f);
        }

        if (window.APPLICATION_ENV === 'staging' || window.APPLICATION_ENV === 'production') {
            grecaptcha.execute(GUI.$("submitTicketReCaptcha").getAttribute('data-id'));
        } else {
            this.submitForm();
        }

        return false;
    }
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.submitForm = function () {
    this.submitButton.setAttribute('disabled', 'disabled');
    GUI.$('add-ticket').submit();
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.onClickAttachment = function () {
    if (!this.attachments) {
        this.attachments = new Attachments();
        this.attachments.init({
            attachments       : [],
            attachmentsHolder : GUI.$('listNamesAttachments'),
            callback          : this.setAttachmentsCount.bindLegacy(this)
        });
    }

    this.attachments.render();
};

/**
 *
 * @param {type} count
 * @returns {undefined}
 */
NewTicketController.setAttachmentsCount = function (count) {
    var text = '';

    if (count > 0) {
        text = sprintf(i18n('%s Attachment', '%s Attachments', count), count);
    } else {
        text = i18n('Attachments');
    }

    GUI.$('add-ticket-attachment').innerHTML = text;
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.onChangeChannel = function (combo, value, oldValue) {
    if (value !== 'request') {
        var stopper = document.createElement('input');
        stopper.name  = 'stopper';
        stopper.value = 1;
        stopper.type  = 'hidden';

        if (combo.getItemByValue(value).feedbackspace) {
            var spaceKey = combo.getItemByValue(value).feedbackspace;
        } else {
            var spaceKey = combo.getItemByValue(value).spacekey;
        }

        var url = route('addItem' + (top === self ? '' : 'Feedback'), {
            spaceName   : 's' + spaceKey +
            '-' + combo.getItemByValue(value).spacename,
            channel     : value
        });

        if (combo.getItemByValue(value).feedbackspace) {
            url += '?feedbackSpace=' + combo.getItemByValue(value).feedbackspace;
        }

        this.form.dom.action = url;
        this.form.dom.appendChild(stopper);
        this.form.addField(stopper);

        this.form.dom.submit();
    }
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.onChangeData = function () {
    if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
    }

    this.requestTimeout = setTimeout(this.onRequestCreate.bindLegacy(this), 1000);
};

/**
 *
 * @returns {undefined}
 */
NewTicketController.onRequestCreate = function () {
    if (this.request && !this.request.complete) {
        this.request.abort();
        this.request = null;
    }

    var data = {
        text      : (this.title ? this.title.getValue() + ' ' : '') + this.textarea.getValue()
    };

    if (data.text.trim().length < 2) {
        return;
    }

    if (this.form.getField('space')) {
        data.space = this.form.getField('space').getValue();
    }

    this.request = new GUI.Ajax.Request(
        //route('getRelatedForText'),
        route('getRelatedForText' + (top === self ? '' : 'Feedback')),
        {
            method    : 'POST',
            data      : data,
            onSuccess : this.onSuccessSearch.bindLegacy(this)
        }
    );
};

/**
 *
 * @param {type} xhr
 * @param {type} request
 * @returns {undefined}
 */
NewTicketController.onSuccessSearch = function (xhr, request) {
    GUI.$('search-result').innerHTML = xhr.responseText;
};