(function () {

    /**
     * JavaScript Graphical User Interface
     * WindowMgr implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Utils
     */
    GUI.Utils.WM = Class.create();

    /**
     * Default is 5000
     * @type Number
     */
    GUI.Utils.WM.prototype.zseed = 95;

    /**
     * Default is 10000
     * @tyoe Number
     */
    GUI.Utils.WM.prototype.alwaysOnTopzseed = 10000;

    /**
     * Constructor
     */
    GUI.Utils.WM.prototype.initialize = function () {
        this.registeredWindows    = {};
        this.activeWindow         = null;
        this.accessList           = [];
        this.maxUsedZindex        = this.zseed;

        document.on('dom:loaded', this.onLoad, this);
    };

    /**
     * Register objects
     * @param {Object} win
     */
    GUI.Utils.WM.prototype.register = function (win) {
        this.registeredWindows[win.config.id] = win;
        this.accessList.push(win);
        win.on('hide', this.activateLast, this);
    };

    /**
     * Unrefister object
     * @param {Object} win
     */
    GUI.Utils.WM.prototype.unregister = function (win) {
        delete this.registeredWindows[win.config.id];
        GUI.arrayRemove(this.accessList, win);
        win.un('hide', this.activateLast, this);
        if (win === this.activeWindow) {
            this.activeWindow = null;
        }
    };

    /**
     * Returns object by id
     * @param {Number|String} id
     */
    GUI.Utils.WM.prototype.get = function (id) {
        return (GUI.isObject(id)) ? id : this.registeredWindows[id];
    };

    /**
     * Bring window to front
     * @param {Object} win
     */
    GUI.Utils.WM.prototype.bringToFront = function (win) {
        win = this.get(win);
        if (win !== this.activeWindow) {
            win._lastAccess = new Date().getTime();
            this.orderWindows();
            return true;
        }
        return false;
    };

    /**
     * Send window to back
     * @param {Object} win
     */
    GUI.Utils.WM.prototype.sendToBack = function (win) {
        win = this.get(win);
        win._lastAccess = -(new Date().getTime());
        this.orderWindows();
    };

    /**
     * Returns active window
     * @returns {Object} window
     */
    GUI.Utils.WM.prototype.getActive = function () {
        return this.activeWindow;
    };

    /**
     * Sorts windows
     * @param {Object} d1
     * @param {Object} d2
     * @returns {Number}
     */
    GUI.Utils.WM.prototype.sortWindows = function (d1, d2) {
        return (!d1._lastAccess || d1._lastAccess < d2._lastAccess) ? -1 : 1;
    };

    /**
     * Orders windows
     */
    GUI.Utils.WM.prototype.orderWindows = function () {
        var seed, zIndex, i, win,
            a = this.accessList,
            len = a.length;

        this.maxUsedZindex = this.zseed;
        if (len > 0) {
            a.sort(this.sortWindows);
            seed = a[0].WM.zseed;
            for (i = 0; i < len; i++) {
                win = a[i];
                if (win && win.visible) {
                    zIndex = win.alwaysOnTop
                        ? this.alwaysOnTopzseed + (i * 10)
                        : seed + (i * 10);

                    win.setZIndex(zIndex);
                }
            }
        }
        this.activateLast();
    };

    /**
     * Returns z-index of the active window
     */
    GUI.Utils.WM.prototype.getMaxUsedZIndex = function () {
        if (this.activeWindow) {
            return this.activeWindow.getZIndex();
        } else {
            return this.zseed;
        }
    };

    /**
     * Sets active window to the win
     * @param {Object} win Window
     */
    GUI.Utils.WM.prototype.setActiveWin = function (win) {
        if (win !== this.activeWindow) {
            this.activeWindow = win;
        }
    };

    /**
     * Activate last window
     */
    GUI.Utils.WM.prototype.activateLast = function () {
        var i;
        for (i = this.accessList.length - 1; i >= 0; --i) {
            if (this.accessList[i].visible) {
                this.setActiveWin(this.accessList[i]);
                return;
            }
        }
        // none to activate
        this.setActiveWin(null);
    };

    /**
     * Pass keydown events to active window
     * @param {Object} e Event
     */
    GUI.Utils.WM.prototype.onKeyDown = function (e) {
        if (this.activeWindow) {
            this.activeWindow.onKeyDown(e);
        }
    };

    /**
     * Pass keyup events to active window
     * @param {Object} e Event
     */
    GUI.Utils.WM.prototype.onKeyUp = function (e) {
        if (this.activeWindow) {
            this.activeWindow.onKeyUp(e);
        }
    };

    /**
     * Pass keypress events to active window
     * @param {Object} e Event
     */
    GUI.Utils.WM.prototype.onKeyPress = function (e) {
        if (this.activeWindow) {
            this.activeWindow.onKeyPress(e);
        }
    };

    /**
     * Fires, when DOM document is ready.
     * Events initialization
     */
    GUI.Utils.WM.prototype.onLoad = function () {
        document.on('keydown', this.onKeyDown, this);
        document.on('keyup', this.onKeyUp, this);
        document.on('keypress', this.onKeyPress, this);
    };

    /**
     * Cleans up event listeners on window unload
     */
    GUI.Utils.WM.prototype.onUnLoad = function () {
        document.un('keydown', this.onKeyDown, this);
        document.un('keyup', this.onKeyUp, this);
        document.un('keypress', this.onKeyPress, this);
    };


    GUI.Utils.WM = new GUI.Utils.WM();

}());