(function () {
    var superproto = GUI.Forms.Field.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.TextField implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.Field
    */
    GUI.Forms.TextField = Class.create();
    Object.extend(GUI.Forms.TextField.prototype, superproto);

    /**
     * Type of the element, 'text'
     * @type String
     */
    GUI.Forms.TextField.prototype.type = 'text';

    /**
     * Css class of the field, 'b-text-field'
     * @type String
     */
    GUI.Forms.TextField.prototype.className = 'b-text-field';
    
    GUI.Forms.TextField.prototype.extraClass = '';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.TextField.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component
     */
    GUI.Forms.TextField.prototype.initComponent = function () {
        superproto.initComponent.call(this);
    };

    /**
     * Attach event listeners on 'blur', 'focus',
     * apply default text
     */
    GUI.Forms.TextField.prototype.attachEventListeners = function () {
        superproto.attachEventListeners.call(this);
        if (this.defaultText) {
            this.on('blur',  this.postBlur, this);
            this.on('focus', this.preFocus, this);
            this.applyDefaultText();
        }
    };

    /**
     * Sets size and maxLength
     * @param {HTMLElement} elem
     */
    GUI.Forms.TextField.prototype.onAssign = function (elem) {
        superproto.onAssign.call(this, elem);
        var value = elem.getAttribute('size');
        if (GUI.isSet(value) && value > 0) {
            this.size = value;
        }

        value = elem.getAttribute('maxlength');
        if (GUI.isSet(value) && value > 0) {
            this.maxLength = value;
        }
    };

    /**
     * Sets size and maxLength of the fieldEL.
     * Sets attribute autocomplite to off, for Gecko and Chrome
     * @param {HTMLElement} fieldEl Element of the field
     */
    GUI.Forms.TextField.prototype.initField = function (fieldEl) {
        superproto.initField.call(this, fieldEl);

        if (GUI.isSet(this.size)) {
            fieldEl.size = this.size;
        }
        if (GUI.isSet(this.maxLength)) {
            fieldEl.maxlength = this.maxLength;
        }
        if (GUI.isSet(this.placeholder)) {
            fieldEl.placeholder = this.placeholder;
        }
        if ((GUI.isGecko || GUI.isChrome) && !this.autocomplete) { // Chrome is needed in this attribute
            fieldEl.setAttribute('autocomplete', 'off');
        }
        if (this.extraClass) {
            fieldEl.addClass(this.extraClass);
        }
    };

    /**
     * Applies default text
     */
    GUI.Forms.TextField.prototype.postBlur = function () {
        this.applyDefaultText();
    };

    /**
     * Value of the field is sets to '' if it is equal to the default text,
     * remove class 'defaultTextClass'
     */
    GUI.Forms.TextField.prototype.preFocus = function () {
        if (this.defaultText) {
            if (this.fieldEl.value === this.defaultText) {
                this.setRawValue('');
                this.fieldEl.removeClass(this.defaultTextClass);
            }
        }
        
        
    };

    /**
     * Select text from strat to end
     * @param {Number} start Start of the selection
     * @param {Number} end End of the selection
     */
    GUI.Forms.TextField.prototype.selectText  = function (start, end) {
        var fieldEl, range,
            v = this.getRawValue(),
            doFocus = false;

        try {
            if (v.length > 0) {
                start = start === undefined ? 0 : start;
                end = end === undefined ? v.length : end;
                fieldEl = this.fieldEl;
                if (fieldEl.setSelectionRange) {
                    fieldEl.setSelectionRange(start, end);
                } else if (fieldEl.createTextRange) {
                    range = fieldEl.createTextRange();
                    range.moveStart("character", start);
                    range.moveEnd("character", end - v.length);
                    range.select();
                }
                doFocus = GUI.isGecko || GUI.isOpera;
            } else {
                doFocus = true;
            }
        } catch (e) {}

        if (doFocus) {
            this.focus();
        }
    };

}());
