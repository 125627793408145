(function () {
    var superproto = GUI.BoxComponent.prototype;
    /**
    * JavaScript Graphical User Interface
    * Base Container implementation. Container is a base class for all JSGUI components
    * that holds other components. It introduces layout subsystem that is responsible
    * for arranging child components. Containers may be nested as many times as you want.
    *
    * @class GUI.Utils.Container
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.BoxComponent
    */
    GUI.Utils.Container = Class.create();
    Object.extend(GUI.Utils.Container.prototype, superproto);

    /**
     * Defaults class name
     */
    GUI.Utils.Container.prototype.defaultCls = '';

    /**
     * True to destroy item when it is removed
     * @field
     * @name autoDestroy
     * @type Boolean
     */
    GUI.Utils.Container.prototype.autoDestroy = true;

    /**
     * Initializes object.
     * Call parent initialize meethod
     * @param {Object} config configuration object
     */
    GUI.Utils.Container.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Initialize component.
     * Add events 'afterLayout', 'afterChildrenLayout', 'beforeRemove', 'remove'.
     * Initialize items.
     * Call parent initComponent meethod.
     */
    GUI.Utils.Container.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            afterLayout : true, // Fires after the items are arranged by the layout
            afterChildrenLayout: true,
            beforeRemove: true,
            remove      : true
        });

        var items = this.items;
        if (items) {
            delete this.items;
            if (GUI.isArray(items)) {
                this.add.apply(this, items);
            } else {
                this.add(items);
            }
        } else {
            this.initItems();
        }
        return true;
    };

    /**
     * Initializes items and layout.
     */
    GUI.Utils.Container.prototype.initItems = function () {
        if (!this.items) {
            /**
             * GUI Collections
             * @type Array
             * @see GUI.Utils.Collection#
             */
            this.items = new GUI.Utils.Collection(false);
            this.getLayout(); // initialize the layout
        }
        return true;
    };

    /**
     * Renders 'div' html element.
     * @return {HTMLElement} dom
     */
    GUI.Utils.Container.prototype.onRender = function () {
        var dom = document.createElement('div');
        dom.id = this.id;
        dom.className = this.defaultCls + (this.cls ?  ' ' + this.cls : '');
        return dom;
    };

    /**
     * Destroys all items and layout.
     * Call parent method onBeforeDestroy
     * @param {Boolean} fast
     */
    GUI.Utils.Container.prototype.onBeforeDestroy = function (fast) {
        if (this.items) {
            this.items.each(function (item) {
                item.ownerCt = null;
                item.destroy(fast);
            });
        }
        if (this.layout && this.layout.destroy) {
            this.layout.destroy();
        }
        superproto.onBeforeDestroy.call(this, fast);
    };

    /**
     * Render container, layout.
     * If we are not in the container, then layout items, else parent
     * container will call doLayout itself
     */
    GUI.Utils.Container.prototype.render = function () {
        // At first, render container
        superproto.render.apply(this, arguments);

        // If layout name passed then create layout object
        if (this.layout) {
            if (GUI.isString(this.layout)) {
                if (GUI.Layouts[this.layout]) {
                    /**
                     * GUI Layout
                     * @type Object
                     */
                    this.layout = new GUI.Layouts[this.layout](this.layoutConfig);
                } else {
                    throw new Error('Unknown layout: "' + this.layout + '"');
                }
            }
            this.setLayout(this.layout);

            if (GUI.isSet(this.activeItem)) {
                var item = this.activeItem;
                delete this.activeItem;
                this.layout.setActiveItem(item);
                return;
            }
        }

        if (!this.ownerCt) {
            this.doLayout();
        }

        if (this.monitorResize === true) {
            GUI.Event.onWindowResize(this.onWindowResize, this);
        }
    };

    /**
     * Call doLayout with param false
     */
    GUI.Utils.Container.prototype.onWindowResize = function (w, h) {
        this.doLayout(false);
    };

     /**
     * Returns the layout currently in use by the container.  If the container does not currently have a layout
     * set, a default {@link Ext.layout.ContainerLayout} will be created and set as the container's layout.
     * @return {ContainerLayout} layout The container's layout
     */
    GUI.Utils.Container.prototype.getLayout = function () {
        if (!this.layout) {
            var layout = new GUI.Layouts.Layout(this.layoutConfig);
            this.setLayout(layout);
        }
        return this.layout;
    };

    /**
     * Returns layout target
     * @returns {HTMLElement} dom
     */
    GUI.Utils.Container.prototype.getLayoutTarget = function () {
        return this.dom;
    };

    /**
     * Sets layout
     * @param {Object} layout
     */
    GUI.Utils.Container.prototype.setLayout = function (layout) {
        if (this.layout && this.layout !== layout) {
            this.layout.setContainer(null);
        }
        this.initItems();
        this.layout = layout;
        layout.setContainer(this);
    };

    /**
     * Add layout, set component's owner container.
     * Fire event 'beforeadd', 'add'.
     * @param {Object} comp Layout
     */
    GUI.Utils.Container.prototype.add = function (comp) {
        if (!this.items) {
            this.initItems();
        }
        var a = arguments,
            len = a.length,
            i = 0,
            c = {},
            pos = 0;

        if (len > 1) {
            for (i; i < len; i++) {
                this.add(a[i]);
            }
            return;
        }

        c = this.lookupComponent(this.applyDefaults(comp));
        pos = this.items.length;

        if (this.fireEvent('beforeadd', this, c, pos) !== false && this.onBeforeAdd(c) !== false) {
            this.items.add(c);
            c.ownerCt = this;
            this.fireEvent('add', this, c, pos);
        }
        return c;
    };

    /**
     * Remove item from the previous container
     * @param {Object} item Layout
     */
    GUI.Utils.Container.prototype.onBeforeAdd = function (item) {
        if (item.ownerCt) {
            item.ownerCt.remove(item, false);
        }
    };

    /**
     * Destroy component.
     * Fire event 'beforeremove', 'remove'.
     * @param {Object} comp Component
     * @param {Boolean} autoDestroy
     */
    GUI.Utils.Container.prototype.remove = function (comp, autoDestroy) {
        var c = this.getComponent(comp);
        if (c && this.fireEvent('beforeremove', this, c) !== false) {
            this.items.remove(c);
            delete c.ownerCt;
            if (autoDestroy === true || (autoDestroy !== false && this.autoDestroy)) {
                c.destroy();
            }
            if (this.layout && this.layout.activeItem === c) {
                delete this.layout.activeItem;
            }
            this.fireEvent('remove', this, c);
        }
        return c;
    };

    /**
    * Force this container's layout to be recalculated. A call to this function
    * is required after adding a new component to an already rendered container,
    * or possibly after changing sizing/position properties of child components.
    * Fire event 'afterChildrenLayout'.
    * @param {Boolean} shallow (optional) True to only calc the layout of this
    *   component, and let child components auto calc layouts as required
    *   (defaults to false, which calls doLayout recursively for each subcontainer)
    */
    GUI.Utils.Container.prototype.doLayout = function (shallow) {
        if (this.rendered && this.layout) {
            this.layout.layout();
        }

        if (shallow !== false && this.items) {
            var i, len, c,
                cs = this.items.items;

            for (i = 0, len = cs.length; i < len; i++) {
                c  = cs[i];
                if (c.doLayout) {
                    c.doLayout(); // call doLayout() method for all nested containers
                }
            }
            this.fireEvent('afterChildrenLayout', this);
        }
    };

    /**
     * Gets a direct child Component by id, or by index.
     * @param {String|Number} id or index of child Component to return.
     * @return GUI.Component
     */
    GUI.Utils.Container.prototype.getComponent = function (comp) {
        if (typeof comp === 'object') {
            return comp;
        }
        return this.items.get(comp);
    };

    /**
     * @param {Object} comp Component
     */
    GUI.Utils.Container.prototype.lookupComponent = function (comp) {
        if (!comp.events) {
            var Cls = comp.cmp || GUI.Panel;
            comp.cmp = null;
            return new Cls(comp);
        }
        return comp;
    };

    /**
     * @param {Object} comp Component
     */
    GUI.Utils.Container.prototype.applyDefaults = function (comp) {
        if (this.defaults) {
            if (GUI.isString(comp)) {
                comp = GUI.getCmp(comp);
                Object.extend(comp, this.defaults);
            } else if (!comp.events) {
                Object.extendIf(comp, this.defaults);
            } else {
                Object.extend(comp, this.defaults);
            }
        }
        return comp;
    };

    /**
     * Layouts
     */
    GUI.Utils.Container.prototype.Layouts = {};

}());