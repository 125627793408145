(function () {
    var superproto = GUI.Popup.Dialog.prototype;

    /**
    * JavaScript Graphical User Interface
    * GUI.Popup.WizardDialog implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Popup.Dialog
    */
    GUI.Popup.WizardDialog = Class.create();
    Object.extend(GUI.Popup.WizardDialog.prototype, superproto);

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Popup.WizardDialog.prototype.initialize = function (config) {
        var defCfg, cfg;

        defCfg = {
            id      : GUI.getUniqId('wizard-dialog-'),
            modal   : true,
            scrollable  : false,
            allowScrollbars : false,
            title   : '',
            text    : '', // String or DOMNode. If DOMNode, then get innerHTML when display
            toolbuttons : [{name: 'close',  img: 'close',   tite: 'Close\nCloses window'}],

            footerButtons : [{
                name: 'prev',
                obj	: new GUI.ToolBar.Button({
                    name	: 'prev',
                    text	: i18n('Back'),
                    disabled: true,
                    onClick : this.onPrevClick.bindLegacy(this)
                })
            }, {
                name: 'next',
                obj	: new GUI.ToolBar.Button({
                    name	: 'next',
                    text	: i18n('Next'),
                    onClick : this.onNextClick.bindLegacy(this)
                })
            }],
            wizard          : {},

            visible: false,
            animate: window.GUI_FX_ANIMATION === true // boolean convertation
        };
        Object.extend(defCfg, config);
        // Call parent initialize meethod
        superproto.initialize.call(this, defCfg);
        Object.extend(this.config, defCfg);

        cfg = this.config;

        if (GUI.isIE) {
            cfg.animate = false;
        }
        this.elementType = 'GUI.Popup.WizardDialog';

        this.wizardId    = GUI.getUniqId('wizard-');
        this.defHandlers = {
            close : this.close
        };

        cfg.content = '<div id="' + this.wizardId + '"></div>';

        this.addEvents({
            close: true
        });
    };

    /**
     * Creates objects
     */
    GUI.Popup.WizardDialog.prototype.create = function () {
        superproto.create.call(this);
        var cfg = this.config;
        this.wizard = new GUI.Wizard(cfg.wizard);
        this.wizard.render(this.wizardId);
    };

    /**
     * Returns button by the name
     * @param {String} name Name of the item
     * @returns {Object} button
     */
    GUI.Popup.WizardDialog.prototype.getButton = function (name) {
        var i, btn,
            btns = this.config.footerButtons,
            len = btns.length;
        for (i = 0; i < len; i++) {
            btn = btns[i];
            if (btn.name === name) {
                return btn.obj;
            }
        }
    };

    /**
     * Destroys item
     */
    GUI.Popup.WizardDialog.prototype.destroy = function () {
        if (this.dom) {
            if (this.wizard) {
                this.wizard.destroy();
            }
        }
        superproto.destroy.call(this);
    };

    /**
     * Run prevStep method of wizard
     */
    GUI.Popup.WizardDialog.prototype.onPrevClick = function () {
        var prev = this.getButton('prev'),
            next = this.getButton('next');

        this.wizard.prevStep();
        if (!this.wizard.hasPrev()) {
            prev.disable();
        }
        if (next.isDisabled()) {
            next.enable();
        }
    };


    /**
     * Run nextClick of the wizard
     */
    GUI.Popup.WizardDialog.prototype.onNextClick = function () {
        var prev = this.getButton('prev'),
            next = this.getButton('next');

        this.wizard.nextStep();
        if (!this.wizard.hasNext()) {
            next.disable();
        }
        if (prev.isDisabled()) {
            prev.enable();
        }
    };

}());
