/**
* JavaScript Graphical User Interface.
* Bar implementation. This is a base component for different bars.
* It allows add, insert and remove elements.
*
* @author Eugene Lyulka
* @version 2.0
* @namespace GUI
* @extends GUI.Utils.Draggable
*/
GUI.Bar = Class.create();

Object.extend(GUI.Bar.prototype, GUI.Utils.Draggable.prototype);

    /**
     * Initializes object
     * @param {Object} config Configuration object, it has next properties: <br />
     * <b>id</b>        <i>String</i>
     *  Bar's id to set. Defaults to automatically generated unique id. <br />
     * <b>holder</b>    <i>String|HTMLElement</i>
     *  Holder to render component to. <br />
     * <b>elements</b>  <i>Array</i>
     *  Array of elements on the bar. Each element has to be an object having
     *  methods render(), unrender() and destroy(). <br />
     * <b>position</b>  <i>String</i>
     *  Position of the bar. Used in derived classes. <br />
     * <b>align</b>     <i>String</i>
     *  Bar's alignment. Used in derived classes. <br />
     * <b>name</b>      <i>String</i>
     *  Component's name. If it is set, component will be registered in
     *  the component manager by this name. <br />
     * <b>disabled</b>  <i>Boolean</i>
     *  True if toolbar is disabled. <br />
     */
GUI.Bar.prototype.initialize = function (config) {
    // Call parent initialize meethod
    GUI.Utils.Draggable.prototype.initialize.apply(this, arguments);

    var cfg = this.config;
    // Extend parent config
    Object.extend(cfg, {
        id          : GUI.getUniqId('bar-'),
        holder      : undefined,
        className   : '',
        elements    : undefined,
        position    : undefined,
        align       : undefined,
        disabled    : false,
        hideMode    : 'display',
        visible     : true
    });

    Object.extend(cfg, config);

    this.elementType = 'GUI.Bar';
    /**
     * GUI Collection
     * @type Object
     * @see GUI.Utils.Collection#
     */
    this.elements = new GUI.Utils.Collection();
    /**
     * @type Boolean
     */
    this.rendered = false;
    /**
     * config.disabled
     * @type Boolean
     */
    this.disabled = cfg.disabled;
    /**
     * config.visible
     * @type Boolean
     */
    this.visible = cfg.visible;

    if (cfg.name) {
        GUI.ComponentMgr.register(cfg.name, this);
    }
};

/**
 * Destroys all elements on the bar and the bar itself.
 */
GUI.Bar.prototype.destroy = function (fast) {
    this.config.holder = null;
    if (this.config.name) {
        GUI.ComponentMgr.unregister(this);
    }
    this.elements.each(function (item) {
        if (item.destroy) {
            item.destroy(fast);
        }
    }, this);
    this.elements.clear();
    this.purgeListeners();
};

/**
 * Adds element on the bar. Element can be accessed by its name later.
 * @param {String} name Name to access element on the toolbar later.
 * @param {Object} elem Element to add on the toolbar.
 */
GUI.Bar.prototype.add = function (name, elem) {
    this.elements.add(name, elem);
};

/**
 * Removes element from the bar by its name or index.
 * @param {String | Number key | Object} key Pass number to remove element by index or pass string to remove element by name.
 */
GUI.Bar.prototype.remove = function (key) {
    if (typeof key === 'string') {
        return this.elements.removeKey(key);
    } else if (typeof key === 'number') {
        return this.elements.removeAt(key);
    }
};

/**
 * Insert element on the bar at the specified index
 * @param {Number} index Specified index of element.
 * @param {String} name Name to access element on the toolbar later.
 * @param {Object} elem Element to add on the toolbar.
 */
GUI.Bar.prototype.insert = function (index, name, elem) {
    this.elements.insert(index, name, elem);
};

/**
 * Enables bar and all its elements
 */
GUI.Bar.prototype.enable = function () {
    if (this.disabled) {
        this.elements.each(function (elem) {
            elem.enable();
        });
        this.disabled = false;
    }
    return true;
};

/**
 * Disables bar and all its elements
 * @param {Boolean} flag False bar was enabled
 */
GUI.Bar.prototype.disable = function (flag) {
    if ((flag !== undefined) && (!flag)) {
        this.enable();
        return true;
    }

    if (!this.disabled) {
        this.elements.each(function (elem) {
            elem.disable();
        });
        this.disabled = true;
    }
    return true;
};

/**
 * Return is disabled
 * @returns {Boolean}
 */
GUI.Bar.prototype.isDisabled = function () {
    return this.disabled;
};

/**
 * Return is enabled
 * @returns {Boolean}
 */
GUI.Bar.prototype.isEnabled = function () {
    return !this.disabled;
};

/**
 * Sets bar position. Abstract method
 */
GUI.Bar.prototype.setPosition = function () {
    throw new Error('setPosition() is an abstract method!');
};

/**
 * Sets bar alignment. Abstract method
 */
GUI.Bar.prototype.setAlign = function () {
    throw new Error('setAlign() is an abstract method!');
};

/**
 * Returns bar position.
 * @returns {String}
 */
GUI.Bar.prototype.getPosition = function () {
    return this.config.position;
};

/**
 * Returns bar alignment.
 * @returns {String}
 */
GUI.Bar.prototype.getAlign = function () {
    return this.config.align;
};

/**
 * Return element on the bar by its key
 * @returns {String} key Key of the element to get.
 */
GUI.Bar.prototype.getElement = function (key) {
    return this.elements.get(key);
};

/**
 * Returns name of the element.
 * @param {Object} obj Object to get name of.
 * @returns {String} name
 */
GUI.Bar.prototype.getElementName = function (obj) {
    var idx = this.elements.indexOf(obj);
    return (GUI.isSet(idx)) ? this.elements.keyAt(idx) : null;
};

/**
 * Return element by index of tab object
 * @param {Object} obj
 */
GUI.Bar.prototype.indexOf = function (obj) {
    return this.elements.indexOf(obj);
};

/**
 * Renders bar to holder, if one is not passed as argument,
 * else replaces holder in config with passed
 * Fires events 'beforeRender', 'afterRender'
 * Abstract method
 * @param {String|HTMLElement} elem Optional id of container to render to
 */
GUI.Bar.prototype.render = function (elem) {
    if (!this.fireEvent('beforeRender', this)) {
        return;
    }

    var cfg = {},
        containerNode = {};

    if (this.dom) {
        this.unrender();
    }

    cfg = this.config;
    if (GUI.isSet(elem)) {
        cfg.holder = elem;
    }

    containerNode = GUI.$(cfg.holder);
    if (containerNode) {
        this._render(containerNode);
        this.rendered = true;
    } else {
        throw new Error(this.elementType + ' error in method render(): Element with id=' + this.config.holder + ' not found');
    }

    if (!this.visible) {
        this.hide();
    }

    this.fireEvent('afterRender', this);
};

/**
 * Unrenders all elements on the bar and the bar itself.
 */
GUI.Bar.prototype.unrender = function () {
    if (!this.dom) {
        return false;
    }
    this.elements.each(function (item) {
        item.unrender();
    }, this);
    GUI.remove(this.dom);
    this.dom = null;
    this.rendered = false;
};

// Private methods
/**
 * Renders DOM tree and sets this.dom to root of this tree
 * @return {Object} rendered DOMNode object
 */
GUI.Bar.prototype._render = function () {
    throw new Error(this.elementType + ' error: _render() is an abstract method!');
};

/**
 * Return this.dom
 * @returns {HTMLElement}
 */
GUI.Bar.prototype.getDom = function () {
    return this.dom;
};

/**
 * Hide the bar using the config.hideMode, it can be 'visibility' or 'display'. Default is 'display'.
 */
GUI.Bar.prototype.hide = function () {
    this.visible = false;
    if (this.dom) {
        switch (this.config.hideMode) {
        case 'visibility':
            this.dom.style.visibility = 'hidden';
            break;

        case 'display':
            this.dom.style.display = 'none';
            break;
        default:
            this.dom.style.display = 'none';
        }
    }
};

/**
 * Show the bar using the config.hideMode, it can be 'visibility' or 'display'. Default is 'display'.
 */
GUI.Bar.prototype.show = function () {
    this.visible = true;
    if (this.dom) {
        switch (this.config.hideMode) {
        case 'visibility':
            this.dom.style.visibility = '';
            break;

        case 'display':
            this.dom.style.display = '';
            break;

        default:
            this.dom.style.display = '';
        }
    }
};
