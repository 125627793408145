window.showConfirmFeatureNotAvailableDialog = function (btn) {

    if (btn && btn.enable != undefined) {
        btn.enable();
    }

    if (Application.authorization && Application.authorization.role === 'owner') {
        var msgBox = new GUI.Popup.Dialog({
            alwaysOnTop: true,
            title: i18n('Helprace Subscription'),
            content: '<div class="default-data-layout">' +
            getFullFeatureNotAvailableText(true) +
            '</div>',
            modal: true,
            autoDestroy: true,
            dimensions: {
                width: 400
            },
            toolbuttons: [],
            footerButtons: [{
                name: 'ok',
                obj: new GUI.ToolBar.Button({
                    text: i18n('Upgrade'),
                    primary: true,
                    onClick: function () {
                        closeAllDialogs();
                        var route = AdminRouter(
                            'settings',
                            {
                                category: 'billing',
                                action: 'subscription'
                            }
                        );
                        location.href = route;
                    }
                })
            },{
                name: 'cancel',
                obj: new GUI.ToolBar.Button({
                    text: i18n('Cancel'),
                    onClick: function () {
                        closeAllDialogs();
                    }
                })
            }]
        });
    } else {
        var msgBox = new GUI.Popup.Dialog({
            alwaysOnTop: true,
            title: 'Sorry..',
            content: '<div class="default-data-layout">' +
                getFullFeatureNotAvailableText() +
                '</div>',
            modal: true,
            autoDestroy: true,
            dimensions: {
                width: 400
            },
            toolbuttons: [],
            footerButtons: [{
                name: 'ok',
                obj: new GUI.ToolBar.Button({
                    text: i18n('OK'),
                    primary: true,
                    onClick: function () {
                        closeAllDialogs();
                    }
                })
            }]
        });
    }

    msgBox.create();
    msgBox.show();
};