(function () {
    var superproto = GUI.Grid.prototype;

    /**
     * JavaScript Graphical User Interface
     * EditableGrid implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Grid
     */
    GUI.EditableGrid = Class.create();
    Object.extend(GUI.EditableGrid.prototype, superproto);

    /**
     * Degfaults false
     * @type Boolean
     */
    GUI.EditableGrid.prototype.editOnClick = false;

    /**
     * Class constuctor
     */
    GUI.EditableGrid.prototype.initComponent = function () {
        // Call parent initialize method
        superproto.initComponent.call(this);

        this.addEvents({
            beforeEdit  : true,
            beforeApply : true,
            apply       : true,
            cancel      : true
        });

        if (this.editOnClick) {
            this.on('cellClick', this.onCellClick, this);
        } else {
            this.on('cellDblClick', this.onCellClick, this);
        }
    };

    /**
     * Shows editor
     * @param {Object} row
     * @param {Object} col
     */
    GUI.EditableGrid.prototype.showEditor = function (row, col) {
        var value;
        // Check if column is editable and show editor if so
        if (col.editable && col.editor) {
            if (!col.editorObj) {
                col.editorObj = new GUI.Grid.Editor({
                    grid    : this,
                    type    : col.editor,
                    options : col.options,
                    format  : col.format
                });
            }

            if (GUI.isSet(col.dataIndexEdit)) {
                value = row.data[col.dataIndexEdit];
            } else {
                value = row.data[col.dataIndex];
            }

            this.fireEvent('beforeEdit', this, row, col._index, value);
            col.editorObj.show({
                node    : GUI.$(row.domId).cells[col.domIndex],
                value   : value,
                colIndex: col._index, //Index,
                row     : row,
                editor  : col.editor
            });
        }
    };

    /**
     * Handler click on cell. Shows editor.
     * @param {Object} grid
     * @param {Object} row
     * @param {Object} col
     * @param {Event} e
     * @param {Object} td
     */
    GUI.EditableGrid.prototype.onCellClick = function (grid, row, col, e, td) {
        if (col.editable) {
            this.showEditor(row, col);
        }
    };

}());