(function () {
    var superproto = GUI.ActiveElement.prototype;

    /**
     * JavaScript Graphical User Interface
     * GUI.TabBar.Tab implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.ActiveElement
     */
    GUI.TabBar.Tab = Class.create();
    Object.extend(GUI.TabBar.Tab.prototype, superproto);

    /**
     * Static array, contains cached templates
     * @type Array
     */
    GUI.TabBar.Tab._tplCache = new Array(8);

    /**
     * Css class of th etab, default is 'b-button_tab_simple'
     * @type String
     */
    GUI.TabBar.Tab.prototype.extraClass = 'b-button_tab';

    /**
     * Default is 'selected'
     * @type String
     */
    GUI.TabBar.Tab.prototype.activeClass = 'selected';

    /**
     * Default is ''
     * @type String
     */
    GUI.TabBar.Tab.prototype.iconRightClass = '';

    /**
     * Default is 'mousedown'
     * @type String
     */
    GUI.TabBar.Tab.prototype.clickEvent = 'mousedown';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.TabBar.Tab.prototype.closeOnDblClick = false;

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.TabBar.Tab.prototype.initialize = function (config) {
        // Back support
        if (config) {
            if (!config.listeners) {
                config.listeners = {};
            }

            if (config.onBeforeActivate) {
                config.listeners.beforeactivate = config.onBeforeActivate;
                delete config.onBeforeActivate;
            }

            if (config.onAfterActivate) {
                config.listeners.afteractivate = config.onAfterActivate;
                delete config.onAfterActivate;
            }

            if (config.onBeforeDeactivate) {
                config.listeners.beforedeactivate = config.onBeforeDeactivate;
                delete config.onBeforeDeactivate;
            }

            if (config.onAfterDeactivate) {
                config.listeners.afterdeactivate = config.onAfterDeactivate;
                delete config.onAfterDeactivate;
            }

            if (config.closeBtn) {
                this.iconRightClass = 'close';
            }
        }
        superproto.initialize.call(this, config);
    };

    /**
     * Returns this
     * @returns {Object} tab
     */
    GUI.TabBar.Tab.prototype.getSelf = function () { return GUI.TabBar.Tab; };

    /**
     * Activates tab
     * Fires events 'beforeActivate', 'afterActivate'
     */
    GUI.TabBar.Tab.prototype.activate = function () {
        if (this.disabled || (this.fireEvent('beforeActivate', this) === false)) {
            return false;
        }
        this.active = true;
        if (this.dom) {
            this.onActivate();
        }
        this.fireEvent('afterActivate', this);
    };

    /**
     * Shows content
     */
    GUI.TabBar.Tab.prototype.onActivate = function () {
        this.dom.addClass(this.activeClass);
        if (this.assigned) {
            GUI.$(this.assigned).style.display = 'block';
        }
    };

    /**
     * Deactivates tab
     * Fires events 'beforeDeactivate', 'afterDeactivate'
     */
    GUI.TabBar.Tab.prototype.deactivate = function () {
        if (this.disabled || (this.fireEvent('beforeDeactivate', this) === false)) {
            return false;
        }
        this.active = false;
        if (this.dom) {
            this.onDeactivate();
        }
        this.fireEvent('afterDeactivate', this);
    };

    /**
     * Hides content
     */
    GUI.TabBar.Tab.prototype.onDeactivate = function () {
        this.dom.removeClass(this.activeClass);
        if (this.assigned) {
            GUI.hide(this.assigned);
        }
    };

    /**
     * Closes tab
     */
    GUI.TabBar.Tab.prototype.close = function () {
        if (this.bar && (GUI.isFunction(this.bar.remove))) {
            // if our tab is on the bar, then remove it from bar
            this.bar.closeTab(this);
        } else {
            // if we are not on the bar, then simply deactivate us
            this.deactivate();
        }
    };

    /**
     * Sets tab bar, which tab belongs to
     * @param {Object} bar GUI.TabBar object
     */
    GUI.TabBar.Tab.prototype.setBar = function (bar) {
        if (bar instanceof GUI.TabBar) {
            this.bar = bar;
        } else {
            throw new Error(' error: setBar() - bar is not instance of TabBr');
        }
    };

    /**
     * Returns true if tab is active
     * @return {Boolean}
     */
    GUI.TabBar.Tab.prototype.isActive = function () {
        return this.active;
    };

    /**
     * Adds events 'afterActivate', 'afterDeactivate',
     * 'beforeActivate', 'beforeDeactivate'
     */
    GUI.TabBar.Tab.prototype.initComponent = function () {
        superproto.initComponent.call(this);
        this.addEvents({
            'afterActivate'     : true,
            'afterDeactivate'   : true,
            'beforeActivate'    : true,
            'beforeDeactivate'  : true
        });
    };

    /**
     * Activates item
     * @param {Object} btn Button
     */
    GUI.TabBar.Tab.prototype.onAfterRender = function (btn) {
        superproto.onAfterRender.call(this, btn);
        if (this.closeOnDblClick) {
            btn.on('dblclick', this.onDblClick, this);
        }
        if (this.active) {
            this.activate();
        }
    };

    /**
     * Destroys objects
     * @param {Boolean} fast
     */
    GUI.TabBar.Tab.prototype.onDestroy = function (fast) {
        this.assigned = this.bar = null;
        superproto.onDestroy.call(this, fast);
    };

    /**
     * Handler click
     * @param {Event} e Event
     */
    GUI.TabBar.Tab.prototype.onClick = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
        if (!this.disabled) {
            if (e.target.nodeName === 'I' && e.target.className === 'b-button__icon close') {
                this.close();
            } else if (this.bar && (GUI.isFunction(this.bar.switchTo))) {
                // if our tab is on the bar, then force tabbar to switch tabs
                this.bar.switchTo(this);
            }
            this.fireEvent('click', this);
        }
    };

    /**
     * Handler double click
     * @param {Event} e Event
     */
    GUI.TabBar.Tab.prototype.onDblClick = function (e) {
        // If not active tab or close on dbl click is disabled, then nothing to do
        if (this.disabled || !this.active || !this.closeOnDblClick) {
            return;
        }
        // Else close this tab
        this.close();
    };

}());