
window.AC_Layer = Class.create();

Object.extend(window.AC_Layer.prototype, GUI.View.prototype);


window.AC_Layer.prototype.initialize = function () {
    this.permanentActive = false;
    this.createRegion();
    this.region.setPadding('5 5 5 5');
    this.region.setBorder('1 1 1 1');

    this.layer = new GUI.Popup.Layer({
        dom         : document.body,
        position    : 'tr-br?',
        layer       : this.region,
        target      : document.querySelector('button.agent-collision-button'),
        useClickToShow: true
    });
};

window.AC_Layer.prototype.createRegion = function () {
    this.region = new GUI.Popup.Region({
        className   : 'b-layer b-layer_usersList b-arrow b-arrow_right view-agent-collision',
        content     : ''
    });
};

/**
 *
 * @param {Tickets_AgentCollision_Collection} collection
 */
window.AC_Layer.prototype.setCollection = function (collection) {
    this.agentsCollisionCollection = collection;
    this.setContent();
};

window.AC_Layer.prototype.setContent = function () {
    var maxUsers = 5,
        users = 0,
        tpl = '';

    var editors = this.agentsCollisionCollection;

    if (!editors) {
        return;
    }

    if (!this.region) {
        return;
    }


    if (editors.length == maxUsers + 1) {
        maxUsers += 1;
    }

    Object.keys(editors).forEach(function (editorId) {
        var domainAC = editors[editorId];

        if (users >= maxUsers) {
            users += 1;
            return;
        }

        tpl += '<div class="b-user-layer b-user-layer_usersList ticket-ac-edit-list">';
        tpl += '    <div class="b-user-layer__info">';
        tpl += '        <div class="b-avatar b-avatar_layerList">';
		tpl += '        	<img src="' + domainAC.avatar + '">';
        tpl += '            <span class="b-avatar__statusEdit"><i class="b-avatar__statusIcon fa fa-pencil fa_size_10 fa_font_8 fa_white"></i></span>';
		tpl += '        </div>';
		tpl += '        <div class="b-user-layer__info-data">';
		tpl += '        	<div class="b-user-layer__info-name username">' + domainAC.userName + '</div>';
		tpl += '        	<div class="b-user-layer__info-row action">' + i18n('Editing') + '</div>';
		tpl += '        </div>';
        tpl += '    </div>';
        tpl += '</div>';

        users += 1;
    });

    if (users > maxUsers) {
        tpl += '<div class="b-user-layer b-user-layer_usersList ticket-ac-view-list">';
        tpl += '    <div class="b-user-layer__info">';
        tpl += '        <div class="b-avatar b-avatar_layerList">';
		tpl += '        	<div class="b-avatar__counter more-users">+' + (users - maxUsers)  +'</div>';
		tpl += '        </div>';
        tpl += '    </div>';
        tpl += '</div>';
    }

    this.region.setContent(tpl);
};

window.AC_Layer.prototype.hide = function () {
    this.layer.hideLayer();
};

window.AC_Layer.prototype.destroy = function () {
    this.layer.destroy();
};