(function () {
    var superproto = GUI.Forms.Validation.Validator.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.NotEmpty implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Validator
    */
    GUI.Forms.Validation.Notempty = Class.create();
    Object.extend(GUI.Forms.Validation.Notempty.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_NOTEMPTY'
     * @type String
     */
    GUI.Forms.Validation.Notempty.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_NOTEMPTY);

    /**
     * Type of the element, 'GUI.Forms.Validation.NotEmpty'
     * @type String
     */
    GUI.Forms.Validation.Notempty.prototype.elementType = 'GUI.Forms.Validation.NotEmpty';

    /**
     * Validates value
     * @param {String|Number} value Value to validate
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Notempty.prototype.validateFunc = function (value) {
        return (value.trim().length > 0);
    };
}());