import {hotkeys, Keys, KEY, GroupKeys} from "library/keyboard";

(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * MenuButton  implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.ToolBar.MenuButton
     * @extends GUI.Utils.Observable
     */
    GUI.ToolBar.MenuButton = Class.create();
    Object.extend(GUI.ToolBar.MenuButton.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.ToolBar.MenuButton.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        // Extend parent config
        this.config = {
            id              : GUI.getUniqId('menubutton-'),
            name            : null,
            caption         : null,
            img             : null,
            hint            : null,
            disabled        : false,
            extraClass      : '',
            iconRightClass  : 'dropdown_down', // b-button__icon dropdown_up
            button          : GUI.ToolBar.Button,
            background      : false,
            position        : 'left',
            canToggle       : false,
            menu            : null
        };

        var cfg = this.config;
        Object.extend(cfg, config);

        if (cfg.menu) {
            cfg.menu = Object.extend({
                destroyOnHide: false,
                hideOnDocumentClick: true,
                hintPosition: 'right',
                canBeSelected: true
            }, config.menu);

            this.menu = new GUI.Popup.Menu(cfg.menu);
        }

        this.button = new cfg.button({
            id              : cfg.id,
            name            : cfg.name,
            caption         : cfg.caption,
            img             : cfg.img,
            hint            : cfg.hint,
            title           : cfg.title,
            iconRightClass  : cfg.iconRightClass,
            iconClass       : cfg.iconClass,
            extraClass      : cfg.extraClass,
            canToggle       : false,
            autoDisable     : false,
            primary         : cfg.primary,
            delimiter       : cfg.delimiter,
            background      : cfg.background,
            disabled        : cfg.disabled,
            tabIndex        : cfg.tabIndex
        });

        this.disabled = cfg.disabled || false;

        this.rendered = false;
        this.toogled = false;
    };

    /**
     *
     */
    GUI.ToolBar.MenuButton.prototype.attachEventListeners = async function () {
        this.button.on('click', this.onBtnClick, this);
        this.menu.on('hide', this.onHideMenu, this);
    };

    /***
     */
    GUI.ToolBar.MenuButton.prototype.removeEventListeners = function () {
        this.button.un('click', this.onBtnClick, this);
        this.menu.un('hide', this.onHideMenu, this);
    };

    /**
     * Destroys object
     */
    GUI.ToolBar.MenuButton.prototype.destroy = function () {
        this.unrender();
        if (this.menu) {
            this.menu.config.destroyOnHide = true;
            this.menu.hide();
        }

        if (this.button.destroy) {
            this.button.destroy();
        }
    };

    /**
     * Renders dom
     * @param {HTMLElement} to Element to render object to
     */
    GUI.ToolBar.MenuButton.prototype.render = function (to) {
        if (this.rendered) {
            this.unrender();
        }
        if (to) {
            this.config.holder = to;
        } else {
            to = this.config.holder;
        }
        this.button.render(to);
        this.attachEventListeners();
    };

    /**
     * Removes object
     */
    GUI.ToolBar.MenuButton.prototype.unrender = function () {
        if (this.rendered) {
            this.button.unrender();
            this.removeEventListeners();
        }
    };

    /**
     *
     */
    GUI.ToolBar.MenuButton.prototype.onHideMenu = function (obj, e) {
        if (!e || (e && !e.within(this.button.dom))) {
            if (this.config.iconRightClass) {
                this.button.setIconRightClass('dropdown_down');
            }

            this.toogled = false;
            this.button.getKeyboardEventManager().resume();
        }
    };

    /**
     * If the menu is visible hides it, or shows
     */
    GUI.ToolBar.MenuButton.prototype.onBtnClick = function (btn, e) {
        var btndown = this.button.dom.children[2];
        if (this.disabled) {
            return;
        }

        if (this.toogled) {
            if (this.config.iconRightClass) {
                this.button.setIconRightClass('dropdown_down');
            }

            if (this.menu) {
                this.menu.hide();
            }

            this.toogled = false;
            // not toogled
        } else {
            if (this.config.iconRightClass) {
                this.button.setIconRightClass('dropdown_up');
            }

            if (this.config.delimiter) {

                if (e.target === btndown && this.menu) {
                    this.showMenu(this.button.dom);

                } else if (this.config.onClick) {
                    this.config.onClick();
                }

            } else if (this.menu) {

                this.showMenu(this.button.dom);
            }
            this.toogled = true;
        }
    };

    /**
     * Disable button
     */
    GUI.ToolBar.MenuButton.prototype.showMenu = function (el) {
        var position;

        if (GUI.LTR) {
            position = this.config.position === 'left' ? 'tl-bl?' : 'tr-br?';
        } else {
            position = this.config.position === 'left' ? 'tr-br?' : 'tl-bl?';
        }

        this.menu.alignTo(el, position);
        this.menu.show();

        this.button.getKeyboardEventManager().pause();
    };

    /**
     * Disable button
     */
    GUI.ToolBar.MenuButton.prototype.disable = function () {
        this.disabled = true;
        this.button.disable();
    };

    /**
     * Enable button
     */
    GUI.ToolBar.MenuButton.prototype.enable = function () {
        this.disabled = false;
        this.button.enable();
    };

    /**
     * Sets text of the button
     * @param {String} text
     */
    GUI.ToolBar.MenuButton.prototype.setText = function (text) {
        this.button.setText(text);
    };

    /**
     * Sets icon class of the button
     * @param {String} value Url of icon
     */
    GUI.ToolBar.MenuButton.prototype.setIcon = function (value) {
        this.button.setIcon(value);
    };
    
    /**
     * Sets icon class of the button
     * @param {String} css class
     */
    GUI.ToolBar.MenuButton.prototype.setIconClass = function (value) {
        this.button.setIconClass(value);
    };

}());