const KEY = {
    // Alphabet
    a: 'a', b: 'b', c: 'c', d: 'd', e: 'e',
    f: 'f', g: 'g', h: 'h', i: 'i', j: 'j',
    k: 'k', l: 'l', m: 'm', n: 'n', o: 'o',
    p: 'p', q: 'q', r: 'r', s: 's', t: 't',
    u: 'u', v: 'v', w: 'w', x: 'x', y: 'y', z: 'z',

    // Numbers
    n0: 'n0', n1: 'n1', n2: 'n2', n3: 'n3', n4: 'n4',
    n5: 'n5', n6: 'n6', n7: 'n7', n8: 'n8', n9: 'n9',

    // Controls
    tab: 'tab', enter: 'enter', shift: 'shift', backspace: 'backspace',
    ctrl: 'ctrl', alt: 'alt', esc: 'esc', space: 'space',
    menu: 'menu', pause: 'pause', cmd: 'cmd',

    insert: 'insert', home: 'home', pageup: 'pageup',
    'delete': 'delete', end: 'end', pagedown: 'pagedown',

    shiftSemicolon: 'shiftSemicolon',

    // F*
    f1: 'f1', f2: 'f2', f3: 'f3', f4: 'f4', f5: 'f5', f6: 'f6',
    f7: 'f7', f8: 'f8', f9: 'f9', f10: 'f10', f11: 'f11', f12: 'f12',
    // numpad
    np0: 'np0', np1: 'np1', np2: 'np2', np3: 'np3', np4: 'np4',
    np5: 'np5', np6: 'np6', np7: 'np7', np8: 'np8', np9: 'np9',

    npslash: 'npslash', npstar: 'npstar', nphyphen: 'nphyphen', npplus: 'npplus', npdot: 'npdot',

    // Lock
    capslock: 'capslock', numlock: 'numlock', scrolllock: 'scrolllock',

    // Symbols
    equals: 'equals', hyphen: 'hyphen', coma: 'coma', dot: 'dot',
    gravis: 'gravis', backslash: 'backslash', sbopen: 'sbopen', sbclose: 'sbclose',
    slash: 'slash', semicolon: 'semicolon', apostrophe: 'apostrophe', underscore: 'underscore',

    // Arrows
    aleft: 'aleft', aup: 'aup', aright: 'aright', adown: 'adown',

    anyKey: 'anykey'
};

const MODIFIER = {
    ctrl: KEY.ctrl,
    shift: KEY.shift,
    alt: KEY.alt,
};

const SETTINGS = {
    PROCESS_INPUT_KEYBOARD: 'processInputKeyboard',
    DISABLE_PREVENT_DEFAULT: 'disablePreventDefault',
    ON_KEY_DOWN: 'onKeyDown'
};

const _keysToCode = {
    // Alphabet
    a: 65, b: 66, c: 67, d: 68, e: 69,
    f: 70, g: 71, h: 72, i: 73, j: 74,
    k: 75, l: 76, m: 77, n: 78, o: 79,
    p: 80, q: 81, r: 82, s: 83, t: 84,
    u: 85, v: 86, w: 87, x: 88, y: 89, z: 90,
    // Numbers
    n0: 48, n1: 49, n2: 50, n3: 51, n4: 52,
    n5: 53, n6: 54, n7: 55, n8: 56, n9: 57,
    // Controls
    tab: 9, enter: 13, shift: 16, backspace: 8,
    ctrl: 17, alt: 18, esc: 27, space: 32,
    menu: 93, pause: 19, cmd: 91,
    insert: 45, home: 36, pageup: 33,
    'delete': 46, end: 35, pagedown: 34,

    shiftSemicolon: 59,

    // F*
    f1: 112, f2: 113, f3: 114, f4: 115, f5: 116, f6: 117,
    f7: 118, f8: 119, f9: 120, f10: 121, f11: 122, f12: 123,
    // numpad
    np0: 96, np1: 97, np2: 98, np3: 99, np4: 100,
    np5: 101, np6: 102, np7: 103, np8: 104, np9: 105,
    npslash: 111, npstar: 106, nphyphen: 109, npplus: 107, npdot: 110,
    // Lock
    capslock: 20, numlock: 144, scrolllock: 145,

    // Symbols
    equals: 187, hyphen: 109, coma: 188, underscore: 189, dot: 190,
    gravis: 192, backslash: 220, sbopen: 219, sbclose: 221,
    slash: 191, semicolon: 186, apostrophe: 222,

    // Arrows
    aleft: 37, aup: 38, aright: 39, adown: 40
};

const _codeToKeys = {8: "backspace", 9: "tab", 11: "npslash", 13: "enter", 16: "shift", 17: "ctrl", 18: "alt", 19: "pause", 20: "capslock", 27: "esc", 32: "space", 33: "pageup", 34: "pagedown", 35: "end", 36: "home", 37: "aleft", 38: "aup", 39: "aright", 40: "adown", 45: "insert", 46: "delete", 48: "n0", 49: "n1", 50: "n2", 51: "n3", 52: "n4", 53: "n5", 54: "n6", 55: "n7", 56: "n8", 57: "n9", 59: "shiftSemicolon", 61: "equals", 65: "a", 66: "b", 67: "c", 68: "d", 69: "e", 70: "f", 71: "g", 72: "h", 73: "i", 74: "j", 75: "k", 76: "l", 77: "m", 78: "n", 79: "o", 80: "p", 81: "q", 82: "r", 83: "s", 84: "t", 85: "u", 86: "v", 87: "w", 88: "x", 89: "y", 90: "z", 91: "cmd", 93: "menu", 96: "np0", 97: "np1", 98: "np2", 99: "np3", 100: "np4", 101: "np5", 102: "np6", 103: "np7", 104: "np8", 105: "np9", 106: "npstar", 107: "npplus", 109: "hyphen", 110: "npdot", 112: "f1", 113: "f2", 114: "f3", 115: "f4", 116: "f5", 117: "f6", 118: "f7", 119: "f8", 120: "f9", 121: "f10", 122: "f11", 123: "f12", 144: "numlock", 145: "scrolllock", 186: "semicolon", 188: "coma", 189: "underscore", 190: "dot", 191: "slash", 192: "gravis", 219: "sbopen", 220: "backslash", 221: "sbclose", 222: "apostrophe"};


function getCodeToKeys(code) {
    return _codeToKeys[code];
}

function getKeysToCode(key) {
    return _keysToCode[key];
}

class Keys {
    /**
     * @param keys Array<KEYS>
     * @param scope String
     * @param fn void Function()
     * @param modifiers Array<KEYS>
     */
    constructor(keys, scope, fn, modifiers=[]) {
        this.init(keys, scope, fn, {
            ctrl: modifiers.includes(KEY.ctrl),
            alt: modifiers.includes(KEY.alt),
            shift: modifiers.includes(KEY.shift),
            processInputKeyboard: modifiers.includes(SETTINGS.PROCESS_INPUT_KEYBOARD),
            preventDefault: !modifiers.includes(SETTINGS.DISABLE_PREVENT_DEFAULT),
            onKeyDown: modifiers.includes(SETTINGS.ON_KEY_DOWN)
        });
    }

    init(
        keys,
        scope,
        fn,
        ref
    ) {
        if (keys === undefined) {
            throw new Error('Keys is required');
        }

        this.keys = keys.map((key) => _keysToCode[key]);
        this.scope = scope;
        this.fn = fn;
        this.ctrl = ref.ctrl;
        this.alt = ref.alt;
        this.shift = ref.shift;
        this.processInputKeyboard = ref.processInputKeyboard;
        this.preventDefault = ref.preventDefault;
        this.isKeyDownEvent = ref.onKeyDown;
    }

    isExec(pressedKeys, ref) {
        let index = this.keys.length - 1;

        if (ref.ctrl !== this.ctrl || ref.alt !== this.alt || ref.shift !== this.shift) {
            return false;
        }

        for (let key of pressedKeys.slice().reverse()) {
            if (key !== this.keys[index]) {
                return false;
            }

            index -= 1;
        }

        return index === -1;
    }

    isScope(scope) {
        return this.scope === null
            || this.scope === scope
            || (this.scope.includes ? this.scope.includes(scope) : false);
    }

    processInput() {
        return this.processInputKeyboard;
    }

    call(e) {
        return this.fn && this.fn(e);
    }
}

class GroupKeys extends Keys {
    /**
     * @param keys Array<Array<KEYS>>
     * @param scope String
     * @param fn void Function()
     * @param ctrl bool
     * @param alt bool
     * @param shift bool
     * @param processInputKeyboard
     * @param preventDefault
     * @param onKeyDown
     */
    init(
        keys,
        scope,
        fn,
        ref
    ) {
        super.init(keys, scope, fn, ref);
        this.keysGroups = keys.map((group) => group.map((key) => _keysToCode[key]));
    }

    isExec(pressedKeys, ref) {
        for (let keys of this.keysGroups) {
            this.keys = keys;
            if (super.isExec(pressedKeys, ref) === true) {
                return true;
            }
        }

        return false;
    }
}

class HotKeys extends GUI.Utils.Observable {
    constructor(target) {
        if (!target) {
            target = jQuery(document);
        }

        super();

        this.keysActivate = [];
        this.subscriptionStack = [];
        this.ctrl = false;
        this.alt = false;
        this.shift = false;
        this.currentScope = null;
        this.scopeStack = [];

        target.on('keydown', this._onKeyDown.bind(this));
        target.on('keyup', this._onKeyUp.bind(this));
    }

    getScope() {
        return this.currentScope;
    }

    waitForScope() {
        return new Promise((resolve, reject) => {
            let i = 0;

            setInterval(() => {
                if (i > 20 || this.getScope() !== null) {
                    resolve();
                }

                i++;
            }, 100);
        })
    }

    setScope(name) {
        if (this.currentScope === name) {
            return;
        }

        this.currentScope = name;
        this.scopeStack.push(name);

        if (window.APPLICATION_ENV !== 'production') {
            console.log(`Current hotkeys scope: ${this.currentScope}`);
        }
    }

    removeScope(name) {
        this.subscriptionStack = this.subscriptionStack.filter((subscription) => !subscription.isScope(name) || subscription.scope === null);
        this.scopeStack = _.without(this.scopeStack, name);
        this.currentScope = this.scopeStack[this.scopeStack.length - 1] || null;

        if (window.APPLICATION_ENV !== 'production') {
            console.log(`Remove hotkeys scope ${name}. Current scope: ${this.currentScope}`);
        }
    }

    on(subscribe) {
        if (!(subscribe instanceof Keys) && !(subscribe instanceof GroupKeys)) {
            throw new Error("Wrong subscription, read docs, muhaha");
        }

        this.subscriptionStack.push(subscribe);

        return this;
    }

    _processExecCall(e) {
        const isInput = e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA';
        let failedCall = [];
        if (window.debug) {
            console.log(`Call: ${this.getScope()}, is input: ${isInput}, key:${this.keysActivate},${e.key} c:${this.ctrl},a:${this.alt},s:${this.shift}`);
        }

        let result;
        for (let sub of this.subscriptionStack) {

            if (sub.isScope(this.currentScope) &&
                (isInput ? sub.processInput() : true) &&
                sub.isExec(this.keysActivate, {ctrl: this.ctrl, alt: this.alt, shift: this.shift})
            ) {
                if (window.debug) {
                    console.info('Called:', sub);
                }

                result = (result || sub.call(e));
            } else if (window.debug) {
                failedCall.push({
                    scope: _.isArray(sub.scope) ? sub.scope.join('|') : (sub.scope ? sub.scope : 'null'),
                    keys: sub.keysGroups
                        ? sub.keysGroups.map((group) => {
                            return (group.map((key) => _codeToKeys[key])).join(' ')
                        }).join('|')
                        : _.map(sub.keys, (key) => _codeToKeys[key]).join(' '),
                    ctrl: sub.ctrl,
                    shift: sub.shift,
                    alt: sub.alt,
                    input: sub.processInputKeyboard
                });
            }
        }

        if (failedCall.length) {
            console.table(failedCall);
        }

        return result;
    }

    _isPossibleExecCall(e) {
        const isInput = e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA';

        let result;
        for (let sub of this.subscriptionStack) {
            if (sub.isScope(this.currentScope) &&
                (isInput ? sub.processInput() : true) &&
                sub.isExec(this.keysActivate, {ctrl: this.ctrl, alt: this.alt, shift: this.shift})
            ) {
                if (sub.isKeyDownEvent) {
                    return sub;
                }

                if (sub.preventDefault === true) {
                    result = true;
                }

                break;
            }
        }

        return result;
    }

    _onKeyDown(e) {
        let isModifier = e.keyCode === _keysToCode.ctrl ||
            e.keyCode === _keysToCode.cmd ||
            e.keyCode === _keysToCode.shift ||
            e.keyCode === _keysToCode.alt;

        this.ctrl = e.ctrlKey;
        this.alt = e.altKey;
        this.shift = e.shiftKey;

        if (!isModifier) {
            if (this.keysActivate.includes(e.keyCode)) {
                return;
            }

            this.keysActivate.push(e.keyCode);
        }

        let isPossibleExecCall = this._isPossibleExecCall(e);

        if (isPossibleExecCall instanceof Keys) {
            if (isPossibleExecCall.preventDefault === true) {
                e.preventDefault();
                e.stopPropagation();
            }

            this._onKeyUp(e);

            return;
        }

        if (isPossibleExecCall === true) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    _onKeyUp(e) {
        if (this._processExecCall(e) === true) {
            this.keysActivate.remove(e.keyCode);
        } else {
            this.keysActivate = [];
        }

        if (!e.ctrlKey && !e.metaKey) {
            this.ctrl = false;
        }

        if (!e.altKey) {
            this.alt = false;
        }

        if (!e.shiftKey) {
            this.shift = false;
        }
    }

    destroy() {
        if (this === hotkeys) {
            throw new Error(`Can't destroy base instance`);
        }

        this.subscriptionStack = null;
    }
}

let hotkeys = new HotKeys();

window.seleniumHotkey = function (keys=[], ctrl=false, shift=false, alt=false) {
    let e;
    let target = jQuery(document.activeElement||document);

    if (ctrl) {
        e = jQuery.Event('keydown');
        e.keyCode = _keysToCode.ctrl;
        target.trigger(e);
    }

    if (shift) {
        e = jQuery.Event('keydown');
        e.keyCode = _keysToCode.shift;
        target.trigger(e);
    }

    if (alt) {
        e = jQuery.Event('keydown');
        e.keyCode = _keysToCode.alt;
        target.trigger(e);
    }

    for (let key of keys) {
        e = jQuery.Event('keydown');
        e.keyCode = _keysToCode[key];
        e.ctrlKey = ctrl;
        e.altKey = alt;
        e.shiftKey = shift;
        target.trigger(e);
    }

    for (let key of keys.reverse()) {
        e = jQuery.Event('keyup');
        e.keyCode = _keysToCode[key];
        e.ctrlKey = ctrl;
        e.altKey = alt;
        e.shiftKey = shift;
        target.trigger(e);
    }
};

export {
    hotkeys,
    Keys,
    GroupKeys,
    KEY,
    MODIFIER,
    SETTINGS,
    HotKeys,
    getCodeToKeys,
    getKeysToCode
}