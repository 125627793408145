(function () { // variables isolation

    var AuthInformersClass = window.Class.create();

    var prototype = {
        informers : {},

        initialize : function (config) {
            Object.extend(this, config);

            var informer,
                delay = 0;

            switch(this.informers.informer_type) {
                case 'success':
                    delay = window.hideInformerSuccessAfter;
                    break;
                case 'info':
                    delay = window.hideInformerInfoAfter;
                    break;
                case 'error':
                    delay = window.hideInformerErrorAfter;
                    break;
            }

            if (this.informers.informer_messages && this.informers.informer_messages.length) {
                for (var i = 0; i < this.informers.informer_messages.length; i ++) {
                    informer = new GUI.Popup.PopupInformer({
                        type	: this.informers.informer_type,
                        caption	: '',//. this.informers.informer_title,
                        text	: this.informers.informer_messages[i],
                        closeButton	: true,
                        hideDelay : delay,
                        cls     : 'b-informer b-informer_popup auth'
                    });
                    informer.show();
                }
            }
        }
    };

    Object.extend(AuthInformersClass.prototype, prototype);

    GUI.onDOMReady(function () {
        if (window.doNotInitAuth) {
            Application.AuthInformers = new AuthInformersClass({
                informers : Application.informers
            });
        }
    });
} ());