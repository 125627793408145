(function () {
    var superproto = GUI.Forms.TextField.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.SpinEdit implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TextField
    */
    GUI.Forms.SpinEdit = Class.create();
    Object.extend(GUI.Forms.SpinEdit.prototype, superproto);

    /**
     * Default is 0
     * @type Number
     */
    GUI.Forms.SpinEdit.prototype.value = 0;

    /**
     * Default is 1
     * @type Number
     */
    GUI.Forms.SpinEdit.prototype.size = 1;

    /**
     * Default is 1
     * @type Number
     */
    GUI.Forms.SpinEdit.prototype.step = 1;

    /**
     * Default is 2
     * @type Number
     */
    GUI.Forms.SpinEdit.prototype.digits = 2;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.SpinEdit.prototype.leadingZero = false;

    /**
     * Css class of the field, default is 'b-text-field_spin'
     * @type String
     */
    GUI.Forms.SpinEdit.prototype.wrapperClass = '';

    /**
     * Css class of the 'trigger up' disabled, default is 'disabled'
     * @type String
     */
    GUI.Forms.SpinEdit.prototype.triggerUpDisabledClass = 'disabled';
    /**
     * Css class of the 'trigger down' disabled, default is 'disabled'
     * @type String
     */
    GUI.Forms.SpinEdit.prototype.triggerDownDisabledClass = 'disabled';

    /**
     * If true 'trigger up' is disabled, default is false
     * @type Boolean
     */
    GUI.Forms.SpinEdit.prototype.triggerUpDisabled = false;

    /**
     * If true 'trigger down' is disabled, default is false
     * @type Boolean
     */
    GUI.Forms.SpinEdit.prototype.triggerDownDisabled = false;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.SpinEdit.prototype.triggerOnFieldClick = false;

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.SpinEdit.prototype.parseFloat = false;

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.SpinEdit.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_spin {1}">' +
        '<i class="arrow-up"></i>' +
        '<i class="arrow-down"></i>' +
        '</i>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.SpinEdit.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Returns value
     * @returns {Number} value
     */
    GUI.Forms.SpinEdit.prototype.getValue = function () {
        var value = superproto.getValue.call(this);

        if (this.parseFloat) {
            value = parseFloat(value);
        } else {
            value = parseInt(value, 10)
        }

        if (isNaN(value)) {
            value = this.min || 0;
        }

        return value;
    };

    /**
     * Sets value, fire events 'beforechange', 'change'
     * @param {String} value
     * @param {Boolean} silent
     */
    GUI.Forms.SpinEdit.prototype.setValue = function (value, silent) {
        if (!silent) {
            if (this.fireEvent('beforechange', this, value) === false) {
                return false;
            }
        }
        if (!GUI.isNumber(value)) {
            value = this.parseFloat ? parseFloat(value) : parseInt(value, 10);

            if (isNaN(value)) {
                value = 0;
            }
        }
        this.min = this.min || 0;
        value = value.constrain(this.min, this.max);
        var oldValue = this.value,
            formattedValue;

        this.value = value;
        if (this.fieldEl) {
            if (value === null || value === undefined) {
                formattedValue = '';
            } else {
                formattedValue = this.leadingZero
                    ? value.toPaddedString(this.digits)
                    : value.toString();
            }
            this.fieldEl.value =  formattedValue;
            this.validate();
        }
        if (value !== oldValue && !silent) {
            this.fireEvent('change', this, value, oldValue);
        }

        this.toogleClasses(value);
        return true;
    };

    /**
     *
     * @param {type} value
     * @returns {undefined}
     */
    GUI.Forms.SpinEdit.prototype.toogleClasses = function (value) {
        if (this.dom) {
            // Check if need to disable min/max icon

            if (value === this.min) {
                if (!this.triggerDownDisabled) {
                    // Disable
                    this.triggerDownDisabled = true;
                    if (this.triggerDownEl) {
                        this.triggerDownEl.addClass(this.triggerDownDisabledClass);
                    }
                }
            } else if (this.triggerDownDisabled) {
                //  Enable
                this.triggerDownDisabled = false;
                if (this.triggerDownEl) {
                    this.triggerDownEl.removeClass(this.triggerDownDisabledClass);
                }
            }

            if (value === this.max) {
                if (!this.triggerUpDisabled) {
                    // Disable
                    this.triggerUpDisabled = true;
                    if (this.triggerUpEl) {
                        this.triggerUpEl.addClass(this.triggerUpDisabledClass);
                    }
                }
            } else if (this.triggerUpDisabled) {
                //  Enable
                this.triggerUpDisabled = false;
                if (this.triggerUpEl) {
                    this.triggerUpEl.removeClass(this.triggerUpDisabledClass);
                }
            }
        }
    };

    /**
     * Init component, add events 'triggerUp', 'triggerDown', 'beforechange'
     */
    GUI.Forms.SpinEdit.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            triggerUp   : true,
            triggerDown : true,
            beforechange: true
        });

        if (!GUI.isNumber(this.value)) {
            var value = this.parseFloat ? parseFloat(this.value) : parseInt(this.value, 10);
            this.value = (isNaN(value)) ? 0 : value;
        }
    };

    /**
     * Attach events listeners
     */
    GUI.Forms.SpinEdit.prototype.attachEventListeners = function () {
        this.fieldEl.on('focus', this.onFocus, this);
        this.fieldEl.on('blur',  this.onBlur,  this);

        this.keyboardEventDown = new GUI.Utils.KeyboardEvents(this.dom, {
            anyKey: true
        });
        this.keyboardEventUp = new GUI.Utils.KeyboardEvents(this.dom, {
            byEvent: 'keyup',
            anyKey: true
        });

        this.keyboardEventDown.on(GUI.Utils.keys.anyKey, this.onKeyDown, this);
        this.keyboardEventUp.on(GUI.Utils.keys.anyKey, this.onKeyUp, this);

        this.dom.on('blur', this.onBlur, this);

    };

    /**
     * Handler key down
     * @param keyName
     * @param {Event} e Event
     */
    GUI.Forms.SpinEdit.prototype.onKeyDown = function (keyName, e) {
        e = GUI.Event.extend(e);
        var value = this.fieldEl.value;

        if (!GUI.isNumber(value)) {
            value = this.parseFloat ? parseFloat(value) : parseInt(value, 10);

            if (isNaN(value)) {
                value = '';
            }
        }

        if (this.getValue() !== value) {
            this.fieldEl.value = value;
            this.value = value;
            this.toogleClasses(value);
        }

        if (keyName === GUI.Utils.keys.aup) {
            this.setValue(
                this.getValue() + this.step,
                true
            );
            e.stop();
        } else if (keyName === GUI.Utils.keys.adown) {
            this.setValue(
                this.getValue() - this.step,
                true
            );
            e.stop();
        }
    };

    /**
     * Handler key up, sets value
     * @param keyName
     * @param {Event} e Event
     */
    GUI.Forms.SpinEdit.prototype.onKeyUp = function (keyName, e) {
        e = GUI.Event.extend(e);

        var value = this.fieldEl.value;

        if (!GUI.isNumber(value)) {
            value = this.parseFloat ? parseFloat(value) : parseInt(value, 10);

            if (isNaN(value)) {
                value = '';
            }
        }

        if (this.getValue() !== value) {
            this.fieldEl.value = value;
            this.value = value;
            this.toogleClasses(value);
        }

        if (keyName === GUI.Utils.keys.aup) {
            e.stop();
        } else if (keyName === GUI.Utils.keys.adown) {
            e.stop();
        }
    };

    /**
     *
     * @returns {undefined}
     */
    GUI.Forms.SpinEdit.prototype.onBlur = function () {
        this.setValue(this.getValue());
    };

    /**
     * Handler assign
     * @param {HTMLElement} to Html element assign to
     */
    GUI.Forms.SpinEdit.prototype.onAssign = function (to) {
        superproto.onAssign.call(this, to);

        var v;
        if (GUI.isSet(v = to.getAttribute('max'))) {
            this.max = parseInt(v, 10);
        }

        if (GUI.isSet(v = to.getAttribute('min'))) {
            this.min = parseInt(v, 10);
        }

        if (GUI.isSet(v = to.getAttribute('step'))) {
            this.step = parseInt(v, 10);
        }

        if (GUI.isSet(v = to.getAttribute('leadingzero'))) {
            this.leadingZero = true;
        } else {
            this.leadingZero = false;
        }

        if (GUI.isSet(v = to.getAttribute('digits'))) {
            this.digits = parseInt(v, 10);
        }
    };

    /**
     *
     */
    GUI.Forms.SpinEdit.prototype.onRender = function () {
        // Render text field
        this.fieldEl = superproto.onRender.call(this);

        // Render wrapper
        var dom, wrapper,
            tmp = GUI.getFly(),
            html = this.domTemplate.format(this.id, this.wrapperClass);

        tmp.innerHTML = html; // TODO: Check IE leaks
        dom = tmp.removeChild(tmp.firstChild);

        // Now wrap textfield
        dom.insertBefore(this.fieldEl, dom.firstChild);

        if (this.hiddenInput && this.name) {
            this.fieldEl.name = '';
            this.hiddenField = GUI.createInput(this.name);
            this.hiddenField.type = 'hidden';
            wrapper.appendChild(this.hiddenField);
        }
        return dom;
    };

    /**
     * Handler after render
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.SpinEdit.prototype.onAfterRender = function (dom) {
        superproto.onAfterRender.call(this, this.fieldEl);

        this.fieldEl.style.width = this.adjustSize(this.width).width + 'px';

        this.triggerUpEl    = GUI.Dom.extend(GUI.Dom.findDescedents(this.dom, 'i.arrow-up')[0]);
        this.triggerDownEl  = GUI.Dom.extend(GUI.Dom.findDescedents(this.dom, 'i.arrow-down')[0]);

        var ClickRepeater = GUI.Utils.ClickRepeater;

        this.upRepeater = new ClickRepeater({
            dom         : this.triggerUpEl,
            pressedClass: '',
            listeners: {
                scope: this,
                click: this.onTriggerUpClick
            }
        });

        this.downRepeater = new ClickRepeater({
            dom         : this.triggerDownEl,
            pressedClass: '',
            listeners: {
                scope: this,
                click: this.onTriggerDownClick,
                pressedClass: ''
            }
        });
    };

    /**
     * Destroy trigger elements, downrepeater, uprepeater
     * @param {Boolean} quick
     */
    GUI.Forms.SpinEdit.prototype.onDestroy = function (quick) {
        this.triggerUpEl = null;
        this.triggerDownEl = null;

        if (this.downRepeater) {
            this.downRepeater.destroy();
            this.downRepeater = null;
        }

        if (this.upRepeater) {
            this.upRepeater.destroy();
            this.upRepeater = null;
        }

        superproto.onDestroy.call(this, quick);
    };

    /**
     * Sets raw value
     */
    GUI.Forms.SpinEdit.prototype.onBeforeBlur = function () {
        var v = parseInt(this.getRawValue(), 10);
        if (isNaN(v) || v !== v.constrain(this.min, this.max)) {
            v = this.startValue;
        }
        this.setRawValue(v);
    };

    /**
     * Sets value to value + step
     */
    GUI.Forms.SpinEdit.prototype.onTriggerUpClick = function () {
        if (!this.disabled && !this.triggerUpDisabled) {
            this.setValue(this.value + this.step);
        }
    };

    /**
     * Sets value to value - step
     */
    GUI.Forms.SpinEdit.prototype.onTriggerDownClick = function () {
        if (!this.disabled && !this.triggerDownDisabled) {
            this.setValue(this.value - this.step);
        }
    };

}());
