(function () {
    /**
     * @function
     * Create request and add handlers on event 'scope', 'beforeRequest', 'requestComplete'
     */
    function RequestManager() {
        /**
         * Array of requests
         * @type Array
         */
        this.requests = [];

        /**
         * @type Number
         */
        this.counter = 0;

        var r = GUI.Ajax.Request;
        r.on({
            scope: this,
            beforeRequest   : this.onBeforeRequest,
            requestComplete : this.onRequestComplete
        });
    }

    /**
     * JavaScript Graphical User Interface
     * Ajax.RequestsManager implementation
     * Stores all currently running ajax requests
     *
     * @class
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Ajax
     */
    GUI.Ajax.RequestsManager = RequestManager;

    GUI.Ajax.RequestsManager.prototype = {
        /**
         * Add request to the array this.requests
         */
        add: function (request) {
            this.requests[this.counter++] = request;
        },
        /**
         * Remove request from the array this.requests
         */
        remove: function (request) {
            if (this.requests.remove(request)) {
                this.counter--;
            }
        },

        /**
         * Aborts group of ajax requests
         */
        abortGroup: function (groupName) {
            var i = this.counter, r;
            while (i--) {
                r = this.requests[i];
                if (r.group === groupName) {
                    r.abort();
                }
            }
        },
        /**
         * Add request to array this.requests, before the request will be send
         * @param {Object} request
         */
        onBeforeRequest: function (request) {
            this.add(request);
        },
        /**
         * Remove request from array this.requests after complete request
         * @param {Object} request
         */
        onRequestComplete: function (request) {
            this.remove(request);
        }
    };

    if (typeof GUI === 'undefined') {
        GUI = {};
    }
    if (typeof GUI.Ajax === 'undefined') {
        GUI.Ajax = {};
    }


}());