(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Mask implementation
     *
     * @author S. [LinuZz] Ostapenko
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.Popup
     * @extends GUI.Utils.Observable
     */
    GUI.Popup.Mask = Class.create();
    Object.extend(GUI.Popup.Mask.prototype, superproto);

    /**
     *
     */
    GUI.Popup.Mask.prototype.animate = window.GUI_FX_ANIMATION === true;

    /**
     * Css name of the element, default is 'b-mask'
     * @type String
     */
    GUI.Popup.Mask.prototype.className = 'b-mask';

    /**
     * Default is Loading...
     * @type String
     */
    GUI.Popup.Mask.prototype.text = i18n('Loading...');

    /**
     * Type of the element, default is 'GUI.Popup.Mask'
     * @type String
     */
    GUI.Popup.Mask.prototype.elementType = 'GUI.Popup.Mask';

    /**
     * z-index mask position. Default zIndex 100
     * @type Null
     */
    GUI.Popup.Mask.prototype.zIndex = 100;

    /**
     * Stop show mask element. Default false
     * @type Boolean
     */
    GUI.Popup.Mask.prototype.disabled = false;

    /**
     * Template of the element
     * @type String
     */
    GUI.Popup.Mask.prototype.template    =
        '<div id="{0}" class="b-mask b-mask__loader">' +
        '<div class="b-mask__loader__i">' +
        '<span class="b-mask__loader-txt">{1}</span>' +
        '<span class="b-mask__loader-icon"></span>' +
        '</div>' +
        '</div>';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Popup.Mask.prototype.initialize = function (config) {
        if (GUI.isIE) {
            this.animate = false;
        }
        if (config) {
            Object.extend(this, config);
        }
        this.addEvents({
            show : true,
            hide : true
        });
        // Call parent initialize meethod
        superproto.initialize.call(this, config);
        this.maskGlassId = GUI.getUniqId('mask-layout-');
    };

    /**
     * If state of the animator is true hides mask, or show.
     * @param {Object} anim Animator object
     */
    GUI.Popup.Mask.prototype.onAnimatorComplete = function (anim) {
        if (anim.state === 0) {
            this._hide();
        } else {
            this._show();
        }
    };

    /**
     * Sets element
     * @param {HTMLElement} element
     */
    GUI.Popup.Mask.prototype.setElement = function (element) {
        this.element = element;
    };

    /**
     * Inits region, animator
     */
    GUI.Popup.Mask.prototype.initRegion = function () {
        var content;

        if (this.background) {
            content = '';
            this.className = 'b-mask b-mask_overlay';
        } else {
            content = this.template.formatArr([this.maskGlassId, this.text]);
        }
        this.region = new GUI.Popup.Region({
            className   : this.className,
            zIndex      : this.zIndex,
            content     : content
        });

        if (this.animate) {
            this.fadeEf = new Animator({
                duration    : 500,
                interval    : 20,
                scope       : this,
                onComplete  : this.onAnimatorComplete
            });
        }
    };

    /**
     * Shows the element
     */
    GUI.Popup.Mask.prototype.show = function () {
        if (this.disabled === true) {
            return;
        }

        if (!this.region) {
            this.initRegion();
        }

        var elem;
        if (this.element) {
            elem = GUI.$(this.element);
            if (elem) {
                this.region.config.parentNode = elem;
            }
        }
        if (!elem) {
            console.log('Mask: No element... ', this.element);
            return false;
        }

        this.region.show();
        GUI.unselectable(this.region.dom);

        if (this.animate) {
            this.fadeEf.clearSubjects();
            this.fadeEf.addSubject(new window.NumericalStyleSubject(this.region.getElement(), 'opacity', 0.1, 1));
            this.fadeEf.seekTo(1);
        } else {
            GUI.setOpacity(this.region.getElement(), 1); // leaks
            this._show();
        }
    };

    /**
     * Fires event 'show'
     */
    GUI.Popup.Mask.prototype._show = function () {
        this.fireEvent('show', this);
    };

    /**
     * Hides the element
     */
    GUI.Popup.Mask.prototype.hide = function () {
        if (this.animate) {
            this.fadeEf.seekTo(0);
        } else {
            this._hide();
        }
    };

    /**
     * Hides region, fires event 'hide'
     */
    GUI.Popup.Mask.prototype._hide = function () {
        if (!this.region) {
            return;
        }

        this.region.hide();
        this.fireEvent('hide', this);
    };

    /**
     * Stop show element
     * @param {Boolean} isDisabled
     */
    GUI.Popup.Mask.prototype.disable = function (isDisabled) {
        this.disabled = isDisabled;
        if (isDisabled === true && this.region) {
            this.hide();
        }
    };

    /**
     * Destroys objects
     */
    GUI.Popup.Mask.prototype.destroy = function () {
        this.purgeListeners();
        if (this.fadeEf) {
            this.fadeEf.destroy();
            this.fadeEf = null;
        }
        if (this.region) {
            this.region.destroy();
            this.region = null;
        }
        this.element = null;
    };

}());