(function () {
    var superproto = GUI.Forms.TriggerField.prototype;

    /**
    * JavaScript Graphical User Interface
    * Forms.Combo implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.TriggerField
    */
    GUI.Forms.Combo = Class.create();
    Object.extend(GUI.Forms.Combo.prototype, superproto);

    /**
     * Information about current form element
     * @type String
     */
    GUI.Forms.Combo.prototype.type = 'combo';

    /**
     * Height of the list, default is 14 items
     * @type String
     */
    GUI.Forms.Combo.prototype.listHeight = 8;

    /**
     * Width of the list, default is 'auto'
     * @type String
     */
    GUI.Forms.Combo.prototype.listWidth = 'auto';

    /**
     * Css class of the list message, default is ''
     * @type String
     */
    GUI.Forms.Combo.prototype.listMessageClass = '';

    /**
     * Css class of the list, default is 'b-popup-menu b-popup-menu_main-menu'
     * @type String
     */
    GUI.Forms.Combo.prototype.listClass = 'b-popup-menu b-popup-menu_main-menu b-popup-menu_style_combo';

    /**
     * Css class of the item, default is 'b-popup-menu__item'
     * @type String
     */
    GUI.Forms.Combo.prototype.itemClass = 'b-popup-menu__item';

    /**
     * Css class of the selected item, default is 'b-text-field_advanced_focus'
     * @type String
     */
    GUI.Forms.Combo.prototype.selectedClass = 'b-text-field_advanced_focus';

    //GUI.Forms.Combo.prototype.focusClassForDom = 'text-field_advanced_focus';

    /**
     * Css class of the text field
     */
    GUI.Forms.Combo.prototype.textFieldClass = '';

    /**
     * Css class of the hint, default is ''
     * @type String
     */
    GUI.Forms.Combo.prototype.hintClass = '';

    /**
     * Css class of the combo, when it is invalid, default is ''
     * @type String
     */
    GUI.Forms.Combo.prototype.invalidClass = '';

    /**
     * Offset x for hint, default is -7
     * @type Number
     */
    GUI.Forms.Combo.prototype.hintOffsetX = -7;

    /**
     * Offset y for hint, default is 2
     * @type Number
     */
    GUI.Forms.Combo.prototype.hintOffsetY = 2;

    /**
     * Record property that specifies value of the item, default is 'value'
     * @type String
     */
    GUI.Forms.Combo.prototype.valueField = 'value';

    /**
     * Record property that specifies html text of the item in the popup list, default is 'text'
     * @type String
     */
    GUI.Forms.Combo.prototype.listField = 'text';

    /**
     * Record property that specifies text of the item displayed in the textfield, default is 'text'
     * @type String
     */
    GUI.Forms.Combo.prototype.textField = 'text';

    /**
     * Record property that specifies desciption of the item in list, default is 'description'
     * @type String
     */
    GUI.Forms.Combo.prototype.descriptionField = 'description';

    /**
     * Read-only attribute, default is false
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.readOnly = false;

    /**
     * Index of the selected item, default is -1
     * @type Number
     */
    GUI.Forms.Combo.prototype.selectedIndex = -1;

    /**
     * If true hidden is available, default is true
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.hiddenInput = true;

    /**
     * Sets width to auto, default is false
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.autoWidth = false;

    /*
     *
     */
    GUI.Forms.Combo.prototype.autoWidthOnBlur = false;

    /**
     * default is true
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.autoReapplyAutoWidth = true;

    /**
     * default is 100
     * @type Number
     */
    GUI.Forms.Combo.prototype.minWidth = 100;

    /**
     * default is 200 if less 1200, 400 if more
     * @type Number
     */
    GUI.Forms.Combo.prototype.maxWidth = GUI.getWidth() < 1200 ? 300 : 600;

    /**
     * default is 150
     * @type Number
     */
    GUI.Forms.Combo.prototype.width = 150;

    /**
     * Allow deselet tems, default is false
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.allowDeselect = false;

    /**
     * Default is 'mousedown'
     * @type String
     */
    GUI.Forms.Combo.prototype.triggerEvent = 'mousedown';

    /**
     *
     */
    GUI.Forms.Combo.prototype.triggerElClassName = 'i.b-text-field__icon';

    /**
     * Value, default is ''
     * @type String
     */
    GUI.Forms.Combo.prototype.value = '';

    /**
     * After clear field value, set default value
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.autoSetDefaultValue = true;

    /**
     * Dom template
     * @type String
     */
    GUI.Forms.Combo.prototype.domTemplate =
        '<i id="{0}" class="b-text-field_advanced b-text-field_combo {1}">' +
        '<i class="b-text-field__icon dropdown"></i>' +
        '<i class="b-text-field__icon {3}"><i class="s-icon"></i></i>' +
        '<i class="i-text-field">' +
        '<i class="b-text-field b-text-field_clone {2}"></i>' +
        '</i>' +
        '</i>';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.autocomplete = false;

    /**
     * If item focused it will be selected, default is true
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.selectOnFocus = true;

    /**
     * Show list when click on item, default is true
     *  (need to set to false when autocomplete is true)
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.showListOnClick = true;

    /**
     * Min characters for begin search, default is 1
     * @type Number
     */
    GUI.Forms.Combo.prototype.minSearchCharacters = 1;

    /**
     * Delay before search, default is 0
     * @type Number
     */
    GUI.Forms.Combo.prototype.searchDelay = 1;

    /**
     * Params for search
     * @type String
     */
    GUI.Forms.Combo.prototype.searchParam = 'query';

    /**
     * Field with the results, default is 'results'
     * @type String
     */
    GUI.Forms.Combo.prototype.resultsField = 'results';

    /**
     * Default is true
     * @type String
     */
    GUI.Forms.Combo.prototype.typeAhead = true;

    /**
     * What type search use for auto completer filter.
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.advancedSearch = false;

    /**
     * Fileds for search
     * @type Array
     */
    GUI.Forms.Combo.prototype.searchFields = [];

    /**
     * Default is true
     * @type String
     */
    GUI.Forms.Combo.prototype.substituteValue = true;

    /**
     * Show hint for long text field. Set false to disable it
     * @type Boolean
     */
    GUI.Forms.Combo.prototype.showHintsForLongText = true;

    GUI.Forms.Combo.prototype.alignTo = 'tl-bl?';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.Combo.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component, add events 'listShow', 'listHide', 'beforeSelect', 'select',
     * init selection model, search task
     */
    GUI.Forms.Combo.prototype.initComponent = function () {
        superproto.initComponent.call(this);

        this.addEvents({
            listShow    : true,
            listHide    : true,
            beforeSelect: true,
            select      : true,
            emptyField  : true,
            clickOpenList: true
        });

        this.itemSelector = 'li.' + this.itemClass;

        this.initSelectionModel();

        if (GUI.isArray(this.options)) {
            var options = this.options;
            this.options = new GUI.Utils.Collection();

            this.suspendEvents();
            this.setOptions(options);
            this.resumeEvents();
        } else {
            this.options = new GUI.Utils.Collection();
        }

        this._listItems = this.options.items; // by def
        this._lastInputKey = '';

        this.searchTask = new GUI.Utils.DelayedTask(this.doSearch, this);
    };

    /**
     * Returns config of the default selection model
     * @returns {Object} Config of the selection model
     */
    GUI.Forms.Combo.prototype.getDefaultSmConfig = function () {
        return {
            singleSelect: true
        };
    };

    /**
     * Init selection model
     */
    GUI.Forms.Combo.prototype.initSelectionModel = function () {
        if (!this.sm) {
            this.sm = new GUI.Forms.Combo.SelectionModel(this.getDefaultSmConfig());
        }
        this.sm.init(this);
    };

    /**
     * If nodename is 'Select' and not multiple returns true
     * @param {HTMLElement} dom Dom
     * @returns {Boolean}
     */
    GUI.Forms.Combo.prototype.onBeforeAssign = function (dom) {
        return dom.nodeName === 'SELECT' && !dom.multiple;
    };

    /**
     *
     */
    GUI.Forms.Combo.prototype.onAssign =  function (elem) {
        superproto.onAssign.call(this, elem);
        var listWidth, listHeight, minWidth, maxWidth, option, len, optionText, hint, hintposition,
            options = [], icons, optionIcon, optionImg, optionDisabled, title, placeholder,
            i = 0;

        this.autoWidth = !!elem.getAttribute('autoWidth');
        listWidth = elem.getAttribute('listWidth');
        if (GUI.isString(listWidth) && listWidth.length) {
            if (listWidth === 'auto') {
                this.listWidth = 'auto';
            } else {
                this.listWidth = parseInt(listWidth, 10);
            }
        }

        listHeight = elem.getAttribute('listHeight');
        if (GUI.isString(listHeight) && listHeight.length) {
            this.listHeight = parseInt(listHeight, 10);
        }

        minWidth = elem.getAttribute('minWidth');
        if (GUI.isSet(minWidth)) {
            this.minWidth = parseInt(minWidth, 10);
        }

        maxWidth = elem.getAttribute('maxWidth');
        if (GUI.isSet(maxWidth)) {
            this.maxWidth = parseInt(maxWidth, 10);
        }

        icons = elem.getAttribute('icons');
        if (GUI.isSet(icons)) {
            this.icons = true;
        }

        title = elem.getAttribute('title');
        if (GUI.isSet(title)) {
            this.title = title;
        }

        hint = elem.getAttribute('hint');
        if (GUI.isSet(hint)) {
            this.hint = hint;
        }

        placeholder = elem.getAttribute('placeholder');
        if (GUI.isSet(placeholder)) {
            this.placeholder = placeholder;
        }

        // Load options
        options = elem.options;
        this.suspendEvents();
        this.clear();
        for (i = 0, len = options.length; i < len; i++) {
            option = options[i];

            if (option.getAttribute('separator')) {
                this.add({separator: true});
                continue;
            }

            optionText = option.getAttribute('text');
            optionIcon = option.getAttribute('icon');
            optionImg = option.getAttribute('img');
            optionDisabled = option.getAttribute('disabled');
            hint = option.getAttribute('hint');
            hintposition = option.getAttribute('hintposition');

            var attrKey,
                params = {};
            for (attrKey = 0; attrKey < option.attributes.length; attrKey++) {
                params[option.attributes[attrKey].nodeName] = option.attributes[attrKey].nodeValue;
            }

            this.add(Object.extend(params, {
                text    : optionText || option.text,
                value   : option.value,
                icon    : optionIcon || option.icon,
                img     : optionImg || option.img,
                disabled : optionDisabled || false,
                hint    : hint,
                hintPosition: hintposition,
                selected: option.defaultSelected || option.selected // Shit... Browsers sets selected on onLoad, but we  run after domload :-(. So defaultselected = true and selected = false...
            }));
        }
        this.resumeEvents();
    };

    /**
     * Handler after render, add event 'focus' 'blur'
     * @param {HTMLElement} dom Dom
     */
    GUI.Forms.Combo.prototype.onAfterRender = function (dom) {
        var focusEl;
        this.iconEl = GUI.Dom.findDescedents(dom, 'i.b-text-field__icon ' + this.iconClass)[0];
        this.divEl = GUI.Dom.findDescedents(dom, 'i.b-text-field b-text-field_clone')[0];

        if (!GUI.LTR) {
            this.divEl.setAttribute('dir', 'auto');
        }

        focusEl = GUI.Dom.extend(dom.getElementsByTagName('input')[0]);
        focusEl.addClass(this.textFieldClass);
        focusEl.on('focus', this.onFocus, this);
        focusEl.on('blur', this.onBlur, this);
        this.focusEl = focusEl;

        superproto.onAfterRender.call(this, dom);

        this.fieldEl.on('focus', this.focus, this);
        this.fieldEl.on('blur', this.blur, this);

        if (GUI.isMobile && !this.autocomplete) {
            this.fieldEl.setAttribute('readonly', true);
        }

        if (this.title) {
            this.dom.setAttribute('title', this.title);
        }

        if (this.autocomplete && this.placeholder) {
            this.fieldEl.setAttribute('placeholder', '');
        }

        if (this.autoWidth) {
            this.doAutoWidth();
        }

        if (this.hint) {
            this.dom.setAttribute('hint', this.hint);
        }

        if (this.hintposition) {
            this.dom.setAttribute('hintposition', this.hintposition);
        }

        if (this.unselectable) {
            if (GUI.isIE) {
                this.dom.unselectable = 'on';
                this.divEl.unselectable = 'on';
            }
        }
    };

    /**
     * Handler on blur, hide text field, cancel search task, clear list message
     */
    GUI.Forms.Combo.prototype.onBlur = function () {
        this.dom.removeClass(this.selectedClass);

        if (!this.listOpened) {
            superproto.onBlur.call(this);
            if (this.autocomplete && this.textFieldVisible) {
                this.hideTextField();
                this.searchTask.cancel();
                if (this._searchRequest && !this._searchRequest.complete) {
                    this._searchRequest.abort();
                    this.clearListMessage();
                }
            }
        }
    };

    /**
     * Clear items dom, destroy list
     * @param {boolean} fast
     */
    GUI.Forms.Combo.prototype.onDestroy = function (fast) {
        this.clearItemsDom();

        this.listFocusEl = this.listHolder = null;

        if (this.listOpened) {
            this.hideList();
        }
        if (this.list) {
            this.list.destroy();
            this.list = null;
        }

        this.divEl = this.focusEl = this.triggerEl = null;

        superproto.onDestroy.call(this, fast);
    };

    /**
     * Attach events listeners, on divEl 'mouseenter', 'mouseleave', 'click',
     * on trigger 'mouseover', 'mouseout', 'mousedown', 'keydown', 'keyup', 'click'.
     * Applies default text
     */
    GUI.Forms.Combo.prototype.attachEventListeners = function () { // overridden
        this.divEl.on('click',  this.onMouseDown, this);
        this.divEl.on('click',  this.onClick,    this);

        this.iconEl.on('click',  this.onMouseDown, this);
        this.iconEl.on('click',  this.onClick,    this);

        this.keyboardEvent = new GUI.Utils.KeyboardEvents(this.dom, {
            anyKey: true
        });

        this.keyboardEvent.on(GUI.Utils.keys.anyKey, this.onTriggerKeyDown, this);


        this.triggerEl.on(this.triggerEvent, this.onTriggerClick,     this);
        this.triggerEl.on('click', this.preventDefaultEvent);

        if (this.triggerOnFieldClick) {
            this.fieldEl.on(this.triggerEvent, this.onTriggerClick,     this);
        }

        if (this.defaultText) {
            this.on('blur',  this.postBlur, this);
            this.on('focus', this.preFocus, this);
            this.applyDefaultText();
        }
    };

    /**
     * Value manipulation functions
     * @returns {String} value
     */
    GUI.Forms.Combo.prototype.getValue = function () {
        if (this.valueField) {
            return typeof this.value !== 'undefined' ? this.value : ''; // CHD-2115
            //return typeof this.value !== 'undefined' ? GUI.html_entity_decode(this.value) : ''; // CHD-2115
        } else {
            return superproto.getValue.call(this);
        }
    };

    /**
     * Set selected item to false
     * @param {Object} item Item
     */
    GUI.Forms.Combo.prototype._resetSelectedPropertyItem = function (item) {
        item.selected = false;
    };

    /**
     * Deselect all options
     */
    GUI.Forms.Combo.prototype.resetSelectedProperty = function () {
        this.options.each(this._resetSelectedPropertyItem);
    };

    /**
     * Sets value
     * @param {String} value New value
     * @param {Boolean} quiet
     */
    GUI.Forms.Combo.prototype.setValue = function (value, quiet) {
        if (this.fieldEl) {
            this.fieldEl.removeClass(this.defaultTextClass);
        }

        var record, oldValue, span,
            text = value;

        this.resetSelectedProperty();

        if (this.valueField) {
            record = this.findRecord(this.valueField, value);

            if (record) {
                text = record[this.textField];
                this.selectedIndex = this.options.indexOf(record);
                this.sm.selectItem(record, false, true);

                if (this.iconEl) {
                    if (record.icon) {
                        this.iconEl.className = "b-text-field__icon icon " + record.icon;
                        this.iconEl.style.display = '';
                        this.iconEl.innerHTML = '<i class="s-icon"></i>';

                    } else if (record.img) {
                        this.iconEl.innerHTML = '<img src="' + record.img + '">';
                        this.iconEl.style.display = '';

                    } else {
                        this.iconEl.style.display = 'none';
                    }
                }

            } else {
                if (this.valueNotFoundText !== undefined) {
                    text = this.valueNotFoundText;
                }
                if (this.iconEl) {
                    this.iconEl.style.display = 'none';
                }

                this.selectedIndex = -1;
            }
        }
        this.lastSelectionText = text;

        oldValue = this.getValue();

        if (this.hiddenField) {
            this.hiddenField.value = value;
        }

        // Added startValue to overcome bug, when 'change' event is fired twice: on select and on blur
        this.value = this.startValue = value;

        if (this.divEl) {
            this.divEl.removeClass('b-txt_gray');

            if (record) {
                span = record[this.listField].stripTags() || '';

            } else if (this.placeholder) {
                span = this.placeholder;
                this.divEl.addClass('b-txt_gray');
                //this.divEl.style.display = '';
            }
            this.divEl.innerHTML = span || '';
        }

        if (this.fieldEl) {
            this.fieldEl.value = (text === null || text === undefined) ? '' : GUI.html_entity_decode(text); // CHD-2115
        }

        if (!quiet && (value !== oldValue)) {
            this.fireEvent('change', this, value, oldValue);
        }

        if (this.autoWidthOnBlur) {
            this.doAutoWidth(true);
        }
    };

    GUI.Forms.Combo.prototype.reset  = function () {
        if (this.dom) {
            superproto.reset.call(this);

            if (this.icons && this.iconClass) {
                this.iconEl.className = 'b-text-field__icon ' + this.iconClass;
                this.iconEl.style.display = '';
                this.iconEl.innerHTML = '<i class="s-icon"></i>';
            }

            this.hideTextField();
        }
    };

    /**
     * Sets raw value
     */
    GUI.Forms.Combo.prototype.setRawValue  = function (value) {
        if (this.divEl) {
            this.divEl.innerHTML = value === null || value === undefined ? '' : value;
        }
        if (this.fieldEl) {
            this.fieldEl.value = value;
        }
    };

    /**
     * Returns raw value
     * @returns {String} value
     */
    GUI.Forms.Combo.prototype.getRawValue = function () {
        if (this.fieldEl && this.textFieldVisible) {
            return this.fieldEl.value;
        }
        return (this.divEl) ? this.divEl.innerHTML : '';
    };

    /**
     * Set value to ''
     */
    GUI.Forms.Combo.prototype.clear = function () {
        this.options.clear();
        this.setValue('', true);
    };

    /**
     * Add option
     * @param {Object} option Option
     */
    GUI.Forms.Combo.prototype.add = function (option) {
         // Force passing by value, not by reference!
        option = Object.clone(option);

        if (!option.id) {
            option.id = GUI.getUniqId('');
        }
        option.domId = this.id + '-item-'  + option.id;
        this.options.add(option);
        this.onItemAdd(option);
        if (option.selected) {
            this.sm.selectItem(option, true);
        }
        // remove all filters, new options arrived
        this._listItems = this.options.items;
    };

    /**
     * Sets options
     * @param {Array} options Array of options
     */
    GUI.Forms.Combo.prototype.setOptions = function (options) {
        var i = 0,
            len;

        this.options.clear();
        this.sm.clearSelections();
        if (options.length <= 0) {
            this.updateList([]);
        }
        for (i = 0, len = options.length; i < len; i++) {
            this.add(options[i]);
        }

        if (this.autoWidth && this.autoReapplyAutoWidth && this.dom) {
            this.doAutoWidth();
        }

        if (this.listOpened) {
            this.updateList();
        }
    };

    /**
     * Returns value of the field
     * @returns {String} value
     */
    GUI.Forms.Combo.prototype.getValueField = function () {
        return this.valueField || this.textField;
    };

    /**
     * Returns value of the option
     * @patam {Object} option Option
     * @returns {String} value
     */
    GUI.Forms.Combo.prototype.getOptionValue = function (option) {
        return option[this.getValueField()];
    };

    /**
     * Returns record
     * @param {Object} field Field
     * @param {String} value Value
     * @returns {Boolean} record is present
     */
    GUI.Forms.Combo.prototype.findRecord = function (field, value) {
        return this.options.find(function (item) {
            return (item[field] + '') === (value + '');
        });
    };

    /**
     * Returns item by dom id
     * @param {String} id Dom id
     * @returns {Object} record
     */
    GUI.Forms.Combo.prototype.getItemByDomId = function (id) {
        return this.findRecord('domId', id);
    };

    /**
     * Search item by value
     * @param {String} value Value for search
     * @returns {Object} items
     */
    GUI.Forms.Combo.prototype.getItemByValue = function (value) {
        return this.findRecord(this.getValueField(), value);
    };

    /**
     * Returns index of the value
     * @param {String} value Value of item
     * @returns {Number} index
     */
    GUI.Forms.Combo.prototype.getIndexByValue = function (value) {
        var r = this.findRecord(this.valueField || this.textField, value);
        return this.options.indexOf(r);
    };

    /**
     * Select item
     * @param {Number} index Index of item
     */
    GUI.Forms.Combo.prototype.selectItem = function (index) {
        this.select(parseInt(index, 10));
    };

    /**
     * Select option with value
     * @param {String} value Value
     */
    GUI.Forms.Combo.prototype.select = function (value, quiet) {
        if (GUI.isNumber(value)) {
            var r = this.options.itemAt(value);
            this.setValue(r ? this.getOptionValue(r) : '', quiet);
        } else {
            this.setValue(value, quiet);
        }
    };

    /**
     * Hide hint
     */
    GUI.Forms.Combo.prototype.hideHint = function () {
        if (this.hintList && this.hintList.getVisibility()) {
            this.hintList.dom.un();
            this.hintList.hide();
            this.hintAssignedNode = null;
        }
    };

    /**
     * Returns node of the hint
     * @returns {HTMLElement} node
     */
    GUI.Forms.Combo.prototype.getHintAlignNode = function (node) {
        return node;
    };

    /**
     * Set content of the hint, show it, add event 'click' on dom
     * @param {Object} node Node of hint
     * @param {String} text Text of the hint
     * @param {String} descr Description of the hint
     */
    GUI.Forms.Combo.prototype.showHint = function (node, text, descr) {
        if (this.showHintsForLongText === false) {
            return false;
        }

        if (!this.hintList) {
            this.hintList = new GUI.Popup.Region({
                className: 'b-hint b-hint_popup'
            });
        }

        if (this.hintList.getVisibility()) {
            this.hideHint();
        }

        this.hintList.setContent(GUI.Popup.Hint.renderHint({
            text    : text
        }));

        //this.hintList.alignTo(this.getHintAlignNode(node), 'l-l', [ this.hintOffsetX, this.hintOffsetY ]);
        this.hintList.alignTo(
            this.getHintAlignNode(node),
            'tl-tl',
            [ this.hintOffsetX, this.hintOffsetY ]
        );
        this.hintList.show();

        this.hintAssignedNode = node;
        // Attach events to proxy
        var dom = this.hintList.dom;
        GUI.Dom.extend(dom);
        dom.on('click', this.onHintClick, this);
    };

    /**
     * Reroute event
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onHintClick = function (e) {
        GUI.Event.fire(this.hintAssignedNode, e.type, {});
    };

    /**
     * Returns dom of the list
     * @returns {HTMLElement} dom
     */
    GUI.Forms.Combo.prototype.getListDom = function () {
        return this.list && GUI.Dom.findChild(this.list.dom, 'ul');
    };

    /**
     * Add focus on the element.(Overridden)
     */
    GUI.Forms.Combo.prototype.focus = function () {
        this.dom.addClass(this.selectedClass);

        if (this.fieldEl && this.fieldEl.hasClass(this.defaultTextClass)) {
            this.fieldEl.removeClass(this.defaultTextClass);
        }

        this.fieldEl.focus();
    };

    /**
     * Remove css class
     */
    GUI.Forms.Combo.prototype.blur = function () {
        this.dom.removeClass(this.selectedClass);
    };

    /**
     * Defer focus on 20
     */
    GUI.Forms.Combo.prototype.deferFocus = function () {
        this.focus.defer(20, this);
    };

    /**
     * Add focus to node
     * @param {HTMLElement} node Node
     * @param set_value
     * @param direction
     */
    GUI.Forms.Combo.prototype.focusItem = function (node, set_value, direction) {
        var item;

        if (!direction && this.getItemByDomId(node.id).disabled) {
            return;
        } else if (direction && this.getItemByDomId(node.id).disabled) {
            if (this.selectedId) {
                if (this.selectedId !== node.id) {
                    this.hideHint();
                }
                item = this.getItemByDomId(this.selectedId);
                if (!item.selected) {
                    GUI.$(this.selectedId).removeClass('selected');
                }
            }
            this.selectedId = node.id;

            if (direction === 'next') {
                this.focusNext();
            } else if (direction === 'prev') {
                this.focusPrev();
            }

            return;
        }

        if (this.selectedId) {
            if (this.selectedId !== node.id) {
                this.hideHint();
            }
            item = this.getItemByDomId(this.selectedId);
            if (!item.selected) {
                GUI.$(this.selectedId).removeClass('selected');
            }
        }
        this.selectedId = node.id;
        GUI.$(this.selectedId).addClass('selected');
        this.onFocusItem(node, set_value);
    };

    /**
     * Add focus to node
     * @param {HTMLElement} node Node
     */
    GUI.Forms.Combo.prototype.blurItem = function (node) {
        var item;

        item = this.getItemByDomId(node.id);
        if (!item.selected) {
            GUI.$(node.id).removeClass('selected');
        }
    };

    /**
     * Scroll to node, show hint
     * @param {HTMLElelemnt} node Node
     */
    GUI.Forms.Combo.prototype.onFocusItem = function (node, set_value) {
        GUI.scrollIntoView(this.getListDom(), node, 'v');
        var item = this.getItemByDomId(node.id);
        if (item) {
            // Need to show hint if item content is clipped...
            if (node.scrollWidth > node.offsetWidth) {
                this.showHint(node, item[this.listField], item[this.descriptionField]);
            }
            if (set_value) {
                this.setValue(item.value);
            }
        }
    };

    /**
     * Add focus to the first tem
     */
    GUI.Forms.Combo.prototype.focusFirst = function () {
        var node = this.getListDom().findChild(this.itemSelector);
        if (node) {
            this.focusItem(node, false);
        }
    };

    /**
     * Add focus to the next item
     */
    GUI.Forms.Combo.prototype.focusNext = function () {
        if (!this.selectedId) {
            this.focusFirst();
        } else {
            var nextNode = GUI.Dom.findNextSibling(GUI.$(this.selectedId), this.itemSelector)[0];
            if (nextNode) {
                this.focusItem(nextNode, false, 'next');
            }
        }
    };

    /**
     * Add focus to the prev item
     */
    GUI.Forms.Combo.prototype.focusPrev = function () {
        if (!this.selectedId) {
            this.focusLast();
        } else {
            var prevNode = GUI.Dom.findPrevSibling(GUI.$(this.selectedId), this.itemSelector)[0];
            if (prevNode) {
                this.focusItem(prevNode, false, 'prev');
            }
        }
    };

    /**
     * Add focus to the last item
     */
    GUI.Forms.Combo.prototype.focusLast = function () {
        var items = this.getListDom().findChild('li.' + this.itemClass);
        if (items && items.length > 0) {
            this.focusItem(items[items.length - 1], false);
        }
    };

    /**
     * Returns index of the item
     * @param {HTMLElement} node Node of item
     * @returns {Number} index of item
     */
    GUI.Forms.Combo.prototype.getItemIndex = function (node) {
        var item = this.getItemByDomId(node.id);
        if (item) {
            return this.options.indexOf(item);
        }
        return -1;
    };

    /**
     * Sets combo width as width of the widest list item
     */
    GUI.Forms.Combo.prototype.doAutoWidth = function (useDom) {
        var needReopen = false,
            width;

        if (useDom) {
            this.dom.style.width = '';
            width = GUI.measureWidth(this.divEl, {padding: '0 8px 0 9px'});

        } else {
            if (this.listOpened) {
                this.hideList();
                needReopen = true;
            }
            // Generate list
            this.initList();
            this.list.config.dimensions.width = null; // unset width before show
            this.list.setPosition(-10000, -10000);
            this.list.show();

            // Measure its width
            width = this.list.getWidth();

            // Hide
            this.list.hide();
        }

        // Add trigger and borders/paddings width
        width = this.adjustAutoWidth(width);

        // add paddings for icon
        if (this.icons) {
            width += 27;
        }
        // Constrain min/maxwidth
        width = width.constrain(this.minWidth, this.maxWidth);

        // Set automatically calculated width
        this.setWidth(width);

        if (needReopen) {
            this.showList();
        }
    };

    /**
     * Add 17 px to width...
     * @param {Number} width Width
     */
    GUI.Forms.Combo.prototype.adjustAutoWidth = function (width) {
        return width + 18 - 1;
    };

    /**
     * Returns html of the item
     * @param {Object} item Item
     * @returns {HTMLElement} doms
     */
    GUI.Forms.Combo.prototype.getListItemHtml = function (item) {
        var itemHtml,
            title = '',
            hint = '',
            hinttype = '',
            hintposition = '',
            disabled = '',
            labelClass = item.className ? ' ' + item.className : '',
            itemClass = this.itemClass;

        if (item.selected && !item.noSelect) {
            itemClass += ' selected';
        }
        if (item.noSelect) {
            itemClass += ' noselect';
        }

        itemHtml = '<span class="b-popup-menu__button"' + (!GUI.LTR ? ' dir="auto"' : '') + '>';

        if (item.icon) {
            itemHtml += '<i class="icon ' + item.icon + '" ></i>';
        }

        if (item.img) {
            itemHtml += '<i class="icon"><img src="' + item.img + '" alt=""></i>';
        }

        if (item.title) {
            title = ' title="' + item.title + '" ';
        }

        if (item.hint) {
            hint = ' hint="' + item.hint + '"';
        }

        if (item.hinttype) {
            hinttype = ' hinttype="' + item.hinttype + '"';
        }

        if (item.hintposition) {
            hintposition = ' hintposition="' + item.hintposition + '"';
        }

        if (item.disabled) {
            disabled = ' disabled';
            itemClass += ' disabled';
        }

        if (item.itemClass) {
            itemClass += ' ' + item.itemClass;
        }

        itemHtml += '<span class="label' + labelClass + '">' + item[this.listField] + '</span>';

        if (GUI.isSet(item[this.descriptionField])) {
            itemHtml += '<span class="description">' + item[this.descriptionField] + '</span>';
        }
        itemHtml += '</span>';

        return '<li id="' + item.domId + '" class="' + itemClass + '" ' + title + hint + hinttype + hintposition + disabled + '>' + itemHtml + '</li>';
    };

    /**
     * Returns html of the list
     * @param {Array} items List of items
     * @returns {HTMLElement} html
     */
    GUI.Forms.Combo.prototype.getListHtml = function (items) {
        // Generate list html
        var html = new GUI.StringBuffer(),
            hasMessage,
            listMessageClass = this.listMessageClass,
            i = 0,
            len = 0;

        hasMessage = GUI.isSet(this.listMessage) && this.listMessage !== '';

        if (this.icons) {
            listMessageClass += ' b-popup-menu__list-message b-popup-menu_with-icons ';
        }

        html.append('<div class="' + listMessageClass + '" style="display: ' +
            (hasMessage ? 'block' : '') + ';">' + (hasMessage ? this.listMessage : '') + '</div>');

        items = items || this._listItems;

        html.append('<ul class="b-popup-menu__list ');

        if (this.icons) {
            html.append(' with-icons ');
        }
        html.append('">');

        for (i = 0, len = items.length; i < len; i++) {
            html.append(this.getListItemHtml(items[i]));
        }
        html.append('</ul>');
        return html.toString();
    };

    /**
     * Create list, update it
     * @param {Array} items List of items
     */
    GUI.Forms.Combo.prototype.initList = function (items) {
        if (!this.list) {
            this.list = new GUI.Popup.Region({
                className   : this.listClass,
                parentNode  : this.listHolder || document.body,
                border      : '1'
            });
        }

        if (GUI.isNumber(this.listWidth)) {
            this.list.config.dimensions.width = this.listWidth + 2;
        }

        this.updateList(items);
    };

    /**
     * Sets content, sets the focus to the last focused if it is
     */
    GUI.Forms.Combo.prototype.updateList = function (items) {
        if (this.list) {
            this.list.setContent(this.getListHtml(items));
        }
        var lastFocusedNode = GUI.$(this.selectedId);
        if (lastFocusedNode) {
            this.onFocusItem(lastFocusedNode, false);
        } else {
            this.selectedId = null;
        }
        if (this.listOpened) {
            // Realign if list is opened
            this.list.alignTo(this.dom, 'tl-bl?', [-1, 0]);
        }
    };

    /**
     * List show, add events 'mouseover', 'mouseout', 'mousedown', 'click',
     * 'keydown', 'keyup' on dom,  and event 'mousedown' on document
     * @param {Array} items List of the items
     */
    GUI.Forms.Combo.prototype.showList = function (items) {
        this.initList(items);
        if (!this.listOpened) {

            if (this.listWidth === 'auto') {
                this.list.config.dimensions.width = this.dom.offsetWidth + 2;
            } else if (GUI.isNumber(this.listWidth)) {
                this.list.config.dimensions.width = this.listWidth + 2;
            }

            this.list.show();

            var el_a, item, value, selItem,
                dom = this.list.dom;

            GUI.Dom.extend(dom);

            if (this.listWidth === 'content' && GUI.isIE) {
                dom.style.visibility = 'hidden';
                setTimeout(function () {
                    GUI.setFullWidth(dom, dom.offsetWidth);
                    dom.style.visibility = '';
                }, 1);
            }

            // Add focus link
            el_a = document.createElement('a');
            el_a.className = 'g-focus';
            el_a.href = '#';
            el_a.tabIndex = -1;
            dom.appendChild(el_a);
            el_a.innerHTML = '&#160;';
            this.listFocusEl = GUI.Dom.extend(el_a);

            jQuery(dom).on('mouseenter', 'li.b-popup-menu__item', this.onListMouseOver.bindLegacy(this));
            jQuery(dom).on('mouseleave', 'li.b-popup-menu__item', this.onListMouseOut.bindLegacy(this));
            dom.on('mousedown', this.onListMouseDown, this);
            dom.on('click', this.onListClick, this);

            document.on('mousedown', this.onDocumentMouseDown, this);
            GUI.Utils.globalEvents.on(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);

            this.listKeyboardEvents = new GUI.Utils.KeyboardEvents(dom, {
                anyKey: true
            });

            this.listKeyboardEvents.keyboardBrowserEvents(false);
            this.listKeyboardEvents.on(GUI.Utils.keys.anyKey, this.onListKeyDown, this);

            // Apply list height limit if exists
            if (GUI.isNumber(this.listHeight) && this.options.length > this.listHeight) {
                // Discover item height if not discovered before
                if (!GUI.isSet(this.itemHeight)) {
                    item = dom.findDescedents('li.' + this.itemClass)[0];
                    this.itemHeight = item.offsetHeight;
                }
                GUI.setClientHeight(dom, this.itemHeight * this.listHeight);
                dom.style.overflow = 'auto';
            }
            this.listOpened = true;
            this.fireEvent('listShow', this);
        }

        this.list.alignTo(this.dom, this.alignTo);

        (function () {
            if (!this.listOpened) {
                return; // List has been closed while we were waiting
            }

            value = this.getValue();
            selItem = this.findRecord(this.valueField, value);
            if (selItem) {
                var itemDom = this.getItemDom(selItem);
                if (itemDom) {
                    this.selectedId = itemDom.id;
                    GUI.scrollIntoView(this.list.dom, itemDom, 'v');
                }
            }
        }).defer(20, this);
    };

    /**
     * Defer focus list on 20
     */
    GUI.Forms.Combo.prototype.deferFocusList = function () {
        this.focusList.defer(20, this);
    };

    /**
     * Focus list if listFocusEl is true
     */
    GUI.Forms.Combo.prototype.focusList = function () {
        if (this.listFocusEl) {
            try {
                this.listFocusEl.focus();
                this.dom.addClass(this.selectedClass);
            } catch (e) {}
        }
    };

    /**
     * Hide list if list opened, remove event 'mousedown' from document
     */
    GUI.Forms.Combo.prototype.hideList = function () {
        var combo;
        if (this.listOpened) {
            this.listKeyboardEvents.destroy();
            this.hideHint();
            this.list.dom.un();
            this.list.hide();
            this.clearItemsDom();
            this.listOpened = false;
            this.fireEvent('listHide', this);
            this.selectedId = null;
            document.un('mousedown', this.onDocumentMouseDown, this);
            GUI.Utils.globalEvents.un(GUI.Utils.globalEvents.names.hidePopupElements, this.hideList, this);
            if (combo = this.dom.querySelector('input[type="combo"]')) {
                combo.focus();
            }
        }
    };

    /**
     * Handler key up on trigger. if e.key is key_down show list.
     * @param keyName
     * @param e
     */
    GUI.Forms.Combo.prototype.onTriggerKeyDown = function (keyName, e) {
        var len;
        e = GUI.Event.extend(e);

        if (this.listOpened) {
            // When list is opened, keyboard is handled by onDocumentKeyPress
            return;
        }

        if (keyName === GUI.Utils.keys.enter) {
            if (!this.listOpened) {
                this.onTriggerClick(e);
            }
        }
    };


    /**
     *
     * Handler click on trigger
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onTriggerClick = function (e) { // Fires on mousedown only
        /*
         Google chrome && FF3 fire click event when 'return' key is down
        */
        e = GUI.Event.extend(e);

        // e.preventDefault(); // Commented, because when triggerEvent is 'mousedown' then focus is prevented
        if (!this.disabled) {
            this._listItems = this.options.items; // remove filters
            //this.dom.addClass(this.focusClassForDom);
            this.dom.addClass(this.selectedClass);

            if (this.listOpened) {
                this.hideList();
            } else {
                if (this.autocomplete && this._listItems && this._listItems.length) {

                    this.clearListMessage();
                    this.updateList();
                } else if (this.autocomplete && this.dataUrl) {
                    this._listItems = [];
                    this.sm.clearSelections();
                    this.options.clear();

                    this.updateList();

                    //this.setListMessage(GUI.formatString(i18n(GUI.i18n.GUI_ENTER_CHARATER), this.minSearchCharacters));
                    this.setListMessage(sprintf(
                        i18n('Please, enter at least %s character', 'Please, enter at least %s characters', this.minSearchCharacters),
                        this.minSearchCharacters
                    ));
                } else {
                    if ((this.getValue() === '') && (this.options.length === 0)) {
                        //this.setListMessage(GUI.formatString(i18n(GUI.i18n.GUI_ENTER_CHARATER)), this.minSearchCharacters));
                        this.clearListMessage();
                    } else {
                        this.clearListMessage();
                    }
                }

                this.showList();
                this.deferFocusList();

                this.fireEvent('clickOpenList')
            }
        }
    };

    /**
     * Hide list, applies default's text
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onDocumentMouseDown = function (e) {
        e = GUI.Event.extend(e);
        var t = e.target;
        try {
            if (!(t.within(this.list.dom) || (this.hintList && t.within(this.hintList.dom)) || t.within(this.dom))) {
                //this.dom.removeClass(this.focusClassForDom);
                this.dom.removeClass(this.selectedClass);

                this.hideList();

                if (this.textFieldVisible) {
                    this.hideTextField();
                }

                if (this.defaultText && this.fieldEl && !this.fieldEl.hasClass(this.defaultTextClass)) {
                    this.fieldEl.addClass(this.defaultTextClass);
                    this.applyDefaultText();
                }

                if (this.autoWidthOnBlur) {
                    this.doAutoWidth(true);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * If autocomplete, then put item text into input and select it
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype._preSelectOption = function () {
        var item;
        if (this.selectedId) {
            item = this.getItemByDomId(this.selectedId);
        }

        if (item) {
            if (GUI.isSet(this._lastSearchedText) && this.substituteValue) { // not backspace or delete
                this.fieldEl.value = item[this.textField];
                this.selectText(this._lastSearchedText.length);
            }
        }
    };

    /**
     * Handler key up on the list
     * @param keyName
     * @param e
     */
    GUI.Forms.Combo.prototype.onListKeyDown = function (keyName, e) {
        e = GUI.Event.extend(e);

        switch (keyName) {
        case GUI.Utils.keys.esc:
            e.stop();
            this.hideList();
            break;

        case GUI.Utils.keys.enter:
            if (this.selectedId) {
                this.onListItemClick(this.selectedId);
            }
            break;

        case GUI.Utils.keys.adown:
            e.stop();
            if (this.selectedId) {
                // Move focus down
                this.focusNext();
            } else {
                // List is opened, so select first its item
                this.focusFirst();
            }
            this._preSelectOption();
            break;

        case GUI.Utils.keys.aup:
            e.stop();
            if (this.selectedId) {
                // Move focused up
                this.focusPrev();
            } else {
                // List is opened, so select last its item
                this.focusLast();
            }
            this._preSelectOption();
            break;

        default:
            if (this.autocomplete && !e.isSpecialKey()) {
                this.searchTask.delay(this.searchDelay);
            }
        }
    };

    /**
     * Handler mouse down on the ist
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onListMouseDown = function (e) {
        e = GUI.Event.extend(e);
        if (e.target.nodeName !== 'INPUT') {
            e.stop();
        } else {
            e.stopPropagation();
        }
    };

    /**
     * Handler click on the list
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onListClick = function (e) {
        var node, item;

        e = GUI.Event.extend(e);
        if ((node = e.target.queryParent(this.itemSelector))) {
            this.onListItemClick(node.id);
        }
    };

    /**
     * Handler click on the item of list
     * @param {Number} nodeId Id of the node
     */
    GUI.Forms.Combo.prototype.onListItemClick = function (nodeId) {
        var item = this.getItemByDomId(nodeId);

        if (item.disabled) {
            return false;
        }

        if (item) {
            this.fireEvent('itemClick', this, item);

            if (this.textFieldVisible) {
                this._lastSearchedText = null;
                this.selectText(this.fieldEl.value.length);
            }
        }
        this.hideList();
    };

    /**
     * Handler mouse over on the list
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onListMouseOver = function (e) {
        e = GUI.Event.extend(e);
        var node;
        if ((node = e.target.queryParent(this.itemSelector))) {
            if (e.isMouseEnter(node)) {
                this.onItemMouseEnter(node);
            } else {
                this.onItemMouseLeave(node);
            }
        }
    };
    /**
     * Handler mouse over on the list
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onListMouseOut = function (e) {
        e = GUI.Event.extend(e);
        var node;
        if ((node = e.target.queryParent(this.itemSelector))) {
            this.onItemMouseLeave(node);
        }
    };

    /**
     * Handler click, if list is open, hide it, and vice versa
     */
    GUI.Forms.Combo.prototype.onClick = function () {
        if (this.disabled) {
            return;
        }
        //this.dom.addClass(this.focusClassForDom);
        this.dom.addClass(this.selectedClass);

        if (this.autoWidthOnBlur) {
            this.setWidth(200);
        }

        if (this.listOpened) {
            this.hideList();

        } else if (this.showListOnClick) {
            if (this.options.items.length) {
                this.showList(this.options.items);
            }

            if (!this.autocomplete) {
                this.deferFocusList();
                this.dom.addClass(this.selectedClass);
            }
        }
    };

    /**
     * empty function
     */
    GUI.Forms.Combo.prototype.onItemAdd = function () {
        // Nothing to do.
    };

    /**
     * Remove item
     * @param {Object} item Item to remove
     */
    GUI.Forms.Combo.prototype.onItemRemove = function (item) {
        if (item.dom) {
            GUI.remove(item.dom);
            item.dom = null;
        }
    };

    /**
     * Returns dom of the item
     * @param {Object} item Item
     * @returns {HTMLElement} dom
     */
    GUI.Forms.Combo.prototype.getItemDom = function (item) {
        return (item.dom = GUI.$(item.domId));
    };

    /**
     * Clear items dom
     */
    GUI.Forms.Combo.prototype.clearItemsDom = function () {
        this.options.each(function (item) {
            item.dom = null;
        });
    };

    /**
     * Focus item, fire event 'itemMouseOver'.
     * @param {HTMLElelemnt} node Node
     */
    GUI.Forms.Combo.prototype.onItemMouseEnter = function (node) {
        var item = this.getItemByDomId(node.id);
        if (item) {
            this.focusItem(node, false);
            this.fireEvent('itemMouseOver', this, item);
        }
    };

    /**
     * Focus item, fire event 'itemMouseOver'.
     * @param {HTMLElelemnt} node Node
     */
    GUI.Forms.Combo.prototype.onItemMouseLeave = function (node) {
        var item = this.getItemByDomId(node.id);
        if (item) {
            this.blurItem(node, false);
            this.fireEvent('itemMouseBlur', this, item);
        }
    };

    /**
     * Update textfield
     * @param {Object} item Item
     */
    GUI.Forms.Combo.prototype.onItemSelect = function (item) {
        this.setValue(this.getOptionValue(item));
    };

    /**
     * empty function
     */
    GUI.Forms.Combo.prototype.onItemDeselect = function () {};

    /**
     * Sets style display to 'block',
     * adds events 'keydown', 'keyup', 'blur'
     */
    GUI.Forms.Combo.prototype.showTextField = function () {
        var selItem = this.sm.getSelected();
        this.fieldEl.value = GUI.html_entity_decode(selItem ? selItem[this.textField] : ''); // CHD-2115
        this._lastSearchedText = null;
        this.dom.removeClass('b-text-field_combo');
        this.dom.addClass('b-text-field_combo_editable');
        GUI.hide(this.divEl);
        //this.fieldEl.style.display = 'block';
        this.textFieldVisible = true;
        this.fieldEl.un();
        this.fieldEl.on('keydown', this.onInputKeyDown, this);
        this.fieldEl.on('keyup', this.onInputKeyUp, this);
        this.fieldEl.on('blur', this.onBlur, this);
        this.fieldEl.select();
       // this.deferFocus();
    };

    /**
     * Sets stye display to '', removes events 'keydown', 'keyup', 'blur'
     */
    GUI.Forms.Combo.prototype.hideTextField = function () {
        //this.fieldEl.style.display = '';
        this.dom.removeClass('b-text-field_combo_editable');
        this.dom.addClass('b-text-field_combo');
        GUI.show(this.divEl);

        if (this.fieldEl.listeners) {
            this.fieldEl.un('keydown', this.onInputKeyDown, this);
            this.fieldEl.un('keyup', this.onInputKeyUp, this);
            this.fieldEl.un('blur', this.onBlur, this);
        }
        this.textFieldVisible = false;
    };

    /**
     * Handles div mousedown
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onMouseDown = function (e) {
        if (!GUI.isSmallMobile) {
            this.fieldEl.focus();
        }

        if (this.autocomplete && !this.disabled) {
            this.showTextField();
        }
    };

    /**
     * Updates list, sets list message, sends request
     */
    GUI.Forms.Combo.prototype.doSearch = function () {
        var searchValue, j, option, option_, search_flag, value,
            val = this.fieldEl.value,
            data = {},
            options = [],
            len = 0,
            filteredOptions = [],
            i = 0;
        if (this._lastSearchedText === val) {
            return; // ignore same consecutive queries
        }

        this._lastSearchedText = val;

        if (val.length < this.minSearchCharacters) {
            if (val === '' && !this.dataUrl) {
                // Local filtering, restore origignal options
                this._listItems = this.options.items;

                this.clearListMessage();
                this.updateList();

                return;
            }
            //this.setListMessage(GUI.formatString(i18n(GUI.i18n.GUI_ENTER_CHARATER), this.minSearchCharacters));
            this.setListMessage(sprintf(
                i18n('Please, enter at least %s character', 'Please, enter at least %s characters', this.minSearchCharacters),
                this.minSearchCharacters
            ));
            return;
        }

        if (this.baseParams) {
            Object.extend(data, this.baseParams);
        }

        data[this.searchParam] = val;

        if (this.dataUrl) { // use remote ajax search engine
            if (this._searchRequest && !this._searchRequest.complete) {
                this._searchRequest.abort();
            }
            this._listItems = [];
//            this.setListMessage(i18n('Searching...'));
//            this.updateList();

            this._searchRequest = new GUI.Ajax.Request(this.dataUrl, {
                method      : 'post',
                data        : data,
                scope       : this,
                onSuccess   : this.onSearchSuccess,
                onFailure   : this.onSearchFailure
            });
        } else { // use local search
            options = this.options.items;
            len = options.length;
            searchValue = val.toLowerCase();

            for (i = 0; i < len; i++) {
                option = options[i];

                if (this.searchFields && this.searchFields.length > 0) {
                    search_flag = false;

                    for (j = 0; j < this.searchFields.length; j++) {
                        if (option[this.searchFields[j]]) {
                            value = option[this.searchFields[j]].toLowerCase();
                            if (this.filterInText(value, searchValue)) {
                                search_flag = true;
                            }
                        }
                    }
                    if (search_flag) {
                        option = Object.clone(option);
                        option.selected = false;
                        filteredOptions.push(option);
                    }
                } else {
                    value = option[this.textField].toLowerCase();

                    if (this.filterInText(value, searchValue)) {
                        option_ = Object.clone(option);
                        option_.selected = false;
                        filteredOptions.push(option_);
                    }
                }
            }
            this.showFilteredList(filteredOptions);
        }
    };

    /**
     *
     * @param value
     * @param searchValue
     * @returns {*}
     */
    GUI.Forms.Combo.prototype.filterInText = function (value, searchValue) {
        if (this.advancedSearch) {
            return new RegExp("\\b(" + searchValue + ")").test(value);
        } else {
            return (
                !this.typeAhead && value.indexOf(searchValue) != -1 ||
                this.typeAhead && value.indexOf(searchValue) === 0
            );
        }
    };

    /**
     * Show list updated with options
     * @param {Array} options  List of options
     */
    GUI.Forms.Combo.prototype.showFilteredList = function (options) {
        if (options.length) {
            this.clearListMessage();
        } else {
            this.setListMessage(this.listMessage || '<span class="label">' + i18n('Nothing found') + '</span>');
            this.selectedId = null; // prevent erroneus selection if return pressed
        }

        if (this.dataUrl) {
            this.setOptions(options);
        } else {
            this._listItems = options;
        }

        if (!this.listOpened) {
            this.showList();
        } else {
            this.updateList();
        }

        if (this.typeAhead) {
            var selStart, newValue,
                item = options[0];

            if (item) {
                selStart = this.getRawValue().length;
                newValue = item[this.textField];

                if (this._lastInputKey !== 8 && this._lastInputKey !== 46 && this.substituteValue) {
                    this.fieldEl.value = newValue;
                    this.selectText(selStart);
                }
                if (!this.selectedId) {
                    this.focusFirst();
                }
            }
        }
    };

    /**
     * Sets list of options, show filtered list
     * @param {Object} response Response of request
     * @param {Object} req Request object
     */
    GUI.Forms.Combo.prototype.onSearchSuccess = function (response, req) {
        var json = req.responseJson,
            options = [];

        if (json && json[this.resultsField]) {
            options = json[this.resultsField];
            this.showFilteredList(options);
        }
    };

    /**
     * Sets list of items as empty array, sets list message
     */
    GUI.Forms.Combo.prototype.onSearchFailure = function () {
        this._listItems = [];
        this.setListMessage(i18n('Oops, something went wrong. Please refresh the page or try again later.'));
    };

    /**
     * Handles keyboard navigation in input
     * @param {Event} e Event
     **/
    GUI.Forms.Combo.prototype.onInputKeyDown = function (e) {
        e = GUI.Event.extend(e);
        var key = e.getKey();

        if (((key === e.KEY_DOWN) || (key === e.KEY_UP)) && !this.listOpened) {
            this._listItems = this.options.items; // same as trigger click

            this.clearListMessage();
            this.showList();
            return;
        }
        switch (key) {
        case e.KEY_DOWN:
            e.stop();
            if (this.selectedId) {
                this.focusNext(); // Move focus down
            } else {
                this.focusFirst(); // List is opened, so select first its item
            }
            this._preSelectOption();
            break;

        case e.KEY_UP:
            e.stop();
            if (this.selectedId) {
                this.focusPrev(); // Move focused up
            } else {
                this.focusLast(); // List is opened, so select last its item
            }
            this._preSelectOption();
            break;

        case e.KEY_ESC:
            e.stop();
            this.hideList();
            break;

        case e.KEY_RETURN:
            if (this.selectedId) {
                this.onListItemClick(this.selectedId);
            }
            e.preventDefault(); // Prevent submit
            break;

        }

    };

    /**
     * Handles keyboard navigation in input
     * @param {Event} e Event
     */
    GUI.Forms.Combo.prototype.onInputKeyUp = function (e) {
        e = GUI.Event.extend(e);
        this._lastInputKey = e.getKey();

        if (this.fieldEl.value === '') {
            this.clearListMessage();
            this.fireEvent('emptyField', this);

            if (this.autoSetDefaultValue) {
                this.selectItem(-1);
                this.sm.clearSelections();
            }
        }

        if (this.autocomplete && !e.isSpecialKey()) {
            this.searchTask.delay(this.searchDelay);
        }
    };

    /**
     * Sets text of message, show list
     * @param {String} msg Text of the list message
     */
    GUI.Forms.Combo.prototype.setListMessage = function (msg) {
        this.listMessage = msg;

        if (this.listOpened) {
            var div = this.getListDom().firstChild;
            if (div) {
                div.innerHTML = msg;
                div.style.display = (msg === '') ? '' : 'block';
            }
        } else {
            this.updateList();
            if (msg !== '') {
                this.showList();
            }
        }
    };

    /**
     * Sets list message to empty string
     */
    GUI.Forms.Combo.prototype.clearListMessage = function () {
        this.setListMessage('');
    };

}());