(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
    * JavaScript Graphical User Interface
    * GUI.Informer implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Utils.Observable
    */
    GUI.Informer = Class.create();
    Object.extend(GUI.Informer.prototype, superproto);

    /**
     * Array of the types
     * @type Array
     */
    GUI.Informer.prototype.types = {
        error   : 'b-informer_error',
        criticalerror: 'b-informer_critical',
        info    : 'b-informer_info',
        help    : 'b-informer_help'
    };

    /**
     * Default is small
     * @type Boolean
     */
    GUI.Informer.prototype.small = false;

    /**
     * Type of the error, can be 'error', 'critical', 'info', 'help', default is 'error'
     * @type String
     */
    GUI.Informer.prototype.type = 'error'; // error, info, help, critical

    /**
     * Caption of the informer
     * @type String
     */
    GUI.Informer.prototype.caption = '';

    /**
     * Text of the informer
     * @type String
     */
    GUI.Informer.prototype.text = '';

    /**
     * Holder of the informer
     * @type String
     */
    GUI.Informer.prototype.holder = null;

    /**
     * Css class of the informer, default is 'b-informer'
     * @type
     */
    GUI.Informer.prototype.cls = 'b-informer';

    /**
     * Default is false
     * @type Boolean
     */
    GUI.Informer.prototype.autoHide = false;

    /**
     * Delay before hide, default is 5
     * @type Number
     */
    GUI.Informer.prototype.hideDelay   = 5;

    /**
     * Default is true
     * @type Boolean
     */
    GUI.Informer.prototype.visible = true;

    /**
     * Type of the element, default is 'м'
     * @type String
     */
    GUI.Informer.prototype.elementType = 'GUI.Informer';

    /**
     * Hide on click, default is false
     * @type Boolean
     */
    GUI.Informer.prototype.hideOnClick = false;

    /**
     * If true, close button will be visible, default is false
     * @type Boolean
     */
    GUI.Informer.prototype.closeButton = false;

    /**
     * Timer
     * @type Object
     */
    GUI.Informer.prototype.timerId = null;

    /**
     * Template
     * @type String
     */
    GUI.Informer.prototype.template =
        '<div id="<%=id%>" class="<%=cls%>" <%=style%> >' +
        '<i class="b-informer__icon"></i>' +
        '<div class="b-informer__data">' +
        '<b class="b-informer__ttl"></b>' +
        '<p class="b-informer__txt"></p>' +
        '</div>' +
        '<%=icons%>' +
        '</div>';

    /**
     * Method of the render html, default is 'append'
     * @type String
     */
    GUI.Informer.prototype.renderMethod = 'append';

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Informer.prototype.initialize = function (config) {
        if (config) {
            Object.extend(this, config);
        }

        this.getId();

        this.hideDelegate = this.hide.bindLegacy(this);

        this.addEvents('click');

        superproto.initialize.call(this);

        GUI.ComponentMgr.register(this);
        GUI.registerObject(this);
    };

    /**
     * Returns id of the dom
     * @returns {String|Number} id
     */
    GUI.Informer.prototype.getId = function () {
        return this.id || (this.id = GUI.getUniqId('informer-'));
    };

    /**
     * Destroys objects
     */
    GUI.Informer.prototype.destroy = function () {
        GUI.ComponentMgr.unregister(this);
        this.unrender();
    };

    /**
     * Renders objects
     * @param {HTMLElement} to Elemnt to render dom to
     */
    GUI.Informer.prototype.render = function (to) {
        var tpl, cls, clsplus;

        if (to) {
            this.holder = to;
        } else {
            to = this.holder;
        }

        to = GUI.$(to);
        if (!to) {
            throw new Error('Holder is null');
        }
        tpl = {};
        cls = this.cls;
        clsplus = this.types[this.type];

        if (clsplus) {
            cls += ' ' + clsplus;
            if (!this.small) {
                cls += ' b-informer_big';
            }
        }
        tpl.id = this.id;
        tpl.cls = cls;

        tpl.style = (!this.visible) ? 'style="display: none;"' : '';

        tpl.icons = this.closeButton ? '<i class="b-toolbox__icon close-tips"></i>' : '';

        this.dom = GUI.Dom[this.renderMethod](to, GUI.Template(this.template, tpl, this.elementType));
        GUI.Dom.extend(this.dom);
        this.rendered = true;

        if (this.text) {
            this.setText(this.text);
        } else {
            this.setCaption(this.caption);
        }

        if (this.hideOnClick) {
            this.dom.on('click', this.onClick, this);
            GUI.Popup.Hint.add(this.dom, i18n(GUI.i18n.GUI_CLICK_TO_HIDE));
            this.dom.setAttribute('followmouse', 'true');
        } else if (this.closeButton) {
            this._closeBtnEl = this.dom.findDescedent('i.b-toolbox__icon close-tips');
            GUI.$(this._closeBtnEl).on('click', this.onClick, this);
        }
    };

    /**
     * Call hide method, fires event 'click'
     */
    GUI.Informer.prototype.onClick = function () {
        this.hide();
        this.fireEvent('click', this);
    };

    /**
     * Unrenders dom
     */
    GUI.Informer.prototype.unrender = function () {
        if (!this.rendered) {
            return;
        }
        this.dom.un();
        if (this._closeBtnEl) {
            this._closeBtnEl.un();
        }
        GUI.destroyNode(this.dom);
        this.dom = this.captionEl = this.textEl = this._closeBtnEl = null;
        this.rendered = false;
    };

    /**
     * Shows the informer
     * @param {Object} cfg
     */
    GUI.Informer.prototype.show = function (cfg) {
        // Unrender if already rendered
        if (this.dom) {
            this.hide();
        }
        // Apply new config if passed
        if (cfg) {
            Object.extend(this, cfg);
        }

        this.visible = true;
        this.render();
        // If autoHide is set, defer hiding
        if (this.autoHide) {
            this.timerId = window.setTimeout(this.hideDelegate, this.hideDelay * 1000);
        }
    };

    /**
     * Hides the informer
     */
    GUI.Informer.prototype.hide = function () {
        if (this.timerId) {
            window.clearTimeout(this.timerId);
            this.timerId = null;
        }
        this.unrender();
        this.visible = false;
    };

    /**
     * Sets type of the informer
     * @param {String} type Type of the informer
     */
    GUI.Informer.prototype.setType = function (type) {
        this.type = type;
        if (this.dom) {
            var cls = this.cls,
                clsplus = this.types[this.type];

            if (clsplus) {
                cls += ' ' + clsplus;
                if (this.small) {
                    cls += '-small';
                }
            }
            this.dom.className = cls;
        }
    };

    /**
     * Sets caption of the informer
     * @param {String} caption Caption of the informer
     */
    GUI.Informer.prototype.setCaption = function (caption) {
        this.caption = caption;
        if (this.dom) {
            if (this.captionEl || (this.captionEl = this.dom.findDescedents('b.b-informer__ttl')[0])) {
                this.captionEl.innerHTML = this.caption + '&nbsp;';
            }
        }
    };

    /**
     * Sets text of the informer
     * @param {String} text Text of the informer
     */
    GUI.Informer.prototype.setText = function (text) {
        this.text = text;
        if (this.dom) {
            if (this.textEl || (this.textEl = this.dom.findDescedents('p.b-informer__txt')[0])) {
                var html = this.caption ? '<b class="b-informer__ttl">' + this.caption + '&nbsp;</b>' : '';
                this.textEl.innerHTML =  html + this.text;
            }
        }
    };

    /**
     * Returns true if informer is shown
     * @returns {Boolean}
     */
    GUI.Informer.prototype.isDisplayed = function () {
        return !!this.dom && this.visible;
    };

}());
