(function () {
    var superproto = GUI.Forms.Combo.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.MultipleCombo implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Forms.Combo
    */
    GUI.Forms.MultipleCombo = Class.create();
    Object.extend(GUI.Forms.MultipleCombo.prototype, superproto);


    /**
     * Default is true
     * @type Boolean
     */
    GUI.Forms.MultipleCombo.prototype.doNotReadValueFromDom  = true;

    /**
     * Type of the field, 'hidden'
     */
    GUI.Forms.MultipleCombo.prototype.type = 'hidden';

    /**
     * Css class of the list, default is ''
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.listClass = 'b-popup-menu b-popup-menu_main-menu b-popup-menu_style_combo';

    /**
     * Css class of the item, default is ''
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.itemClass = 'b-popup-menu__item';

    /**
     * Css class of hover item, default is ''
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.itemHoverClass = '';

    /**
     * Css class of item with min width, default is ''
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.minWidthClass = '';

    /**
     * Css class of the hint, default is 't'
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.hintClass = '';

    /**
     * Width of the list, default is 200
     * @type Number
     */
    GUI.Forms.MultipleCombo.prototype.width = 200;

    /**
     * Allow checkbox, default is true
     * @type Boolean
     */
    GUI.Forms.MultipleCombo.prototype.checkbox = true;

    /**
     * Show text Select All, default is true
     * @type Boolean
     */
    GUI.Forms.MultipleCombo.prototype.showSelectAll = true;

    /**
     * Allow deselect items, default is true
     * @type Boolean
     */
    GUI.Forms.MultipleCombo.prototype.allowDeselect = true;

    /**
     * Placeholder
     * @type String
     */
    GUI.Forms.MultipleCombo.prototype.placeholder = '';

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Forms.MultipleCombo.prototype.initialize = function (config) {
        superproto.initialize.call(this, config);
    };

    /**
     * Init component
     */
    GUI.Forms.MultipleCombo.prototype.initComponent = function () {
        superproto.initComponent.call(this);
    };

    /**
     * Returns config of the selection model
     * @returns {Object} config
     */
    GUI.Forms.MultipleCombo.prototype.getDefaultSmConfig = function () {
        return {
            singleSelect: false
        };
    };

    /**
     * Check node name and multiple
     * @param {HTMLElement} dom Dom of the node
     */
    GUI.Forms.MultipleCombo.prototype.onBeforeAssign = function (dom) {
        return dom.nodeName === 'SELECT' && dom.multiple;
    };

    GUI.Forms.MultipleCombo.prototype.onAssign = function (elem) {
        this.selectAllHint = elem.getAttribute('selectAllHint');

        superproto.onAssign.call(this, elem);
    };

    /**
     * Adjust width
     * @returns {Number} width
     */
    GUI.Forms.MultipleCombo.prototype.adjustAutoWidth = function (width) {
        return width - 1;
    };

    /**
     * Returns value
     * @returns {Array} values
     */
    GUI.Forms.MultipleCombo.prototype.getValue = function () {
        var ids = [],
            selections = this.sm.getSelections(),
            valueField = this.getValueField(),
            i = 0,
            len = 0;

        for (i = 0, len = selections.length; i < len; i++) {
            ids[i] = selections[i][valueField];
        }

        return ids;
    };

    /**
     * Sets value
     * @param {Array} ids
     */
    GUI.Forms.MultipleCombo.prototype.setValue = function (values) {
        // Need to check items with values passed in the array and uncheck all other items
        var item,
            i = 0,
            len = 0;

        this.sm.clearSelections();
        if (GUI.isArray(values)) {
            for (i = 0, len = values.length; i < len; i++) {
                item = this.getItemByValue(values[i]);
                if (item) {
                    this.sm.selectItem(item, true);
                    GUI.$(item.domId) && GUI.$(item.domId).addClass('selected');
                }
            }
        } else {
            item = this.getItemByValue(values);
            if (item) {
                this.sm.selectItem(item);
                GUI.$(item.domId) && GUI.$(item.domId).addClass('selected');
            }
        }

        if (this.iconEl && !this.icon) {
            this.iconEl.style.display = 'none';
        }

        if (this.divEl) {
            this.updateValue();
        }
    };

    /**
     * Sets options
     * @param {Array} options
     */
    GUI.Forms.MultipleCombo.prototype.setOptions = function (options) {
        superproto.setOptions.call(this, options);
        this.updateValue();
    };

    /**
     * Updates values
     */
    GUI.Forms.MultipleCombo.prototype.updateValue = function () {
        var tmpHiddenInputElement,
            text,
            selection,
            selectedTextArr = [],
            count = 0,
            allCount = 0,
            i = 0,
            hiddenVal = [],
            len = 0,
            oldValue = this.value ? this.value : '';

        if (this.divEl) {
            count = this.sm.getCount();
            allCount = this.options.length;

            switch (count) {
            case 0:
                text = '' || '<span style="color: #999">' + this.placeholder + '</span>';
                break;

            case 1:
                text = this.sm.getSelected()[this.textField];
                break;

            case allCount:
                text = this.labelSelectAll || pgettext('General use', 'All');
                break;

            default:
                if (this.showSelectedOptions) {
                    selection = this.sm.getSelections();
                    len = selection.length;
                    for (i = 0; i < len; i++) {
                        selectedTextArr.push(selection[i][this.textField || this.valueField]);
                    }
                    text = selectedTextArr.join(', ');
                } else {
                    text = i18n('Multiple Selection');
                }
                break;
            }

            this.divEl.innerHTML = text;
        }
        this.value = this.getValue();

        hiddenVal = [];
        selection = this.sm.getSelections();
        len = selection.length;
        for (i = 0; i < len; i++) {
            hiddenVal[i] = selection[i][this.valueField || this.textField];
        }

        if (this.hiddenField) {
            if (!this.multiHidden) {
                this.hiddenField.value = hiddenVal.join(',');
            } else {
                if (this.hiddenInputs && this.hiddenInputs.length) {
                    for (i = 0; i < this.hiddenInputs.length; i++) {
                        this.hiddenInputs[i].parentElement.removeChild(this.hiddenInputs[i]);
                    }
                }

                this.hiddenInputs = [];
                this.hiddenField.value = '';

                for (i = 0; i < len; i++) {
                    if (i == 0) {
                        this.hiddenField.value = hiddenVal[i];
                        continue;
                    }

                    tmpHiddenInputElement = GUI.createInput(this.name);
                    tmpHiddenInputElement.type = 'hidden';
                    tmpHiddenInputElement.value = hiddenVal[i];

                    this.hiddenField.parentNode.appendChild(tmpHiddenInputElement);
                    this.hiddenInputs.push(tmpHiddenInputElement);
                }
            }
        }

        if (!this.quiet && (this.value !== oldValue)) {
            this.fireEvent('change', this, this.value, oldValue);
        }
    };

    /**
     * Returns html of the item
     * @param {Object} item
     * @returns {HTMLElement} html
     */
    GUI.Forms.MultipleCombo.prototype.getListItemHtml = function (item) {
        var itemClass = this.itemClass, itemHtml, hint = '';

        if (item.selected) {
            itemClass += ' selected';
        }

        itemHtml = '<span class="b-popup-menu__button">';

        if (this.checkbox) {
            itemHtml += '<label class="b-checkbox b-checkbox_collapsedSingle">' +
                '<input type="checkbox" class="checkbox" ' + (item.selected ? ' checked="checked"' : '') + '>' +
                '<i class="b-checkbox__pseudo"></i></label>';
        }
        itemHtml += '<span class="label">' + item[this.listField] + '</span>';

        if (GUI.isSet(item[this.descriptionField])) {
            itemHtml += '<span class="description">' + item[this.descriptionField] + '</span>';
        }
        itemHtml += '</span>';

        if (item.hint) {
            hint = 'hint="' + item.hint + '" '
        }

        return '<li id="' + item.domId + '" class="' + itemClass + '" ' + hint +  '>' + itemHtml + '</li>';
    };

    /**
     * Returns html of the list
     * @returns {HTMLElelemnt} html
     */
    GUI.Forms.MultipleCombo.prototype.getListHtml = function (items) {
        var item, hasMessage,
            i = 0,
            len = 0,
            html = new GUI.StringBuffer();

        // Generate list html
        hasMessage = GUI.isSet(this.listMessage) && this.listMessage !== '';
        html.append('<div class="' + this.listMessageClass + '" style="display: ' +
            (hasMessage ? 'block' : '') + ';">' + (hasMessage ? this.listMessage : '') + '</div>');

        items = items || this._listItems;
        html.append('<ul class="b-popup-menu__list ');

        if (this.checkbox) {
            html.append(' with-icons ');
        }
        html.append('">');

        if (this.showSelectAll) {
            item = {
                domId    : this.id + '-select-all',
                selected : this.sm.isAllSelected()
            };
            if (this.labelSelectAll) {
                item[this.listField] = this.labelSelectAll;
            } else {
                item[this.listField] = i18n('Select All');
            }

            if (this.selectAllHint) {
                item.hint = this.selectAllHint;
            }

            html.append(this.getListItemHtml(item));
        }

        for (i = 0, len = items.length; i < len; i++) {
            html.append(this.getListItemHtml(items[i]));
        }

        html.append('</ul>');

        return html.toString();
    };

    /**
     * Add focus to node
     * @param {HTMLElement} node Node
     * @param set_value
     * @param direction
     */
    GUI.Forms.MultipleCombo.prototype.focusItem = function (node, set_value, direction) {
        var item;

        if (!direction && this.getItemByDomId(node.id) && this.getItemByDomId(node.id).disabled) {
            return;
        } else if (direction && this.getItemByDomId(node.id) && this.getItemByDomId(node.id).disabled) {
            if (this.selectedId) {
                if (this.selectedId !== node.id) {
                    this.hideHint();
                }
                item = this.getItemByDomId(this.selectedId);
                if (!item.selected) {
                    GUI.$(this.selectedId).removeClass('selected');
                }
            }
            this.selectedId = node.id;

            if (direction === 'next') {
                this.focusNext();
            } else if (direction === 'prev') {
                this.focusPrev();
            }

            return;
        }

        GUI.$(node.id).addClass('selected');
    };

    /**
     * Fire event 'itemClick'
     * @param {Number} nodeId
     */
    GUI.Forms.MultipleCombo.prototype.onListItemClick = function (nodeId) {
        var item = this.getItemByDomId(nodeId);
        if (item) {
            this.fireEvent('itemClick', this, item);
        } else if (nodeId === this.id + '-select-all') {
            if (this.sm.isAllSelected()) {
                this.sm.getSelections().each(function (item) {
                    GUI.$(item.domId).removeClass('selected');
                });
                GUI.$(this.id + '-select-all').removeClass('selected');
                this.sm.clearSelections();
            } else {
                this.sm.selectAll();
                this.sm.getSelections().each(function (item) {
                    GUI.$(item.domId).addClass('selected');
                });
                GUI.$(this.id + '-select-all').addClass('selected');
            }
        }
    };

    /**
     * Add class itemSelectedClass, sets checked
     */
    GUI.Forms.MultipleCombo.prototype.onSelectionChange = function () {
        if (!this.showSelectAll) {
            return;
        }

        var dom = GUI.$(this.id + '-select-all'),
            cb = dom.findDescedents('input')[0];

        if (this.sm.isAllSelected()) {
            dom.addClass('selected');
            cb.checked = true;
        } else {
            dom.removeClass('selected');
            cb.checked = false;
        }
    };

    /**
     * Handler item select
     * @param {Object} item
     */
    GUI.Forms.MultipleCombo.prototype.onItemSelect = function (item) {
        var dom = this.getItemDom(item);
        if (dom) {
            dom.addClass('selected');
            if (this.checkbox) {
                dom.findDescedents('input')[0].checked = true;
            }
            this.onSelectionChange();
        }
        this.updateValue();
    };

    /**
     * Handler item deselect
     * @param {Object} item
     */
    GUI.Forms.MultipleCombo.prototype.onItemDeselect = function (item) {
        superproto.onItemDeselect.call(this, item);
        var dom = this.getItemDom(item);
        if (dom) {
            dom.removeClass('selected');
            if (this.checkbox) {
                dom.findDescedents('input')[0].checked = false;
            }
            this.onSelectionChange();
        }
        this.updateValue();
    };

    /**
     * Returns hint
     * @param {HTMLElement} node
     * @returns {HTMLElement} html
     */
    GUI.Forms.MultipleCombo.prototype.getHintAlignNode = function (node) {
        return node.getElementsByTagName('span')[0];
    };

    /**
     * Handler key down event on the list
     * @param keyName
     * @param {Event} e Event
     */
    GUI.Forms.MultipleCombo.prototype.onListKeyDown = function (keyName, e) {
        e = GUI.Event.extend(e);
        switch (keyName) {
        case GUI.Utils.keys.space:
            e.stop(); // Blocks space to prevent ff3 from scrolling down
            break;

        default:
            superproto.onListKeyDown.call(this, keyName, e.e);
            break;
        }
    };

    /**
     * Handler key up on the list
     * @param keyName
     * @param {Event} e Event
     */
    GUI.Forms.MultipleCombo.prototype.onListKeyUp = function (keyName, e) {
        e = GUI.Event.extend(e);

        switch (keyName) {
        case GUI.Utils.keys.space:
            e.stop();
            if (this.selected) {
                this.onListItemClick(this.selected);
            }
            break;

        case GUI.Utils.keys.enter:
            if (this.selected) {
                this.onListItemClick(this.selected);
            }
            break;

        default:
            superproto.onListKeyUp.call(this, e);
            break;
        }
    };

}());
