(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Checkedany implementation
    *
    * @author Sergey Ostapenko
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Validator
    */
    GUI.Forms.Validation.Checkedany = Class.create();
    Object.extend(GUI.Forms.Validation.Checkedany.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_SELECTED'
     * @type String
     */
    GUI.Forms.Validation.Checkedany.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_SELECTED);

    /**
     * Type of the element, 'GUI.Forms.Validation.Checkedany'
     * @type String
     */
    GUI.Forms.Validation.Checkedany.prototype.elementType    = 'GUI.Forms.Validation.Checkedany';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Checkedany.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);
    };

    /**
     * Validates value
     * @param {String|Number} value Value to validate
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Validation.Checkedany.prototype.validateFunc = function (value) {
        var name = this.params,
            elements = document.getElementsByName(name),
            result = false;

        if (!elements || !elements.length) {
            return true;
        }

        for (var i = 0; i < elements.length; i++) {
            if (elements[i].checked) {
                result = true;
            }
        }

        return result;
    };
}());