(function () {
    var superproto = GUI.ToolBar.Button.prototype;

    /**
     * JavaScript Graphical User Interface
     * GUI.ToolBar.ToggleButton implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI.ToolBar
     * @extends GUI.ToolBar.Button
     */
    GUI.ToolBar.ToggleButton = Class.create();
    Object.extend(GUI.ToolBar.ToggleButton.prototype, superproto);

    /**
     * Static array, contains cached templates
     * @type Array
     */
    GUI.ToolBar.ToggleButton._tplCache = new Array(8);

    /**
     * If true the button can be toogled
     */
    GUI.ToolBar.ToggleButton.prototype.canToggle = true;

    /**
     * Returns object of the button
     * @returns {Object} button
     */
    GUI.ToolBar.ToggleButton.prototype.getSelf = function () { return ToggleButton; };

}());