(function () {
    var superproto = GUI.Application.prototype;

    GUI.Controller = Class.create();
    Object.extend(GUI.Controller.prototype, superproto);

    /**
     * Type of the module, default is 'Controller'
     * @type String
     */
    GUI.Controller.prototype.type = 'Controller';

    /**
     * Initializes controller
     * @param {Object} config
     */
    GUI.Controller.prototype.initialize = function (config) {
        Object.extend(this, config);
        superproto.create.call(this);
    };

    /**
     * Returns controller by the name
     * @param {String} name of the controller
     * @returns {Object} controller
     */
    GUI.Controller.prototype.getController = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Controller', name);
    };

    /**
     * Returns service by the name
     * @param {String} name of the serice
     * @returns {Object} service
     */
    GUI.Controller.prototype.getService = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Service', name);
    };

    // old
    /**
     * Returns model by the name
     * @param {String} name of the model
     * @returns {Object} model
     */
    GUI.Controller.prototype.getModel = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'Model', name);
    };

    /**
     * Returns view by the name
     * @param {String} name of the view
     * @returns {Object} view
     */
    GUI.Controller.prototype.getView = function (name) {
        if (!GUI.isSet(name)) {
            return;
        }
        return superproto.getModule.call(this, 'View', name);
    };

}());



