(function () {
    var superproto = GUI.Forms.Validation.Custom.prototype;
    /**
    * JavaScript Graphical User Interface
    * Forms.Validation.Phone implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms.Validation
    * @extends GUI.Forms.Validation.Custom
    */
    GUI.Forms.Validation.Phone = Class.create();
    Object.extend(GUI.Forms.Validation.Phone.prototype, superproto);

    /**
     * Text of the error message, default is 'i18n.GUI_VALIDATION_PHONE'
     * @type String
     */
    GUI.Forms.Validation.Phone.prototype.errorMessage = i18n(GUI.i18n.GUI_VALIDATION_PHONE);

    /**
     * Type of the element, 'GUI.Forms.Validation.Phone'
     * @type String
     */
    GUI.Forms.Validation.Phone.prototype.elementType   = 'GUI.Forms.Validation.Phone';

    /**
     * Initialize validator
     * @param {Object} config configuration object
     */
    GUI.Forms.Validation.Phone.prototype.initialize = function (config) {
        // Call parent method
        superproto.initialize.call(this, config);

        this.pattern = /^\+?[-()\s0-9]*$/i;
    };
}());