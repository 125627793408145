(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Resizer implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.Resizer = Class.create();
    Object.extend(GUI.Resizer.prototype, superproto);

    /**
     * Css class of the item
     * @type String
     */
    GUI.Resizer.prototype.addClass = '';

    /**
     * Css class of the proxy element
     * @type String
     */
    GUI.Resizer.prototype.dragProxyAddClass = '';

    /**
     * Base css class of the item, default is 'jsgui-resizer'
     * @type String
     */
    GUI.Resizer.prototype.baseClass = 'b-resizer';

    /**
     * Css class of the glass element, default is 'jsgui-resizer-glass'
     * @type String
     */
    GUI.Resizer.prototype.glassClass = '';

    /**
     * Base css class of the proxy element, default is 'jsgui-resizer-dragproxy'
     * @type String
     */
    GUI.Resizer.prototype.dragProxyClass = '';

    /**
     * Css class of the info element, default is 'jsgui-resizer-draginfo'
     * @type String
     */
    GUI.Resizer.prototype.dragInfoClass = 'b-hint';

    /**
     * Text of the hint
     * @type String
     */
    GUI.Resizer.prototype.hint = 'Drag to resize';

    /**
     * Vertical position of the item, default is false
     * @type Boolean
     */
    GUI.Resizer.prototype.vertical = false;

    /**
     * Offset X, default is 0
     * @type Number
     */
    GUI.Resizer.prototype.offsetX = 0;

    /**
     * Offset Y, default is 0
     * @type Number
     */
    GUI.Resizer.prototype.offsetY = 0;

    /**
     * Default is 1
     * @type Number
     */
    GUI.Resizer.prototype.step = 1;

    /**
     * Template of the proxy element, default is ''
     * @type String
     */
    GUI.Resizer.prototype.dragProxyTemplate = '<i class="b-resizer b-resizer_selected"><i class="b-resizer__shadow"></i><i class="b-resizer__snatch"></i></i>';

    /**
     * Content of the info element, default is ''
     * @type String
     */
    GUI.Resizer.prototype.info = '';

    /**
     * Constructor of GridFiltersLayout
     * @param {Object} config Configuration object
     */
    GUI.Resizer.prototype.initialize = function (config) {
        var ddConfig, defCfg;
        // default config
        defCfg =  {
            // Drag'n'Drop configuration
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                isTarget    : false,
                moveOnly    : true,
                groups      : {
                    'resizer' : true
                },
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            }
        };
        if (config) {
            Object.extend(defCfg, config);
            Object.extend(this, config);
        }
        superproto.initialize.call(this, defCfg);

        this.id = GUI.getUniqId('jsgui-resizer-');
        ddConfig = this.config.dd;
        ddConfig.linkedElId = ddConfig.dragElId = ddConfig.handleElId = this.id;
    };

    /**
     * Renders dom
     * @paranm {HTMLElement} to Element to render dom to
     */
    GUI.Resizer.prototype.render = function (to) {
        var i_el = document.createElement('I'),
            i_shadow = document.createElement('I'),
            i_snatch = document.createElement('I');
        i_el.id = this.id;
        i_el.className = this.baseClass;
        i_el.setAttribute('hint', this.hint);

        to = to || this.holder;
        GUI.$(to).appendChild(i_el);

        i_shadow.className = 'b-resizer__shadow';
        i_el.appendChild(i_shadow);

        i_snatch.className = 'b-resizer__snatch';
        i_el.appendChild(i_snatch);

        this.dom = GUI.Dom.extend(i_el);
        this.initDD();
    };

    /**
     * empty function
     */
    GUI.Resizer.prototype.onBeforeDestroy = function () {};

    /**
     * Destroy objects
     */
    GUI.Resizer.prototype.destroy = function () {
        this.onBeforeDestroy();
        if (this.dragProxy) {
            this.dragProxy.destroy();
            this.dragProxy = null;
        }
        if (this.dom) {
            this.destroyDD();
            GUI.destroyNode(this.dom);
            this.dom = null;
        }
    };

    /**
     * Returns dom
     * @returns {HTMLElement} dom
     */
    GUI.Resizer.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Creates proxy element and glass
     */
    GUI.Resizer.prototype.onDragStart = function () {
        // Create drag proxy and glass
        if (!this.dragProxy) {
            this.glass = new GUI.Popup.Region({
                className   : this.glassClass
            });

            this.dragProxy = new GUI.Popup.Region({
                className   : this.dragProxyClass + (this.dragProxyAddClass ? ' ' + this.dragProxyAddClass : ''),
                content     : this.dragProxyTemplate
            });

            this.dragInfo = new GUI.Popup.Region({
                className   : this.dragInfoClass,
                content     : ''
            });
        }

        this.glass.takeDocument();

        var dims = GUI.getDimensions(this.dom),
            pos = GUI.getPosition(this.dom);

        this._startPos = pos.top;

        this.dragProxy.setDimensions({
            top     : pos.top + this.offsetY,
            left    : pos.left + this.offsetX,
            width   : dims.width
        });

        this.glass.show();
        this.dragProxy.show();
        this.dragInfo.show();

        this.onResizeStart();
    };

    /**
     * Sets position and content of the info element
     * @param {Event} e Event
     */
    GUI.Resizer.prototype.onDrag = function (e) {
        var deltaY, info,
            xy = e.getPageXY(),
            x = xy[0],
            y = xy[1],
            style = this.dragProxy.dom.style,
            deltaHeight = y - this.DDM.startY,
            deltaRows = Math.round(deltaHeight / this.step); // Grid row height is 25px

        deltaRows = this.adjustDeltaStep(deltaRows);
        this._lastDeltaStep = deltaRows;
        deltaY = deltaRows * this.step;

        style.top = (this._startPos + deltaY + this.offsetY) + 'px';

        this.dragInfo.setPosition(x + 16, y + 16);

        info = this.updateInfo(deltaRows);
        if (info !== this.info) {
            this.dragInfo.setContent(this.info = info);
        }
    };

    /**
     * Adjusts step
     * @param {Number} deltaStep
     */
    GUI.Resizer.prototype.adjustDeltaStep = function (deltaStep) {
        return deltaStep; // no adjustment by default
    };

    /**
     * empty function
     */
    GUI.Resizer.prototype.updateInfo = function (deltaStep) {
        return 'Info';
    };

    /**
     * Hides proxy, info and glasses elements
     * @param {Event} e Event
     */
    GUI.Resizer.prototype.onDragEnd = function (e) {
        if (e) {
            this.onResizeEnd(this._lastDeltaStep);
        }
        this.dragInfo.hide();
        this.dragProxy.hide();
        this.glass.hide();
    };

    /**
     * empty function
     */
    GUI.Resizer.prototype.onResizeEnd = function (deltaStep) {};

    /**
     * empty function
     */
    GUI.Resizer.prototype.onResizeStart = function () {};

}());