(function () {
    var superproto = GUI.Layouts.Layout.prototype;

    /**
    * JavaScript Graphical User Interface
    * Form Layout implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI
    * @extends GUI.Layouts.Layout
    */
    GUI.Layouts.Form = Class.create();
    Object.extend(GUI.Layouts.Form.prototype, superproto);

    /**
     * The standard separator to display after the text of each form label (defaults to a colon ':').  To turn off
     * separators for all fields in this layout by default specify empty string '' (if the labelSeparator value is
     * explicitly set at the field level, those will still be displayed).
     * @type String
     */
    GUI.Layouts.Form.prototype.labelSeparator = ':';

    /**
     * Sets template
     * @param {Object} ct Container
     */
    GUI.Layouts.Form.prototype.setContainer = function (ct) {
        superproto.setContainer.call(this, ct);

        var pad, template;
        // Apply label alignment class
        if (ct.labelAlign) {
            ct.addClass('x-form-label-' + ct.labelAlign);
        }

        if (ct.hideLabels) {
            this.labelStyle = "display:none";
            this.elementStyle = "padding-left:0;";
            this.labelAdjust = 0;
        } else {
            this.labelSeparator = ct.labelSeparator || this.labelSeparator;
            ct.labelWidth = ct.labelWidth || 100;
            if (typeof ct.labelWidth === 'number') {
                pad = (typeof ct.labelPad === 'number' ? ct.labelPad : 5);
                this.labelAdjust = ct.labelWidth + pad;
                this.labelStyle = "width:" + ct.labelWidth + "px;";
                this.elementStyle = "padding-left:" + (ct.labelWidth + pad) + 'px';
            }

            if (ct.labelAlign === 'top') {
                this.labelStyle = "width:auto;";
                this.labelAdjust = 0;
                this.elementStyle = "padding-left:0;";
            }
        }

        if (!this.fieldTpl) {
            // the default field template used by all form layouts
            template =
                '<div class="x-formlayout-item {5}" tabIndex="-1">' +
                '<label for="{0}" style="{2}" class="x-formlayout-item-label formlayout-item">{1}{4}</label>' +
                '<div class="x-formlayout-field" id="x-form-el-{0}" style="{3}">' +
                '</div><div class="{6}"></div>' +
                '</div>';
            FormLayout.prototype.fieldTpl = template;
        }
    };

    /**
     * Formats template
     * @param {Object} c Container
     * @param {String} position
     * @param {HTMLElement} to Element to render object to
     */
    GUI.Layouts.Form.prototype.renderItem = function (c, position, to) {
        if (c && !c.rendered && c.isFormField && c.type !== 'hidden') {
            var html,
                args = [
                    c.id,
                    c.label,
                    c.labelStyle || this.labelStyle || '',
                    this.elementStyle || '',
                    typeof c.labelSeparator === 'undefined' ? this.labelSeparator : c.labelSeparator,
                    (c.itemCls || this.container.itemCls || '') + (c.hideLabel ? ' x-hide-label' : ''),
                    c.clearCls || 'x-form-clear-left'
                ];

            if (typeof position === 'number') {
                position = to.childNodes[position] || null;
            }

            html = this.fieldTpl.format.apply(this.fieldTpl, args);
            if (position) {
                GUI.Dom.insertBefore(position, html);
            } else {
                GUI.Dom.append(to, html);
            }
            c.render('x-form-el-' + c.id);
        } else {
            superproto.renderItem.apply(this, arguments);
        }
    };

    /**
     * empty function, returns true
     * @returns {Boolean} true
     */
    GUI.Layouts.Form.prototype.isValidParent = function (c, target) {
        return true;
    };

}());