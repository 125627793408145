(function () {
    var superproto = GUI.Utils.Container.prototype;
    /**
    * JavaScript Graphical User Interface
    * Form implementation
    *
    * @author Eugene Lyulka
    * @version 2.0
    * @namespace GUI.Forms
    * @extends GUI.Utils.Container
    */
    GUI.Forms.Form = Class.create();
    Object.extend(GUI.Forms.Form.prototype, superproto);

    /**
     * Method of th request, default is 'get'
     * @type String
     */
    GUI.Forms.Form.prototype.method = 'get';

    /**
     * Upload a file, default is false
     * @type Boolean
     */
    GUI.Forms.Form.prototype.fileUpload = false;

    /**
     * Physical send form
     * @type Boolean
     */
    GUI.Forms.Form.prototype.sendForm = false;

    /**
     * Expected JSON data type in the answer of the request
     * @type Boolean
     */
    GUI.Forms.Form.prototype.jsonExpected = true;

    /**
     * Map of the types
     * @type Object
     */
    GUI.Forms.Form.prototype.assignMap = {
        'textarea'  : {
            'textarea' : 'TextArea',
            'editor'   : 'EditorTinymce'
        },

        'select'    : {
            'select-one'      : 'Combo',
            'select-multiple' : 'MultipleCombo',
            'list'            : 'List'
        },

        'input'     : {
            'text'      : 'TextField',
            'hidden'    : 'Hidden',
            'spin'      : 'SpinEdit',
            'date'      : 'DatePicker',
            'color'     : 'ColorPicker',
            'password'  : 'PasswordField',
            'radio'     : 'Radio',
            'checkbox'  : 'CheckBox',
            'file'      : 'File'
        }
    };

    /**
     * Initializes object
     * @param {Object} config Configuration object
     */
    GUI.Forms.Form.prototype.initialize = function (config) {
        // back support of Url parameter
        if (config) {
            if (config.url) {
                config.action = config.url;
                delete config.url;
            }
        }

        // Call parent initialize meethod
        superproto.initialize.call(this, config);
    };

    /**
     * Assigns form to the specified form
     * @param {HTMLElement/String} to
     */
    GUI.Forms.Form.prototype.assign = function (to) {
        to = GUI.$(to);
        if (!to || to.nodeName !== 'FORM') {
            return false;
        }

        this.dom = GUI.Dom.extend(to);
        this.dom.on('submit', this.onSubmit, this);
        this.dom.on('reset',  this.onReset, this);

        // update id
        if (GUI.isSet(to.id)) {
            GUI.ComponentMgr.unregister(this);
            this.id = to.id;
            GUI.ComponentMgr.register(this);
        } else {
            to.id = this.id;
        }

        var attrNode, disValidPopups, liveValidation, errorHolder, targetForm,
            xtype, tmp, cls, cfg, f,
            fields = [],
            defCfg = {},
            i = 0,
            len = 0,
            field = 0,
            tag = 0;

        attrNode = to.attributes.action; // The only right way to get attribute value for IE
        this.action = attrNode ? attrNode.nodeValue : '';

        attrNode = to.attributes.method;
        this.method = attrNode ? attrNode.nodeValue : 'post';

        targetForm = to.getAttribute('target');
        if (targetForm) {
            this.targetForm = targetForm;
        }

        disValidPopups = to.getAttribute('disableValidationPopups');
        if (disValidPopups === 'true') {
            this.disableValidationPopups = true;
        } else if (disValidPopups === 'false') {
            this.disableValidationPopups = false;
        }

        liveValidation = to.getAttribute('livevalidation');
        if (liveValidation === 'true') {
            this.livevalidation = true;
        } else if (liveValidation === 'false') {
            this.livevalidation = false;
        }

        errorHolder = to.getAttribute('errorHolder');
        if (errorHolder) {
            this.errorHolder = errorHolder;
        }

        this.initItems();

        fields = GUI.toArray(to.elements);
        defCfg = {
            disableValidationPopups : this.disableValidationPopups,
            livevalidation          : this.livevalidation
        };

        if (GUI.isSet(this.errorHolder)) {
            defCfg.errorNodeHolder = this.errorHolder;
        }

        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];

            if (field._jsguiComponent) {
                continue;
            }
            if (field.type === 'file') {
                this.fileUpload = true;
                continue;
            }
            tag = field.nodeName.toLowerCase();
            xtype = field.getAttribute('xtype') || field.type || 'text';
            tmp = undefined;
            cls = (tmp = this.assignMap[tag]) ? tmp[xtype] : undefined;

            if (cls) {
                cls = GUI.Forms[cls];
            }

            if (cls) {
                cfg = Object.extend({
                    assignTo: field
                }, defCfg);
                f = new cls(cfg);

                this.add(f); // to the items
                this.addField(f); // to the elements
            }
        }

        if (this.fileUpload) {
            if (GUI.isIE) {
                to.encoding = 'multipart/form-data';
            } else {
                to.enctype = 'multipart/form-data';
            }
            this.method = to.method = 'post';
        }
        return true;
    };

    GUI.Forms.Form.prototype.reassign = function () {
        var fields, i, len, tag, xtype, tmp, field, cls, cfg, f, defCfg;

        defCfg = {
            disableValidationPopups : this.disableValidationPopups,
            livevalidation          : this.livevalidation
        };

        var elements = GUI.toArray(this.dom.elements);

        fields = elements.filter(function (item) {
            return !item._jsguiComponent;
        });

        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];

            if (field.type === 'file') {
                this.fileUpload = true;
                continue;
            }
            tag = field.nodeName.toLowerCase();
            xtype = field.getAttribute('xtype') || field.type || 'text';
            tmp = undefined;
            cls = (tmp = this.assignMap[tag]) ? tmp[xtype] : undefined;

            if (cls) {
                cls = GUI.Forms[cls];
            }

            if (cls) {
                cfg = Object.extend({
                    assignTo: field
                }, defCfg);
                f = new cls(cfg);

                this.add(f); // to the items
                this.addField(f); // to the elements
            }
        }
    };

    /**
     * Call reset
     * @param {Event} e Event
     */
    GUI.Forms.Form.prototype.onReset = function (e) {
        e = GUI.Event.extend(e);
        e.preventDefault();
        this.reset();
    };

    /**
     * Reset all fields
     */
    GUI.Forms.Form.prototype.reset = function () {
        var fields = this.fields,
            i = fields.length;

        while (i--) {
            fields[i].reset();
        }
    };

    /**
     * Clear all fields
     */
    GUI.Forms.Form.prototype.clear = function () {
        var fields = this.fields,
            i = fields.length;

        while (i--) {
            fields[i].clear();
        }
    };

    /**
     *
     */
    GUI.Forms.Form.prototype.getField = function (name) {
        var res = [];
        this.fields.each(function (i) {
            if (i.name === name) {
                res.push(i);
            }
        });

        if (res.length === 1) {
            return res[0];
        } else if (res.length > 1) {
            return res;
        } else {
            return null;
        }
    };

    /**
     * Loads form fields values from server using ajax request
     * @param {Object} config {url: Url to load from, params: parameters to pass in request}
     */
    GUI.Forms.Form.prototype.load = function (config) {
        config = config || {};

        Object.extendIf(config, {
            url    : this.action,
            params : this.params
        });

        this.fireEvent('beforeLoad', this);

        this._loadRequest = new Ajax.Request(config.url, {
            method      : this.method,
            parameters  : GUI.toQueryString(config.params),
            scope       : this,
            onSuccess   : this.onLoadSuccess,
            onFailure   : this.onLoadFailure,
            onTimeout   : this.onLoadTimeout,
            timeout     : this.loadTimeout
        });
    };

    /**
     * Submit form values to server using ajax request
     * @param {Object} config {url: Url to submit to, params: parameters to pass in request}
     * @param {Event} e Event
     */
    GUI.Forms.Form.prototype.submit = function (config, e) {
        config = config || {};

        Object.extendIf(config, {
            url    : this.action,
            params : Object.clone(this.params || {})
        });

        var serialized = this.serialize(); // string :(

        if (this.fireEvent('beforeSubmit', this, config.params, serialized, e) === false) {
            if (e) {
                e.preventDefault();
            }
            return;
        }

        if (e) {
            e = GUI.Event.extend(e);
        }

        // Handle file upload
        if(this.sendForm) {
            return this._sendForm(config, e);
        } else if (this.fileUpload) {
            return this._iframeSubmit(config, e);
        } else {
            if (e) {
                e.stop();
            }
            return this._ajaxSubmit(config);
        }
    };

    /**
     * Validates field by calling validate() method on all fields
     * @return {Boolean} true - valid, false - invalid
     */
    GUI.Forms.Form.prototype.validate = function () {
        var valid = true,
            invalid_fields = [];

        this.fields.each(function (f) {
            if (!f.validate()) {
                invalid_fields.push(f);
                valid = false;
            }
        });

        if (valid) {
            this.fireEvent('valid', this);
        } else {
            this.fireEvent('invalid', this, invalid_fields);
        }

        return valid;
    };

    /**
     * Sets action
     * @param {String} action Action
     */
    GUI.Forms.Form.prototype.setAction = function (action) {
        this.action = action;
    };

    /**
     * Returns fields values as associative array with field names as keys
     */
    GUI.Forms.Form.prototype.getValues = function () {
        var i, field, name,
            fields = this.fields,
            len = fields.length,
            res = {};

        for (i = 0; i < len; i++) {
            field = fields[i];
            name = field.name;
            if (name) {
                if (field.type === 'checkbox') {
                    res[name] = field.isChecked();
                } else if (field.type === 'radio') {
                    if (field.isChecked()) {
                        res[name] = res[name] = field.getValue();
                    }
                } else {
                    res[name] = field.getValue();
                }
            }
        }
        return res;
    };

    /**
     * Sets fields values from data object
     * @param {Object} data Data
     */
    GUI.Forms.Form.prototype.setFieldsValues = function (data) {
        var name, field, value, len;

        data = this.convertJsonToNames(data);
        for (name in data) {
            value = data[name];

            if (typeof value !== 'function' && (field = this.getField(name))) {
                if (GUI.isArray(field)) {
                    len = field.length;
                    while (len--) {
                        this.setFieldValue(field[len], value);
                    }
                } else {
                    this.setFieldValue(field, value);
                }
            }
        }
    };

    /**
     * Sets field's value
     * @paran {Object} field
     * @param {String} value
     */
    GUI.Forms.Form.prototype.setFieldValue = function (field, value) {
        if ((field instanceof GUI.Forms.Combo) && (value instanceof Array)) {
            // Populate options of combo
            field.setOptions(value);

        } else if (field instanceof GUI.Forms.Radio) {
            if (field.getValue() === value) {
                field.setChecked();
            }
        } else if (field instanceof GUI.Forms.CheckBox) {
            field.setChecked(value);
        } else {
            field.setValue(value);
        }
    };

    /**
     * Converts json
     * @param {Object} object
     */
    GUI.Forms.Form.prototype.convertJsonToNames = function (object) {
        var key_, value,
            uri = {},
            i = 0,
            len = 0;

        function _toQueryString(object, prepend) {
            if (GUI.isNumber(object) || GUI.isString(object)
                    || GUI.isBoolean(object)) {
                // Number, string or boolean
                if (!(GUI.isString(prepend) && prepend !== '')) {
                    throw new Error("At first object must be passed!");
                }
                uri[prepend] = object;

            } else if (GUI.isArray(object)) {
                // Array
                if (!(GUI.isString(prepend) && prepend !== '')) {
                    throw new Error("2At first object must be passed!");
                }
                for (i = 0, len = object.length; i < len; i++) {
                    _toQueryString(object[i], prepend + '[' + i + ']');
                }

            } else if (GUI.isObject(object)) {
                // Object
                for (key_ in object) {
                    value = object[key_];
                    // Prototype hack
                    if (GUI.isFunction(value)) {
                        continue;
                    }

                    _toQueryString(value, (GUI.isString(prepend) && prepend !== '')
                        ? prepend + '[' + key_ + ']'
                        : key_
                        );
                }
            }
        }
        _toQueryString(object, '');
        return uri;
    };

    /**
     * Serializes form field values to the string
     * @param {String} serialized params
     */
    GUI.Forms.Form.prototype.serialize = function () {
        var field, name,
            elements = GUI.toArray(this.dom.elements),
            o = [],
            i = 0,
            len = 0;

        for (i = 0, len = elements.length; i < len; i++) {
            field = elements[i];
            if (!field.name) {
                continue;
            }

            name = encodeURIComponent(field.name);

            switch (field.nodeName) {
            case 'INPUT':
                switch (field.type) {
                case 'text':
                case 'password':
                case 'hidden':
                    o.push(name + '=' + encodeURIComponent(field.value));
                    break;

                case 'checkbox':
                case 'radio':
                    if (field.checked) {
                        o.push(name + '=' + encodeURIComponent(field.value));
                    }
                    break;
                }
                break;

            case 'TEXTAREA':
                o.push(name + '=' + encodeURIComponent(field.value));
                break;

            case 'SELECT':
                // unassigned combo or multiselect
                // TODO
                break;
            }
        }
        return o.join('&');
    };

    /**
     * Submit ajax request
     * @param {Object} cfg Config
     */
    GUI.Forms.Form.prototype._ajaxSubmit = function (cfg) {
        var p = this.serialize(),
            myAjax;
        if (p) {
            if (typeof p === 'string' && cfg.params) {
                p += '&' + GUI.toQueryString(cfg.params);
            }
        } else if (cfg.params) {
            p = GUI.toQueryString(cfg.params);
        }

        myAjax = new Ajax.Request(cfg.url, {
            method      : this.method,
            parameters  : p,
            scope       : this,
            onSuccess   : this.onAjaxSubmitSuccess,
            onFailure   : this.onAjaxSubmitFailure,
            onTimeout   : this.onAjaxSubmitTimeout,
            timeout     : this.submitTimeout
        });

        return true;
    };

    /**
     * Fire event 'afterSubmit'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onAjaxSubmitSuccess = function (response, request) {
        if (this.fireEvent) { // check if form is not destroyed
            this.fireEvent('afterSubmit', this, response, request);
        }
    };

    /**
     * Fire event 'invalidSubmit'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onAjaxSubmitFailure = function (response, request) {
        if (this.fireEvent) { // check if form is not destroyed
            this.fireEvent('invalidSubmit', this, response, request);
        }
    };

    /**
     * Fire event 'submitTimeout'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onAjaxSubmitTimeout = function (response, request) {
        if (this.fireEvent) { // check if form is not destroyed
            this.fireEvent('submitTimeout', this, response, request);
        }
    };

    /**
     * Send form without ajax and iframe. Send form as default <form>
     * @param {Object} cfg Configuration object
     * @param {Event} e Event
     */
    GUI.Forms.Form.prototype._sendForm = function (cfg, e) {
        this.fireEvent('beforeSubmit');
        this.dom.submit();
    };

    /**
     * Create html of iframe, add events 'load', 'error', add action and submit
     * @param {Object} cfg Configuration object
     * @param {Event} e Event
     */
    GUI.Forms.Form.prototype._iframeSubmit = function (cfg, e) {
        var params, param, input, bpDiv, dom, iframe,
            id = GUI.getUniqId('file-upload-iframe-'),
            d = document.createElement('div'),
            i = 0,
            len = 0;

        d.innerHTML = '<iframe style="display: none;" id="' + id + '" name="' + id + '" ></iframe>';

        document.body.appendChild(d);

        if (GUI.isIE) {
            document.frames[id].name = id;
        }

        this.iframe = GUI.$(id);

        if (cfg.params) {
            params = GUI.toQueryString(cfg.params);
            bpDiv = document.createElement('div');

            params = params.split('&');
            for (i = 0, len = params.length; i < len; i++) {
                param = params[i].split('=', 2);
                input = GUI.createInput(param[0]);

                input.type = 'hidden';
                if (GUI.isSet(param[1])) {
                    input.value = param[1];
                }
                bpDiv.appendChild(input);
            }
            this.dom.appendChild(bpDiv);
        }
        if (GUI.isIE) {
            this.iframe.src = 'javascript:false';
        }
        this.iframe.on('load', this.onIframeLoad, this);
        this.iframe.on('error', this.onIframeError, this);

        dom = this.dom;
        dom.target = this.targetForm || id;

        if (this.submitTimeout) {
            iframe = this.iframe;
            (function () {
                GUI.destroyNode(iframe.parentNode);
            }).defer(this.submitTimeout);
        }

        dom.enctype = "multipart/form-data";
        dom.charset = "utf-8";
        dom.method = "post";

        if (cfg.url) {
            if (dom.action && dom.action.nodeName) {
            } else {
                dom.action = cfg.url;
            }
        }

        if (!e) {
            // If form submitted using submit() method of GUI.Form.Form then need
            // to call submit() on html element
            GUI.Ajax.Request.counts = GUI.Ajax.Request.counts || 0;
            GUI.Ajax.Request.counts += 1;

            this.dom.submit();
        }

        if (cfg.params) {
            (function () {
                if (bpDiv) {
                    GUI.destroyNode(bpDiv);
                }
            }).defer(20);
        }
        return false;
    };

    /**
     * Handler on load iframe, fire event 'afterSubmit'
     */
    GUI.Forms.Form.prototype.onIframeLoad = function () {
        var d, b,
            i = this.iframe,
            responseText = '',
            response = {},
            responseJson = {};

        GUI.Ajax.Request.counts -= 1;

        if (i.contentDocument) {
            d = i.contentDocument;
        } else if (i.contentWindow) {
            d = i.contentWindow.document;
        } else {
            d = i.document;
        }

        if (d.location.href === 'javascript:false') {
            return this.onIframeError();
        }

        i.un();

        responseText = d.body.innerHTML;
        b = d.body;

        if (b.firstChild && b.firstChild.nodeName !== '#text') {
            responseText = b.firstChild.innerHTML;
        } else {
            responseText = b.innerHTML;
        }

        // for tags in text
        responseText = GUI.html_entity_decode(responseText);

        response = {
            responseText: responseText
        };

        if (this.jsonExpected) {
            responseJson = null;
            try {
                responseJson = responseText.evalJSON();
            } catch (e) {}

            if (responseJson === null) {
                GUI.Ajax.Request._observable.fireEvent('invalidjson', response, this);
            }
            response.responseJson = responseJson;
        }

        d = null;
        this.fireEvent('afterSubmit', this, response);

        (function () {
            GUI.destroyNode(i.parentNode);
        }).defer(100);

        return true;
    };

    /**
     * Handler on error load iframe, fire event 'invalidSubmit'
     */
    GUI.Forms.Form.prototype.onIframeError = function () {
        var d,
            i = this.iframe,
            response = {};

        GUI.Ajax.Request.counts -= 1;

        i.un();
        if (i.contentDocument) {
            d = i.contentDocument;
        } else if (i === i.contentWindow) {
            d = i.contentWindow.document;
        } else {
            d = i.document;
        }

        response = {
            responseText: d.body.innerHTML
        };

        this.fireEvent('invalidSubmit', this, response);

        (function () {
            GUI.destroyNode(i.parentNode);
        }).defer(100);

        return true;
    };

    /**
     * Init component, add events 'filterLoadData', 'afterLoad', 'afterSubmit',
     * 'beforeLoad', 'beforeSubmit', 'invalid', 'invalidLoad',
     * 'invalidSubmit', 'loadTimeout', 'submitTimeout', 'valid'
     */
    GUI.Forms.Form.prototype.initComponent = function () {
        superproto.initComponent.call(this);
        this.addEvents(
            'filterLoadData',
            'afterLoad',
            'afterSubmit',
            'beforeLoad',
            'beforeSubmit',
            'invalid',
            'invalidLoad',
            'invalidSubmit',
            'loadTimeout',
            'submitTimeout',
            'valid'
        );

        this.fields = [];
    };

    /**
     * Add field
     * @param {Object} f Field
     */
    GUI.Forms.Form.prototype.addField = function (f) {
        this.fields.push(f);
    };

    /**
     * Init fields, add each to form
     */
    GUI.Forms.Form.prototype.initFields  = function () {
        var form = this,
            fn = {};

        // Adds fields from all nested containers to the form
        fn = function (c) {
            if (c.isFormField) {
                // Add form field
                form.addField(c);
            } else if (c.doLayout && c !== form) {
                // Add items from the nested container
                if (c.items) {
                    c.items.each(fn);
                }
            }
        };
        this.items.each(fn);
    };

    /**
     * Creates html element of form
     * @returns {HTMLElement} fom
     */
    GUI.Forms.Form.prototype.onRender = function () {
        this.initFields();

        var dom = document.createElement('form');
        return dom;
    };

    /**
     * Handler after render, add events 'submit', 'reset'
     */
    GUI.Forms.Form.prototype.onAfterRender = function () {
        var f = this.dom;
        f.id = this.getId();
        if (this.action) {
            f.action = this.action;
        }
        f.method = this.method;

        this.dom.on('submit', this.onSubmit, this);
        this.dom.on('reset', this.onReset, this);
    };

    /**
     * Abort load request
     * @param {Boolean} fast
     */
    GUI.Forms.Form.prototype.onDestroy = function (fast) {
        if (this._loadRequest && !this._loadRequest.complete) {
            this._loadRequest.abort();
            this._loadRequest = null;
        }
        superproto.onDestroy(this, fast);
    };

    /**
     * Handler load success, fire events 'filterLoadData', 'afterLoad'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onLoadSuccess = function (response, request) {
        if (!this.fireEvent) {
            return; // form is destroyed
        }

        try {
            this.data = request.responseJson;
            this.fireEvent('filterLoadData', this, this.data);
            // TODO!
            this.setFieldsValues(this.data);
            this.fireEvent('afterLoad', this, this.data);
            delete this.data;
        } catch (e) {
            alert('Exception!' + e.message);
        }
    };

    /**
     * Fire event 'invalidLoad'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onLoadFailure = function (response, request) {
        if (this.fireEvent) {
            this.fireEvent('invalidLoad', this, response, request);
        }
    };

    /**
     * Fire event 'loadTimeout'
     * @param {Object} response
     * @param {Object} request
     */
    GUI.Forms.Form.prototype.onLoadTimeout = function (reponse, request) {
        if (this.fireEvent) {
            this.fireEvent('loadTimeout', this, response, request);
        }
    };

    /**
     * If form is valid submit request
     * @param {Event} e Event
     */
    GUI.Forms.Form.prototype.onSubmit = function (e) {
        e = GUI.Event.extend(e);
        if (this.validate()) {
            this.submit(null, e);
        } else {
            e.stop();
        }
        return true;
    };

}());
