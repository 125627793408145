(function () {
    var superproto = '';

    /**
     * JavaScript Graphical User Interface
     * Element implementation
     * This component is used to render html text wrapped in span on the page.
     * It implements basic rendering/unredering, enabling/disabling,
     * showing/hiding and other common functionality.
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    GUI.Element = Class.create();

    superproto = GUI.Utils.Draggable.prototype;
    Object.extend(GUI.Element.prototype, superproto);

    /**
     * Initializes object
     * @param {Object} config configuration object
     * <b>id</b>        <i>{String}</i>
     *  Element's id to set. <br />
     * <b>html</b>      <i>{String}</i>
     *  Element's HTML text<br />
     * <b>holder</b>    <i>{String|HtmlElement}</i>
     *  Holder to render element to.<br />
     * <b>className</b> <i>{Stirng}</i>
     *  Element's class name.<br />
     * <b>disabled</b>  <i>{Boolean}</i>
     *  Only logical implementation if disabling/enabling.
     *  Does not affect element’'s visual representation. Defaults to false.<br />
     * <b>visible</b>   <i>{Boolean}</i>
     *  False to render element hidden(css style 'display' set to 'none').
     *  Defaults to true.<br />
     */
    GUI.Element.prototype.initialize = function (config) {
        // Call parent initialize meethod
        superproto.initialize.call(this, config);

        var cfg = this.config;
        // Extend parent config
        Object.extend(cfg, {
            id          : undefined,
            html        : undefined,
            holder      : undefined,
            className   : 'b-toolbar__txt',
            disabled    : false,
            visible     : true,
            withoutSpan : false
        });

        Object.extend(cfg, config);

        this.elementType = 'GUI.Element';
        this.disabled = cfg.disabled ? 1 : 0;
        this.visible = cfg.visible;

        // Add events
        this.addEvents({
            beforeRender: true,
            afterRender : true,
            show    : true,
            hide    : true
        });

        // Add event listeners from config
        if (typeof cfg.onBeforeRender === 'function') {
            this.on('beforeRender', cfg.onBeforeRender, this);
        }
        if (typeof cfg.onAfterRender === 'function') {
            this.on('afterRender', cfg.onAfterRender, this);
        }
        if (typeof cfg.onShow === 'function') {
            this.on('show', cfg.onShow, this);
        }
        if (typeof cfg.onHide === 'function') {
            this.on('hide', cfg.onHide, this);
        }
    };

    /**
     * Destroy objets
     * @param {Boolean} fast
     */
    GUI.Element.prototype.destroy = function (fast) {
        if (this.dom) {
            this.unrender(fast);
        }
        this.purgeListeners();
        GUI.destroyObject(this);
    };

    /**
     * Renders element to holder, if one is not passed as argument,
     * else replaces holder in config with passed
     * Fires events 'beforeRender', 'afterRender'
     * @param {String} elem Optional id of container to render to
     */
    GUI.Element.prototype.render = function (to) {
        if (!this.fireEvent('beforeRender', this)) {
            return;
        }

        if (this.dom) {
            this.unrender();
        }

        var cfg = this.config;

        if (to) {
            cfg.holder = to;
        } else {
            to = cfg.holder;
        }
        to = GUI.$(to);

        if (!to) {
            throw new Error(this.elementType + ' error in method render(): No holder ' + to + ' found');
        }

        this._render(to);
        this.attachEventListeners();

        this.fireEvent('afterRender', this);
    };

    /**
     * Remove event listeners, sets dom to null.
     * @param {Boolean} fast
     */
    GUI.Element.prototype.unrender = function (fast) {
        if (!this.dom) {
            return false;
        }
        this.removeEventListeners();
        if (!fast) {
            GUI.destroyNode(this.dom);
        }
        this.dom = null;
    };

    /**
     * Shows hidden rendered element. Sets css style 'display' to 'block'
     * Fires event 'show'
     */
    GUI.Element.prototype.show = function () {
        if (!this.visible) {
            this.visible = true;
            if (this.dom) {
                GUI.show(this.dom);
            }
            this.fireEvent('show', this);
        }
    };

    /**
     * Hides rendered element. Sets css style 'display' to 'none'
     * Fires event 'hide'
     */
    GUI.Element.prototype.hide = function () {
        if (this.visible) {
            this.visible = false;
            if (this.dom) {
                GUI.hide(this.dom);
            }
            this.fireEvent('hide', this);
        }
    };

    /**
     * If disabled === 1 disable element
     * @param {Boolean} flag If false enable element
     */
    GUI.Element.prototype.disable = function (flag) {
        if ((flag !== undefined) && (!flag)) {
            this.enable();
        } else {
            this.disabled++;
            if (this.disabled === 1) {
                // First time disabling
                this._disable();
            }
        }
    };

    /**
     * empty function
     */
    GUI.Element.prototype._disable = function () {
        // empty
    };

    /**
     * If disabled === 0 enale element
     */
    GUI.Element.prototype.enable = function () {
        if (this.disabled > 0) {
            this.disabled--;
            if (this.disabled === 0) {
                this._enable();
            }
        }
    };

    /**
     * empty function
     */
    GUI.Element.prototype._enable = function () {
        // empty
    };

    /**
     * Checks if element is enabled
     * @return {Boolean} true - enabled, false - disabled
     */
    GUI.Element.prototype.isEnabled = function () {
        return !this.disabled;
    };

    /**
     * Checks if element is enabled
     * @return {Boolean} true - disabled, false - enabled
     */
    GUI.Element.prototype.isDisabled = function () {
        return !!this.disabled;
    };

    // Getters/setters

    /**
     * Gets element id
     * @return {String} Element's id
     */
    GUI.Element.prototype.getId = function () {
        return this.config.id;
    };

    /**
     * Gets id of elements' holder
     * @return {String} holder's id
     */
    GUI.Element.prototype.getHolder = function () {
        return this.config.holder;
    };

    /**
     * Gets html code of element
     * @return {String} Html code of element
     */
    GUI.Element.prototype.getHtml = function () {
        return this.config.html;
    };

    /**
     * Gets visibility of element
     * @return {Boolean} true - visible, false - hidden
     */
    GUI.Element.prototype.getVisibility = function () {
        var id = this.config.id;
        if (GUI.$(id)) {
            if ((GUI.$(id).style.display === 'none') || (GUI.$(id).style.visibility === 'hidden')) {
                return false;
            } else {
                return true;
            }
        }

        throw new Error(this.elementType + ' error in method getVisibility(). Invalid id: ' + id);
    };

    /**
     * Get DOMNode of element
     * @return {Object} Elements' DOMNode
     */
    GUI.Element.prototype.getDom = function () {
        return this.dom;
    };

    /**
     * Set elements' html code
     * @param {Object} html Html code to set
     */
    GUI.Element.prototype.setHtml = function (html) {
        this.config.html = html;
    };

    /**
     *
     */
    GUI.Element.prototype.updateHtml = function (html) {
        this.dom.innerHTML = html;
    };

    /**
     * Renders DOM tree and sets this.dom to root of this tree
     * @return {Object} rendered DOMNode object
     */
    GUI.Element.prototype._render = function (to) {
        if (!to) {
            throw new Error('Can not render to:' + to);
        }
        this.holder = to;

        var span,
            cfg = this.config;

        if (cfg.withoutSpan) {
            if (typeof cfg.html === 'string') {
                to.innerHTML = cfg.html;
            } else {
                to.appendChild(cfg.html);
            }
            this.dom = to;
        } else {
            span = document.createElement('SPAN');
            to.appendChild(span);
            if (GUI.isString(cfg.id)) {
                span.id = cfg.id;
            }
            if (GUI.isString(cfg.html)) {
                span.innerHTML = cfg.html;
            }
            if (GUI.isString(cfg.className)) {
                span.className = cfg.className;
            }
            this.dom = span;
        }
        if (!this.visible) {
            GUI.hide(this.dom);
        }
    };

    /**
     * empty function
     */
    GUI.Element.prototype.attachEventListeners = function () {
        // empty
    };

    /**
     * empty function
     */
    GUI.Element.prototype.removeEventListeners = function () {
    // empty
    };

}());