(function () {
    var superproto = GUI.Utils.Draggable.prototype;

    /**
     * JavaScript Graphical User Interface
     * GridFiltersLayout implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Draggable
     */
    window.GridFiltersLayout = Class.create();
    Object.extend(window.GridFiltersLayout.prototype, superproto);

    /**
     * Constructor of GridFiltersLayout
     * @param {Object} config Configuration object
     */
    window.GridFiltersLayout.prototype.initialize = function (config) {
        var defCfg, cfg;

        // default config
        defCfg =  {
            holder      : null,
            toolbar     : null,
            grid        : null,
            editable    : false,
            paging      : null,
            loadOnRender: true,
            resizer     : false,
            // Id autoconfiguration
            toolbarId   : GUI.getUniqId('gfl-toolbar-'),
            tabbarId    : GUI.getUniqId('gfl-tabbar-'),
            groupActionsId    : GUI.getUniqId('gfl-groupActions-'),
            panelId     : GUI.getUniqId('gfl-panel-'),
            gridId      : GUI.getUniqId('gfl-grid-'),

            // Drag'n'Drop configuration
            dd: {
                linkedElId  : null,
                dragElId    : null,
                handleElId  : null,
                isTarget    : false,
                moveOnly    : true,
                groups      : {
                    'default' : true
                },
                enableDrag  : false,
                dropReciever: false,
                dragType    : ''
            }
        };

        Object.extend(defCfg, config);

        superproto.initialize.call(this, defCfg);
        cfg = this.config;

        this.id = GUI.getUniqId('globalfilterslayout-');
        this.resizeElId = this.id + '-resizeEl';
        // Init dd id's
        cfg.dd.linkedElId  = this.resizeElId;
        cfg.dd.handleElId  = this.resizeElId;
        this.ddConfig      = this.config.dd;

        this.hasToolbar = !!cfg.toolbar;
        this.hasGroupsActions = !!cfg.groupActions;
        this.toolbar    = null;
        this.tabbar     = null;
        this.filterToolbars = new GUI.Utils.Collection();
        this.grid       = null;
        this.paging     = null;
        GUI.ComponentMgr.register(this);
        GUI.registerObject(this);
    };

    /**
     * Destroys objects
     */
    window.GridFiltersLayout.prototype.destroy = function () {
        if (this.resizer) {
            this.resizer.destroy(true);
        }

        this.filterToolbars.each(function (item) {
            item.destroy(true);
        });
        this.filterToolbars.clear();
        this.filterToolbars = null;

        if (this.toolbar) {
            this.toolbar.destroy(true);
            this.toolbar = null;
        }

        if (this.tabbar) {
            this.tabbar.destroy(true);
            this.tabbar = null;
        }
        if (this.groupActions) {
            this.groupActions.destroy(true);
            this.groupActions = null;
        }
        if (this.paging) {
            this.paging.destroy(true);
            this.paging = null;
        }
        if (this.grid) {
            this.grid.destroy(true);
            this.grid = null;
        }
        var holder = GUI.$(this.config.holder);
        if (holder) {
            holder.innerHTML = '';
        }
        holder = null;
        GUI.ComponentMgr.unregister(this);
        GUI.destroyObject(this);
    };

    /**
     * Renders global widget to holder or passed element id
     * @param {Object} elem
     */
    window.GridFiltersLayout.prototype.render = function (elem) {
        var cfg, toolbarHolder, panel, i, id, len, cont,
            gridClass, ga, pagingCfg,
            items_tabbar, item,
            o = [];

        cfg = this.config;

        if (elem !== undefined) {
            cfg.holder = elem;
        }

        GUI.Dom.addClass(cfg.holder, 'b-grid_filters_layout');

        // Render base html code
        if (this.hasToolbar) {
            o.push('<div id="' + cfg.toolbarId + '" class="b-gridbar">');
            o.push('<div id="' + cfg.panelId + '" ></div>');
            o.push('</div>');
        }
        o.push('<div class="b-content-scrollable" id="' + cfg.gridId + '"></div>');

        if (this.hasGroupsActions) {
            o.push('<div id="' + cfg.groupActionsId + '" class="b-gridstatusbar b-toolbar b-paging b-buttons-list">');
                o.push('<div id="' + this.id + '-group-actions" class="b-gridstatusbar__actions">' +
                        '<i class="b-gridstatusbar__icon"></i>' +
                    '</div>');
            o.push('</div>');
        } else {
            o.push('<div id="' + cfg.groupActionsId + '" class="b-gridstatusbar b-toolbar b-paging b-buttons-list">');
            o.push('</div>');
            //cfg.holder.addClass(' b-content-scrollable_no-grid-status-bar ');
        }

        // Convert array to text
        o = o.join('');

        // Paste html into container
        GUI.$(cfg.holder).innerHTML = o;

        // Now we can render toolbars and tabbars to placeholders
        if (this.hasToolbar && (cfg.toolbar instanceof GUI.ToolBar)) {
            // render top left toolbar
            this.toolbar = cfg.toolbar;

            if (this.toolbar.sidebar) {
                panel = GUI.$(cfg.panelId);
                items_tabbar = this.toolbar.sidebar.elements.items;
                len = items_tabbar.length;
                for (i = 0; i < len; i++) {
                    item = items_tabbar[i];
                    id = GUI.getUniqId('gfl-bar-');

                    item.content = item.content || '';

                    if (typeof (item.content) === 'object') {
                        item.content._render(panel);
                        item.content.dom.id = id;
                        item.content.dom.style.display = 'none';
                    } else {
                        cont = document.createElement('div');
                        cont.is = id;
                        cont.className = 'b-gridbar__toggle-area b-buttons-list';
                        panel.appendChild(cont);
                        cont.innerHTML = item.content;
                    }
                    item.assigned = id;
                }
            }
            toolbarHolder = GUI.$(cfg.toolbarId);
            this.toolbar.render(toolbarHolder);
        }

        if (cfg.grid) {
            // Render grid
            gridClass = (cfg.editable) ? GUI.EditableGrid : GUI.Grid;

            this.grid = new gridClass(cfg.grid);
            if (cfg.groupActions) {
                cfg.groupActions.setGrid(this.grid);
                this.groupActions = cfg.groupActions;
            }

            this.grid.render(cfg.gridId);

            if (this.groupActions) {
                this.groupActions.render(this.id + '-group-actions');
                this.groupActions.update();
            }

            if (cfg.paging) {
                // Render paging
                pagingCfg = {};
                Object.extend(pagingCfg, cfg.paging);
                if (pagingCfg.rendererObj !== false) {
                    pagingCfg.rendererObj = this.grid;
                }

                pagingCfg.align = 'right';
                pagingCfg.renderAfterBeginHolder = false;

                this.paging = new GUI.Ajax.Paging(pagingCfg);
                this.paging.render(cfg.groupActionsId);
                if (cfg.loadOnRender) {
                    this.paging.load({});
                }

                this.paging.toolbar.dom.removeClass('b-toolbar');
                this.paging.toolbar.dom.removeClass('b-toolbar_clear');
                // Render resizer
                if (cfg.resizer) {
                    this.resizer = new GUI.PagingResizer({
                        paging: this.paging
                    });
                    this.resizer.render(GUI.$(cfg.groupActionsId));
                }
            }
        }
    };

    /**
     * Render toolbar and add it to collection
     * @param {Object} bar
     * @param {String|Number} id
     */
    window.GridFiltersLayout.prototype.addFilterBar = function (bar, id) {
        if (bar.obj instanceof GUI.ToolBar) {
            this.filterToolbars.add(bar.name, bar.obj);
            bar.obj.render(id);
        }
    };

    /**
     * Get filter's toolbar by its name
     * @param {Object} name Name of toolbar to get
     */
    window.GridFiltersLayout.prototype.getFilterBar = function (name) {
        return this.filterToolbars.get(name);
    };

    /**
     * Returns id
     * @returns {String|Number} id
     */
    window.GridFiltersLayout.prototype.getId = function () {
        return this.id;
    };

}());
