(function () {
    var superproto = GUI.Utils.Observable.prototype;

    /**
     * JavaScript Graphical User Interface
     * Accordion implementation
     *
     * @author Eugene Lyulka
     * @version 2.0
     * @namespace GUI
     * @extends GUI.Utils.Observable
     */
    GUI.Accordion = Class.create();
    Object.extend(GUI.Accordion.prototype, superproto);

    /**
     * Constructor
     * @param {Object} config Configuration object
     */
    GUI.Accordion.prototype.initialize = function (config) {
        superproto.initialize.call(this);

        var cfg, items, i, item;

        this.config =  {
            id      : GUI.getUniqId('accordion-'),
            holder  : null,
            items   : [],
            disable : false
        };
        cfg = this.config;
        Object.extend(cfg, config);

        if (cfg.disable) {
            return;
        }

        this.currentOpened = null;
        this.lastHeight = null;

        // Process items
        items = this.config.items;
        for (i = 0; i < items.length; i++) {
            item = items[i];
            if (item.isExpanded()) {
                if (this.currentOpened) {
                    this.currentOpened.collapse();
                }
                this.currentOpened = item;
            }
            item.on('collapse', this.onItemCollapse.bindLegacy(this));
            item.on('expand', this.onItemExpand.bindLegacy(this));
        }
    };

    /**
     * Returns height of the quickbar
     * @returns {Number} height
     */
    GUI.Accordion.prototype.getQuickBarHeight = function () {
        var items = this.config.items,
            len = items.length,
            h = GUI.getClientHeight(GUI.$(this.config.holder).parentNode);

        h -= (len - 1) * 27;
        return (h < 0) ? 0 : h;
    };

    /**
     * Updates height of the quickbar
     */
    GUI.Accordion.prototype.updateItemsHeight = function () {
        var items = this.config.items,
            len = items.length,
            h = this.getQuickBarHeight();

        if (this.lastHeight !== h) {
            this.lastHeight = h;
            while (len--) {
                items[len].setHeight(h);
            }
        }
    };

    /**
     * Destroys objects
     * @param {Boolean} quick
     */
    GUI.Accordion.prototype.destroy = function (quick) {
        var i,
            items = this.config.items,
            len = items.length;

        for (i = 0; i < len; i++) {
            items[i].destroy(quick);
        }
        if (this.attachedEvent) {
            GUI.Event.unWindowResize(this.onWindowResize, this);
            this.attachedEvent = null;
        }
        this.config.items = null;
        this.config.holder = null;
    };

    /**
     * Renders all items
     * @param {HTMLElement} to Element to render items to
     */
    GUI.Accordion.prototype.render = function (to) {
        if (to) {
            this.config.holder = to;
        }

        var i, h, holder,
            items = this.config.items,
            len = items.length;

        if (!this.config.disable) {
            h = this.getQuickBarHeight();
            holder = GUI.$(this.config.holder);

            for (i = 0; i < len; i++) {
                items[i].setHeight(h);
                items[i].render(holder);
            }
            GUI.Event.onWindowResize(this.onWindowResize, this);
            this.attachedEvent = true;
        } else {
            for (i = 0; i < len; i++) {
                items[i].render(this.config.holder);
            }
        }
    };

    /**
     * Method update height calls
     * @param {Event} e Event
     */
    GUI.Accordion.prototype.onWindowResize = function (e) {
        this.updateItemsHeight();
    };

    /**
     * If current item is open, collapses it, sets new current opened item
     * @param {Object} item
     */
    GUI.Accordion.prototype.onItemExpand = function (item) {
        if (this.currentOpened) {
            this.currentOpened.collapse();
        }
        this.currentOpened = item;
    };

    /**
     * Sets current opened item to nul
     * @param {Object} item
     */
    GUI.Accordion.prototype.onItemCollapse = function (item) {
        this.currentOpened = null;
    };

}());