(function () {
    var superproto = GUI.Utils.Observable.prototype;

    GUI.BackboneRouterBridge = Class.create();
    Object.extend(GUI.BackboneRouterBridge.prototype, superproto);

    GUI.BackboneRouterBridge.prototype.initialize = function (cfg) {
        this.addEvents('onHistoryInit');
        this.isInited = false;

        this.config = cfg;
        this.backboneRouter = cfg.backboneRouter;
        this.router = new PrivateRouter(cfg.routerCfg);
        this.historyStates = [];

        setTimeout(function () {
            this.isInited = true;
            Backbone.history.start();
            this.fireEvent('onHistoryInit');
        }.bindLegacy(this), 50);

        this.backboneRouter.route('*notFound', '*notFound', this._notFoundHandle.bindLegacy(this));

        Backbone.history.on('route', this._onRouteChange, this);
    };

    GUI.BackboneRouterBridge.prototype.navigate = function (hash, newWindow) {
        if (!newWindow) {
            if (window.systemSilentReload) {
                Backbone.history.navigate(hash, {trigger: false});
                location.reload();
                return;
            }

            Backbone.history.navigate(hash, {trigger: true});
            this.router.navigate(hash);
        } else {
            window.open('//' + window.account_host + '/admin#' + hash, '_blank');
        }
    };

    GUI.BackboneRouterBridge.prototype.getHash = function () {
        return Backbone.history.getHash();
    };

    GUI.BackboneRouterBridge.prototype._notFoundHandle = function (path) {
        if (
            this.backboneRouter.currentController &&
            this.backboneRouter.currentController[this.backboneRouter.currentMethod + 'Leave']
        ) {
            this.backboneRouter.currentController[this.backboneRouter.currentMethod + 'Leave']('old');
            this.backboneRouter.currentController = null;
            this.backboneRouter.currentMethod = null;
        }

        return false;
    };

    GUI.BackboneRouterBridge.prototype._onRouteChange = function (router, methodName, args) {
        if (!window.location.lasthash) {
            window.location.lasthash = ['-1'];
        }

        window.location.lasthash.push(window.location.hash);

        if (window.location.lasthash.length > 3) {
            window.location.lasthash.shift();
        }

        var module = window.location.hash.split('/')[0].split('?')[0].replace('#', ''),
            item = this.router.mainMenu.getItemByName(module);

        if (methodName !== '*notFound') {
            if (!item) {
                return;
            }

            item.route = window.location.hash.replace('#', '');
            this.router.mainMenu.setSelectedItem(
                item,
                false
            );

            return;
        } else {
            if (item) {
                item.route = window.location.hash.replace('#', '');
            }
        }

        var hash = args[0].notFound;
        var argsList = [];

        ['q', 'sort', 'sort2'].reverse().each(function (key) {
            if (args[0][key]) {
                argsList.push(key + '=' + args[0][key])
            }
        });

        if (argsList) {
            hash += '?' + (argsList.join("&"));
        }

        this.historyStates.push(hash);

        this.router.historyListener(hash);
    };

    GUI.BackboneRouterBridge.prototype.switchToLayout = function (name) {
        this.router.appLayout.switchToLayout(name);
        return this.getCurrentLayout();
    };

    GUI.BackboneRouterBridge.prototype.getCurrentLayout = function () {
        return this.router.appLayout.getLayout(
            this.router.appLayout.currentLayout
        );
    };

    GUI.BackboneRouterBridge.prototype.goToModule = function () {
        this.router.goToModule.apply(this.router, arguments);
    };

    // Private extend for GUI.Router
    var routerSuperproto = GUI.Router.prototype;
    var PrivateRouter = Class.create();
    Object.extend(PrivateRouter.prototype, routerSuperproto);

    PrivateRouter.prototype.initialize = function (cfg) {
        Object.extend(this, cfg);

        this.historyStates = [];
        this.mainMenu = this.appLayout.mainMenu;
    };
    
    PrivateRouter.prototype.navigate = function (hash) {
        this.setParametres(hash);
        var target = this.mainMenu._indexByName[this.moduleName];

        if (target === undefined) {
            for (var item in this.mainMenu._indexByName) {
                if (!this.mainMenu._indexByName.hasOwnProperty(item)) {
                    continue;
                }

                if (!this.mainMenu._indexByName[item].sub) {
                    continue;
                }

                for (var hoverItem in this.mainMenu._indexByName[item].sub.config.items) {
                    if (!this.mainMenu._indexByName[item].sub.config.items.hasOwnProperty(hoverItem)) {
                        continue;
                    }

                    if (hoverItem.name === this.moduleName) {
                        target = hoverItem;
                    }
                }
            }
        }

        this.mainMenu.setSelectedItem(target);
    };

})();