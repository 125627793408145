/**
 * JavaScript Graphical User Interface Object Library
 * GUI.Popup.Region class
 *
 * @author Ilya Zharovsky, Eugene Lyulka
 * @version 2.0
 * @namespace GUI.Popup
 */
GUI.Popup.Region = Class.create();

/**
 * Start value for zIndex, default is 10000
 * @type Number
 */
GUI.Popup.Region.startzIndex = 10000;

/**
 * Global zIndex counter. Each new region increments this value by 5
 * @type Function
 */
GUI.Popup.Region.zIndexCounter = GUI.Popup.Region.startzIndex;

/**
 * Returns zIndex increments on 5
 * @returns {Number} zIndex
 */
GUI.Popup.Region.getzIndex = function () {
    var val = GUI.Popup.Region.zIndexCounter;
    GUI.Popup.Region.zIndexCounter += 5;
    return val;
};

var superproto = GUI.Utils.Draggable.prototype;
Object.extend(GUI.Popup.Region.prototype, superproto);

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.paddingLeft = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.paddingRight = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.paddingTop = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.paddingBottom = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.borderLeft = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.borderRight = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.borderTop = 0;

/**
 * Default is 0
 * @type Number
 */
GUI.Popup.Region.prototype.borderBottom = 0;

/**
 * Constructor of GUI.Popup.Region
 * @param {Object} config Configuration object
 */
GUI.Popup.Region.prototype.initialize = function (config) {
    GUI.Utils.Draggable.prototype.initialize.apply(this, arguments);
    var cfg = this.config;

    Object.extend(cfg, {
        parentNode  : null,
        content     : '',
        id          : GUI.getUniqId('region-'),
        className   : '',
        dimensions  : {
            top : 0,
            left: 0
        },
        destroyOnHide: true,
        padding     : '',
        border      : '',
        useShim     : false // GUI.isIE // use shims only in ie by def
    });

    Object.extend(cfg, config);

    this.visible = false;
    this.alignArgs = [];

    // Parse paddings and borders string
    this.setPadding(cfg.padding);
    this.setBorder(cfg.border);

    this.addEvents({
        show        : true,
        beforeHide  : true,
        hide        : true
    });

};

/**
 * Destroys objects
 */
GUI.Popup.Region.prototype.destroy = function () {
    if (this.config.destroyOnHide && this.visible) {
        this.hide();
    } else if (!this.config.destroyOnHide && this.dom) {
        GUI.destroyNode(this.dom);
        this.dom = null;
    }

    this.config.parentNode = null;
};

/**
 * Sets padding
 * @param {String} str String with padding connected space
 */
GUI.Popup.Region.prototype.setPadding = function (str) {
    var t, r, b, l,
        arr = str.split(' ', 4);
    t = r = b = l = 0;
    t = arr[0] || 0;
    r = (arr[1] === null || arr[1] === undefined) ? t : arr[1];
    b = (arr[2] === null || arr[2] === undefined) ? t : arr[0];
    l = (arr[3] === null || arr[3] === undefined)
        ? ((arr[1] === null || arr[1] === undefined) ? t : r)
        : arr[0];

    this.paddingLeft   = l - 0;
    this.paddingRight  = r - 0;
    this.paddingTop    = t - 0;
    this.paddingBottom = b - 0;
};

/**
 * Sets borders
 * @param {String} str String with borders connected space
 */
GUI.Popup.Region.prototype.setBorder = function (str) {
    var t, r, b, l,
        arr = str.split(' ', 4);

    t = r = b = l = 0;
    t = (arr[0] === null || arr[0] === '') ? 0 : arr[0];
    r = (arr[1] === null || arr[1] === undefined) ? t : arr[1];
    b = (arr[2] === null || arr[2] === undefined) ? t : arr[0];
    l = (arr[3] === null || arr[3] === undefined)
        ? ((arr[1] === null || arr[1] === undefined) ? t : r)
        : arr[0];

    this.borderLeft     = l - 0;
    this.borderRight    = r - 0;
    this.borderTop      = t - 0;
    this.borderBottom   = b - 0;
};

/**
 * Aligns region with another element relative to the specified anchor points. If the other element is the
 * document it aligns it to the viewport.
 * The position parameter is optional, and can be specified in any one of the following formats:
 * <ul>
 *   <li><b>Blank</b>: Defaults to aligning the element's top-left corner to the target's bottom-left corner ("tl-bl").</li>
 *   <li><b>One anchor (deprecated)</b>: The passed anchor position is used as the target element's anchor point.
 *       The element being aligned will position its top-left corner (tl) to that point.  <i>This method has been
 *       deprecated in favor of the newer two anchor syntax below</i>.</li>
 *   <li><b>Two anchors</b>: If two values from the table below are passed separated by a dash, the first value is used as the
 *       element's anchor point, and the second value is used as the target's anchor point.</li>
 * </ul>
 * In addition to the anchor points, the position parameter also supports the "?" character.  If "?" is passed at the end of
 * the position string, the element will attempt to align as specified, but the position will be adjusted to constrain to
 * the viewport if necessary.  Note that the element being aligned might be swapped to align to a different position than
 * that specified in order to enforce the viewport constraints.
 * Following are all of the supported anchor positions:
    <pre>
    Value  Description
    -----  -----------------------------
    tl     The top left corner (default)
    t      The center of the top edge
    tr     The top right corner
    l      The center of the left edge
    c      In the center of the element
    r      The center of the right edge
    bl     The bottom left corner
    b      The center of the bottom edge
    br     The bottom right corner
    </pre>
    Example Usage:
    <pre><code>
    // align el to other-el using the default positioning ("tl-bl", non-constrained)
    el.alignTo("other-el");

    // align the top left corner of el with the top right corner of other-el (constrained to viewport)
    el.alignTo("other-el", "tr?");

    // align the bottom right corner of el with the center left edge of other-el
    el.alignTo("other-el", "br-l?");

    // align the center of el with the bottom left corner of other-el and
    // adjust the x position by -6 pixels (and the y position by 0)
    el.alignTo("other-el", "c-bl", [-6, 0]);
    </code></pre>
 * @param {String} id Element id
 * @param {String} pos Alignment configuration
 * @param {Array} offset Offset to apply to xy
 */
GUI.Popup.Region.prototype.alignTo = function (id, pos, offset) {
    if (this.dom && id) {
        this.alignArgs = GUI.toArray(arguments);
        this._alignTo(id, pos, offset);
    } else if (this.dom && this.alignArgs && this.alignArgs.length) {
        this._alignTo.apply(this, this.alignArgs);
    } else if (id)  {
        // Save arguments to call _alignTo when dom node would be rendered
        this.alignArgs = GUI.toArray(arguments);
    }
};

/**
 * Shows region. Creates dom tree and inserts it into container
 * TODO: Some small leak exists here...
 * TODO: Avoid creating new node every time.
 */
GUI.Popup.Region.prototype.show = function () {
    var style, node, dim,
        cfg = this.config;

    if (!this.visible) {
        // Create new dom node
        node = document.createElement('div');
        node.id = cfg.id;
        node.style.position = cfg.position || 'absolute';
        node.className = cfg.className;
        GUI.hide(node);

        if (!cfg.parentNode) {
            cfg.parentNode = document.body;
        }

        cfg.parentNode.appendChild(node); // eats memory each time
        node.innerHTML = cfg.content; // eats memory each time, must be after appendChild to prevent leaks!!!
        this.dom = GUI.Dom.extend(node);

        this.visible = true;
        node = null;
    }

    dim   = cfg.dimensions;
    style = this.dom.style;

    this.setDimensions(dim);

    /**
     * Gets zIndex from config if set or from global zIndex counter
     */
    style.zIndex = cfg.zIndex || GUI.Popup.Region.getzIndex();

    this.dom.style.visibility = 'hidden';
    GUI.show(this.dom);

    // Aligning have to be applyed after show!!!!!
    if ((GUI.isSet(this.alignArgs)) && (this.alignArgs.length > 0)) {
        this._alignTo.apply(this, this.alignArgs);
        this.alignArgs = [];
    }

    this.dom.style.visibility = '';

    this.fireEvent('show', this);

    // create and apply shim
    if (cfg.useShim) {
        if (!this.shim) {
            this.createShim();
        } else {
            GUI.show(this.shim);
        }
        setTimeout(function () {
            this.syncShim();
        }.bindLegacy(this), 10);
    }
};

/**
 * Hides region and deletes it from dom tree
 */
GUI.Popup.Region.prototype.hide = function () {
    if (this.visible) {
        if (this.fireEvent('beforeHide', this) === false) {
            return;
        }
        if (this.shim) {
            GUI.hide(this.shim);
        }
        this.visible = false;
        this.fireEvent('hide', this);
    }

    if (this.dom && this.config.destroyOnHide) {
        GUI.destroyNode(this.dom); // in ie fires window.onresize
        this.dom = null;
    } else if (this.dom && !this.config.destroyOnHide) {
        this.hideByOffset();
    }
};

/**
 * Saved dimensiosn, sets it to -10000
 */
GUI.Popup.Region.prototype.hideByOffset = function () {
    var cfg = this.config;
    this._oldPosition = [cfg.dimensions.left, cfg.dimensions.top];
    this.setDimensions({
        left: -10000,
        top : -10000
    });
};

/**
 * Sets dimensions to saved params
 */
GUI.Popup.Region.prototype.showByOffset = function () {
    if (this._oldPosition) {
        this.setDimensions({
            left: this._oldPosition[0],
            top: this._oldPosition[1]
        });
        delete this._oldPosition;
    }
};

/**
 * Apply region to passed element id. Takes element's position and dimensions
 * @param {String} id Element id
 */
GUI.Popup.Region.prototype.takeRegion = function (id) {
    var cfg  = this.config,
        dim  = cfg.dimensions,
        elem = GUI.$(id),
        pos  = GUI.getPosition(elem),
        elemDim = elem.getDimensions();

    dim.left = pos[0];
    dim.top = pos[1];
    dim.height = elemDim.height;
    dim.width = elemDim.width;

    this.setDimensions(dim);
};

/**
 * Take dimensions of document.
 * @param {Object} extend If document smaller then
 */
GUI.Popup.Region.prototype.takeDocument = function (extend) {
    var cfg = this.config,
        dim = cfg.dimensions;

    dim.left = 0;
    dim.top = 0;

    if (this.dom) {
        this.dom.setStyle({
            left    : '0px',
            top     : '0px',
            width   : '100%',
            height  : '100%'
        });
    }

    dim.height = GUI.getDocumentHeight();
    dim.width = GUI.getDocumentWidth();

    if (this.dom) {
        this.dom.setStyle({
            left    : '0px',
            top     : '0px',
            width   : dim.width + 'px',
            height  : dim.height + 'px'
        });
    }
};

/**
 * Sets region content
 * @param {String} content Any text or html code
 */
GUI.Popup.Region.prototype.setContent = function (content) {
    this.config.dimensions = {
        top : 0,
        left: 0
    };
    this.config.content = content;
    if (this.visible) {
        this.dom.innerHTML = content;
        this.dom.setStyle({
            width: '',
            height: ''
        });
        this.config.dimensions = this.getDimensions();
        this.alignTo();
    }
};

/**
 *
 */
GUI.Popup.Region.prototype.adjustWidth = function (w) {
    if (!GUI.isBorderBox) {
        w -= this.paddingLeft + this.paddingRight + this.borderLeft + this.borderRight;
    }
    return w;
};

/**
 *
 */
GUI.Popup.Region.prototype.adjustHeight = function (h) {
    if (!GUI.isBorderBox) {
        h -= this.paddingTop + this.paddingBottom + this.borderTop + this.borderBottom;
    }
    return h;
};

/**
 * Sets position and dimensions of region
 * @param {Object} dimensions
 */
GUI.Popup.Region.prototype.setDimensions = function (dimensions) {
    Object.extend(this.config.dimensions, Object.clone(dimensions));
    if (this.dom) {
        var style = this.dom.style;
        if (GUI.isNumber(dimensions.left)) {
            style.left = dimensions.left + 'px';
        } else if (GUI.isString(dimensions.left)) {
            style.left = dimensions.left;
        }

        if (GUI.isNumber(dimensions.top)) {
            style.top = dimensions.top + 'px';
        } else if (GUI.isString(dimensions.top)) {
            style.top = dimensions.top;
        }

        if (GUI.isNumber(dimensions.width)) {
            style.width = this.adjustWidth(dimensions.width) + 'px';
        } else if (GUI.isString(dimensions.width)) {
            style.width = dimensions.width;
        }

        if (GUI.isNumber(dimensions.height)) {
            style.height = this.adjustHeight(dimensions.height) + 'px';
        } else if (GUI.isString(dimensions.height)) {
            style.height = dimensions.height;
        }
    }
};

/**
 * Sets positions
 * @param {Number} x Left position
 * @param {Number} y Top position
 */
GUI.Popup.Region.prototype.setPosition = function (x, y) {
    var dims = this.config.dimensions,
        style = this.dom && this.dom.style;

    if (x !== undefined) {
        dims.left = x;
        if (style) {
            style.left = GUI.isNumber(x) ? x + 'px' : x;
        }
    }

    if (y !== undefined) {
        dims.top = y;
        if (style) {
            style.top = GUI.isNumber(y) ? y + 'px' : y;
        }
    }
};

/**
 * Returns width
 * @returns {Number} width
 */
GUI.Popup.Region.prototype.getWidth = function () {
    return this.dom
        ? this.dom.offsetWidth
        : this.config.dimensions.width;
};

/**
 * Gets position and dimensions of region from dom
 * and updates config
 * @return {Object} dimensions
 */
GUI.Popup.Region.prototype.getDimensions = function () {
    // Take attention!!! this.dom!
    var xy = GUI.getPosition(this.dom),
        dim = GUI.getDimensions(this.dom),
        cfgDim = this.config.dimensions;

    cfgDim.left     = xy[0];
    cfgDim.top      = xy[1];
    cfgDim.width    = dim.width;
    cfgDim.height   = dim.height;

    return {
        left    : cfgDim.left,
        top     : cfgDim.top,
        width   : cfgDim.width,
        height  : cfgDim.height
    };
};

/**
 * Get dom node of region (only if it is showed)
 * @returns {HTMLElement} dom
 */
GUI.Popup.Region.prototype.getElement = function () {
    return this.dom;
};

/**
 * Returns dom
 * @returns {HTMLElement} dom
 */
GUI.Popup.Region.prototype.getDom = function () {
    return this.dom;
};

/**
 * Set z-index of region
 * @param {Object} zIndex z-index
 */
GUI.Popup.Region.prototype.setZIndex = function (zIndex) {
    this.config.zIndex = zIndex;
    if (this.visible) {
        this.dom.style.zIndex = zIndex;
    }
};

/**
 * Gets visibility of region
 */
GUI.Popup.Region.prototype.getVisibility = function () {
    return this.visible;
};

/**
 * See documentation about alignTo() method
 * @param {String} id Element id
 * @param {String} pos Alignment configuration
 * @param {Array} offset Offset to apply to xy
 */
GUI.Popup.Region.prototype._alignTo = function (id, pos, offset) {
    var m, elem, dim, a1, a2, x, y, w, h, r, dw, p1y, p2y, p1x, p2x, xy, dh,
        scrollX, scrollY, doc,
        swapX = false,
        swapY = false,
        c = false, //constrain to viewport
        p1 = "", p2 = "";
    offset = offset || [0, 0];

    if (!pos) {
        // Setup default position if not passed
        pos = "tl-bl";
    } else if (pos === "?") {
        pos = "tl-bl?";
    } else if (pos.indexOf("-") === -1) {
        pos = "tl-" + pos;
    }
    pos = pos.toLowerCase();
    m = pos.match(/^([a-z]+)-([a-z]+)(\?)?$/);
    if (!m) {
        throw new Error("Element.alignTo with an invalid alignment " + pos);
    }

    p1 = m[1];
    p2 = m[2];
    c = !!m[3];

    // Subtract the aligned el's internal xy from the target's offset xy
    // plus custom offset to get the aligned el's new offset xy
    elem = GUI.$(id);
    dim = this.config.dimensions;
    a1 = this.getAnchorXY(this.dom, p1, true);
    a2 = this.getAnchorXY(elem, p2, false);

    x = a2[0] - a1[0] + offset[0];
    y = a2[1] - a1[1] + offset[1];

    if (c) { // need to debug, buggy :(
        //constrain the aligned el to viewport if necessary
        w = GUI.getDimensions(this.dom, 'deep').width;
        h = GUI.getDimensions(this.dom, 'deep').height;
        r = this.getRegion(elem);

        // 5px of margin for ie
        dw = GUI.getViewportWidth(elem);
        dh = GUI.getViewportHeight(elem); // -5 for both

        // If we are at a viewport boundary and the aligned el is anchored on a target border that is
        // perpendicular to the vp border, allow the aligned el to slide on that border,
        // otherwise swap the aligned el to the opposite border of the target.
        p1y = p1.charAt(0);
        p1x = p1.charAt(p1.length - 1);
        p2y = p2.charAt(0);
        p2x = p2.charAt(p2.length - 1);
        swapY = ((p1y === "t" && p2y === "b") || (p1y === "b" && p2y === "t"));
        swapX = ((p1x === "r" && p2x === "l") || (p1x === "l" && p2x === "r"));

        doc = document;
        scrollX = (doc.documentElement.scrollLeft || doc.body.scrollLeft || 0); //+5;
        scrollY = (doc.documentElement.scrollTop || doc.body.scrollTop || 0); //+5;

        this.shiftX = '';
        this.shiftY = '';

        if ((x + w) > dw) {
            // Too right
            x = swapX ? (r.left - w) : (dw + scrollX - w);
            x -= offset[0]; // bugfixes :)
            this.shiftX = 'right';
        }

        if ((x + w) > dw + scrollX) {
            // Too right
            x = swapX ? (r.left - w) : (dw + scrollX - w);
            x -= offset[0]; // bugfixes :)
            this.shiftX = 'right';
        }
        if (x < scrollX) {
            // Too left
            x = swapX ? r.right : scrollX;
            x += offset[0];
            this.shiftX = 'left';
        }

        if ((y + h) > dh + scrollY) {
            // Under bottom...
            y = swapY ? (r.top - h) : (dh + scrollY - h);
            y -= offset[1];
            this.shiftY = 'under-bottom';
        }
        if (y < scrollY) {
            // Over top
            y = swapY ? r.bottom : scrollY;
            y += offset[1];
            this.shiftY = 'over-top';
        }
    }

    xy = GUI.getLocalCoordinates(this.config.parentNode, [x, y]);

    x = xy[0]; y = xy[1];

    if (x < 0) {
        x = 0;
    }

    dim.left = x;
    dim.top = y;

    this.dom.setStyle({
        left: x + 'px',
        top:  y + 'px'
    });
};

/*
 * Utility functions. Need to move it out of here and replace references
 */
/**
 * Gets region of region, object with properties: left, top, right, bottom
 * @param {Object} elem
 */
GUI.Popup.Region.prototype.getRegion = function (elem) {
    var pos = GUI.getPosition(elem),
        dim = GUI.getDimensions(elem);

    return {
        left    : pos[0],
        top     : pos[1],
        right   : pos[0] + dim.width,
        bottom  : pos[1] + dim.height
    };
};

/**
 * Gets anchor point XY coordinates
 * @param {Object} elem DOMNode
 * @param {String} anchor Anchor string
 * @param {Boolean} local True to get coordinates relative to element, false - absolute
 * @param {Number} size
 */
GUI.Popup.Region.prototype.getAnchorXY = function (elem, anchor, local, size) {
    var w, h, _dim, x, y, r, sc, o,
        vp = false,
        dim = this.config.dimensions;

    if (!size) {
        if (elem === document.body || elem === document) {
            vp = true;
            w = GUI.getViewportWidth(elem);
            h = GUI.getViewportHeight(elem);
        } else {
            _dim = GUI.getDimensions(elem, 'deep');
            w = _dim.width;
            h = _dim.height;
        }
    } else {
        w = size.width; h = size.height;
    }

    x = 0;
    y = 0;
    r = Math.round;
    switch ((anchor || "tl").toLowerCase()) {
    case "c":
        x = r(w * 0.5);
        y = r(h * 0.5);
        break;
    case "t":
        x = r(w * 0.5);
        y = 0;
        break;
    case "l":
        x = 0;
        y = r(h * 0.5);
        break;
    case "r":
        x = w;
        y = r(h * 0.5);
        break;
    case "b":
        x = r(w * 0.5);
        y = h;
        break;
    case "tl":
        x = 0;
        y = 0;
        break;
    case "bl":
        x = 0;
        y = h;
        break;
    case "br":
        x = w;
        y = h;
        break;
    case "tr":
        x = w;
        y = 0;
        break;
    }

    if (local === true) {
        return [x, y];
    }

    if (vp) {
        sc = GUI.getScroll(elem);
        return [x + sc.left, y + sc.top];
    }
    // Add the element's offset xy
    o = GUI.getPosition(elem);
    return [x + o[0], y + o[1]];
};

/**
 * Creates the shim
 */
GUI.Popup.Region.prototype.createShim = function () {
    var iframe = document.createElement('iframe');
    iframe.className = '';
    iframe.frameBorder = 'no';
    this.dom.parentNode.insertBefore(iframe, this.dom);
    this.shim = iframe;
};

/**
 * Sets positions of the shim
 */
GUI.Popup.Region.prototype.syncShim = function () {
    if (this.dom) {
        this.shim.style.left = this.dom.offsetLeft + 'px';
        this.shim.style.top = this.dom.offsetTop + 'px';
        this.shim.style.width = this.dom.offsetWidth + 'px';
        this.shim.style.height = this.dom.offsetHeight + 'px';
    }
};
